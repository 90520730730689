import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import {
  getMonthName,
  CheckDepositCovered,
  CalculateFormulasFromPurchasePrice,
  GetPropertyType,
  GetHomeAndPropertyLoanRepayment,
  GenerateSpendingData,
  CheckBlendedPropertyRiskProfile,
  commaStringSeparatorsWithDollors,
  GetRegionData,
  TotalInvestmentPropertiesGrossIncome,
  CalculatePropertiesCashFlowAnnual,
  calculateMaxBorrowingCapacity,
} from "utils/helperFunctions";
import { GS } from "services/global";
import { TPropertyGeneratorItem, TSpendingSheet } from "types";
import { useDispatch } from "react-redux";
import { SetCalData } from "store/Slices/CalculatorSlice";
import { SaveCalData } from "store/Slices/CalculatorSlice";
export interface IFeasibilityMarker {
  property_id: string | number;
  data: string;
  state: string;
  name: string;
  isLocked: boolean;
}
export interface ISummaryTableData {
  product: string;
  headingName: string;
  columnColor?: string;
  className?: string;
  textclassName?: string;
  values: string[] | IFeasibilityMarker[] | number[];
  expanded: Boolean;
  lastColumnHeading?: string;
  totalValue?: string;
}
interface ISummaryRowsData {
  RiskFactoredValues: number[];
  FeasibilityMarkers: IFeasibilityMarker[];
  PropertyTypeValues: string[];
  RegionValues: IFeasibilityMarker[];
  PurchasePriceValues: number[];
  Deposit: number[];
  StampDutyValues: number[];
  AcquisitionCost: number[];
  LMIValues: number[];
  TotalDepositRequired: number[];
  TotalPurchaseCosts: number[];
  FINANCE: string[];
  LVR: number[];
  AppliedInterestRate: string[];
  TotalDebt: number[];
  TotalDepositEquity: number[];
  TotalDepositCash: number[];
  CASHFLOW: string[];
  PropertyRentIncome: number[];
  TotalCostofLending: number[];
  CashflowAnnual: number[];
  CashflowMonthly: number[];
  ReturnonEquity: number[];
  GROWTHHACKING: string[];
  AppliedGrowthRateAnnual: string[];
  EquityGain: number[];
  CashGain: number[];
}
const initialSpendingData: TSpendingSheet = {
  date: "",
  balanceSheet: {
    total: 0,
    cashOnHand: {
      total: 0,
      total2: 0,
      accountYour: 0,
      accountPartner: 0,
      extraCash: 0,
      liquidassets: 0,
    },
    EquityGain: 0,
    EquityGain2: 0,
    liabilities: {
      total: 0,
      creditCardPartner: 0,
      creditCardYours: 0,
    },
  },
  monthlyCashflow: {
    total: 0,
    expenses: {
      total: 0,
      PersonalExpenses: 0,
      InvestmentExpenses: 0,
      BillsUtilities: 0,
      Education: 0,
      Entertainment: 0,
      HigherEducationandVocationalTraining: 0,
      Childcare: 0,
      PrimaryResidence: 0,
      FoodDining: 0,
      RecreationHolidays: 0,
      GeneralInsurance: 0,
      LoansCreditCards: 0,
      RentMortgageProperty: 0,
      Shopping: 0,
      Travel: 0,
      HECSPayments: 0,
      CarLoan: 0,
      AlcoholicBeverages: 0,
      ClothingAndFootwear: 0,
      Communication: 0,
      CurrentHousingCosts: 0,
      DomesticFuelAndPower: 0,
      FoodAndNonAlcoholicBeverages: 0,
      HouseholdFurnishingsAndEquipment: 0,
      HouseholdServicesAndOperation: 0,
      HealthFitness: 0,
      PersonalCare: 0,
      Recreation: 0,
      AccountantFees: 0,
      BodyCorporatePayments: 0,
      CashGiftsAndDonations: 0,
      FeesNecGovernment: 0,
      FeesNecPrivate: 0,
      FinancialInstitutionCharges: 0,
      HireServicesNec: 0,
      InsuranceNec: 0,
      MiscellaneousGoodsNec: 0,
      MiscellaneousGoodsNfd: 0,
      MiscellaneousServicesNec: 0,
      NonHolidayAccommodation: 0,
      StationeryEquipmentnfd: 0,
      TravelGoods: 0,
      UnionDues: 0,
      OtherExpenses: 0,
      cashWithdrawals: 0,
      BankFees: 0,
      loanInterests: 0,
      externalTransfers: 0,
    },
    income: {
      total: 0,
      netSalaryYour: 0,
      netSalaryPartner: 0,
      rentalIncome: 0,
      OtherIncome: 0,
    },
  },
};
export const useGenerateSummary = () => {
  const CalData = useSelector((state: RootState) => state.calculator);
  const SettingsData = useSelector((state: RootState) => state.settings);
  const dispatch = useDispatch();
  const [initialpropertydata] = useState<TPropertyGeneratorItem>({
    CashCovered: "You still need  more cash from your cash amount!",
    cashAvailable: 0,
    currentmbc: 0,
    equityAvailable: 0,
    equityGain: CalData?.Equity?.UsableEquity,
    extraCash: 0,
    hidden: false,
    initialPropertyType: "",
    isLocked: false,
    isRequird: true,
    liquidAssets: 0,
    medianPropertyType: "",
    methodforPurchasePrice: "Maximum borrowing capacity + 20% LVR",
    netIncome: 0,
    newmbc: 0,
    nextDate: "",
    totalAvailableFunds: 0,
    summaryData: {
      percentageCash: 100,
      percentageEquity: 80,
      percentageLiquidAssets: 0,
      ExtraCash: 0,
      useCash: "Y",
      useEquity: "Y",
      useExtraCash: "N",
      useLiquidAssets: "N",
      priceReduce: "N",
      propertyType: "Residential",
      region: "",
      regionNo: 0,
      stateName: "",
      PurchasePrice: 0,
      Deposit: 0,
      StampDuty: 0,
      AcquisitionCost: 0,
      TotalDepositRequired: 0,
      TotalPurchaseCosts: 0,
      LoantoValueRatioLVR: 0,
      TotalDebt: 0,
      TotalDepositEquity: 0,
      TotalDepositCash: 0,
      TotalDepositExtraCash: 0,
      TotalDepositLiquidAssets: 0,
      PropertyRentIncomeYear1: 0,
      InterestPayments: 0,
      PrinciplePayments: 0,
      outgoingExpenses: 0,
      outgoingExpensePercent: 0,
      CashflowAnnual: 0,
      CashflowMonthly: 0,
      AppliedGrowthRateannualPERC: "0%",
      EquityGainPERCorDOLLAR: 0,
      CashGainDOLLAR: 0,
      LMI: 0,
      mortgageInsuranceApply: "N",
      interestRate: SettingsData.Assumptions.InterestRate,
      DebtInterestRate: SettingsData.Assumptions.DebtInterestRate,
      DebtPrincipleRate: SettingsData.Assumptions.DebtPrincipalRate,
      EquityInterestRate: SettingsData.Assumptions.EquityInterestRate,
      EquityPrincipleRate: SettingsData.Assumptions.EquityPrincipalRate,
      RiskFactoredYieldReturn: 0,
      TotalCostofLending: 0,
      ReturnonEquityCOC: 0,
    },
  });
  const [summaryRowValues, setsummaryRowValues] = useState<ISummaryRowsData>({
    RiskFactoredValues: [],
    FeasibilityMarkers: [],
    PropertyTypeValues: [],
    RegionValues: [],
    PurchasePriceValues: [],
    Deposit: [],
    StampDutyValues: [],
    AcquisitionCost: [],
    LMIValues: [],
    TotalDepositRequired: [],
    TotalPurchaseCosts: [],
    FINANCE: [],
    LVR: [],
    AppliedInterestRate: [],
    TotalDebt: [],
    TotalDepositEquity: [],
    TotalDepositCash: [],
    CASHFLOW: [],
    PropertyRentIncome: [],
    TotalCostofLending: [],
    CashflowAnnual: [],
    CashflowMonthly: [],
    ReturnonEquity: [],
    GROWTHHACKING: [],
    AppliedGrowthRateAnnual: [],
    EquityGain: [],
    CashGain: [],
  });
  const GenerateTable = (summaryRowValues: ISummaryRowsData) => {
    let tabledata: ISummaryTableData[] = [];
    const totalPurhcasePrice = summaryRowValues.PurchasePriceValues.reduce(
      (prevTotal, Item) => {
        return prevTotal + Item;
      },
      0
    );
    const totalDeposit = summaryRowValues.Deposit.reduce((prevTotal, Item) => {
      return prevTotal + Item;
    }, 0);
    const totalStampDuty = summaryRowValues.StampDutyValues.reduce(
      (prevTotal, Item) => {
        return prevTotal + Item;
      },
      0
    );
    const totalLMI = summaryRowValues.LMIValues.reduce((prevTotal, Item) => {
      return prevTotal + Item;
    }, 0);
    const totalAcquistionCost = summaryRowValues.AcquisitionCost.reduce(
      (prevTotal, Item) => {
        return prevTotal + Item;
      },
      0
    );
    const totalPurchaseCost = summaryRowValues.TotalPurchaseCosts.reduce(
      (prevTotal, Item) => {
        return prevTotal + Item;
      },
      0
    );
    const totalDebt = summaryRowValues.TotalDebt.reduce((prevTotal, Item) => {
      return prevTotal + Item;
    }, 0);
    const totalDepositEquity = summaryRowValues.TotalDepositEquity.reduce(
      (prevTotal, Item) => {
        return prevTotal + Item;
      },
      0
    );
    const totalDepositCash = summaryRowValues.TotalDepositCash.reduce(
      (prevTotal, Item) => {
        return prevTotal + Item;
      },
      0
    );
    const totalPropertyRentIncome = summaryRowValues.PropertyRentIncome.reduce(
      (prevTotal, Item) => {
        return prevTotal + Item;
      },
      0
    );
    const totalCostLending = summaryRowValues.TotalCostofLending.reduce(
      (prevTotal, Item) => {
        return prevTotal + Item;
      },
      0
    );
    const totalCashflowAnnual = summaryRowValues.CashflowAnnual.reduce(
      (prevTotal, Item) => {
        return prevTotal + Item;
      },
      0
    );
    const totalCashflowMonthly = summaryRowValues.CashflowMonthly.reduce(
      (prevTotal, Item) => {
        return prevTotal + Item;
      },
      0
    );
    const totalCashGain = summaryRowValues.CashGain.reduce(
      (prevTotal, Item) => {
        return prevTotal + Item;
      },
      0
    );
    const totalEquityGain = summaryRowValues.EquityGain.reduce(
      (prevTotal, Item) => {
        return prevTotal + Item;
      },
      0
    );
    tabledata = [
      ...tabledata,
      {
        product: "Risk Factored Yield Return",
        headingName: "Risk Factored Yield Return",
        className: "!bg-[white]",
        values: summaryRowValues.RiskFactoredValues,
        expanded: false,
        lastColumnHeading: "",
      },
      {
        product: "Feasibility Markers",
        headingName: "Feasibility Markers",
        className: "!bg-[#ebf2fa] !h-[120px]",
        values: summaryRowValues.FeasibilityMarkers,
        expanded: false,
        lastColumnHeading: "Total",
      },
      {
        product: "Property Type",
        headingName: "Property Type",
        className: "bg-[white]",
        values: summaryRowValues.PropertyTypeValues,
        expanded: false,
        lastColumnHeading: "",
      },
      {
        product: "Suburb",
        headingName: "Suburb",
        className: "bg-[white]",
        values: summaryRowValues.RegionValues,
        expanded: false,
        lastColumnHeading: "",
      },
      {
        product: "Purchase Price",
        headingName: "Purchase Price",
        className: "bg-[white]",
        values: summaryRowValues.PurchasePriceValues,
        expanded: false,
        lastColumnHeading: "",
        totalValue: commaStringSeparatorsWithDollors(totalPurhcasePrice),
      },
      {
        product: "Deposit",
        headingName: "Deposit",
        className: "bg-[white]",
        values: summaryRowValues.Deposit,
        expanded: false,
        lastColumnHeading: "",
        totalValue: commaStringSeparatorsWithDollors(totalDeposit),
      },
      {
        product: "Stamp Duty",
        headingName: "Stamp Duty",
        className: "bg-[white]",
        values: summaryRowValues.StampDutyValues,
        expanded: false,
        lastColumnHeading: "",
        totalValue: commaStringSeparatorsWithDollors(totalStampDuty),
      },
      {
        product: "Acquisition Cost",
        headingName: "Acquisition Cost",
        className: "bg-[white]",
        values: summaryRowValues.AcquisitionCost,
        expanded: false,
        lastColumnHeading: "",
        totalValue: commaStringSeparatorsWithDollors(totalAcquistionCost),
      },
      {
        product: "LMI",
        headingName: "LMI",
        className: "bg-[white]",
        values: summaryRowValues.LMIValues,
        expanded: false,
        lastColumnHeading: "",
        totalValue: commaStringSeparatorsWithDollors(totalLMI),
      },

      {
        product: "Total Deposit Required",
        headingName: "Total Deposit Required",
        className: "bg-[#ffaca9]",
        values: summaryRowValues.TotalDepositRequired,
        expanded: false,
        lastColumnHeading: "",
        totalValue: commaStringSeparatorsWithDollors(totalAcquistionCost),
      },
      {
        product: "Total Purchase Costs",
        headingName: "Total Purchase Costs",
        className: "bg-[white]",
        values: summaryRowValues.TotalPurchaseCosts,
        expanded: false,
        lastColumnHeading: "",
        totalValue: commaStringSeparatorsWithDollors(totalPurchaseCost),
      },
      {
        product: "FINANCE",
        headingName: "FINANCE",
        className: "!bg-[#ebf2fa]",
        textclassName: "!font-bold !text-black",
        values: summaryRowValues.FINANCE,
        expanded: false,
        lastColumnHeading: "",
      },
      {
        product: "Loan to Value Ratio (LVR)",
        headingName: "Loan to Value Ratio (LVR)",
        className: "bg-[white]",
        values: summaryRowValues.LVR,
        expanded: false,
        lastColumnHeading: "",
      },
      {
        product: "Applied Interest Rate",
        headingName: "Applied Interest Rate",
        className: "bg-[white]",
        values: summaryRowValues.AppliedInterestRate,
        expanded: false,
        lastColumnHeading: "",
      },
      {
        product: "Total Debt",
        headingName: "Total Debt",
        className: "bg-[white]",
        values: summaryRowValues.TotalDebt,
        expanded: false,
        lastColumnHeading: "",
        totalValue: commaStringSeparatorsWithDollors(totalDebt),
      },
      {
        product: "Total Deposit Equity",
        headingName: "Total Deposit Equity",
        className: "bg-[white]",
        values: summaryRowValues.TotalDepositEquity,
        expanded: false,
        lastColumnHeading: "",
        totalValue: commaStringSeparatorsWithDollors(totalDepositEquity),
      },
      {
        product: "Total Deposit Cash",
        headingName: "Total Deposit Cash",
        className: "bg-[#ebf2fa]",
        values: summaryRowValues.TotalDepositCash,
        expanded: false,
        lastColumnHeading: "",
        totalValue: commaStringSeparatorsWithDollors(totalDepositCash),
      },
      {
        product: "CASHFLOW",
        headingName: "CASHFLOW",
        className: "!bg-[white]",
        textclassName: "!font-bold !text-black",
        values: summaryRowValues.CASHFLOW,
        expanded: false,
        lastColumnHeading: "",
      },

      {
        product: "Property Rent Income (Year 1)",
        headingName: "Property Rent Income (Year 1)",
        className: "bg-[white]",
        values: summaryRowValues.PropertyRentIncome,
        expanded: false,
        lastColumnHeading: "",
        totalValue: commaStringSeparatorsWithDollors(totalPropertyRentIncome),
      },
      {
        product: "Total Cost of Lending",
        headingName: "Total Cost of Lending",
        className: "bg-[white]",
        values: summaryRowValues.TotalCostofLending,
        expanded: false,
        lastColumnHeading: "",
        totalValue: commaStringSeparatorsWithDollors(totalCostLending),
      },
      {
        product: "Cashflow (Annual)",
        headingName: "Cashflow (Annual)",
        className: "bg-[#ffd700]",
        values: summaryRowValues.CashflowAnnual,
        expanded: false,
        lastColumnHeading: "",
        totalValue: commaStringSeparatorsWithDollors(totalCashflowAnnual),
      },
      {
        product: "Cashflow (Monthly)",
        headingName: "Cashflow (Monthly)",
        className: "bg-[#ffd700]",
        values: summaryRowValues.CashflowMonthly,
        expanded: false,
        lastColumnHeading: "",
        totalValue: commaStringSeparatorsWithDollors(totalCashflowMonthly),
      },
      {
        product: "Return on Equity (COC)",
        headingName: "Return on Equity (COC)",
        className: "bg-[#ebf2fa]",
        values: summaryRowValues.ReturnonEquity,
        expanded: false,
        lastColumnHeading: "",
      },
      {
        product: "GROWTH HACKING",
        headingName: "GROWTH HACKING",
        className: "!bg-[white]",
        textclassName: "!font-bold !text-black",
        values: summaryRowValues.GROWTHHACKING,
        expanded: false,
        lastColumnHeading: "",
      },
      {
        product: "Applied Growth Rate (Annual)(%)",
        headingName: "Applied Growth Rate (Annual)(%)",
        className: "bg-[white]",
        values: summaryRowValues.AppliedGrowthRateAnnual,
        expanded: false,
        lastColumnHeading: "",
      },
      {
        product: "Equity Gain ($)",
        headingName: "Equity Gain ($)",
        className: "bg-[white]",
        values: summaryRowValues.EquityGain,
        expanded: false,
        lastColumnHeading: "",
        totalValue: commaStringSeparatorsWithDollors(totalEquityGain),
      },
      {
        product: "Cash Gain ($)",
        headingName: "Cash Gain ($)",
        className: "bg-[white]",
        values: summaryRowValues.CashGain,
        expanded: false,
        lastColumnHeading: "",
        totalValue: commaStringSeparatorsWithDollors(totalCashGain),
      },
    ];

    return tabledata;
  };

  const CalculateFirstPropertyFormula = (
    propertydata: TPropertyGeneratorItem,
    propertyno: number,
    selectedRegions: number[]
  ) => {
    let newPurchasePrice: any = 0;
    let totaldebt: number = 0;
    let lvr = 65;
    let riskfactor = propertydata.summaryData.RiskFactoredYieldReturn || 6;
    let currentmbc = 0;
    let percentageEquity = 100 || 0;
    let percentageCash = 100 || 0;
    let useCash = propertydata?.summaryData?.useCash;
    let useEquity = propertydata?.summaryData?.useEquity;
    let useLiquidAssets = propertydata?.summaryData?.useLiquidAssets;
    let useExtraCash = propertydata?.summaryData?.useExtraCash;
    let cashAvailable = propertydata?.cashAvailable || 0;
    let equityAvailable = propertydata?.equityAvailable || 0;
    let equityGain = propertydata?.equityGain || 0.0;
    let equityGainGrowth =
      propertydata?.summaryData.EquityGainPERCorDOLLAR || 0;
    let cashGainGrowth =
      propertydata?.summaryData?.CashGainDOLLAR === 0
        ? 0
        : propertydata?.summaryData?.CashGainDOLLAR;
    let annualGrowth = propertydata?.summaryData?.AppliedGrowthRateannualPERC;

    let maxborrow = calculateMaxBorrowingCapacity(
      CalData.Equity.PPR,
      CalData.Equity.Properties,
      0,
      0,
      CalData.BorrowingCalculator.IncomeDetails,
      CalData.BorrowingCalculator.PersonalLiabilities.CreditCards,
      CalData.BorrowingCalculator.UseAverageAustralian,
      CalData.BorrowingCalculator.UseBasiq,
      CalData.BorrowingCalculator.LivingExpenses,
      CalData.BorrowingCalculator.BasiqExpenses,
      SettingsData.Assumptions.DTI,
      CalData.BorrowingCalculator.ManualExpense
    );
    currentmbc = maxborrow;

    const { propertytype, medianPropertyType } = GetPropertyType({
      RiskFactoredYieldReturn: propertydata.summaryData.RiskFactoredYieldReturn,
    });
    if (propertytype === "Residential") {
      totaldebt = (maxborrow * 0.2) / (lvr / 100); //  80/100
    } else {
      totaldebt = (maxborrow * 0.35) / (lvr / 100); // 65/100
    }
    newPurchasePrice =
      propertydata?.summaryData?.PurchasePrice === 0
        ? totaldebt + maxborrow || 0
        : propertydata?.summaryData?.PurchasePrice;
    let { stateName, regionNo, region, growthRate } = GetRegionData({
      RiskFactorYieldReturn: riskfactor,
      medianPropertyType,
      propertyno,
      selectedRegions,
    });
    annualGrowth = growthRate;
    const {
      Deposit: newDeposit,
      StampDuty: newStampDuty,
      AcquisitionCost: newTotalAcquistonCost,
      TotalDepositRequired: newTotalDepositRequired,
      TotalDebt: newTotalDebt,
      TotalPurchaseCosts: newTotalPurchaseCosts,
      PropertyIncome,
    } = CalculateFormulasFromPurchasePrice({
      PurchasePrice: newPurchasePrice,
      stateName,
      riskfactor,
      acquistionCostValue: 6.5 / 100,
    });

    let newpropertyYield = PropertyIncome / newPurchasePrice || 0;
    riskfactor = newpropertyYield * 100;
    let cash = propertydata.cashAvailable || 0;

    const {
      checkdepositmessage: updatedcheckdepositmessage,
      PurchasePrice: updatedPurchasePrice,
      PropertyIncome: updatedPropertyIncome,
      TotalAcquistonCost: updatedTotalAcquistonCost,
      Deposit: updatedDeposit,
      StampDuty: updatedStampDuty,
      TotalDepositRequired: updatedTotalDepositRequired,
      TotalPurchaseCost: updatedTotalPurchaseCost,
      TotalDebt: updatedTotalDebt,
      priceReduce: updatedpriceReduce,
    } = CheckDepositCovered({
      cash,
      equityGain,
      stateName,
      newDeposit,
      newStampDuty,
      newAcquisitionCost: newTotalAcquistonCost,
      newTotalDepositRequired,
      newTotalDebt,
      newTotalPurchaseCosts,
      newPropertyIncome: PropertyIncome,
      PurchasePrice: newPurchasePrice,
      lvr,
      riskfactor,
      mortgageApply: "N",
    });
    if (equityGain > 0) {
      percentageEquity = 100;
      useEquity = "Y";
    } else {
      percentageEquity = 0;
      useEquity = "N";
    }
    if (cashAvailable > 0) {
      percentageCash = 100;
      useCash = "Y";
    } else {
      percentageCash = 0;
      useCash = "N";
    }
    //Calculate How much equity and cash you want to deposit
    let TotalDepositEquity =
      (equityGain * percentageEquity) / 100 > updatedTotalDepositRequired
        ? updatedTotalDepositRequired
        : (equityGain * percentageEquity) / 100;

    let TotalDepositCash = updatedTotalDepositRequired - TotalDepositEquity;
    if (cash + equityGain === updatedTotalDepositRequired) {
      useCash = "Y";
      useEquity = "Y";
      percentageEquity = 100;
      percentageCash = 100;
    } else if (
      cash + equityGain > updatedTotalDepositRequired &&
      equityGain < updatedTotalDepositRequired
    ) {
      useEquity = "Y";
      percentageEquity = (TotalDepositEquity / equityGain) * 100;
      useCash = "Y";
      percentageCash = (TotalDepositCash / cashAvailable) * 100;

      if (percentageCash === 0) {
        useCash = "N";
      }
    } else {
      useEquity = "Y";
      percentageEquity = (TotalDepositEquity / equityGain) * 100;
      useCash = "N";
      percentageCash = 0;
    }
    useLiquidAssets = "N";

    useExtraCash = "N";
    let PropertyRentIncomeYear1 = updatedPropertyIncome || 0;

    let EquityGainPERCorDOLLAR = equityGainGrowth || 0;
    let CashGainDOLLAR = cashGainGrowth || 0;
    let LMI = 0;
    let NetYield = Number(updatedPurchasePrice) * (Number(riskfactor) / 100);

    let equityInterestPayable =
      TotalDepositEquity *
      (Number(SettingsData?.Assumptions?.EquityInterestRate) / 100 || 0);
    let depositInterestPayable =
      updatedTotalDebt *
      (Number(SettingsData?.Assumptions?.DebtInterestRate) / 100 || 0);
    let equityPrincipalPayable =
      TotalDepositEquity *
      (Number(SettingsData?.Assumptions?.EquityPrincipalRate) / 100 || 0);
    let depositPrincipalPayable =
      updatedTotalDebt *
      (Number(SettingsData?.Assumptions?.DebtPrincipalRate) / 100 || 0);
    let TotalInterestPayable = equityInterestPayable + depositInterestPayable;
    let TotalPricipalPayable = equityPrincipalPayable + depositPrincipalPayable;
    if (propertydata?.summaryData?.AppliedGrowthRateannualPERC === "0%") {
      annualGrowth = growthRate;
    } else {
      annualGrowth = propertydata?.summaryData?.AppliedGrowthRateannualPERC;
    }
    let growthRateValue = annualGrowth.slice(0, annualGrowth?.length - 1);
    EquityGainPERCorDOLLAR =
      propertydata?.summaryData.EquityGainPERCorDOLLAR === 0
        ? (updatedPurchasePrice / 100) * Number(growthRateValue)
        : propertydata?.summaryData.EquityGainPERCorDOLLAR;

    // let TotalCostofLending =
    // (updatedTotalDebt + TotalDepositEquity) *
    //   (SettingsData.Assumptions.InterestRate / 100) || 0;

    let TotalCostofLending = TotalInterestPayable + TotalPricipalPayable;
    let CashflowAnnual = PropertyRentIncomeYear1 - TotalCostofLending || 0;
    let CashflowMonthly = CashflowAnnual / 12 || 0;
    let ReturnonEquityCOC =
      (CashflowAnnual / updatedTotalDepositRequired) * 100 || 0;
    if (updatedPurchasePrice === 0) {
      CashflowAnnual = 0;
      CashflowMonthly = 0;
      ReturnonEquityCOC = 0;
    }

    let updated_property_data: TPropertyGeneratorItem = {
      CashCovered: updatedcheckdepositmessage,
      cashAvailable: cashAvailable,
      currentmbc: currentmbc,
      equityAvailable: equityAvailable,
      equityGain: equityGain,
      extraCash: 0,
      hidden: false,
      initialPropertyType: "",
      isLocked: false,
      isRequird: true,
      liquidAssets: CalData.Equity.LiquidAssets,
      medianPropertyType: medianPropertyType,
      methodforPurchasePrice: "Maximum borrowing capacity + 20% LVR",
      netIncome: 0,
      newmbc: currentmbc,
      nextDate: propertydata?.nextDate,
      totalAvailableFunds: 0,
      summaryData: {
        percentageCash: percentageCash,
        percentageEquity: percentageEquity,
        percentageLiquidAssets: 0,
        ExtraCash: 0,
        useCash: useCash,
        useEquity: useEquity,
        useExtraCash: useExtraCash,
        useLiquidAssets: useLiquidAssets,
        priceReduce: updatedpriceReduce,
        propertyType: propertytype,
        region: region,
        regionNo: regionNo,
        stateName: stateName,
        PurchasePrice: updatedPurchasePrice,
        Deposit: updatedDeposit,
        StampDuty: updatedStampDuty,
        AcquisitionCost: updatedTotalAcquistonCost,
        TotalDepositRequired: updatedTotalDepositRequired,
        TotalPurchaseCosts: updatedTotalPurchaseCost,
        LoantoValueRatioLVR: lvr,
        TotalDebt: updatedTotalDebt,
        TotalDepositEquity: TotalDepositEquity,
        TotalDepositCash: TotalDepositCash,
        TotalDepositExtraCash: 0,
        TotalDepositLiquidAssets: 0,
        PropertyRentIncomeYear1: PropertyRentIncomeYear1,
        InterestPayments: TotalInterestPayable,
        PrinciplePayments: TotalPricipalPayable,
        outgoingExpenses:
          PropertyRentIncomeYear1 > 0 ? PropertyRentIncomeYear1 * 0.325 : 0,
        outgoingExpensePercent: 0.325 * 100,
        CashflowAnnual: CashflowAnnual,
        CashflowMonthly: CashflowMonthly,
        AppliedGrowthRateannualPERC: annualGrowth,
        EquityGainPERCorDOLLAR: EquityGainPERCorDOLLAR,
        CashGainDOLLAR: CashGainDOLLAR,
        LMI: LMI,
        mortgageInsuranceApply: "N",
        interestRate: SettingsData.Assumptions.InterestRate,
        DebtInterestRate: SettingsData.Assumptions.DebtInterestRate,
        DebtPrincipleRate: SettingsData.Assumptions.DebtPrincipalRate,
        EquityInterestRate: SettingsData.Assumptions.EquityInterestRate,
        EquityPrincipleRate: SettingsData.Assumptions.EquityPrincipalRate,
        RiskFactoredYieldReturn: riskfactor,
        TotalCostofLending: TotalCostofLending,
        ReturnonEquityCOC: ReturnonEquityCOC,
      },
    };
    return updated_property_data;
  };
  const CalculateNextThreePropertyFormulas = (
    propertydata: TPropertyGeneratorItem,
    propertyno: number,
    selectedRegions: number[],
    data?: TSpendingSheet[],
    generated_properties?: TPropertyGeneratorItem[]
  ) => {
    let RiskFactorYieldReturn =
      propertydata.summaryData.RiskFactoredYieldReturn || 6;
    let lvr = 65;
    let purchasepr = propertydata?.summaryData?.PurchasePrice || 0;
    let percentageEquity = propertydata?.summaryData?.percentageEquity;
    let percentageCash = propertydata?.summaryData?.percentageCash;
    let useCash = propertydata?.summaryData?.useCash;
    let useEquity = propertydata?.summaryData?.useEquity;
    let useLiquidAssets = propertydata?.summaryData?.useLiquidAssets;
    let useExtraCash = propertydata?.summaryData?.useExtraCash;
    let cashAvailable = propertydata?.cashAvailable;
    let equityAvailable = propertydata?.equityAvailable;
    let equityGain = CalData?.StrategicSpending?.TotalEquityGain || 0.0;
    const { propertytype, medianPropertyType } = GetPropertyType({
      RiskFactoredYieldReturn: RiskFactorYieldReturn,
    });
    let { stateName, growthRate, regionNo, region } = GetRegionData({
      RiskFactorYieldReturn: RiskFactorYieldReturn,
      medianPropertyType,
      propertyno,
      selectedRegions,
    });
    const { homeloanRepayment, propertyLoanRepayment } =
      GetHomeAndPropertyLoanRepayment({
        PPR: CalData.Equity.PPR,
        Properties: CalData.Equity.Properties,
      });
    let totalFinanceRepayment = homeloanRepayment + propertyLoanRepayment || 0;
    let OtherLoanPayments =
      CalData?.BorrowingCalculator?.PersonalLiabilities?.CreditCards?.reduce(
        (partialSum: any, nextItem: any) =>
          nextItem?.type !== "Credit Card"
            ? partialSum + nextItem.minimumPayment
            : partialSum,
        0
      ) * 12 || 0;
    let total_card_limit =
      CalData?.BorrowingCalculator?.PersonalLiabilities?.CreditCards?.reduce(
        (partialSum: any, nextItem: any) =>
          nextItem?.type === "Credit Card"
            ? partialSum + nextItem.limit
            : partialSum,
        0
      );
    let totalLivingExpense = CalData?.BorrowingCalculator?.LivingExpenses || 0;
    let index = propertyno;
    let propertyGrossIncome = TotalInvestmentPropertiesGrossIncome({
      Properties: CalData?.Equity?.Properties,
    });
    let propertyCashflowAnnual: any = CalculatePropertiesCashFlowAnnual({
      propertyno,
      properties: GS?.Calculator?.PropertyGenerator,
    });

    let sum = Math.round(
      CalData.BorrowingCalculator.IncomeDetails.YourIncome.value +
        CalData.BorrowingCalculator.IncomeDetails.PartnerIncome.value +
        CalData.BorrowingCalculator.IncomeDetails.OtherIncome.value +
        propertyCashflowAnnual +
        propertyGrossIncome
    );
    let maxborrow = calculateMaxBorrowingCapacity(
      CalData.Equity.PPR,
      CalData.Equity.Properties,
      propertyCashflowAnnual,
      propertyno,
      CalData.BorrowingCalculator.IncomeDetails,
      CalData.BorrowingCalculator.PersonalLiabilities.CreditCards,
      CalData.BorrowingCalculator.UseAverageAustralian,
      CalData.BorrowingCalculator.UseBasiq,
      CalData.BorrowingCalculator.LivingExpenses,
      CalData.BorrowingCalculator.BasiqExpenses,
      SettingsData.Assumptions.DTI,
      CalData.BorrowingCalculator.ManualExpense
    );

    let currentmbc = maxborrow;
    let RequiredDSR = SettingsData?.Assumptions?.DSR || 40;
    let NewLoanTerm = 5;
    let totaldebt = 0;
    if (propertytype === "Residential") {
      totaldebt = (maxborrow * 0.6) / (lvr / 100);
    } else {
      totaldebt = (maxborrow * 0.4) / (lvr / 100);
    }
    let newPurchasePrice =
      propertydata?.summaryData?.PurchasePrice === 0
        ? totaldebt + maxborrow
        : propertydata?.summaryData?.PurchasePrice;

    const {
      AcquisitionCost: newTotalAcquistonCost,
      PropertyIncome,
      TotalDepositRequired: newTotalDepositRequired,
      Deposit: newDeposit,
      StampDuty: newStampDuty,
    } = CalculateFormulasFromPurchasePrice({
      PurchasePrice: newPurchasePrice,
      stateName,
      riskfactor: RiskFactorYieldReturn,
      acquistionCostValue: 6.5 / 100,
    });
    let totalLoanAmountPrincipal = totaldebt; //Change According to Tyson
    let totalDepositAndAcquiston =
      newTotalAcquistonCost +
      newTotalDepositRequired +
      newDeposit +
      newStampDuty; //Change According to Tyson
    let newAnnualRepaymentDebt = Math.round(
      totaldebt * (SettingsData?.Assumptions?.DebtInterestRate / 100)
    ); //Change According to Tyson

    let updatedproperty: TPropertyGeneratorItem = {
      ...propertydata,
      summaryData: {
        ...propertydata.summaryData,
        TotalDepositRequired: newTotalDepositRequired,
      },
    };
    const { updatedcurrentProperty } = CalculateEquityAndCashDeposit({
      updated_property: updatedproperty,
      data: data ? data : [],
      properties: generated_properties ? generated_properties : [],
    });

    let newAnnualRepaymentEquity = Math.round(
      updatedcurrentProperty.summaryData.TotalDepositEquity *
        (SettingsData?.Assumptions?.EquityInterestRate / 100)
    ); //Change According to Tyson
    let currentDSRpart1 =
      newAnnualRepaymentDebt +
      newAnnualRepaymentEquity +
      totalFinanceRepayment +
      OtherLoanPayments +
      total_card_limit;
    let currentDSRpart2 = sum + PropertyIncome;
    let currentDSR = (currentDSRpart1 / currentDSRpart2) * 100 || 0;
    let newGrosIncome =
      Math.round(
        (totalFinanceRepayment +
          totalLivingExpense +
          total_card_limit +
          newAnnualRepaymentDebt +
          newAnnualRepaymentEquity) /
          12 /
          (currentDSR / 100)
      ) || 0;
    console.log(
      newGrosIncome,
      "newGrossIncome",
      propertyno,
      updatedcurrentProperty.summaryData.TotalDepositEquity,
      newTotalDepositRequired
    );
    let targetDebtRepayment = Math.round(newGrosIncome * (RequiredDSR / 100));
    let DecreaseInTotalPayment = newGrosIncome - targetDebtRepayment;
    let DecreaseinMBCpart2 =
      NewLoanTerm / (1 - Math.pow(1 + 0.07, -NewLoanTerm));
    let DecreaseInMBC = Math.round(DecreaseInTotalPayment * DecreaseinMBCpart2);
    let newMaxBorrowCapacity = totalLoanAmountPrincipal - DecreaseInMBC;
    let newTotalAmountPrincipal = newMaxBorrowCapacity;
    let newTotalDebt =
      (newTotalAmountPrincipal * (1 - lvr / 100)) / (lvr / 100);

    let newTotalPurchasePrice =
      propertydata?.summaryData?.PurchasePrice === 0
        ? Number(newTotalAmountPrincipal + newTotalDebt)
        : propertydata?.summaryData?.PurchasePrice;
    const {
      Deposit: updatedDeposit,
      StampDuty: updatedStampDuty,
      AcquisitionCost: updatedTotalAcquistonCost,
      TotalDepositRequired: updatedTotalDepositRequired,
      TotalDebt: updatedTotalDebts,
      TotalPurchaseCosts: updatedTotalPurchaseCosts,
      PropertyIncome: updatedPropertyIncome,
    } = CalculateFormulasFromPurchasePrice({
      PurchasePrice: newTotalPurchasePrice,
      stateName,
      riskfactor: RiskFactorYieldReturn,
    });
    useLiquidAssets = "N";
    useExtraCash = "N";
    let PropertyRentIncomeYear1 = updatedPropertyIncome;
    let EquityGainPERCorDOLLAR =
      propertydata?.summaryData?.EquityGainPERCorDOLLAR;
    let CashGainDOLLAR =
      propertydata?.summaryData?.CashGainDOLLAR === 0
        ? 0
        : propertydata?.summaryData?.CashGainDOLLAR;
    let LMI = 0;
    let annualGrowth = propertydata?.summaryData?.AppliedGrowthRateannualPERC;
    if (propertydata?.summaryData?.AppliedGrowthRateannualPERC === "0%") {
      annualGrowth = growthRate;
    } else {
      annualGrowth = propertydata?.summaryData?.AppliedGrowthRateannualPERC;
    }
    let growthRateValue = annualGrowth.slice(0, annualGrowth?.length - 1);
    EquityGainPERCorDOLLAR =
      propertydata?.summaryData?.EquityGainPERCorDOLLAR === 0
        ? (newTotalPurchasePrice / 100) * Number(growthRateValue)
        : propertydata?.summaryData.EquityGainPERCorDOLLAR;
    let updated_property_data: TPropertyGeneratorItem = {
      CashCovered: "",
      cashAvailable: cashAvailable,
      currentmbc: currentmbc,
      equityAvailable: equityAvailable,
      equityGain: equityGain,
      extraCash: 0,
      hidden: false,
      initialPropertyType: "",
      isLocked: false,
      isRequird: true,
      liquidAssets: CalData.Equity.LiquidAssets,
      medianPropertyType: medianPropertyType,
      methodforPurchasePrice: "Maximum borrowing capacity + 20% LVR",
      netIncome: 0,
      newmbc: newMaxBorrowCapacity,
      nextDate: propertydata?.nextDate,
      totalAvailableFunds: 0,
      summaryData: {
        percentageCash: percentageCash,
        percentageEquity: percentageEquity,
        percentageLiquidAssets: 0,
        ExtraCash: 0,
        useCash: useCash,
        useEquity: useEquity,
        useExtraCash: useExtraCash,
        useLiquidAssets: useLiquidAssets,
        priceReduce: "N",
        propertyType: propertytype,
        region: region,
        regionNo: regionNo,
        stateName: stateName,
        PurchasePrice: newTotalPurchasePrice,
        Deposit: updatedDeposit,
        StampDuty: updatedStampDuty,
        AcquisitionCost: updatedTotalAcquistonCost,
        TotalDepositRequired: updatedTotalDepositRequired,
        TotalPurchaseCosts: updatedTotalPurchaseCosts,
        LoantoValueRatioLVR: lvr,
        TotalDebt: updatedTotalDebts,
        TotalDepositEquity: 0,
        TotalDepositCash: 0,
        TotalDepositExtraCash: 0,
        TotalDepositLiquidAssets: 0,
        PropertyRentIncomeYear1: PropertyRentIncomeYear1,
        InterestPayments: 0,
        PrinciplePayments: 0,
        outgoingExpenses:
          PropertyRentIncomeYear1 > 0 ? PropertyRentIncomeYear1 * 0.325 : 0,
        outgoingExpensePercent: 0.325 * 100,
        CashflowAnnual: 0,
        CashflowMonthly: 0,
        AppliedGrowthRateannualPERC: annualGrowth,
        EquityGainPERCorDOLLAR: EquityGainPERCorDOLLAR,
        CashGainDOLLAR: CashGainDOLLAR,
        LMI: LMI,
        mortgageInsuranceApply: "N",
        interestRate: SettingsData.Assumptions.InterestRate,
        DebtInterestRate: SettingsData.Assumptions.DebtInterestRate,
        DebtPrincipleRate: SettingsData.Assumptions.DebtPrincipalRate,
        EquityInterestRate: SettingsData.Assumptions.EquityInterestRate,
        EquityPrincipleRate: SettingsData.Assumptions.EquityPrincipalRate,
        RiskFactoredYieldReturn: RiskFactorYieldReturn,
        TotalCostofLending: 0,
        ReturnonEquityCOC: 0,
      },
    };
    return updated_property_data;
  };
  const CalculateRemainingPropertiesFormula = (
    propertydata: TPropertyGeneratorItem,
    propertyno: number,
    selectedRegions: number[]
  ) => {
    let propertytype = "Residential";
    let medianPropertyType = propertytype;
    let equityAvailable = propertydata?.equityAvailable;
    let percentageCash = propertydata?.summaryData?.percentageCash;
    let percentageEquity = propertydata?.summaryData?.percentageEquity;
    let RiskFactorYieldReturn =
      propertydata.summaryData.RiskFactoredYieldReturn || 6;
    let cashAvailable = propertydata?.cashAvailable;
    let interestRate = 5;
    let equityGainGrowth = propertydata.summaryData.EquityGainPERCorDOLLAR || 0;
    let cashGainGrowth =
      propertydata?.summaryData?.CashGainDOLLAR === 0
        ? 0
        : propertydata?.summaryData?.CashGainDOLLAR;

    let {
      stateName,
      ResidentialMedianPrice,
      ComercialMedianPrice,
      RetailMedianPrice,
      growthRate,
      regionNo,
      region,
    } = GetRegionData({
      RiskFactorYieldReturn: RiskFactorYieldReturn,
      medianPropertyType: propertytype,
      propertyno,
      selectedRegions,
    });
    let thePurchasePrice = 0;
    // let MEDIANRENTPRICE_RentIncome = 0;
    if (RiskFactorYieldReturn < 5.99) {
      thePurchasePrice =
        propertydata?.summaryData?.PurchasePrice === 0
          ? (ResidentialMedianPrice / RiskFactorYieldReturn) * 100
          : propertydata?.summaryData?.PurchasePrice;
      // MEDIANRENTPRICE_RentIncome = ResidentialMedianPrice;
    } else if (RiskFactorYieldReturn < 6.99) {
      thePurchasePrice =
        propertydata?.summaryData?.PurchasePrice === 0
          ? (RetailMedianPrice / RiskFactorYieldReturn) * 100
          : propertydata?.summaryData?.PurchasePrice;
      // MEDIANRENTPRICE_RentIncome = RetailMedianPrice / 100;
    } else {
      thePurchasePrice =
        propertydata?.summaryData?.PurchasePrice === 0
          ? (ComercialMedianPrice / RiskFactorYieldReturn) * 100
          : propertydata?.summaryData?.PurchasePrice;
      // MEDIANRENTPRICE_RentIncome = ComercialMedianPrice / 100;
    }
    let equityGain = propertydata?.equityGain || 0;
    if (RiskFactorYieldReturn <= 5) {
      propertytype = "Residential";
      medianPropertyType = "Residential";
    } else if (RiskFactorYieldReturn > 5 && RiskFactorYieldReturn <= 6) {
      propertytype = "Commercial Retail";
      medianPropertyType = "Commercial Retail";
    } else if (RiskFactorYieldReturn > 6 && RiskFactorYieldReturn <= 8) {
      propertytype = "Commercial Office";
      medianPropertyType = "Commercial Office";
    } else {
      propertytype = "Commercial";
      medianPropertyType = "Commercial";
    }
    let newpurchasePrice = thePurchasePrice;
    let LVR = RiskFactorYieldReturn < 5.1 ? 80 : 65;
    const {
      Deposit: newDeposit,
      StampDuty: newStampDuty,
      AcquisitionCost: newTotalAcquistonCost,
      TotalDepositRequired,
      TotalDebt,
      TotalPurchaseCosts: newTotalPurchaseCosts,
      PropertyIncome,
    } = CalculateFormulasFromPurchasePrice({
      PurchasePrice: newpurchasePrice,
      stateName,
      riskfactor: RiskFactorYieldReturn,
      lvr: LVR,
      acquistionCostValue: 6.5 / 100,
    });
    let PropertyRentIncomeYear1 = PropertyIncome; //MEDIANRENTPRICE_RentIncome;
    let EquityGainPERCorDOLLAR = equityGainGrowth;
    let CashGainDOLLAR = cashGainGrowth;
    let LMI = 0;
    let currentmbc = 0;
    let useLiquidAssets = propertydata?.summaryData?.useLiquidAssets;
    let useExtraCash = propertydata?.summaryData?.useExtraCash;
    let useCash = propertydata?.summaryData?.useCash;
    let useEquity = propertydata?.summaryData?.useEquity;
    let annualGrowth = propertydata?.summaryData?.AppliedGrowthRateannualPERC;
    if (propertydata?.summaryData?.AppliedGrowthRateannualPERC === "0%") {
      annualGrowth = growthRate;
    } else {
      annualGrowth = propertydata?.summaryData?.AppliedGrowthRateannualPERC;
    }
    let growthRateValue = annualGrowth.slice(0, annualGrowth?.length - 1);
    EquityGainPERCorDOLLAR =
      propertydata?.summaryData.EquityGainPERCorDOLLAR === 0
        ? (newpurchasePrice / 100) * Number(growthRateValue)
        : propertydata?.summaryData.EquityGainPERCorDOLLAR;
    let updated_property_data: TPropertyGeneratorItem = {
      CashCovered: "",
      cashAvailable: cashAvailable,
      currentmbc: currentmbc,
      equityAvailable: equityAvailable,
      equityGain: equityGain,
      extraCash: 0,
      hidden: false,
      initialPropertyType: "",
      isLocked: false,
      isRequird: true,
      liquidAssets: CalData.Equity.LiquidAssets,
      medianPropertyType: medianPropertyType,
      methodforPurchasePrice: "Maximum borrowing capacity + 20% LVR",
      netIncome: 0,
      newmbc: currentmbc,
      nextDate: propertydata?.nextDate,
      totalAvailableFunds: 0,
      summaryData: {
        percentageCash: percentageCash,
        percentageEquity: percentageEquity,
        percentageLiquidAssets: 0,
        ExtraCash: 0,
        useCash: useCash,
        useEquity: useEquity,
        useExtraCash: useExtraCash,
        useLiquidAssets: useLiquidAssets,
        priceReduce: "N",
        propertyType: propertytype,
        region: region,
        regionNo: regionNo,
        stateName,
        PurchasePrice: newpurchasePrice,
        Deposit: newDeposit,
        StampDuty: newStampDuty,
        AcquisitionCost: newTotalAcquistonCost,
        TotalDepositRequired: TotalDepositRequired,
        TotalPurchaseCosts: newTotalPurchaseCosts,
        LoantoValueRatioLVR: LVR,
        TotalDebt: TotalDebt,
        TotalDepositEquity: 0,
        TotalDepositCash: 0,
        TotalDepositExtraCash: 0,
        TotalDepositLiquidAssets: 0,
        PropertyRentIncomeYear1: PropertyRentIncomeYear1,
        InterestPayments: 0,
        PrinciplePayments: 0,
        outgoingExpenses:
          PropertyRentIncomeYear1 > 0 ? PropertyRentIncomeYear1 * 0.325 : 0,
        outgoingExpensePercent: 0.325 * 100,
        CashflowAnnual: 0,
        CashflowMonthly: 0,
        AppliedGrowthRateannualPERC: annualGrowth,
        EquityGainPERCorDOLLAR: EquityGainPERCorDOLLAR,
        CashGainDOLLAR: CashGainDOLLAR,
        LMI: LMI,
        mortgageInsuranceApply: "N",
        interestRate: SettingsData.Assumptions.InterestRate,
        DebtInterestRate: SettingsData.Assumptions.DebtInterestRate,
        DebtPrincipleRate: SettingsData.Assumptions.DebtPrincipalRate,
        EquityInterestRate: SettingsData.Assumptions.EquityInterestRate,
        EquityPrincipleRate: SettingsData.Assumptions.EquityPrincipalRate,
        RiskFactoredYieldReturn: RiskFactorYieldReturn,
        TotalCostofLending: 0,
        ReturnonEquityCOC: 0,
      },
    };
    return updated_property_data;
  };
  const CalulateSummaryPropertiesFormula = (
    propertydata: TPropertyGeneratorItem,
    propertyno: number,
    selectedRegions: number[],
    data?: TSpendingSheet[],
    generated_properties?: TPropertyGeneratorItem[]
  ) => {
    if (propertyno === 0) {
      let updated_propertydata = CalculateFirstPropertyFormula(
        propertydata,
        propertyno,
        selectedRegions
      );
      return updated_propertydata;
    } else if (propertyno === 1 || propertyno === 2 || propertyno === 3) {
      let updated_propertydata = CalculateNextThreePropertyFormulas(
        propertydata,
        propertyno,
        selectedRegions,
        data,
        generated_properties
      );
      return updated_propertydata;
    } else {
      let updated_propertydata = CalculateRemainingPropertiesFormula(
        propertydata,
        propertyno,
        selectedRegions
      );
      return updated_propertydata;
    }
  };
  const GenerateSummaryTableData = ({
    properties,
  }: {
    properties: TPropertyGeneratorItem[];
  }) => {
    let RiskFactoredValues: number[] = [];
    let FeasibilityMarkers: IFeasibilityMarker[] = [];
    let PropertyTypeValues: string[] = [];
    let RegionValues: IFeasibilityMarker[] = [];
    let PurchasePriceValues: number[] = [];
    let Deposit: number[] = [];
    let StampDutyValues: number[] = [];
    let AcquisitionCost: number[] = [];
    let LMIValues: number[] = [];
    let TotalDepositRequired: number[] = [];
    let TotalPurchaseCosts: number[] = [];
    let FINANCE: string[] = [];
    let LVR: number[] = [];
    let AppliedInterestRate: string[] = [];
    let TotalDebt: number[] = [];
    let TotalDepositEquity: number[] = [];
    let TotalDepositCash: number[] = [];
    let CASHFLOW: string[] = [];
    let PropertyRentIncome: number[] = [];
    let TotalCostofLending: number[] = [];
    let CashflowAnnual: number[] = [];
    let CashflowMonthly: number[] = [];
    let ReturnonEquity: number[] = [];
    let GROWTHHACKING: string[] = [];
    let AppliedGrowthRateAnnual: string[] = [];
    let EquityGain: number[] = [];
    let CashGain: number[] = [];
    for (let i = 0; i < properties.length; i++) {
      RiskFactoredValues = [
        ...RiskFactoredValues,
        properties[i].summaryData.RiskFactoredYieldReturn,
      ];
      FeasibilityMarkers = [
        ...FeasibilityMarkers,
        {
          property_id: i + 1,
          data: properties[i].nextDate,
          state: properties[i].summaryData.stateName,
          name: `Property ${i + 1}`,
          isLocked: properties[i].isLocked,
        },
      ];
      PropertyTypeValues = [
        ...PropertyTypeValues,
        properties[i].summaryData.propertyType,
      ];
      RegionValues = [
        ...RegionValues,
        {
          property_id: 0,
          data: "",
          state: properties[i].summaryData.stateName,
          name: properties[i].summaryData.region,
          isLocked: properties[i].isLocked,
        },
      ];
      PurchasePriceValues = [
        ...PurchasePriceValues,
        properties[i].summaryData.PurchasePrice,
      ];
      Deposit = [...Deposit, properties[i].summaryData.Deposit];
      StampDutyValues = [
        ...StampDutyValues,
        properties[i].summaryData.StampDuty,
      ];
      AcquisitionCost = [
        ...AcquisitionCost,
        properties[i].summaryData.AcquisitionCost,
      ];
      LMIValues = [...LMIValues, properties[i].summaryData.LMI];
      TotalDepositRequired = [
        ...TotalDepositRequired,
        properties[i].summaryData.TotalDepositRequired,
      ];
      TotalPurchaseCosts = [
        ...TotalPurchaseCosts,
        properties[i].summaryData.TotalPurchaseCosts,
      ];
      FINANCE = [...FINANCE, ""];
      LVR = [...LVR, properties[i].summaryData.LoantoValueRatioLVR];
      AppliedInterestRate = [
        ...AppliedInterestRate,
        `${String(properties[i].summaryData.interestRate)}%`,
      ];
      TotalDebt = [...TotalDebt, properties[i].summaryData.TotalDebt];
      TotalDepositEquity = [
        ...TotalDepositEquity,
        properties[i].summaryData.TotalDepositEquity,
      ];
      TotalDepositCash = [
        ...TotalDepositCash,
        properties[i].summaryData.TotalDepositCash,
      ];
      CASHFLOW = [...CASHFLOW, ""];
      PropertyRentIncome = [
        ...PropertyRentIncome,
        properties[i].summaryData.PropertyRentIncomeYear1,
      ];
      TotalCostofLending = [
        ...TotalCostofLending,
        properties[i].summaryData.TotalCostofLending,
      ];
      CashflowAnnual = [
        ...CashflowAnnual,
        properties[i].summaryData.CashflowAnnual,
      ];
      CashflowMonthly = [
        ...CashflowMonthly,
        properties[i].summaryData.CashflowMonthly,
      ];
      ReturnonEquity = [
        ...ReturnonEquity,
        properties[i].summaryData.ReturnonEquityCOC,
      ];
      GROWTHHACKING = [...GROWTHHACKING, ""];
      AppliedGrowthRateAnnual = [
        ...AppliedGrowthRateAnnual,
        `${String(properties[i].summaryData.AppliedGrowthRateannualPERC)}`,
      ];
      EquityGain = [
        ...EquityGain,
        properties[i].summaryData.EquityGainPERCorDOLLAR,
      ];
      CashGain = [...CashGain, properties[i].summaryData.CashGainDOLLAR];
    }
    let tablerowvalues = {
      RiskFactoredValues,
      FeasibilityMarkers,
      PropertyTypeValues,
      RegionValues,
      PurchasePriceValues,
      Deposit,
      StampDutyValues,
      AcquisitionCost,
      LMIValues,
      TotalDepositRequired,
      TotalPurchaseCosts,
      FINANCE,
      LVR,
      AppliedInterestRate,
      TotalDebt,
      TotalDepositEquity,
      TotalDepositCash,
      CASHFLOW,
      PropertyRentIncome,
      TotalCostofLending,
      CashflowAnnual,
      CashflowMonthly,
      ReturnonEquity,
      GROWTHHACKING,
      AppliedGrowthRateAnnual,
      EquityGain,
      CashGain,
    };
    setsummaryRowValues(tablerowvalues);
    return { tablerowvalues };
  };
  const CalculateEquityAndCashDeposit = ({
    updated_property,
    data,
    properties,
  }: {
    updated_property: TPropertyGeneratorItem;
    data: TSpendingSheet[];
    properties: TPropertyGeneratorItem[];
  }) => {
    const previousPropertiesTotalDepositRequired =
      GS.Calculator.PropertyGenerator.reduce((partialSum, Item) => {
        return partialSum + Item.summaryData.TotalDepositRequired;
      }, 0);
    const previousPropertiesTotalEquityGains =
      GS.Calculator.PropertyGenerator.reduce((partialSum, Item) => {
        return partialSum + Item.summaryData.EquityGainPERCorDOLLAR;
      }, 0);
    const previousPropertiesTotalCashGains =
      GS.Calculator.PropertyGenerator.reduce((partialSum, Item) => {
        return partialSum + Item.summaryData.CashGainDOLLAR;
      }, 0);
    const totalDepositRequired =
      previousPropertiesTotalDepositRequired +
      updated_property.summaryData.TotalDepositRequired;
    let propertyPurhcasedata: TSpendingSheet = initialSpendingData;
    let previousCashDeposit = 0;
    let previousEquityDeposit = 0;
    let previousLiquidAssetsDeposit = 0;
    let previousExtraCashDeposit = 0;
    properties.map((item) => {
      previousCashDeposit =
        previousCashDeposit + item.summaryData.TotalDepositCash;
      previousEquityDeposit =
        previousEquityDeposit + item.summaryData.TotalDepositEquity;
      previousLiquidAssetsDeposit =
        previousLiquidAssetsDeposit + item.summaryData.TotalDepositLiquidAssets;
      previousExtraCashDeposit = item.summaryData.TotalDepositExtraCash;
    });
    let TotalLiquidAndExtraCash =
      previousExtraCashDeposit + previousLiquidAssetsDeposit;

    for (let index = 0; index < data.length; index++) {
      let item = data[index];

      if (
        item?.balanceSheet?.total +
          previousPropertiesTotalEquityGains +
          previousPropertiesTotalCashGains +
          TotalLiquidAndExtraCash >=
        totalDepositRequired
      ) {
        propertyPurhcasedata = item;
        break;
      }
    }

    let currentProperty = updated_property;
    const equityAvailable: number =
      propertyPurhcasedata.balanceSheet.EquityGain - previousEquityDeposit;

    const cashAvailable =
      propertyPurhcasedata.balanceSheet.cashOnHand.total -
        previousCashDeposit || 0;
    const liquidAssetsAvailable =
      CalData.Equity.LiquidAssets - previousLiquidAssetsDeposit || 0;
    let percentageEquity = 100;
    let percentageCash = 100;
    let useEquity: "Y" | "N" = "Y";
    let useCash: "Y" | "N" = "Y";
    let TotalDepositEquity =
      (equityAvailable * percentageEquity) / 100 >
      updated_property.summaryData.TotalDepositRequired
        ? updated_property.summaryData.TotalDepositRequired
        : (equityAvailable * percentageEquity) / 100;
    let LeftAfterEquity =
      updated_property.summaryData.TotalDepositRequired - TotalDepositEquity;
    let TotalDepositCash =
      (cashAvailable * percentageCash) / 100 > LeftAfterEquity
        ? LeftAfterEquity
        : (cashAvailable * percentageCash) / 100;
    percentageEquity = (TotalDepositEquity / equityAvailable) * 100;
    percentageCash = (TotalDepositCash / cashAvailable) * 100;
    if (percentageEquity > 0) {
      useEquity = "Y";
    } else {
      useEquity = "N";
    }
    if (percentageCash > 0) {
      useCash = "Y";
    } else {
      useCash = "N";
    }

    let equityInterestPayable =
      TotalDepositEquity *
      (Number(SettingsData?.Assumptions?.EquityInterestRate) / 100 || 0);
    let depositInterestPayable =
      currentProperty.summaryData.TotalDebt *
      (Number(SettingsData?.Assumptions?.DebtInterestRate) / 100 || 0);
    let equityPrincipalPayable =
      TotalDepositEquity *
      (Number(SettingsData?.Assumptions?.EquityPrincipalRate) / 100 || 0);
    let depositPrincipalPayable =
      currentProperty.summaryData.TotalDebt *
      (Number(SettingsData?.Assumptions?.DebtPrincipalRate) / 100 || 0);
    let TotalInterestPayable = equityInterestPayable + depositInterestPayable;
    let TotalPricipalPayable = equityPrincipalPayable + depositPrincipalPayable;

    // let TotalCostofLending =
    //   (currentProperty.summaryData.TotalDebt + TotalDepositEquity) *
    //   (SettingsData?.Assumptions?.InterestRate / 100);
    let TotalCostofLending = TotalInterestPayable + TotalPricipalPayable;
    let CashflowAnnual =
      currentProperty.summaryData.PropertyRentIncomeYear1 - TotalCostofLending;
    let CashflowMonthly = CashflowAnnual / 12;
    let ReturnonEquityCOC =
      (CashflowAnnual / currentProperty.summaryData.TotalDepositRequired) * 100;

    currentProperty = {
      ...currentProperty,
      nextDate: propertyPurhcasedata?.date,
      cashAvailable: cashAvailable,
      equityAvailable: equityAvailable,
      equityGain: propertyPurhcasedata?.balanceSheet?.EquityGain,
      liquidAssets: liquidAssetsAvailable,
      summaryData: {
        ...currentProperty.summaryData,
        TotalCostofLending: TotalCostofLending,
        CashflowAnnual: CashflowAnnual,
        CashflowMonthly: CashflowMonthly,
        ReturnonEquityCOC: ReturnonEquityCOC,
        useEquity: useEquity,
        useCash: useCash,
        TotalDepositEquity: TotalDepositEquity,
        TotalDepositCash: TotalDepositCash,
        InterestPayments: TotalInterestPayable,
        PrinciplePayments: TotalPricipalPayable,
        percentageEquity,
        percentageCash,
      },
    };
    return { updatedcurrentProperty: currentProperty };
  };
  const CalculatePropertyPurchaseDate = ({
    data,
    TotalDepositRequired,
  }: {
    data: TSpendingSheet[];
    TotalDepositRequired: number;
  }) => {
    const previousPropertiesTotalDepositRequired =
      GS.Calculator.PropertyGenerator.reduce((partialSum, Item) => {
        return partialSum + Item.summaryData.TotalDepositRequired;
      }, 0);
    const previousPropertiesTotalEquityGains =
      GS.Calculator.PropertyGenerator.reduce((partialSum, Item) => {
        return partialSum + Item.summaryData.EquityGainPERCorDOLLAR;
      }, 0);
    const previousPropertiesTotalCashGains =
      GS.Calculator.PropertyGenerator.reduce((partialSum, Item) => {
        return partialSum + Item.summaryData.CashGainDOLLAR;
      }, 0);
    const totalDepositRequired =
      previousPropertiesTotalDepositRequired + TotalDepositRequired;
    let propertyPurhcasedata: TSpendingSheet = initialSpendingData;
    for (let index = 0; index < data.length; index++) {
      let item = data[index];
      if (
        item?.balanceSheet?.total +
          previousPropertiesTotalEquityGains +
          previousPropertiesTotalCashGains >=
        totalDepositRequired
      ) {
        propertyPurhcasedata = item;
        break;
      }
    }
    return { propertyPurhcasedata };
  };

  const CalculateOnPurchaseMethodChanged = ({
    Properites,
    selected_regions,
    startAt = 0,
  }: {
    Properites?: TPropertyGeneratorItem[];
    selected_regions?: number[];
    startAt?: number;
  }) => {
    let properties: TPropertyGeneratorItem[] = [initialpropertydata];
    let existing_properties = [];
    let selectedRegions = CalData?.PortfolioSummary?.selectedRegions;
    if (selected_regions) {
      selectedRegions = selected_regions;
    }
    if (Properites && Properites?.length > 0) {
      existing_properties = Properites;
    } else {
      existing_properties = CalData?.PropertyGenerators;
    }
    properties[0].summaryData.RiskFactoredYieldReturn =
      CheckBlendedPropertyRiskProfile({
        PropertyType: CalData.RiskProfile.PropertyType,
        CalData: CalData,
        propertyno: 0,
      });
    const { data } = GenerateSpendingData(100, CalData);

    let generated_properties: TPropertyGeneratorItem[] = [];
    if (startAt !== 0) {
      generated_properties = existing_properties.slice(0, startAt);
      GS.Calculator.PropertyGenerator = generated_properties;
    }

    for (let i = startAt; i <= 20; i++) {
      if (i === 0) {
        const equityAvailable: number = data[0]?.balanceSheet?.EquityGain;
        const cashAvailable = data[0]?.balanceSheet?.cashOnHand?.total || 0;

        properties[0].summaryData.useExtraCash = "N";
        properties[0].summaryData.useLiquidAssets = "N";
        properties[0].cashAvailable = cashAvailable;
        properties[0].equityAvailable = equityAvailable;
        properties[0].equityGain = equityAvailable;
        let currentProperty = properties[0];
        currentProperty = {
          ...currentProperty,
          nextDate:
            getMonthName(new Date().getMonth()) +
            "-" +
            new Date().getFullYear(),
          summaryData: {
            ...currentProperty.summaryData,
            PurchasePrice:
              (Properites && Properites[0]?.summaryData?.PurchasePrice) || 0,
            EquityGainPERCorDOLLAR:
              (Properites &&
                Properites[0]?.summaryData?.EquityGainPERCorDOLLAR) ||
              0,
            CashGainDOLLAR:
              (Properites && Properites[0]?.summaryData?.CashGainDOLLAR) || 0,
            AppliedGrowthRateannualPERC:
              (Properites &&
                Properites[0]?.summaryData?.AppliedGrowthRateannualPERC) ||
              "0%",
          },
        };
        let updated_property_values: TPropertyGeneratorItem =
          CalulateSummaryPropertiesFormula(currentProperty, 0, selectedRegions);
        if (existing_properties[i]?.isLocked) {
          generated_properties = [
            ...generated_properties,
            existing_properties[i],
          ];
        } else {
          generated_properties = [
            ...generated_properties,
            updated_property_values,
          ];
        }

        GS.Calculator.PropertyGenerator = generated_properties;
      } else {
        let currentproperty = initialpropertydata;
        currentproperty.summaryData.RiskFactoredYieldReturn =
          CheckBlendedPropertyRiskProfile({
            PropertyType: CalData.RiskProfile.PropertyType,
            CalData: CalData,
            propertyno: i,
          });

        currentproperty.summaryData.PurchasePrice =
          (Properites && Properites[i]?.summaryData?.PurchasePrice) || 0;
        currentproperty.summaryData.EquityGainPERCorDOLLAR =
          (Properites && Properites[i]?.summaryData?.EquityGainPERCorDOLLAR) ||
          0;
        currentproperty.summaryData.CashGainDOLLAR =
          (Properites && Properites[i]?.summaryData?.CashGainDOLLAR) || 0;
        currentproperty.summaryData.AppliedGrowthRateannualPERC =
          (Properites &&
            Properites[i]?.summaryData?.AppliedGrowthRateannualPERC) ||
          "0%";

        let updated_property_values: TPropertyGeneratorItem =
          CalulateSummaryPropertiesFormula(
            currentproperty,
            i,
            selectedRegions,
            data,
            generated_properties
          );
        const { updatedcurrentProperty } = CalculateEquityAndCashDeposit({
          data: data,
          updated_property: updated_property_values,
          properties: generated_properties,
        });
        let currentPropertiesCashFlow = generated_properties?.reduce(
          (total, item) => {
            return (total += item.summaryData.CashflowAnnual);
          },
          0
        );
        if (
          currentPropertiesCashFlow >= CalData?.BorrowingCalculator?.income_goal
        ) {
          break;
        } else {
          if (existing_properties[i]?.isLocked) {
            const { propertyPurhcasedata } = CalculatePropertyPurchaseDate({
              data,
              TotalDepositRequired:
                existing_properties[i]?.summaryData?.TotalDepositRequired,
            });

            generated_properties = [
              ...generated_properties,
              {
                ...existing_properties[i],
                nextDate: propertyPurhcasedata.date,
              },
            ];
          } else {
            generated_properties = [
              ...generated_properties,
              updatedcurrentProperty,
            ];
          }

          GS.Calculator.PropertyGenerator = generated_properties;
        }
      }
    }
    let selectedregions: number[] = [];
    generated_properties.map((item) => {
      selectedregions.push(item.summaryData.regionNo);
    });
    let newcalobj = { ...CalData };
    newcalobj = {
      ...newcalobj,
      PortfolioSummary: {
        ...newcalobj.PortfolioSummary,
        selectedRegions: selectedregions,
      },
      PropertyGenerators: generated_properties,
    };

    dispatch(SetCalData(newcalobj));
    SaveCalData(newcalobj);
    // console.log(generated_properties,"generatedProperties")
    let { tablerowvalues } = GenerateSummaryTableData({
      properties: generated_properties,
    });
    return { tablerowvalues };
  };
  const ChangeExistingSummaryData = () => {};
  return {
    GenerateSummaryTableData,
    ChangeExistingSummaryData,
    GenerateTable,
    summaryRowValues,
    CalculateOnPurchaseMethodChanged,
  };
};
