import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store/store'
import { TsubItems } from 'types'
import { useNavigate, useLocation } from 'react-router-dom'
import { DeleteAuthToken } from 'config'
import { NavItemContainer, NavItem, Text, NavIcon, NavSubContainer } from 'global.style'
import { Image } from 'react-bootstrap'
import AnimateHeight from '../../AnimateHeight'
import { SubSidebarItem } from '../SubSidebarItem'
import PlusIcon from "assets/images/plus.svg";
import MinusIcon from "assets/images/minus.svg";
import { setPath } from 'store/Slices/PathSlice'
import { SetSubSidebar, SubSidebarItems } from 'store/Slices/SidebarSlice'
import { logout } from 'store/Slices/AuthSlice'
type SidebarItemProps = {
    item: TsubItems
}
export const SidebarItem = ({ item }: SidebarItemProps) => {
    const dispatch = useDispatch();
    const SidebarItems = useSelector((state: RootState) => state.sidebar.SubSidebar)
    const activeItem = useSelector((state: RootState) => state.path.SidebarPath)

    const ActiveVendor = useSelector((state: RootState) => state.calculator)
    const height = (SidebarItems as any)[item.name] ? 'auto':0;
    const location = useLocation();
    const mypath: string = location.pathname;
    const navigate = useNavigate();

    const handleNavigation = (link: string, name: string, header: string) => {
        let check = link?.includes("/Clients/");
        dispatch(setPath({ SidebarPath: name, headerPath: header }))
        if (check === true) {
            link && navigate(`${link}?id=${ActiveVendor.VendorId}`);
        } else {
            link && navigate(link);
        }
    }
    const handleLogOut = (link: string) => {
        if (link === "/logout") {
            sessionStorage.removeItem("Token");
            localStorage.removeItem("email")
            DeleteAuthToken();
            dispatch(logout());
            navigate("/signin");
        }
    };
    const handleSetSidebar = (item: string) => {
        let updatedSidebar: SubSidebarItems = { ...SidebarItems }
        for (const key of Object.keys(updatedSidebar)) {
            if (key === item) {
                (updatedSidebar as any)[key] = !(updatedSidebar as any)[key]
            } else {
                (updatedSidebar as any)[key] = false
            }
        }
        dispatch(SetSubSidebar(updatedSidebar))
       
    }
    if (item.subItems) {
        return (
            <>
                <NavItemContainer
                    onClick={() => item?.link === "/logout" ? handleLogOut(item.link) : handleNavigation(item.link, item.name, item.header)}
                    active={item.name === activeItem}
                    activeColor={item.activeColor}>
                    <NavItem
                        gap="16px"
                        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                            e.preventDefault();
                            let prop: string = item.name;
                            handleSetSidebar(prop)
                        }}
                    >
                        {item.icon && (
                            <NavIcon>
                                <Image src={item.icon} width="100%" />
                            </NavIcon>
                        )}
                        <Text fontSize='16px' color='var(--text-color)'>{item.name}</Text>
                    </NavItem>
                    {item.name === "Client Lifestyle Goals" &&
                        !location.pathname.includes("/Clients/") ? (
                        <></>
                    ) : (
                        <Image
                            src={(SidebarItems as any)[item.name] === false ? PlusIcon : MinusIcon} //open[item.name] === false ? PlusIcon : MinusIcon
                            onClick={(e) => {
                                e.stopPropagation();
                                handleSetSidebar(item.name)
                            }}
                        />
                    )}
                </NavItemContainer>
                <AnimateHeight height={height}>
                    <NavSubContainer className='flex flex-col gap-[7px]'>
                        {item.subItems.map((subitem, index) => (
                            <SubSidebarItem item={subitem} key={index} />
                        ))}
                    </NavSubContainer>
                </AnimateHeight>
            </>
        )
    } else {
        return (
            <NavItemContainer
                onClick={() => item?.link === "/logout" ? handleLogOut(item.link) : handleNavigation(item.link, item.name, item.header)}
                active={item.link === mypath}
                activeColor={item.activeColor}>
                <NavItem gap="16px">
                    {item.icon && (
                        <NavIcon>
                            <Image src={item.icon} width="100%" />
                        </NavIcon>
                    )}
                    <Text fontSize='16px' color='var(--text-color)'>{item.name}</Text>
                </NavItem>
            </NavItemContainer>
        )
    }
}
