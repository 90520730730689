import React from "react";
import { InputTexts } from "components/ui/InputTexts";
import { InputPassword } from "components/ui/InputPassword";
import { TUpdateUser } from "store/Slices/UserSlice";
import { TCreateContact } from "store/Slices/ContactSlice";

import { ContactDropDown } from "global.style";
import { RootState } from "store/store";
import { useSelector } from "react-redux";
import { ContactTypeOptions, AdminContactTypes } from "utils/constants";
import { InputMask } from "primereact/inputmask";
import InputPhone from "components/ui/InputPhone";
type ContactFormProps = {
  userdata: TCreateContact;
  setuserdata: React.Dispatch<React.SetStateAction<TCreateContact>>;
  isAdmin?: boolean;
};
export const ContactForm = ({
  userdata,
  setuserdata,
  isAdmin = false,
}: ContactFormProps) => {
  const Loginuser = useSelector((state: RootState) => state.userProfile.data);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setuserdata({ ...userdata, [e.target.name]: e.target.value });
  };
  return (
    <>
      <div className="flex flex-col gap-10 py-3 md:flex-row">
        <div className="flex flex-col grow">
          <p className="input-label">First name</p>
          <InputTexts
            classname="!mt-[5px]"
            height="40px"
            type={"text"}
            name="first_name"
            value={userdata?.first_name}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col grow">
          <p className="input-label">Last name</p>
          <InputTexts
            classname="!mt-[5px]"
            height="40px"
            type={"text"}
            name="last_name"
            value={userdata?.last_name}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col grow">
          <p className="input-label">Email</p>
          <InputTexts
            classname="!mt-[5px]"
            type={"email"}
            height="40px"
            name="email"
            value={userdata?.email}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="flex flex-col gap-10 py-3 md:flex-row ">
        <div className="flex flex-col grow">
          <p className="input-label">Mobile No</p>
          <InputPhone
            value={userdata?.mobile_no}
            onChange={(e) => {
              const value = e.target.value;
              if (value) {
                setuserdata({ ...userdata, mobile_no: value });
              }
            }}
            className="!min-w-[13.3rem] !mt-[5px]"
            name="mobile_no"
            id="mobile"
            mask="9999 999 999"
            placeholder=""
          />
        </div>
        {isAdmin ? (
          <>
             <div className="flex flex-col grow">
                <p className="input-label">Password</p>
                <InputPassword
                  outerclasses="!mt-[5px] "
                  height="40px"
                  type={"password"}
                  name="password"
                  value={userdata?.password || ""}
                  onChange={handleChange}
                />
              </div>
          </>
        ) : (
          <div className="flex flex-col grow">
          <p className="input-label">Membership Type</p>
          <ContactDropDown
          className={`!min-w-[13.5rem] !mt-[5px]`}
           
            height="40px"
            value={userdata.contact_type}
            onChange={(e) => {
              setuserdata({ ...userdata, contact_type: e.value });
            }}
            options={
              process.env.REACT_APP_ADMIN_EMAIL === Loginuser.email
                ? AdminContactTypes
                : ContactTypeOptions
            }
          />
        </div>
        )}
         <div className={`flex flex-col grow ${isAdmin && 'opacity-0'}`}>
                <p className="input-label">Password</p>
                <InputPassword
                  outerclasses="!mt-[5px] "
                  height="40px"
                  type={"password"}
                  name="password"
                  value={userdata?.password || ""}
                  onChange={handleChange}
                />
              </div>
       
      </div>
    </>
  );
};
