import React,{useEffect,useState} from 'react'
import { InnerContainer, Container } from 'global.style'
import { useGetDomainData } from 'hooks/useGetDomainData';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TabView, TabPanel } from "primereact/tabview";
import { Card, Col, Row } from "react-bootstrap";
import { commaStringSeparatorsWithDollors } from 'utils/helperFunctions';
import { ProgressSpinner } from 'primereact/progressspinner';
export const MedianPrices = () => {
  const [loading, setloading] = useState(false);
  const [ResidentialData, setResidentialData] = useState<any>([]);
  const [OfficeData, setOfficeData] = useState<any>([]);
  const [RetailData, setRetailData] = useState<any>([]);
  const { getSuburbStats } = useGetDomainData();
  const avgMedianPrice = (rowData:any) => {
    return (
      <div>
        {commaStringSeparatorsWithDollors(isNaN(rowData?.median) ? 0 : rowData?.median)}
      </div>
    );
  };
  const avgMedianRentalPrice = (rowData:any) => {
    return (
      <div>
        {commaStringSeparatorsWithDollors(
          isNaN(rowData?.medianRental) ? 0 : rowData?.medianRental
        )}
      </div>
    );
  };
  const GetSuburbs=async()=>{
    try{
      setloading(true)
      let data = await getSuburbStats();
      if(data){
        setloading(false)
        setResidentialData(data?.Calculator?.DomainData?.data?.residential || [])
        setOfficeData(data?.Calculator?.DomainData?.data?.office || [])
        setRetailData(data?.Calculator?.DomainData?.data?.retail || [])
      }else{
        setloading(false)
      }
    }catch(err){
      setloading(false)
    }
  }
  useEffect(()=>{
    GetSuburbs()
  },[])
  return (
    <Container>
      <InnerContainer>
        <div className="profile-container">
          <h1>Median Prices - Suburbs</h1>
        </div> 
        <div>
            <TabView className="!border-[0px]">
              <TabPanel header="Residential">
                <h1 className="mt-3 mb-3 card-title">Residential</h1>
                {loading ? (
                   <div className='flex justify-center w-full'>
                    <ProgressSpinner />
                   </div>
                ) : (
                  <DataTable
                    value={ResidentialData}
                    editMode="cell"
                    className="border editable-cells-table"
                   
                  >
                    <Column
                      header="Suburb"
                      field="name"
                      className='!border-b-[1px]'
                      style={{ width: "25%" }}
                    ></Column>
                    <Column
                      header="State"
                      field="state"
                       className='!border-b-[1px]'
                      style={{ width: "25%" }}
                    ></Column>
                    <Column
                      header="Median"
                      body={avgMedianPrice}
                      field="median"
                       className='!border-b-[1px]'
                      style={{ width: "35%" }}
                    ></Column>
                    <Column
                      header="Median Rental Price"
                      body={avgMedianRentalPrice}
                      field="Median_Rental_Price"
                       className='!border-b-[1px]'
                      style={{ width: "35%" }}
                    ></Column>
                    <Column
                      header="Growth"
                      field="growth_percent"
                       className='!border-b-[1px]'
                      style={{ width: "35%" }}
                    ></Column>
                    <Column
                      header="Postcode"
                      field="postcode"
                       className='!border-b-[1px]'
                      style={{ width: "35%" }}
                    ></Column>
                  </DataTable>
                )}
              </TabPanel>
              <TabPanel header="Retail">
                <h1 className="mt-3 mb-3 card-title">Retail</h1>
                {loading ? (
                   <div className='flex justify-center w-full'>
                   <ProgressSpinner />
                  </div>
                ) : (
                  <DataTable
                    value={RetailData}
                    editMode="cell"
                    className="border editable-cells-table"
                   
                  >
                    <Column
                      header="Suburb"
                      field="name"
                       className='!border-b-[1px]'
                      style={{ width: "25%" }}
                    ></Column>
                    <Column
                      header="State"
                      field="state"
                       className='!border-b-[1px]'
                      style={{ width: "25%" }}
                    ></Column>
                    <Column
                      header="Median"
                      body={avgMedianPrice}
                      field="median"
                       className='!border-b-[1px]'
                      style={{ width: "35%" }}
                    ></Column>
                    <Column
                      header="Median Rental Price"
                      body={avgMedianRentalPrice}
                      field="Median_Rental_Price"
                       className='!border-b-[1px]'
                      style={{ width: "35%" }}
                    ></Column>
                    <Column
                      header="Growth"
                      field="growth_percent"
                       className='!border-b-[1px]'
                      style={{ width: "35%" }}
                    ></Column>
                    <Column
                      header="Postcode"
                      field="postcode"
                       className='!border-b-[1px]'
                      style={{ width: "35%" }}
                    ></Column>
                  </DataTable>
                )}
              </TabPanel>
              <TabPanel header="Offices">
                <h1 className="mt-3 mb-3 card-title">Offices</h1>
                {loading ? (
                   <div className='flex justify-center w-full'>
                   <ProgressSpinner />
                  </div>
                ) : (
                  <DataTable
                    value={OfficeData}
                    editMode="cell"
                    className="border editable-cells-table"
                   
                  >
                    <Column
                      header="Suburb"
                      field="name"
                       className='!border-b-[1px]'
                      style={{ width: "25%" }}
                    ></Column>
                    <Column
                      header="State"
                       className='!border-b-[1px]'
                      field="state"
                      style={{ width: "25%" }}
                    ></Column>
                    <Column
                      header="Median"
                      body={avgMedianPrice}
                      field="median"
                       className='!border-b-[1px]'
                      style={{ width: "35%" }}
                    ></Column>
                    <Column
                      header="Median Rental Price"
                      body={avgMedianRentalPrice}
                      field="Median_Rental_Price"
                       className='!border-b-[1px]'
                      style={{ width: "35%" }}
                    ></Column>
                    <Column
                      header="Growth"
                      field="growth_percent"
                       className='!border-b-[1px]'
                      style={{ width: "35%" }}
                    ></Column>
                    <Column
                      header="Postcode"
                      field="postcode"
                       className='!border-b-[1px]'
                      style={{ width: "35%" }}
                    ></Column>
                  </DataTable>
                )}
              </TabPanel>
            </TabView>
          </div>
      </InnerContainer>
    </Container>
  )
}
