import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type AutoSaveData = {
   AutoSave:boolean,
}
const initialState:AutoSaveData={
    AutoSave:false
}

const AutoSaveSlice=createSlice({
    name:'autosave',
    initialState,
    reducers:{
        setAutoSave:(state,action:PayloadAction<AutoSaveData>) => {
        state.AutoSave=action.payload.AutoSave
        }
    }
})

export const {setAutoSave} = AutoSaveSlice.actions

export default AutoSaveSlice.reducer