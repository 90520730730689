import React from 'react'
import { Container,InnerContainer } from 'global.style'
import { LaunchingSoon } from 'components/screen-components/LaunchingSoon'
export const Vendors = () => {
  return (
    <Container margin='0px' padding='0px' minHeight='100vh'>
    <InnerContainer margin='0px' padding='0px' bg={true}>
    <LaunchingSoon/>
    </InnerContainer>
  </Container>
  )
}
