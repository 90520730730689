import React from 'react'
import { Paginator } from 'primereact/paginator'
export const CustomPaginator = ({totalRecords,Filters,FilterParamsData,setFilterParamsData,totalPages}:any) => {
    const onPageChange = (page:any) => {
        if (page == "Firstpage") {
          setFilterParamsData({ ...FilterParamsData, Page: 1 });
        } else if (page == "Nextpage") {
          setFilterParamsData({
            ...FilterParamsData,
            Page: FilterParamsData.Page + 1,
          });
        } else if (page == "Previouspage") {
          setFilterParamsData({
            ...FilterParamsData,
            Page: FilterParamsData.Page - 1,
          });
        } else if (page == "Lastpage") {
          setFilterParamsData({ ...FilterParamsData, Page: totalPages });
        }
      }; 
    const template1 = {
        layout:
          "FirstPageLink PrevPageLink CurrentPageReport  NextPageLink LastPageLink",
        FirstPageLink: (options:any) => {
          return (
            <button
              type="button"
              className="mr-2 pagination-button"
              disabled={Filters.Page == 1}
              onClick={() => onPageChange("Firstpage")}
            > 
              <span className="p-3">{"<<"}</span>
            </button>
          );
        },
        LastPageLink: (options:any) => {
          return (
            <button
              type="button"
              className="mr-2 pagination-button"
              disabled={Filters.Page == totalPages}
              onClick={() => onPageChange("Lastpage")}
            >
              <span className="p-3">{">>"}</span>
            </button>
          );
        },
        PrevPageLink: (options:any) => {
          return (
            <button
              type="button"
              className="mr-2 pagination-button"
              disabled={Filters.Page == 1}
              onClick={() => onPageChange("Previouspage")}
            >
              <span className="p-3">{"<"}</span>
            </button>
          );
        },
        NextPageLink: (options:any) => {
          return (
            <button
              type="button"
              className="mr-2 pagination-button"
              disabled={Filters.Page == totalPages}
              onClick={() => onPageChange("Nextpage")}
            >
              <span className="p-3">{">"}</span>
            </button>
          );
        },
        CurrentPageReport: (options:any) => {
          return <p className="mr-2">{`(${Filters.Page} of ${totalPages})`}</p>;
        },
      };
  return (
    <Paginator
              rows={FilterParamsData.PerPage}
              totalRecords={totalRecords}
              template={template1}
            />
  )
}
