import React, { useState, useRef } from "react";
import { Toast } from "primereact/toast";
import { GradientButton } from "components/ui/Buttons/GradientButton";
import { Loading } from "components/ui/Loading";
import AnimateHeight from "components/screen-components/AnimateHeight";

const SwotProfile = ({
  rowData,
  editingRows,
  setCurrentEditField,
  seteditingRows,
}: {
  rowData: any;
  editingRows: any;
  setCurrentEditField: any;
  seteditingRows: any;
}) => {
  const [profile, setprofile] = useState(rowData?.SWOT || "");
  const [loading, setloading] = useState(false);
  const [open, setOpen] = useState(false);
  const height = open ? "auto" : 0;
  const toast = useRef<any>(null);
  const showSuccess = (Message: any) => {
    toast.current.show({
      severity: "warn",
      life: 2000,
      content: (
        <div
          className="flex flex-column "
          style={{ paddingTop: "0px", paddingBottom: "0px", width: "100%" }}
        >
          <div className="text-center ">
            <p className="popup-warn-text">{Message}</p>
          </div>
        </div>
      ),
    });
  };
  const onGetDataComplete = (rowData: any, newValue: any, field: any) => {
    const existingRowIndex = editingRows?.findIndex(
      (item: any) => item._id === rowData.id
    );
    rowData[field] = newValue;
    setCurrentEditField({
      fieldname: field,
      oldvalue: "",
      newvalue: newValue,
      propertyId: rowData.propertyId,
    });
    if (existingRowIndex !== -1) {
      const updatedRows = [...editingRows];

      updatedRows[existingRowIndex] = {
        ...updatedRows[existingRowIndex],
        [field]: newValue,
      };

      seteditingRows(updatedRows);
    } else {
      seteditingRows((prevEditingRows: any) => [...prevEditingRows, rowData]);
    }
  };
  const GetSWOTData = async () => {
    setloading(true);
    try {
      await fetch(
        `${process.env.REACT_APP_API_BASEURL}chat-gpt/suburb?suburb=${rowData.Suburb}&postcode=${rowData.Postcode}&state=${rowData.State}&swot=1`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("Token")}`,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data?.gptData) {
            onGetDataComplete(rowData, data.gptData, "SWOT");
            setprofile(data.gptData);
          } else {
            setloading(false);
            showSuccess(data?.message);
          }
        });
    } catch (err) {}
  };

  return (
    <>
      {profile.length == 0 ? (
        <>
          <div style={{ display: "flex", gap: "2px", alignItems: "center" }}>
            <GradientButton
              onClick={GetSWOTData}
              icon={undefined}
              classes="text-sm"
              style={{ width: "100px", height: "36px" }}
              txt="Generate AI"
            />
            {loading == true && (
              <div className="px-1">
                <Loading height="0" />
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <AnimateHeight height={height} minheight={"50px"}>
            <button
              className="overflow-hidden text-left whitespace-normal bg-transparent border-none cursor-pointer text-ellipsis"
              onClick={() => setOpen(!open)}
            >
              {profile}
            </button>
          </AnimateHeight>
        </>
      )}
    </>
  );
};

export default SwotProfile;
