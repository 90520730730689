import React, { useState } from "react";
import { Slider } from "primereact/slider";
import { CustomRiskSliderContainer } from "global.style";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/store";
import { SetCalData, SaveCalData } from "store/Slices/CalculatorSlice";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
export const RiskSlider = () => {
  const userCaldata = useSelector((state: RootState) => state.calculator);
  const lockedProperties = userCaldata.PropertyGenerators.filter((item)=>item.isLocked)
  const [sliderVal, setSliderVal] = useState<number>(
    Number(userCaldata.RiskProfile.NetYieldonProperty || 5)
  );

  const [progressColor, setProgessColor] = useState<string>(
     userCaldata?.RiskProfile?.PropertyMode === "Conservative"
      ? "#00a779"
      : userCaldata?.RiskProfile?.PropertyMode === "Moderate"
      ? "#fee468"
      : "#cd4a5e"
  );

  const dispatch = useDispatch();
  const handleMode = async (propertyMode: string) => {
    let newcaldata = { ...userCaldata };
    let NetYieldProperty = userCaldata?.RiskProfile?.NetYieldonProperty;
    let PropertyType = userCaldata?.RiskProfile?.PropertyType;
    if (propertyMode === "Conservative") {
      NetYieldProperty = 5;
      PropertyType = "Residential";
      Number(userCaldata?.RiskProfile?.NetYieldonProperty || 5);
    } else if (propertyMode === "Moderate") {
      NetYieldProperty = 6;
      PropertyType = "Commercial - Office";
    } else {
      NetYieldProperty = 7;
      PropertyType = "Commercial - Retail";
    }
    if(userCaldata?.RiskProfile?.PropertyType==="Blended"){
      PropertyType =userCaldata?.RiskProfile?.PropertyType
    }
    newcaldata = {
      ...newcaldata,
      RiskProfile: {
        ...newcaldata.RiskProfile,
        PropertyMode: propertyMode,
        PropertyType: PropertyType,
        NetYieldonProperty: NetYieldProperty,
      },
      PortfolioSummary:{
        ...newcaldata.PortfolioSummary,
        selectedRegions:[]
      },
      PropertyGenerators:[]
    };
    dispatch(SetCalData(newcaldata));
    SaveCalData(newcaldata);
  };
  const handleRiskVal = async (value: number) => {
    let newcaldata = { ...userCaldata };
    let propertyMode = userCaldata?.RiskProfile?.PropertyMode;
    if (value === 7) {
      propertyMode = "Aggressive";
    } else if (value < 7 && value >= 6) {
      propertyMode = "Moderate";
    } else {
      propertyMode = "Conservative";
    }
    newcaldata = {
      ...newcaldata,
      RiskProfile: {
        ...newcaldata.RiskProfile,
        PropertyMode: propertyMode,
        NetYieldonProperty: value,
      },
      PortfolioSummary:{
        ...newcaldata.PortfolioSummary,
        selectedRegions:[]
      },
      PropertyGenerators:[]
    };
    if(lockedProperties?.length>0){
      NotificationManager.error("Some of Properties are locked, Kindly unlock to change risk profile", "", 2000);
    }else{
      dispatch(SetCalData(newcaldata));
      SaveCalData(newcaldata);
    }
   
  };
  const SetColor = (value: number) => {
    if (value === 7) {
      setProgessColor("#cd4a5e");
    } else if (value < 6 && value >= 5) {
      setProgessColor("#00a779");
    } else if (value < 7 && value >= 6) {
      setProgessColor("#fee468");
    }
  };
  return (
    <div className="relative !mt-[60px]">
      <CustomRiskSliderContainer
        color={
            userCaldata?.RiskProfile?.PropertyMode === "Conservative"
      ? "#00a779"
      : userCaldata?.RiskProfile?.PropertyMode === "Moderate"
      ? "#fee468"
      : "#cd4a5e"
        }
        RiskVal={Number(userCaldata?.RiskProfile?.NetYieldonProperty)}
      >
        <Slider
          value={userCaldata?.RiskProfile?.NetYieldonProperty}
          onChange={(e) => {
            if(lockedProperties?.length>0){
              NotificationManager.error("Some of Properties are locked, Kindly unlock to change risk profile", "", 2000);
            }else{
              setSliderVal(Number(e.value));
              SetColor(Number(e.value));
              handleRiskVal(Number(e.value));
            }
           
          }}
          step={0.1}
          min={5}
          max={7}
        />
        <div className="mt-[30px] progressbar-container">
          <div className="progress-txt-container !-ml-[2.6rem]">
            <div
              className="progress line1"
              style={{
                backgroundColor:
                userCaldata?.RiskProfile?.NetYieldonProperty >= 5 && userCaldata?.RiskProfile?.NetYieldonProperty < 6 ? "#00a779" : "#e0e0e0",
              }}
            ></div>
            <p
              className="progress-Text text1"
              style={{
                color: userCaldata?.RiskProfile?.NetYieldonProperty >= 5 && userCaldata?.RiskProfile?.NetYieldonProperty < 6 ? "#00a779" : "#e0e0e0",
              }}
              onClick={() => {
                if(lockedProperties?.length>0){
                  NotificationManager.error("Some of Properties are locked, Kindly unlock to change risk profile", "", 2000);
                }else{
                  setSliderVal(5)
                  SetColor(5);
                  handleMode("Conservative");
                }
               
               
              }}
            >
              Conservative
            </p>
          </div>
          <div className="progress-txt-container progresstxt2 !mr-[0.9rem]">
            <div
              className="progress line2"
              style={{
                backgroundColor:
                userCaldata?.RiskProfile?.NetYieldonProperty >= 6 && userCaldata?.RiskProfile?.NetYieldonProperty < 7 ? "#fee468" : "#e0e0e0",
              }}
            ></div>
            <p
              className="progress-Text text2"
              style={{
                color: userCaldata?.RiskProfile?.NetYieldonProperty >= 6 && userCaldata?.RiskProfile?.NetYieldonProperty < 7 ? "#fee468" : "#e0e0e0",
              }}
              onClick={() => {
                if(lockedProperties?.length>0){
                  NotificationManager.error("Some of Properties are locked, Kindly unlock to change risk profile", "", 2000);
                }else{
                  setSliderVal(6)
                  SetColor(6);
                  handleMode("Moderate");
                }
                
              }}
            >
              Moderate
            </p>
          </div>
          <div className="progress-txt-container progresstxt3 !-mr-[2.1rem]">
            <div
              className="progress line3"
              style={{
                backgroundColor: userCaldata?.RiskProfile?.NetYieldonProperty === 7 ? "#cd4a5e" : "#e0e0e0",
              }}
            ></div>
            <p
              className="progress-Text text3"
              style={{
                color: userCaldata?.RiskProfile?.NetYieldonProperty === 7 ? "#cd4a5e" : "#e0e0e0",
              }}
              onClick={() => {
                if(lockedProperties?.length>0){
                  NotificationManager.error("Some of Properties are locked, Kindly unlock to change risk profile", "", 2000);
                }else{
                  setSliderVal(7)
                  SetColor(7);
                  handleMode("Aggressive");
                }
               
              }}
            >
              Aggressive
            </p>
          </div>
        </div>
      </CustomRiskSliderContainer>
      <NotificationContainer />
    </div>
  );
};
