import React from 'react'
import { TsubItems } from 'types'
import { NavItemContainer,NavItem,Text } from 'global.style'
import { RootState } from 'store/store'
import { useSelector,useDispatch } from 'react-redux'
import { Image } from 'react-bootstrap'
import PlusIcon from "assets/images/plus.svg";
import MinusIcon from "assets/images/minus.svg";
import AnimateHeight from '../../AnimateHeight';
import { SubSidebarItems,SetSubSidebar } from 'store/Slices/SidebarSlice'
import { useNavigate } from 'react-router-dom'
import { setPath } from 'store/Slices/PathSlice'

type SubSidebarItemProps = {
    item:TsubItems
}
export const SubSidebarItem = ({item}:SubSidebarItemProps) => {
    const activeItem = useSelector((state: RootState) => state.path.SidebarPath)
    const SidebarItems = useSelector((state:RootState) => state.sidebar.SubSidebar)
    const currentUserCalData = useSelector(
      (state: RootState) => state.calculator
    );
    const height = (SidebarItems as any)[item.name] ? "auto" : 0;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleSetSidebar = (item: string) => {
      let updatedSidebar: SubSidebarItems = { ...SidebarItems }
      for (const key of Object.keys(updatedSidebar)) {
          if (key === item) {
              (updatedSidebar as any)[key] = true
          } else {
              (updatedSidebar as any)[key] = false
          }
      }
      dispatch(SetSubSidebar(updatedSidebar))
     
  }
  const handleNavigate=(link:string,name: string, header: string)=>{
    let ClientLinkCheck = link.includes("/Clients/");
    dispatch(setPath({ SidebarPath: name, headerPath: header }))
    if(ClientLinkCheck){
      if(currentUserCalData._id===""){
        navigate('/Clients');
      }else{
        navigate(link);
      }
      
    }else{
      navigate(link);
    }
  }
  return (
    <div>
        <NavItemContainer
        onClick={(e:React.MouseEvent<HTMLDivElement, MouseEvent>)=>handleNavigate(item.link, item.name, item.header)}
        active={item.name === activeItem}
        activeColor={item.activeColor}
        className='!mb-[1px]' marginTop="2px" height="35px">
        <NavItem>
        <Text fontSize='14px' color='var(--text-color)'>{item.name}</Text>
        </NavItem>
        {item.subItems && (
          <Image
            src={(SidebarItems as any)[item.name] === false ? PlusIcon : MinusIcon} 
            onClick={(e:React.MouseEvent<HTMLImageElement, MouseEvent>) => {
              e.stopPropagation();
              handleSetSidebar(item.name)
            }}
          />
        )}

        </NavItemContainer>
        <AnimateHeight  height={height}>
        <div>
          {item.subItems &&
            item.subItems.map((subitem, index) => (
              <SubSidebarItem item={subitem} key={index} />
            ))}
        </div>
      </AnimateHeight>
    </div>
  )
}
