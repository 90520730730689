import React, { useState, useRef, useEffect } from "react";
import { UploadPropertiesFile } from "services/api";
import { GetCSVListings } from "services/api";
import { Loading } from "components/ui/Loading";
import { CustomPaginator } from "components/screen-components/Pinder/CustomPaginator";
// @ts-ignore
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import CSVPropertiesTable from "components/screen-components/CSVProperties/CSVPropertiesTable";
import { CSVProperties } from "types";
export const UploadCSV = () => {
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef<any>();
  const [Filters, setFilters] = useState({
    Page: 1,
    perPage: 50,
  });
  const [TotalRecords, setTotalRecords] = useState(0);
  const [tableData, settableData] = useState<CSVProperties[]>([]);
  const [isLoading, setisLoading] = useState<boolean>(false);
  let totalPages = Math.ceil((TotalRecords || 0) / Filters.perPage);
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      const file = event.target.files?.[0];
      if (
        file &&
        (file.type === "text/csv" ||
          file.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
      ) {
        const formData = new FormData();
        formData.append("csvdata", file);
        setLoading(true);
        let response = await UploadPropertiesFile(formData);
        if (response?.message) {
          if (response?.message?.includes("Network")) {
            NotificationManager.error(response?.message, "", 2000);
          } else {
            NotificationManager.success(response?.message, "", 2000);
          }

          setLoading(false);
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
          GetData();
        } else {
          NotificationManager.error("File not uploaded", "", 2000);
          setLoading(false);

          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        }
      } else {
        NotificationManager.error("File type not supported", "", 2000);
        setLoading(false);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      }
    } catch (err) {
      NotificationManager.error("Something went worng", "", 2000);
      setLoading(false);
    }
  };
  const GetData = async () => {
    setisLoading(true);
    let response = await GetCSVListings({
      page: Filters.Page,
      perPage: Filters.perPage,
    });
    if (response.data) {
      setisLoading(false);
      const { data, pagination } = response;
      setTotalRecords(pagination.total);
      settableData(data);
      console.log(response, "response");
    }
  };
  useEffect(() => {
    GetData();
  }, [Filters]);
  return (
    <div className="flex flex-col items-center justify-center min-h-screen gap-10 p-4 bg-gray-100">
      <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
        <h2 className="mb-4 text-2xl font-bold text-center">Upload CSV File</h2>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileUpload}
          className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none p-2.5 mb-4"
        />
        {loading && <Loading height="60px" />}
      </div>

      {isLoading ? (
        <div className="flex items-center justify-center h-full">
          <Loading />
        </div>
      ) : (
        <div className="flex flex-col w-full">
          <CSVPropertiesTable TableData={tableData} rows={Filters.perPage} />
          <CustomPaginator
            totalRecords={TotalRecords}
            Filters={Filters}
            FilterParamsData={Filters}
            setFilterParamsData={setFilters}
            totalPages={totalPages}
          />
        </div>
      )}

      <NotificationContainer />
    </div>
  );
};
