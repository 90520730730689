import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import url from "../../config";
import type { PayloadAction } from "@reduxjs/toolkit";
import { TAllContacts } from "types";
export type TUserData = {
    full_name?:string,
    first_name:string,
    last_name: string,
    email: string,
    mobile_no: string, 
    logos?: string,
    photo?:string,
    telephone_no:string,
    contact_type:string,
    contact_address?:string,
    password?:string;
};
type GetUserResponse = {
  contact_type:string,
  status:boolean,
  message:string,
  data:TUserData
}
export type TUpdateUser = {
  first_name: string,
  last_name: string,
  email: string,
  mobile_no: string,
  telephone_no: string,
  photo?: string,
  contact_type?:string,
  contact_address?:string,
  password?:string
}
export type PasswordProps = {
  oldpassword:string,
  password:string
}
export type InitialData = {
  data:TUserData,
  BasiqUserId:string,
  selectedUser:TAllContacts
}
export type TBasicUser = {
  email:string,
  mobile:string,
  firstName:string,
  middleName:string,
  lastName:string,
  url:string
}
export const GetUserData=createAsyncThunk('contacts/getbyid',async(id:string)=>{
    try {
        let response = await url.get(`contacts/getbyid/${id}`);
        return response.data;
      } catch (err) {
        return err;
      }
})
export const ChangePassword= async(props:PasswordProps)=>{
  try {
    let response = await url.post(`auth/change-password`,props);
    return response.data;
  } catch (err) {
    return err;
  }
}
export const UpdateAdmin= async(body:TUpdateUser,officeId:string)=>{
  try {
    let response = await url.put(`/offices/${officeId}`,body);
    return response.data;
  } catch (err) {
    return err;
  }
}
export const UpdateUser=async(userId:string,body:TUpdateUser)=>{
  try {
    let response = await url.put(`contacts/${userId}`,body);
    return response.data;
  } catch (err) {
    return err;
  }
}
export const GetOfficeData=createAsyncThunk('',async(officeid:string)=>{
  try{
    let response = await url.get(`offices/${officeid}`);
    return response.data;
  }catch(err){
    return err;
  }
})
export const GetAllContact=async(officeid:string)=>{
  try{
    let response = await url.get(`contacts?office_id=${officeid}`);
    return response.data;
  }catch(err){
    return err; 
  }
}
export const GetAllUsers=async()=>{
  try{
    let response = await url.get(`users`);  //?role=610a0f1dc61edfcf62d62bb1 for just admins
    return response.data;
  }catch(err){
    return err; 
  }
}
export const DeleteUser = async(id:string) =>{
  try{
    let response = await url.delete(`contacts/${id}`);
    return response.data;
  }catch(err){
    return err;
  }
}
export const GetUserById= async(id:string)=>{
  try {
      let response = await url.get(`contacts/getbyid/${id}`);
      return response.data;
    } catch (err) {
      return err;
    }
}
export const CreateBasiqUser=async(body:TBasicUser)=>{
  let token:any=sessionStorage.getItem('Token')
  const headers={
    Authorization:`Bearer ${token}`,
    'Content-Type':'application/json'
  }
  try {
    let result= await fetch(`${process.env.REACT_APP_API_BASEURL}all-url-basiq`,
      {
        method:'POST',
        headers:headers,
        body:JSON.stringify(body)
      }
      )
      .then((response)=>response.json())
      .then((data)=>data.data)
    return result.data;
  } catch (err) {
    return err;
  }
}
export const GetGoogleAddress = async(address:string)=>{
  try {
  let response = await fetch(
      `https://api.domain.com.au/v1/properties/_suggest?terms=${address}&channel=${"All"}&api_key=${
        process.env.REACT_APP_ADDRESS_APIKEY
      }`,
      {
        method: "GET",
      }
    ).then((response) => response.json())
      .then((data) => {
        return data
      });
      return response
  } catch (error) {
    console.log(error);
  }
}
export const getAutoValProperty = async (address:string) => {
  try{
    let token = sessionStorage.getItem("Token");
    let data = { address: address };
    let body = JSON.stringify(data);
  let response = await fetch(`${process.env.REACT_APP_API_BASEURL}domain/priceEstimation`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    })
      .then((response) => response.json())
      .then((data) => {
        let date = "";
        if (data.date == undefined || data.date == null || data.date == "") {
        } else {
          date = data.date.split("T")[0];
        }
        
        if(data.status) {
         return { 
          DomainAutoVal: data.midPriceEstimation,
          PurchaseDate: date,
          status:true
        }
        }else{
          return null
        }
      });
      return response
  }catch(err){

  }
};
const initialState: InitialData = {
  BasiqUserId:"",
    data:{
      full_name:"",
    first_name:"",
    last_name:"",
    email:"",
    mobile_no:"",
    logos:"",
    photo:"",
    telephone_no:"",
    contact_type:""
    },
    selectedUser:{
      Progress: [],
  contact_address: "",
  contact_type: "",
  email: "",
  first_name: "",
  full_name: "",
  isPrivate: false,
  last_name: "",
  mobile_no: "",
  photo: "",
  telephone_no: "",
  user_id: "",
  _id: "",
    }
};
const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<TUserData>) => {
      state.data = action.payload;
    },
    setBasiqUserId:(state,action:PayloadAction<string>)=>{
      state.BasiqUserId = action.payload
    },
    setSelectedUser:(state,action:PayloadAction<TAllContacts>)=>{
      state.selectedUser = action.payload
    }
  },
  extraReducers:(builder) => {
    builder.addCase(GetUserData.fulfilled,(state,action: PayloadAction<GetUserResponse>)=>{
        const {data}=action.payload
        state.data=data
    })
    builder.addCase(GetOfficeData.fulfilled,(state,action)=>{
      const {first_name,last_name,email,mobile_no,logos,full_name,telephone_no} = action.payload.data
      return {
        ...state,
        full_name: full_name,
        email: email,
        first_name: first_name,
        mobile_no: mobile_no,
        logos: logos,
        last_name: last_name,
        photo: logos,
        telephone_no: telephone_no,
      };
      
    })
  },
});
export const { setUserData,setBasiqUserId,setSelectedUser } = UserSlice.actions;
export default UserSlice.reducer;
