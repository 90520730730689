import React, { memo, useState, useEffect } from "react";
import { CustomTimeLine } from "global.style";
import classes from "stylesheet.module.css";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/store";
import pic1 from "assets/images/peoplePurpleActive.png";
import pic2 from "assets/images/home-alt.png";
import pic3 from "assets/images/housesPurple.png";
import pic4 from "assets/images/profits.png";
import { useNavigate} from "react-router-dom";
import { setPath } from "store/Slices/PathSlice";
import {
  SaveCalData,
  TCalculatorData,
  GetUserCalData,
  SetCalData
} from "store/Slices/CalculatorSlice";
type TimeLineProps = {
  id: string;
  currentActive:string
};
type TimeLineEvents = {
  txt: string;
  tab: number;
  txtIcon: string;
  txtIconClasses?: string;
  link: string;
  pageName: string;
  header:string
};
export const TimeLine = memo(({ id,currentActive="Profile" }: TimeLineProps) => {
  const userAllow = useSelector((state:RootState)=>state.contacts.UserAllow)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUserCalData = useSelector(
    (state: RootState) => state.calculator
  );
  const events: TimeLineEvents[] = [
    {
      txt: "Profile",
      pageName: "Profile",
      tab: 1,
      txtIcon: pic1,
      link: "/Clients/Profile/",
      header: "/Clients/Profile",
    },
    {
      txt: "Finance",
      pageName: "Finances",
      tab: 2,
      txtIcon: pic1, 
      link: "/Clients/Finances/",
      header: "/Clients/Finances",
    },
    {
      txt: !userAllow?"Overview": "Spending",
      pageName: "Spending",
      tab: 3,
      txtIcon: pic2,
      link: "/Clients/Spending/",
      header: "/Clients/Spending",
    },
    {
      txt: "Summary",
      pageName: "Summary",
      tab: 4,
      txtIcon: pic3,
      txtIconClasses: "!w-[30px] !h-[30px]",
      link: "/Clients/Summary/",
      header: "/Clients/Portfolio Summary",
    },
    {
      txt: "Portfolio Plan",
      pageName: "IncomePlan",
      tab: 5,
      txtIcon: pic4,
      txtIconClasses: "!w-[25px] !h-[25px]",
      link: "/Clients/IncomePlan/",
      header: "/Clients/Income Plan",
    },
  ];

  const customizedMarker = (item: TimeLineEvents) => {
    return (
      <>
        <span
          className={`flex justify-center items-center rounded-[50px] ${
            item.tab !== 5 ? "w-[35px]" : "w-[30px]"
          } h-[30px] ${
            item.tab <= currentUserCalData.Progress.completed
              ? "bg-[var(--main-blue)]"
              : "border-[2px] border-blue-500"
          } `}
        >
          {item.tab <= currentUserCalData.Progress.completed ? (
            <i className="pi pi-check text-[white] text-[12px]"></i>
          ) : (
            <span>{item.tab}</span>
          )}
        </span>
        {item.tab !== 5 && (
          <div
            className={`h-[2px] w-[100%] ${
              item.tab <= currentUserCalData.Progress.completed
                ? "bg-[var(--main-blue)]"
                : "bg-[#ccc]"
            } `}
          ></div>
        )}
      </>
    );
  };
  const customizedContent = (item: TimeLineEvents) => {
    return (
      <>
        <span className="flex flex-row gap-[10px] items-center cursor-pointer w-[150px]">
          <img
            src={item.txtIcon}
            alt="noImg"
            className={`w-[20px] h-[20px] ${item.txtIconClasses}`}
          />
          <span
            onClick={(event:React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
              event.preventDefault();
              handleTabs(item.tab, item.link,item.header,item.txt);
            }}
            className={`text-[16px]  timeline-content ${
              item.tab <= currentUserCalData.Progress.completed ||
              currentActive === item.pageName
                ? "text-[var(--main-blue)] font-[500]"
                : "text-[#6c757d]"
            }`}
          >
            {item.txt}
          </span>
        </span>
      </>
    );
  };
  const handleTabs = async (currentTab: number, link: string,header:string,name:string) => {
    let body: TCalculatorData = { ...currentUserCalData };
    body = {
      ...body,
      Progress: {
        ...body.Progress,
        current: currentTab,
      },
    };
    dispatch(setPath({ SidebarPath: name , headerPath: header }))
    navigate(`${link}?id=${currentUserCalData.VendorId}`);
    dispatch(SetCalData(body))
    await SaveCalData(body);
  };
  return (
    <>
      <div className={classes.TimeLineContainer}>
        <span className="mt-[15px]">Portfolio Journey</span>
        <CustomTimeLine
          value={events}
          layout="horizontal"
          content={customizedContent}
          align="top"
          className="customized-timeline"
          marker={customizedMarker}
        />
      </div>
    </>
  );
});
