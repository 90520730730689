import React from 'react'
import { TAllContacts } from 'types'
import { Avatar } from 'components/screen-components/Avatar'
import { Link } from 'react-router-dom'
import { SplitContactType } from 'utils/helperFunctions'
// @ts-ignore
import { Image } from 'react-bootstrap'
import DeleteIcon from "assets/images/Delete-icon.svg";
import EditIcon from "assets/images/Edit-icon.svg";
import { DeleteUser } from 'store/Slices/UserSlice'
// @ts-ignore
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { GetAllAdmins, GetAllContacts } from 'store/Slices/ContactSlice'
import { useDispatch,useSelector } from 'react-redux'
import { RootState } from 'store/store'

type ContactBodyProps = {
  currentTableData: TAllContacts[],
  GetUpdatedData?: Function
}
export const ContactBody = ({ currentTableData, GetUpdatedData }: ContactBodyProps) => {
  const dispatch = useDispatch();
  const LoginUserData = useSelector((state:RootState)=>state.userProfile.data)
  const handleDelete = async (id: string, email: string) => {
    let response = await DeleteUser(id);
    if (response) {
      let officeId=process.env.REACT_APP_OFFICEID as string
      dispatch(GetAllContacts(officeId) as any)
      if(process.env.REACT_APP_ADMIN_EMAIL === LoginUserData.email){
        dispatch(GetAllAdmins() as any)
      } 
      
    
      NotificationManager.success(response.message, "", 2000)
      setTimeout(()=>{
        GetUpdatedData && GetUpdatedData();
      },2000)
    }
  }
  return (
    <>

      {currentTableData.map((item, index) => {
        return (
          <tr key={index}>
            <td>
              <div className="avatar-column">
                <Link to={`/EditContact/${item._id}`}>
                  <Avatar
                    width="30px"
                    height="30px"
                    text={item.full_name}
                    fontSize="8.8px"
                    textColor="#fefefe"
                    imgSrc={item.photo}
                  />
                </Link>
                <Link to={`/EditContact/${item._id}`}>
                  {item.full_name}
                </Link>
              </div>
            </td>
            <td>
              <Link to={`/EditContact/${item._id}`}>{item.email}</Link>
            </td>
            <td>
              <Link to={`/EditContact/${item._id}`}>{item.mobile_no}</Link>
            </td>
            <td>
              <Link to={`/EditContact/${item._id}`}>
                {item.contact_address}
              </Link>
            </td>
            <td>
              <Link to={`/EditContact/${item._id}`}>{SplitContactType(item.contact_type)}</Link>
            </td>
            <td className="LineTd">
              <Link to={`/EditContact/${item._id}`}>
                <Image src={EditIcon} width="15px" height="15px" />
              </Link>
            </td>
            <td className="delete-icon">
              <Image
                src={DeleteIcon}
                width="15px"
                height="15px"
                onClick={() => handleDelete(item._id, item.email)}
              />
            </td>
          </tr>
        )
      })}
      <NotificationContainer />
    </>
  )
}
