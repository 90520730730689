import React, { useState, useEffect,memo } from "react";
import { getAutoValProperty, GetGoogleAddress } from "store/Slices/UserSlice";
import { TGoogleAddress,TAutoValData } from "types";
type Props = {
  Address: string;
  propertySelect: boolean;
  HandleData:Function
};

export const useDomainAutoVal = ({ Address, propertySelect,HandleData }: Props) => {
  const [priceGenerated, setpriceGenerated] = useState(true);
  const [data] = useState<TAutoValData>({
    AutoValuation: 0,
    PurchaseDate: "",
    status: true,
  });
  const [showGoogleAddresses, setshowGoogleAddresses] =
    useState<boolean>(false);
  const [GoogleAddresses, setGoogleAddresses] = useState<TGoogleAddress[]>([]);
  const [initialRenderAddress, setinitialRenderAddress] =
    useState<boolean>(true);
  const GetAddresses = async () => {
    let response = await GetGoogleAddress(Address);
    if (response) {
      setshowGoogleAddresses(true);
      setGoogleAddresses(response);
    }
  };
  const GetPropertyValuation = async () => {
    let response = await getAutoValProperty(Address);
   
    if (response?.status) {
        setpriceGenerated(true);
      HandleData({
        ...data,
        AutoValuation: response.DomainAutoVal,
        PurchaseDate: response.PurchaseDate,
        status: true,
      })
    } else {
        setpriceGenerated(false);
      HandleData({
        ...data,
        AutoValuation: 0,
        PurchaseDate: "",
        status: false,
      })
     
    }
  };
  useEffect(() => {
    if (initialRenderAddress) {
      setinitialRenderAddress(false);
    } else if (propertySelect) {
      GetPropertyValuation();
    } else {
      GetAddresses();
    }
  }, [Address]);
  return {
    priceGenerated,
    setpriceGenerated,
    showGoogleAddresses,
    setshowGoogleAddresses,
    GoogleAddresses,
  };
};
