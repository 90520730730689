import React, { useState } from 'react'
import { OuterContainer, InnerContainer } from "global.style";
import { PortfolioJourney } from 'components/screen-components/PortfolioJourney';
import SummaryBoxes from 'components/screen-components/Calculator/Summary/SummaryBoxes';
import SummaryTable from 'components/screen-components/Calculator/Summary/SummaryTable';
import "./style.css"
import { GradientButton } from 'components/ui/Buttons/GradientButton';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { SetCalData, SaveCalData } from "store/Slices/CalculatorSlice";
import { useDispatch } from 'react-redux';
export const Summary = () => {
  const navigate = useNavigate()
  const caldata = useSelector((state: RootState) => state.calculator);
  const dispatch = useDispatch();
  const updateProfile = async (event: React.MouseEvent<HTMLButtonElement>) => {
    let newcalobj = { ...caldata };
    newcalobj = {
      ...newcalobj,
      Progress: {
        ...newcalobj.Progress,
        completed: 4,
        current: 5,
      },
    };
    SaveCalData(newcalobj);
    dispatch(SetCalData(newcalobj));
    navigate(`/Clients/IncomePlan?id=${newcalobj.VendorId}`);
  };
  return (
    <OuterContainer>
      <InnerContainer width="100%" bg={true} padding="1rem 15px 0rem 15px">
        <PortfolioJourney currentActive="Summary"/>
        
        <SummaryBoxes/>
        <SummaryTable/>
        <div className="relative flex justify-end mt-9">
          <GradientButton
            onClick={updateProfile}
            style={{
              position: "fixed",
              bottom: "31px",
              right: "94px",
              width: "89px !important",
            }}
            width="89px"
            txt="Next"
          />

          <GradientButton
            style={{
              position: "fixed",
              bottom: "31px",
              right: "196px",
              width: "89px !important",
            }}
            onClick={() => {
              navigate(-1)
            }}
            width="89px"
            txt="Back"
          />
        </div>
        </InnerContainer>
       
        </OuterContainer>
  )
}
