import React, { useState } from "react";
import {
  InnerContainer,
  OuterContainer,
  ContactDropDown,
} from "global.style";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/store";
import { SetCurrentPinderState } from "store/Slices/PinderSlice";
import { LocalResultsItems } from "utils/constants";
import { TPinderFilters } from "types";
import { Searchbar } from "components/screen-components/Searchbar";
export const LiveData = () => {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const pinderstate = useSelector((state: RootState) => state.pinder);

  const [TopFilters, setTopFilters] = useState<TPinderFilters>({
    PostCode: [],
    Page: 1,
    PerPage: pinderstate.currentPinderState === "residential" ? 200 : 500,
    MaxPrice: 0,
    MinPrice: 0,
    PropertyType: [],
    ListingType: [],
    Keyword: "",
    State: [],
  });
  return (
    <OuterContainer>
      <InnerContainer margin="0px" padding="0px" bg={true}>
        <div
          style={{ width: width - 290 + "px" }}
          className={`PinderPageContainer`}
        >
          <div className="flex flex-wrap justify-between mt-4">
            <div>
              <h2 className="pageTitle">Live Data</h2>
            </div>
            <div style={{ width: "150px" }}>
              <ContactDropDown
                className="w-[100%] h-[32px]"
                value={pinderstate.currentPinderState}
                options={LocalResultsItems}
                onChange={(e) => {
                  dispatch(
                    SetCurrentPinderState({ currentPinderState: e.value })
                  );
                  if (e.value === "residential") {
                    setTopFilters({ ...TopFilters, PerPage: 200 });
                  } else {
                    setTopFilters({ ...TopFilters, PerPage: 500 });
                  }
                }}
                placeholder="Data Type"
                color="#ced4da"
              />
            </div>
          </div>
          <div className="FilterContainer">
            <div>
              <Searchbar
                SearchQuery={TopFilters.Keyword}
                setSearchQuery={(value: string) => {
                  setTopFilters({ ...TopFilters, Keyword: value });
                }}
                placeholder="Search"
              />
            </div>
          </div>
        </div>
      </InnerContainer>
    </OuterContainer>
  );
};
