/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, useRef, useState, useEffect } from "react";
import classes from "PinderPage.module.css";
import ArrowIcon from "assets/images/arrowW.png";
import { PinderTable } from "pinder.style";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import PropertyHistory from "./PropertyHistory";
import PropertyEmail from "./PropertyEmail";
import RevertProperty from "./RevertProperty";
import { commaSeparators } from "utils/helperFunctions";
import SwotProfile from "./SwotProfile";
import PropertyDescription from "./PropertyDescription";
import SuburbProfile from "./SuburbProfile";
import ToolTips from "components/ui/Tooltip/Tooltips";
import moment from "moment";
import { InputText } from "primereact/inputtext";
import { HandleFilter } from "utils/helperFunctions";
import FilterValueImg from "assets/images/cil-dollar.svg";
import { CustomDropDown } from "pinder.style";
import { CustomPanel } from "pinder.style";
import arrowDown from "assets/images/arrows-down-up.svg";
import { sortOptions } from "utils/constants";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
interface PropertyTableProps {
  rows: number;
  TableData: any[];
  selectedCustomers: any;
  setSelectedCustomers: any;
  setcallingGetApi: any;
  visibleColumns: any;
  editingRows: any;
  seteditingRows: any;
  PropertiesID: any;
  setPropertiesId: any;
  propertyType: "commercial" | "residential";
}
const PropertiesTable = memo(
  ({
    rows,
    TableData,
    selectedCustomers,
    setSelectedCustomers,
    setcallingGetApi,
    visibleColumns,

    editingRows,
    seteditingRows,
    PropertiesID,
    setPropertiesId,
    propertyType,
  }: PropertyTableProps) => {
    const TableRef = useRef<any>(null);
    const userData = useSelector((state: RootState) => state.userProfile.data);
    const [CurrentEditField, setCurrentEditField] = useState<any>({
      fieldname: "",
      oldvalue: "",
      newvalue: "",
      propertyId: 0,
    });
    const [filterData, setFilterData] = useState<any>(TableData);
    const [selectedSort, setselectedSort] = useState({
      name: "A to Z",
      code: "AZ",
    });
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const prevBtnNavigation = (e: any) => {
      e.preventDefault();
      var tableContainer = document.querySelector(".p-datatable-wrapper");
      if (tableContainer) {
        tableContainer.scrollTo({
          left: tableContainer.scrollLeft - 500,
          behavior: "smooth",
        });
      }
    };
    const nextBtnNavigation = (e: any) => {
      e.preventDefault();
      var tableContainer = document.querySelector(".p-datatable-wrapper");
      if (tableContainer) {
        tableContainer.scrollTo({
          left: tableContainer.scrollLeft + 500,
          behavior: "smooth",
        });
      }
    };
    const filterApplyTemplate = (options: any) => {
      return (
        <Button
          type="button"
          className="p-button-sm"
          onClick={options.filterApplyCallback}
          severity="success"
        >
          Apply
        </Button>
      );
    };
    const AscDecFilterTemplate = (options: any) => {
      const applySort = (value: any, e: any) => {
        if (value?.code === "AZ") {
          setFilterData(HandleFilter(options.field, 1, TableData));
        } else {
          setFilterData(HandleFilter(options.field, -1, TableData));
        }
      };

      const template = (options: any) => {
        const toggleIcon = options.collapsed
          ? "pi pi-chevron-right "
          : "pi pi-chevron-down";
        const className = `${options.className}`;

        return (
          <div className={className}>
            <div className="header-left-content">
              <img src={FilterValueImg} alt="img" />
              <span className="title">Filter By Value</span>
            </div>
            <span
              className={toggleIcon}
              onClick={options.onTogglerClick}
            ></span>
          </div>
        );
      };

      return (
        <>
          <div className={classes.filterheader}>
            <p>Filter</p>
            <span>Clear</span>
          </div>
          <div className={classes.filtersort}>
            <p>
              <img src={arrowDown} width="14px" height="14px" alt="img" /> Sort
              by
            </p>
            <CustomDropDown
              value={selectedSort}
              onChange={(e) => {
                setselectedSort(e.value);
                applySort(e.value, e);
              }}
              options={sortOptions}
              optionLabel="name"
            />
          </div>
          <div>
            <div>
              <CustomPanel
                collapsed={!isPanelOpen}
                onToggle={() => setIsPanelOpen(!isPanelOpen)}
                headerTemplate={template}
                toggleable
              >
                <InputText
                  placeholder={options?.placeholder}
                  value={options.value}
                  onChange={(e) =>
                    options.filterCallback(e.target.value, options.index)
                  }
                />
              </CustomPanel>
            </div>
          </div>
        </>
      );
    };
    const rowClass = (data: any) => {
      let check = data?.Background || false;
      // console.log(data,"data")
      return {
        rowbackground: check,
      };
    };
    const PriceBodyTemplate = (rowData: any) => {
      return (
        <p className="overflow-hidden text-center whitespace-normal bg-transparent border-none cursor-pointer text-ellipsis">
          ${rowData?.extractedPrice || rowData?.Price}
        </p>
      );
    };

    const ActiveDateBodyTemplate = (rowData: any) => {
      let currentDate: any = new Date();
      let listingDate: any = new Date(rowData?.DaysonMarket);
      let timeDifference: any = currentDate - listingDate;
      let daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      return <span>{daysDifference || ""}</span>;
    };
    const RentBodyTemplate = (rowData: any) => {
      return <p>{commaSeparators(rowData?.NetRent || rowData?.Rent)}</p>;
    };
    const LinkBodyTemplate = (options: any) => {
      return (
        <a
          style={{ color: "blue" }}
          href={`${options?.Listings}`}
          target="_blank"
          rel="noreferrer"
        >
          Link
        </a>
      );
    };

    const ImageTipComp = (Image: any) => {
      let [idx, setIdx] = useState<any>("6");
      return (
        <div className="ToolTip-container">
          <div className="ToolTip">
            <ToolTips
              top="25px"
              width="320px"
              textAlign="left"
              show={idx === "hovering" ? true : false}
              txt={
                <img
                  style={{ width: "300px", height: "300px" }}
                  src={Image?.Image}
                  alt="img"
                />
              }
            />
          </div>
          <a
            style={{ cursor: "pointer" }}
            onMouseEnter={() => setIdx("hovering")}
            onMouseLeave={() => setIdx(6)}
          >
            Image
          </a>
        </div>
      );
    };
    const ImageBodyTemplate = (rowData: any) => {
      return (
        <>
          <ImageTipComp Image={rowData?.Image} />
        </>
      );
    };
    const ToolTipComp = (ClientNames: any) => {
      let [idx, setIdx] = useState<any>("6");
      let ClientArr = ClientNames?.ClientNames?.split(",");
      return (
        <div className="ToolTip-container">
          <div className="ToolTip">
            <ToolTips
              top="25px"
              width="270px"
              textAlign="left"
              show={idx === "hovering" ? true : false}
              txt={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  {ClientArr?.length > 0 &&
                    ClientArr?.map((item: any, index: any) => {
                      return <span>{item}</span>;
                    })}
                </div>
              }
            />
          </div>
          <div style={{ width: "50px", height: "40px", overflow: "hidden" }}>
            <p
              style={{ textOverflow: "ellipsis", overflow: "hidden" }}
              onMouseEnter={() => setIdx("hovering")}
              onMouseLeave={() => setIdx(6)}
            >
              {ClientNames?.ClientNames}
            </p>
          </div>
        </div>
      );
    };
    const ClientBodyTemplate = (rowData: any) => {
      return (
        <>
          <ToolTipComp ClientNames={rowData?.Client} />
        </>
      );
    };
    const SearchModeTemplate = (rowData: any) => {
      let listing =
        rowData?.Listing?.charAt(0)?.toUpperCase() + rowData?.Listing?.slice(1);
      let capitalLetterCount = 0;
      let indexSecondCapitalLetter = -1;
      for (let i = 0; i < listing?.length; i++) {
        if (listing[i] === listing[i]?.toUpperCase()) {
          capitalLetterCount++;
          if (capitalLetterCount === 2) {
            indexSecondCapitalLetter = i;
            break;
          }
        }
      }
      if (indexSecondCapitalLetter != -1) {
        listing =
          listing.slice(0, indexSecondCapitalLetter) +
          " " +
          listing.slice(indexSecondCapitalLetter);
      }
      return <span>{listing}</span>;
    };
    const AdvertDateBodyTemplate = (rowData: any) => {
      let date = rowData?.AdvertUpdateDate
        ? moment(rowData?.AdvertUpdateDate).format("DD/MM/YYYY h:mma")
        : "";

      return <p>{date}</p>;
    };
    const cellEditor = (options: any) => {
      if (options.field === "price") return textEditor(options);
      else return textEditor(options);
    };
    const textEditor = (options: any) => {
      return (
        <InputText
          type="text"
          value={options.value}
          onChange={(e) => options.editorCallback(e.target.value)}
        />
      );
    };
    const onCellEditComplete = (e: any) => {
      let { rowData, newValue, field, originalEvent: event } = e;
      event.preventDefault();
      const existingRowIndex = editingRows?.findIndex(
        (item: any) => item?._id === rowData?._id
      );
      let oldvalue = rowData[field];
      rowData[field] = newValue;

      setCurrentEditField({
        fieldname: field,
        oldvalue: oldvalue,
        newvalue: newValue,
        propertyId: rowData.propertyId,
      });
      let ExistingProp = PropertiesID?.filter(
        (item: any) => item === rowData?.propertyId
      );
      if (ExistingProp.length === 0) {
        setPropertiesId([...PropertiesID, rowData?.propertyId]);
      }
      if (existingRowIndex !== -1) {
        const updatedRows: any[] = [...editingRows];
        updatedRows[existingRowIndex] = {
          ...updatedRows[existingRowIndex],
          [field]: newValue,
        };

        seteditingRows(updatedRows);
      } else {
        seteditingRows((prevEditingRows: any) => [...prevEditingRows, rowData]);
      }
    };
    const SavePropertyData = async () => {
      const currentEditProp = editingRows?.filter(
        (item: any) => item?.propertyId === CurrentEditField?.propertyId
      );
      let PropertyHistory = currentEditProp[0]?.propertyHistory || [];

      if (PropertyHistory?.length > 0) {
        let AdminId = sessionStorage.getItem("userId");
        PropertyHistory = PropertyHistory?.filter(
          (item: any) => item.AdminId === AdminId
        );

        if (PropertyHistory?.length > 0) {
          PropertyHistory = PropertyHistory[0]?.ChangeFields || [];
        } else {
          PropertyHistory = [];
        }
      } else {
        PropertyHistory = [];
      }

      let AdminId = sessionStorage.getItem("userId");
      let userName = userData?.first_name + " " + userData?.last_name;
      let body = {};
      if (propertyType === "commercial") {
        body = {
          AdminPropertyChanges: {
            AdminId: AdminId,
            propertyDetails: {
              ...currentEditProp[0]?.propertyDetails,
              Comments: currentEditProp[0]?.Comments || "",
              Client: currentEditProp[0]?.Client || "",
              NetRent: currentEditProp[0]?.NetRent || "",
              Outgoing: currentEditProp[0]?.Outgoing || "",
              YieldReturn: currentEditProp[0]?.YieldReturn || "",
              TenureType: currentEditProp[0]?.TenureType || "",
              NumofTenants: currentEditProp[0]?.NumofTenants || "",
              Occupancy: currentEditProp[0]?.Occupancy || "",
              TenantName: currentEditProp[0]?.TenantName || "",
              LeaseExpiry: currentEditProp[0]?.LeaseExpiry || "",
              SWOT: currentEditProp[0]?.SWOT || "",
              SuburbProfile: currentEditProp[0]?.SuburbProfile || "",
              AgencyName: currentEditProp[0]?.AgencyName || "",
              AgentName: currentEditProp[0]?.AgentName || "",
              AgentContact: currentEditProp[0]?.AgentContact || "",
              AgentEmail: currentEditProp[0]?.AgentEmail || "",
              SaleAuction: currentEditProp[0]?.SaleAuction || "",
              SaleExpressionofInterest:
                currentEditProp[0]?.SaleExpressionofInterest || "",
              Zoning: currentEditProp[0]?.Zoning || "",
              Municipality: currentEditProp[0]?.Municipality || "",
              Headline: currentEditProp[0]?.headline || "",
              address: currentEditProp[0]?.Address || "",

              metadata: {
                ...currentEditProp[0].propertyDetails.metadata,
                addressComponents: {
                  ...currentEditProp[0].propertyDetails.metadata
                    .addressComponents,
                  suburb: currentEditProp[0]?.Suburb || "",
                  stateShort: currentEditProp[0]?.State || "",
                  postcode: currentEditProp[0]?.Postcode || "",
                },
              },
              propertyType: currentEditProp[0]?.PropertyType || "",
              price: currentEditProp[0]?.Price || "",

              listDetails: {
                ...currentEditProp[0].propertyDetails.listDetails,
                landAreaSqm: currentEditProp[0]?.LandArea || "",
                buildingAreaSqm: currentEditProp[0]?.FloorArea || "",
                description: currentEditProp[0]?.Description || "",
                dateListed: currentEditProp[0]?.AdvertUpdateDate || "",
                carspaces: currentEditProp[0]?.TotalCarSpaces || 0,
              },
            },
          },
          propertyHistory: {
            AdminId: AdminId,
            AdminName: userName,
            propertyId: CurrentEditField.propertyId,
            ChangeFields:
              PropertyHistory?.length > 0
                ? [
                    ...PropertyHistory,
                    {
                      changeDate: Date.now(),
                      event: "Update",
                      fieldname: CurrentEditField.fieldname,
                      oldvalue: CurrentEditField.oldvalue,
                      newvalue: CurrentEditField.newvalue,
                    },
                  ]
                : [
                    {
                      changeDate: Date.now(),
                      event: "Update",
                      fieldname: CurrentEditField.fieldname,
                      oldvalue: CurrentEditField.oldvalue,
                      newvalue: CurrentEditField.newvalue,
                    },
                  ],
          },
        };
      } else {
        body = {
          AdminPropertyChanges: {
            AdminId: AdminId,
            propertyDetails: {
              ...currentEditProp[0]?.propertyDetails,
              Comments: currentEditProp[0]?.Comments || "",
              Client: currentEditProp[0]?.Client || "",
              Ensuites: currentEditProp[0]?.Ensuites || "",
              Toilets: currentEditProp[0]?.Toilets || 0,
              Rent: currentEditProp[0]?.Rent || "",
              Yield: currentEditProp[0]?.Yield || "",
              SWOT: currentEditProp[0]?.SWOT || "",
              SuburbProfile: currentEditProp[0]?.SuburbProfile || "",
              Outgoing: currentEditProp[0]?.Outgoing || "",
              TenureType: currentEditProp[0]?.TenureType || "",
              NumofTenants: currentEditProp[0]?.NumofTenants || "",
              Occupancy: currentEditProp[0]?.Occupancy || "",
              TenantName: currentEditProp[0]?.TenantName || "",
              LeaseExpiry: currentEditProp[0]?.LeaseExpiry || "",
              SaleAuction: currentEditProp[0]?.SaleAuction || "",
              SaleExpressionofInterest:
                currentEditProp[0]?.SaleExpressionofInterest || "",
              Zoning: currentEditProp[0]?.Zoning || "",
              Municipality: currentEditProp[0]?.Municipality || "",
              listing: {
                ...currentEditProp[0]?.propertyDetails?.listing,
                listingType: currentEditProp[0]?.Listing || "",
                headline: currentEditProp[0]?.Headline || "",
                priceDetails: {
                  ...currentEditProp[0]?.propertyDetails?.listing?.priceDetails,
                  displayPrice: currentEditProp[0]?.Price || "",
                },
                propertyDetails: {
                  ...currentEditProp[0]?.propertyDetails?.listing
                    ?.propertyDetails,
                  displayableAddress: currentEditProp[0]?.Address || "",
                  suburb: currentEditProp[0]?.Suburb || "",
                  state: currentEditProp[0]?.State || "",
                  postcode: currentEditProp[0]?.Postcode || "",
                  bedrooms: currentEditProp[0]?.Bedrooms || "",
                  bathrooms: currentEditProp[0]?.Bathrooms || "",
                  landArea: currentEditProp[0]?.LandSize || "",
                  buildingArea: currentEditProp[0]?.HouseSize || "",
                  summaryDescription: currentEditProp[0]?.Description || "",
                  propertyType: currentEditProp[0]?.PropertyType || "",
                  carspaces: currentEditProp[0]?.TotalCarSpaces || 0,
                },
              },
              AgencyName: currentEditProp[0]?.AgencyName || "",
              AgentName: currentEditProp[0]?.AgentName || "",
              AgentContact: currentEditProp[0]?.AgentContact || "",
              AgentEmail: currentEditProp[0]?.AgentEmail || "",
            },
          },
          propertyHistory: {
            AdminId: AdminId,
            AdminName: userName,
            propertyId: CurrentEditField.propertyId,
            ChangeFields:
              PropertyHistory?.length > 0
                ? [
                    ...PropertyHistory,
                    {
                      changeDate: Date.now(),
                      event: "Update",
                      fieldname: CurrentEditField.fieldname,
                      oldvalue: CurrentEditField.oldvalue,
                      newvalue: CurrentEditField.newvalue,
                    },
                  ]
                : [
                    {
                      changeDate: Date.now(),
                      event: "Update",
                      fieldname: CurrentEditField.fieldname,
                      oldvalue: CurrentEditField.oldvalue,
                      newvalue: CurrentEditField.newvalue,
                    },
                  ],
          },
        };
      }

      try {
        let response = await fetch(
          `${process.env.REACT_APP_API_BASEURL}set-property-changes/${propertyType}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("Token")}`,
            },
            body: JSON.stringify(body),
          }
        );
        if (response.status === 201) {
          seteditingRows([]);
          setcallingGetApi((prevVal: any) => !prevVal);
        }
      } catch (err) {}
    };
    const HeadLine = (rowData: any) => {
      return (
        <p className="overflow-hidden text-left whitespace-normal bg-transparent border-none cursor-pointer text-ellipsis">
          {rowData.Headline}
        </p>
      );
    };
    const Comment = (rowData: any) => {
      return (
        <p className="overflow-hidden text-left whitespace-normal bg-transparent border-none cursor-pointer text-ellipsis max-w-[100px]">
          {rowData.Comments}
        </p>
      );
    };
    const AddressBody = (rowData: any) => {
      return (
        <p className="overflow-hidden text-left whitespace-normal bg-transparent border-none cursor-pointer text-ellipsis">
          {rowData.Address}
        </p>
      );
    };
    const PropertyTypeBody = (rowData: any) => {
      return (
        <p className="overflow-hidden text-center whitespace-normal bg-transparent border-none cursor-pointer text-ellipsis">
          {rowData.PropertyType}
        </p>
      );
    };
    useEffect(() => {
      if (editingRows.length !== 0) {
        const editingRowId = editingRows[0]?.propertyDetails?.propertyId;
        let newfilterdata = filterData.map((item: any) => {
          if (item.propertyId === editingRowId) {
            let newobj = {
              ...item,
              Background: true,
            };
            return newobj;
          } else {
            return item;
          }
        });
        setFilterData(newfilterdata);
        SavePropertyData();
      }
    }, [editingRows]);
    useEffect(() => {
      setFilterData(TableData);
    }, [TableData]);
    return (
      <>
        <div className={`flex relative`}>
          <span
            onClick={prevBtnNavigation}
            className={`${classes.prevNavigationBtn2} ${classes.activeImg}`}
          >
            <img
              src={ArrowIcon}
              className={classes.prevNavigationBtnImg}
              alt="<"
            />
          </span>
          <span
            onClick={nextBtnNavigation}
            className={`${classes.navigationBtn2} ${classes.activeImg}`}
          >
            <img src={ArrowIcon} className={classes.navigationBtnImg} alt=">" />
          </span>
        </div>
        <div className="scrollable-table-container"></div>

        <PinderTable
          ref={TableRef}
          editMode="cell"
          className="propertyDataTable"
          value={filterData} //filterData
          rows={Number(rows)}
          tableStyle={{ minWidth: "390rem", width: "100%" }}
          selectionMode="checkbox"
          selection={selectedCustomers}
          rowClassName={rowClass}
          onSelectionChange={(e) => {
            setSelectedCustomers(e.value);
          }}
        >
          <Column
            header=""
            style={{
              width: "23px",
              paddingLeft: "5px",
              paddingRight: "0px",
              maxWidth: "23px",
            }}
            bodyStyle={{
              paddingLeft: "0px",
              textAlign: "center",
              maxWidth: "23px",
            }}
            body={(rowData: any) => <PropertyHistory rowData={rowData} />}
          ></Column>
          <Column
            header=""
            style={{
              width: "23px",
              paddingLeft: "0px",
              paddingRight: "0px",
              maxWidth: "23px",
            }}
            bodyStyle={{
              paddingLeft: "0px",
              textAlign: "center",
              maxWidth: "23px",
            }}
            body={(rowData: any) => <PropertyEmail rowData={rowData} />}
          ></Column>
          <Column
            header=""
            style={{
              width: "23px",
              paddingLeft: "0px",
              paddingRight: "0px",
              maxWidth: "23px",
            }}
            bodyStyle={{
              paddingLeft: "0px",
              textAlign: "center",
              maxWidth: "23px",
            }}
            body={(rowData: any) => (
              <RevertProperty
                setcallingGetApi={setcallingGetApi}
                propertyType={propertyType}
                rowData={rowData}
                propertyId={
                  rowData?.propertyDetails?.id ||
                  rowData?.propertyDetails?.listing?.id
                }
              />
            )}
          ></Column>
          {visibleColumns.map((item: any, index: number) => {
            return (
              <Column
                key={index}
                field={item.field}
                header={item.header}
                style={item.style}
                bodyStyle={item.bodyStyle}
                body={(rowData) => {
                  if (
                    item.header === "Extracted Price" ||
                    item.header === "Price"
                  ) {
                    return PriceBodyTemplate(rowData);
                  } else if (item.header === "Listing Type") {
                    return SearchModeTemplate(rowData);
                  } else if (item.header === "Client") {
                    return ClientBodyTemplate(rowData);
                  } else if (item.header === "Image") {
                    return ImageBodyTemplate(rowData);
                  } else if (item.header === "Days on Market") {
                    return ActiveDateBodyTemplate(rowData);
                  } else if (item.header === "Advert Update Date") {
                    return AdvertDateBodyTemplate(rowData);
                  } else if (
                    item.header === "Net Rent" ||
                    item.header === "Rent"
                  ) {
                    return RentBodyTemplate(rowData);
                  } else if (item.header === "Listing") {
                    return LinkBodyTemplate(rowData);
                  } else if (item.header === "Headline") {
                    return HeadLine(rowData);
                  } else if (item.header === "Comments") {
                    return Comment(rowData);
                  } else if (item.header === "Address") {
                    return AddressBody(rowData);
                  } else if (item.header === "Property Type") {
                    return PropertyTypeBody(rowData);
                  } else if (item.header === "Suburb Profile") {
                    return (
                      <SuburbProfile
                        rowData={rowData}
                        setCurrentEditField={setCurrentEditField}
                        editingRows={editingRows}
                        seteditingRows={seteditingRows}
                      />
                    );
                  } else if (item.header === "SWOT") {
                    return (
                      <SwotProfile
                        rowData={rowData}
                        setCurrentEditField={setCurrentEditField}
                        editingRows={editingRows}
                        seteditingRows={seteditingRows}
                      />
                    );
                  } else if (item.header === "Description") {
                    return <PropertyDescription rowData={rowData} />;
                  } else {
                    return (
                      <p className="overflow-hidden text-center whitespace-normal bg-transparent border-none cursor-pointer text-ellipsis">
                        {rowData[item.field]}
                      </p>
                    );
                  }
                }}
                showFilterMatchModes={item.showFilterMatchModes}
                showAddButton={item.showAddButton}
                showFilterMenuOptions={item.showFilterMenuOptions}
                filter={item.filter}
                filterElement={AscDecFilterTemplate}
                filterApply={filterApplyTemplate}
                editor={
                  item.header !== "Client" &&
                  item.header !== "Listing" &&
                  item.header !== "Suburb Profile" &&
                  item.header !== "SWOT" &&
                  item.header !== "Description" &&
                  item.header !== "Image" &&
                  cellEditor
                }
                onCellEditComplete={(e) => {
                  onCellEditComplete(e);
                }}
              ></Column>
            );
          })}
        </PinderTable>
      </>
    );
  }
);

export default PropertiesTable;
