import React from 'react'
import { OuterContainer,HomeContainer } from '../global.style'
import { Sidebar } from '../components/screen-components/Sidebar'
import { Header } from '../components/screen-components/Header'
export const Layout = ({children}:any) => {
  return ( 
    <OuterContainer>
     <Sidebar />
      <HomeContainer>
        <Header/>
      {children}
      </HomeContainer>
    </OuterContainer>
  )
}
 