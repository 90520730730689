import React, { useCallback, useEffect, useState } from "react";
import { Container, InnerContainer } from "global.style";
import { Searchbar } from "components/screen-components/Searchbar";
import { SpaceRemove } from "utils/helperFunctions";
import { TAllContacts, TColumnHeaderTypes } from "types";
import { ListTable } from "components/screen-components/Tables/ListTable";
import { GetAllContact } from "store/Slices/UserSlice";
import { SortContact } from "utils/helperFunctions";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
export const ClientList = () => {
  const [SearchQuery, setSearchQuery] = useState<string>("");
  const { Contacts, Admins } = useSelector(
    (state: RootState) => state.contacts
  );
  const userdata = useSelector((state: RootState) => state.userProfile.data);
  const [loading, setLoading] = useState<boolean>(false);
  const [ClientsData, setClientsData] = useState<TAllContacts[]>(Contacts);
  const [FilteredData, setFilteredData] = useState<TAllContacts[]>(Contacts);
  const [ColumnHeaders] = useState<TColumnHeaderTypes[]>([
    {
      name: "Name",
      classes: "name",
    },
    {
      name: "Email",
    },
    {
      name: "Contact",
    },
    {
      name: "Address",
    },
    {
      name: "Type",
    },
    {
      name: "Progress",
      classes: "text-end !pr-[24px]",
    },
  ]);
  const handleSearch = useCallback(() => {
    let FilteredData = ClientsData?.filter(
      (item) =>
        item?.full_name.toUpperCase().indexOf(SearchQuery.toUpperCase()) > -1 ||
        item?.email.toUpperCase().indexOf(SearchQuery.toUpperCase()) > -1 ||
        SpaceRemove(item?.mobile_no).indexOf(SpaceRemove(SearchQuery)) > -1
    );
    const data = SortContact(FilteredData);
    setFilteredData(data);
  }, [ClientsData, SearchQuery]);

  const GetClients = async () => {
    setLoading(true)
    let response = await GetAllContact(
      process.env.REACT_APP_OFFICEID as string
    );
    if (response.status) {
      let updated_data = response?.data?.filter((item: any) =>
        item?.contact_type?.includes("Contact")
      );
      const data = SortContact(updated_data);
      setClientsData(data);
      setLoading(false)
    }
  };
  useEffect(()=>{
    GetClients()
  },[])
  useEffect(() => {
    handleSearch();
  }, [SearchQuery, handleSearch]);

  return (
    <Container>
      <InnerContainer padding="1.8rem 0 1rem 0px">
        <div className="profile-container px-[32px] ">
          <h1>Clients</h1>
          <Searchbar
            SearchQuery={SearchQuery}
            setSearchQuery={setSearchQuery}
            placeholder="Search Clients"
          />
        </div>
        <ListTable
          loading={loading}
          ListData={FilteredData}
          SearchQuery={SearchQuery}
          ColumnHeaders={ColumnHeaders}
          Type="Client"
          classes="!mt-[20px]"
        />
      </InnerContainer>
    </Container>
  );
};
