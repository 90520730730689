import React, { useState } from "react";
import AnimateHeight from "components/screen-components/AnimateHeight";
const PropertyDescription = ({ rowData }: { rowData: any }) => {
  const [open, setOpen] = useState(false);
  const height = open ? "auto" : 0;
  const HandleClick = () => {
    setOpen(!open);
  };
  return (
    <AnimateHeight height={height} minheight={"52px"}>
      <button
        className="overflow-hidden text-left whitespace-normal bg-transparent border-none cursor-pointer text-ellipsis"
        onClick={HandleClick}
      >
        {rowData?.Description}
      </button>
    </AnimateHeight>
  );
};

export default PropertyDescription;
