/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, memo, useEffect, useCallback } from "react";
import { Toast } from "primereact/toast";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/store";
import { TEquityGains } from "types";
import {  PropertyAddButton } from "global.style";
import { TopGradient } from "./TopGradient";
import { EquityCash } from "./EquityCash";
import { FloatingBox } from "components/screen-components/FloatingBox";
import classes from "stylesheet.module.css";
import { ExpenseInput } from "./ExpenseInput";
import { IncomeDetails } from "./IncomeDetails";
import { ExpenseDetails } from "./ExpenseDetails";
import { PPR } from "./PPR";
import { InvestmentProperties } from "./InvestmentProperties";
import { SetCalData, SaveCalData } from "store/Slices/CalculatorSlice";
import { BottomGradient } from "./BottomGradient";
import {  commaSeparators } from "utils/helperFunctions";
import { GetFinanceMBCValue } from "utils/helperFunctions";
import { calculateMaxBorrowingCapacity } from "utils/helperFunctions";
//@ts-ignore
import moment from "moment";

export const Equity = memo(() => {
  const userCaldata = useSelector((state: RootState) => state.calculator);
  const settingsdata = useSelector((state: RootState) => state.settings);
  
  const toast = useRef<any>(null);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [newPropertyName, setNewPropertyName] = useState<string>("");
  const [initialRender,setinitialRender] = useState<boolean>(true);

  const dispatch = useDispatch();
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  const handleAddProperty = () => {
    setNewPropertyName(`Property ${userCaldata?.Equity?.Properties.length + 1}`);
    let newEquityGain: TEquityGains = {
      PropertyName: `Property ${userCaldata?.Equity?.Properties.length + 1}`,
      Percentage: 5,
      StartDate: new Date(moment().add(1, "month")),
      EndDate: new Date(
        new Date(moment().add(1, "month")).getTime() + 31536000000
      ),
      noOfMonths: 12,
      value: 0,
    };
    let newproperty = {
      PropertyName: `Property ${userCaldata?.Equity?.Properties?.length + 1}`,
      Address: "",
      GrossRent: {
        type: "Annually",
        value: 0,
      },
      Mortgage: 0,
      InterestRate: 0,
      DebtProvider: "",
      AutoValuation: 0,
      PurchaseDate: "",
      EstimatedOutgoings: 0,
      LoanTerms: 30,
      RemainingLoanTerm: 0,
      LoanRepayments: {
        type: "Monthly",
        value: 0,
        principalvalue: 0,
        interestvalue:0,
      },
      InterestOnly: false,
      PrincipalAndInterest: true,
      NetPropertyIncome: {
        type: "Annually",
        value: 0,
      },
    };
    let newcalbody = { ...userCaldata };
    newcalbody = {
      ...newcalbody,
      Equity: {
        ...newcalbody.Equity,
        Properties: [...userCaldata?.Equity?.Properties, newproperty],
      },
      StrategicSpending: {
        ...newcalbody.StrategicSpending,
        EquityGains: [
          ...newcalbody.StrategicSpending.EquityGains,
          newEquityGain,
        ],
      },
    };
    dispatch(SetCalData(newcalbody));
    SaveCalData(newcalbody);
  };
  const AutoSave = async (maxborrow: number) => {
    let newcalbody = { ...userCaldata };
    newcalbody = {
      ...newcalbody,
      Equity: {
        ...newcalbody.Equity,
        MaxBorrowingCapacity: maxborrow,
      },
    };
    dispatch(SetCalData(newcalbody));
    await SaveCalData(newcalbody);
  };
  const SaveMaxBorrowCapacity = useCallback(() => {
    let max_borrow_value = calculateMaxBorrowingCapacity(
      userCaldata?.Equity?.PPR, 
      userCaldata?.Equity?.Properties,
      0,
      0,
      userCaldata?.BorrowingCalculator?.IncomeDetails,
      userCaldata?.BorrowingCalculator?.PersonalLiabilities?.CreditCards,
      userCaldata?.BorrowingCalculator?.UseAverageAustralian,
      userCaldata?.BorrowingCalculator?.UseBasiq,
      userCaldata?.BorrowingCalculator?.LivingExpenses,
      userCaldata?.BorrowingCalculator?.BasiqExpenses,
      settingsdata?.Assumptions?.DTI,
      userCaldata?.BorrowingCalculator?.ManualExpense
    );
    AutoSave(max_borrow_value);
  }, [
    userCaldata?.Equity?.PPR,
    userCaldata.Equity.Properties,
    settingsdata?.Assumptions?.DTI,
    userCaldata?.BorrowingCalculator?.IncomeDetails,
    userCaldata?.BorrowingCalculator?.PersonalLiabilities?.CreditCards,
    userCaldata?.BorrowingCalculator?.UseBasiq,
    userCaldata?.BorrowingCalculator?.UseAverageAustralian,
    userCaldata?.BorrowingCalculator?.LivingExpenses,
    userCaldata?.BorrowingCalculator?.BasiqExpenses,
    userCaldata?.BorrowingCalculator?.OtherPayments,
    userCaldata?.StrategicSpending?.NetSalaryYours,
    userCaldata?.StrategicSpending?.NetSalaryPartner,
    userCaldata?.StrategicSpending?.OtherIncome
  ]);
  useEffect(() => {
    // if(initialRender){
    //   setinitialRender(false)
    // }else{
    //   SaveMaxBorrowCapacity();
    // }
    SaveMaxBorrowCapacity();
  }, [SaveMaxBorrowCapacity]);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <Toast
        ref={toast}
        position="center"
        style={{ zIndex: "2", opacity: "1" }}
      />
      <div className="!mt-[20px] ">
        <TopGradient />
      </div>
      <div className="mt-[20px]">
        <EquityCash />
      </div> 
      <div className="flex">
        <div className="flex flex-col mt-[20px] gap-[20px] w-[80%]">
          <ExpenseInput />
          <IncomeDetails />
          <ExpenseDetails />
          <PPR />
          {userCaldata?.Equity?.Properties?.map((item, index) => {
            return (
              <InvestmentProperties key={index} item={item} index={index} />
            );
          })}
          <PropertyAddButton
            as="a"
            onClick={() => handleAddProperty()}
            href={`#${newPropertyName}`}
          >
            <i className="pi pi-plus"></i>
            Add Property
          </PropertyAddButton>
          <div className="!mt-[20px] ">
            <BottomGradient />
          </div>
        </div>
        <FloatingBox
          className={
            document.documentElement.scrollHeight - 1000 > scrollPosition
              ? "floatingbox"
              : "floatingbox fadeOut"
          }
          style={{ top: scrollPosition }}
        >
          <p className={`${classes.itemTitle} mt-4`}>Max Borrowing Capacity</p>
          <p className="itemValue">
            {commaSeparators(GetFinanceMBCValue({CalData:userCaldata}))}
          </p>
          <p className={`${classes.itemTitle} mt-4`}>Total Debt</p>
          <p className="itemValue">
            {commaSeparators(userCaldata.Equity.TotalDebt)}
          </p>

          <p className={`${classes.itemTitle} mt-3`}>Usable Equity</p>
          <p className="itemValue">
            {commaSeparators(userCaldata.Equity.UsableEquity)}
          </p>
          <div className={`mt-4 ${classes.ResultBox}`}>
            <p className={`${classes.itemTitle}`}>Usable Equity + Cash</p>
            <p className="itemValue">
              {commaSeparators(userCaldata.Equity.UsableEquityAndCash)}
            </p>
          </div>
        </FloatingBox>
      </div>
    </>
  );
});
