import React from "react";
import { InnerContainer } from "global.style";
import { Row, Col } from "react-bootstrap";
import {commaSeparators } from "utils/helperFunctions";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { GetFinanceMBCValue } from "utils/helperFunctions";
export const BottomGradient = () => {
  const userCaldata = useSelector((state:RootState)=>state.calculator)
  return (
    <InnerContainer className="card-gredient !min-h-[110px]">
      <Row>
        <Col md={7} lg={7} xl={7} xxl={6}>
          <div className="flex items-end justify-between">
            <div className="text-left">
              <p className="my-1 text-[16px] font-[700] text-[white]">Max Borrowing Capacity</p>
              <p
                className="my-1 text-[16px] font-[700] text-[white]"
              >
                Total Debt
              </p>
            </div>
            <div className="text-right">
              <p className="my-1 text-[16px] font-[700] text-[white]">{commaSeparators(GetFinanceMBCValue({CalData:userCaldata}))}</p>
              <p className="my-1 text-[16px] font-[700] text-[white]">{commaSeparators(userCaldata.Equity.TotalDebt)}</p>
            </div>
          </div>
        </Col>
        <Col md={7} lg={7} xl={7} xxl={6}>
          <div className="flex items-end justify-between">
            <div className="text-left">
              <p className="my-1 text-[16px] font-[700] text-[white]">Usable Equity </p>
              <p
                className="my-1 text-[16px] font-[700] text-[white]"
                style={{ display: "flex", flexDirection: "row" }}
              >
                Usable Equity + Cash
              </p>
            </div>
            <div className="text-right">
              <p className="my-1 text-[16px] font-[700] text-[white]">{commaSeparators(userCaldata.Equity.UsableEquity)}</p>
              <p className="my-1 text-[16px] font-[700] text-[white]">{commaSeparators(userCaldata.Equity.UsableEquityAndCash)}</p>
            </div>
          </div>
        </Col>
      </Row>
    </InnerContainer>
  );
};
