import { configureStore, combineReducers, AnyAction } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import AuthSlice from './Slices/AuthSlice';
import ThemeSlice from './Slices/ThemeSlice';
import storage from 'redux-persist/lib/storage';
import CalculatorSlice from './Slices/CalculatorSlice';
import PathSlice from './Slices/PathSlice';
import AutoSaveSlice from './Slices/AutoSaveSlice';
import UserSlice from './Slices/UserSlice';
import VendorsSlice from './Slices/VendorsSlice';
import SidebarSlice from './Slices/SidebarSlice';
import SettingsSlice from './Slices/SettingsSlice';
import PinderSlice from './Slices/PinderSlice';
import ContactSlice from './Slices/ContactSlice';

const persistConfig = {
    key: 'root',
    storage: storage,
    onError: (error: any) => {},
  };
  const appReducer = combineReducers({
  auth:AuthSlice,
  theme:ThemeSlice,
  calculator:CalculatorSlice,
  path:PathSlice,
  autoSave:AutoSaveSlice,
  userProfile:UserSlice,
  Vendors:VendorsSlice,
  sidebar:SidebarSlice,
  settings:SettingsSlice,
  pinder:PinderSlice,
  contacts:ContactSlice,
  
  });
  const rootReducer = (state:any, action: AnyAction) => {
    if (action.type === "LOGOUT") {
      state = undefined;
    }
    return appReducer(state, action);
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
  export const persistor = persistStore(store);
  // Infer the `RootState` and `AppDispatch` types from the store itself
  export type RootState = ReturnType<typeof store.getState>;
  // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
  export type AppDispatch = typeof store.dispatch;