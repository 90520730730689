import React from 'react'
import { PreviousButton,NextButton } from '../svgcomponents'
import { PaginationListItem } from 'global.style'
type PaginationProps = {
    nPages:number, 
    currentPage:number, 
    setCurrentPage:React.Dispatch<React.SetStateAction<number>>
}
export const Pagination = ({nPages, currentPage, setCurrentPage}:PaginationProps) => {
    const pageNumbers = [];
     for (let i = 1; i <= nPages; i++) {
      pageNumbers.push(i);
    }
  const endPage = pageNumbers[pageNumbers.length - 1];
  const onlypages = pageNumbers.slice(currentPage - 1, currentPage + 2);

  if (currentPage === endPage) {
    const p = onlypages[0];
    onlypages.unshift(p - 1);
    onlypages.unshift(p - 2);
  } else if (currentPage === endPage - 1) {
    const p = onlypages[0];
    onlypages.unshift(p - 1);
  } 
    const nextPage = () => {
        if (currentPage !== nPages) setCurrentPage(currentPage + 1);
      };
      const prevPage = () => {
        if (currentPage !== 1) setCurrentPage(currentPage - 1);
      };
  return (
    <nav>
        <ul className='flex gap-[10px]'>
        <PreviousButton onClick={prevPage} currentPage={currentPage} />
        {nPages > 1 ? (
          onlypages.map((pgNumber, idx) => (
            <>
              {pgNumber !== 0 && (
                <PaginationListItem
                  key={idx}
                  active={currentPage === pgNumber ? true : false}
                  onClick={() => setCurrentPage(pgNumber)}
              >
                  <span>{pgNumber}</span>
                </PaginationListItem>
              )}
            </>
          ))
        ) : (
          <PaginationListItem active={true}>
            <span>1</span>
          </PaginationListItem>
        )}

        <NextButton
          onClick={nextPage}
          currentPage={currentPage}
          endPage={endPage}
        />
        
        </ul>
    </nav>
  )
}
