import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import url from "../../config";
import {  TAdminsData,TAllContacts } from "../../types";

export type TContacts = {
  Contacts: TAllContacts[];
  ContactCreateFor: "User" | "Admin";
  Admins: TAdminsData[];
  UserAllow:boolean;
};
export type TCreateContact = {
  first_name: string,
  last_name: string,
  email: string,
  mobile_no: string,
  telephone_no: string,
  contact_type: string,
  contact_address:string,
  password: string,
  agent: string,
  preferred_contact_method: string,
  photo: string,
  role_id: string, 
  office_id: string,
}

export const GetAllContacts = createAsyncThunk(
  "contacts?office_id=id",
  async (officeid: string) => {
    try {
      let response = await url.get(`contacts?office_id=${officeid}`);
      return response.data;
    } catch (err) {
      return err;
    }
  }
);
export const CreateContacts = async(body:TCreateContact)=>{
  try {
    let response = await url.post(`contacts`,body);
    return response.data;
  } catch (err) {
    return err;
  }
}
export const GetAllAdmins = createAsyncThunk("users?role=id", async () => {
  try {
    let response = await url.get("users?role=610a0f1dc61edfcf62d62bb1");
    return response.data;
  } catch (err) {
    return err;
  }
});

const initialState: TContacts = {
  Contacts: [],
  ContactCreateFor: "User",
  Admins: [],
  UserAllow: true,
};
const ContactSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    SetCreateContact: (state, action) => {
      state.ContactCreateFor = action.payload;
    },
    SetUserAllow:(state,action)=>{
      state.UserAllow = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(GetAllContacts.fulfilled, (state, action) => {
      state.Contacts = action.payload.data;
      return state;
    });
    builder.addCase(GetAllAdmins.fulfilled, (state, action) => {
      state.Admins = action.payload.data;
      return state
    });
  },
});
export const { SetCreateContact,SetUserAllow } = ContactSlice.actions;
export default ContactSlice.reducer;
