import React, { memo } from "react";
import classes from "stylesheet.module.css";
type FloatingBoxProps = {
  children: React.ReactNode;
  style: Object;
  className: string;
};
export const FloatingBox = memo(
  ({ children, style = {}, className = "" }: FloatingBoxProps) => {
    return (
      <div className={`${classes[className]} ${classes.aside}`} style={style}>
        {children}
      </div>
    );
  }
);
