import React, { useEffect,useState } from "react";
import { OuterContainer, InnerContainer } from "global.style";
import { PortfolioJourney } from "components/screen-components/PortfolioJourney";
import { MonthlySavings, TMonthlySaving } from "components/screen-components/Calculator/Spending/MonthlySavings";
import { BalanceSheet } from "components/screen-components/Calculator/Spending/BalanceSheet";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/store";
import {
  CalculateAdminLiabilities,
  CalculateMonthlyCashFlow,
} from "utils/helperFunctions";
import { SetCalData, SaveCalData } from "store/Slices/CalculatorSlice";
import { Row,Col } from "react-bootstrap";
import { BottomGradient } from "components/screen-components/Calculator/Spending/BottomGradient";
import "./style.css"
import DataOverview from "components/screen-components/Calculator/Spending/DataOverview";
import { GradientButton } from "components/ui/Buttons/GradientButton";
import { useNavigate } from "react-router-dom";
import { TSheetData } from "components/screen-components/Calculator/Spending/BalanceSheet";
import ClientOverview from "components/screen-components/Calculator/Spending/ClientOverview";
export const Spending = () => {
  const userAllow = useSelector((state:RootState)=>state.contacts.UserAllow)
  const caldata = useSelector((state: RootState) => state.calculator);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const handleSaveData=async({BalanceSheet,MonthlySavings}:{BalanceSheet:TSheetData,MonthlySavings:TMonthlySaving})=>{
    let totalIncome =
      MonthlySavings.NetSalaryYours +
      MonthlySavings.NetSalaryPartner +
      MonthlySavings.OtherIncome +
      MonthlySavings.RentalIncome;

    let liabilities = CalculateAdminLiabilities(
      caldata.BorrowingCalculator.PersonalLiabilities.CreditCards,
      caldata.Equity.PPR,
      caldata.Equity.Properties
    );
    let monthlysaving = totalIncome - (MonthlySavings.TotalExpenses + liabilities) ;
    let monthlycashflow = CalculateMonthlyCashFlow(
      totalIncome,
      MonthlySavings.TotalExpenses,
      caldata.BorrowingCalculator.PersonalLiabilities.CreditCards,
      caldata.Equity.PPR,
      caldata.Equity.Properties
    );
    let extracash = BalanceSheet.ExtraCash.reduce((total,nextItem)=>{
      return total+=nextItem.ExtraCash
    },0)
    let cashonHand = BalanceSheet.AccountYours +
    BalanceSheet.AccountPartner +
    monthlycashflow +
    extracash +
    caldata.Equity.Cash || 0.0
    let newcalbody = { ...caldata };
    newcalbody = {
      ...newcalbody,
      BorrowingCalculator:{
        ...newcalbody.BorrowingCalculator,
        MonthlyCashFlow:monthlycashflow,
        TotalExpenses: MonthlySavings.TotalExpenses,
        UseAverageAustralian: MonthlySavings.UseAverageAustralian,
        UseBasiq: MonthlySavings.UseBasiq,
        UseManual: MonthlySavings.UseManual,
        IncomeDetails:{
          ...newcalbody.BorrowingCalculator.IncomeDetails,
          YourIncome: {
            ...newcalbody.BorrowingCalculator.IncomeDetails.YourIncome,
            value: MonthlySavings.NetSalaryYours * 12,
          },
          PartnerIncome: {
            ...newcalbody.BorrowingCalculator.IncomeDetails.PartnerIncome,
            value: MonthlySavings.NetSalaryPartner * 12,
          },
          OtherIncome: {
            ...newcalbody.BorrowingCalculator.IncomeDetails.OtherIncome,
            value: MonthlySavings.OtherIncome * 12,
          },
          UserPartner: MonthlySavings.NetSalaryPartner > 0 ? true : false,
        }
      },
      StrategicSpending:{
        ...newcalbody.StrategicSpending,
        AccountYours:BalanceSheet.AccountYours,
        AccountPartner:BalanceSheet.AccountPartner,
        EquityGains:BalanceSheet.EquityGains,
        ExtraCash:BalanceSheet.ExtraCash,
        CashOnHand:cashonHand,
        TotalLiabilities: liabilities,
        CapitalGrowth: BalanceSheet.CapitalGrowth, 
        Balance:caldata.Equity.UsableEquity  + cashonHand, //+ Math.abs(monthlycashflow)

        Income: totalIncome,
        NetSalaryYours: MonthlySavings.NetSalaryYours,
        NetSalaryPartner: MonthlySavings.NetSalaryPartner,
        OtherIncome:MonthlySavings.OtherIncome,
        RentalIncome: MonthlySavings.RentalIncome || 0,
        MonthlySavings: monthlysaving,
        
      }
    }
    dispatch(SetCalData(newcalbody));
    await SaveCalData(newcalbody); 
  }
  const updateProfile = async (event: React.MouseEvent<HTMLButtonElement>) => {
    let newcalobj = { ...caldata };
    newcalobj = {
      ...newcalobj,
      Progress: {
        ...newcalobj.Progress,
        completed: 3,
        current: 4,
      },
    };

    SaveCalData(newcalobj);
    dispatch(SetCalData(newcalobj));
    navigate(`/Clients/Summary?id=${newcalobj.VendorId}`);
  };
  useEffect(() => {
    let BalanceSheet:TSheetData={
      EquityGains: caldata.StrategicSpending.EquityGains,
      Balance:caldata.StrategicSpending.Balance,
      CashOnHand: caldata.StrategicSpending.CashOnHand,
      AccountYours: caldata.StrategicSpending.AccountYours,
      AccountPartner: caldata.StrategicSpending.AccountPartner,
      ExtraCash: caldata.StrategicSpending.ExtraCash,
      CapitalGrowth: caldata.StrategicSpending.CapitalGrowth, 
      TotalLiabilities: caldata.StrategicSpending.TotalLiabilities,
    } 
    let monthlySaving:TMonthlySaving={
      MonthlySavings: caldata.StrategicSpending.MonthlySavings || 0,
      Income: caldata.StrategicSpending.Income || 0,
      NetSalaryYours:caldata.StrategicSpending.NetSalaryYours || 0,
      NetSalaryPartner: caldata.StrategicSpending.NetSalaryPartner || 0,
      RentalIncome: caldata.StrategicSpending.RentalIncome || 0,
      OtherIncome: caldata.StrategicSpending.OtherIncome || 0, 
      UseBasiq: caldata.BorrowingCalculator.UseBasiq,
      UseAverageAustralian: caldata.BorrowingCalculator.UseAverageAustralian,
      UseManual: caldata.BorrowingCalculator.UseManual,
      TotalExpenses: caldata.BorrowingCalculator.TotalExpenses,
    }
    handleSaveData({BalanceSheet,MonthlySavings:monthlySaving});
  }, []);
 
  return (
    <OuterContainer>
      <InnerContainer width="100%" bg={true} padding="1rem 15px 3rem 15px">
        <PortfolioJourney currentActive="Spending" />
        {userAllow? 
        <>
         <div className="flex flex-col !gap-[20px] mt-[20px]">
          <BalanceSheet handleSaveData={handleSaveData}/>
          <MonthlySavings handleSaveData={handleSaveData}/>
        </div>
        <Row> 
          <Col xs={8}>
          </Col>
          <Col xs={4}>
          </Col>
        </Row> 
        <DataOverview />
       
        <div className="max-w-[82.7%] mt-4">
        <BottomGradient/>
        </div>
        </>:<>
        <ClientOverview/>
        
        </>}
       
        <div className="relative flex justify-end mt-9">
          <GradientButton
            onClick={updateProfile}
            style={{
              position: "fixed",
              bottom: "31px",
              right: "94px",
              width: "89px !important",
            }}
            width="89px"
            txt="Next"
          />

          <GradientButton
            style={{
              position: "fixed",
              bottom: "31px",
              right: "196px",
              width: "89px !important",
            }}
            onClick={() => {
              navigate(-1)
            }}
            width="89px"
            txt="Back"
          />
        </div>
      </InnerContainer>
    </OuterContainer>
  );
};
