import { Chart as ChartJS, ArcElement, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Pie, Doughnut } from "react-chartjs-2";

const Chart = ({ data, options, type = "Pie" }:{data:any,options:any,type:string}) => {
  ChartJS.register(ArcElement, ChartDataLabels, Legend);
  ChartJS.defaults.font.size = 16;
  ChartJS.defaults.plugins.legend.display = true;
  ChartJS.defaults.font.family = "Inter";

  return (
    <>
      {type === "Pie" ? (
        <Pie
          data={data}
          options={options}
          style={{ width: "100px !important", height: "100px !important" }}
          className="Pie"
        />
      ) : (
        <Doughnut
          data={data}
          options={options}
          style={{ width: "100px !important", height: "100px !important" }}
          className="donut"
        />
      )}
    </>
  );
};

export default Chart;
