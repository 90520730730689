/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, memo, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SpendingTableContainer } from "global.style";
import { ISpendingTableData } from "hooks/useGenerateSpendingData";
import { TExpandedRows } from "hooks/useGenerateSpendingData";
import { commaStringSeparatorsWithDollors } from "utils/helperFunctions";
import { ISpendingRowsData } from "hooks/useGenerateSpendingData";
import ArrowIcon from "assets/images/arrowIcon1.png";
import FilterIcon from "assets/images/filter.png";
import { GetTableData } from "utils/helperFunctions";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { useGenerateSpendingData } from "hooks/useGenerateSpendingData";
import { useDispatch } from "react-redux";
import { TSpendingData } from "types";
import { SetCalData, SaveCalData } from "store/Slices/CalculatorSlice";
interface SpendingTableProps {
  setCurrentColumn: React.Dispatch<React.SetStateAction<number>>;
}
export const SpendingTable = memo(
  ({ setCurrentColumn }: SpendingTableProps) => {
    const Calstate = useSelector((state: RootState) => state.calculator);
    const [TableScrollPosition, setTableScrollPosition] = useState<number>(0);
    const [expandedRows, setexpandedRows] = useState<TExpandedRows[]>([
      {
        name: "Equity Gain",
        open: false,
      },
      {
        name: "Income",
        open: false,
      },
      {
        name: "Liabilities",
        open: false,
      },
      {
        name: "Living Expenses",
        open: false,
      },
    ]);
    const dispatch = useDispatch();
    const [FilterBy, setFilterBy] = useState<string>("ByMonth");

    const {
      GenerateData,
      sheetmonths,
      SpendingTableRowsValues,
    }: {
      TableData: ISpendingTableData[];
      GenerateData: any;
      sheetmonths: any;
      SpendingTableRowsValues: ISpendingRowsData;
    } = useGenerateSpendingData({
      expandedRows,
    });

    const userCalData = useSelector((state: RootState) => state.calculator);

    const [tableData, settableData] = useState<ISpendingTableData[]>([]);
    const [monthData,setmonthsData] = useState<ISpendingTableData[]>([])
    const [expenseData,setexpenseData] = useState<ISpendingTableData[]>([])
    const [incomeData,setincomeData] = useState<ISpendingTableData[]>([])
    const [liabilitiesData,setliabilitiesData] = useState<ISpendingTableData[]>([])
    const [cashData,setcashData] = useState<ISpendingTableData[]>([])

    const TableRef = useRef<any>();

    const DateBodyTemplate = (rowData: ISpendingTableData, index: number) => {
      return (
        <>
          <div
            className={`flex justify-center items-center px-[5px] !h-[60px] ${rowData.className}`}
          >
            <p>
              {commaStringSeparatorsWithDollors(
                rowData.values[index + 1]?.value
              )}
            </p>
          </div>
        </>
      );
    };
    const ProductBodyTemplate = (rowData: ISpendingTableData) => {
      const filterexpandedRow = expandedRows?.filter(
        (item) => item?.name === rowData?.headingName
      );

      const HandleToggle = (event: any) => {
        let Expandedrows = [...expandedRows];
        let updatedRows = Expandedrows?.map((item) => {
          if (item.name === rowData.headingName) {
            return {
              ...item,
              open: !item.open,
            };
          } else {
            return item;
          }
        });
        let tabledata = GetTableData(
          updatedRows,
          userCalData,
          SpendingTableRowsValues,
          sheetmonths
        );
        settableData(tabledata);
        setexpandedRows(updatedRows);
      };
      return (
        <div
          className={`flex justify-between items-center px-[18px] !h-[60px] border-[white] border-r-2  ${rowData.className}`}
        >
          <p className="flex gap-[10px]">
            {rowData.expanded && (
              <>
                <i
                  onClick={HandleToggle}
                  className={`pi ${
                    filterexpandedRow[0]?.open ? "pi-angle-up" : "pi-angle-down"
                  }`}
                ></i>
              </>
            )}
            {rowData.headingName}
          </p>
          <div className="min-w-[100px] flex justify-center">
            <p>
              {commaStringSeparatorsWithDollors(rowData?.values[0]?.value || 0)}
            </p>
          </div>
        </div>
      );
    };

    const NameHeader = (props: any) => {
      return (
        <div className="flex justify-between w-full py-3">
          <div className="flex flex-col justify-center items-center gap-[5px] opacity-0">
            <p className="!text-[13px]">Current</p>
            <p className="!text-[13px]">Dec-2023</p>
          </div>
          <div className="flex flex-col justify-center items-center gap-[5px] min-w-[130px] ">
            <p className="!text-[13px]">Current</p>
            <p className="!text-[13px]">
              {props?.props?.value[0]?.values[0]?.date || "Dec-2023"}
            </p>
          </div>
        </div>
      );
    };
    const FieldHeader = (rowData: any, index: number) => {
      return (
        <>
          {rowData?.props?.value[0]?.values[index + 1]?.date ? (
            <>
              <div className="flex flex-col justify-center items-center gap-[5px] ">
                <p className="!text-[13px]">Forcast</p>
                <p className="!text-[13px]">
                  {rowData?.props?.value[0]?.values[index + 1]?.date}
                </p>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      );
    };

    const generateColumns = () => {
      if (tableData.length === 0) {
        return null;
      }
      const firstRowValues = tableData[0]?.values;
      return firstRowValues?.slice(0, -1)?.map((value, index) => {
        return (
          <Column
            key={index}
            field={`values[${index}].value`}
            style={{ minWidth: "150px", padding: "0px" }}
            header={(rowData) => FieldHeader(rowData, index)}
            body={(rowData) => DateBodyTemplate(rowData, index)}
          />
        );
      });
    };
    let nextBtnNavigation = () => {
      var tableContainer = document.querySelector(".p-datatable-wrapper");

      if (tableContainer) {
        tableContainer.scrollBy({
          left: 150,
          behavior: "smooth",
        });

        setTableScrollPosition(tableContainer.scrollLeft + 150);
        setCurrentColumn((prevColumn) =>
          prevColumn >= 84 ? 84 : prevColumn + 1
        );
      }
    };

    let prevBtnNavigation = () => {
      var tableContainer = document.querySelector(".p-datatable-wrapper");
      if (tableContainer) {
        tableContainer.scrollBy({
          left: -150,
          behavior: "smooth",
        });

        setTableScrollPosition(tableContainer.scrollLeft - 150);
        setCurrentColumn((prevColumn) =>
          prevColumn <= 0 ? 0 : prevColumn - 1
        );
      }
    };
    let ApplyFilter = (value = "ByMonth") => {
      setFilterBy(value);
    };
    let handleLowerScroll = (e: any) => {
      setTableScrollPosition(e.target.scrollLeft);
      
    };
    const AutoSave = async (Data: TSpendingData[]) => {
      let newcalbody = { ...Calstate };
      newcalbody = {
        ...newcalbody,
        StrategicSpending: {
          ...newcalbody.StrategicSpending,
          Data: Data,
        },
      };

      dispatch(SetCalData(newcalbody));
      await SaveCalData(newcalbody);
    };
    const GenerateSpendingData = () => {
      let { data, tablerowvalues,months } = GenerateData();
      let tabledata = GetTableData(
        expandedRows,
        userCalData,
        tablerowvalues,
        months
      );
      settableData(tabledata);
      AutoSave(data);
    };
    useEffect(() => {
      GenerateSpendingData();
    }, [
      Calstate.BorrowingCalculator.TotalExpenses,
      Calstate.StrategicSpending.Balance,
      Calstate.StrategicSpending.CapitalGrowth,
      Calstate.StrategicSpending.TotalLiabilities,
    ]);

    return (
      <SpendingTableContainer className="w-full ">
        <div className="flex flex-wrap items-start justify-end gap-3 mb-3 filters-container">
          <span
            onClick={prevBtnNavigation}
            className={`prevNavigationBtn  ${
              TableScrollPosition >= 180 ? "active-img" : ""
            }`}
          >
            <img src={ArrowIcon} alt="" className="cursor-pointer" />
          </span>
          <div
            onClick={() => ApplyFilter("ByMonth")}
            className={FilterBy === "ByMonth" ? "filter-active" : ""}
          >
            By Month <img src={FilterIcon} alt="" />
          </div>
          <div
            onClick={() => ApplyFilter("Expenses")}
            className={FilterBy === "Expenses" ? "filter-active" : ""}
          >
            Expenses <img src={FilterIcon} alt="" />
          </div>
          <div
            onClick={() => ApplyFilter("Income")}
            className={FilterBy === "Income" ? "filter-active" : ""}
          >
            Income <img src={FilterIcon} alt="" />
          </div>
          <div
            onClick={() => ApplyFilter("Liabilties")}
            className={FilterBy === "Liabilties" ? "filter-active" : ""}
          >
            Liabilties <img src={FilterIcon} alt="" />
          </div>
          <div
            onClick={() => ApplyFilter("Cash")}
            className={FilterBy === "Cash" ? "filter-active" : ""}
          >
            Cash
            <img src={FilterIcon} alt="" />
          </div>
          <span
            onClick={nextBtnNavigation}
            className={`navigationBtn cursor-pointer ${
              TableScrollPosition >= 0 ? "active-img" : ""
            }`}
          >
            <img src={ArrowIcon} alt="" />
          </span>
        </div>
        <div ref={TableRef} onScroll={handleLowerScroll}>
          <DataTable value={tableData} scrollable className="spending-table ">
            <Column
              field="headingName"
              style={{
                minWidth: "300px",
                padding: "0px",
              }}
              header={NameHeader}
              body={ProductBodyTemplate}
              frozen={true}
              alignFrozen="left"
            ></Column>
            {generateColumns()}
          </DataTable>
        </div>
      </SpendingTableContainer>
    );
  }
);
