import React, { useState,useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { SideBarContainer, LogoContainer } from "../../../global.style";
import { useSetMenuItems } from '../../../hooks/useSetMenuItems';
import { Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import Logo from "../../../assets/images/logo.png";
import MiniLogo from "../../../assets/SVG/Hut.svg" 
import Logo1 from "../../../assets/images/invest-logic.png";
import { SidebarItem } from './SidebarItem/SidebarItem';
import { ToogleSidebar } from 'store/Slices/SidebarSlice';
import { useDispatch } from 'react-redux';
export const Sidebar = () => {
  const { MenuItems } = useSetMenuItems();
  const theme = useSelector((state: RootState) => state.theme.currentTheme)
  const sidbaropen = useSelector((state:RootState)=>state.sidebar.open)
  const dispatch = useDispatch()
  const [open] = useState<boolean>(true)

  const navigate = useNavigate();
  useEffect(()=>{
    function handleResize() {
     if(window.innerWidth>1000){
      dispatch(ToogleSidebar({open:true}))
     }else{
      dispatch(ToogleSidebar({open:false}))
     }
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  },[])
  return (
    <>
      <SideBarContainer  open={sidbaropen} marginleft={sidbaropen.toString()} >
        <div className="crossIcon"><i className="pi pi-times text-[white] cursor-pointer" onClick={() => {dispatch(ToogleSidebar({open:false}))}}></i></div>
        <LogoContainer
          open={sidbaropen}
          onClick={() => navigate("/")} 
          style={
            theme === "Investlogic"
              ? {
                marginBottom: "20px",
                paddingLeft: "10px",
                paddingRight: "10px",
                backgroundColor: "white",
                height: "auto",
              }
              : { marginBottom: "70px" }
          }
        >
          {theme === "Propvesting" &&
            <>
              {open ?
                <Image src={Logo} width={"120%"} />
                : <Image src={MiniLogo} width={"50px"} />
              }

            </>
          }
          {theme === "Investlogic" && (
            <div>
              <Image src={Logo1} width="100%" />
            </div>
          )}
        </LogoContainer>
        <div className='flex flex-col gap-[7px]' >
        {MenuItems.map((item, index) => (
          <SidebarItem  item={item} key={index}/>
        ))}
      </div>
      </SideBarContainer>
    </>
  )
}
