import React, { useState, useEffect, memo } from "react";
import AnimateHeight from "components/screen-components/AnimateHeight";
import { Row, Col } from "react-bootstrap";
import { InputNumbers } from "components/ui/InputNumbers";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/store";
import { TExpenseDetails } from "types";
import { SetCalData, SaveCalData } from "store/Slices/CalculatorSlice";
import { CalculateBasiqExpense,CalculateAdminLiabilities } from "utils/helperFunctions";
type BasicExpenseProps = {
  expenseheight: number | string;
};
type BasicExpenseType = {
  ExpenseDetail: TExpenseDetails;
};
export const BasiqExpenses = memo(({ expenseheight }: BasicExpenseProps) => {
  const dispatch = useDispatch();
  const userCaldata = useSelector((state: RootState) => state.calculator);
  const activepath = useSelector((state: RootState) => state.path.SidebarPath);
  const [initialRender, setInitialRender] = useState<boolean>(true);
  const [ExpenseDetailData, setExpenseDetailData] = useState<BasicExpenseType>({
    ExpenseDetail: userCaldata.BorrowingCalculator.ExpenseDetails,
  });
  const AutoSave = async () => {
    let newcalbody = { ...userCaldata };
    let hecpayments = userCaldata?.BorrowingCalculator?.PersonalLiabilities?.CreditCards?.reduce((total,nextItem)=>{
      if(nextItem?.type === "HECS"){
        return total+=nextItem.minimumPayment
      }else{
        return total
      }
    },0)
     let liabilities = CalculateAdminLiabilities(
      userCaldata.BorrowingCalculator.PersonalLiabilities.CreditCards,
      userCaldata.Equity.PPR,
      userCaldata.Equity.Properties
    );
    let totalBasiqExpense = CalculateBasiqExpense(ExpenseDetailData.ExpenseDetail) + hecpayments;
    let totalExpenses = userCaldata.BorrowingCalculator.UseAverageAustralian
      ? (userCaldata.BorrowingCalculator.LivingExpenses / 12) | 0
      : userCaldata.BorrowingCalculator.UseManual
      ? (userCaldata.BorrowingCalculator.ManualExpense / 12) | 0
      : (totalBasiqExpense) | 0;

    newcalbody = { 
      ...newcalbody,
      StrategicSpending: {
        ...newcalbody.StrategicSpending,
        MonthlySavings: userCaldata.StrategicSpending.Income - (liabilities+totalExpenses)
      },
      BorrowingCalculator: {
        ...newcalbody.BorrowingCalculator,
        TotalExpenses:totalExpenses,
        ExpenseDetails: ExpenseDetailData.ExpenseDetail,
        BasiqExpenses:totalBasiqExpense,
        OtherPayments:{
          ...newcalbody.BorrowingCalculator.OtherPayments,
          value:ExpenseDetailData.ExpenseDetail.OtherPayment
        }
      },
    };
    dispatch(SetCalData(newcalbody));
    SaveCalData(newcalbody);
  };
  useEffect(()=>{
    if (!initialRender) {
      if(ExpenseDetailData.ExpenseDetail.OtherPayment!==userCaldata.BorrowingCalculator.OtherPayments.value){
        setExpenseDetailData({
          ...ExpenseDetailData,
          ExpenseDetail: {
            ...ExpenseDetailData.ExpenseDetail,
            OtherPayment:userCaldata.BorrowingCalculator.OtherPayments.value
          },
        });
      }
      
    }
  },[userCaldata.BorrowingCalculator.OtherPayments])
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
    } else {
      AutoSave();
    }
  }, [ExpenseDetailData,userCaldata?.BorrowingCalculator?.PersonalLiabilities?.CreditCards]);
  
  return (
    <AnimateHeight height={expenseheight}>
      <Row className="gap-y-[28px] mb-[10px]">
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Alcoholic beverages{" "}
            </p>
            <InputNumbers
              value={ExpenseDetailData.ExpenseDetail.AlcoholicBeverages}
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    AlcoholicBeverages: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px]  !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Clothing and Footwear{" "}
            </p>
            <InputNumbers
              value={ExpenseDetailData.ExpenseDetail.ClothingAndFootwear}
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    ClothingAndFootwear: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px]  !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Communication{" "}
            </p>
            <InputNumbers
              value={ExpenseDetailData.ExpenseDetail.Communication}
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    Communication: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px] !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Current Housing Costs{" "}
            </p>
            <InputNumbers
              value={ExpenseDetailData.ExpenseDetail.CurrentHousingCosts}
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    CurrentHousingCosts: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px] !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Domestic Fuel & Power
            </p>
            <InputNumbers
              value={ExpenseDetailData.ExpenseDetail.DomesticFuelAndPower}
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    DomesticFuelAndPower: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px] !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Education{" "}
            </p>
            <InputNumbers
              value={ExpenseDetailData.ExpenseDetail.Education}
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    Education: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px] !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Food & Non-Alcoholic Beverages{" "}
            </p>
            <InputNumbers
              value={ExpenseDetailData.ExpenseDetail.FoodNonAlcoholicBeverages}
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    FoodNonAlcoholicBeverages: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px] !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Household Furnishings & Equipment{" "}
            </p>
            <InputNumbers
              value={
                ExpenseDetailData.ExpenseDetail.HouseholdFurnishingsAndEquipment
              }
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    HouseholdFurnishingsAndEquipment: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px] !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Household Services & Operation{" "}
            </p>
            <InputNumbers
              value={
                ExpenseDetailData.ExpenseDetail.HouseholdServicesAndOperation
              }
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    HouseholdServicesAndOperation: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px] !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Medical Care & Health Expenses{" "}
            </p>
            <InputNumbers
              value={
                ExpenseDetailData.ExpenseDetail.MedicalCareAndHealthExpenses
              }
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    MedicalCareAndHealthExpenses: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px]  !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Personal Care{" "}
            </p>
            <InputNumbers
              value={ExpenseDetailData.ExpenseDetail.PersonalCare}
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    PersonalCare: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px] !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Recreation{" "}
            </p>
            <InputNumbers
              value={ExpenseDetailData.ExpenseDetail.Recreation}
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    Recreation: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px] !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Transport{" "}
            </p>
            <InputNumbers
              value={ExpenseDetailData.ExpenseDetail.Transport}
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    Transport: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px] !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Accountant Fees{" "}
            </p>
            <InputNumbers
              value={ExpenseDetailData.ExpenseDetail.AccountantFees}
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    AccountantFees: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px] !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Body Corporate Payments{" "}
            </p>
            <InputNumbers
              value={ExpenseDetailData.ExpenseDetail.BodyCorporatePayments}
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    BodyCorporatePayments: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px] !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Cash Gifts, Donations To Charity Nec{" "}
            </p>
            <InputNumbers
              value={
                ExpenseDetailData.ExpenseDetail.CashGiftDonationsToCharityNec
              }
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    CashGiftDonationsToCharityNec: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px] !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Fees Nec - Government{" "}
            </p>
            <InputNumbers
              value={ExpenseDetailData.ExpenseDetail.FeesNecGovernment}
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    FeesNecGovernment: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px] !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Fees Nec - Private
            </p>
            <InputNumbers
              value={ExpenseDetailData.ExpenseDetail.FeesNecPrivate}
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    FeesNecPrivate: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px] !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Financial Institution Charges{" "}
            </p>
            <InputNumbers
              value={
                ExpenseDetailData.ExpenseDetail.FinancialInstitutionCharges
              }
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    FinancialInstitutionCharges: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px] !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Hire Services Nec{" "}
            </p>
            <InputNumbers
              value={ExpenseDetailData.ExpenseDetail.HireServicesNec}
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    HireServicesNec: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px] !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Insurance Nec{" "}
            </p>
            <InputNumbers
              value={ExpenseDetailData.ExpenseDetail.InsuranceNec}
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    InsuranceNec: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px] !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Miscellaneous Goods Nec{" "}
            </p>
            <InputNumbers
              value={ExpenseDetailData.ExpenseDetail.MiscellaneousGoodsNec}
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    MiscellaneousGoodsNec: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px] !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Miscellaneous Goods Nfd
            </p>
            <InputNumbers
              value={ExpenseDetailData.ExpenseDetail.MiscellaneousGoodsNfd}
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    MiscellaneousGoodsNfd: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px] !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Miscellaneous Services Nec{" "}
            </p>
            <InputNumbers
              value={ExpenseDetailData.ExpenseDetail.MiscellaneousServicesNec}
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    MiscellaneousServicesNec: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px] !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Non-Holiday Accommodation{" "}
            </p>
            <InputNumbers
              value={ExpenseDetailData.ExpenseDetail.NonHolidayAccommodation}
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    NonHolidayAccommodation: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px] !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Stationery Equipment Nfd{" "}
            </p>
            <InputNumbers
              value={ExpenseDetailData.ExpenseDetail.StationeryEquipmentNfd}
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    StationeryEquipmentNfd: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px] !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Travel goods, handbags & related{" "}
            </p>
            <InputNumbers
              value={ExpenseDetailData.ExpenseDetail.TravelGoodsHandbags}
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    TravelGoodsHandbags: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px] !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Other Payment{" "}
            </p>
            <InputNumbers
              value={ExpenseDetailData.ExpenseDetail.OtherPayment}
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    OtherPayment: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px] !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Union dues, professional association subscriptions{" "}
            </p>
            <InputNumbers
              value={ExpenseDetailData.ExpenseDetail.UnionDues}
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    UnionDues: Number(e.value),
                  },
                });
              }}
              classes={`${activepath==="Finance"?'!max-w-[380px]':'!max-w-[323px]'} `}
              inputClassName={"!mt-[7px]  !h-[40px] !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Cash Withdrawals{" "}
            </p>
            <InputNumbers
              value={ExpenseDetailData.ExpenseDetail.CashWithdrawals}
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    CashWithdrawals: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px] !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Bank Fees{" "}
            </p>
            <InputNumbers
              value={ExpenseDetailData.ExpenseDetail.BankFees}
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    BankFees: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px] !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              Loan Interest{" "}
            </p>
            <InputNumbers
              value={ExpenseDetailData.ExpenseDetail.LoanInterest}
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    LoanInterest: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px] !bg-transparent "}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <div className="flex flex-col">
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">
              External Transfers{" "}
            </p>
            <InputNumbers
              value={ExpenseDetailData.ExpenseDetail.ExternalTransfers}
              onChange={(e) => {
                setExpenseDetailData({
                  ...ExpenseDetailData,
                  ExpenseDetail: {
                    ...ExpenseDetailData.ExpenseDetail,
                    ExternalTransfers: Number(e.value),
                  },
                });
              }}
              inputClassName={"!mt-[7px] !h-[40px] !bg-transparent "}
            />
          </div>
        </Col>
      </Row>
    </AnimateHeight>
  );
});
