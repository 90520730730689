import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import url from "../../config"

type TPinderData = {
  currentPinderState:string
};
type Tattachments = {

}
type TAdminPropertyChangesCommercial = {
  
}
type TAdminPropertyChangesResidential = {
  
}
type TCommercialpropertyDetails = {

}
type TResidentialpropertyDetails = {

}
type TChangeFields = {
  changeDate:number;
  event:string;
  fieldname:string;
  newvalue:string
  oldvalue:string;
}
type TpropertyHistory = {
  AdminId:string;
  AdminName:string;
  ChangeFields:TChangeFields[];
  propertyId:string

}
type TpropertyDataResponse = {
  AdminPropertyChanges:TAdminPropertyChangesCommercial[] | TAdminPropertyChangesResidential[],
  attachments:Tattachments[],
  propertyDetails:TCommercialpropertyDetails | TResidentialpropertyDetails,
  propertyHistory:TpropertyHistory[]
}
export type TPinderPropertyResponse = {
  data:TpropertyDataResponse[],
    pagination:{
        page:number;
        perPage:number;
        total:number
    }
}
const initialState: TPinderData = {
    currentPinderState:"residential"
};
export const GetFilters = async(CurrentPinderState:string) =>{
try{
  let response = await url.get(`type-properties-totals/${CurrentPinderState}`)
  return response.data
}
catch(err){

}
}
export const GetPinderProperties = async(urls:string,queryparams:string)=>{
  try{
    let response = await url.get(`${urls}${queryparams}`)
    return response.data
  }
  catch(err){
  }
}
export const SaveLocalReports=async({propertyType,body}:{propertyType:string,body:any})=>{
  try{
    let response = await url.post(`save-report-local-properties/${propertyType}`,body)
    return response.data
  }
  catch(err){
  }
}
export const SendMail=(EmailData:any,email:any,subject:any)=>{

  const bodyObj = EmailData;
  const body = Object.entries(bodyObj)
  .map(([key, value]) => `${key}: ${value}`)
  .join('\n');
  const encodedEmail = encodeURIComponent(email);
  const encodedSubject = encodeURIComponent(subject);


  // const uint8Array = new TextEncoder().encode(body);
  // const compressedUint8Array = pako.gzip(uint8Array);
  // const compressedArray = Array.from(new Uint8Array(compressedUint8Array));
  // const base64CompressedBody = btoa(compressedArray.map((item) => String.fromCharCode(item)).join(''));
  const encodedBody = encodeURIComponent(body);
  const mailtoLink = `mailto:${encodedEmail}?subject=${encodedSubject}&body=${encodedBody}`;
  window.location.href = mailtoLink;
    }
const PinderSlice = createSlice({
  name: "pinder",
  initialState,
  reducers: {
    SetCurrentPinderState:(state,action:PayloadAction<{currentPinderState:string}>)=>{
        state.currentPinderState = action.payload.currentPinderState
    }
  },
  extraReducers(builder) {},
});
export const {SetCurrentPinderState} = PinderSlice.actions
export default PinderSlice.reducer;
