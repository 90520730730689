import React, { useState, useEffect, useReducer, useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import moment from "moment";
import { TSpendingData } from "types";
import { GenerateSpendingData } from "utils/helperFunctions";
export type valueType = {
  value: number;
  date: String;
};
type props = {
  expandedRows:TExpandedRows[],
 
};
export interface ISpendingTableData {
  product: string;
  headingName: string;
  columnColor?: string;
  className?: string;
  values: valueType[];
  expanded: Boolean;
}
export type TExpandedRows = {
  name: String;
  open: Boolean;
};
export interface ISpendingRowsData {
  balanceSheetArrValues: valueType[];
  cashOnHandArrValues: valueType[];
  AccountYourArrValues: valueType[];
  AccountPartnerArrValues: valueType[];
  liquidassetsArrValues: valueType[];
  extraCashArrValues: valueType[];
  PPRArrValues: valueType[];
  EquityGainArrValues: valueType[];
  PPRPrincipalArrValues: valueType[];
  MonthlySavingsArrValues: valueType[];
  IncomeArrValues: valueType[];
  NetSalaryYoursArrValues: valueType[];
  NetSalaryPartnerArrValues: valueType[];
  RentalIncomeArrValues: valueType[];
  OtherIncomeArrValues: valueType[];
  LiabilitiesArrValues: valueType[];
  PPRMortgageArrValues: valueType[];
  TotalExpenseArrValues: valueType[];
}

export const useGenerateSpendingData = ({expandedRows }: props) => {
  const Calstate = useSelector((state: RootState) => state.calculator);
  const [TableData, setTableData] = useState<ISpendingTableData[]>([]);
  const [sheetmonths,setsheetmonths] = useState<any>([])
  const [SpendingTableRowsValues, setSpendingTableRowsValues] =
    useState<ISpendingRowsData>({
      balanceSheetArrValues: [],
      cashOnHandArrValues: [],
      AccountYourArrValues: [],
      AccountPartnerArrValues: [],
      liquidassetsArrValues: [],
      extraCashArrValues: [],
      PPRArrValues: [],
      EquityGainArrValues: [],
      PPRPrincipalArrValues: [],
      MonthlySavingsArrValues: [],
      IncomeArrValues: [],
      NetSalaryYoursArrValues: [],
      NetSalaryPartnerArrValues: [],
      RentalIncomeArrValues: [],
      OtherIncomeArrValues: [],
      LiabilitiesArrValues: [],
      PPRMortgageArrValues: [],
      TotalExpenseArrValues: [],
    });
  
  const GenerateData = () => {
    const{data,tablerowvalues,months} = GenerateSpendingData(7,Calstate)
    setsheetmonths(months)
   setSpendingTableRowsValues(tablerowvalues)
    return {data,tablerowvalues,months};
  };
  return { TableData,GenerateData,sheetmonths,SpendingTableRowsValues };
};
