import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type SubSidebarItems = {
    "Vendors": boolean;
    "Portfolio Summary": boolean;
    "Contacts": boolean;
    "Agency Profile": boolean;
    "Lifestyle Goals": boolean;
    "Settings": boolean;
    "Pinder 2.0": boolean;
    "Clients": boolean;
};
type SidebarData = {
  open: boolean;
  SubSidebar: SubSidebarItems;
};
const initialState: SidebarData = {
  open: true,
  SubSidebar: {
      "Vendors": false,
      "Portfolio Summary": false,
      "Contacts": false,
      "Agency Profile": false,
      "Lifestyle Goals": false,
      "Settings": false,
      "Pinder 2.0": false,
      "Clients": false,
  },
};

const SidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
   
    ToogleSidebar: (state, action: PayloadAction<{ open: boolean }>) => {
      state.open = action.payload.open;
      return state
    },
    SetSubSidebar:(state,action:PayloadAction<SubSidebarItems>)=>{
        state.SubSidebar=action.payload
        return state
    }
  },
});

export const {  ToogleSidebar,SetSubSidebar } =
  SidebarSlice.actions;

export default SidebarSlice.reducer;
