/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect,memo,useCallback } from "react";
import { InnerContainer, PropertyHeader } from "global.style";
import AnimateHeight from "components/screen-components/AnimateHeight";
import { InputNumbers } from "components/ui/InputNumbers";
import { Row, Col } from "react-bootstrap";
import { CustomCheckBox } from "global.style";
import { useSelector, useDispatch } from "react-redux";
import { SetCalData, SaveCalData } from "store/Slices/CalculatorSlice";
import { RootState } from "store/store";
import { BasiqExpenses } from "../../BasiqExpenses";
import { TSheetData } from "../BalanceSheet";
export type TMonthlySaving = {
  MonthlySavings: number;
  Income: number;
  NetSalaryYours: number;
  NetSalaryPartner: number;
  RentalIncome: number;
  OtherIncome: number;
  UseBasiq: boolean;
  UseAverageAustralian: boolean;
  UseManual: boolean;
  TotalExpenses: number;
};
type MonthlySavingsProps={
  handleSaveData:({BalanceSheet,MonthlySavings}:{BalanceSheet:TSheetData,MonthlySavings:TMonthlySaving}) => void;
}
export const MonthlySavings = memo(({handleSaveData}:MonthlySavingsProps) => {
  const dispatch = useDispatch();
  const [open, setopen] = useState<boolean>(false);
  const userCalData = useSelector((state: RootState) => state.calculator);
  const [initialRender,setinitialRender] = useState<boolean>(true)
  const [MonthlySaving, setMonthlySaving] = useState<TMonthlySaving>({
    MonthlySavings: userCalData.StrategicSpending.MonthlySavings || 0,
    Income: userCalData.StrategicSpending.Income || 0,
    NetSalaryYours: userCalData.StrategicSpending.NetSalaryYours || 0,
    NetSalaryPartner: userCalData.StrategicSpending.NetSalaryPartner || 0,
    RentalIncome: userCalData.StrategicSpending.RentalIncome || 0,
    OtherIncome: userCalData.StrategicSpending.OtherIncome || 0, 
    UseBasiq: userCalData.BorrowingCalculator.UseBasiq,
    UseAverageAustralian: userCalData.BorrowingCalculator.UseAverageAustralian,
    UseManual: userCalData.BorrowingCalculator.UseManual,
    TotalExpenses: userCalData.BorrowingCalculator.TotalExpenses,
  });
  
  const height = open ? "auto" : 0;
  const AutoSave = async () => {
    let totalIncome =
      MonthlySaving.NetSalaryYours +
      MonthlySaving.NetSalaryPartner +
      MonthlySaving.OtherIncome +
      MonthlySaving.RentalIncome;
   
    let totalExpenses = MonthlySaving.UseAverageAustralian
      ? (userCalData.BorrowingCalculator.LivingExpenses / 12) | 0
      : MonthlySaving.UseManual
      ? (userCalData.BorrowingCalculator.ManualExpense / 12) | 0
      : userCalData.BorrowingCalculator.BasiqExpenses | 0;
      
    const BalanceSheetData:TSheetData={
      EquityGains: userCalData.StrategicSpending.EquityGains,
      Balance: userCalData.StrategicSpending.Balance,
      CashOnHand: userCalData.StrategicSpending.CashOnHand,
      AccountYours: userCalData.StrategicSpending.AccountYours,
      AccountPartner: userCalData.StrategicSpending.AccountPartner,
      ExtraCash: userCalData.StrategicSpending.ExtraCash,
      CapitalGrowth: userCalData.StrategicSpending.CapitalGrowth, 
      TotalLiabilities: userCalData.StrategicSpending.TotalLiabilities,
    }
   handleSaveData({BalanceSheet:BalanceSheetData,MonthlySavings:{...MonthlySaving,Income:totalIncome,TotalExpenses:totalExpenses}})
     
  };
  const handleSave=useCallback(()=>{
      AutoSave()
  },[MonthlySaving])
  useEffect(() => {
    if(initialRender){
      setinitialRender(false)
    }else{
      handleSave()
    }
  }, [handleSave]);
  return (
    <InnerContainer className="" padding="1.5rem 32px 1.5rem 32px">
      <PropertyHeader onClick={() => setopen(!open)}>
        <h1 className="card-title !text-[18px]">Monthly Savings</h1>
        {open ? (
          <div className="mb-[8px]">
            <i className="pi pi-minus" onClick={() => setopen(!open)}></i>
          </div>
        ) : (
          <div className="mb-[8px]">
            <i className="pi pi-plus" onClick={() => setopen(!open)}></i>
          </div>
        )}
      </PropertyHeader>
      <AnimateHeight height={height} classes="w-full max-w-[1025px]">
        <Row className="!mt-[20px]">
          <Col sm={3}>
            <InputNumbers
              onChange={(e) => {}}
              value={userCalData.StrategicSpending.MonthlySavings}
              inputClassName={
                "!text-left !rounded-[0px] !bg-[var(--grey-light2)] !border-[0px] "
              }
              classes="!w-[325px]"
            />
          </Col>
        </Row>
        <Row className="!mt-[20px]  gap-y-[20px]">
          <Col sm={4}>
            <div className="flex flex-col gap-[9px]">
              <p className="!text-[16px] font-[500]">Income:</p>
              <InputNumbers
                onChange={(e) => {
                  setMonthlySaving({
                    ...MonthlySaving,
                    Income: Number(e.value),
                  });
                }}
                value={userCalData.StrategicSpending.Income}
                inputClassName={
                  "!text-left !bg-[var(--primary-green)] !rounded-[0px] !border-[0px] !text-[white]"
                }
                classes="!w-[325px] !bg-[var(--primary-green) !text-[white]]"
              />
            </div>
          </Col>
          <Col sm={4}>
            <div className="flex flex-col gap-[9px]">
              <p className="!text-[16px] font-[500]">Net Salary (Yours)</p>
              <InputNumbers
                onChange={(e) => {
                  setMonthlySaving({
                    ...MonthlySaving,
                    NetSalaryYours: Number(e.value),
                  });
                }}
                value={MonthlySaving.NetSalaryYours}
                inputClassName={"!text-left !rounded-[0px]"}
                classes="!w-[325px]"
              />
            </div>
          </Col>
          <Col sm={4}>
            <div className="flex flex-col gap-[9px]">
              <p className="!text-[16px] font-[500]">Net Salary (Partners)</p>
              <InputNumbers
                onChange={(e) => {
                  setMonthlySaving({
                    ...MonthlySaving,
                    NetSalaryPartner: Number(e.value),
                  });
                }}
                value={MonthlySaving.NetSalaryPartner}
                inputClassName={"!text-left !rounded-[0px]"}
                classes="!w-[325px]"
              />
            </div>
          </Col>
          <Col sm={4}>
            <div className="flex flex-col gap-[9px]">
              <p className="!text-[16px] font-[500]">Rental Income</p>
              <InputNumbers
                onChange={(e) => {
                  setMonthlySaving({
                    ...MonthlySaving,
                    RentalIncome: Number(e.value),
                  });
                }}
                value={MonthlySaving.RentalIncome}
                inputClassName={"!text-left !rounded-[0px]"}
                classes="!w-[325px]"
              />
            </div>
          </Col>
          <Col sm={4}>
            <div className="flex flex-col gap-[9px]">
              <p className="!text-[16px] font-[500]">Other Income</p>
              <InputNumbers
                onChange={(e) => {
                  setMonthlySaving({
                    ...MonthlySaving,
                    OtherIncome: Number(e.value),
                  });
                }}
                value={MonthlySaving.OtherIncome}
                inputClassName={"!text-left !rounded-[0px]"}
                classes="!w-[325px]"
              />
            </div>
          </Col>
        </Row>
        <Row className="!mt-[20px]">
          <Col lg={12}>
            <div className="flex gap-[35px]">
              <div className="flex flex-col gap-[9px]">
                <p className="!text-[16px] font-[500]">Living Expenses:</p>
                <InputNumbers
                  onChange={(e) => {}}
                  value={userCalData.BorrowingCalculator.TotalExpenses}
                  inputClassName={
                    "!text-left !bg-[var(--primary-red)] !text-[white] !rounded-[0px] !border-[0px]"
                  }
                  classes="!w-[325px] !bg-[var(--primary-red)]"
                />
              </div>
              <div className="flex items-center gap-[15px] !mt-[20px]">
                <CustomCheckBox
                  inputId="UseAverageAustralianAnnual"
                  checked={userCalData.BorrowingCalculator.UseAverageAustralian}
                  onChange={(e) => {
                    if (e.checked !== undefined) {
                      setMonthlySaving({
                        ...MonthlySaving,
                        UseAverageAustralian: e.checked,
                        UseBasiq:
                          e.checked === false &&
                          userCalData.BorrowingCalculator.UseManual === false
                            ? true
                            : false,
                        UseManual: false,
                      });
                    }
                  }}
                ></CustomCheckBox>
                <label
                  className="font-[500] !text-[16px]"
                  htmlFor="UseAverageAustralianAnnual"
                >
                  Use Average Australian Annual Expenses
                </label>
              </div>
              <div className="flex items-center gap-[15px] !mt-[20px]">
                <CustomCheckBox
                  inputId="UseManual"
                  checked={userCalData.BorrowingCalculator.UseManual}
                  onChange={(e) => {
                    if (e.checked !== undefined) {
                      setMonthlySaving({
                        ...MonthlySaving,
                        UseAverageAustralian: false,
                        UseBasiq:
                          e.checked === false &&
                          userCalData.BorrowingCalculator
                            .UseAverageAustralian === false
                            ? true
                            : false,
                        UseManual: e.checked,
                      });
                    }
                  }}
                ></CustomCheckBox>
                <label className="font-[500] !text-[16px]" htmlFor="UseManual">
                  Use Manual
                </label>
              </div>
            </div>
          </Col>
        </Row>
        {userCalData.BorrowingCalculator.UseBasiq && (
          <div className="mt-[20px] w-full max-w-[1025px]">
            <BasiqExpenses expenseheight={"auto"} />
          </div>
        )}
      </AnimateHeight>
    </InnerContainer>
  );
});
