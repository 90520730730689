import { createSlice } from "@reduxjs/toolkit";
import { TUserSignup } from "../../types/index";
import url from "../../config";
import type { PayloadAction } from "@reduxjs/toolkit";
// import { setAuthToken } from '@/config';
type AuthData = {
  access_token: string;
  accountType: string;
  check_otp?: boolean;
  expires_in: string;
  otp: string | Number | null;
  token_type: string;
  userId: string;
};
type LoginData = {
  authData: AuthData;
};
type ResetProps = {
  email: string;
};
type OtpProps = {
  otp: string;
  password: string;
};

export const UserSignup = async (userData: TUserSignup) => {
  try {
    let response = await url.post("contacts/signup", userData);
    return response.data;
  } catch (err) {
    return err;
  }
};
export const UserSignin = async (params: {
  email: string;
  password: string;
}) => {
  try {
    let response = await url.post("auth/login", params);
    return response.data;
  } catch (err) {
    return err;
  }
};
export const GenerateUserOtp = async (params: {
  email: string;
  password: string;
  otp: null | number | string;
}) => {
  try {
    let response = await url.post("auth/generate-otp", params);
    return response.data;
  } catch (err) {
    return err;
  }
};
export const ResetPassword = async (props: ResetProps) => {
  try {
    let response = await url.post("forgetpass", props);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const SendOtp = async (props: OtpProps) => {
  try {
    let response = await url.post("otp", props);
    return response.data;
  } catch (err) {
    return err;
  }
};
export const logout = () => ({
  type: "LOGOUT",
});
const initialState: LoginData = {
  authData: {
    access_token: "",
    accountType: "Master Admin",
    check_otp: false,
    expires_in: "",
    otp: null,
    token_type: "",
    userId: "",
  },
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthData: (state, action: PayloadAction<AuthData>) => {
      state.authData = action.payload;
    },
  },
  extraReducers(builder) {},
});
export const { setAuthData } = authSlice.actions;
export default authSlice.reducer;
