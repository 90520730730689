import url from "../config";
export const GetAllSuburbs = async () => {
  try {
    let response = await url.get("median/suburb/all");
    return response.data;
  } catch (err) {
    return err;
  }
};
export const GetRetailMedianPrices = async () => {
  try {
    let response = await url.get("pricefinder/comm/median-prices/Retail");
    return response.data;
  } catch (err) {
    return err;
  }
};
export const GetOfficeMedianPrices = async () => {
  try {
    let response = await url.get("pricefinder/comm/median-prices/Offices");
    return response.data;
  } catch (err) {
    return err;
  }
};
export const GetResidentialMedianPrices = async (apiurl: any) => {
  try {
    let response = await url.get(apiurl);
    return response.data;
  } catch (err) {
    return err;
  }
};
export const GetDomainProperties = async (apiurl: any, data: any) => {
  try {
    let response = await url.post(apiurl, {
      propertiesData: data,
    });
    return response.data;
  } catch (err) {
    return err;
  }
};
export const GetCSVListings = async ({
  page,
  perPage,
}: {
  page: number;
  perPage: number;
}) => {
  try {
    let response = await url.get(
      `get-csv-file-listing?page=${page}&perPage=${perPage}`
    );
    return response.data;
  } catch (err) {
    return err;
  }
};
export const UploadPropertiesFile = async (formdata: any) => {
  try {
    let response = await url.post(`only-csv-file`, formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (err) {
    return err;
  }
};
