import React, { useCallback, useEffect, useState } from "react";
import { Container, InnerContainer } from "global.style";
import { Searchbar } from "components/screen-components/Searchbar";
import { SpaceRemove } from "utils/helperFunctions";
import { GetAllContact, GetAllUsers } from "store/Slices/UserSlice";

import { TAllContacts, TColumnHeaderTypes } from "types";
import { ListTable } from "components/screen-components/Tables/ListTable";
import { SortContact } from "utils/helperFunctions";
import { Button } from "components/ui/Buttons/Button";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/store";
import { SetCreateContact } from "store/Slices/ContactSlice";

export const ContactList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userdata = useSelector((state: RootState) => state.userProfile.data);
  const Clients = useSelector((state: RootState) => state.contacts.Contacts);
  const [AdminAllow, setAdminAllow] = useState(
    userdata.email === process.env.REACT_APP_ADMIN_EMAIL
  );
  const [SearchQuery, setSearchQuery] = useState<string>("");
  const [ClientsData, setClientsData] = useState<TAllContacts[]>(Clients);
  const [FilteredData, setFilteredData] = useState<TAllContacts[]>(Clients);
  const [loading, setLoading] = useState<boolean>(false);
  const [ColumnHeaders] = useState<TColumnHeaderTypes[]>([
    {
      name: "Name",
      classes: "name",
    },
    {
      name: "Email",
    },
    {
      name: "Contact",
    },
    {
      name: "Address",
    },
    {
      name: "Type",
      styles: { width: "7%" },
    },
    {
      name: "",
      styles: { width: "3%" },
    },
    {
      name: "",
      styles: { width: "4.9%" },
    },
  ]);
  const handleSearch = useCallback(() => {
    let FilteredData = ClientsData?.filter(
      (item) =>
        item?.full_name.toUpperCase().indexOf(SearchQuery.toUpperCase()) > -1 ||
        item?.email.toUpperCase().indexOf(SearchQuery.toUpperCase()) > -1 ||
        SpaceRemove(item?.mobile_no).indexOf(SpaceRemove(SearchQuery)) > -1
    );
    const data = SortContact(FilteredData);
    setFilteredData(data);
  }, [SearchQuery, ClientsData]);
  const GetClients = async () => {
    setLoading(true)
    let response = await GetAllContact(
      process.env.REACT_APP_OFFICEID as string
    );

    if (response.status) {
      let updated_data = response?.data?.filter((item: any) =>
        item?.contact_type?.includes("Contact")
      );
      const data = SortContact(updated_data)
      setClientsData(data);
      setFilteredData(data);
      setLoading(false)
    }
  };
  const GetAllContacts = async () => {
    setLoading(true);
    let response = await GetAllUsers();
    if (response?.status) {
      const updatedData: TAllContacts[] = response?.data?.map(
        (item: any, index: number) => {
          return {
            ...item,
          };
        }
      );
      let filteredData = updatedData.filter(
        (item) => item.email !== userdata.email
      );
      setClientsData(filteredData);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (AdminAllow) {
      GetAllContacts();
    } else {
      GetClients();
    }
  }, []);
  useEffect(() => {
    handleSearch();
  }, [SearchQuery, handleSearch]);
  return (
    <Container>
      <InnerContainer padding="1.8rem 0 1rem 0px">
        <div className="profile-container px-[32px]">
          <h1>Contacts</h1>
          <div className="content-right-container">
            <Searchbar
              SearchQuery={SearchQuery}
              setSearchQuery={setSearchQuery}
              placeholder="Search Vendors"
            />
            <Button
              paddingTop="10px"
              height="36px"
              onClick={(e) => {
                e.preventDefault();
                dispatch(SetCreateContact("User"));
                navigate("/CreateContact");
              }}
              txt="Create Contact"
            />
            {userdata.email === process.env.REACT_APP_ADMIN_EMAIL && (
              <Button
                paddingTop="10px"
                height="36px"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(SetCreateContact("Admin"));
                  navigate("/CreateAdmin");
                }}
                txt="Create Admin"
              />
            )}
          </div>
        </div>
        <ListTable
          loading={loading}
          ListData={FilteredData}
          SearchQuery={SearchQuery}
          ColumnHeaders={ColumnHeaders}
          Type="Contact"
          classes="!mt-[20px]"
          GetUpdatedData={() => {
            if (AdminAllow) {
              GetAllContacts();
            } else {
              GetClients();
            }
          }}
        />
      </InnerContainer>
    </Container>
  );
};
