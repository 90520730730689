import React, { useState, memo } from 'react'
import { SearchbarContainer, SearchbarText, SearchResultContainer } from 'global.style'
import { Avatar } from '../Avatar'
import { Link } from 'react-router-dom'
import { Loading } from '../../ui/Loading'
import { TVendorsData, SearchVendors } from 'store/Slices/VendorsSlice'
type SearchbarProps = {
  SearchQuery: string,
  setSearchQuery: Function,
  placeholder: string,
  FromHeader?: boolean
}
export const Searchbar = memo(({
  SearchQuery,
  setSearchQuery,
  placeholder,
  FromHeader = false
}: SearchbarProps) => {
  const [ShowSuggestion, setShowSuggestion] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [VendorsData, setVendorsData] = useState<TVendorsData[]>([]);
  let user_id = "610a0f1dc61edfcf62d62c52";
  const onSearch = async (query: string) => {
    setSearchQuery(query)
    if (FromHeader) {
      let wait = setTimeout(async () => {
        setLoading(true)
        setShowSuggestion(true);
        if (query !== "") {
          await SearchVendors(user_id, query).then((response) => response).then((result) => {
            setVendorsData(result.data || [])
            setLoading(false)
          })
        } else {
          setVendorsData([])
          setLoading(false)
          setShowSuggestion(false);
        }


      }, 250)
      return () => {
        clearTimeout(wait);
      };
    }
  }
  return (
    <>
      <SearchbarContainer>
        <i className="pi pi-search search-icon" />
        <SearchbarText
          value={SearchQuery}
          onChange={(e) => onSearch(e.target.value)}
          placeholder={placeholder}
        />
        {SearchQuery.length > 0 && <i className="pi pi-times" style={{ marginRight: "10px", cursor: "pointer", color: "#999" }} onClick={() => { setSearchQuery(""); setShowSuggestion(false) }}></i>}
      </SearchbarContainer>
      {FromHeader && ShowSuggestion && (
        <SearchResultContainer>
          {loading ? (
            <div style={{ height: "150px" }}
              className="flex items-center justify-center">
              <Loading height="60px" />
            </div>
          ) : (
            <>
              {VendorsData?.length > 0 ? (
                VendorsData?.map((item, idx) => {
                  return (
                    <Link to={`/Clients/Vendor/?id=${item?._id}`} key={idx}>
                      <div
                        key={idx}
                        className={`flex items-center py-3 ${idx < VendorsData?.length && "border-bottom"
                          }`}
                      >
                        <span>
                          <Avatar
                            width="30px"
                            height="30px"
                            imgSrc={item?.photo || ""}
                            fontSize="8.8px"
                            textColor="#fefefe"
                            text={item?.full_name || ""}
                          />
                        </span>
                        <span className="ml-3">
                          <div>{item?.full_name}</div>
                          <div>{item?.email}</div>
                        </span>
                      </div>
                    </Link>
                  );
                })
              ) : (
                <div
                  style={{ height: "150px", color: "#999" }}
                  className="flex items-center justify-center"
                >
                  No Result Found...
                </div>
              )}
            </>
          )}
        </SearchResultContainer>
      )}
    </>
  )
})
