import React from "react";
import { Dialog } from "primereact/dialog";
import { useFetchPinderSummary } from "hooks/useFetchPinderSummary";
const PropertyOverviewDialog = ({
  DialogVisible,
  setDialogVisible,
  propertyType
}: {
  DialogVisible: boolean;
  setDialogVisible: any;
  propertyType:"commercial" | "residential"
}) => {
    const { Total, Propertyoverview } = useFetchPinderSummary(
        `local-properties-totals/${propertyType}`
      );
  const renderHeader = (title: any) => {
    return (
      <div>
        <p>{title}</p>
      </div>
    );
  };
  return (
    <Dialog
      header={() => renderHeader("Overview")}
      className="pinder-Dialog"
      visible={DialogVisible}
      style={{ width: "408px", height: "550px", overflowY: "auto" }}
      modal
      onHide={() => setDialogVisible(false)}
    >
      <div className="pinderDialogBodyContainer">
        <div className="flex justify-between px-3 py-2 TotalContainer">
          <p>Total</p>
          <p>{Total}</p>
        </div> 
        {Propertyoverview?.map((item: any, index) => {
          return (
            <div key={index} className="flex justify-between px-3 py-2">
              <p style={{ width: "150px" }}>{propertyType==='commercial'?item?._id:item?.type} </p>
              <p>{item?.total}</p>
            </div>
          );
        })}
      </div>
    </Dialog>
  );
};

export default PropertyOverviewDialog;
