import React from 'react'
import { ButtonContainer } from '../../../../global.style'
import { Image } from 'react-bootstrap'
import EditIcon from "../../../../assets/images/Edit-icon.svg"
import { PdfIcon } from '../../../../components/screen-components/svgcomponents'

type ButtonProps = {
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void,
    txt: string,
    paddingTop?: string,
    height?: string,
    classes?:string,
    txtPrint?:boolean
}
export const Button = ({ onClick, txt, paddingTop, height,classes="",txtPrint=true }: ButtonProps) => {
    return (
        <ButtonContainer className={classes} onClick={onClick} paddingTop={paddingTop} height={height}>
            {txt === "Edit Contact" && <Image src={EditIcon} width="15px" height="15px" style={{ color: "white" }} />}
            {txt === "Generate PDF" && <PdfIcon color="white" />}
            {txtPrint === true && <span>{txt}</span>}  
        </ButtonContainer>
    )
}
