import React from "react";
import {
  InputNumberValueChangeEvent,
  InputNumberProps,
  InputNumber,
} from "primereact/inputnumber";
import { CurrencyFormatter } from "../../../utils/helperFunctions";
import { CustomInputNumber } from "../../../global.style";
interface CustomInputNumberProps {
  value: number;
  inputClassName?: string;
  onChange: (event: InputNumberValueChangeEvent) => void;
  disabled?: boolean;
  classes?: string;
  onMouseEnter?: Function;
  onMouseLeave?: Function;
  txtBlur?: boolean;
}
export const InputNumbers = ({
  value,
  onChange,
  inputClassName,
  disabled = false,
  classes,
  onMouseEnter,
  onMouseLeave,
  txtBlur = false,
  ...props
}: CustomInputNumberProps) => {
  return (
    <CustomInputNumber txtBlur={txtBlur}>
      <InputNumber
        className={classes}
        inputClassName={inputClassName}
        disabled={disabled}
        onMouseEnter={onMouseEnter && onMouseEnter()}
        onMouseLeave={onMouseLeave && onMouseLeave()}
        onValueChange={onChange}
        onFocus={(e) => (e.target.value = "")}
        onBlur={(e) => (e.target.value = `${CurrencyFormatter.format(value)}`)}
        value={value}
        mode="currency"
        currency="USD"
        locale="en-US"
        {...props}
      />
    </CustomInputNumber>
  );
};
