import React, { useState } from 'react'
import { InnerContainer, Container } from 'global.style'
import { Avatar } from 'components/screen-components/Avatar'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store/store'
import { TUserData, PasswordProps, ChangePassword, TUpdateUser, GetUserData, UpdateUser } from 'store/Slices/UserSlice'
import { GradientButton } from 'components/ui/Buttons/GradientButton'
import { InputTexts } from 'components/ui/InputTexts'
import { formatPhoneNumber } from 'utils/helperFunctions'

// @ts-ignore
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
export const Profile = () => {

    const dispatch = useDispatch()
    const userprofile = useSelector((state: RootState) => state.userProfile.data)
    const [userdata, setuserdata] = useState<TUserData>(userprofile)
    const [photo, setPhoto] = useState("")
    const [oldpassword, setoldpassword] = useState<string>("");
    const [newpassword, setnewpassword] = useState<string>("");

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === "mobile_no") {
            const formatNumber = formatPhoneNumber(e.target.value);
            setuserdata({ ...userdata, [e.target.name]: formatNumber });
        } else if (e.target.name === "telephone_no") {
            const formatNumber = formatPhoneNumber(e.target.value);
            setuserdata({ ...userdata, [e.target.name]: formatNumber });
        } else {
            setuserdata({ ...userdata, [e.target.name]: e.target.value });
        }
    }
    const UpdateData = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (
            userdata?.first_name !== "" ||
            userdata?.last_name !== "" ||

            userdata?.email !== "") {
            let body: TUpdateUser = {
                first_name: userdata.first_name,
                last_name: userdata.last_name,
                email: userdata.email,
                mobile_no: userdata?.mobile_no || "",
                telephone_no: userdata?.telephone_no || "",
                contact_type: userdata?.contact_type || "",
                contact_address: userdata?.contact_address || "",
                password: ""
            }
            if (photo) {
                body.photo = userdata.photo
            }
            let userid = sessionStorage.getItem("userId") as string
            let response = await UpdateUser(userid, body)
            if (response.status) {
                dispatch(GetUserData(userid) as any)
                NotificationManager.success("Profile Updated Successfully", "", 800);
            } else {
                NotificationManager.error(response.message, "Error", 800);
            }
        }
        else {
            NotificationManager.error("Required Fields Cannot Be Empty", "", 800);
        }
    }
    const UpdatePassword = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        try {
            let body: PasswordProps = {
                oldpassword: oldpassword,
                password: newpassword
            }
            let response = await ChangePassword(body);
            if (response.status) {

                NotificationManager.success(response.message, "", 800);
                setnewpassword("")
                setoldpassword("")
            } else {

                NotificationManager.error(response.message, "", 800);
            }

        } catch (err) {

            NotificationManager.error(err, "", 800);
        }
    }

    return (
        <>
            <Container>
                <InnerContainer>
                    <div className="profile-container">
                        <h1>Profile</h1>
                        <div className="avatar-container">
                            <Avatar
                                text="CH"
                                width="80px"
                                height="80px"
                                fontSize="23px"
                                textColor="#fefefe"
                                imgSrc={userprofile?.photo}
                                setImgSrc={setPhoto}
                                setuserdata={setuserdata}
                                data={userdata}
                                userProfile={true}
                            />

                            <div className="avatar-text-container">
                                <p className="main-text">
                                    {userprofile?.first_name}&nbsp;{userprofile?.last_name}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="gap-10 columns-1 lg:columns-3">
                            <div className='py-3'>
                                <div className='flex flex-col'>
                                    <p className="mb-2 input-label">First name</p>
                                    <InputTexts
                                        height='40px'
                                        type={"text"}
                                        name="first_name"
                                        value={userdata?.first_name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='py-3'>
                                <div className='flex flex-col'>
                                    <p className="mb-2 input-label">Last name</p>
                                    <InputTexts
                                        height='40px'
                                        type={"text"}
                                        name="last_name"
                                        value={userdata?.last_name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='py-3'>
                                <div className='flex flex-col'>
                                    <p className="mb-2 input-label">Email</p>
                                    <InputTexts
                                        type={"email"}
                                        height='40px'
                                        name="email"
                                        value={userdata?.email}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="gap-10 columns-1 lg:columns-3">
                            <div className='py-3'>
                                <div className='flex flex-col'>
                                    <p className="mb-2 input-label">Mobile No</p>
                                    <InputTexts
                                        height='40px'
                                        type={"text"}
                                        name="mobile_no"
                                        value={userdata?.mobile_no}
                                        onChange={handleChange}

                                    />
                                </div>
                            </div>
                            {/* <div className='py-3'>
                                <div className='flex flex-col'>
                                    <p className="mb-2 input-label">Phone Number</p>
                                    <InputTexts
                                        height='40px'
                                        type={"text"}
                                        name="telephone_no"
                                        value={userdata?.telephone_no}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div> */}
                            <div className='py-3'>
                                <div className='flex flex-col'>
                                    <p className="mb-2 opacity-0 input-label">Phone Number</p>
                                    <GradientButton
                                        classes="!h-[40px]"
                                        txt={"Update"}
                                        onClick={UpdateData}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-8 profile-container">
                        <h1>Update Password</h1>
                    </div>
                    <div>
                        <div className="gap-10 columns-1 lg:columns-3">
                            <div className='py-3'>
                                <div className='flex flex-col'>
                                    <p className="mb-2 input-label">Old Password</p>
                                    <InputTexts
                                        height='40px'
                                        type={"text"}
                                        name="mobile_no"
                                        value={oldpassword}
                                        onChange={(e) => setoldpassword(e.target.value)}

                                    />
                                </div>
                            </div>
                            <div className='py-3'>
                                <div className='flex flex-col'>
                                    <p className="mb-2 input-label">New Password</p>
                                    <InputTexts
                                        height='40px'
                                        type={"text"}
                                        name="telephone_no"
                                        value={newpassword}
                                        onChange={(e) => setnewpassword(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='py-3'>
                                <div className='flex flex-col'>
                                    <p className="mb-2 opacity-0 input-label">Phone Number</p>
                                    <GradientButton
                                        classes="!h-[40px]"
                                        txt={"Update"}
                                        onClick={UpdatePassword}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </InnerContainer>
            </Container>
            <NotificationContainer />
        </>
    )
}
