import React, { useState, useRef, useEffect } from "react";
import { TPinderDataProps } from "types";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { LocalResidentialColumnData } from "utils/constants";
import { useFetchPinderLocalData } from "hooks/useFetchPinderLocalData";
import { useFetchReportsData } from "hooks/useFetchReports";
import { SaveLocalReports } from "store/Slices/PinderSlice";
import url from "config";
import { Toast } from "primereact/toast";
import { commaStringSeparators } from "utils/helperFunctions";
import PropertyOverviewDialog from "../comman/PropertyOverviewDialog";
import OverviewImg from "assets/SVG/Overview.svg";
import OverviewImg2 from "assets/SVG/overview2.svg";
import EditReportImg from "assets/SVG/ReportEdit.svg";
import bookmarkimg from "assets/images/Bookmark.svg";
import ReportImg from "assets/SVG/Report.svg";
import Eyeclosed from "assets/SVG/eye-closed.svg";
import { GradientButton } from "components/ui/Buttons/GradientButton";
import { Loading } from "components/ui/Loading";
import { CustomOverLayPannel } from "pinder.style";
import { CustomPaginator } from "../CustomPaginator";
import PropertiesTable from "../comman/PropertiesTable";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import classes from "PinderPage.module.css";
import { CustomDropDown } from "pinder.style";
const LocalResidential = ({
  TopFilters,
  setTopFilters,
  Clear,
}: TPinderDataProps) => {
  const VendorsData = useSelector(
    (state: RootState) => state.contacts.Contacts
  );
  const saveRef = useRef<any>(null);
  const getRef = useRef<any>(null);
  const toast = useRef<any>(null);
  const columnRef = useRef<any>(null);
  const [DialogVisible, setDialogVisible] = useState(false);
  const [initialLoader, setinitialLoader] = useState(true);
  const [selectedCustomers, setSelectedCustomers] = useState<any>([]);
  const [editingRows, seteditingRows] = useState<any>([]);
  const [ReportName, setReportName] = useState("");
  const [ClientOptions, setClientOptions] = useState<any>([]);
  const [ReportOptions, setReportOptions] = useState<any>([]);
  const [Client, setClient] = useState("");
  const [ClientId, setClientId] = useState("");
  const [ReportId, setReportId] = useState("");

  const [LocalColumns, setLocalColumns] = useState(LocalResidentialColumnData);
  const [visibleColumns, setVisibleColumns] = useState(
    LocalResidentialColumnData
  );
  const [PropertiesID, setPropertiesId] = useState<any>([]);

  const [callingGetApi, setcallingGetApi] = useState(false);
  const { isLoading, TableData, totalResult } = useFetchPinderLocalData({
    url: "get-local-properties/residential?",
    TopFilters,
    propertyType: "Residential",
    callingGetApi,
    initialLoader,
  });
  let PerPage = TopFilters.PerPage === 0 ? 50 : TopFilters.PerPage;

  let totalPages = Math.ceil((totalResult || 0) / PerPage);
  const handleReportOptions = (data: any) => {
    setReportOptions(data);
  };
  const handleColumns = (data: any) => {
    if (data?.length > 0) {
      let visiblecols = data?.filter((item: any) => item.selected == true);
      setVisibleColumns(visiblecols);
      setLocalColumns(data);
    } else {
      setVisibleColumns(LocalResidentialColumnData);
      setLocalColumns(LocalResidentialColumnData);
    }
  };
  const { isReportLoading } = useFetchReportsData(
    "all-report-local",
    "residential",
    ReportId,
    ClientId,
    ReportOptions,
    TopFilters,
    setTopFilters,
    handleReportOptions,
    handleColumns
  );
  const showSuccess = (Message: any) => {
    toast.current.show({
      severity: "warn",
      life: 2000,
      content: (
        <div
          className="flex flex-column "
          style={{ paddingTop: "0px", paddingBottom: "0px", width: "100%" }}
        >
          <div className="text-center ">
            <p className="popup-warn-text">{Message}</p>
          </div>
        </div>
      ),
    });
  };
  const SaveData = async () => {
    try {
      let newobj = {
        client: Client,
        reportName: ReportName,
        filters: TopFilters,
        Columns: LocalColumns,
      };
      let response: any = await SaveLocalReports({
        propertyType: "residential",
        body: newobj,
      });
      let propertyIds: any = [];
      TableData.map((item: any) => {
        propertyIds = [...propertyIds, item.propertyDetails.listing.id];
      });

      showSuccess(response?.message);
      if (response?.id) {
        let newobj2 = {
          clientId: Client,
          reportId: response?.id,
          reportName: ReportName,
          properties: propertyIds, //PropertiesID
        };
        let response2 = await url.post(
          "attach-client-reports/residential",
          newobj2
        );

        setLocalColumns(LocalResidentialColumnData);
        setVisibleColumns(LocalResidentialColumnData);
        setcallingGetApi((prevVal: any) => !prevVal);
        setClient("");
        setReportName("");
      }
    } catch (err) {
      showSuccess("Something Went Wrong");
    }
  };
  const DeleteReport = async () => {
    let body = {
      id: ReportId,
      client: ClientId,
    };
    try {
      let result = await fetch(
        `${process.env.REACT_APP_API_BASEURL}delete-report-properties/residential`, ///${ReportId}
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("Token")}`,
          },
          body: JSON.stringify(body),
        }
      );
      if (result) {
        setReportId("");
        setClientId("");
        setTopFilters({
          PostCode: [],
          Page: 1,
          PerPage: 0,
          MaxPrice: 0,
          MinPrice: 0,
          PropertyType: [],
          ListingType: [],
          Keyword: "",
          State: [],
        });

        showSuccess("report deleted");
      }
    } catch (err) {}
  };
  const EditReport = async () => {
    try {
      let newobj = {
        client: ClientId,
        filters: TopFilters,
        Columns: LocalColumns,
      };
      let response: any = await url.put(
        `update-report-local-properties/${ReportId}`,
        newobj
      );
      showSuccess(response?.message);
    } catch (err) {
      showSuccess("Something Went Wrong");
    }
  };
  const onColumnToggle = (header: any) => {
    const updatedColumns = LocalColumns?.map((column) => {
      if (column.header === header) {
        return { ...column, selected: !column.selected };
      }
      return column;
    });
    setLocalColumns(updatedColumns);

    let visblecolumns = updatedColumns?.filter((item) => item.selected == true);

    setVisibleColumns(visblecolumns);
  };
  useEffect(() => {
    if (initialLoader) {
      setinitialLoader(false);
    }
  }, [initialLoader]);
  useEffect(() => {
    if (Clear) {
      setClient("");
      setReportName("");
      setClientId("");
      setReportId("");
    }
  }, [Clear]);
  useEffect(() => {
    let newoptionsArr = VendorsData?.map((item) => {
      let newobj = {
        label: item?.full_name,
        value: item?._id,
      };
      return newobj;
    });
    setClientOptions(newoptionsArr);
  }, [VendorsData]);
  return (
    <div>
      <Toast ref={toast} />
      <PropertyOverviewDialog
        DialogVisible={DialogVisible}
        setDialogVisible={setDialogVisible}
        propertyType="residential"
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
          marginTop: "20px",
        }}
      >
        <span className="result">
          Total Results: {commaStringSeparators(totalResult || 0,0)}
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "7px",
          }}
        >
          <button
            onClick={(e) => columnRef.current.toggle(e)}
            className="text-sm bg-white "
            style={{
              width: "35px",
              height: "36px",
              borderColor: "#cccccc",
              color: "#262660",
              fontFamily: "Inter",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
            type="button"
          >
            <img style={{ width: "15px" }} src={OverviewImg} />
          </button>
          <button
            onClick={() => setDialogVisible(true)}
            className="flex items-center justify-center text-sm bg-white "
            style={{
              width: "35px",
              height: "36px",
              borderColor: "#cccccc",
              color: "#262660",
              fontFamily: "Inter",
              justifyContent: "center",
              alignItems: "center",
            }}
            type="button"
          >
            <img style={{ width: "15px" }} src={OverviewImg2} />
          </button>
          {ClientId !== "" && ReportId == "" ? (
            <Button
              className={`flex justify-center items-center gap-2 text-sm bg-white ${classes.deleteReport}`}
              icon="pi pi-trash"
              type="button"
              severity="success"
              onClick={() => DeleteReport()}
            >
              Delete Shortlist
            </Button>
          ) : (
            ClientId !== "" &&
            ReportId !== "" && (
              <Button
                className={`flex justify-center items-center gap-2 text-sm bg-white ${classes.deleteReport}`}
                icon="pi pi-trash"
                type="button"
                severity="success"
                onClick={() => DeleteReport()}
              >
                Delete Shortlist
              </Button>
            )
          )}
          {ClientId !== "" && ReportId !== "" ? (
            <GradientButton
              onClick={EditReport}
              classes="text-sm"
              icon={
                <img
                  width="15px"
                  height="15px"
                  className="mr-1"
                  src={EditReportImg}
                />
              }
              style={{ width: "81px", height: "36px" }}
              txt="Edit"
            />
          ) : (
            <GradientButton
              onClick={(e) => {
                saveRef.current.toggle(e);
              }}
              classes="text-sm"
              icon={
                <img
                  width="15px"
                  height="15px"
                  className="mr-1"
                  src={bookmarkimg}
                />
              }
              style={{ width: "81px", height: "36px" }}
              txt="Save"
            />
          )}

          <GradientButton
            onClick={(e) => getRef.current.toggle(e)}
            classes="text-sm"
            icon={
              <img
                width="15px"
                height="15px"
                className="mr-1"
                src={ReportImg}
              />
            }
            style={{ width: "81px", height: "36px" }}
            txt="Clients"
          />
        </div>
        <CustomOverLayPannel ref={saveRef}>
          <div className="overlayPannelContainer">
            <p className="title">Save Data</p>

            <CustomDropDown
              filter
              width="100%"
              color="#ced4da"
              // className={classes.filterDropdown}
              value={Client}
              options={ClientOptions}
              onChange={(e) => {
                setClientId(e.value);
                setClient(e.value);
                setReportId("");
              }}
              placeholder="Client"
              className="mt-3 mb-3 !h-[40px]"
            />

            <InputText
              disabled={Client == ""}
              value={ReportName}
              onChange={(e) => setReportName(e.target.value)}
              placeholder="Title"
              className="!w-full !h-[40px]"
            />

            <div
              style={{
                width: "100%",
                display: "flex",
                gap: "10px",
                justifyContent: "flex-end",
              }}
            >
              <GradientButton
                onClick={(e) => {
                  setClient("");
                  setReportName("");
                  setTopFilters({
                    PostCode: [],
                    Page: 1,
                    PerPage: 0,
                    MaxPrice: 0,
                    MinPrice: 0,
                    PropertyType: [],
                    ListingType: [],
                    Keyword: "",
                    State: [],
                  });
                  setLocalColumns(LocalResidentialColumnData);
                  setVisibleColumns(LocalResidentialColumnData);
                }}
                classes="text-sm"
                icon={
                  <img
                    width="15px"
                    height="15px"
                    className="mr-1"
                    src={bookmarkimg}
                  />
                }
                style={{ width: "81px", height: "36px", marginTop: "30px" }}
                txt="Clear"
              />
              <GradientButton
                onClick={(e) => {
                  SaveData();
                  saveRef.current.hide();
                }}
                classes="text-sm"
                icon={
                  <img
                    width="15px"
                    height="15px"
                    className="mr-1"
                    src={bookmarkimg}
                  />
                }
                style={{ width: "81px", height: "36px", marginTop: "30px" }}
                txt="Save"
              />
            </div>
          </div>
        </CustomOverLayPannel>
        <CustomOverLayPannel ref={getRef}>
          <div className="overlayPannelContainer">
            <p className="mb-3 title">Client Shortlist</p>
            <CustomDropDown
              filter
              width="100%"
              color="#ced4da"
              value={ClientId}
              options={ClientOptions}
              onChange={(e) => {
                e.preventDefault();
                setClientId(e.value);
                setReportId("");
              }}
              placeholder="Select Client"
              className="!h-[40px] mt-3"
            />
            <div style={{ marginTop: "20px" }}>
              <CustomDropDown
                disabled={ClientId == ""}
                filter
                width="100%"
                color="#ced4da"
                value={ReportId}
                options={ReportOptions}
                onChange={(e) => {
                  setReportId(e.value);
                  setTimeout(() => {
                    getRef.current.hide();
                  }, 1000);
                }}
                placeholder="Select Shortlist"
                className="!h-[40px]"
              />
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <GradientButton
                onClick={(e) => {
                  setClientId("");
                  setReportId("");
                  setTopFilters({
                    PostCode: [],
                    Page: 1,
                    PerPage: 0,
                    MaxPrice: 0,
                    MinPrice: 0,
                    PropertyType: [],
                    ListingType: [],
                    Keyword: "",
                    State: [],
                  });
                  setLocalColumns(LocalResidentialColumnData);
                  setVisibleColumns(LocalResidentialColumnData);
                }}
                classes="text-sm"
                icon={
                  <img
                    width="15px"
                    height="15px"
                    className="mr-1"
                    src={bookmarkimg}
                  />
                }
                disabled={ClientId == ""}
                style={{ width: "81px", height: "36px", marginTop: "30px" }}
                txt="Clear"
              />
            </div>
          </div>
        </CustomOverLayPannel>
        <CustomOverLayPannel
          style={{ padding: "none !important" }}
          ref={columnRef}
        >
          <div style={{ height: "400px", overflowY: "auto", margin: "5px " }}>
            {LocalColumns?.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    onColumnToggle(item.header);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginRight: "20px",
                  }}
                >
                  <span style={{ fontSize: "14px" }}>{item.header}</span>
                  {item.selected ? (
                    <img
                      style={{ cursor: "pointer", width: "15px" }}
                      src={OverviewImg}
                    />
                  ) : (
                    <img
                      style={{ cursor: "pointer", width: "15px" }}
                      src={Eyeclosed}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </CustomOverLayPannel>
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <>{TableData?.length > 0 ? 
        <>
        <PropertiesTable 
        rows={Number(TopFilters.PerPage)}
        TableData={TableData}
        selectedCustomers={selectedCustomers}
        setSelectedCustomers={setSelectedCustomers}
        setcallingGetApi={setcallingGetApi}
        visibleColumns={visibleColumns}
        editingRows={editingRows}
        seteditingRows={seteditingRows}
        PropertiesID={PropertiesID}
        setPropertiesId={setPropertiesId}
        propertyType="residential"
        />
            <div>
            <CustomPaginator
              totalRecords={totalResult}
              Filters={TopFilters}
              FilterParamsData={TopFilters}
              setFilterParamsData={setTopFilters}
              totalPages={totalPages}
            />
          </div>
        </> : "No Data Found"}</>
      )}
    </div>
  );
};

export default LocalResidential;
