import React, { memo, useState, useMemo } from 'react'
import { TAllContacts,TAllUsers } from 'types'
import Table from "react-bootstrap/Table";
import { Pagination } from '../../Pagination';
import { TColumnHeaderTypes } from 'types';
import { ClientBody } from './ClientBody';
import { ContactBody } from './ContactBody';
import { Loading } from 'components/ui/Loading';
type ListTableProps = {
    ListData: TAllContacts[] ,
    SearchQuery: string,
    ColumnHeaders: TColumnHeaderTypes[],
    Type: "Client" | "Contact",
    classes?: string,
    GetUpdatedData?:Function,
    loading?:boolean
}
export const ListTable = memo(({ ListData ,SearchQuery, ColumnHeaders, Type, classes,GetUpdatedData,loading=false }: ListTableProps) => {
    let PageSize: number = 50;
    const [currentPage, setCurrentPage] = useState<number>(1);
    const nPages: number = ListData?.length > PageSize ? Math.ceil(ListData?.length / PageSize) : 1;

  
    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        if (SearchQuery !== "") {
            setCurrentPage(1);
        }
        return ListData?.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, ListData,PageSize,SearchQuery]);
    return (
        <>
            <Table responsive className={classes}>
                <thead>
                    <tr>
                        {ColumnHeaders?.map((item, index) => {
                            return (
                                <th className={item.classes} style={item.styles} key={index}>{item.name}</th>
                            )
                        })}
                    </tr>
                </thead>
                {loading ? <tbody >
                  <tr className='h-[100px] bg-orange-100'><td colSpan={ColumnHeaders.length}>
                  <Loading height="0" />
                    </td></tr>
                </tbody>: Type==="Client" ?<tbody> <ClientBody currentTableData={currentTableData} /></tbody> :<tbody> <ContactBody currentTableData={currentTableData} GetUpdatedData={GetUpdatedData} /> </tbody>}
                
                    
                   
                
            </Table>
            <div className="pagination-container">
                <Pagination
                    nPages={nPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            </div>
        </>
    )
})
