import React from "react";
import { Dialog } from "primereact/dialog";
import { PinderTable } from "pinder.style";
import { Column } from "primereact/column";
import { PropertyColumnData } from "utils/constants";
import moment from "moment";
const PropertyHistoryDialog = ({
  HistoryVisible,
  setHistoryVisible,
  propertyHistory,
}: {
  HistoryVisible: boolean;
  setHistoryVisible: any;
  propertyHistory: any;
}) => {

  const renderHeader = (title: any) => {
    return (
      <div>
        <p>{title}</p>
      </div>
    );
  };
  const DateBodyTemplate = (rowData:any) => {
    return <p>{moment(rowData?.createdAt).format("DD/MM/YYYY")}</p>;
  };
  const AdminBodyTemplate = (rowData:any) => {
    return <p>{rowData?.AdminId}</p>;
  };
  return (
    <Dialog
      header={() => renderHeader("History")}
      className="pinder-Dialog"
      visible={HistoryVisible}
      style={{
        width: "608px",
        height: "auto",
        minHeight: "550px",
        overflowY: "auto",
      }}
      modal
      onHide={() => setHistoryVisible(false)}
    >
      <div className="pinderDialogBodyContainer">
        <PinderTable
          paginator
          rows={10}
          editMode="cell"
          className="propertyDataTable"
          value={propertyHistory}
          tableStyle={{ width: "100%" }}
        >
          {PropertyColumnData.map((item, index) => {
            return (
              <Column
                key={index}
                field={item.field}
                header={item.header}
                style={item.style}
                body={
                  item.field == "changeDate"
                    ? DateBodyTemplate
                    : item.field == "AdminId" && AdminBodyTemplate
                }
              ></Column>
            );
          })}
        </PinderTable>
      </div>
    </Dialog>
  );
};

export default PropertyHistoryDialog;
