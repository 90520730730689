import React from 'react'
import classes from "stylesheet.module.css"
type InfoBoxProps = {
children:React.ReactNode,
show:boolean,
onMouseLeave?:any,
className?:string,
style?:Object
} 
export const InfoBox = ({
  children,
  show,
  onMouseLeave = () => {},
  className = "",
  style = {},
}:InfoBoxProps) => {
  return (
    <>
    {
          show ? (
            <span
              onMouseLeave={onMouseLeave}
              style={style}
              className={`${className} ${classes.InfoBox}`}
            > 
              {children}
            </span>
          ):<></>
    }
    </>
  )
}
