
import RevertImg from "assets/SVG/undo.svg";
import RevertImg2 from "assets/SVG/undo1.svg";
 const RevertProperty = ({rowData,propertyId,propertyType,setcallingGetApi}:{rowData:any,propertyId:any,propertyType:"commercial" | "residential",setcallingGetApi:any}) => {
 
  let check = rowData?.Background;
    const DeleteSaveProperty = async () => {
        try {
          let response = await fetch(
            `${process.env.REACT_APP_API_BASEURL}delete-local-properties/${propertyType}/${propertyId}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("Token")}`,
              },
            }
          );
          if (response.status == 201) {
            setcallingGetApi((prevVal:any) => !prevVal);
          }
        } catch (err) {}
      };
    return (
     <>
      <button
       
       onClick={() => DeleteSaveProperty()}
       style={{
           width: "30px",
           height: "30px",
           color: "#262660",
           fontFamily: "Inter",
           justifyContent: "center",
           alignItems: "center",
           background: "none",
           border: "none",
           outline: "none",
           display: "flex",
         }}
         type="button"
     >
       {check ? (
         <img src={RevertImg2} style={{ width: "20px" }} />
       ) : (
         <img src={RevertImg} style={{ width: "20px" }} />
       )}
     </button>
     </>
    );
  };
  export default RevertProperty