import React, { useState, useRef } from "react";
import classes from "stylesheet.module.css";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Toast } from "primereact/toast";
import { Loading } from "components/ui/Loading";
import {
  UserSignin,
  GenerateUserOtp,
  setAuthData,
} from "store/Slices/AuthSlice";
import { TSignupResponse, TOtpResponse } from "types";
import { setAuthToken } from "config";
import { useDispatch } from "react-redux";
import { setPath } from "store/Slices/PathSlice";
import { GetUserData } from "store/Slices/UserSlice";
import { GetSettingsData } from "store/Slices/SettingsSlice";
import { GetAllContacts, GetAllAdmins } from "store/Slices/ContactSlice";
import { GetUserCalData } from "store/Slices/CalculatorSlice";
// import { useGetDomainData } from "hooks/useGetDomainData";
import { setSelectedUser } from "store/Slices/UserSlice";
import { SetUserAllow } from "store/Slices/ContactSlice";
const SigninForm = () => {
  const toast = useRef<any>(null);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [verifyEmail, setVerifyEmail] = useState<boolean>(false);
  const [ShowPassword, setShowPassword] = useState<boolean>(false);
  const [resToken, setResToken] = useState<any>(null);
  const [Agree, setAgree] = useState<boolean>(
    localStorage.getItem("Agree") ? true : false
  );
  const [otp, setotp] = useState<string>("");
  const [accountType, setaccountType] = useState<string>("");
  const [userId, setuserId] = useState<string>("");
  const [resAccessToken, setAccessToken] = useState<string>("");
  // const {getSuburbStats} =useGetDomainData()
  const [loading,setloading] = useState<boolean>(false)
  const getVendors = async () => {};
  const handleSubmit = async () => {
    localStorage.setItem("Agree", JSON.stringify(Agree));
    const params = {
      email,
      password,
    };
    setVerifyEmail(true);
    let response: TSignupResponse = await UserSignin(params);
    if (response.access_token) {
      setloading(true)
      sessionStorage.setItem("accountType", response.accountType);
      sessionStorage.setItem("userId", response.userId);
      setaccountType(response.accountType);
      setuserId(response.userId);
      dispatch(setAuthData(response));
      let contactcheck = (response.accountType === "Contact Lite" || response.accountType === "Contacts") ? false:true
      dispatch(SetUserAllow(contactcheck))
      setAuthToken(response.access_token);
 
      const UserData=await dispatch(GetUserData(response.userId) as any);
      
      await dispatch(
        GetSettingsData(process.env.REACT_APP_OFFICEID as string) as any
      ); 
    //  await getSuburbStats()
      if (response.accountType === "Master Admin") {
        await dispatch(
          GetAllContacts(process.env.REACT_APP_OFFICEID as string) as any
        );
        await dispatch(GetAllAdmins() as any);
      }
      if (!response.check_otp) {
        sessionStorage.setItem("Token", response.access_token);
        localStorage.setItem("email", JSON.stringify(email));
        setTimeout(() => getVendors(), 500);
        if (response.accountType === "Master Admin") {
          dispatch(
            setPath({
              headerPath: "/Dashboard",
              SidebarPath: "Dashboard",
            })
          );
          setloading(false)
          navigation("/");
        } else {
          dispatch(
            setSelectedUser({
              Progress: [],
              contact_address: UserData?.payload?.data?.contact_address,
              contact_type: UserData?.payload?.data?.contact_type,
              email: UserData?.payload?.data?.email,
              first_name: UserData?.payload?.data?.first_name,
              full_name: UserData?.payload?.data?.full_name,
              isPrivate: UserData?.payload?.data?.isPrivate,
              last_name: UserData?.payload?.data?.last_name,
              mobile_no: UserData?.payload?.data?.mobile_no,
              photo: UserData?.payload?.data?.photo,
              telephone_no: UserData?.payload?.data?.telephone_no,
              user_id: response?.userId,
              _id: response?.userId,
            })
          );
          dispatch(
            setPath({
              headerPath: "/Clients/Profile",
              SidebarPath: "Dashboard",
            })
          );
         
          await dispatch(GetUserCalData(response.userId) as any);
          setloading(false)
          navigation(`/Clients/Profile?id=${response.userId}`);
        }
      } else {
        setloading(false)
        setAccessToken(response.access_token);
        setResToken("");
        setTimeout(() => {
          showSuccess("Email Verification Send");
        }, 2000);
      }
    } else {
      setResToken(response.message);
      setloading(false)
    }
  };
  const handleSubmitWithOtp = async () => {
    if (otp.length === 0) {
      setResToken("Enter OTP");
    } else {
      GenerateOtp();
    }
  };
  const GenerateOtp = async () => {
    const params = {
      email: email,
      password: password,
      otp: otp,
    };
    let response: TOtpResponse = await GenerateUserOtp(params);
    if (response?.status) {
      console.log(response);
      sessionStorage.setItem("Token", resAccessToken);
      setTimeout(() => getVendors(), 500);
      showSuccess(response?.message || "");
      localStorage.setItem("email", JSON.stringify(email));
      if (accountType === "Master Admin") {
        dispatch(
          setPath({
            headerPath: "/Dashboard",
            SidebarPath: "Dashboard",
          })
        );
        navigation("/");
      } else {
        dispatch(
          setPath({
            headerPath: "/Clients/Profile",
            SidebarPath: "Dashboard",
          })
        );
        await dispatch(GetUserCalData(userId) as any);
        navigation(`/Clients/Profile?id=${userId}`);
      }
    } else {
      setResToken(response.message);
    }
  };
  const showSuccess = (Message: string) => {
    toast.current.show({
      severity: "warn",
      life: 2000,
      content: (
        <div
          className="flex flex-column "
          style={{ paddingTop: "0px", paddingBottom: "0px", width: "100%" }}
        >
          <div className="text-center ">
            <p className="popup-warn-text">{Message}</p>
          </div>
        </div>
      ),
    });
  };
  return (
    <>
      <Toast ref={toast} />
      {loading ? (
        <div className="loadingScreen">
          
          <Loading height="60px" />
        </div>
      ) : !verifyEmail ? (
        <Form
          onSubmit={(e) => {
            e.preventDefault();
           
            handleSubmit();
          }}
        >
          <Form.Group className="mt-6 mb-3" controlId="signinForm.email">
            <Form.Control
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={classes.EmailInput}
              placeholder="Email Address"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="signinForm.password">
            <InputGroup className={classes.PasswordInputGroup}>
              <Form.Control
                type={ShowPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={classes.PasswordInput}
                placeholder="Password"
                required
              />
              <InputGroup.Text className={classes.passwordShowHide}>
                <span
                  onClick={() => setShowPassword(!ShowPassword)}
                  className={classes.showHideBtn}
                >
                  {ShowPassword ? "Hide" : "Show"}
                </span>
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <div className="flex mb-3 justify-content-between">
            {resToken && <p className={classes.hiddenMsg}>{resToken}</p>}
          </div>
          <Form.Group className="mb-3" controlId="signinForm.checkbox">
            <Form.Check>
              <Form.Check.Input
                type={"checkbox"}
                checked={Agree}
                onChange={() => setAgree(!Agree)}
                className={classes.CheckboxInput}
                required
              />
              <Form.Check.Label className={classes.CheckboxLabel}>
                I agree to all the statements included in the&nbsp;
                <Link to="/Terms" className={classes.link}>
                  Terms of use / Privacy policy.
                </Link>
              </Form.Check.Label>
            </Form.Check>
          </Form.Group>
          <Button className={classes.loginBtn} type="submit">
            Login
          </Button>
        </Form>
      ) : (
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmitWithOtp();
          }}
        >
          <Form.Group className="mt-6 mb-3" controlId="signinForm.email">
            <Form.Control
              type="text"
              value={otp}
              onChange={(e) => setotp(e.target.value)}
              className={classes.EmailInput}
              placeholder="Enter OTP"
              required
            />
          </Form.Group>
          {resToken && <p className={classes.hiddenMsg}>{resToken}</p>}
          <Form.Check.Label className={classes.CheckboxLabel}>
            Please add the verification code sent to your email.
          </Form.Check.Label>
          <Button className={classes.loginBtn} type="submit">
            Submit
          </Button>
        </Form>
      )}
      
    </>
  );
};

export default SigninForm;
