import React, { useState } from "react";
import {
  InnerContainer,
  OuterContainer,
  ContactDropDown,
} from "global.style";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/store";
import { SetCurrentPinderState } from "store/Slices/PinderSlice";
import { LocalResultsItems } from "utils/constants";
import { TPinderFilters } from "types";
import { PinderTopFilters } from "components/screen-components/PinderTopFilters";
import { LocalCommercial } from "components/screen-components/Pinder/Local/LocalCommercial";
// import LocalResidential from "components/screen-components/Pinder/Local/LocalResidential";
import "../stylesheet.css"
import { useSetPinderFiltersOptions } from "../../../hooks/useSetPinderFiltersOptions";
import LocalResidential from "components/screen-components/Pinder/Local/LocalResidential";
export const LocalData = () => {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const pinderstate = useSelector((state: RootState) => state.pinder);
  const[Clear,setClear]=useState(false)
  const [TopFilters, setTopFilters] = useState<TPinderFilters>({
    PostCode: [],
    Page: 1,
    PerPage: 30,
    MaxPrice: 0,
    MinPrice: 0,
    PropertyType: [],
    ListingType: [],
    Keyword: "",
    State: [],
  });
  const {postcodes,propertyOptions,ListingOptions} = useSetPinderFiltersOptions();
  const ClearFilters = (event: React.MouseEvent) => {
    event.preventDefault();
    
    setTopFilters({
      PostCode: [],
      Page: 1,
      PerPage: pinderstate?.currentPinderState === "residential" ? 200 : 200,
      MaxPrice: 0,
      MinPrice: 0,
      PropertyType: [],
      ListingType: [],
      Keyword: "",
      State: [],
    });
    setClear(true)
  };
  return (
    <OuterContainer>
      <InnerContainer margin="0px" padding="0px" bg={true}>
        <div
          style={{ width: width - 290 + "px" }}
          className={`PinderPageContainer`}
        >
          <div className="flex flex-wrap justify-between mt-4">
            <div>
              <h2 className="pageTitle">Local Data</h2>
            </div>
            <div style={{ width: "150px" }}>
              <ContactDropDown
                className="w-[100%] h-[32px]"
                value={pinderstate.currentPinderState}
                options={LocalResultsItems}
                onChange={(e) => {
                  dispatch(
                    SetCurrentPinderState({ currentPinderState: e.value })
                  );
                  if (e.value === "residential") {
                    setTopFilters({ ...TopFilters, PerPage: 30 });
                  } else {
                    setTopFilters({ ...TopFilters, PerPage: 30 });
                  }
                }}
                placeholder="Data Type"
                color="#ced4da"
              />
            </div>
          </div>
          <PinderTopFilters
            postcodes={postcodes}
            propertyOptions={propertyOptions}
            ListingOptions={ListingOptions}
            TopFilters={TopFilters}
            setTopFilters={setTopFilters}
            ClearFilter={ClearFilters}
          />
          {pinderstate.currentPinderState === "commercial" ? (
            <LocalCommercial TopFilters={TopFilters} setTopFilters={setTopFilters} Clear={Clear}/>
          ) : (
            // <LocalResidential TopFilters={TopFilters} setTopFilters={setTopFilters} Clear={Clear}/>
            <>
            <LocalResidential TopFilters={TopFilters} setTopFilters={setTopFilters} Clear={Clear}/>
            </>
          )}
        </div>
      </InnerContainer>
    </OuterContainer>
  );
};
