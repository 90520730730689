import React, { useState, useRef } from 'react'
import { Button, Form, InputGroup } from "react-bootstrap";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import classes from "stylesheet.module.css"
import { SendOtp, ResetPassword } from 'store/Slices/AuthSlice';
const ResetPasswordForm = () => {
    const toast = useRef<any>(null);
    const navigate = useNavigate();
    const [ShowPassword, setShowPassword] = useState<boolean>(false);
    const [otpSent, setOtpSent] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [otp, setOtp] = useState<string>("");
    const showMessage = (Message: string) => {
        toast.current.show({
            severity: "warn",
            life: 2000,
            content: (
                <div
                    className="flex flex-column "
                    style={{ paddingTop: "0px", paddingBottom: "0px", width: "100%" }}
                >
                    <div className="text-center ">

                        <p className="popup-warn-text">
                            {Message}
                        </p>
                    </div>

                </div>
            ),
        });
    }
    const handleSubmit = async () => {

        if (otpSent === false) {
            const params = { email };
            let result = await ResetPassword(params)

            if (result?.response?.data?.status === false) {

                showMessage(result?.response?.data?.message)
            } else {
                setOtpSent(true)
            }
        } else {
            const params = { otp, password };
            const result = await SendOtp(params)
            if (result?.response?.data?.status === false) {
                showMessage(result?.response?.data?.message)
            } else {
                navigate("/signin");
            }
        }
    }

    return (
        <>
            <Toast ref={toast} />
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}>
                <Form.Group className="mt-4 mb-3" controlId="signinForm.email">
                    <Form.Control
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className={classes.EmailInput}
                        placeholder="Email Address"
                        disabled={otpSent}
                        required
                    />
                </Form.Group>
                {Boolean(otpSent) && (
                    <>
                        <Form.Group className="mb-3" controlId="signinForm.password">
                            <InputGroup className={classes.PasswordInputGroup}>
                                <Form.Control
                                    type={ShowPassword ? "text" : "password"}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className={classes.PasswordInput}
                                    placeholder="New Password"
                                    required
                                />
                                <InputGroup.Text className={classes.passwordShowHide}>
                                    <span
                                        onClick={() => setShowPassword(!ShowPassword)}
                                        className={classes.showHideBtn}
                                    >
                                        {ShowPassword ? "Hide" : "Show"}
                                    </span>
                                </InputGroup.Text>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="signinForm.otp">
                            <Form.Control
                                type="text"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                className={classes.EmailInput}
                                placeholder="OTP"
                                required
                            />
                        </Form.Group>
                    </>
                )}
                <Button className={classes.loginBtn} type="submit">
                    Submit
                </Button>
            </Form>
        </>

    )
}

export default ResetPasswordForm