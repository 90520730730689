import React from "react";
import { CSVProperties } from "types";
import { Column } from "primereact/column";
import { PinderTable } from "pinder.style";
import { CSVPropertiesColumnData } from "utils/constants";
interface CSVPropertiesProps {
  TableData: CSVProperties[];
  rows: number;
}
const CSVPropertiesTable = ({ TableData, rows }: CSVPropertiesProps) => {
  return (
    <div>
      <PinderTable
        editMode="cell"
        className="propertyDataTable"
        value={TableData}
        rows={Number(rows)}
        tableStyle={{ minWidth: "100rem" }}
      >
        {CSVPropertiesColumnData?.map((item, key) => {
          return (
            <Column
              key={key}
              header={item?.header}
              style={item?.style}
              body={(rowData: any) => {
                if (item.header === "Street Name") {
                  return (
                    <p className="overflow-hidden whitespace-normal bg-transparent border-none cursor-pointer text-ellipsis">
                      {rowData?.metadata?.addressComponents?.streetName}
                    </p>
                  );
                } else if (item.header === "Area") {
                  return (
                    <p className="overflow-hidden whitespace-normal bg-transparent border-none cursor-pointer text-ellipsis">
                      {rowData?.metadata?.addressComponents?.area}
                    </p>
                  );
                } else if (item.header === "State") {
                  return (
                    <p className="overflow-hidden whitespace-normal bg-transparent border-none cursor-pointer text-ellipsis">
                      {rowData?.metadata?.addressComponents?.stateShort}
                    </p>
                  );
                } else if (item.header === "Postcode") {
                  return (
                    <p className="overflow-hidden whitespace-normal bg-transparent border-none cursor-pointer text-ellipsis">
                      {rowData?.metadata?.addressComponents?.postcode}
                    </p>
                  );
                } else if (item.header === "Region") {
                  return (
                    <p className="overflow-hidden whitespace-normal bg-transparent border-none cursor-pointer text-ellipsis">
                      {rowData?.metadata?.addressComponents?.region}
                    </p>
                  );
                } else if (item.header === "Link") {
                  return (
                    <a href={rowData?.ad?.url} target="_blank" rel="noreferrer">
                      Link
                    </a>
                  );
                } else if (item.header === "Agent Name") {
                  return (
                    <div>
                      {rowData?.contacts?.map((item: any, index: any) => {
                        return (
                          <p
                            key={index}
                            className="overflow-hidden whitespace-normal bg-transparent border-none cursor-pointer text-ellipsis"
                          >
                            {item?.displayFullName}
                          </p>
                        );
                      })}
                    </div>
                  );
                } else if (item.header === "Agent Phone") {
                  return (
                    <div>
                      {rowData?.contacts?.map((item: any, index: any) => {
                        return (
                          <>
                            {item?.phoneNumbers?.map(
                              (subitem: any, index: any) => {
                                return (
                                  <p
                                    key={index}
                                    className="overflow-hidden whitespace-normal bg-transparent border-none cursor-pointer text-ellipsis"
                                  >
                                    {subitem?.number}
                                  </p>
                                );
                              }
                            )}
                          </>
                        );
                      })}
                    </div>
                  );
                } else {
                  return (
                    <p className="overflow-hidden whitespace-normal bg-transparent border-none cursor-pointer text-ellipsis">
                      {rowData[item.field]}
                    </p>
                  );
                }
              }}
            ></Column>
          );
        })}
      </PinderTable>
    </div>
  );
};

export default CSVPropertiesTable;
