import Resi1 from "assets/images/hardcodedcards/resi1.png";
import Resi2 from "assets/images/hardcodedcards/resi2.png";
import Resi3 from "assets/images/hardcodedcards/resi3.png";
import Retail1 from "assets/images/hardcodedcards/retail1.png";
import Retail2 from "assets/images/hardcodedcards/retail2.png";
import Retail3 from "assets/images/hardcodedcards/retail3.png";
import Office1 from "assets/images/hardcodedcards/office1.png";
import Office2 from "assets/images/hardcodedcards/office2.png";
import Office3 from "assets/images/hardcodedcards/office3.png";
import { TPropertyCard } from "types";
type PageOptions = {
  label: string;
  value: Number;
};
type States = {
  name: string;
  code: string;
};
type LocalItem = {
  label: string;
  value: string;
};
type SortOpt = {
  name: string;
  code: string;
};
type ContactType = {
  label: string;
  value: string;
};
type TLiabilityType = {
  value: string;
  label: string;
};
export const PropertyCardsData: TPropertyCard[] = [
  {
    img: Resi1,
    PropertyMode: "Conservative",
    number: 1,
    propertyProps: {
      propertyType: "Residential",
      LVR: 80.0,
      PurchasePrice: 375000.0,
      InterestRate: 5.5,
      NetCashflow: 13672.5,
      Deposit: 98630.0,
      GrossRentalIncome: 20280.0,
      RiskProfile: 5,
      progressColor: "var(--primary-green)",
      netrental: 20280.0,
    },
  },
  {
    img: Resi2,
    PropertyMode: "Conservative",
    number: 2,
    propertyProps: {
      propertyType: "Residential",
      LVR: 80.0,
      PurchasePrice: 519000,
      InterestRate: 5.5,
      NetCashflow: -3882.0,
      Deposit: 136497.0,
      GrossRentalIncome: 28080.0,
      RiskProfile: 5,
      progressColor: "var(--primary-green)",
      netrental: 28080.0,
    },
  },
  {
    img: Resi3,
    PropertyMode: "Conservative",
    number: 3,
    propertyProps: {
      propertyType: "Residential",
      LVR: 80.0,
      PurchasePrice: 780000,
      InterestRate: 5.5,
      NetCashflow: -975,
      Deposit: 205140.0,
      GrossRentalIncome: 49400.0,
      RiskProfile: 5,
      progressColor: "var(--primary-green)",
      netrental: 49400.0,
    },
  },
  {
    img: Office1,
    PropertyMode: "Aggressive",
    number: 1,
    propertyProps: {
      propertyType: "Office",
      LVR: 70.0,
      PurchasePrice: 750000.0,
      InterestRate: 5.5,
      NetCashflow: 23625.0,
      Deposit: 272250.0,
      GrossRentalIncome: 52500.0,
      RiskProfile: 7,
      progressColor: "#cd4a5e",
      netrental: 52500.0,
    },
  },
  {
    img: Office2,
    PropertyMode: "Aggressive",
    number: 2,
    propertyProps: {
      propertyType: "Office",
      LVR: 70.0,
      PurchasePrice: 1010000.0,
      InterestRate: 5.5,
      NetCashflow: 40626.0,
      Deposit: 366630.0,
      GrossRentalIncome: 79511.0,
      RiskProfile: 7,
      progressColor: "#cd4a5e",
      netrental: 79511.0,
    },
  },
  {
    img: Office3,
    PropertyMode: "Aggressive",
    number: 3,
    propertyProps: {
      propertyType: "Office",
      LVR: 70.0,
      PurchasePrice: 1500000.0,
      InterestRate: 5.5,
      NetCashflow: 54750.0,
      Deposit: 544500.0,
      GrossRentalIncome: 112500.0,
      RiskProfile: 7,
      progressColor: "#cd4a5e",
      netrental: 112500.0,
    },
  },
  {
    img: Retail1,
    PropertyMode: "Moderate",
    number: 1,
    propertyProps: {
      propertyType: "Retail",
      LVR: 70.0,
      PurchasePrice: 600000.0,
      InterestRate: 5.5,
      NetCashflow: 19430.0,
      Deposit: 217800.0,
      GrossRentalIncome: 42530.0,
      RiskProfile: 6,
      progressColor: "#fee468",
      netrental: 42530.0,
    },
  },
  {
    img: Retail2,
    PropertyMode: "Moderate",
    number: 2,
    propertyProps: {
      propertyType: "Retail",
      LVR: 70.0,
      PurchasePrice: 1100000.0,
      InterestRate: 5.5,
      NetCashflow: 19430.0,
      Deposit: 399300.0,
      GrossRentalIncome: 76800.0,
      RiskProfile: 6,
      progressColor: "#fee468",
      netrental: 76800.0,
    },
  },
  {
    img: Retail3,
    PropertyMode: "Moderate",
    number: 3,
    propertyProps: {
      propertyType: "Retail",
      LVR: 70.0,
      PurchasePrice: 2300000.0,
      InterestRate: 5.5,
      NetCashflow: 51450.0,
      Deposit: 834900.0,
      GrossRentalIncome: 140000.0,
      RiskProfile: 6,
      progressColor: "#fee468",
      netrental: 140000.0,
    },
  },
];
export const OwnerType: TLiabilityType[] = [
  { value: "Applicant", label: "Applicant" },
  { value: "Partner", label: "Partner" },
];
export const ContactTypeOptions: ContactType[] = [
  { label: "Lite", value: "Contact Lite" },
];
export const AdminContactTypes: ContactType[] = [
  { label: "Lite", value: "Contact Lite" },
  { label: "Premium", value: "Contact Paid" },
];
export const PaymentTypes: ContactType[] = [
  { label: "Weekly", value: "Weekly" },
  { label: "Monthly", value: "Monthly" },
  { label: "Annually", value: "Annually" },
  { label: "Fornightly", value: "Fornightly" },
];
export const PaymentTypes2: ContactType[] = [
  { label: "Weekly", value: "Weekly" },
  { label: "Monthly", value: "Monthly" },
  { label: "Annually", value: "Annually" },
];
export const IncomeTypes: ContactType[] = [
  { label: "Gross", value: "Gross" },
  { label: "Net", value: "Net" },
];
export const ListingTypeSelectItems: ContactType[] = [
  { label: "For Sale", value: "Sale" },
  { label: "Rent", value: "Rent" },
  { label: "Sold", value: "Sold" },
];
export const LiabilityType: TLiabilityType[] = [
  { value: "Loan", label: "Loan" },
  { value: "Credit Card", label: "Credit Card" },
  { value: "Car Loan", label: "Car Loan" },
  { value: "HECS", label: "HECS" },
  { value: "Other", label: "Other" },
  { value: "Insurance", label: "Insurance" },
  { value: "Utilities", label: "Utilities" },
];
export const PerPageOptions: PageOptions[] = [
  { label: "50", value: 50 },
  { label: "100", value: 100 },
  { label: "150", value: 150 },
  { label: "200", value: 200 },
  { label: "250", value: 250 },
  { label: "300", value: 300 },
  { label: "350", value: 350 },
  { label: "400", value: 400 },
  { label: "450", value: 450 },
  { label: "500", value: 500 },
];
export const statesOfAustralia: States[] = [
  { name: "ACT", code: "ACT" },
  { name: "NSW", code: "NSW" },
  { name: "NT", code: "NT" },
  { name: "QLD", code: "QLD" },
  { name: "SA", code: "SA" },
  { name: "TAS", code: "TAS" },
  { name: "VIC", code: "VIC" },
  { name: "WA", code: "WA" },
];
export const LocalResultsItems: LocalItem[] = [
  { label: "Residential", value: "residential" },
  { label: "Commercial", value: "commercial" },
];
export const sortOptions: SortOpt[] = [
  { name: "A to Z", code: "AZ" },
  { name: "Z to A", code: "ZA" },
];
export const PortfolioOptions: LocalItem[] = [
  { value: "Residential", label: "Residential Only (5%)" },
  { value: "Commercial - Retail", label: "Commercial Only (6%)" },
  { value: "Commercial - Office", label: "Commercial Only (7%)" },
  { value: "Blended", label: "Blended" },
];
export const LocalCommercialColumnData = [
  {
    header: "Comments",
    field: "Comments",
    style: { width: "120px", paddingLeft: "0px", paddingRight: "0px" },
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "10px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Client",
    field: "Client",
    style: { width: "100px", paddingLeft: "0px", paddingRight: "0px" },
    bodyStyle: { paddingLeft: "10px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Image",
    field: "Image",
    style: { width: "100px", paddingLeft: "0px", paddingRight: "0px" },
    bodyStyle: { paddingLeft: "31px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Headline",
    field: "Headline",
    style: { width: "120px", paddingLeft: "0px", paddingRight: "0px" },
    bodyStyle: { paddingLeft: "21px", maxWidth: "120px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Address",
    field: "Address",
    style: { width: "100px", paddingLeft: "0px", paddingRight: "0px" },
    bodyStyle: { paddingLeft: "15px", maxWidth: "130px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "State",
    field: "State",
    style: { width: "90px", paddingLeft: "0px", paddingRight: "0px" },
    bodyStyle: { paddingLeft: "15px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Postcode",
    field: "Postcode",
    style: { width: "90px", paddingLeft: "0px", paddingRight: "0px" },
    bodyStyle: { paddingLeft: "15px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Property Type",
    style: { width: "130px", paddingLeft: "0px", paddingRight: "0px" },
    bodyStyle: { paddingLeft: "0px" },
    field: "PropertyType",
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Price",
    field: "Price",
    style: { width: "100px", paddingLeft: "0px", paddingRight: "0px" },
    bodyStyle: { paddingLeft: "0px", maxWidth: "100px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Floor Area",
    field: "FloorArea",
    style: { width: "100px", paddingLeft: "0px", paddingRight: "0px" },
    bodyStyle: { paddingLeft: "10px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Land Area",
    field: "LandArea",
    style: { width: "100px", paddingLeft: "0px", paddingRight: "0px" },
    bodyStyle: { paddingLeft: "10px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Net Rent",
    field: "NetRent",
    style: { width: "100px", paddingLeft: "0px", paddingRight: "0px" },
    bodyStyle: { paddingLeft: "10px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Description",
    field: "Description",
    style: { width: "150px", paddingLeft: "0px", paddingRight: "0px" },
    bodyStyle: { paddingLeft: "30px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Days on Market",
    field: "DaysonMarket",
    style: { width: "130px", paddingLeft: "0px", paddingRight: "0px" },
    bodyStyle: { paddingLeft: "0px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Listing",
    field: "Listings",
    style: { width: "100px", paddingLeft: "0px", paddingRight: "0px" },
    bodyStyle: { paddingLeft: "0px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },

  {
    header: "Listing Type",
    field: "Listing",
    style: { width: "130px", paddingLeft: "0px", paddingRight: "0px" },
    bodyStyle: { paddingLeft: "0px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },

  {
    header: "Outgoing",
    field: "Outgoing",
    style: {
      width: "100px",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "100px",
    },
    bodyStyle: { paddingLeft: "0px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },

  {
    header: "Yield/Return",
    field: "YieldReturn",
    style: {
      width: "100px",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "100px",
    },
    bodyStyle: { paddingLeft: "0px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },

  {
    header: "Tenure Type",
    field: "TenureType",
    style: {
      width: "130px",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "130px",
    },
    bodyStyle: { paddingLeft: "0px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Number of Tenants",
    field: "NumofTenants",
    style: {
      width: "150px",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "150px",
    },
    bodyStyle: { paddingLeft: "0px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Occupancy (AGENT)",
    field: "Occupancy",
    style: {
      width: "180px",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "180px",
    },
    bodyStyle: { paddingLeft: "0px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Tenant Name (AGENT)",
    field: "TenantName",
    style: {
      width: "180px",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "180px",
    },
    bodyStyle: { paddingLeft: "0px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Lease Expiry (AGENT)",
    field: "LeaseExpiry",
    style: {
      width: "180px",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "180px",
    },
    bodyStyle: { paddingLeft: "0px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },

  {
    header: "SWOT",
    field: "SWOT",
    style: { width: "250px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Suburb Profile",
    field: "SuburbProfile",
    style: { width: "250px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },

  {
    header: "Advert Update Date",
    field: "AdvertUpdateDate",
    style: {
      width: "150px",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "150px",
    },
    bodyStyle: { paddingLeft: "0px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Agency Name",
    field: "AgencyName",
    style: {
      width: "150px",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "150px",
    },
    bodyStyle: { paddingLeft: "0px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Agent Name",
    field: "AgentName",
    style: {
      width: "100px",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "100px",
    },
    bodyStyle: { paddingLeft: "0px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Agent Mobile",
    field: "AgentContact",
    style: {
      width: "110px",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "110px",
    },
    bodyStyle: { paddingLeft: "0px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Agent Email",
    field: "AgentEmail",
    style: {
      width: "150px",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "150px",
    },
    bodyStyle: { paddingLeft: "0px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Sale-Auction",
    field: "SaleAuction",
    style: {
      width: "150px",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "150px",
    },
    bodyStyle: { paddingLeft: "0px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Sale-Expression of Interest",
    field: "SaleExpressionofInterest",
    style: {
      width: "160px",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "160px",
    },
    bodyStyle: { paddingLeft: "0px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Total Car Spaces",
    field: "TotalCarSpaces",
    style: {
      width: "130px",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "130px",
    },
    bodyStyle: { paddingLeft: "0px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Zoning",
    field: "Zoning",
    style: {
      width: "180px",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "180px",
    },
    bodyStyle: { paddingLeft: "0px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Municipality",
    field: "Municipality",
    style: {
      width: "180px",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "180px",
    },
    bodyStyle: { paddingLeft: "0px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
];
export const LiveCommercialColumnData = [
  {
    header: "Listing",
    field: "Listing",
    style: { width: "100px" },
    bodyStyle: { paddingLeft: "41px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Image",
    field: "Image",
    style: { width: "100px" },
    bodyStyle: { paddingLeft: "41px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },

  {
    header: "Address",
    field: "Address",
    // style: { width: "25%" },
    style: { width: "350px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Suburb",
    field: "Suburb",
    style: { width: "150px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "State",
    field: "State",
    style: { width: "100px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Postcode",
    field: "Postcode",
    style: { width: "100px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Property Type",
    style: { width: "300px" },
    bodyStyle: { paddingLeft: "40px" },
    field: "PropertyType",
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Price",
    field: "Price",
    style: { width: "300px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },

  {
    header: "Land Area",
    field: "LandArea",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Floor Area",
    field: "FloorArea",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Agency Name",
    field: "AgencyName",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Agent Name",
    field: "AgentName",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Agent Mobile",
    field: "AgentContact",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Agent Email",
    field: "AgentEmail",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },

  {
    header: "Total Car Spaces",
    field: "TotalCarSpaces",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "60px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
];
export const LocalResidentialColumnData = [
  {
    header: "Comments",
    field: "Comments",
    style: {
      width: "70px",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "70px",
    },
    bodyStyle: { paddingLeft: "10px", textAlign: "center", maxWidth: "70px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Client",
    field: "Client",
    style: {
      width: "60px",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "60px",
    },
    bodyStyle: { paddingLeft: "0px", maxWidth: "60px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Image",
    field: "Image",
    style: {
      width: "50px",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "50px",
    },
    bodyStyle: { paddingLeft: "30px", maxWidth: "50px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Headline",
    field: "Headline",
    style: { width: "50px", paddingLeft: "0px", paddingRight: "0px" },
    bodyStyle: { paddingLeft: "21px", maxWidth: "80px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Address",
    field: "Address",
    style: { width: "100px", paddingLeft: "0px", paddingRight: "0px" },
    bodyStyle: { paddingLeft: "15px", maxWidth: "100px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "State",
    field: "State",
    style: {
      width: "50px",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "50px",
    },
    bodyStyle: { paddingLeft: "0px", maxWidth: "50px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Postcode",
    field: "Postcode",
    style: {
      width: "60px",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "60px",
    },
    bodyStyle: { paddingLeft: "0px", maxWidth: "60px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Property Type",
    style: {
      width: "70px",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "70px",
    },
    bodyStyle: { paddingLeft: "0px", maxWidth: "70px" },
    field: "PropertyType",
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Price",
    field: "Price",
    style: { width: "70px", paddingLeft: "0px", paddingRight: "0px" },
    bodyStyle: { paddingLeft: "0px", maxWidth: "80px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Floor Area",
    field: "HouseSize",
    style: {
      width: "70px",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "70px",
    },
    bodyStyle: { paddingLeft: "10px", maxWidth: "70px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Land Area",
    field: "LandSize",
    style: {
      width: "70px",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "70px",
    },
    bodyStyle: { paddingLeft: "10px", maxWidth: "70px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Rent",
    field: "Rent",
    style: {
      width: "70px",
      paddingLeft: "15px",
      paddingRight: "0px",
      maxWidth: "70px",
    },
    bodyStyle: { paddingLeft: "0px", textAlign: "center", maxWidth: "70px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Description",
    field: "Description",
    style: { width: "150px", paddingLeft: "0px", paddingRight: "0px" },
    bodyStyle: { paddingLeft: "30px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Days on Market",
    field: "DaysonMarket",
    style: { width: "100px", paddingLeft: "0px", paddingRight: "0px" },
    bodyStyle: { paddingLeft: "0px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Listing",
    field: "Listings",
    style: { width: "80px", paddingLeft: "0px", paddingRight: "0px" },
    bodyStyle: { paddingLeft: "0px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Listing Type",
    field: "Listing",
    style: { width: "90px", paddingLeft: "0px", paddingRight: "0px" },
    bodyStyle: { paddingLeft: "0px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Bedrooms",
    field: "Bedrooms",
    style: {
      width: "80px",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "80px",
    },
    bodyStyle: { paddingLeft: "0px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Bathrooms",
    field: "Bathrooms",
    style: {
      width: "80px",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "80px",
    },
    bodyStyle: { paddingLeft: "0px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Ensuites",
    field: "Ensuites",
    style: {
      width: "80px",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "80px",
    },
    bodyStyle: { paddingLeft: "0px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Toilets",
    field: "Toilets",
    style: {
      width: "80px",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "80px",
    },
    bodyStyle: { paddingLeft: "0px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Yield",
    field: "Yield",
    style: {
      width: "80px",
      paddingLeft: "0px",
      paddingRight: "0px",
      maxWidth: "80px",
    },
    bodyStyle: { paddingLeft: "0px", textAlign: "center" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },

  {
    header: "SWOT",
    field: "SWOT",
    style: { width: "250px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Suburb Profile",
    field: "SuburbProfile",
    style: { width: "250px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },

  {
    header: "Advert Update Date",
    field: "AdvertUpdateDate",
    style: {
      width: "110px",
      paddingLeft: "0px",
      paddingRight: "0px",
      textAlign: "center",
    },
    bodyStyle: { paddingLeft: "0px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Agency Name",
    field: "AgencyName",
    style: {
      width: "100px",
      paddingLeft: "0px",
      paddingRight: "0px",
      textAlign: "center",
    },
    bodyStyle: { paddingLeft: "0px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Agent Name",
    field: "AgentName",
    style: {
      width: "100px",
      paddingLeft: "0px",
      paddingRight: "0px",
      textAlign: "center",
    },
    bodyStyle: { paddingLeft: "0px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Agent Mobile",
    field: "AgentMobile",
    style: {
      width: "110px",
      paddingLeft: "0px",
      paddingRight: "0px",
      textAlign: "center",
    },
    bodyStyle: { paddingLeft: "0px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Agent Email",
    field: "AgentEmail",
    style: { width: "180px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Sale-Auction",
    field: "SaleAuction",
    style: { width: "130px", paddingLeft: "0px", paddingRight: "0px" },
    bodyStyle: { paddingLeft: "0px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Sale-Expression of Interest",
    field: "SaleExpressionOfInterest",
    style: { width: "130px", paddingLeft: "0px", paddingRight: "0px" },
    bodyStyle: { paddingLeft: "0px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Features",
    field: "Features",
    style: { width: "120px", paddingLeft: "0px", paddingRight: "0px" },
    bodyStyle: { paddingLeft: "0px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Zoning",
    field: "Zoning",
    style: { width: "100px", paddingLeft: "0px", paddingRight: "0px" },
    bodyStyle: { paddingLeft: "0px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Municipality",
    field: "Municipality",
    style: { width: "110px", paddingLeft: "0px", paddingRight: "0px" },
    bodyStyle: { paddingLeft: "0px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Page Views",
    field: "PageViews",
    style: { width: "120px", paddingLeft: "0px", paddingRight: "0px" },
    bodyStyle: { paddingLeft: "0px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
];
export const LiveResidentialColumnData = [
  {
    header: "Listing",
    field: "Listing",
    style: { width: "100px" },
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "41px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Image",
    field: "Image",
    style: { width: "100px" },
    bodyStyle: { paddingLeft: "41px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Listing Type",
    field: "ListingType",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "41px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Address",
    field: "Address",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Suburb",
    field: "Suburb",
    style: { width: "130px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "State",
    field: "State",
    style: { width: "100px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Postcode",
    field: "Postcode",
    style: { width: "100px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Bedrooms",
    field: "Bedrooms",
    style: { width: "100px" },
    bodyStyle: { paddingLeft: "60px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Bathrooms",
    field: "Bathrooms",
    style: { width: "100px" },
    bodyStyle: { paddingLeft: "60px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Ensuites",
    field: "Ensuites",
    style: { width: "130px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Toilets",
    field: "Toilets",
    style: { width: "100px" },
    bodyStyle: { paddingLeft: "60px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "House Size",
    field: "HouseSize",
    style: { width: "120px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Land Size",
    field: "LandSize",
    style: { width: "120px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Price",
    field: "Price",
    style: { width: "250px" },
    bodyStyle: { paddingLeft: "25px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },

  {
    header: "Rent",
    field: "Rent",
    style: { width: "130px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Yield",
    field: "Yield",
    style: { width: "130px" },
    bodyStyle: { paddingLeft: "60px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Headline",
    field: "Headline",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Description",
    field: "Description",
    style: { width: "350px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Days on Market",
    field: "DaysonMarket",
    bodyStyle: { paddingLeft: "60px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Advert Update Date",
    field: "AdvertUpdateDate",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Agency Name",
    field: "AgencyName",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Agent Name",
    field: "AgentName",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Agent Mobile",
    field: "AgentMobile",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Agent Email",
    field: "AgentEmail",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Sale-Auction",
    field: "SaleAuction",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Sale-Expression of Interest",
    field: "SaleExpressionOfInterest",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Features",
    field: "Features",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Zoning",
    field: "Zoning",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Municipality",
    field: "Municipality",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
  {
    header: "Page Views",
    field: "PageViews",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected: true,
  },
];
export const PropertyColumnData = [
  {
    header: "Created At",
    field: "changeDate",
    style: { maxWidth: "150px" },
  },
  {
    header: "Change By",
    field: "AdminName",
    style: { maxWidth: "150px" },
  },
  {
    header: "Event",
    field: "event",
    style: { maxWidth: "150px" },
  },
  {
    header: "Field Name",
    field: "fieldname",
    style: { maxWidth: "150px" },
  },
  {
    header: "Old Value",
    field: "oldvalue",
    style: { maxWidth: "150px" },
  },
  {
    header: "New Value",
    field: "newvalue",
    style: { maxWidth: "150px" },
  },
];
export const CSVPropertiesColumnData: {
  header: string;
  field: string;
  style?: any;
}[] = [
  {
    header: "Property ID",
    field: "id",
    style: { maxWidth: "100px" },
  },
  {
    header: "Property Type",
    field: "propertyType",
  },
  {
    header: "Property Area",
    field: "propertyArea",
  },
  {
    header: "Price",
    field: "price",
    style: { maxWidth: "150px" },
  },
  {
    header: "Street Name",
    field: "streetName",
    style: { maxWidth: "150px" },
  },
  {
    header: "Area",
    field: "area",
  },
  {
    header: "State",
    field: "state",
  },
  {
    header: "Postcode",
    field: "postcode",
  },
  {
    header: "Region",
    field: "region",
  },
  {
    header: "Agent Name",
    field: "agentName",
    style: { maxWidth: "150px" },
  },
  {
    header: "Agent Phone",
    field: "phoneNumber",
  },
  {
    header: "Link",
    field: "Link",
  },
];
