import React from 'react'
import { InnerContainer, Container } from 'global.style'
export const Themes = () => {
  return (
    <Container>
      <InnerContainer>
        <div className="profile-container">
          <h1>Themes</h1>
        </div>
      </InnerContainer>
    </Container>
  )
}
