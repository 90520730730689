import React from 'react'
import { InputNumberValueChangeEvent } from "primereact/inputnumber";
import { CurrencyFormatter } from '../../../utils/helperFunctions';
import { CustomInputNumber } from '../../../global.style';
import { InputNumber } from 'primereact/inputnumber';
type InputNumberProps = {
   classes?:string;
    value: number;
    inputClassName?: string;
    onChange: (event: InputNumberValueChangeEvent) => void;
    minFractionDigits?:number;
    maxFractionDigits?:number;
    suffix?:string;
    mode?:"decimal" | "currency" | undefined;
    type?:string
}
export const InputPercents = ({ value, onChange, inputClassName,minFractionDigits = 2,maxFractionDigits = 2,suffix="%",mode="decimal",classes }: InputNumberProps) => {
    return (
        <CustomInputNumber>
            <InputNumber 
            onValueChange={onChange}
            onFocus={(e) => (e.target.value = "")}
            onBlur={(e) =>
                (e.target.value = `${value}${suffix}`)
            }
            value={value}
            mode={mode}
            minFractionDigits={minFractionDigits}
            maxFractionDigits={maxFractionDigits}
            suffix={suffix}
            inputClassName={inputClassName}
            className={classes}
            />
        </CustomInputNumber>
        
    )
}
