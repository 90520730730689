import React, { useEffect, useState } from "react";
import { OuterContainer, InnerContainer } from "global.style";
import { PortfolioJourney } from "components/screen-components/PortfolioJourney";
import { GS } from "services/global";
import { RootState } from "store/store";
import { useSelector } from "react-redux";
import { GetDomainProperties } from "services/api";
import { CalculateFormulasFromPurchasePrice } from "utils/helperFunctions";
import Pic1 from "assets/empty.svg";
import { useDispatch } from "react-redux";
import { SetCalData } from "store/Slices/CalculatorSlice";
import PlanTimeLine from "components/screen-components/Calculator/IncomePlan/PlanTimeLine";
import IncomePropertyLists from "components/screen-components/Calculator/IncomePlan/IncomeCards";
import { useGetDomainData } from "hooks/useGetDomainData";
import { Loading } from "components/ui/Loading";
import { SaveCalData } from "store/Slices/CalculatorSlice";
export const IncomePlan = () => {
  const { getSuburbStats } = useGetDomainData();
  const userCalData = useSelector((state: RootState) => state.calculator);
  const [propertiesData, setpropertiesData] = useState<any>(
    userCalData?.PropertyGenerators
  );
  const [loading, setloading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const getImagesData = async () => {
    const updated_property_data = userCalData?.PropertyGenerators?.map(
      (item) => {
        let propertyType = item.summaryData.propertyType.includes("Commercial")
          ? "commercial"
          : "residential";

        let type =
          item.medianPropertyType === "Residential"
            ? null
            : item.medianPropertyType === "Commercial Retail"?'retail':item.medianPropertyType === "Commercial Office"?"office":item.medianPropertyType;
        let selectedPropertyArea: any = [];
        if (item.medianPropertyType === "Residential") {
          selectedPropertyArea = GS.Calculator.DomainData.data.residential;
        } else if (item.medianPropertyType === "Commercial Retail") {
          selectedPropertyArea = GS.Calculator.DomainData.data.retail;
        } else if (item.medianPropertyType === "Commercial Office") {
          selectedPropertyArea = GS.Calculator.DomainData.data.office;
        } else {
          selectedPropertyArea = [];
        }
        return {
          type,
          state: item.summaryData.stateName,
          postcode: selectedPropertyArea[item?.summaryData?.regionNo]?.postcode,
          price: item.summaryData.PurchasePrice,
          propertyTypes: propertyType,
        };
      }
    );
    let body: any[] = [];
    let postcodes: any[] = [];
    
    updated_property_data.map((i) => {
      if (body.length === 0) {
        body.push(i);
        postcodes.push(i.postcode);
      } else {
        let alreadyExistPostCode = body.find(
          (data) => data.postcode === i.postcode
        );
        if (alreadyExistPostCode) {
          if (i.propertyTypes === "residential") {
            let postcodeData =
              GS?.Calculator?.DomainData?.regionsData?.residential?.find(
                (data) => {
                  return (
                    data.State === i.state &&
                    !postcodes.includes(
                      data.address ? data.address.postcode : data.postcode
                    )
                  );
                }
              );
            if (!postcodeData) {
              postcodeData =
                GS?.Calculator?.DomainData?.regionsData?.residential.find(
                  (data) => {
                    return (
                      data.State !== i.state &&
                      !postcodes.includes(
                        data.address ? data.address.postcode : data.postcode
                      )
                    );
                  }
                );
              if (!postcodeData) {
                postcodes.push(i.postcode);
                return body.push(i);
              }
            }

            const postcode = postcodeData.address
              ? postcodeData.address.postcode
              : postcodeData.postcode;
            postcodes.push(postcode);
            body.push({ ...i, postcode: Number(postcode) });
          } else if (i.propertyTypes === "commercial") {
            if (i.type === "retail") {
              let postcodeData =
                GS?.Calculator?.DomainData?.regionsData?.retail?.find(
                  (data) => {
                    return (
                      data.State === i.state &&
                      !postcodes.includes(data.postcode)
                    );
                  }
                );
              if (postcodeData) {
                postcodes.push(postcodeData.postcode);
              } else if (!postcodeData) {
                postcodeData =
                  GS?.Calculator?.DomainData?.regionsData?.retail?.find(
                    (data) => {
                      return (
                        data.State !== i.state &&
                        !postcodes.includes(data.postcode)
                      );
                    }
                  );
                if (!postcodeData) {
                  postcodes.push(i.postcode);
                  return body.push(i);
                }
              }
              body.push({ ...i, postcode: Number(postcodeData.postcode) });
            }
            if (i.type === "office") {
              let postcodeData =
                GS?.Calculator?.DomainData?.regionsData?.office?.find(
                  (data) => {
                    return (
                      data.State === i.state &&
                      !postcodes.includes(data.postcode)
                    );
                  }
                );
              if (postcodeData) {
                postcodes.push(postcodeData.postcode);
              } else if (!postcodeData) {
                postcodeData =
                  GS?.Calculator?.DomainData?.regionsData?.office?.find(
                    (data) => {
                      return (
                        data.State !== i.state &&
                        !postcodes.includes(data.postcode)
                      );
                    }
                  );
                if (!postcodeData) {
                  postcodes.push(i.postcode);
                  return body.push(i);
                }
              }
              body.push({ ...i, postcode: Number(postcodeData.postcode) });
            }
          }
        } else {
          postcodes.push(i.postcode);
          body.push(i);
        }
      }
    });
    try {
      let response = await GetDomainProperties("domain/propertiesData", body);
      let updated_props = response?.data?.map((item: any, idx: number) => {
        if (item?.props === null || item?.props?.length === 0) {
          return {
            ...userCalData.PropertyGenerators[idx],
            listingData: {
              TotalPurchaseCosts:
                userCalData.PropertyGenerators[idx]?.summaryData
                  ?.TotalPurchaseCosts,
              AcquisitionCost:
                userCalData.PropertyGenerators[idx]?.summaryData
                  ?.AcquisitionCost,
              StampDuty:
                userCalData.PropertyGenerators[idx]?.summaryData?.StampDuty,
              Deposit:
                userCalData.PropertyGenerators[idx]?.summaryData?.Deposit,
              image: Pic1,
              purchasePrice:
                userCalData.PropertyGenerators[idx]?.summaryData?.PurchasePrice,
              ad: `#`,
              tdr: userCalData.PropertyGenerators[idx]?.summaryData
                ?.TotalDepositRequired,
              GrossRent:
                userCalData.PropertyGenerators[idx]?.summaryData
                  ?.PropertyRentIncomeYear1,
              NetYield: (
                userCalData.PropertyGenerators[idx]?.summaryData
                  ?.PurchasePrice *
                (userCalData.PropertyGenerators[idx]?.summaryData
                  ?.RiskFactoredYieldReturn /
                  100)
              ).toFixed(1),
              CFA: userCalData.PropertyGenerators[idx]?.summaryData
                ?.CashflowAnnual,
            },
            domainPropertyType: "notfound",
            idx: idx,
          };
        }
        if (item.propertyTypes === "residential") {
          let listing = item?.props[0]?.listing
            ? item?.props[0]?.listing
            : item?.props[0]?.listings[0];
          const purchasePrice = item?.props[0]?.extractedPrice
            ? item?.props[0]?.extractedPrice
            : 0;
          const {
            Deposit,
            StampDuty,
            AcquisitionCost,
            TotalDepositRequired,
            TotalPurchaseCosts,
            TotalDebt,
            PropertyIncome,
            NetYield,
          } = CalculateFormulasFromPurchasePrice({
            PurchasePrice: purchasePrice,
            stateName: listing?.propertyDetails?.state,
            riskfactor:
              userCalData.PropertyGenerators[idx]?.summaryData
                ?.RiskFactoredYieldReturn,
            lvr: userCalData.PropertyGenerators[idx]?.summaryData
              ?.LoantoValueRatioLVR,
          });
          let equityInterestPayable =
            userCalData.PropertyGenerators[idx]?.summaryData
              ?.TotalDepositEquity *
            (Number(
              userCalData.PropertyGenerators[idx]?.summaryData
                ?.EquityInterestRate
            ) / 100 || 0);
          let depositInterestPayable =
            TotalDebt *
            (Number(
              userCalData.PropertyGenerators[idx]?.summaryData?.DebtInterestRate
            ) / 100 || 0);
          let equityPrincipalPayable =
            userCalData.PropertyGenerators[idx]?.summaryData
              ?.TotalDepositEquity *
            (Number(
              userCalData.PropertyGenerators[idx]?.summaryData
                ?.EquityPrincipleRate
            ) / 100 || 0);
          let depositPrincipalPayable =
            TotalDebt *
            (Number(
              userCalData.PropertyGenerators[idx]?.summaryData
                ?.DebtPrincipleRate
            ) / 100 || 0);
          let TotalInterestPayable =
            equityInterestPayable + depositInterestPayable;
          let TotalPricipalPayable =
            equityPrincipalPayable + depositPrincipalPayable;
          let TotalCostofLending = TotalInterestPayable + TotalPricipalPayable;
          let CashflowAnnual = PropertyIncome - TotalCostofLending;
          return {
            ...userCalData.PropertyGenerators[idx],
            ...item?.props,
            listingData: {
              image: listing?.media?.length > 0 && listing?.media[0]?.url,
              purchasePrice: purchasePrice,
              ad: `https://www.domain.com.au/${listing?.listingSlug}`,
              tdr: TotalDepositRequired,
              NetYield,
              GrossRent: PropertyIncome,
              CFA: CashflowAnnual,
              TotalPurchaseCosts,
              AcquisitionCost,
              StampDuty,
              Deposit,
            },
            domainPropertyType: "resi",
            idx: idx,
          };
        } else {
          const purchasePrice =
            item?.props[0]?.extractedPrice && item?.props[0]?.extractedPrice > 0
              ? parseInt(item?.props[0]?.extractedPrice)
              : item?.price;
          const {
            Deposit,
            StampDuty,
            AcquisitionCost,
            TotalDepositRequired,
            TotalPurchaseCosts,
            TotalDebt,
            PropertyIncome,
            NetYield,
          } = CalculateFormulasFromPurchasePrice({
            PurchasePrice: purchasePrice,
            stateName:
              userCalData.PropertyGenerators[idx]?.summaryData?.stateName,
            riskfactor:
              userCalData.PropertyGenerators[idx]?.summaryData
                ?.RiskFactoredYieldReturn,
            lvr: userCalData.PropertyGenerators[idx]?.summaryData
              ?.LoantoValueRatioLVR,
          });
          let equityInterestPayable =
            userCalData.PropertyGenerators[idx]?.summaryData
              ?.TotalDepositEquity *
            (Number(
              userCalData.PropertyGenerators[idx]?.summaryData
                ?.EquityInterestRate
            ) / 100 || 0); 
          let depositInterestPayable =
            TotalDebt *
            (Number(
              userCalData.PropertyGenerators[idx]?.summaryData?.DebtInterestRate
            ) / 100 || 0);
          let equityPrincipalPayable =
            userCalData.PropertyGenerators[idx]?.summaryData
              ?.TotalDepositEquity *
            (Number(
              userCalData.PropertyGenerators[idx]?.summaryData
                ?.EquityPrincipleRate
            ) / 100 || 0);
          let depositPrincipalPayable =
            TotalDebt *
            (Number(
              userCalData.PropertyGenerators[idx]?.summaryData
                ?.DebtPrincipleRate
            ) / 100 || 0);
          let TotalInterestPayable =
            equityInterestPayable + depositInterestPayable;
          let TotalPricipalPayable =
            equityPrincipalPayable + depositPrincipalPayable;
          let TotalCostofLending = TotalInterestPayable + TotalPricipalPayable;
          let CashflowAnnual = PropertyIncome - TotalCostofLending;
          return {
            ...userCalData.PropertyGenerators[idx],
            ...item?.props,
            listing: { ...item?.props[0] },
            listingData: {
              TotalPurchaseCosts,
              AcquisitionCost,
              StampDuty,
              Deposit,
              image: item?.props[0]?.media[0]?.imageUrl,
              purchasePrice: purchasePrice,
              ad: item?.props[0]?.ad?.url,
              tdr: TotalDepositRequired,
              NetYield,
              GrossRent: PropertyIncome,
              CFA: CashflowAnnual,
            },
            domainPropertyType: "commercial",
            idx: idx,
          };
        }
      });
      let newcalobj = { ...userCalData };
      newcalobj = {
        ...newcalobj,
       IncomePlan:updated_props
      };
  
      dispatch(SetCalData(newcalobj));
      SaveCalData(newcalobj);  
      setpropertiesData(updated_props);
    } catch (err) {}
  };
  const GetPlanData = async () => {
    if (GS?.Calculator?.DomainData?.regionsData?.residential?.length > 0) {
      getImagesData();
    } else {
      setloading(true);
      let data = await getSuburbStats();
      if (data) {
        setloading(false);
        getImagesData();
      }
    }
  };
  useEffect(() => {
    GetPlanData();
  }, []);
  return (
    <OuterContainer>
      <InnerContainer width="100%" bg={true} padding="1rem 15px 0rem 15px">
        <PortfolioJourney currentActive="IncomePlan" />
        {loading ? (
          <div className="flex items-center justify-center mt-20">
            <Loading />
          </div>
        ) : (
          <>
            <PlanTimeLine data={propertiesData} />
            <IncomePropertyLists data={propertiesData} />
          </>
        )}
      </InnerContainer>
    </OuterContainer>
  );
};
