import React, { useState, useEffect, useCallback } from "react";
import { Container, InnerContainer } from "global.style";
import { useParams } from "react-router-dom";
import { Avatar } from "components/screen-components/Avatar";
import {
  GetUserById,
  TUserData,
  TUpdateUser,
  UpdateUser,
} from "store/Slices/UserSlice";
import { InputTexts } from "components/ui/InputTexts";
import { formatPhoneNumber } from "utils/helperFunctions";
import { ContactTypeOptions, AdminContactTypes } from "utils/constants";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/store";
import { ContactDropDown } from "global.style";
import { InputPassword } from "components/ui/InputPassword";
import { GetAllAdmins, GetAllContacts } from "store/Slices/ContactSlice";
// @ts-ignore
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Loading } from "../../components/ui/Loading";
export const EditContact = () => {
  const dispatch = useDispatch();
  const LoginUserProfile = useSelector(
    (state: RootState) => state.userProfile.data
  );
  const [loader, setLoader] = useState<boolean>(false);
  const loginuserdata = useSelector(
    (state: RootState) => state.userProfile.data
  );
  const [AdminAllow] = useState(
    loginuserdata.email === process.env.REACT_APP_ADMIN_EMAIL
  );
  const [userdata, setuserdata] = useState<TUserData>({
    full_name: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile_no: "",
    logos: "",
    photo: "",
    telephone_no: "",
    contact_type: "",
    contact_address: "",
    password: "",
  });

  const [photo, setPhoto] = useState<string>("");
  const { id } = useParams();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "mobile_no") {
      const formatNumber = formatPhoneNumber(e.target.value);
      setuserdata({ ...userdata, mobile_no: formatNumber });
    } else if (e.target.name === "telephone_no") {
      const formatNumber = formatPhoneNumber(e.target.value);
      setuserdata({ ...userdata, telephone_no: formatNumber });
    } else {
      setuserdata({ ...userdata, [e.target.name]: e.target.value });
    }
  };

  const handleEdit = async (e: any) => {
    e.preventDefault();
    let body: TUpdateUser = {
      first_name: userdata.first_name,
      last_name: userdata.last_name,
      email: userdata.email,
      mobile_no: userdata.mobile_no,
      telephone_no: userdata.telephone_no,
      contact_type: userdata.contact_type,
      contact_address: userdata.contact_address,
      password: userdata?.password || "",
    };
    if (photo) {
      body.photo = userdata.photo;
    }
    let response = await UpdateUser(id as string, body);
    if (response.status) {
      let officeId = process.env.REACT_APP_OFFICEID as string;
      dispatch(GetAllContacts(officeId) as any);
      if (process.env.REACT_APP_ADMIN_EMAIL === LoginUserProfile.email) {
        dispatch(GetAllAdmins() as any);
      }
      NotificationManager.success("Contact Updated Successfully", "", 800);
    }
  };
  const FetchUsers = useCallback(async () => {
    setLoader(true);
    let response = await GetUserById(id as string);
    if (response.status) {
      let userdata: TUserData = response.data;
      setuserdata({
        full_name: userdata.full_name,
        first_name: userdata.first_name,
        last_name: userdata.last_name,
        email: userdata.email,
        mobile_no: userdata.mobile_no || "",
        logos: userdata.logos,
        photo: userdata.photo,
        telephone_no: userdata.telephone_no,
        contact_type: userdata.contact_type,
        contact_address: userdata.contact_address,
        password: "",
      });
      setPhoto("");
      setLoader(false);
    }
  }, [id]);

  useEffect(() => {
    FetchUsers();
  }, [FetchUsers]);

  return (
    <>
      {loader ? (
        <Loading marginTop="20px" />
      ) : (
        <>
          <NotificationContainer />
          <Container>
            <InnerContainer padding="1.8rem 32px 1rem 32px">
              <div className="profile-container">
                <h1>Profile</h1>
                <div className="avatar-container">
                  <Avatar
                    text="CH"
                    width="80px"
                    height="80px"
                    fontSize="23px"
                    textColor="#fefefe"
                    imgSrc={userdata?.photo || ""}
                    setImgSrc={setPhoto}
                    setuserdata={setuserdata}
                    data={userdata}
                    userProfile={true}
                  />

                  <div className="avatar-text-container">
                    <p className="main-text">
                      {userdata?.first_name}&nbsp;{userdata?.last_name}
                    </p>
                    <p className="sub-text !mt-[5px]">
                      {userdata?.contact_type || ""}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex flex-col gap-10 py-3 md:flex-row">
                  <div className="flex flex-col grow">
                    <p className="mb-2 input-label">First name</p>
                    <InputTexts
                      onBlur={handleEdit}
                      height="40px"
                      type={"text"}
                      name="first_name"
                      value={userdata?.first_name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex flex-col grow">
                    <p className="mb-2 input-label">Last name</p>
                    <InputTexts
                      onBlur={handleEdit}
                      height="40px"
                      type={"text"}
                      name="last_name"
                      value={userdata?.last_name}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="flex flex-col grow">
                    <p className="mb-2 input-label">Email</p>
                    <InputTexts
                      onBlur={handleEdit}
                      type={"email"}
                      height="40px"
                      name="email"
                      value={userdata?.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {userdata.contact_type === "Master Admin" ? (
                  <>
                    <div className="flex flex-col gap-10 py-3 md:flex-row ">
                      <div className="flex flex-col grow">
                        <p className="mb-2 input-label">Mobile No</p>
                        <InputTexts
                          onBlur={handleEdit}
                          height="40px"
                          type={"text"}
                          name="mobile_no"
                          value={userdata?.mobile_no}
                          onChange={handleChange}
                          classname="!min-w-[13rem]"
                        />
                      </div>
                      <div className={`flex flex-col grow `}>
                        <p className="mb-2 input-label">Password </p>
                        <InputPassword
                          disabled={
                            userdata?.contact_type === "Master Admin" &&
                            AdminAllow
                          }
                          height="40px"
                          type={"password"}
                          name="password"
                          value={userdata?.password || ""}
                          onChange={handleChange}
                          onBlur={handleEdit}
                         
                        />
                      </div>
                      <div className={`flex flex-col grow opacity-0`}>
                        <p className="mb-2 input-label">Membership Type</p>
                        <ContactDropDown
                          disabled={userdata?.contact_type === "Master Admin"}
                          height="43px"
                          width="100%"
                          className={`!min-w-[13.5rem] `}
                          value={userdata.contact_type}
                         
                          
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex flex-col gap-10 py-3 md:flex-row">
                      <div className="flex flex-col grow">
                        <p className="mb-2 input-label">Mobile No</p>
                        <InputTexts
                          onBlur={handleEdit}
                          height="40px"
                          type={"text"}
                          name="mobile_no"
                          value={userdata?.mobile_no}
                          onChange={handleChange}
                          classname="!min-w-[13.3rem]"
                        />
                      </div>

                      <div className={`flex flex-col grow `}>
                        <p className="mb-2 input-label">Membership Type</p>
                        <ContactDropDown
                          disabled={userdata?.contact_type === "Master Admin"}
                          height="43px"
                          width="100%"
                          className={`!min-w-[13.5rem] `}
                          value={userdata.contact_type}
                          onChange={(e) => {
                            setuserdata({ ...userdata, contact_type: e.value });
                            handleEdit(e);
                          }}
                          options={
                            process.env.REACT_APP_ADMIN_EMAIL ===
                            LoginUserProfile.email
                              ? AdminContactTypes
                              : ContactTypeOptions
                          }
                        />
                      </div>

                      <div className={`flex flex-col grow `}>
                        <p className="mb-2 input-label">Password </p>
                        <InputPassword
                          disabled={
                            userdata?.contact_type === "Master Admin" &&
                            AdminAllow
                          }
                          height="40px"
                          type={"password"}
                          name="password"
                          value={userdata?.password || ""}
                          onChange={handleChange}
                          onBlur={handleEdit}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </InnerContainer>
          </Container>
        </>
      )}
    </>
  );
};
