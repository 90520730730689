import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { TPinderFilters, TAllContacts } from "../types";
import { RootState } from "../store/store";
import { GetPinderProperties } from "../store/Slices/PinderSlice";
type InputProps = {
  url: string;
  TopFilters: TPinderFilters;
  propertyType: string;
  callingGetApi: boolean;
  initialLoader: boolean;
};
type ClientOption = {
  label: string;
  value: string;
};
export const useFetchPinderLocalData = ({
  url,
  TopFilters,
  propertyType = "",
  callingGetApi,
  initialLoader,
}: InputProps) => {
  let VendorsData: TAllContacts[] = useSelector(
    (state: RootState) => state?.contacts?.Contacts
  );
  const [TableData, setTableData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalResult, setTotalResults] = useState();
  const [ClientOptions, setClientOptions] = useState<ClientOption[]>([]);

  const CallListingApi = async () => {
    let queryparams = "";
    if (TopFilters?.PostCode?.length > 0) {
      TopFilters?.PostCode?.map((item) => {
        queryparams = queryparams + `postcode[]=${item.code}&`;
        return item.code;
      });
    }
    if (TopFilters?.Page > 0) {
      queryparams = queryparams + `page=${Number(TopFilters?.Page)}&`;
    }
    if (TopFilters?.PerPage > 0) {
      queryparams = queryparams + `perPage=${Number(TopFilters?.PerPage)}&`;
    }
    if (TopFilters?.MaxPrice > 0) {
      queryparams = queryparams + `maxPrice=${Number(TopFilters?.MaxPrice)}&`;
    }
    if (TopFilters?.MinPrice > 0) {
      queryparams = queryparams + `minPrice=${Number(TopFilters?.MinPrice)}&`;
    }
    if (TopFilters?.PropertyType?.length > 0) {
      TopFilters?.PropertyType?.map((item) => {
        queryparams = queryparams + `propertyType[]=${item.code}&`;
        return item.code;
      });
    }
    if (TopFilters?.ListingType?.length > 0) {
      TopFilters?.ListingType?.map((item) => {
        queryparams = queryparams + `listingType[]=${item.code}&`;
        return item.code;
      });
    }
    if (TopFilters?.Keyword?.length > 0) {
      queryparams = queryparams + `keyword=${TopFilters?.Keyword}`;
    }
    if (TopFilters?.State?.length > 0) {
      TopFilters?.State?.map((item) => {
        queryparams = queryparams + `state[]=${item.code}&`;
        return item.code;
      });
    }
    try {
      if (initialLoader) {
        setIsLoading(true);
      }

      let result = await GetPinderProperties(url, queryparams);
      if (result) {
        setIsLoading(false);
        let newArr: any = [];
        if (propertyType === "Commercial") {
          newArr = result?.data?.map((item: any) => {
            let background = false;
            let Admins = [];
            if (item?.AdminPropertyChanges !== null) {
              let AdminId = sessionStorage.getItem("userId");
              Admins = item?.AdminPropertyChanges?.filter(
                (item: any) => item.AdminId == AdminId
              );

              if (Admins?.length > 0) {
                background = true;
              } else {
                background = false;
              }
            }
            let clientname = "";
            if (item?.attachments?.length > 0) {
              let clients = item?.attachments.map((item: any) => item.clientId);
              let newclientname = clients?.map((item: any) => {
                let matchobj = VendorsData.find((obj) => {
                  return obj._id == item;
                });
                if (matchobj !== null && matchobj !== undefined) {
                  return matchobj.full_name;
                } else {
                  return null;
                }
              });
              if (newclientname?.length > 0) {
                newclientname?.map((item: any, index: any) => {
                  if (index == 0) {
                    if (item !== null) {
                      clientname = clientname + " " + item;
                    }
                  } else {
                    if (item?.length === 0) {
                      clientname = clientname + " " + item;
                    } else {
                      clientname = clientname + "," + item;
                    }
                  }
                });
              }
            }
            if (clientname == null) {
              clientname = "";
            } else {
              let names = clientname.split(",").map((name) => name.trim());
              let uniqueNames = Array.from(new Set(names));
              let resultString = uniqueNames.join(",");
              clientname = resultString;
            }
            if (background) {
              let descp =
                Admins[0]?.propertyDetails?.listDetails?.description.replace(
                  /\r\n\r\n/g,
                  ""
                );
              let newObj = {
                ...item,
                Background: background,
                propertyDetails: {
                  ...item?.propertyDetails,
                },
                id: item?.propertyDetails?._id || "",
                propertyId: Admins[0]?.propertyDetails?._id || "",
                Comments: Admins[0]?.propertyDetails?.Comments || "",
                Client: clientname || "",
                Listings: item?.propertyDetails?.ad?.url || "",
                Listing: Admins[0]?.propertyDetails?.searchMode || "",
                Address: Admins[0]?.propertyDetails?.address || "",
                Suburb:
                  Admins[0]?.propertyDetails?.metadata?.addressComponents
                    ?.suburb || "",
                State:
                  Admins[0]?.propertyDetails?.metadata?.addressComponents
                    ?.stateShort || "",
                Postcode:
                  Admins[0]?.propertyDetails?.metadata?.addressComponents
                    ?.postcode || "",
                PropertyType: Admins[0]?.propertyDetails?.propertyType || "",
                Price: Admins[0]?.propertyDetails?.price || "",
                NetRent: Admins[0]?.propertyDetails?.YieldReturn
                  ? Number(item?.propertyDetails?.extractedPrice) *
                    (Number(Admins[0]?.propertyDetails?.YieldReturn) / 100)
                  : Admins[0]?.propertyDetails?.NetRent || "",
                Outgoing: Admins[0]?.propertyDetails?.Outgoing || "",
                YieldReturn: Admins[0]?.propertyDetails?.YieldReturn || "",
                LandArea:
                  Admins[0]?.propertyDetails?.listDetails?.landAreaSqm || "",
                FloorArea:
                  Admins[0]?.propertyDetails?.listDetails?.buildingAreaSqm ||
                  "",
                TenureType: Admins[0]?.propertyDetails?.TenureType || "",
                NumofTenants: Admins[0]?.propertyDetails?.NumofTenants || "",
                Occupancy: Admins[0]?.propertyDetails?.Occupancy || "",
                TenantName: Admins[0]?.propertyDetails?.TenantName || "",
                LeaseExpiry: Admins[0]?.propertyDetails?.LeaseExpiry || "",
                Description: descp || "",
                SWOT: Admins[0]?.propertyDetails?.SWOT || "",
                SuburbProfile: Admins[0]?.propertyDetails?.SuburbProfile || "",
                DaysonMarket:
                  Admins[0]?.propertyDetails?.listDetails?.dateListed || "",
                AdvertUpdateDate:
                  Admins[0]?.propertyDetails?.listDetails?.dateUpdated || "",
                AgencyName: Admins[0]?.propertyDetails?.AgencyName || "",
                AgentName: Admins[0]?.propertyDetails?.AgentName || "",
                AgentContact: Admins[0]?.propertyDetails?.AgentContact || "",
                AgentEmail: Admins[0]?.propertyDetails?.AgentEmail || "",
                SaleAuction: Admins[0]?.propertyDetails?.SaleAuction || "",
                SaleExpressionofInterest:
                  Admins[0]?.propertyDetails?.SaleExpressionofInterest || "",
                TotalCarSpaces: Admins[0]?.propertyDetails?.carspaceCount || 0,
                Zoning: Admins[0]?.propertyDetails?.Zoning || "",
                Municipality: Admins[0]?.propertyDetails?.Municipality || "",
                Image: item?.propertyDetails?.media[0]?.imageUrl || "",
                Headline: Admins[0]?.propertyDetails?.headline || "",
              };
              return newObj;
            } else {
              let descp =
                item?.propertyDetails?.listDetails?.description?.replace(
                  /\r\n\r\n/g,
                  ""
                );

              let newObj = {
                ...item,
                Background: background,
                propertyDetails: {
                  ...item.propertyDetails,
                },
                id: item?.propertyDetails?._id || "",
                propertyId: item?.propertyDetails?.id || "",
                Comments: "",
                Client: clientname || "",
                Listings: item?.propertyDetails?.ad.url || "",
                Listing: item?.propertyDetails?.searchMode || "",
                Address: item?.propertyDetails?.address || "",
                Suburb:
                  item?.propertyDetails?.metadata?.addressComponents?.suburb ||
                  "",
                State:
                  item?.propertyDetails?.metadata?.addressComponents
                    ?.stateShort || "",
                Postcode:
                  item?.propertyDetails?.metadata?.addressComponents
                    ?.postcode || "",
                PropertyType: item?.propertyDetails?.propertyType || "",
                Price: item?.propertyDetails?.price || "",
                NetRent: "",
                Outgoing: "",
                YieldReturn: "",
                LandArea: item?.propertyDetails?.listDetails?.landAreaSqm || "",
                FloorArea:
                  item?.propertyDetails?.listDetails?.buildingAreaSqm || "",
                TenureType: "",
                NumofTenants: "",
                Occupancy: "",
                TenantName: "",
                LeaseExpiry: "",
                Description: descp || "",
                SWOT: "",
                SuburbProfile: "",
                DaysonMarket:
                  item?.propertyDetails?.listDetails?.dateListed || "",
                AdvertUpdateDate:
                  item?.propertyDetails?.listDetails?.dateUpdated || "",
                AgencyName: item?.propertyDetails?.advertiser?.name || "",
                AgentName:
                  item?.propertyDetails?.advertiser?.contacts[0]
                    ?.displayFullName || "",
                AgentContact:
                  item?.propertyDetails?.advertiser?.contacts[0]
                    ?.phoneNumbers[0]?.number || "",
                AgentEmail: "",
                SaleAuction: "",
                SaleExpressionofInterest: "",
                TotalCarSpaces:
                  item?.propertyDetails?.listDetails?.carspaces || 0,
                Zoning: "",
                Municipality: "",
                Image: item?.propertyDetails?.media[0]?.imageUrl || "",
                Headline: item?.propertyDetails?.headline || "",
              };
              return newObj;
            }
          });
        } else {
          newArr = result?.data?.map((item: any) => {
            let background = false;
            let Admins = [];

            if (item?.AdminPropertyChanges !== null) {
              let AdminId = sessionStorage.getItem("userId");
              Admins = item?.AdminPropertyChanges?.filter(
                (item: any) => item.AdminId == AdminId
              );

              if (Admins?.length > 0) {
                background = true;
              } else {
                background = false;
              }
            }
            let clientname = "";

            if (item?.attachments?.length > 0) {
              let clients = item?.attachments?.map(
                (item: any) => item.clientId
              );
              let newclientname = clients?.map((item: any) => {
                let matchobj = VendorsData?.find((obj) => {
                  return obj._id == item;
                });

                if (matchobj !== null && matchobj !== undefined) {
                  return matchobj.full_name;
                } else {
                  return null;
                }
              });

              if (newclientname.length > 0) {
                newclientname.map((item: any, index: any) => {
                  if (index == 0) {
                    if (item !== null) {
                      clientname = clientname + " " + item;
                    }
                  } else {
                    if (item !== null) {
                      if (item.length === 0) {
                        clientname = clientname + " " + item;
                      } else {
                        clientname = clientname + "," + item;
                      }
                    }
                  }
                });
              }

              // console.log(newclientname,"newclientname")
            }
            if (clientname == null) {
              clientname = "";
            } else {
              let names = clientname.split(",").map((name) => name.trim());
              let uniqueNames = Array.from(new Set(names));
              let resultString = uniqueNames.join(",");
              clientname = resultString;
            }

            if (background) {
              let descp =
                Admins[0]?.propertyDetails?.listing?.summaryDescription
                  .replace(/<b>(.*?)<\/b>/g, "")
                  .replace(/<br \/>/g, "");
              let Features = "";
              Admins[0]?.propertyDetails?.listing?.propertyDetails?.features?.map(
                (item: any, index: any) => {
                  if (index == 0) {
                    Features = Features + " " + item;
                  } else {
                    Features = Features + " , " + item;
                  }
                }
              );
              let newObj = {
                ...item,
                Background: background,
                propertyDetails: {
                  ...Admins[0]?.propertyDetails,
                },
                id: item.propertyDetails?._id || "",
                Comments: Admins[0]?.propertyDetails?.Comments || "",
                Client: clientname || "",
                Listings: `https://www.domain.com.au/${
                  Admins[0]?.propertyDetails?.listing?.listingSlug || ""
                }`,
                Listing: Admins[0]?.propertyDetails?.listing?.listingType || "",
                Address:
                  Admins[0]?.propertyDetails?.listing?.propertyDetails
                    ?.displayableAddress || "",
                Suburb:
                  Admins[0]?.propertyDetails?.listing?.propertyDetails
                    ?.suburb || "",
                State:
                  Admins[0]?.propertyDetails?.listing?.propertyDetails?.state ||
                  "",
                Postcode:
                  Admins[0]?.propertyDetails?.listing?.propertyDetails
                    ?.postcode || "",
                Bedrooms:
                  Admins[0]?.propertyDetails?.listing?.propertyDetails
                    ?.bedrooms || "",
                Bathrooms:
                  Admins[0]?.propertyDetails?.listing?.propertyDetails
                    ?.bathrooms || "",
                Ensuites: Admins[0]?.propertyDetails?.Ensuites || "",
                Toilets:
                  Admins[0]?.propertyDetails?.listing?.propertyDetails
                    ?.bathrooms || "",
                HouseSize:
                  Admins[0]?.propertyDetails?.listing?.propertyDetails
                    ?.buildingArea || "",
                LandSize:
                  Admins[0]?.propertyDetails?.listing?.propertyDetails
                    ?.landArea || "",
                Price:
                  Admins[0]?.propertyDetails?.listing?.priceDetails
                    ?.displayPrice || "",
                Rent: Admins[0]?.propertyDetails?.Yield
                  ? Number(item.propertyDetails.extractedPrice) *
                    (Number(Admins[0]?.propertyDetails?.Yield) / 100)
                  : Admins[0]?.propertyDetails?.Rent || "",
                Yield: Admins[0]?.propertyDetails?.Yield || "",
                Headline: Admins[0]?.propertyDetails?.listing?.headline || "",
                Description: descp || "",
                SWOT: Admins[0]?.propertyDetails?.SWOT || "",
                PropertyType:
                  Admins[0]?.propertyDetails?.listing?.propertyDetails
                    ?.propertyType || "",
                Outgoing: Admins[0]?.propertyDetails?.Outgoing || "",
                LandArea:
                  Admins[0]?.propertyDetails?.listing?.propertyDetails
                    ?.landArea || "",
                FloorArea:
                  Admins[0]?.propertyDetails?.listing?.propertyDetails
                    ?.buildingArea || "",
                TenureType: Admins[0]?.propertyDetails?.TenureType || "",
                NumofTenants: Admins[0]?.propertyDetails?.NumofTenants || "",
                Occupancy: Admins[0]?.propertyDetails?.Occupancy || "",
                TenantName: Admins[0]?.propertyDetails?.TenantName || "",
                LeaseExpiry: Admins[0]?.propertyDetails?.LeaseExpiry || "",
                SuburbProfile: Admins[0]?.propertyDetails?.SuburbProfile || "",
                DaysonMarket:
                  Admins[0]?.propertyDetails?.listing?.dateListed || "",
                AdvertUpdateDate:
                  Admins[0]?.propertyDetails?.listing?.dateListed || "",
                AgencyName: Admins[0]?.propertyDetails?.AgencyName || "",
                AgentName: Admins[0]?.propertyDetails?.AgentName || "",
                AgentContact: Admins[0]?.propertyDetails?.AgentContact || "",
                AgentEmail: Admins[0]?.propertyDetails?.AgentEmail || "",
                SaleAuction: Admins[0]?.propertyDetails?.SaleAuction || "",
                SaleExpressionofInterest:
                  Admins[0]?.propertyDetails?.SaleExpressionofInterest || "",
                TotalCarSpaces:
                  Admins[0]?.propertyDetails?.listing?.propertyDetails
                    ?.carspaces || 0,
                Zoning: Admins[0]?.propertyDetails?.Zoning || "",
                Municipality: Admins[0]?.propertyDetails?.Municipality || "",
                Features: Features || "",
                Image: item?.propertyDetails?.listing?.media[0]?.url || "",
              };
              return newObj;
            } else {
              let descp = item?.propertyDetails?.listing?.summaryDescription
                ?.replace(/<b>(.*?)<\/b>/g, "")
                .replace(/<br \/>/g, "");
              let Features = "";
              item?.propertyDetails?.listing?.propertyDetails?.features?.map(
                (item: any, index: any) => {
                  if (index == 0) {
                    Features = Features + " " + item;
                  } else {
                    Features = Features + " , " + item;
                  }
                }
              );

              let newObj = {
                ...item,
                Background: background,

                id: item.propertyDetails?._id || "",
                propertyId: item?.propertyDetails?.listing?.id || "",
                Comments: "",
                Client: clientname || "",
                Listings: `https://www.domain.com.au/${
                  item?.propertyDetails?.listing?.listingSlug || ""
                }`,
                Listing: item?.propertyDetails?.listing?.listingType || "",
                Address:
                  item?.propertyDetails?.listing?.propertyDetails
                    ?.displayableAddress || "",
                Suburb:
                  item?.propertyDetails?.listing?.propertyDetails?.suburb || "",
                State:
                  item?.propertyDetails?.listing?.propertyDetails?.state || "",
                Postcode:
                  item?.propertyDetails?.listing?.propertyDetails?.postcode ||
                  "",
                Bedrooms:
                  item?.propertyDetails?.listing?.propertyDetails?.bedrooms ||
                  "",
                Bathrooms:
                  item?.propertyDetails?.listing?.propertyDetails?.bathrooms ||
                  "",
                Ensuites: "",
                Toilets:
                  item?.propertyDetails?.listing?.propertyDetails?.bathrooms ||
                  "",
                HouseSize:
                  item?.propertyDetails?.listing?.propertyDetails
                    ?.buildingArea || "",
                LandSize:
                  item?.propertyDetails?.listing?.propertyDetails?.landArea ||
                  "",
                Price:
                  item.propertyDetails?.listing?.priceDetails?.displayPrice ||
                  "",
                Rent: "",
                Yield: "",
                Headline: item?.propertyDetails?.listing?.headline || "",
                Description: descp || "",
                SWOT: "",
                PropertyType:
                  item?.propertyDetails?.listing?.propertyDetails
                    ?.propertyType || "",
                Outgoing: "",
                LandArea:
                  item?.propertyDetails?.listing?.propertyDetails?.landArea ||
                  "",
                FloorArea:
                  item?.propertyDetails?.listing?.propertyDetails
                    ?.buildingArea || "",
                TenureType: "",
                NumofTenants: "",
                Occupancy: "",
                TenantName: "",
                LeaseExpiry: "",
                SuburbProfile: "",
                DaysonMarket: item?.propertyDetails?.listing?.dateListed || "",
                AdvertUpdateDate:
                  item?.propertyDetails?.listing?.dateListed || "",
                AgencyName:
                  item?.propertyDetails?.listing?.advertiser?.name || "",
                AgentName:
                  item?.propertyDetails?.listing?.advertiser?.contacts[0]
                    ?.name || "",
                AgentContact: "",
                AgentEmail: "",
                SaleAuction: "",
                SaleExpressionofInterest: "",
                TotalCarSpaces:
                  item?.propertyDetails?.listing?.propertyDetails?.carspaces ||
                  "",
                Zoning: "",
                Municipality: "",
                Features: Features || "",
                Image: item?.propertyDetails?.listing?.media[0]?.url || "",
              };
              return newObj;
            }
          });
        }

        setTableData(newArr);
        setTotalResults(result?.pagination?.total);
      }
    } catch (err) {}
  };

  useEffect(() => {
    const fetchData = async () => {
      await CallListingApi();
    };

    fetchData();
  }, [TopFilters, propertyType, callingGetApi]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    let newoptionsArr = VendorsData?.map((item) => {
      let newobj = {
        label: item?.full_name,
        value: item?._id,
      };
      return newobj;
    });
    setClientOptions(newoptionsArr);
  }, [VendorsData]);

  return { ClientOptions, isLoading, TableData, totalResult };
};
