import React, { useState, useEffect } from 'react'
import { InnerContainer, Container } from 'global.style'
import { Slider } from "primereact/slider";
import { Row, Col } from 'react-bootstrap';
import { InputNumbers } from 'components/ui/InputNumbers';
import { InputPercents } from 'components/ui/InputPercents';
import { TAssumptions, TUpdateSetting, UpdateSettingsData, GetSettingsData } from 'store/Slices/SettingsSlice';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/store';
export const Assumptions = () => {
  const assumptions = useSelector((state: RootState) => state.settings.Assumptions)
  const [Assumptions, setAssumptions] = useState<TAssumptions>(assumptions)
  const [initialRender, setinitialRender] = useState<boolean>(true)
  const dispatch = useDispatch();
  const UpdateAssumptions = async () => {
    let body: TUpdateSetting = {
      name: "Borrowing Assumptions",
      slug: "borrowing-assumptions",
      settings: {
        ...Assumptions,
      },
      office_id: process.env.REACT_APP_OFFICEID as string,
    }
    let response = await UpdateSettingsData(body)
    if (response.status) {
      let officeid = process.env.REACT_APP_OFFICEID as string
      dispatch(GetSettingsData(officeid) as any)

    }
  }
  useEffect(() => {
    if (!initialRender) {
      setTimeout(() => {
        UpdateAssumptions()
      }, 2000)
    } else {
      setinitialRender(false)
    }

  }, [Assumptions,initialRender])  // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Container>
      <InnerContainer padding='1.5rem 32px 2.5rem 32px'>
        <div className="profile-container">
          <h1>Assumptions</h1>
        </div>
        <div className='flex flex-col gap-[2rem]'>
          <Row>
            <Col sm={12} xs={12} md={12} lg={4}>
              <div>
                <p className="mb-[5px] input-label">Single Base Expense</p>
                <div className="flex items-center gap-3">
                  <div > 
                    <Slider
                      value={Assumptions.SingleBaseExpense}
                      onChange={(e) => {
                        setAssumptions({ ...Assumptions, SingleBaseExpense: Number(e.value) })
                      }}
                      className={"slider-field"}
                      step={1}
                      min={0}
                      max={100000}
                    />
                  </div>
                  <InputNumbers
                    onChange={(e) => {
                      setAssumptions({ ...Assumptions, SingleBaseExpense: Number(e.value) })
                    }}
                    value={Assumptions.SingleBaseExpense}
                    classes='!w-[98px]'
                    inputClassName={"!text-center !w-[98px]"}
                  />
                </div>
              </div>
            </Col>
            <Col sm={12} xs={12} md={12} lg={4}>
              <div>
                <p className="mb-[5px] input-label">Dependent Base Expensive</p>
                <div className="flex items-center gap-3">
                  <div >
                    <Slider
                      value={Assumptions.DependentBaseExpensive}
                      onChange={(e) => {
                        setAssumptions({ ...Assumptions, DependentBaseExpensive: Number(e.value) })
                      }}
                      className={"slider-field"}
                      step={1}
                      min={0}
                      max={100000}
                    />


                  </div>
                  <InputNumbers
                    onChange={(e) => {
                      setAssumptions({ ...Assumptions, DependentBaseExpensive: Number(e.value) })
                    }}
                    classes='!w-[98px]'
                    value={Assumptions.DependentBaseExpensive}
                    inputClassName={"!text-center !w-[98px]"}
                  />
                </div>

              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} xs={12} md={12} lg={4}>
              <div>
                <p className="mb-[5px] input-label">Service Income Factors</p>
                <div className="flex items-center gap-3">
                  <div >
                    <Slider
                      value={Assumptions.ServiceIncomeFactors}
                      onChange={(e) => {
                        setAssumptions({ ...Assumptions, ServiceIncomeFactors: Number(e.value) })
                      }}
                      className={"slider-field"}
                      step={1}
                      min={0}
                      max={100}
                    />
                  </div>
                  <InputPercents
                    onChange={(e) => {
                      setAssumptions({ ...Assumptions, ServiceIncomeFactors: Number(e.value) })
                    }}
                    classes='!w-[98px]'
                    value={Assumptions.ServiceIncomeFactors}
                    inputClassName={"!text-center !w-[98px]"}
                  />
                </div>

              </div>
            </Col>
            <Col sm={12} xs={12} md={12} lg={4}>
              <div>
                <p className="mb-[5px] input-label">Default BP Interest Rate</p>
                <div className="flex items-center gap-3">
                  <div >
                    <Slider
                      value={Assumptions.DefaultBPInterestRate}
                      onChange={(e) => {
                        setAssumptions({ ...Assumptions, DefaultBPInterestRate: Number(e.value) })
                      }}
                      className={"slider-field"}
                      step={1}
                      min={0}
                      max={100}
                    />


                  </div>
                  <InputPercents
                    onChange={(e) => {
                      setAssumptions({ ...Assumptions, DefaultBPInterestRate: Number(e.value) })
                    }}
                    classes='!w-[98px]'
                    value={Assumptions.DefaultBPInterestRate}
                    inputClassName={"!text-center !w-[98px]"}
                  />
                </div>

              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} xs={12} md={12} lg={4}>
              <div>
                <p className="mb-[5px] input-label">Interest Rate Buffer</p>
                <div className="flex items-center gap-3">
                  <div >
                    <Slider
                      value={Assumptions.InterestRateBuffer}
                      onChange={(e) => {
                        setAssumptions({ ...Assumptions, InterestRateBuffer: Number(e.value) })
                      }}
                      className={"slider-field"}
                      step={1}
                      min={0}
                      max={100}
                    />


                  </div>
                  <InputPercents
                    onChange={(e) => {
                      setAssumptions({ ...Assumptions, InterestRateBuffer: Number(e.value) })
                    }}
                    classes='!w-[98px]'
                    value={Assumptions.InterestRateBuffer}
                    inputClassName={"!text-center !w-[98px]"}
                  />
                </div>

              </div>
            </Col>
            <Col sm={12} xs={12} md={12} lg={4}>
              <div>
                <p className="mb-[5px] input-label">Consumer Price Index (CPI)</p>
                <div className="flex items-center gap-3">
                  <div >
                    <Slider
                      value={Assumptions.CPI}
                      onChange={(e) => {
                        setAssumptions({ ...Assumptions, CPI: Number(e.value) })
                      }}
                      className={"slider-field"}
                      step={1}
                      min={0}
                      max={100}
                    />


                  </div>
                  <InputNumbers
                    onChange={(e) => {
                      setAssumptions({ ...Assumptions, CPI: Number(e.value) })
                    }}
                    classes='!w-[98px]'
                    value={Assumptions.CPI}
                    inputClassName={"!text-center !w-[98px]"}
                  />
                </div>

              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} xs={12} md={12} lg={4}>
              <div>
                <p className="mb-[5px] input-label">DTI</p>
                <div className="flex items-center gap-3">
                  <div >
                    <Slider
                      value={Assumptions.DTI}
                      onChange={(e) => {
                        setAssumptions({ ...Assumptions, DTI: Number(e.value) })
                      }}
                      className={"slider-field"}
                      step={1}
                      min={0}
                      max={6}
                    />


                  </div>
                  <InputPercents
                    suffix=""
                    onChange={(e) => {
                      setAssumptions({ ...Assumptions, DTI: Number(e.value) })
                    }}
                    classes='!w-[98px]'
                    value={Assumptions.DTI}
                    inputClassName={"!text-center !w-[98px]"}
                  />
                </div>

              </div>
            </Col>
            <Col sm={12} xs={12} md={12} lg={4}>
              <div>
                <p className="mb-[5px] input-label">Required DSR (Banks Benchmark)</p>
                <div className="flex items-center gap-3">
                  <div >
                    <Slider
                      value={Assumptions.DSR}
                      onChange={(e) => {
                        setAssumptions({ ...Assumptions, DSR: Number(e.value) })
                      }}
                      className={"slider-field"}
                      step={1}
                      min={0}
                      max={100}
                    />


                  </div>
                  <InputPercents
                    onChange={(e) => {
                      setAssumptions({ ...Assumptions, DSR: Number(e.value) })
                    }}
                    classes='!w-[98px]'
                    value={Assumptions.DSR}
                    inputClassName={"!text-center !w-[98px]"}
                  />
                </div>

              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} xs={12} md={12} lg={4}>
              <div>
                <p className="mb-[5px] input-label">Debt Interest Rate</p>
                <div className="flex items-center gap-3">
                  <div >
                    <Slider
                      value={Assumptions.DebtInterestRate}
                      onChange={(e) => {
                        setAssumptions({ ...Assumptions, DebtInterestRate: Number(e.value),InterestRate:Number(e.value) })
                      }}
                      className={"slider-field"}
                      step={1}
                      min={0}
                      max={10}
                    />


                  </div>
                  <InputPercents
                    onChange={(e) => {
                      setAssumptions({ ...Assumptions, DebtInterestRate: Number(e.value),InterestRate:Number(e.value) })
                    }}
                    classes='!w-[98px]'
                    value={Assumptions.DebtInterestRate}
                    inputClassName={"!text-center !w-[98px]"}
                  />
                </div>

              </div>
            </Col>
            <Col sm={12} xs={12} md={12} lg={4}>
              <div>
                <p className="mb-[5px] input-label">Debt Principal Rate</p>
                <div className="flex items-center gap-3">
                  <div >
                    <Slider
                      value={Assumptions.DebtPrincipalRate}
                      onChange={(e) => {
                        setAssumptions({ ...Assumptions, DebtPrincipalRate: Number(e.value) })
                      }}
                      className={"slider-field"}
                      step={1}
                      min={0}
                      max={10}
                    />


                  </div>
                  <InputPercents
                    onChange={(e) => {
                      setAssumptions({ ...Assumptions, DebtPrincipalRate: Number(e.value) })
                    }}
                    classes='!w-[98px]'
                    value={Assumptions.DebtPrincipalRate}
                    inputClassName={"!text-center !w-[98px]"}
                  />
                </div>

              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} xs={12} md={12} lg={4}>
              <div>
                <p className="mb-[5px] input-label">Equity Interest Rate</p>
                <div className="flex items-center gap-3">
                  <div >
                    <Slider
                      value={Assumptions.EquityInterestRate}
                      onChange={(e) => {
                        setAssumptions({ ...Assumptions, EquityInterestRate: Number(e.value) })
                      }}
                      className={"slider-field"}
                      step={1}
                      min={0}
                      max={10}
                    />


                  </div>
                  <InputPercents
                    onChange={(e) => {
                      setAssumptions({ ...Assumptions, EquityInterestRate: Number(e.value) })
                    }}
                    classes='!w-[98px]'
                    value={Assumptions.EquityInterestRate}
                    inputClassName={"!text-center !w-[98px]"}
                  />
                </div>
              </div>
            </Col>
            <Col sm={12} xs={12} md={12} lg={4}>
              <div>
                <p className="mb-[5px] input-label">Equity Principal Rate</p>
                <div className="flex items-center gap-3">
                  <div >
                    <Slider
                      value={Assumptions.EquityPrincipalRate}
                      onChange={(e) => {
                        setAssumptions({ ...Assumptions, EquityPrincipalRate: Number(e.value) })
                      }}
                      className={"slider-field"}
                      step={1}
                      min={0}
                      max={10}
                    />


                  </div>
                  <InputPercents
                    onChange={(e) => {
                      setAssumptions({ ...Assumptions, EquityPrincipalRate: Number(e.value) })
                    }}
                    classes='!w-[98px]'
                    value={Assumptions.EquityPrincipalRate}
                    inputClassName={"!text-center !w-[98px]"}
                  />
                </div>

              </div>
            </Col>
          </Row>
        </div>
      </InnerContainer>
    </Container>
  )
}
