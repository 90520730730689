import React, { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/store';
import { HeaderContainer, HeaderLeft, HeaderRight, Text } from 'global.style';
import { Image } from 'react-bootstrap';
import ExpandDown from "assets/images/Expand_down.svg";
import { Link } from 'react-router-dom';
import { Searchbar } from '../Searchbar';
import { Avatar } from '../Avatar';
import autosave from "assets/images/cloud-check.svg"
import { setPath } from 'store/Slices/PathSlice';
import { ToogleSidebar } from 'store/Slices/SidebarSlice';
export const Header = () => {
  const Savestate = useSelector((state: RootState) => state.autoSave.AutoSave)
  const headerpath: string = useSelector((state: RootState) => state.path.headerPath)
  const UserData = useSelector((state: RootState) => state.userProfile.data)
  const sidbaropen = useSelector((state:RootState)=>state.sidebar.open)
  const mypath: string[] = headerpath?.slice(1)?.split("/") || [];
  const IconRef = useRef<any>(null);
  const [SearchQuery, setSearchQuery] = useState<string>("")
  let accountType = sessionStorage.getItem("accountType") as string;

  const dispatch = useDispatch();
  return (
    <HeaderContainer>
      <div
        className="coming-DropDown animate__animated animate__fadeInDown"
        ref={IconRef}
      >
        Coming Soon
      </div>
      <HeaderLeft>
        <i
          className="pi pi-bars"
          onClick={() => {
            dispatch(ToogleSidebar({open:!sidbaropen}))
          }}
        ></i>
        <Text>Home</Text>
        {mypath?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {item === "Dashboard" ? (
                ""
              ) : (
                <>
                  <Image src={ExpandDown} />
                  <Text
                    fontWeight={index === mypath.length - 1 ? "bold" : ""}
                    key={index}
                  >
                    {item === "Equity"
                      ? `${item} Calculator`
                      : item === "Vendor"
                        ? `${item} Detail`
                        : item === "Summary"
                          ? `Portfolio ${item}`
                          : item === "RiskProfiling"
                            ? "Risk Profiling"
                            : item === "IncomePlan"
                              ? "Income Plan"
                              : item}
                  </Text>
                </>
              )}
            </React.Fragment>
          );
        })}
      </HeaderLeft>
      <HeaderRight>
        {((mypath[1] === "Finances") && (Savestate)) &&
          <div style={{
            fontFamily: "Inter",
            fontSize: "10px",
            fontWeight: "normal",
            color: "#323232",
            display: "flex",
            alignItems: "center",
            gap: "5px",

          }}>
            <img src={autosave} alt="No Img" />
            Auto Save</div>
        }
        {accountType !== "Contacts" && (
          <>
            <Searchbar
              SearchQuery={SearchQuery}
              setSearchQuery={setSearchQuery}
              placeholder="Search Vendors"
              FromHeader={true}
            />
          </>
        )}


        <Link to="/Profile" onClick={() => { dispatch(setPath({ SidebarPath: 'Profile', headerPath: '/Profile' })) }}>
          <Avatar
            text={UserData?.photo ? UserData?.photo || "" : UserData?.full_name || ""}
            width="46px"
            height="46px"
            fontSize="16px"
            textColor="var(--white)"
            imgSrc={UserData?.photo || ""}
            data={UserData}
            userProfile={true}
          />
        </Link>
      </HeaderRight>
    </HeaderContainer>
  )
}
