/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FloatingBox } from "components/screen-components/FloatingBox";
import { SpendingTable } from "../SpendingTable";
import classes from "stylesheet.module.css";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { commaStringSeparatorsWithDollors } from "utils/helperFunctions";
import moment from "moment";
const DataOverview = () => {
  const Calstate = useSelector((state: RootState) => state.calculator);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [currentColumn, setCurrentColumn] = useState<number>(0);
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };
    const GetEquityGainsTotalVal = () => { 
      let value = 0;
      let vk = 0;
      Calstate?.StrategicSpending?.EquityGains?.map((item) => {
        const currentDate = new Date()
        let isBetween = currentDate >= new Date(item.StartDate) && currentDate <= new Date(item.EndDate);
       
       
        if (isBetween) {
          let vk = item.value / item.noOfMonths;
          value = value + vk;
        } 
      });
      
      return value;
    };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="flex gap-[20px] mt-[20px]">
      <SpendingTable
        setCurrentColumn={setCurrentColumn}
      />

      <FloatingBox
        className={
          document.documentElement.scrollHeight - 1000 > scrollPosition
            ? "floatingbox"
            : "floatingbox fadeOut"
        }
        style={{
          top: scrollPosition,
          height: "240px",
          width: "300px",
          margin: "0px ",
        }}
      >
        <p className={`${classes.itemTitle} mt-4`}>Cash on Hand</p>
        <p className="itemValue">
          {commaStringSeparatorsWithDollors(
            (Calstate?.StrategicSpending?.Data?.length > 0 &&
              Calstate?.StrategicSpending?.Data[currentColumn < 0 ? 0 : currentColumn]?.balanceSheet
                .cashOnHand.total) ||
              Calstate.StrategicSpending.CashOnHand
          )}{" "}
          -{" "}
          {(Calstate?.StrategicSpending?.Data?.length > 0 &&
            Calstate?.StrategicSpending?.Data[currentColumn < 0 ? 0 : currentColumn]?.date.replace(
              /-/g,
              " "
            )) ||
            ""}
        </p>
        <p className={`${classes.itemTitle} mt-4`}>Equity Gained</p>
        <p className="itemValue">
          {commaStringSeparatorsWithDollors(
            (Calstate?.StrategicSpending?.Data?.length > 0 &&
              Calstate?.StrategicSpending?.Data[currentColumn < 0 ? 0 : currentColumn]?.balanceSheet
                .EquityGain) ||
              Calstate.Equity.UsableEquity
          )}{" "}
          -{" "}
          {(Calstate?.StrategicSpending?.Data?.length > 0 &&
            Calstate?.StrategicSpending?.Data[currentColumn < 0 ? 0 : currentColumn]?.date.replace(
              /-/g,
              " "
            )) ||
            ""}
        </p>

        <div className={`mt-4 ${classes.ResultBox}`}>
          <p className={`${classes.itemTitle}`}>Cash on Hand + Equity Gained</p>
          <p className="itemValue">
            {" "}
            {commaStringSeparatorsWithDollors(
              ((Calstate?.StrategicSpending?.Data?.length > 0 &&
                Calstate?.StrategicSpending?.Data[currentColumn < 0 ? 0 : currentColumn]?.balanceSheet
                .cashOnHand.total) ||
              Calstate.StrategicSpending.CashOnHand) + ((Calstate?.StrategicSpending?.Data?.length > 0 &&
                Calstate?.StrategicSpending?.Data[currentColumn < 0 ? 0 : currentColumn]?.balanceSheet
                      .EquityGain) ||
                    Calstate.Equity.UsableEquity) + (currentColumn === 0 ? 0 : GetEquityGainsTotalVal()) ||
                    0
            )}{" "}
          </p>
        </div>
      </FloatingBox>
    </div>
  );
};

export default DataOverview;
