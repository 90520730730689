import React from 'react'
type PdfIconProps = {
color?:string
}
export const PdfIcon = ({color}:PdfIconProps) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.444 11.515c-.266-.262-.858-.4-1.758-.413a14.686 14.686 0 0 0-2.112.155c-.345-.2-.701-.417-.98-.678-.751-.701-1.379-1.675-1.769-2.746.025-.1.048-.188.068-.278 0 0 .423-2.403.31-3.216a.914.914 0 0 0-.054-.23l-.036-.095c-.115-.265-.342-.546-.695-.531l-.214-.006c-.395 0-.716.2-.8.503-.256.947.009 2.362.487 4.194l-.122.299c-.344.837-.774 1.681-1.154 2.425l-.05.096a40.85 40.85 0 0 1-1.091 2.009l-.339.18c-.025.012-.606.321-.742.404-1.158.69-1.924 1.475-2.052 2.097-.04.199-.01.453.195.57l.33.165a.99.99 0 0 0 .445.108c.824 0 1.782-1.027 3.1-3.328a30.986 30.986 0 0 1 4.774-1.135c1.158.651 2.581 1.104 3.479 1.104a1.6 1.6 0 0 0 .409-.045.696.696 0 0 0 .406-.278c.174-.262.21-.623.162-.993-.013-.11-.1-.245-.196-.34l-.001.003zM4.133 15.902c.15-.412.745-1.224 1.625-1.945.055-.045.19-.173.316-.292-.92 1.468-1.536 2.053-1.941 2.235v.002zm5.21-12c.266 0 .417.667.43 1.293.012.627-.134 1.067-.315 1.392-.15-.482-.224-1.24-.224-1.737 0 0-.011-.948.11-.948zm-1.554 8.55c.185-.33.376-.678.572-1.048.48-.905.78-1.612 1.005-2.194a7.266 7.266 0 0 0 1.66 2.062c.082.068.17.138.26.207-1.333.264-2.485.584-3.498.974zm8.4-.074c-.081.051-.314.08-.463.08-.482 0-1.08-.22-1.916-.58.321-.024.616-.036.881-.036.484 0 .628-.003 1.1.118.473.122.48.367.398.417z" fill={color}/>
    <path d="M17.926 4.474a17.739 17.739 0 0 0-1.702-1.948A17.74 17.74 0 0 0 14.276.824C13.27.085 12.78 0 12.5 0H2.812C1.953 0 1.25.701 1.25 1.563v16.875c0 .86.701 1.562 1.563 1.562h14.374c.862 0 1.563-.701 1.563-1.563V6.25c0-.28-.085-.769-.824-1.776zM15.34 3.41c.6.6 1.07 1.14 1.417 1.589H13.75V1.994c.448.347.99.817 1.588 1.417h.002zM17.5 18.438c0 .17-.142.312-.313.312H2.813a.317.317 0 0 1-.312-.313V1.563c0-.168.144-.312.313-.312H12.5v4.375c0 .345.28.625.625.625H17.5v12.188z" fill={color}/>
</svg>
  )
}
