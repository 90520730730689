import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import url from "../../config";
import {
  TProgress,
  TEquity,
  TBorrowingCalculator,
  TIncomePlanItem,
  TPropertyGeneratorItem,
  TRiskProfile,
  TStrategicSpending,
  TPortfolioSummary,
  TAllContacts
} from "types";

export type TCalculatorData = {
  Equity: TEquity;
  BorrowingCalculator: TBorrowingCalculator;
  IncomePlan: Array<TIncomePlanItem>;
  PropertyGenerators: Array<TPropertyGeneratorItem>;
  Progress: TProgress;
  RiskProfile: TRiskProfile;
  StrategicSpending: TStrategicSpending;
  PortfolioSummary: TPortfolioSummary;
  VendorId: string;

  _id: string;
};

const initialState: TCalculatorData = {
  Equity: {
    Cash: 0,
    LiquidAssets: 0,
    PPR: {
      Address: "",
      Mortgage: 0,
      InterestRate: 0,
      DebtProvider: "",
      AutoValuation: 0,
      PurchaseDate: "",
      LoanTerms: 0,
      RemainingLoanTerm: 0,
      LoanRepayments: {
        type: "Monthly",
        value: 0,
        principalvalue:0,
        interestvalue:0,
      },
      InterestOnly: true,
      PrincipalAndInterest: false,
    },
    Properties: [],
    MaxBorrowingCapacity: 0,
    UsableEquity: 0,
    TotalDebt: 0,
    UsableEquityAndCash: 0,
  },
  BorrowingCalculator: {
    income_goal: 0,
    FinancialYear: 0,
    LivingExpenses: 0,
    ManualExpense: 0,
    BasiqExpenses:0,
    TotalExpenses: 0,
    MonthlyCashFlow:0,
    OtherPayments: {
      type: "Monthly",
      value: 0,
    },
    UseBasiq: false,
    UseAverageAustralian: true,
    UseManual: false,
    IncomeDetails: {
      UserPartner: false,
      YourIncome: {
        type: "Annually",
        value: 0,
      },
      PartnerIncome: {
        type: "Annually",
        value: 0,
      },
      NetRentalIncome: {
        type: "Annually",
        value: 0,
      },
      OtherIncome: {
        type: "Annually",
        value: 0,
        icomeType: "Net",
      },
    },
    ExpenseDetails: {
      Dependants: 0,
      AlcoholicBeverages: 0,
      ClothingAndFootwear: 0,
      Communication: 0,
      CurrentHousingCosts: 0,
      DomesticFuelAndPower: 0,
      Education: 0,
      FoodNonAlcoholicBeverages: 0,
      HouseholdFurnishingsAndEquipment: 0,
      HouseholdServicesAndOperation: 0,
      MedicalCareAndHealthExpenses: 0,
      PersonalCare: 0,
      Recreation: 0,
      Transport: 0,
      AccountantFees: 0,
      BodyCorporatePayments: 0,
      CashGiftDonationsToCharityNec: 0,
      FeesNecGovernment: 0,
      FeesNecPrivate: 0,
      FinancialInstitutionCharges: 0,
      HireServicesNec: 0,
      InsuranceNec: 0,
      MiscellaneousGoodsNec: 0,
      MiscellaneousGoodsNfd: 0,
      MiscellaneousServicesNec: 0,
      NonHolidayAccommodation: 0,
      StationeryEquipmentNfd: 0,
      TravelGoodsHandbags: 0,
      UnionDues: 0,
      OtherPayment: 0,
      CashWithdrawals: 0,
      BankFees: 0,
      LoanInterest: 0,
      ExternalTransfers: 0,
    },
    PersonalLiabilities: {
      CreditCards: [],
      creditCardPartner:0,
      creditCardYours:0,
      TotalCardDebt:0,
      TotalCardLimit:0,
      TotalCreditCardLimit:0,
      TotalLoan:0,
      Loan:0
    },
  },
  IncomePlan: [],
  PropertyGenerators: [],
  Progress: {
    steps: 5,
    completed: 0,
    current: 1,
  },
  RiskProfile: {
    NetYieldonProperty: 5,
    PropertyType: "Residential",
    PropertyMode: "Conservative",
  },
  StrategicSpending: {
    EquityGains: [],
    Balance: 0,
    CashOnHand: 0,
    AccountYours: 0,
    AccountPartner: 0,
    TotalEquityGain: 0,
    ExtraCash: [],
    CapitalGrowth: [],
    TotalLiabilities: 0,
    MonthlySavings: 0,
    Income: 0,
    NetSalaryYours: 0,
    NetSalaryPartner: 0,
    RentalIncome: 0,
    OtherIncome: 0,
    Data:[]
  },
  PortfolioSummary: {
    Properties:{
      retail:[],
      office:[],
      other:[],
      industrial:[],
      residential:[]
    },
    PropertyPurchase:[],
    selectedRegions:[]
  },
  
  VendorId: "",
  _id: "",
};
type GetCalResponse = {
  data: TCalculatorData;
  message: string;
  status: boolean;
};
export const SaveCalData = async (CalculatorData: TCalculatorData) => {
  try {
    let { _id, ...ResData } = CalculatorData;
    let response = await url.post(
      `vendor-profile/save/${CalculatorData.VendorId}`,
      ResData
    );
    return response.data;
  } catch (err) {}
};

export const GetUserCalData = createAsyncThunk(
  "/vendor-profile/get/${id}",
  async (id:string) => {
    try {
      let response = await url.get(`/vendor-profile/get/${id}`);
      return response.data;
    } catch (err) {}
  }
);
const CalculatorSlice = createSlice({
  name: "calculator",
  initialState,
  reducers: {
    SetCalData: (state, action: PayloadAction<TCalculatorData>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(
      GetUserCalData.fulfilled,
      (state, action: PayloadAction<GetCalResponse>) => {
        return {
          ...state,
          ...action.payload.data,
        };
      }
    );
   
  },
});
export const { SetCalData } = CalculatorSlice.actions;
export default CalculatorSlice.reducer;
