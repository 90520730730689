import React from "react";
import {
  OuterContainer,
  LeftContainer,
  TaskCard,
  InnerContainer,
  CardsContainer,
  SmallCard,
  PropertyPortfolio,
  PropertyType,
} from "global.style";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import pic1 from "assets/images/Vector1.png";
import pic2 from "assets/images/Vector2.png";
import pic3 from "assets/images/Vector3.png";
import pic4 from "assets/images/Vector4.png";
import pic5 from "assets/images/Vector5.png";
import pic6 from "assets/images/Vector6.png";
import { commaStringSeparatorsWithDollors } from "utils/helperFunctions";
export const ClientDashboard = () => {
  const userdata = useSelector((state: RootState) => state.userProfile.data);
  const usercaldata = useSelector((state: RootState) => state.calculator);
  return (
    <OuterContainer>
      <InnerContainer width="100%" bg={true} padding="1rem 15px 1rem 15px">
        <LeftContainer>
          <TaskCard style={{ color: "black " }}>
            Hi {userdata?.first_name}! <br />
          </TaskCard>
          <CardsContainer>
            <div className="card-row !mb-[20px]">
              <SmallCard>
                <div className="txtContainer">
                  <span className="span1" style={{ color: "black " }}>
                    Lifestyle Goal
                  </span>
                  <span className="span2">"$0.00"</span>
                </div>
                <div className="imgContainer">
                  <img src={pic1} />
                </div>
              </SmallCard>
              <SmallCard>
                <div className="txtContainer">
                  <span className="span1" style={{ color: "black " }}>
                    In Which Financial Year
                  </span>
                  <span className="span2">{0}</span>
                </div>
                <div className="imgContainer">
                  <img src={pic2} />
                </div>
              </SmallCard>
              <SmallCard>
                <div className="txtContainer">
                  <span className="span1" style={{ color: "black " }}>
                    Total Portfolio Value
                  </span>
                  <span className="span2">{0}</span>
                </div>
                <div className="imgContainer">
                  <img src={pic3} />
                </div>
              </SmallCard>
            </div>
            <div className="card-row">
              <SmallCard>
                <div className="txtContainer">
                  <span className="span1" style={{ color: "black " }}>
                    Average Yield
                  </span>
                  <span className="span2"> {0}%</span>
                </div>
                <div className="imgContainer">
                  <img src={pic4} />
                </div>
              </SmallCard>
              <SmallCard>
                <div className="txtContainer">
                  <span className="span1" style={{ color: "black " }}>
                    Cash + Equity Available
                  </span>
                  <span className="span2">{0}</span>
                </div>
                <div className="imgContainer">
                  <img src={pic5} />
                </div>
              </SmallCard>
              <SmallCard>
                <div className="txtContainer">
                  <span className="span1" style={{ color: "black " }}>
                    Year 1 Cashflow (Monthly)
                  </span>
                  <span className="span2">{0}</span>
                </div>
                <div className="imgContainer">
                  <img src={pic6} />
                </div>
              </SmallCard>
            </div>
          </CardsContainer>
          <PropertyPortfolio>
            <div className="portfolio-header">
              <span>My Property Portfolio</span>
            </div>
            <div className="propertyList">
              <table>
                <tbody>
                  {(usercaldata?.Equity?.PPR?.Address?.length > 0 ||
                    usercaldata?.Equity?.PPR?.AutoValuation > 0) && (
                    <tr className="items-center">
                      <td className="property-list-img">
                        <span>{usercaldata?.Equity?.PPR?.Address}</span>
                      </td>
                      <PropertyType color="#00a779" bgcolor="#ebfff9">
                        Primary
                      </PropertyType>
                      <td className="value">
                        Valued at
                        <br />
                        <span className="Blurtxt">
                          {commaStringSeparatorsWithDollors(
                            usercaldata?.Equity?.PPR?.AutoValuation
                          )}
                        </span>
                      </td>
                    </tr>
                  )}
                  {usercaldata?.Equity?.Properties?.length > 0 && (
                    <>
                      {usercaldata?.Equity?.Properties?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className="property-list-img">
                              <span>{item.Address}</span>
                            </td>
                            <PropertyType color="#b7b6e2" bgcolor="#272660">
                              Investment
                            </PropertyType>
                            <td className="value">
                              Valued at
                              <br />
                              <span>{item?.AutoValuation}</span>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </PropertyPortfolio>
        </LeftContainer>
      </InnerContainer>
    </OuterContainer>
  );
};
