import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { commaStringSeparatorsWithDollors } from "utils/helperFunctions";

const ClientOverview = () => {
  const userCalData = useSelector((state: RootState) => state.calculator);

  const totalAssets =
    userCalData?.Equity?.LiquidAssets +
    userCalData?.Equity?.Cash +
    userCalData?.Equity?.PPR?.AutoValuation +
    userCalData?.Equity?.Properties?.reduce((total, NextProperty) => {
      return (total += NextProperty.AutoValuation);
    }, 0);
  let cardpayments =
    userCalData.BorrowingCalculator.PersonalLiabilities.CreditCards.reduce(
      (total, nextcard) => {
        return (total += nextcard.minimumPayment);
      },
      0
    );
  const totalLiabilities =
    userCalData?.Equity?.PPR.Mortgage +
    userCalData?.Equity?.Properties?.reduce((total, NextProperty) => {
      return (total += NextProperty.Mortgage);
    }, 0) +
    userCalData.BorrowingCalculator.PersonalLiabilities.CreditCards.reduce(
      (total, nextcard) => {
        return (total += nextcard.currentDebt);
      },
      0
    );
  let propertynetIncomVal = userCalData.Equity?.Properties?.reduce(
    (prevItem, nextItem) => {
      if (nextItem.NetPropertyIncome.value < 0) {
        prevItem = prevItem + Math.abs(nextItem.NetPropertyIncome.value);
      }
      return prevItem;
    },
    0
  );
  const GetRepayments = () => {
    let homerepayment = 0;
    let propertyrepayment = 0;
    let PPRLaonRepayment = userCalData.Equity.PPR.LoanRepayments;
    if (PPRLaonRepayment.type === "Annually") {
      homerepayment = PPRLaonRepayment.value / 12;
    } else if (PPRLaonRepayment.type === "Weekly") {
      homerepayment = (PPRLaonRepayment.value * 52) / 12;
    } else {
      homerepayment = PPRLaonRepayment.value;
    }
    userCalData.Equity.Properties.map((item) => {
      let repayment = 0;
      if (item.LoanRepayments.type === "Annually") {
        repayment = item.LoanRepayments.value / 12;
      } else if (item.LoanRepayments.type === "Weekly") {
        repayment = (item.LoanRepayments.value * 52) / 12;
      } else {
        repayment = item.LoanRepayments.value;
      }
      propertyrepayment += repayment;
    });

    return homerepayment + propertyrepayment;
  };
  let totalexpense =
    userCalData?.BorrowingCalculator?.TotalExpenses +
    cardpayments +
    propertynetIncomVal / 12 +
    GetRepayments();
  return (
    <div className="mt-10 sumaryboxes">
      <div className="boxs1 myboxes">
        <div className="grid">
          <div className="col-fixed">
            <p className="!text-[14px] font-medium !text-[red]">
              Total Income <br />
            </p>
            <p>
              {commaStringSeparatorsWithDollors(
                userCalData.StrategicSpending.Income
              )}
            </p>
          </div>
          <div className="col-fixed">
            <p className="flex items-center justify-center gap-2 !text-[14px] font-medium !text-[red]">
              Monthly Savings
            </p>
            <p >
                {commaStringSeparatorsWithDollors(
                 ( userCalData?.StrategicSpending?.Income || 0)-(totalexpense ||0)
                )}
              </p>
          </div>
          <div className="col-fixed">
            <p className="!text-[14px] font-medium !text-[red]">Total Assets</p>
            <p className="mb-2">
              {commaStringSeparatorsWithDollors(totalAssets)}
            </p>
          </div>
          <div className="col-fixed">
            <p className="!text-[14px] font-medium !text-[red]">
              Estimated Outgoings
            </p>
            <p className="mb-2">
              {" "}
              {commaStringSeparatorsWithDollors(
                userCalData.Equity.Properties?.reduce(
                  (total, NextProperty) =>
                    (total += Number(NextProperty.EstimatedOutgoings)),
                  0
                )
              )}
            </p>
          </div>

          <div className="col-fixed boxShow">
            <p className="!text-[14px] font-medium !text-[red]">
              Total Expenses
            </p>
            <p>{commaStringSeparatorsWithDollors(totalexpense)}</p>
          </div>
          <div className="col-fixed boxShow">
            <p className="!text-[14px] font-medium !text-[red]">Cash On Hand</p>
            <p className="mb-2">
              {commaStringSeparatorsWithDollors(userCalData.Equity.Cash)}
            </p>
          </div>
          <div className="col-fixed boxShow">
            <p className="!text-[14px] font-medium !text-[red]">
              Total Liabilities
            </p>
            <p className="mb-2">
              {commaStringSeparatorsWithDollors(totalLiabilities)}
            </p>
          </div>
          <div className="col-fixed boxShow">
            <p className="!text-[14px] font-medium !text-[red]">
              Useable Equity
            </p>
            <p className="mb-2">
              {commaStringSeparatorsWithDollors(
                userCalData.Equity.UsableEquity
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientOverview;
