import React, { useState, useRef } from "react";
import { Toast } from "primereact/toast";
import { GradientButton } from "components/ui/Buttons/GradientButton";
import { Loading } from "components/ui/Loading";
import AnimateHeight from "components/screen-components/AnimateHeight";
const SuburbProfile = ({
  rowData,
  editingRows,
  setCurrentEditField,
  seteditingRows,
}: {
  rowData: any;
  editingRows: any;
  setCurrentEditField: any;
  seteditingRows: any;
}) => {
  const [profile, setprofile] = useState(rowData?.SuburbProfile || "");
  const [loading, setloading] = useState(false);
  const [open, setOpen] = useState(
    rowData?.SuburbProfile !== "" ? false : true
  );
  const toast = useRef<any>(null);
  const height = open ? "auto" : 0;
  const HandleClick = () => {
    setOpen(!open);
  };
  const showSuccess = (Message: any) => {
    toast.current.show({
      severity: "warn",
      life: 2000,
      content: (
        <div
          className="flex flex-column "
          style={{ paddingTop: "0px", paddingBottom: "0px", width: "100%" }}
        >
          <div className="text-center ">
            <p className="popup-warn-text">{Message}</p>
          </div>
        </div>
      ),
    });
  };
  const onGetDataComplete = (rowData: any, newValue: any, field: any) => {
    const existingRowIndex = editingRows?.findIndex(
      (item: any) => item._id === rowData.id
    );
    rowData[field] = newValue;
    setCurrentEditField({
      fieldname: field,
      oldvalue: "",
      newvalue: newValue,
      propertyId: rowData.propertyId,
    });
    if (existingRowIndex !== -1) {
      const updatedRows = [...editingRows];

      updatedRows[existingRowIndex] = {
        ...updatedRows[existingRowIndex],
        [field]: newValue,
      };

      seteditingRows(updatedRows);
    } else {
      seteditingRows((prevEditingRows: any) => [...prevEditingRows, rowData]);
    }
  };
  const CallingChatGpt = async () => {
    try {
      const conversation = [
        {
          role: "system",
          content: "You are a writer creating a suburb profile.",
        },
        {
          role: "user",
          content: `Name this article Suburb Profile - (2. Suburb:${rowData.Suburb}, 2. State: ${rowData.State}, 2. Postcode: ${rowData.Postcode}). Additionally, include a 50-word InvestLogic Disclaimer at the beginning of the profile. This disclaimer should inform readers that the profile is based on available data and does not constitute investment advice.`,
        },
      ];
      const apiUrl = "https://api.openai.com/v1/chat/completions";
      const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
      let apidata = "";
      const response: any = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKey}`,
        },
        body: JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: conversation,
          temperature: 0.8,
          max_tokens: 256,
          stream: true,
        }),
      }).catch((err) => {
        showSuccess(err);
      });
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }
        const decodedChunk = decoder.decode(value);
        const lines = decodedChunk.split("\n");
        const parsedLines = lines
          .map((line) => line.replace(/^data: /, "").trim())
          .filter((line) => line !== "")
          .map((line) => {
            if (line !== "[DONE]") {
              return JSON.parse(line);
            } else {
              return line;
            }
          }); //&& line!=="[DONE]"
        for (const parsedLine of parsedLines) {
          if (parsedLine == "[DONE]") {
            onGetDataComplete(rowData, apidata, "SuburbProfile");
            break;
          } else {
            const { choices } = parsedLine;
            const { delta } = choices[0];
            const { content } = delta;
            if (content) {
              apidata += content;
              setprofile((prev: any) => prev + content);
              setloading(false);
            }
          }
        }
      }
    } catch (err) {
      showSuccess(err);
    }
  };
  const GetSuburbData = async (e: any) => {
    e.preventDefault();
    setloading(true);
    CallingChatGpt();
  };
  return (
    <div>
      <Toast ref={toast} />
      {profile.length == 0 ? (
        <>
          <div style={{ display: "flex", gap: "2px", alignItems: "center" }}>
            <GradientButton
              onClick={GetSuburbData}
              icon={undefined}
              classes="text-sm"
              style={{ width: "100px", height: "36px" }}
              txt="Generate AI"
            />
            {loading == true && (
              <div className="px-1">
                <Loading height="0" />
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <AnimateHeight height={height} minheight={"52px"}>
            <button
              className="overflow-hidden text-left whitespace-normal bg-transparent border-none cursor-pointer text-ellipsis"
              onClick={() => setOpen(!open)}
            >
              {profile}
            </button>
          </AnimateHeight>
        </>
      )}
    </div>
  );
};

export default SuburbProfile;
