import React, { useState, useEffect, memo } from "react";
import { InnerContainer, PropertyHeader } from "global.style";
import { Row, Col, Table } from "react-bootstrap";
import AnimateHeight from "components/screen-components/AnimateHeight";
import { InputNumbers } from "components/ui/InputNumbers";
import { Slider } from "primereact/slider";
import { InputPercents } from "components/ui/InputPercents";
import { InfoBox } from "components/screen-components/InfoBox";
import { Dropdown } from "primereact/dropdown";
import { LiabilityType, OwnerType } from "utils/constants";
import { TCreditCard, TExpenseDetails } from "types";
import { InputTexts } from "components/ui/InputTexts";
import { ContactDropDown } from "global.style";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/store";
import { SetCalData, SaveCalData } from "store/Slices/CalculatorSlice";
import { BasiqExpenses } from "../../BasiqExpenses";
import {
  CalcuateFinanceTotalDebt,
  CalculateMonthlySavings,
} from "utils/helperFunctions";
import { CreditCardItems } from "utils/helperFunctions";
type ExpenseDetailType = {
  CreditCards: TCreditCard[];
  ExpenseDetail: TExpenseDetails;
};
export const ExpenseDetails = memo(() => {
  const dispatch = useDispatch();
  const userCaldata = useSelector((state: RootState) => state.calculator);
  const settingsdata = useSelector((state: RootState) => state.settings);
  const [open, setopen] = useState<boolean>(true);
  const [openExpenseInputs, setopenExpenseInputs] = useState(false);
  const height = open ? "auto" : 0;
  const expenseheight = openExpenseInputs ? "auto" : 0;
  const [CreditCardTableText, setCreditCardTableText] = useState<string>("");
  const [initialRender, setInitialRender] = useState<boolean>(true);
  const [showLiabilitiesPopup, setshowLiabilitiesPopup] =
    useState<boolean>(false);

  const [ExpenseDetailData, setExpenseDetailData] = useState<ExpenseDetailType>(
    {
      CreditCards:
        userCaldata?.BorrowingCalculator?.PersonalLiabilities?.CreditCards,
      ExpenseDetail: userCaldata?.BorrowingCalculator?.ExpenseDetails,
    }
  );
  const addCreditCard = () => {
    setExpenseDetailData({
      ...ExpenseDetailData,
      CreditCards: [
        ...ExpenseDetailData.CreditCards,
        {
          currentDebt: 0,
          limit: 0,
          minimumPayment: 0,
          name: "",
          owner: "",
          type: "",
        },
      ],
    });
  };
  const removeCreditCard = (index: number) => {
    const updated_list = ExpenseDetailData?.CreditCards?.filter(
      (_, idx) => idx !== index
    );
    setExpenseDetailData({
      ...ExpenseDetailData,
      CreditCards: [...updated_list],
    });
  };
  const updateCreditCard = (
    updatable_id: number,
    key: string,
    value: string | number
  ) => {
    const updated_list = ExpenseDetailData?.CreditCards?.map(
      (item: TCreditCard, idx) => {
        if (updatable_id === idx) {
          let newitem = { ...item };
          newitem = {
            ...newitem,
            [key]: value,
          };
          return newitem;
        }
        return item;
      }
    );
    setExpenseDetailData({
      ...ExpenseDetailData,
      CreditCards: [...updated_list],
    });
  };
  const AutoSave = async () => {
    let newcalbody = { ...userCaldata };
    let totaldebt = CalcuateFinanceTotalDebt(
      userCaldata.Equity.PPR,
      userCaldata.Equity.Properties,
      ExpenseDetailData.CreditCards
    );
    let avgAusVal = userCaldata.BorrowingCalculator.LivingExpenses;
    if (userCaldata.BorrowingCalculator.IncomeDetails.UserPartner) {
      avgAusVal =
        settingsdata.Assumptions.SingleBaseExpense +
        settingsdata.Assumptions.DependentBaseExpensive +
        ExpenseDetailData.ExpenseDetail.Dependants *
          settingsdata.Assumptions.DependentBaseExpensive;
    } else {
      avgAusVal =
        settingsdata.Assumptions.SingleBaseExpense +
        ExpenseDetailData.ExpenseDetail.Dependants *
          settingsdata.Assumptions.DependentBaseExpensive;
    }
    let totalExpenses = userCaldata.BorrowingCalculator.UseAverageAustralian
      ? (avgAusVal / 12) | 0
      : userCaldata.BorrowingCalculator.UseManual
      ? (userCaldata.BorrowingCalculator.ManualExpense / 12) | 0
      : userCaldata.BorrowingCalculator.BasiqExpenses | 0;
    let {
      total_card_current_debt,
      total_card_limit,
      total_card_minimum_payment,
      total_card_minimum_payment_partner,
      totalcreditCardLimit,
      totalloan,
      loan,
    } = CreditCardItems(ExpenseDetailData.CreditCards);
    newcalbody = {
      ...newcalbody,
      BorrowingCalculator: {
        ...newcalbody.BorrowingCalculator,
        LivingExpenses: avgAusVal,
        TotalExpenses: totalExpenses,
        ExpenseDetails: ExpenseDetailData.ExpenseDetail,
        PersonalLiabilities: {
          ...newcalbody.BorrowingCalculator.PersonalLiabilities,
          CreditCards: ExpenseDetailData.CreditCards,
          creditCardPartner: total_card_minimum_payment_partner,
          creditCardYours: total_card_minimum_payment,
          TotalCardDebt: total_card_current_debt,
          TotalCardLimit: total_card_limit,
          TotalCreditCardLimit: totalcreditCardLimit,
          TotalLoan: totalloan,
          Loan: loan,
        },
      },
      Equity: {
        ...newcalbody.Equity,
        TotalDebt: totaldebt,
      },
      StrategicSpending: {
        ...newcalbody.StrategicSpending,
      },
    };
    dispatch(SetCalData(newcalbody));
    SaveCalData(newcalbody);
  };
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
    } else {
      AutoSave();
    }
  }, [ExpenseDetailData]);
  return (
    <InnerContainer className="" padding="1.5rem 32px 1.8rem 32px">
      <PropertyHeader onClick={() => setopen(!open)}>
        <h1 className="card-title">Expense Details</h1>
        {open ? (
          <div className="mb-[8px]">
            <i className="pi pi-angle-up" onClick={() => setopen(!open)}></i>
          </div>
        ) : (
          <div className="mb-[8px]">
            <i className="pi pi-angle-down" onClick={() => setopen(!open)}></i>
          </div>
        )}
      </PropertyHeader>
      <AnimateHeight
        height={height}
        classes={`${open && "!overflow-visible"} `}
      >
        <Row>
          <Col xs={4}>
            <div className="!mt-[20px]">
              <p className="mb-[5px] input-label">Dependants</p>
              <div className="flex items-center gap-3">
                <div className="ml-[8px]">
                  <Slider
                    value={ExpenseDetailData.ExpenseDetail.Dependants}
                    onChange={(e) => {
                      setExpenseDetailData({
                        ...ExpenseDetailData,
                        ExpenseDetail: {
                          ...ExpenseDetailData.ExpenseDetail,
                          Dependants: Number(e.value),
                        },
                      });
                    }}
                    className={"slider-field"}
                    step={1}
                    min={0}
                    max={10}
                  />
                </div>
                <InputPercents
                  onChange={(e) => {
                    setExpenseDetailData({
                      ...ExpenseDetailData,
                      ExpenseDetail: {
                        ...ExpenseDetailData.ExpenseDetail,
                        Dependants: Number(e.value),
                      },
                    });
                  }}
                  value={ExpenseDetailData.ExpenseDetail.Dependants}
                  inputClassName={"!text-center !w-[60px] !h-[40px]"}
                  suffix=""
                  minFractionDigits={0}
                />
              </div>
            </div>
          </Col>
        </Row>
        <div className="flex items-center relative gap-[5px] mt-[20px]">
          <h1 className="card-title">Personal Liabilities</h1>
          <i
            onMouseLeave={() => setshowLiabilitiesPopup(false)}
            onMouseEnter={() => setshowLiabilitiesPopup(true)}
            className="ml-2 pi pi-info-circle text-[0.7rem] cursor-pointer"
          ></i>
          <InfoBox
            onMouseLeave={() => setshowLiabilitiesPopup(false)}
            style={{ width: "320px", left: 20, top: 30 }}
            show={showLiabilitiesPopup}
          >
            <p>
              Expense Details and Default Values <br />
              <br />
              Default annual expense allowance - The calculator assumes that the
              borrower has a minimum set of expenses as defined below: <br />
              <br />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "30px" }}>Number of dependants</p>
                <p style={{ marginRight: "30px" }}>Single</p>
                <p>Joint</p>
              </div>
              <div>
                <div className="flex">
                  <p style={{ marginRight: "152px" }}>0</p>
                  <p style={{ marginRight: "20px" }}>$17,940</p>
                  <p>$26,040</p>
                </div>
                <div className="flex">
                  <p style={{ marginRight: "153px" }}>1</p>
                  <p style={{ marginRight: "18px" }}>$24,300</p>
                  <p>$32,400</p>
                </div>
                <div className="flex">
                  <p style={{ marginRight: "152px" }}>2</p>
                  <p style={{ marginRight: "17px" }}>$30,660</p>
                  <p>$38,760</p>
                </div>
                <div className="flex">
                  <p style={{ marginRight: "152px" }}>3</p>
                  <p style={{ marginRight: "19px" }}>$37,020</p>
                  <p>$45,120</p>
                </div>
                <div className="flex">
                  <p style={{ marginRight: "152px" }}>4</p>
                  <p style={{ marginRight: "17px" }}>$43,380</p>
                  <p>$51,480</p>
                </div>
              </div>
              <br />
              Default extra core expenses for more than 4 dependants - currently
              set four dependents plus an additional $6,360 for each further
              dependent. <br /> <br />
              The default assumptions detailed below are considered reasonable
              for working out a user's estimate on the basis that an estimate of
              basic expenses is applied, based on a national average of the
              Household Expenditure Measure (HEM). This estimate takes into
              account factors such as the application type (single or joint) and
              the number of dependents that the applicant(s) support. This
              amount is used as guidance only in helping you assess your
              potential borrowing capacity. Your actual expenses may differ from
              this approximation.
            </p>
          </InfoBox>
        </div>
        <Row className="!mt-[10px]">
          <Col sm={12} xs={12} lg={12} md={10} className="pr-5">
            {ExpenseDetailData?.CreditCards?.length > 0 ? (
              <>
                <Table bordered className="creditCardTable creditCardTable1">
                  <thead>
                    <tr>
                      <th>
                        <p className="input-label">Type</p>
                      </th>
                      <th>
                        <p className="input-label">Owner</p>
                      </th>
                      <th>
                        <p className="input-label">Bank Provider</p>
                      </th>
                      <th>
                        <p className="input-label">Limit</p>
                      </th>
                      <th>
                        <p className="input-label">Current Debt</p>
                      </th>
                      <th>
                        <p className="input-label">Minimum Payment(Monthly)</p>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {ExpenseDetailData.CreditCards?.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td className="py-1">
                            <div className="d-flex align-items-center justify-content-between">
                              <ContactDropDown
                                value={item.type}
                                className="!border-[0px]"
                                onChange={(e) => {
                                  updateCreditCard(idx, "type", e.value);
                                }}
                                options={LiabilityType}
                              />
                            </div>
                          </td>
                          <td className="py-1">
                            <div className="d-flex align-items-center justify-content-between">
                              <ContactDropDown
                                value={item.owner}
                                className="!border-[0px]"
                                onChange={(e) => {
                                  updateCreditCard(idx, "owner", e.value);
                                }}
                                options={OwnerType}
                              />
                            </div>
                          </td>
                          <td className="py-1">
                            <InputTexts
                              name=""
                              value={item?.name}
                              onChange={(e) => {
                                updateCreditCard(idx, "name", e.target.value);
                              }}
                              classname="!border-[0px] !w-[80px]"
                            />
                          </td>
                          <td className="py-1">
                            <InputNumbers
                              value={item.limit}
                              onChange={(e) => {
                                updateCreditCard(idx, "limit", Number(e.value));
                              }}
                              inputClassName="!border-[0px] !w-[100px]"
                            />
                          </td>
                          <td className="py-1">
                            <InputNumbers
                              value={item?.currentDebt}
                              onChange={(e) => {
                                updateCreditCard(
                                  idx,
                                  "currentDebt",
                                  Number(e.value)
                                );
                              }}
                              inputClassName="!border-[0px] !w-[100px]"
                            />
                          </td>
                          <td className="py-1">
                            <InputNumbers
                              value={item?.minimumPayment}
                              onChange={(e) => {
                                updateCreditCard(
                                  idx,
                                  "minimumPayment",
                                  Number(e.value)
                                );
                              }}
                              inputClassName="!border-[0px] !w-[100px]"
                            />
                          </td>
                          <td>
                            <span
                              onClick={() => {
                                removeCreditCard(idx);
                              }}
                              style={{
                                fontSize: "18px",
                                cursor: "pointer",
                                color: "#323232",
                                padding: "0px 2px",
                              }}
                            >
                              x
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                    <tr className="creditcardTablelastrow">
                      <td
                        className="text-center cursor-pointer text-[14px] "
                        onClick={addCreditCard}
                        colSpan={7}
                      >
                        Add New Liabilities
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </>
            ) : (
              <>
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <p className="input-label">Type</p>
                      </th>
                      <th>
                        <p className="input-label">Owner</p>
                      </th>
                      <th>
                        <p className="input-label">Bank Provider</p>
                      </th>
                      <th>
                        <p className="input-label">Limit</p>
                      </th>
                      <th>
                        <p className="input-label">Current Debt</p>
                      </th>
                      <th>
                        <p className="input-label">Minimum Payment (Monthly)</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      onMouseEnter={() =>
                        setCreditCardTableText("Add New Liabilities")
                      }
                      onMouseLeave={() =>
                        setCreditCardTableText("Add New Liabilities")
                      }
                    >
                      <td
                        className="py-3 text-center cursor-pointer"
                        onClick={addCreditCard}
                        colSpan={6}
                      >
                        {CreditCardTableText}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </>
            )}
          </Col>
        </Row>
        <h1
          onClick={() => setopenExpenseInputs(!openExpenseInputs)}
          className="card-title !text-[18px] !underline"
        >
          Show Expenses
        </h1>
        <div className="mt-[20px] ">
          <BasiqExpenses expenseheight={expenseheight} />
        </div>
      </AnimateHeight>
    </InnerContainer>
  );
});
