import React, { useState, useEffect, memo } from "react";
import { Row, Col } from "react-bootstrap";
import { InputNumbers } from "components/ui/InputNumbers";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/store";
import { InnerContainer } from "global.style";
import { SetCalData, SaveCalData } from "store/Slices/CalculatorSlice";
import GoalAnimation from "assets/animations/goalAnimeAnimation.gif";
export const TopGradient = memo(() => {
  const userCalData = useSelector((state: RootState) => state.calculator);
  const [income_goal, setincome_goal] = useState<number>(
    userCalData.BorrowingCalculator.income_goal
  );
  const [initialRender, setinitialRender] = useState<boolean>(true);
  const dispatch = useDispatch();
  const AutoSave = async () => {
    let newcaldata = { ...userCalData };
    newcaldata = {
      ...newcaldata,
      BorrowingCalculator: {
        ...newcaldata.BorrowingCalculator,
        income_goal: income_goal,
      },
    };
    dispatch(SetCalData(newcaldata));
    SaveCalData(newcaldata);
  };
  useEffect(() => {
    if (initialRender) {
      setinitialRender(false);
    } else {
      AutoSave();
    }
  }, [income_goal]);

  return (
    <InnerContainer className="card-gredient min-h-[230px]">
      <Row>
        <Col className="" sm={12} xs={12} lg={7} md={12}>
          <h1 className="mb-4 !mt-[30px] !text-[white] !text-[24px]">
            Income Goal
          </h1>
          <div>
            <div className="w-[320px] ">
              <p className="!text-[white] text-[14px]">Desired Cash Flows </p>
              <InputNumbers
                value={userCalData.BorrowingCalculator.income_goal}
                onChange={(e) => {
                  setincome_goal(Number(e.value));
                }}
                inputClassName={
                  "input-field !mt-[6px] !bg-transparent !text-[white]"
                }
              />
            </div>
          </div>
        </Col>

        <Col sm={12} xs={12} lg={5} md={12}>
          {userCalData?.BorrowingCalculator?.income_goal > 0 &&
            Number(
              userCalData?.PropertyGenerators[
                userCalData.PropertyGenerators.length - 1
              ]?.nextDate?.split("-")[1]
            ) > 0 && (
              <div className="flex items-center justify-end">
                <img src={GoalAnimation} alt="Animation" />
              </div>
            )}
        </Col>
      </Row>
    </InnerContainer>
  );
});
