import React, { useState,memo,useEffect } from 'react'
import { InnerContainer } from 'global.style'
import { Row,Col } from 'react-bootstrap'
import { InputNumbers } from 'components/ui/InputNumbers'
import { useSelector ,useDispatch} from 'react-redux'
import { RootState } from 'store/store'
import { SetCalData,SaveCalData } from 'store/Slices/CalculatorSlice'
import { InfoBox } from 'components/screen-components/InfoBox'
import { TotalUseableEquity } from 'utils/helperFunctions'
export const EquityCash = memo(() => {
    const userCalData = useSelector((state:RootState)=>state.calculator)
    const dispatch = useDispatch();
    const [initialRender,setinitialRender] = useState<boolean>(true)
    const[EquityCashdata,setEquityCashData]=useState<{Cash:number,LiquidAssets:number}>({
        Cash:userCalData.Equity.Cash,
        LiquidAssets:userCalData.Equity.LiquidAssets
    })
    const [Hover, setHover] = useState<boolean>(false);
const AutoSave = async()=>{
    let newcaldata={...userCalData}
    newcaldata={
        ...newcaldata,
       Equity:{
        ...newcaldata.Equity,
        Cash:EquityCashdata.Cash,
        LiquidAssets:EquityCashdata.LiquidAssets,
        UsableEquityAndCash:EquityCashdata.Cash+TotalUseableEquity(userCalData.Equity.PPR,userCalData.Equity.Properties)
       }
    }
    dispatch(SetCalData(newcaldata))
    SaveCalData(newcaldata)
}
    useEffect(()=>{
        if(initialRender){
            setinitialRender(false)
        }else{
          
            AutoSave();
        }
    },[EquityCashdata])
  return (
    <InnerContainer className='' padding='1.5rem 32px 1.8rem 32px' >
        <Row>
            <Col>
            <div >
          <div className='w-[320px] '>
            <p className="!text-[var(--blackColor)] !font-[500] text-[14px]">Cash Available </p>
            <InputNumbers
              value={userCalData.Equity.Cash}
              onChange={(e) => {
                setEquityCashData({...EquityCashdata,Cash:Number(e.value)})
              }}
              inputClassName={"input-field !mt-[4px]  !bg-transparent "}
            />
          </div> 
         
        </div>
            </Col>
            <Col>
            <div >
          <div className='w-[320px] relative'>
            <p className="flex items-center text-[var(--blackColor)] !font-[500] text-[14px]">
            <span>Liquid Assets </span>

<i
              onMouseOver={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              className="ml-2 cursor-pointer pi pi-info-circle !text-[0.7rem]"
            ></i>
</p>
<InfoBox
            className={`infobox ${Hover}`}
            onMouseLeave={() => setHover(false)}
            style={{ width: "320px", left: 0, position: "absolute" }} //310
            show={Hover}
          >
            <p>
              Your lifestyle goal is the annual net income you aim to generate
              from your portfolio to sustain your lifestyle.
            </p>
          </InfoBox>
            <InputNumbers
              value={userCalData.Equity.LiquidAssets}
              onChange={(e) => {
                setEquityCashData({...EquityCashdata,LiquidAssets:Number(e.value)})
              }}
              inputClassName={"input-field !mt-[4px] !bg-transparent "}
            />
          </div>
         
        </div>
            </Col>
        </Row>
    </InnerContainer>
  )
})
