import React,{useState,useEffect} from 'react'
import classes from "stylesheet.module.css"
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { Image } from 'react-bootstrap';
import OpenImg from 'assets/images/Expand_down1.svg'
import CloseImg from 'assets/images/Expand_down2.svg'
import Tyson from 'assets/tyson.png';
import AnimateHeight from 'components/screen-components/AnimateHeight';
// @ts-ignore
import ReactPlayer from "react-player";
export const VideoCard = () => {
    const userCaldata = useSelector((state: RootState) => state.calculator);
    const [open, setOpen] = useState<boolean>(true);
    const height = open ? "auto" : 0;

  return (
    <div className={classes.VideoCardContainer}>
        <div className={classes.VideoContent}>
        <div className="flex justify-center">
          <h5>Deciding your Risk Profile?</h5>
        </div>
        <div className="flex justify-center">
          <h5
            style={{
              color:
              userCaldata.RiskProfile.PropertyMode === "Aggressive"
                  ? "#cd4a5e"
                  : userCaldata.RiskProfile.PropertyMode === "Moderate"
                  ? "#fee468"
                  : userCaldata.RiskProfile.PropertyMode === "Conservative"
                  ? "#00a779"
                  : "#00a779",
              fontSize: "15px",
            }}
          >
            {userCaldata.RiskProfile.PropertyMode} - {userCaldata.RiskProfile.NetYieldonProperty}% Return
          </h5>
        </div>
        <div className="Video-Content-header">
          <Image
            src={open === true ? OpenImg : CloseImg}
            onClick={() => setOpen(!open)}
          />
        </div>
        <AnimateHeight  height={height}>
        <div>
            <ReactPlayer
              width="100%"
              height="155px"
              controls={true}
              url="https://propvesting-xm.s3.us-east-2.amazonaws.com/videos/IL+property+Portfolio-1.mp4"
              light={Tyson}
              title="Risk Profiling"
              playing
            ></ReactPlayer>
          </div>
        </AnimateHeight>
        </div>
        </div>
  )
}
