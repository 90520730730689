import React from 'react'
import { ButtonGradient } from '../../../../global.style'
type GradientButtonProps = {
    txt?:string,
    onClick?:(event: React.MouseEvent<HTMLButtonElement>) => void,
    style?:Object,
    classes?:string,
    icon?:JSX.Element | undefined,
    width?:string,
    disabled?:boolean
}
export const GradientButton = ({txt="Next", onClick, style = {},classes='',icon,width,disabled=false }:GradientButtonProps) => {
  return (
    <ButtonGradient txt={txt} width={width} className={classes} style={style} onClick={onClick} disabled={disabled}>
        {icon!==undefined && icon}
        {txt === undefined ? "Next" : txt}
        
        </ButtonGradient>
  )
}
