import React, { useState, useEffect } from 'react'
import { InnerContainer, Container } from 'global.style'
import { Slider } from "primereact/slider";
import { Row, Col } from 'react-bootstrap';
import { InputNumbers } from 'components/ui/InputNumbers';
import { TSliders, TUpdateSetting, UpdateSettingsData, GetSettingsData } from 'store/Slices/SettingsSlice';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/store';
import { CurrencyFormatter } from 'utils/helperFunctions';
export const Sliders = () => {
  const sliders = useSelector((state: RootState) => state.settings.Sliders)
  const [SettingSlider, setSettingSlider] = useState<TSliders>(sliders)
  const [initialRender, setinitialRender] = useState<boolean>(true)
  const dispatch = useDispatch();

  const UpdateSliders = async () => {
    let body: TUpdateSetting = {
      name: "Slider Settings",
      slug: "slider-settings",
      settings: {
        ...SettingSlider,
      },
      office_id: process.env.REACT_APP_OFFICEID as string,
    }
    let response = await UpdateSettingsData(body)
    if (response.status) {
      let officeid = process.env.REACT_APP_OFFICEID as string
      dispatch(GetSettingsData(officeid) as any)

    }
  }
  useEffect(() => {
    if (!initialRender) {
      setTimeout(() => {
        UpdateSliders()
      }, 2000)

    } else {
      setinitialRender(false)
    }
  }, [SettingSlider])  // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Container>
      <InnerContainer padding='1.5rem 32px 2.5rem 32px'>
        <div className="profile-container">
          <h1>Sliders</h1>
        </div>
        <div className='flex flex-col gap-[2rem]'>
          <Row>
            <Col sm={12} xs={12} md={12} lg={4}>
              <div className='flex flex-col'>
                <p className="mb-[5px] input-label">Car Loan Payments</p>
                <div className="flex items-center gap-3">
                  <div >
                    <Slider
                      value={SettingSlider.CarLoanSlider}
                      onChange={(e) => {
                        setSettingSlider({ ...SettingSlider, CarLoanSlider: Number(e.value) })
                      }}
                      className={"slider-field"}
                      step={1}
                      min={0}
                      max={100000}
                    />
                  </div>
                  <InputNumbers
                    onChange={(e) => {
                      setSettingSlider({ ...SettingSlider, CarLoanSlider: Number(e.value) })
                    }}
                    value={SettingSlider.CarLoanSlider}
                    inputClassName={"!text-center !w-[98px]"}
                  />
                </div>
                <span className='text-left text-[13px]'>{CurrencyFormatter.format(SettingSlider.CarLoanSlider)}</span>
              </div>
            </Col>
            <Col sm={12} xs={12} md={12} lg={4}>
              <div className='flex flex-col'>
                <p className="mb-[5px] input-label">Credit Card Limit</p>
                <div className="flex items-center gap-3">
                  <div >
                    <Slider
                      value={SettingSlider.CreditCardLimitSlider}
                      onChange={(e) => {
                        setSettingSlider({ ...SettingSlider, CreditCardLimitSlider: Number(e.value) })
                      }}
                      className={"slider-field"}
                      step={1}
                      min={0}
                      max={100000}
                    />


                  </div>
                  <InputNumbers
                    onChange={(e) => {
                      setSettingSlider({ ...SettingSlider, CreditCardLimitSlider: Number(e.value) })
                    }}
                    value={SettingSlider.CreditCardLimitSlider}
                    inputClassName={"!text-center !w-[98px]"}
                  />
                </div>
                <span className='text-left text-[13px]'>{CurrencyFormatter.format(SettingSlider.CreditCardLimitSlider)}</span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} xs={12} md={12} lg={4}>
              <div className='flex flex-col'>
                <p className="mb-[5px] input-label">Current Credit Card Debt</p>
                <div className="flex items-center gap-3">
                  <div >
                    <Slider
                      value={SettingSlider.CurrentCreditCardDebtSlider}
                      onChange={(e) => {
                        setSettingSlider({ ...SettingSlider, CurrentCreditCardDebtSlider: Number(e.value) })
                      }}
                      className={"slider-field"}
                      step={1}
                      min={0}
                      max={100}
                    />
                  </div>
                  <InputNumbers
                    onChange={(e) => {
                      setSettingSlider({ ...SettingSlider, CurrentCreditCardDebtSlider: Number(e.value) })
                    }}
                    value={SettingSlider.CurrentCreditCardDebtSlider}
                    inputClassName={"!text-center !w-[98px]"}
                  />
                </div>
                <span className='text-left text-[13px]'>{CurrencyFormatter.format(SettingSlider.CurrentCreditCardDebtSlider)}</span>
              </div>
            </Col>
            <Col sm={12} xs={12} md={12} lg={4}>
              <div className='flex flex-col'>
                <p className="mb-[5px] input-label">Annual Expenses</p>
                <div className="flex items-center gap-3">
                  <div >
                    <Slider
                      value={SettingSlider.AnnualExpensesSlider}
                      onChange={(e) => {
                        setSettingSlider({ ...SettingSlider, AnnualExpensesSlider: Number(e.value) })
                      }}
                      className={"slider-field"}
                      step={1}
                      min={0}
                      max={100}
                    />


                  </div>
                  <InputNumbers
                    onChange={(e) => {
                      setSettingSlider({ ...SettingSlider, AnnualExpensesSlider: Number(e.value) })
                    }}
                    value={SettingSlider.AnnualExpensesSlider}
                    inputClassName={"!text-center !w-[98px]"}
                  />
                </div>
                <span className='text-left text-[13px]'>{CurrencyFormatter.format(SettingSlider.AnnualExpensesSlider)}</span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} xs={12} md={12} lg={4}>
              <div className='flex flex-col'>
                <p className="mb-[5px] input-label">Single Base Expense</p>
                <div className="flex items-center gap-3">
                  <div >
                    <Slider
                      value={SettingSlider.SingleBaseExpenseSlider}
                      onChange={(e) => {
                        setSettingSlider({ ...SettingSlider, SingleBaseExpenseSlider: Number(e.value) })
                      }}
                      className={"slider-field"}
                      step={1}
                      min={0}
                      max={100}
                    />


                  </div>
                  <InputNumbers
                    onChange={(e) => {
                      setSettingSlider({ ...SettingSlider, SingleBaseExpenseSlider: Number(e.value) })
                    }}
                    value={SettingSlider.SingleBaseExpenseSlider}
                    inputClassName={"!text-center !w-[98px]"}
                  />
                </div>
                <span className='text-left text-[13px]'>{CurrencyFormatter.format(SettingSlider.SingleBaseExpenseSlider)}</span>
              </div>
            </Col>
            <Col sm={12} xs={12} md={12} lg={4}>
              <div className='flex flex-col'>
                <p className="mb-[5px] input-label">Dependent Base Expensive</p>
                <div className="flex items-center gap-3">
                  <div >
                    <Slider
                      value={SettingSlider.DependentBaseExpensiveSlider}
                      onChange={(e) => {
                        setSettingSlider({ ...SettingSlider, DependentBaseExpensiveSlider: Number(e.value) })
                      }}
                      className={"slider-field"}
                      step={1}
                      min={0}
                      max={100}
                    />


                  </div>
                  <InputNumbers
                    onChange={(e) => {
                      setSettingSlider({ ...SettingSlider, DependentBaseExpensiveSlider: Number(e.value) })
                    }}
                    value={SettingSlider.DependentBaseExpensiveSlider}
                    inputClassName={"!text-center !w-[98px]"}
                  />
                </div>
                <span className='text-left text-[13px]'>{CurrencyFormatter.format(SettingSlider.DependentBaseExpensiveSlider)}</span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} xs={12} md={12} lg={4}>
              <div className='flex flex-col'>
                <p className="mb-[5px] input-label">Home Loan/Rental Payments</p>
                <div className="flex items-center gap-3">
                  <div >
                    <Slider
                      value={SettingSlider.HomeLoanRentalPaymentsSlider}
                      onChange={(e) => {
                        setSettingSlider({ ...SettingSlider, HomeLoanRentalPaymentsSlider: Number(e.value) })
                      }}
                      className={"slider-field"}
                      step={1}
                      min={0}
                      max={6}
                    />


                  </div>
                  <InputNumbers
                    onChange={(e) => {
                      setSettingSlider({ ...SettingSlider, HomeLoanRentalPaymentsSlider: Number(e.value) })
                    }}
                    value={SettingSlider.HomeLoanRentalPaymentsSlider}
                    inputClassName={"!text-center !w-[98px]"}
                  />
                </div>
                <span className='text-left text-[13px]'>{CurrencyFormatter.format(SettingSlider.HomeLoanRentalPaymentsSlider)}</span>
              </div>
            </Col>
            <Col sm={12} xs={12} md={12} lg={4}>
              <div className='flex flex-col'>
                <p className="mb-[5px] input-label">Other Payments</p>
                <div className="flex items-center gap-3">
                  <div >
                    <Slider
                      value={SettingSlider.OtherPaymentsSlider}
                      onChange={(e) => {
                        setSettingSlider({ ...SettingSlider, OtherPaymentsSlider: Number(e.value) })
                      }}
                      className={"slider-field"}
                      step={1}
                      min={0}
                      max={100}
                    />


                  </div>
                  <InputNumbers
                    onChange={(e) => {
                      setSettingSlider({ ...SettingSlider, OtherPaymentsSlider: Number(e.value) })
                    }}
                    value={SettingSlider.OtherPaymentsSlider}
                    inputClassName={"!text-center !w-[98px]"}
                  />
                </div>
                <span className='text-left text-[13px]'>{CurrencyFormatter.format(SettingSlider.OtherPaymentsSlider)}</span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} xs={12} md={12} lg={4}>
              <div className='flex flex-col'>
                <p className="mb-[5px] input-label">Mortgage Payments</p>
                <div className="flex items-center gap-3">
                  <div >
                    <Slider
                      value={SettingSlider.MortgagePaymentsSlider}
                      onChange={(e) => {
                        setSettingSlider({ ...SettingSlider, MortgagePaymentsSlider: Number(e.value) })
                      }}
                      className={"slider-field"}
                      step={1}
                      min={0}
                      max={10}
                    />


                  </div>
                  <InputNumbers
                    onChange={(e) => {
                      setSettingSlider({ ...SettingSlider, MortgagePaymentsSlider: Number(e.value) })
                    }}
                    value={SettingSlider.MortgagePaymentsSlider}
                    inputClassName={"!text-center !w-[98px]"}
                  />
                </div>
                <span className='text-left text-[13px]'>{CurrencyFormatter.format(SettingSlider.MortgagePaymentsSlider)}</span>
              </div>
            </Col>
            <Col sm={12} xs={12} md={12} lg={4}>
              <div className='flex flex-col'>
                <p className="mb-[5px] input-label">Ready Gross Weekly Rental Received</p>
                <div className="flex items-center gap-3">
                  <div >
                    <Slider
                      value={SettingSlider.ReadyGrossWeeklyRentalReceivedSlider}
                      onChange={(e) => {
                        setSettingSlider({ ...SettingSlider, ReadyGrossWeeklyRentalReceivedSlider: Number(e.value) })
                      }}
                      className={"slider-field"}
                      step={1}
                      min={0}
                      max={10}
                    />


                  </div>
                  <InputNumbers
                    onChange={(e) => {
                      setSettingSlider({ ...SettingSlider, ReadyGrossWeeklyRentalReceivedSlider: Number(e.value) })
                    }}
                    value={SettingSlider.ReadyGrossWeeklyRentalReceivedSlider}
                    inputClassName={"!text-center !w-[98px]"}
                  />
                </div>
                <span className='text-left text-[13px]'>{CurrencyFormatter.format(SettingSlider.ReadyGrossWeeklyRentalReceivedSlider)}</span>
              </div>
            </Col>
          </Row>

        </div>
      </InnerContainer>
    </Container>
  )
}
