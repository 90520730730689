import React from "react";
import { PropertyCard } from "components/screen-components/PropertyCard";
import { Row,Col } from "react-bootstrap";
import { IncomeCard } from "../IncomeCard";
import Pic1 from "assets/empty.svg";
const IncomePropertyLists = ({ data }: { data: any }) => {
  return (
    <>
    <Row>
      {data?.map((item: any,index:any) => {
       
        return (
            <React.Fragment key={index}>
                <Col
                md={6}
                lg={6}
                xl={6}
                xxl={4}
                style={{ marginBottom: "20px" }}
              >
                <IncomeCard
            img={item?.listingData ? item?.listingData?.image : Pic1}
            number={index+1}
            propertyProps={item}
            admin={true}
            
          />
              </Col>
          
          </React.Fragment>
        );
      })}
      </Row>
    </>
  );
};

export default IncomePropertyLists;
