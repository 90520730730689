import React, { memo } from 'react';

type AnimateHeightProps = {
    children:any,
    height:number | string,
    minheight?:any,
    classes?:string
}
const AnimateHeight = ({ children,height,minheight,classes}:AnimateHeightProps) => {
 
  return <div className={`section ${classes} ${height!==0 ?'open':'close'} `} style={minheight &&{minHeight:minheight}}>{children}</div>;
};

export default memo(AnimateHeight);