import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import url from "../../config";
export type TAssumptions = {
  SingleBaseExpense: number;
  DependentBaseExpensive: number;
  ServiceIncomeFactors: number;
  DefaultBPInterestRate: number;
  InterestRateBuffer: number;
  CPI: number;
  DTI: number;
  DSR: number;
  InterestRate: number;
  DebtInterestRate: number;
  DebtPrincipalRate: number;
  EquityInterestRate: number;
  EquityPrincipalRate: number;
};
export type TSliders = {
  AnnualExpensesSlider: number;
  CarLoanSlider: number;
  CreditCardLimitSlider: number;
  CurrentCreditCardDebtSlider: number;
  DependentBaseExpensiveSlider: number;
  HomeLoanRentalPaymentsSlider: number;
  MortgagePaymentsSlider: number;
  OtherPaymentsSlider: number;
  ReadyGrossWeeklyRentalReceivedSlider: number;
  SingleBaseExpenseSlider: number;
};
export type TUpdateSetting = {
  name: string;
  slug: string;
  settings: TAssumptions | TSliders;
  office_id: string;
};
export type TSettings = {
  Assumptions: TAssumptions;
  Sliders: TSliders;
};
export const GetSettingsData = createAsyncThunk(
  "",
  async (officeid: string) => {
    try {
      let response = await url.get(`settings?office_id=${officeid}`);
      return response.data;
    } catch (err) {
      return err;
    }
  }
);
export const UpdateSettingsData = async (body: TUpdateSetting) => {
  try {
    let response = await url.post(`settings`, body);
    return response.data;
  } catch (err) {
    return err;
  }
};

const initialState: TSettings = {
  Assumptions: {
    SingleBaseExpense: 0,
    DependentBaseExpensive: 0,
    ServiceIncomeFactors: 0,
    DefaultBPInterestRate: 0,
    InterestRateBuffer: 0,
    CPI: 0,
    DTI: 0,
    DSR: 0,
    InterestRate: 0,
    DebtInterestRate: 0,
    DebtPrincipalRate: 0,
    EquityInterestRate: 0,
    EquityPrincipalRate: 0,
  },
  Sliders: {
    AnnualExpensesSlider: 0,
    CarLoanSlider: 0,
    CreditCardLimitSlider: 0,
    CurrentCreditCardDebtSlider: 0,
    DependentBaseExpensiveSlider: 0,
    HomeLoanRentalPaymentsSlider: 0,
    MortgagePaymentsSlider: 0,
    OtherPaymentsSlider: 0,
    ReadyGrossWeeklyRentalReceivedSlider: 0,
    SingleBaseExpenseSlider: 0,
  },
};
const SettingSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetSettingsData.fulfilled, (state, action) => {
      state.Assumptions = action?.payload?.data[0]?.settings;
      state.Sliders = action?.payload?.data[1]?.settings;
    });
  },
});
// export const {} = SettingSlice.actions;
export default SettingSlice.reducer;
