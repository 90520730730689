import { createSlice } from "@reduxjs/toolkit";


type ThemeData = {
  currentTheme:string
};

const initialState: ThemeData = {
    currentTheme:"Propvesting"
};
const ThemeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {},
  extraReducers(builder) {},
});
export default ThemeSlice.reducer;
