import React from 'react'

export const LaunchingSoon = () => {
  return (
    <div className='LaunchingCard'>
      <h1> </h1>
     <div className='LaunchingCard-content-container'>
     <div className='LaunchingCard-content'>
          <p>
            <div></div> Coming Soon
          </p>
          <h1>
            We Are <br /> Launching Soon
          </h1>
        </div>
     </div>
      </div>
  )
}
