import React, { useState } from "react";
import { Image } from "react-bootstrap";
import AnimateHeight from "../AnimateHeight";
import OpenImg from 'assets/images/Expand_down1.svg'
import CloseImg from 'assets/images/Expand_down2.svg'
import { PropertyBox,PropertyContent } from "global.style";
import { TPropertyCard,PropertyProps } from "types";
import { commaStringSeparatorsWithDollors } from "utils/helperFunctions";
type PropertyCardProps = {
  img: string;
  number: number;
  propertyProps:PropertyProps;
  admin?:boolean
};
export const PropertyCard = ({ img, number,propertyProps,admin=false }: PropertyCardProps) => {
  const pathname = window.location.href.includes("IncomePlan")
    ? "IncomePlan"
    : "Profile";
  const [open, setOpen] = useState(true);
  const height = open ? "auto" : 0;
  return (
    <div className="box-border relative flex flex-col w-full">
      <Image
        className="w-full h-[324.9px] rounded-[8px] object-cover"
        src={img}
      />
      <PropertyBox>
        <span>Property {number}</span>
        <PropertyContent
          pathname={pathname}
          admin={admin}
          idx={number}
          style={{ marginTop: "-10px" }}
        >
          <div className="Property-Content-header">
            <Image
              src={open === true ? OpenImg : CloseImg}
              onClick={() => setOpen(!open)}
            />
          </div>
          <AnimateHeight height={height}>
            <div className="Propery-body">
              <div className="column">
                <div className="column-content">
                  <p>Property Type</p>
                  <h1>{propertyProps.propertyType}</h1>
                </div>

                <div className="column-content">
                  <p>LVR</p>
                  <h1>{propertyProps.LVR}.00%</h1>
                </div>
                <div className="column-content">
                  <p>
                    {propertyProps.propertyType === "Residential" ? "Net Yield" : "Net Yield"}
                  </p>
                  <h1>
                    {(
                      (Number(propertyProps.netrental) /
                        Number(propertyProps.PurchasePrice)) *
                        100 || 0
                    ).toFixed(2)}
                    %
                  </h1>
                </div>
              </div>
              <div className="column">
                <div className="column-content">
                  <p>Purchase Price</p>
                  <h1>{commaStringSeparatorsWithDollors(propertyProps.PurchasePrice)}</h1>
                </div>
                <div className="column-content">
                  <p>Interest Rate</p>
                  <h1>{propertyProps.InterestRate}%</h1>
                </div>
                <div className="column-content">
                  <p> Cashflow Annual</p>
                  <h1>{commaStringSeparatorsWithDollors(propertyProps.NetCashflow)}</h1>
                </div>
              </div>
              <div className="column">
                <div className="column-content">
                  <p>Deposit Required</p>
                  <h1>
                  {commaStringSeparatorsWithDollors(propertyProps.Deposit)}
                    </h1>
                </div>

                <div className="column-content">
                  <p>Net Rental Income</p>
                  <h1>{commaStringSeparatorsWithDollors(propertyProps.GrossRentalIncome)}</h1>
                </div>
                <div className="column-content">
                  <p>Risk Profile</p>
                  <h1 style={{ color: propertyProps.progressColor }}>
                    {propertyProps.RiskProfile == 5
                      ? "Conservative"
                      : propertyProps.RiskProfile == 6
                      ? "Moderate"
                      : "Aggressive"}
                  </h1>
                </div>
              </div>
            </div>
          </AnimateHeight>
        </PropertyContent>
      </PropertyBox>
    </div>
  );
};
