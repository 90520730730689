import React from "react";
import { InnerContainer} from "global.style";

import { RiskSlider } from "../RiskSlider";
export const RiskProfile = () => {
  return (
    <InnerContainer padding="1rem 30px 0.7rem 30px" height="288px">
      <div className="profile-container">
        <h1>Investor Risk Profile</h1>
      </div>
      <RiskSlider/>
      
    </InnerContainer>
  );
};
