import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { InputTexts, MySlider, InputNumbers } from "global.style";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { RadioButton } from "primereact/radiobutton";
import { GradientButton } from "components/ui/Buttons/GradientButton";
import { Loading } from "components/ui/Loading";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { TPropertyItem } from "types";
import { TPropertyGeneratorItem } from "types";
import {
  commaStringSeparatorsWithDollors,
  GetRegionData,
  CalculateFormulasFromPurchasePrice,
  getLMI,
  TotalInvestmentPropertiesGrossIncome,
  CalculatePropertiesCashFlowAnnual,
  CalculateTotalLiabilitiesAndExpenses,
} from "utils/helperFunctions";
import { useDispatch } from "react-redux";
import { SetCalData, SaveCalData } from "store/Slices/CalculatorSlice";
import { CheckDepositCovered } from "utils/helperFunctions";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { calculateMaxBorrowingCapacity } from "utils/helperFunctions";
const PropertyDetail = () => {
  const { propertyid } = useParams();
  const dispatch = useDispatch();
  const propertyIdNumber = Number(propertyid) - 1;
  let PropertiesData: TPropertyGeneratorItem[] = useSelector(
    (state: RootState) => state.calculator.PropertyGenerators
  );
  const CalData = useSelector((state: RootState) => state.calculator);
  const SettingsData = useSelector((state: RootState) => state.settings);
  const [initialRender, setinitialRender] = useState<boolean>(true);
  const [propertyData, setpropertyData] = useState<TPropertyItem>({
    percentageCash: PropertiesData[propertyIdNumber].summaryData.percentageCash,
    percentageEquity:
      PropertiesData[propertyIdNumber].summaryData.percentageEquity,
    percentageLiquidAssets:
      PropertiesData[propertyIdNumber].summaryData.percentageLiquidAssets,
    useCash: PropertiesData[propertyIdNumber].summaryData.useCash,
    useEquity: PropertiesData[propertyIdNumber].summaryData.useEquity,
    useExtraCash: PropertiesData[propertyIdNumber].summaryData.useExtraCash,
    useLiquidAssets:
      PropertiesData[propertyIdNumber].summaryData.useLiquidAssets,
    ExtraCash: PropertiesData[propertyIdNumber].summaryData.ExtraCash,
    priceReduce: PropertiesData[propertyIdNumber].summaryData.priceReduce,
    propertyType: PropertiesData[propertyIdNumber].summaryData.propertyType,
    stateName: PropertiesData[propertyIdNumber].summaryData.stateName,
    region: PropertiesData[propertyIdNumber].summaryData.region,
    regionNo: PropertiesData[propertyIdNumber].summaryData.regionNo,
    PurchasePrice: PropertiesData[propertyIdNumber].summaryData.PurchasePrice,
    Deposit: PropertiesData[propertyIdNumber].summaryData.Deposit,
    StampDuty: PropertiesData[propertyIdNumber].summaryData.StampDuty,
    AcquisitionCost:
      PropertiesData[propertyIdNumber].summaryData.AcquisitionCost,
    TotalDepositRequired:
      PropertiesData[propertyIdNumber].summaryData.TotalDepositRequired, //Total Funds
    TotalPurchaseCosts:
      PropertiesData[propertyIdNumber].summaryData.TotalPurchaseCosts,
    LoantoValueRatioLVR:
      PropertiesData[propertyIdNumber].summaryData.LoantoValueRatioLVR,
    TotalDebt: PropertiesData[propertyIdNumber].summaryData.TotalDebt,
    TotalDepositEquity:
      PropertiesData[propertyIdNumber].summaryData.TotalDepositEquity,
    TotalDepositCash:
      PropertiesData[propertyIdNumber].summaryData.TotalDepositCash,
    TotalDepositExtraCash:
      PropertiesData[propertyIdNumber].summaryData.TotalDepositExtraCash,
    TotalDepositLiquidAssets:
      PropertiesData[propertyIdNumber].summaryData.TotalDepositLiquidAssets,
    PropertyRentIncomeYear1:
      PropertiesData[propertyIdNumber].summaryData.PropertyRentIncomeYear1,
    InterestPayments:
      PropertiesData[propertyIdNumber].summaryData.InterestPayments,
    PrinciplePayments:
      PropertiesData[propertyIdNumber].summaryData.PrinciplePayments,
    outgoingExpenses:
      PropertiesData[propertyIdNumber].summaryData.outgoingExpenses,
    outgoingExpensePercent:
      PropertiesData[propertyIdNumber].summaryData.outgoingExpensePercent,
    CashflowAnnual: PropertiesData[propertyIdNumber].summaryData.CashflowAnnual,
    CashflowMonthly:
      PropertiesData[propertyIdNumber].summaryData.CashflowMonthly,
    AppliedGrowthRateannualPERC:
      PropertiesData[propertyIdNumber].summaryData.AppliedGrowthRateannualPERC,
    EquityGainPERCorDOLLAR:
      PropertiesData[propertyIdNumber].summaryData.EquityGainPERCorDOLLAR,
    CashGainDOLLAR: PropertiesData[propertyIdNumber].summaryData.CashGainDOLLAR,
    LMI: PropertiesData[propertyIdNumber].summaryData.LMI,
    mortgageInsuranceApply:
      PropertiesData[propertyIdNumber].summaryData.mortgageInsuranceApply,
    interestRate: PropertiesData[propertyIdNumber].summaryData.interestRate,
    DebtInterestRate:
      PropertiesData[propertyIdNumber].summaryData.DebtInterestRate,
    DebtPrincipleRate:
      PropertiesData[propertyIdNumber].summaryData.DebtPrincipleRate,
    EquityInterestRate:
      PropertiesData[propertyIdNumber].summaryData.EquityInterestRate,
    EquityPrincipleRate:
      PropertiesData[propertyIdNumber].summaryData.EquityPrincipleRate,
    RiskFactoredYieldReturn:
      PropertiesData[propertyIdNumber].summaryData.RiskFactoredYieldReturn,
    TotalCostofLending:
      PropertiesData[propertyIdNumber].summaryData.TotalCostofLending,
    ReturnonEquityCOC:
      PropertiesData[propertyIdNumber].summaryData.ReturnonEquityCOC,
  });
  const [EquityGainEdit, setEquitGainEdit] = useState(false);
  const [AnnualGrowthEdit, setAnnualGrowthEdit] = useState(false);
  const [cashGainEdit, setCashGainEdit] = useState(false);
  const [notice, setNotice] = useState("");
  const [loading, setLoading] = useState(false);

  const handleDepositCovered = ({
    TotalDepositRequired,
    TotalDebt,
    PropertyIncome,
    useEquity,
    useCash,
    useLiquidAssets,
    useExtraCash,
    ExtraCash,
    PercentageCash,
    PercentageEquity,
    PercentageLiquidAssets,
  }: {
    TotalDepositRequired: number;
    TotalDebt: number;
    PropertyIncome: number;
    useEquity: "Y" | "N";
    useCash: "Y" | "N";
    useLiquidAssets: "Y" | "N";
    useExtraCash: "Y" | "N";
    ExtraCash: number;
    PercentageCash: number;
    PercentageEquity: number;
    PercentageLiquidAssets: number;
  }) => {
    let {
      TotalDepositEquity,
      TotalDepositCash,
      TotalDespositExtraCash,
      TotalDepositLiquidAssets,
      percentageEquity,
      percentageCash,
      percentageLiquidAssets,
      TotalCostofLending,
      CashflowAnnual,
      CashflowMonthly,
      ReturnonEquityCOC,
      TotalInterestPayable,
      TotalPricipalPayable,
    } = CalculateCashAndEquityDeposit({
      TotalDepositRequired,
      TotalDebt,
      PropertyIncome,
      useEquity,
      useCash,
      useLiquidAssets,
      useExtraCash,
      ExtraCash,
      PercentageCash,
      PercentageEquity,
      PercentageLiquidAssets,
    });
    setpropertyData({
      ...propertyData,
      useExtraCash,
      useCash,
      useEquity,
      useLiquidAssets,
      TotalDepositEquity,
      TotalDepositCash,
      TotalDepositExtraCash: TotalDespositExtraCash,
      TotalDepositLiquidAssets,
      percentageEquity,
      percentageCash,
      percentageLiquidAssets,
      TotalCostofLending,
      CashflowAnnual,
      CashflowMonthly,
      ReturnonEquityCOC,
      InterestPayments: TotalInterestPayable,
      PrinciplePayments: TotalPricipalPayable,
    });
  };
  const CalculateCashAndEquityDeposit = ({
    TotalDepositRequired,
    TotalDebt,
    PropertyIncome,
    useEquity,
    useCash,
    useLiquidAssets,
    useExtraCash,
    ExtraCash,
    PercentageCash,
    PercentageEquity,
    PercentageLiquidAssets,
  }: {
    TotalDepositRequired: number;
    TotalDebt: number;
    PropertyIncome: number;
    useEquity: "Y" | "N";
    useCash: "Y" | "N";
    useLiquidAssets: "Y" | "N";
    useExtraCash: "Y" | "N";
    ExtraCash: number;
    PercentageCash: number;
    PercentageEquity: number;
    PercentageLiquidAssets: number;
  }) => {
    let equityAvailable = PropertiesData[propertyIdNumber].equityAvailable;
    let cashAvailable = PropertiesData[propertyIdNumber].cashAvailable;
    let LiquidAssetsAvailable = PropertiesData[propertyIdNumber].liquidAssets;
    let percentageEquity = PercentageEquity;
    let percentageCash = PercentageCash;
    let percentageLiquidAssets = PercentageLiquidAssets;
    let TotalDepositEquity = 0;
    let TotalDepositCash = 0;
    let totaldepositextracash = 0;
    let totaldepositLiquidAssets = 0;
    let DepositLeft = TotalDepositRequired;

    if (useExtraCash === "Y") {
      totaldepositextracash =
        ((ExtraCash * 100) / 100 || 0) > TotalDepositRequired
          ? TotalDepositRequired
          : (ExtraCash * 100) / 100 || 0;
      DepositLeft = TotalDepositRequired - totaldepositextracash;
    }

    if (useLiquidAssets === "Y") {
      totaldepositLiquidAssets =
        ((LiquidAssetsAvailable * percentageLiquidAssets) / 100 || 0) >
        DepositLeft
          ? DepositLeft
          : (LiquidAssetsAvailable * percentageLiquidAssets) / 100 || 0;
      DepositLeft = DepositLeft - totaldepositLiquidAssets;
    }
    if (useExtraCash === "N" && useLiquidAssets === "N") {
      if (useEquity === "Y") {
        TotalDepositEquity =
          (equityAvailable * percentageEquity) / 100 > DepositLeft
            ? DepositLeft
            : (equityAvailable * percentageEquity) / 100 || 0;
        DepositLeft = DepositLeft - TotalDepositEquity;
      }
      if (useCash === "Y") {
        TotalDepositCash =
          (cashAvailable * percentageCash) / 100 > DepositLeft
            ? DepositLeft
            : (cashAvailable * percentageCash) / 100 || 0;
      }
    } else {
      if (useCash === "Y") {
        TotalDepositCash =
          (cashAvailable * percentageCash) / 100 > DepositLeft
            ? DepositLeft
            : (cashAvailable * percentageCash) / 100 || 0;
      }
      DepositLeft = DepositLeft - TotalDepositCash;
      if (useEquity === "Y") {
        TotalDepositEquity =
          (equityAvailable * percentageEquity) / 100 > DepositLeft
            ? DepositLeft
            : (equityAvailable * percentageEquity) / 100 || 0;
      }
    }

    percentageEquity = (TotalDepositEquity / equityAvailable) * 100 || 0;
    percentageCash = (TotalDepositCash / cashAvailable) * 100 || 0;

    percentageLiquidAssets =
      (totaldepositLiquidAssets / LiquidAssetsAvailable) * 100 || 0;
    // let TotalCostofLending =
    //   (TotalDebt + TotalDepositEquity) * (propertyData.interestRate / 100);

    let equityInterestPayable =
      TotalDepositEquity * (Number(propertyData.EquityInterestRate) / 100 || 0);
    let depositInterestPayable =
      TotalDebt * (Number(propertyData.DebtInterestRate) / 100 || 0);
    let equityPrincipalPayable =
      TotalDepositEquity *
      (Number(propertyData.EquityPrincipleRate) / 100 || 0);
    let depositPrincipalPayable =
      TotalDebt * (Number(propertyData.DebtPrincipleRate) / 100 || 0);
    let TotalInterestPayable = equityInterestPayable + depositInterestPayable;
    let TotalPricipalPayable = equityPrincipalPayable + depositPrincipalPayable;

    let TotalCostofLending = TotalInterestPayable + TotalPricipalPayable;
    let CashflowAnnual = PropertyIncome - TotalCostofLending;
    let CashflowMonthly = CashflowAnnual / 12;
    let ReturnonEquityCOC = (CashflowAnnual / TotalDepositRequired) * 100;
    return {
      TotalDepositEquity,
      TotalDepositCash,
      TotalDespositExtraCash: totaldepositextracash,
      TotalDepositLiquidAssets: totaldepositLiquidAssets,
      percentageEquity,
      percentageCash,
      percentageLiquidAssets,
      TotalCostofLending,
      CashflowAnnual,
      CashflowMonthly,
      ReturnonEquityCOC,
      TotalInterestPayable,
      TotalPricipalPayable,
    };
  };
  const CalculateHandleRates = ({
    DebtInterestRate,
    DebtPrincipleRate,
    EquityInterestRate,
    EquityPrincipleRate,
    TotalDepositEquity,
    TotalDebt,
  }: {
    DebtInterestRate: number;
    DebtPrincipleRate: number;
    EquityInterestRate: number;
    EquityPrincipleRate: number;
    TotalDepositEquity: number;
    TotalDebt: number;
  }) => {
    let equityInterestPayable =
      TotalDepositEquity * (Number(EquityInterestRate) / 100 || 0);
    let depositInterestPayable =
      TotalDebt * (Number(DebtInterestRate) / 100 || 0);
    let equityPrincipalPayable =
      TotalDepositEquity * (Number(EquityPrincipleRate) / 100 || 0);
    let depositPrincipalPayable =
      TotalDebt * (Number(DebtPrincipleRate) / 100 || 0);
    let TotalInterestPayable = equityInterestPayable + depositInterestPayable;
    let TotalPricipalPayable = equityPrincipalPayable + depositPrincipalPayable;
    let TotalCostofLending = TotalInterestPayable + TotalPricipalPayable;
    let CashflowAnnual =
      propertyData.PropertyRentIncomeYear1 - TotalCostofLending;
    let CashflowMonthly = CashflowAnnual / 12;
    let ReturnonEquityCOC =
      (CashflowAnnual / propertyData.TotalDepositRequired) * 100;
    return {
      TotalInterestPayable,
      TotalPricipalPayable,
      CashflowAnnual,
      CashflowMonthly,
      ReturnonEquityCOC,
    };
  };
  const CalculateRemainingPropertiesData = ({
    propertyType,
    LoantoValueRatioLVR,
    RiskFactoredYieldReturn,
    mortgageApply = "N",
  }: {
    propertyType: string;
    LoantoValueRatioLVR: number;
    RiskFactoredYieldReturn: number;
    mortgageApply: "Y" | "N";
  }) => {
    let {
      ResidentialMedianPrice,
      ComercialMedianPrice,
      RetailMedianPrice,
      region,
      regionNo,
      stateName,
    } = GetRegionData({
      RiskFactorYieldReturn: RiskFactoredYieldReturn,
      medianPropertyType: propertyType,
      propertyno: propertyIdNumber,
      selectedRegions: CalData?.PortfolioSummary?.selectedRegions,
    });
    let PurchasePrice = 0;
    if (
      propertyType === "Residential" ||
      propertyType === "Industrial" ||
      propertyType === "Granny Flat" ||
      propertyType === "NDIS" ||
      propertyType === "Rooming House" ||
      propertyType === "Micro Apartment"
    ) {
      PurchasePrice = (ResidentialMedianPrice / RiskFactoredYieldReturn) * 100;
    } else if (propertyType === "Commercial Retail") {
      PurchasePrice = (RetailMedianPrice / RiskFactoredYieldReturn) * 100;
    } else {
      PurchasePrice = (ComercialMedianPrice / RiskFactoredYieldReturn) * 100;
    }

    let LVR = LoantoValueRatioLVR;
    const {
      Deposit: newDeposit,
      StampDuty: newStampDuty,
      AcquisitionCost: newTotalAcquistonCost,
      TotalDepositRequired,
      TotalDebt,
      TotalPurchaseCosts: newTotalPurchaseCosts,
      PropertyIncome,
      lmi,
    } = CalculateFormulasFromPurchasePrice({
      PurchasePrice: PurchasePrice,
      stateName: PropertiesData[propertyIdNumber].summaryData.stateName,
      riskfactor: RiskFactoredYieldReturn,
      lvr: LVR,
      mortgageApply,
    });
    let annualGrowth =
      PropertiesData[propertyIdNumber]?.summaryData
        ?.AppliedGrowthRateannualPERC;
    let growthRateValue = annualGrowth.slice(0, annualGrowth?.length - 1);
    let EquityGainPERCorDOLLAR =
      (PurchasePrice / 100) * Number(growthRateValue);
    let {
      TotalDepositEquity,
      TotalDepositCash,
      TotalDespositExtraCash,
      TotalDepositLiquidAssets,
      percentageEquity,
      percentageCash,
      percentageLiquidAssets,
      TotalCostofLending,
      CashflowAnnual,
      CashflowMonthly,
      ReturnonEquityCOC,
      TotalInterestPayable,
      TotalPricipalPayable,
    } = CalculateCashAndEquityDeposit({
      TotalDepositRequired,
      TotalDebt,
      PropertyIncome,
      useEquity: propertyData.useEquity,
      useCash: propertyData.useCash,
      useLiquidAssets: propertyData.useLiquidAssets,
      useExtraCash: propertyData.useExtraCash,
      ExtraCash: propertyData.ExtraCash,
      PercentageCash: 100,
      PercentageEquity: 100,
      PercentageLiquidAssets: 100,
    });

    setpropertyData({
      ...propertyData,
      PurchasePrice: PurchasePrice,
      propertyType,
      LoantoValueRatioLVR,
      RiskFactoredYieldReturn,
      Deposit: newDeposit,
      StampDuty: newStampDuty,
      AcquisitionCost: newTotalAcquistonCost,
      TotalDepositRequired,
      TotalDebt,
      TotalPurchaseCosts: newTotalPurchaseCosts,
      PropertyRentIncomeYear1: PropertyIncome,
      EquityGainPERCorDOLLAR: EquityGainPERCorDOLLAR,
      ExtraCash: TotalDespositExtraCash,
      TotalDepositLiquidAssets,
      TotalDepositCash,
      TotalDepositEquity,
      percentageCash,
      percentageEquity,
      percentageLiquidAssets,
      CashflowAnnual,
      CashflowMonthly,
      TotalCostofLending,
      InterestPayments: TotalInterestPayable,
      PrinciplePayments: TotalPricipalPayable,
      ReturnonEquityCOC,
      LMI: lmi,
      mortgageInsuranceApply: mortgageApply,
      region,
      stateName,
      regionNo,
    });
  };
  const CalculateFirstPropertyFormula = ({
    propertyType,
    LoantoValueRatioLVR,
    RiskFactoredYieldReturn,
    mortgageApply = "N",
  }: {
    propertyType: string;
    LoantoValueRatioLVR: number;
    RiskFactoredYieldReturn: number;
    mortgageApply: "Y" | "N";
  }) => {
    let totaldebt = 0;
    let maxborrow = PropertiesData[propertyIdNumber].currentmbc;
    if (
      propertyType === "Residential" ||
      propertyType === "Industrial" ||
      propertyType === "Granny Flat" ||
      propertyType === "NDIS" ||
      propertyType === "Rooming House" ||
      propertyType === "Micro Apartment"
    ) {
      totaldebt = (maxborrow * 0.2) / (LoantoValueRatioLVR / 100); //  80/100
    } else {
      totaldebt = (maxborrow * 0.35) / (LoantoValueRatioLVR / 100); // 65/100
    }
    let newPurchasePrice = totaldebt + maxborrow || 0;

    let annualGrowth =
      PropertiesData[propertyIdNumber].summaryData.AppliedGrowthRateannualPERC;
    const {
      Deposit: newDeposit,
      StampDuty: newStampDuty,
      AcquisitionCost: newTotalAcquistonCost,
      TotalDepositRequired: newTotalDepositRequired,
      TotalDebt: newTotalDebt,
      TotalPurchaseCosts: newTotalPurchaseCosts,
      PropertyIncome,
      lmi,
    } = CalculateFormulasFromPurchasePrice({
      PurchasePrice: newPurchasePrice,
      stateName: PropertiesData[propertyIdNumber].summaryData.stateName,
      riskfactor: RiskFactoredYieldReturn,
      acquistionCostValue: 6.5 / 100,
      mortgageApply,
      cityName: propertyData.stateName,
    });
    let newpropertyYield = PropertyIncome / newPurchasePrice || 0;
    let riskfactor = newpropertyYield * 100;
    const {
      checkdepositmessage: updatedcheckdepositmessage,
      PurchasePrice: updatedPurchasePrice,
      PropertyIncome: updatedPropertyIncome,
      TotalAcquistonCost: updatedTotalAcquistonCost,
      Deposit: updatedDeposit,
      StampDuty: updatedStampDuty,
      TotalDepositRequired: updatedTotalDepositRequired,
      TotalPurchaseCost: updatedTotalPurchaseCost,
      TotalDebt: updatedTotalDebt,
      priceReduce: updatedpriceReduce,
      Lmi,
    } = CheckDepositCovered({
      cash: PropertiesData[propertyIdNumber].cashAvailable,
      equityGain: PropertiesData[propertyIdNumber].equityAvailable,
      stateName: PropertiesData[propertyIdNumber].summaryData.stateName,
      newDeposit,
      newStampDuty,
      newAcquisitionCost: newTotalAcquistonCost,
      newTotalDepositRequired,
      newTotalDebt,
      newTotalPurchaseCosts,
      newPropertyIncome: PropertyIncome,
      PurchasePrice: newPurchasePrice,
      lvr: LoantoValueRatioLVR,
      riskfactor,
      mortgageApply,
      LMI: lmi,
    });
    let {
      TotalDepositEquity,
      TotalDepositCash,
      TotalDespositExtraCash,
      TotalDepositLiquidAssets,
      percentageEquity,
      percentageCash,
      percentageLiquidAssets,
      TotalCostofLending,
      CashflowAnnual,
      CashflowMonthly,
      ReturnonEquityCOC,
      TotalInterestPayable,
      TotalPricipalPayable,
    } = CalculateCashAndEquityDeposit({
      TotalDepositRequired: updatedTotalDepositRequired,
      TotalDebt: updatedTotalDebt,
      PropertyIncome: updatedPropertyIncome,
      useEquity: propertyData.useEquity,
      useCash: propertyData.useCash,
      useLiquidAssets: propertyData.useLiquidAssets,
      useExtraCash: propertyData.useExtraCash,
      ExtraCash: propertyData.ExtraCash,
      PercentageCash: 100,
      PercentageEquity: 100,
      PercentageLiquidAssets: 100,
    });
    let growthRateValue = annualGrowth.slice(0, annualGrowth?.length - 1);
    let EquityGainPERCorDOLLAR =
      (updatedPurchasePrice / 100) * Number(growthRateValue);

    setpropertyData({
      ...propertyData,
      PurchasePrice: updatedPurchasePrice,
      propertyType,
      LoantoValueRatioLVR,
      RiskFactoredYieldReturn,
      Deposit: updatedDeposit,
      StampDuty: updatedStampDuty,
      AcquisitionCost: updatedTotalAcquistonCost,
      TotalDepositRequired: updatedTotalDepositRequired,
      TotalDebt: updatedTotalDebt,
      TotalPurchaseCosts: updatedTotalPurchaseCost,
      PropertyRentIncomeYear1: updatedPropertyIncome,
      EquityGainPERCorDOLLAR: EquityGainPERCorDOLLAR,
      ExtraCash: TotalDespositExtraCash,
      TotalDepositLiquidAssets,
      TotalDepositCash,
      TotalDepositEquity,
      percentageCash,
      percentageEquity,
      percentageLiquidAssets,
      CashflowAnnual,
      CashflowMonthly,
      TotalCostofLending,
      InterestPayments: TotalInterestPayable,
      PrinciplePayments: TotalPricipalPayable,
      ReturnonEquityCOC,
      priceReduce: updatedpriceReduce,
      LMI: Lmi,
      mortgageInsuranceApply: mortgageApply,
    });
  };
  const CalculateNextThreePropertyFormulas = ({
    propertyType,
    LoantoValueRatioLVR,
    RiskFactoredYieldReturn,
    mortgageApply = "N",
  }: {
    propertyType: string;
    LoantoValueRatioLVR: number;
    RiskFactoredYieldReturn: number;
    mortgageApply: "Y" | "N";
  }) => {
    let NewLoanTerm = 5;
    let RequiredDSR = SettingsData?.Assumptions?.DSR || 40;
    let annualGrowth =
      PropertiesData[propertyIdNumber].summaryData.AppliedGrowthRateannualPERC;

    let totaldebt = 0;
    let sum = Math.round(
      CalData.BorrowingCalculator.IncomeDetails.YourIncome.value +
        CalData.BorrowingCalculator.IncomeDetails.PartnerIncome.value +
        CalData.BorrowingCalculator.IncomeDetails.OtherIncome.value +
        CalculatePropertiesCashFlowAnnual({
          propertyno: propertyIdNumber,
          properties: PropertiesData,
        }) +
        TotalInvestmentPropertiesGrossIncome({
          Properties: CalData.Equity.Properties,
        })
    );
    let dti = SettingsData?.Assumptions?.DTI || 6;
    let {
      totalLiabilitiesAndExpenses,
      total_card_limit,
      totalFinanceRepayment,
      OtherLoanPayments,
      totalLivingExpense,
    } = CalculateTotalLiabilitiesAndExpenses({ CalData });
    let propertyCashflowAnnual: any = CalculatePropertiesCashFlowAnnual({
      propertyno: propertyIdNumber,
      properties: CalData.PropertyGenerators,
    });
    let maxborrow = calculateMaxBorrowingCapacity(
      CalData.Equity.PPR,
      CalData.Equity.Properties,
      propertyCashflowAnnual,
      propertyIdNumber,
      CalData.BorrowingCalculator.IncomeDetails,
      CalData.BorrowingCalculator.PersonalLiabilities.CreditCards,
      CalData.BorrowingCalculator.UseAverageAustralian,
      CalData.BorrowingCalculator.UseBasiq,
      CalData.BorrowingCalculator.LivingExpenses,
      CalData.BorrowingCalculator.BasiqExpenses,
      SettingsData.Assumptions.DTI,
      CalData.BorrowingCalculator.ManualExpense
    );
    if (
      propertyType === "Residential" ||
      propertyType === "Industrial" ||
      propertyType === "Granny Flat" ||
      propertyType === "NDIS" ||
      propertyType === "Rooming House" ||
      propertyType === "Micro Apartment"
    ) {
      totaldebt = (maxborrow * 0.6) / (LoantoValueRatioLVR / 100); //  80/100
    } else {
      totaldebt = (maxborrow * 0.4) / (LoantoValueRatioLVR / 100); // 65/100
    }
    let newPurchasePrice = totaldebt + maxborrow;

    const {
      AcquisitionCost: newTotalAcquistonCost,
      TotalDepositRequired: newTotalDepositRequired,
      PropertyIncome,
      lmi,
    } = CalculateFormulasFromPurchasePrice({
      PurchasePrice: newPurchasePrice,
      stateName: PropertiesData[propertyIdNumber].summaryData.stateName,
      riskfactor: RiskFactoredYieldReturn,
      acquistionCostValue: 6.5 / 100,
      mortgageApply,
    });
    let totalLoanAmountPrincipal = totaldebt; //Changes according to tyson

    let newAnnualRepaymentDebt = Math.round(
      totaldebt * (propertyData.DebtInterestRate / 100)
    ); //Change according to Tyson
    let { TotalDepositEquity: newDepositEquity } =
      CalculateCashAndEquityDeposit({
        TotalDepositRequired: newTotalDepositRequired,
        TotalDebt: 0,
        PropertyIncome: 0,
        useEquity: propertyData.useEquity,
        useCash: propertyData.useCash,
        useLiquidAssets: propertyData.useLiquidAssets,
        useExtraCash: propertyData.useExtraCash,
        ExtraCash: propertyData.ExtraCash,
        PercentageCash: 100,
        PercentageEquity: 100,
        PercentageLiquidAssets: 100,
      });
    let newAnnualRepaymentEquity = Math.round(
      newDepositEquity * (propertyData.EquityInterestRate / 100)
    ); //change according to Tyson

    let currentDSRpart1 =
      newAnnualRepaymentDebt +
      newAnnualRepaymentEquity +
      totalFinanceRepayment +
      OtherLoanPayments +
      total_card_limit;
    let currentDSRpart2 = sum + PropertyIncome;
    let currentDSR = (currentDSRpart1 / currentDSRpart2) * 100 || 0;
    let newGrosIncome =
      Math.round(
        (totalFinanceRepayment +
          totalLivingExpense +
          total_card_limit +
          newAnnualRepaymentDebt +
          newAnnualRepaymentEquity) /
          12 /
          (currentDSR / 100)
      ) || 0;
    console.log(
      newGrosIncome,
      "newGrossIncome",
      propertyIdNumber,
      newDepositEquity,
      newTotalDepositRequired
    );
    let targetDebtRepayment = Math.round(newGrosIncome * (RequiredDSR / 100));
    let DecreaseInTotalPayment = newGrosIncome - targetDebtRepayment;
    let DecreaseinMBCpart2 =
      NewLoanTerm / (1 - Math.pow(1 + 0.07, -NewLoanTerm));
    let DecreaseInMBC = Math.round(DecreaseInTotalPayment * DecreaseinMBCpart2);
    let newMaxBorrowCapacity = totalLoanAmountPrincipal - DecreaseInMBC;
    let newTotalAmountPrincipal = newMaxBorrowCapacity;
    let newTotalDebt =
      (newTotalAmountPrincipal * (1 - LoantoValueRatioLVR / 100)) /
      (LoantoValueRatioLVR / 100);

    let newTotalPurchasePrice = Number(newTotalAmountPrincipal + newTotalDebt);
    const {
      Deposit: updatedDeposit,
      StampDuty: updatedStampDuty,
      AcquisitionCost: updatedTotalAcquistonCost,
      TotalDepositRequired: updatedTotalDepositRequired,
      TotalDebt: updatedTotalDebts,
      TotalPurchaseCosts: updatedTotalPurchaseCosts,
      PropertyIncome: updatedPropertyIncome,
      lmi: LMI,
    } = CalculateFormulasFromPurchasePrice({
      PurchasePrice: newTotalPurchasePrice,
      stateName: PropertiesData[propertyIdNumber].summaryData.stateName,
      riskfactor: RiskFactoredYieldReturn,
      mortgageApply,
    });
    let {
      TotalDepositEquity,
      TotalDepositCash,
      TotalDespositExtraCash,
      TotalDepositLiquidAssets,
      percentageEquity,
      percentageCash,
      percentageLiquidAssets,
      TotalCostofLending,
      CashflowAnnual,
      CashflowMonthly,
      ReturnonEquityCOC,
      TotalInterestPayable,
      TotalPricipalPayable,
    } = CalculateCashAndEquityDeposit({
      TotalDepositRequired: updatedTotalDepositRequired,
      TotalDebt: updatedTotalDebts,
      PropertyIncome: updatedPropertyIncome,
      useEquity: propertyData.useEquity,
      useCash: propertyData.useCash,
      useLiquidAssets: propertyData.useLiquidAssets,
      useExtraCash: propertyData.useExtraCash,
      ExtraCash: propertyData.ExtraCash,
      PercentageCash: 100,
      PercentageEquity: 100,
      PercentageLiquidAssets: 100,
    });

    let growthRateValue = annualGrowth.slice(0, annualGrowth?.length - 1);
    let EquityGainPERCorDOLLAR =
      (newTotalPurchasePrice / 100) * Number(growthRateValue);

    setpropertyData({
      ...propertyData,
      PurchasePrice: newTotalPurchasePrice,
      propertyType,
      LoantoValueRatioLVR,
      RiskFactoredYieldReturn,
      Deposit: updatedDeposit,
      StampDuty: updatedStampDuty,
      AcquisitionCost: updatedTotalAcquistonCost,
      TotalDepositRequired: updatedTotalDepositRequired,
      TotalDebt: updatedTotalDebts,
      TotalPurchaseCosts: updatedTotalPurchaseCosts,
      PropertyRentIncomeYear1: updatedPropertyIncome,
      EquityGainPERCorDOLLAR: EquityGainPERCorDOLLAR,
      ExtraCash: TotalDespositExtraCash,
      TotalDepositLiquidAssets,
      TotalDepositCash,
      TotalDepositEquity,
      percentageCash,
      percentageEquity,
      percentageLiquidAssets,
      CashflowAnnual,
      CashflowMonthly,
      TotalCostofLending,
      InterestPayments: TotalInterestPayable,
      PrinciplePayments: TotalPricipalPayable,
      ReturnonEquityCOC,
      LMI: LMI,
      mortgageInsuranceApply: mortgageApply,
    });
  };
  const handlePropertyTypeChanges = async ({
    propertyType,
    LoantoValueRatioLVR,
    RiskFactoredYieldReturn,
    mortgageApply = "N",
  }: {
    propertyType: string;
    LoantoValueRatioLVR: number;
    RiskFactoredYieldReturn: number;
    mortgageApply: "Y" | "N";
  }) => {
    if (propertyIdNumber === 0) {
      CalculateFirstPropertyFormula({
        propertyType,
        LoantoValueRatioLVR,
        RiskFactoredYieldReturn,
        mortgageApply,
      });
    } else if (
      propertyIdNumber === 1 ||
      propertyIdNumber === 2 ||
      propertyIdNumber === 3
    ) {
      CalculateNextThreePropertyFormulas({
        propertyType,
        LoantoValueRatioLVR,
        RiskFactoredYieldReturn,
        mortgageApply,
      });
    } else {
      CalculateRemainingPropertiesData({
        propertyType,
        LoantoValueRatioLVR,
        RiskFactoredYieldReturn,
        mortgageApply,
      });
    }
  };
  const LockProperty = () => {
    let updatedProperties: TPropertyGeneratorItem[] = PropertiesData.map(
      (item, index) => {
        if (index === propertyIdNumber) {
          return {
            ...item,
            isLocked: true,
            summaryData: {
              ...item.summaryData,
              ...propertyData,
            },
          };
        } else {
          return item;
        }
      }
    );
    let newcalobj = { ...CalData };
    newcalobj = {
      ...newcalobj,
      PropertyGenerators: updatedProperties,
    };
    dispatch(SetCalData(newcalobj));
    SaveCalData(newcalobj);
  };
  const UnLockProperty = () => {
    let updatedProperties: TPropertyGeneratorItem[] = PropertiesData.map(
      (item, index) => {
        if (index === propertyIdNumber) {
          return {
            ...item,
            isLocked: false,
          };
        } else {
          return item;
        }
      }
    );
    let newcalobj = { ...CalData };
    newcalobj = {
      ...newcalobj,
      PropertyGenerators: updatedProperties,
    };
    dispatch(SetCalData(newcalobj));
    SaveCalData(newcalobj);
  };
  return (
    <div className="grid px-2">
      <div
        className="col"
        style={{ backgroundColor: "#ebf2fa", padding: "52px 52px" }}
      >
        <p className="heading">Property #{propertyid}</p>
        <table className="propertytable">
          <thead>
            <tr>
              <th colSpan={1}>
                <span className="heading2">FEASIBILITY MARKERS</span>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <span className="property-detail-heading">Property Type</span>
              </td>
              <td className="!text-right">
                <span className="property-detail-text">
                  {propertyData.propertyType}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="property-detail-heading">Purchase Price</span>
              </td>
              <td className="!text-right">
                <span className="property-detail-text">
                  {commaStringSeparatorsWithDollors(propertyData.PurchasePrice)}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="property-detail-heading">Deposit</span>
              </td>
              <td className="!text-right">
                <span className="property-detail-text">
                  {commaStringSeparatorsWithDollors(propertyData.Deposit)}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="property-detail-heading">Stamp Duty</span>
              </td>
              <td className="!text-right">
                <span className="property-detail-text">
                  {commaStringSeparatorsWithDollors(propertyData.StampDuty)}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="property-detail-heading">
                  Acquisition Cost
                </span>
              </td>
              <td className="!text-right">
                <span className="property-detail-text">
                  {commaStringSeparatorsWithDollors(
                    propertyData.AcquisitionCost
                  )}
                </span>
              </td>
            </tr>
            {propertyData.mortgageInsuranceApply === "Y" && (
              <tr>
                <td>
                  <span className="property-detail-heading">Total LMI</span>
                </td>
                <td className="!text-right">
                  <span className="property-detail-text">
                    {commaStringSeparatorsWithDollors(propertyData.LMI)}
                  </span>
                </td>
              </tr>
            )}
            <tr>
              <td>
                <span className="total">Total Funds Required</span>
              </td>
              <td className="!text-right">
                <span className="property-detail-text">
                  {commaStringSeparatorsWithDollors(
                    propertyData.TotalDepositRequired
                  )}{" "}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="property-detail-heading">
                  Total Purchase Costs
                </span>
              </td>
              <td className="!text-right">
                <span className="property-detail-text">
                  {commaStringSeparatorsWithDollors(
                    propertyData.TotalPurchaseCosts
                  )}
                </span>
              </td>
            </tr>
            <tr>
              <td colSpan={1}>
                <span className="heading2">FINANCE</span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="property-detail-heading">
                  Loan To Value Ratio(LVR)
                </span>
              </td>
              <td className="!text-right">
                <span className="property-detail-text">
                  {propertyData.LoantoValueRatioLVR}%
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="property-detail-heading">Total Debt</span>
              </td>
              <td className="!text-right">
                <span className="property-detail-text">
                  {commaStringSeparatorsWithDollors(propertyData.TotalDebt)}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="property-detail-heading">
                  Total Deposit Equity
                </span>
              </td>
              <td className="!text-right">
                <span className="property-detail-text">
                  {commaStringSeparatorsWithDollors(
                    propertyData.TotalDepositEquity
                  )}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="property-detail-heading">
                  Total Deposit Cash
                </span>
              </td>
              <td className="!text-right">
                <span className="property-detail-text">
                  {commaStringSeparatorsWithDollors(
                    propertyData.TotalDepositCash
                  )}
                </span>
              </td>
            </tr>
            {propertyData.useExtraCash == "Y" && (
              <tr>
                <td>
                  <span className="property-detail-heading">
                    Total Deposit Extra Cash
                  </span>
                </td>
                <td className="!text-right">
                  <span className="property-detail-text">
                    {commaStringSeparatorsWithDollors(
                      propertyData.TotalDepositExtraCash
                    )}
                  </span>
                </td>
              </tr>
            )}
            {propertyData.useLiquidAssets == "Y" && (
              <tr>
                <td>
                  <span className="property-detail-heading">
                    Total Liquid Assets{" "}
                  </span>
                </td>
                <td className="!text-right">
                  <span className="property-detail-text">
                    {commaStringSeparatorsWithDollors(
                      propertyData.TotalDepositLiquidAssets
                    )}
                  </span>
                </td>
              </tr>
            )}

            <tr>
              <td colSpan={1}>
                <span className="heading2">CASHFLOW</span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="property-detail-heading">
                  Property Rent Income (Year 1)
                </span>
              </td>
              <td className="!text-right">
                <span className="property-detail-text">
                  {commaStringSeparatorsWithDollors(
                    propertyData.PropertyRentIncomeYear1
                  )}
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <span className="property-detail-heading">
                  Interest Payments
                </span>
              </td>
              <td className="!text-right">
                <span className="property-detail-text">
                  {commaStringSeparatorsWithDollors(
                    propertyData.InterestPayments
                  )}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="property-detail-heading">
                  Principal Payments
                </span>
              </td>
              <td className="!text-right">
                <span className="property-detail-text">
                  {commaStringSeparatorsWithDollors(
                    propertyData.PrinciplePayments
                  )}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="property-detail-heading">
                  Outgoing Expenses
                </span>
              </td>
              <td className="!text-right">
                <span className="property-detail-text">
                  {commaStringSeparatorsWithDollors(
                    propertyData.outgoingExpenses
                  )}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="property-detail-heading">
                  Cashflow (Annual)
                </span>
              </td>
              <td className="!text-right">
                <span className="property-detail-text">
                  {commaStringSeparatorsWithDollors(
                    propertyData.CashflowAnnual
                  )}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="property-detail-heading">
                  Cashflow (Monthly)
                </span>
              </td>
              <td className="!text-right">
                <span className="property-detail-text">
                  {commaStringSeparatorsWithDollors(
                    propertyData.CashflowMonthly
                  )}
                </span>
              </td>
            </tr>

            <tr>
              <td colSpan={1}>
                <span className="heading2">GROWTH HACKING</span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="property-detail-heading">
                  Applied Growth Rate (annual) (%)
                </span>
              </td>
              <td className="!text-right">
                {AnnualGrowthEdit == false ? (
                  <span
                    className="property-detail-text"
                    onClick={() => setAnnualGrowthEdit(true)}
                  >
                    {propertyData.AppliedGrowthRateannualPERC}
                  </span>
                ) : (
                  <InputTexts
                    onFocus={(e: any) => (e.target.value = "")}
                    value={propertyData.AppliedGrowthRateannualPERC}
                    onChange={(e: any) => {
                      let AnnualGrow = Number(e.target.value);
                      let EquityGainPERCorDOLLAR2 =
                        (Number(propertyData.PurchasePrice) / 100) *
                        Number(AnnualGrow);
                      setpropertyData({
                        ...propertyData,
                        AppliedGrowthRateannualPERC: e.target.value,
                        EquityGainPERCorDOLLAR: Number(EquityGainPERCorDOLLAR2),
                      });
                    }}
                    onBlur={(e: any) => {
                      setAnnualGrowthEdit(false);
                    }}
                  />
                )}
              </td>
            </tr>
            <tr>
              <td>
                <span className="property-detail-heading">Equity Gain ($)</span>
              </td>
              <td className="!text-right">
                {EquityGainEdit == false ? (
                  <span
                    className="property-detail-text"
                    onClick={() => setEquitGainEdit(true)}
                  >
                    {commaStringSeparatorsWithDollors(
                      propertyData.EquityGainPERCorDOLLAR
                    )}
                  </span>
                ) : (
                  <InputTexts
                    onFocus={(e: any) => (e.target.value = "")}
                    value={propertyData.EquityGainPERCorDOLLAR}
                    onChange={(e: any) => {
                      setpropertyData({
                        ...propertyData,
                        EquityGainPERCorDOLLAR: Number(e.target.value),
                      });
                    }}
                    onBlur={(e: any) => {
                      setEquitGainEdit(false);
                      let EquityGainPERCorDOLLAR2 = Number(e.target.value);
                      let AnnualGrow =
                        (EquityGainPERCorDOLLAR2 /
                          Number(propertyData.PurchasePrice)) *
                        100;
                      setpropertyData({
                        ...propertyData,
                        AppliedGrowthRateannualPERC: String(AnnualGrow),
                      });
                    }}
                  />
                )}
              </td>
            </tr>
            <tr>
              <td>
                <span className="property-detail-heading">Cash Gain ($)</span>
              </td>
              <td className="!text-right">
                {cashGainEdit == false ? (
                  <span
                    className="property-detail-text"
                    onClick={() => setCashGainEdit(true)}
                  >
                    {commaStringSeparatorsWithDollors(
                      propertyData.CashGainDOLLAR
                    )}
                  </span>
                ) : (
                  <InputTexts
                    onFocus={(e: any) => (e.target.value = "")}
                    value={propertyData.CashGainDOLLAR}
                    onChange={(e: any) => {
                      setpropertyData({
                        ...propertyData,
                        CashGainDOLLAR: Number(e.target.value),
                      });
                    }}
                    onBlur={(e: any) => {
                      setCashGainEdit(false);
                    }}
                  />
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        className="col"
        style={{
          backgroundColor: "var(--white)",
          padding: "0 52px 52px 52px",
        }}
      >
        <div className="!mt-8">
          {propertyIdNumber >= 0 ? (
            <p className="lbl notice">{notice}</p> //
          ) : (
            <p className="lbl notice"></p>
          )}
          <div className="mb-4 ">
            <span className=" lbl">Property Type</span>
            <div className="flex flex-wrap">
              <div className="field-radiobutton radiomargin">
                <RadioButton
                  inputId="propertytype1"
                  name="propertytype"
                  value="Residential"
                  onChange={(e) => {
                    handlePropertyTypeChanges({
                      propertyType: e.value,
                      LoantoValueRatioLVR: 80,
                      RiskFactoredYieldReturn: 5,
                      mortgageApply: propertyData.mortgageInsuranceApply,
                    });
                  }}
                  checked={propertyData.propertyType === "Residential"}
                />
                <label htmlFor="propertytype1">Residential</label>
              </div>
              <div className="field-radiobutton radiomargin">
                <RadioButton
                  inputId="propertytype2"
                  name="propertytype"
                  value="Commercial Retail"
                  onChange={(e) => {
                    handlePropertyTypeChanges({
                      propertyType: e.value,
                      LoantoValueRatioLVR: 65,
                      RiskFactoredYieldReturn: 6.5,
                      mortgageApply: propertyData.mortgageInsuranceApply,
                    });
                  }}
                  checked={propertyData.propertyType === "Commercial Retail"}
                />
                <label htmlFor="propertytype2">Retail</label>
              </div>
              <div className="field-radiobutton radiomargin">
                <RadioButton
                  inputId="propertytype3"
                  name="propertytype"
                  value="Commercial Office"
                  onChange={(e) => {
                    handlePropertyTypeChanges({
                      propertyType: e.value,
                      LoantoValueRatioLVR: 65,
                      RiskFactoredYieldReturn: 7,
                      mortgageApply: "N",
                    });
                  }}
                  checked={propertyData.propertyType === "Commercial Office"}
                />
                <label htmlFor="propertytype3">Office</label>
              </div>
              <div className="field-radiobutton radiomargin">
                <RadioButton
                  inputId="propertytype4"
                  name="propertytype"
                  value="Industrial"
                  onChange={(e) => {
                    handlePropertyTypeChanges({
                      propertyType: e.value,
                      LoantoValueRatioLVR: 65,
                      RiskFactoredYieldReturn: 5.5,
                      mortgageApply: "N",
                    });
                  }}
                  checked={propertyData.propertyType === "Industrial"}
                />
                <label htmlFor="propertytype4">Industrial</label>
              </div>
              <div className="field-radiobutton radiomargin">
                <RadioButton
                  inputId="propertytype5"
                  name="propertytype"
                  value="Granny Flat"
                  onChange={(e) => {
                    handlePropertyTypeChanges({
                      propertyType: e.value,
                      LoantoValueRatioLVR: 75,
                      RiskFactoredYieldReturn: 10,
                      mortgageApply: "N",
                    });
                  }}
                  checked={propertyData.propertyType === "Granny Flat"}
                />
                <label htmlFor="propertytype5">Granny Flat</label>
              </div>
              <div className="field-radiobutton radiomargin">
                <RadioButton
                  inputId="propertytype6"
                  name="propertytype"
                  value="NDIS"
                  onChange={(e) => {
                    handlePropertyTypeChanges({
                      propertyType: e.value,
                      LoantoValueRatioLVR: 75,
                      RiskFactoredYieldReturn: 10,
                      mortgageApply: "N",
                    });
                  }}
                  checked={propertyData.propertyType === "NDIS"}
                />
                <label htmlFor="propertytype6">NDIS</label>
              </div>

              <div className="field-radiobutton radiomargin">
                <RadioButton
                  inputId="propertytype7"
                  name="propertytype"
                  value="Rooming House"
                  onChange={(e) => {
                    handlePropertyTypeChanges({
                      propertyType: e.value,
                      LoantoValueRatioLVR: 65,
                      RiskFactoredYieldReturn: 10,
                      mortgageApply: "N",
                    });
                  }}
                  checked={propertyData.propertyType === "Rooming House"}
                />
                <label htmlFor="propertytype7">Rooming House</label>
              </div>
              <div className="field-radiobutton radiomargin">
                <RadioButton
                  inputId="propertytype8"
                  name="propertytype"
                  value="Micro Apartment"
                  onChange={(e) => {
                    handlePropertyTypeChanges({
                      propertyType: e.value,
                      LoantoValueRatioLVR: 80,
                      RiskFactoredYieldReturn: 8,
                      mortgageApply: propertyData.mortgageInsuranceApply,
                    });
                  }}
                  checked={propertyData.propertyType === "Micro Apartment"}
                />
                <label htmlFor="propertytype8">Micro Apartment</label>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <span className="lbl">Use Equity?</span>
            <div className="flex">
              <div className="field-radiobutton radiomargin">
                <RadioButton
                  inputId="seEquity1"
                  name="seEquity"
                  value="Y"
                  onChange={(e) => {
                    handleDepositCovered({
                      TotalDepositRequired: propertyData.TotalDepositRequired,
                      TotalDebt: propertyData.TotalDebt,
                      PropertyIncome: propertyData.PropertyRentIncomeYear1,
                      useEquity: e.value,
                      useCash: propertyData.useCash,
                      useLiquidAssets: propertyData.useLiquidAssets,
                      useExtraCash: propertyData.useExtraCash,
                      ExtraCash: propertyData.ExtraCash,
                      PercentageCash: propertyData.percentageCash,
                      PercentageEquity: propertyData.percentageEquity,
                      PercentageLiquidAssets:
                        propertyData.percentageLiquidAssets,
                    });
                  }}
                  checked={propertyData.useEquity === "Y"}
                />
                <label htmlFor="seEquity1">Yes</label>
              </div>
              <div className="field-radiobutton radiomargin">
                <RadioButton
                  inputId="seEquity2"
                  name="seEquity"
                  value="N"
                  onChange={(e) => {
                    handleDepositCovered({
                      TotalDepositRequired: propertyData.TotalDepositRequired,
                      TotalDebt: propertyData.TotalDebt,
                      PropertyIncome: propertyData.PropertyRentIncomeYear1,
                      useEquity: e.value,
                      useCash: propertyData.useCash,
                      useLiquidAssets: propertyData.useLiquidAssets,
                      useExtraCash: propertyData.useExtraCash,
                      ExtraCash: propertyData.ExtraCash,
                      PercentageCash: propertyData.percentageCash,
                      PercentageEquity: 0,
                      PercentageLiquidAssets:
                        propertyData.percentageLiquidAssets,
                    });
                  }}
                  checked={propertyData.useEquity === "N"}
                />
                <label htmlFor="seEquity2">No</label>
              </div>
            </div>
            <div className="w-full p-1 mt-3 mb-4">
              <InputNumber
                suffix="%"
                minFractionDigits={0}
                maxFractionDigits={1}
                max={100}
                min={0}
                value={propertyData.percentageEquity}
                className="w-full"
                placeholder="Percentage Equity"
                onChange={(e) => {
                  let value = Number(e?.value || 0);
                  setpropertyData({
                    ...propertyData,
                    useEquity: value > 0 ? "Y" : "N",
                    percentageEquity: value,
                  });
                  handleDepositCovered({
                    TotalDepositRequired: propertyData.TotalDepositRequired,
                    TotalDebt: propertyData.TotalDebt,
                    PropertyIncome: propertyData.PropertyRentIncomeYear1,
                    useEquity: value > 0 ? "Y" : "N",
                    useCash: propertyData.useCash,
                    useLiquidAssets: propertyData.useLiquidAssets,
                    useExtraCash: propertyData.useExtraCash,
                    ExtraCash: propertyData.ExtraCash,
                    PercentageCash: propertyData.percentageCash,
                    PercentageEquity: value,
                    PercentageLiquidAssets: propertyData.percentageLiquidAssets,
                  });
                }}
              />
            </div>
          </div>
          <div className="mb-4">
            <span className="lbl">Use Cash</span>
            <div className="flex">
              <div className="field-radiobutton radiomargin">
                <RadioButton
                  inputId="useCash1"
                  name="useCash"
                  value="Y"
                  onChange={(e) => {
                    handleDepositCovered({
                      TotalDepositRequired: propertyData.TotalDepositRequired,
                      TotalDebt: propertyData.TotalDebt,
                      PropertyIncome: propertyData.PropertyRentIncomeYear1,
                      useEquity: propertyData.useEquity,
                      useCash: e.value,
                      useLiquidAssets: propertyData.useLiquidAssets,
                      useExtraCash: propertyData.useExtraCash,
                      ExtraCash: propertyData.ExtraCash,
                      PercentageCash: propertyData.percentageCash,
                      PercentageEquity: propertyData.percentageEquity,
                      PercentageLiquidAssets:
                        propertyData.percentageLiquidAssets,
                    });
                  }}
                  checked={propertyData.useCash === "Y"}
                />
                <label htmlFor="useCash1">Yes</label>
              </div>
              <div className="field-radiobutton radiomargin">
                <RadioButton
                  inputId="useCash2"
                  name="useCash"
                  value="N"
                  onChange={(e) => {
                    handleDepositCovered({
                      TotalDepositRequired: propertyData.TotalDepositRequired,
                      TotalDebt: propertyData.TotalDebt,
                      PropertyIncome: propertyData.PropertyRentIncomeYear1,
                      useEquity: propertyData.useEquity,
                      useCash: e.value,
                      useLiquidAssets: propertyData.useLiquidAssets,
                      useExtraCash: propertyData.useExtraCash,
                      ExtraCash: propertyData.ExtraCash,
                      PercentageCash: 0,
                      PercentageEquity: propertyData.percentageEquity,
                      PercentageLiquidAssets:
                        propertyData.percentageLiquidAssets,
                    });
                  }}
                  checked={propertyData.useCash === "N"}
                />
                <label htmlFor="useCash2">No</label>
              </div>
            </div>
            <div className="w-full p-1 mt-3 mb-4">
              <InputNumber
                suffix="%"
                minFractionDigits={0}
                maxFractionDigits={1}
                max={100}
                value={propertyData.percentageCash}
                className="w-full"
                placeholder="Percentage Cash"
                onChange={(e) => {
                  let value = Number(e?.value || 0);
                  handleDepositCovered({
                    TotalDepositRequired: propertyData.TotalDepositRequired,
                    TotalDebt: propertyData.TotalDebt,
                    PropertyIncome: propertyData.PropertyRentIncomeYear1,
                    useEquity: propertyData.useEquity,
                    useCash: value > 0 ? "Y" : "N",
                    useLiquidAssets: propertyData.useLiquidAssets,
                    useExtraCash: propertyData.useExtraCash,
                    ExtraCash: propertyData.ExtraCash,
                    PercentageCash: value,
                    PercentageEquity: propertyData.percentageEquity,
                    PercentageLiquidAssets: propertyData.percentageLiquidAssets,
                  });
                }}
              />
            </div>
          </div>
          <div className="mb-4">
            <span className="lbl">Use Liquid Assets</span>
            <div className="flex">
              <div className="field-radiobutton radiomargin">
                <RadioButton
                  inputId="useLiquidAssets1"
                  name="useLiquidAssets"
                  value="Y"
                  onChange={(e) => {
                    handleDepositCovered({
                      TotalDepositRequired: propertyData.TotalDepositRequired,
                      TotalDebt: propertyData.TotalDebt,
                      PropertyIncome: propertyData.PropertyRentIncomeYear1,
                      useEquity: propertyData.useEquity,
                      useCash: propertyData.useCash,
                      useLiquidAssets: e.value,
                      useExtraCash: propertyData.useExtraCash,
                      ExtraCash: propertyData.ExtraCash,
                      PercentageCash: propertyData.percentageCash,
                      PercentageEquity: propertyData.percentageEquity,
                      PercentageLiquidAssets:
                        propertyData.percentageLiquidAssets,
                    });
                  }}
                  checked={propertyData.useLiquidAssets === "Y"}
                />
                <label htmlFor="useLiquidAssets1">Yes</label>
              </div>
              <div className="field-radiobutton radiomargin">
                <RadioButton
                  inputId="useLiquidAssets2"
                  name="useLiquidAssets"
                  value="N"
                  onChange={(e) => {
                    handleDepositCovered({
                      TotalDepositRequired: propertyData.TotalDepositRequired,
                      TotalDebt: propertyData.TotalDebt,
                      PropertyIncome: propertyData.PropertyRentIncomeYear1,
                      useEquity: propertyData.useEquity,
                      useCash: propertyData.useCash,
                      useLiquidAssets: e.value,
                      useExtraCash: propertyData.useExtraCash,
                      ExtraCash: propertyData.ExtraCash,
                      PercentageCash: propertyData.percentageCash,
                      PercentageEquity: propertyData.percentageEquity,
                      PercentageLiquidAssets: 0,
                    });
                  }}
                  checked={propertyData.useLiquidAssets === "N"}
                />
                <label htmlFor="useLiquidAssets2">No</label>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <MySlider
              value={propertyData.percentageLiquidAssets}
              onChange={(e: any) => {
                handleDepositCovered({
                  TotalDepositRequired: propertyData.TotalDepositRequired,
                  TotalDebt: propertyData.TotalDebt,
                  PropertyIncome: propertyData.PropertyRentIncomeYear1,
                  useEquity: propertyData.useEquity,
                  useCash: propertyData.useCash,
                  useLiquidAssets: propertyData.useLiquidAssets,
                  useExtraCash: propertyData.useExtraCash,
                  ExtraCash: propertyData.ExtraCash,
                  PercentageCash: propertyData.percentageCash,
                  PercentageEquity: propertyData.percentageEquity,
                  PercentageLiquidAssets: Number(e.value),
                });
              }}
            />
            <div className="flex">
              <span className="mt-4 ">
                {propertyData.percentageLiquidAssets}%
              </span>
            </div>
          </div>
          <div className="mb-4">
            <span className="lbl">Use Extra Cash</span>
            <div className="flex">
              <div className="field-radiobutton radiomargin">
                <RadioButton
                  inputId="useExtraCash1"
                  name="useExtraCash"
                  value="Y"
                  onChange={(e) => {
                    handleDepositCovered({
                      TotalDepositRequired: propertyData.TotalDepositRequired,
                      TotalDebt: propertyData.TotalDebt,
                      PropertyIncome: propertyData.PropertyRentIncomeYear1,
                      useEquity: propertyData.useEquity,
                      useCash: propertyData.useCash,
                      useLiquidAssets: propertyData.useLiquidAssets,
                      useExtraCash: e.value,
                      ExtraCash: propertyData.ExtraCash,
                      PercentageCash: propertyData.percentageCash,
                      PercentageEquity: propertyData.percentageEquity,
                      PercentageLiquidAssets:
                        propertyData.percentageLiquidAssets,
                    });
                  }}
                  checked={propertyData.useExtraCash === "Y"}
                />
                <label htmlFor="useExtraCash1">Yes</label>
              </div>
              <div className="field-radiobutton radiomargin">
                <RadioButton
                  inputId="useExtraCash2"
                  name="useExtraCash"
                  value="N"
                  onChange={(e) => {
                    handleDepositCovered({
                      TotalDepositRequired: propertyData.TotalDepositRequired,
                      TotalDebt: propertyData.TotalDebt,
                      PropertyIncome: propertyData.PropertyRentIncomeYear1,
                      useEquity: propertyData.useEquity,
                      useCash: propertyData.useCash,
                      useLiquidAssets: propertyData.useLiquidAssets,
                      useExtraCash: e.value,
                      ExtraCash: 0,
                      PercentageCash: propertyData.percentageCash,
                      PercentageEquity: propertyData.percentageEquity,
                      PercentageLiquidAssets:
                        propertyData.percentageLiquidAssets,
                    });
                  }}
                  checked={propertyData.useExtraCash === "N"}
                />
                <label htmlFor="useExtraCash2">No</label>
              </div>
            </div>
            {propertyData.useExtraCash == "Y" && (
              <div className="w-full p-1 mt-4 mb-4">
                <InputNumbers
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  value={propertyData.ExtraCash}
                  onFocus={(e: any) => (e.target.value = "")}
                  // onBlur={(e: any) => (e.target.value = commaSeparators(extraCash))}
                  onChange={(e: any) => {
                    handleDepositCovered({
                      TotalDepositRequired: propertyData.TotalDepositRequired,
                      TotalDebt: propertyData.TotalDebt,
                      PropertyIncome: propertyData.PropertyRentIncomeYear1,
                      useEquity: propertyData.useEquity,
                      useCash: propertyData.useCash,
                      useLiquidAssets: propertyData.useLiquidAssets,
                      useExtraCash: propertyData.useExtraCash,
                      ExtraCash: Number(e.value),
                      PercentageCash: 100,
                      PercentageEquity: 100,
                      PercentageLiquidAssets: 100,
                    });
                  }}
                />
              </div>
            )}
          </div>
          <div className="mb-4">
            <p className="mb-4 lbl">
              Total Acquisition @ {propertyData.LoantoValueRatioLVR}% LVR
            </p>
            <MySlider
              className="mt-4"
              value={propertyData.LoantoValueRatioLVR}
              min={1}
              onChange={(e: any) => {
                handlePropertyTypeChanges({
                  propertyType: propertyData.propertyType,
                  LoantoValueRatioLVR: Number(e.value),
                  RiskFactoredYieldReturn: propertyData.RiskFactoredYieldReturn,
                  mortgageApply:
                    Number(e.value) >= 80
                      ? propertyData.mortgageInsuranceApply
                      : "N",
                });
              }}
            />
            <div className="flex items-center justify-between w-full mt-4 ">
              <span className="">{propertyData.LoantoValueRatioLVR}%</span>
              <InputText
                style={{ width: "5rem", height: "36px", fontSize: "12px" }}
                onFocus={(e: any) => (e.target.value = "")}
                value={String(propertyData.LoantoValueRatioLVR)}
                onChange={(e: any) => {
                  let value = Number(e?.target?.value);
                  handlePropertyTypeChanges({
                    propertyType: propertyData.propertyType,
                    LoantoValueRatioLVR: value,
                    RiskFactoredYieldReturn:
                      propertyData.RiskFactoredYieldReturn,
                    mortgageApply:
                      value >= 80 ? propertyData.mortgageInsuranceApply : "N",
                  });
                }}
              />
            </div>
          </div>
          <div className="mb-4 ">
            <p className="mb-4 lbl">Risk Factored Yield Return</p>
            <MySlider
              className="mt-4"
              min={1}
              max={10}
              step={0.01}
              value={propertyData.RiskFactoredYieldReturn}
              onChange={(e: any) => {
                handlePropertyTypeChanges({
                  propertyType: propertyData.propertyType,
                  LoantoValueRatioLVR: propertyData.LoantoValueRatioLVR,
                  RiskFactoredYieldReturn: Number(e.value),
                  mortgageApply: propertyData.mortgageInsuranceApply,
                });
              }}
            />
            <div className="flex items-center justify-between w-full mt-4">
              <span className="">{propertyData.RiskFactoredYieldReturn}%</span>
              <InputNumbers
                className="generator-input"
                onFocus={(e: any) => (e.target.value = "")}
                value={propertyData.RiskFactoredYieldReturn}
                onChange={(e: any) => {
                  handlePropertyTypeChanges({
                    propertyType: propertyData.propertyType,
                    LoantoValueRatioLVR: propertyData.LoantoValueRatioLVR,
                    RiskFactoredYieldReturn: Number(e.value),
                    mortgageApply: propertyData.mortgageInsuranceApply,
                  });
                }}
              />
            </div>
          </div>

          <div className="mb-4">
            <p className="mb-4 lbl">Debt Interest Rate</p>
            <MySlider
              className="mt-4"
              step={0.025}
              min={0}
              max={16}
              value={propertyData.DebtInterestRate}
              onChange={(e: any) => {
                const {
                  TotalInterestPayable,
                  TotalPricipalPayable,
                  CashflowAnnual,
                  CashflowMonthly,
                  ReturnonEquityCOC,
                } = CalculateHandleRates({
                  DebtInterestRate: Number(e.value),
                  DebtPrincipleRate: propertyData.DebtPrincipleRate,
                  EquityInterestRate: propertyData.EquityInterestRate,
                  EquityPrincipleRate: propertyData.EquityPrincipleRate,
                  TotalDebt: propertyData.TotalDebt,
                  TotalDepositEquity: propertyData.TotalDepositEquity,
                });
                setpropertyData({
                  ...propertyData,
                  DebtInterestRate: e.value,
                  interestRate: e.value,
                  InterestPayments: TotalInterestPayable,
                  PrinciplePayments: TotalPricipalPayable,
                  CashflowAnnual,
                  CashflowMonthly,
                  ReturnonEquityCOC,
                });
              }}
            />
            <div className="flex items-center justify-between w-full mt-4 ">
              <span className="">{propertyData.DebtInterestRate}%</span>
              <InputNumbers
                className="generator-input"
                mode="decimal"
                minFractionDigits={1}
                style={{ width: "5rem" }}
                onFocus={(e: any) => (e.target.value = "")}
                value={propertyData.DebtInterestRate}
                onChange={(e: any) => {
                  const {
                    TotalInterestPayable,
                    TotalPricipalPayable,
                    CashflowAnnual,
                    CashflowMonthly,
                    ReturnonEquityCOC,
                  } = CalculateHandleRates({
                    DebtInterestRate: Number(e.value),
                    DebtPrincipleRate: propertyData.DebtPrincipleRate,
                    EquityInterestRate: propertyData.EquityInterestRate,
                    EquityPrincipleRate: propertyData.EquityPrincipleRate,
                    TotalDebt: propertyData.TotalDebt,
                    TotalDepositEquity: propertyData.TotalDepositEquity,
                  });
                  setpropertyData({
                    ...propertyData,
                    DebtInterestRate: e.value,
                    interestRate: e.value,
                    InterestPayments: TotalInterestPayable,
                    PrinciplePayments: TotalPricipalPayable,
                    CashflowAnnual,
                    CashflowMonthly,
                    ReturnonEquityCOC,
                  });
                }}
              />
            </div>
          </div>
          <div className="mb-4">
            <p className="mb-4 lbl">Debt Principal Rate</p>
            <MySlider
              className="mt-4"
              step={0.025}
              min={0}
              max={16}
              value={propertyData.DebtPrincipleRate}
              onChange={(e: any) => {
                const {
                  TotalInterestPayable,
                  TotalPricipalPayable,
                  CashflowAnnual,
                  CashflowMonthly,
                  ReturnonEquityCOC,
                } = CalculateHandleRates({
                  DebtInterestRate: propertyData.DebtInterestRate,
                  DebtPrincipleRate: Number(e.value),
                  EquityInterestRate: propertyData.EquityInterestRate,
                  EquityPrincipleRate: propertyData.EquityPrincipleRate,
                  TotalDebt: propertyData.TotalDebt,
                  TotalDepositEquity: propertyData.TotalDepositEquity,
                });
                setpropertyData({
                  ...propertyData,
                  DebtPrincipleRate: e.value,
                  InterestPayments: TotalInterestPayable,
                  PrinciplePayments: TotalPricipalPayable,
                  CashflowAnnual,
                  CashflowMonthly,
                  ReturnonEquityCOC,
                });
              }}
            />
            <div className="flex items-center justify-between w-full mt-4 ">
              <span>{propertyData.DebtPrincipleRate}%</span>
              <InputNumbers
                mode="decimal"
                className="generator-input"
                minFractionDigits={0}
                style={{ width: "5rem" }}
                onFocus={(e: any) => (e.target.value = "")}
                value={propertyData.DebtPrincipleRate}
                onChange={(e: any) => {
                  const {
                    TotalInterestPayable,
                    TotalPricipalPayable,
                    CashflowAnnual,
                    CashflowMonthly,
                    ReturnonEquityCOC,
                  } = CalculateHandleRates({
                    DebtInterestRate: propertyData.DebtInterestRate,
                    DebtPrincipleRate: Number(e.value),
                    EquityInterestRate: propertyData.EquityInterestRate,
                    EquityPrincipleRate: propertyData.EquityPrincipleRate,
                    TotalDebt: propertyData.TotalDebt,
                    TotalDepositEquity: propertyData.TotalDepositEquity,
                  });
                  setpropertyData({
                    ...propertyData,
                    DebtPrincipleRate: e.value,
                    InterestPayments: TotalInterestPayable,
                    PrinciplePayments: TotalPricipalPayable,
                    CashflowAnnual,
                    CashflowMonthly,
                    ReturnonEquityCOC,
                  });
                }}
              />
            </div>
          </div>
          <div className="mb-4">
            <p className="mb-4 lbl">Equity Interest Rate </p>
            <MySlider
              className="mt-4"
              step={0.025}
              min={0}
              max={16}
              value={propertyData.EquityInterestRate}
              onChange={(e: any) => {
                const {
                  TotalInterestPayable,
                  TotalPricipalPayable,
                  CashflowAnnual,
                  CashflowMonthly,
                  ReturnonEquityCOC,
                } = CalculateHandleRates({
                  DebtInterestRate: propertyData.DebtInterestRate,
                  DebtPrincipleRate: propertyData.DebtPrincipleRate,
                  EquityInterestRate: Number(e.value),
                  EquityPrincipleRate: propertyData.EquityPrincipleRate,
                  TotalDebt: propertyData.TotalDebt,
                  TotalDepositEquity: propertyData.TotalDepositEquity,
                });
                setpropertyData({
                  ...propertyData,
                  EquityInterestRate: e.value,
                  InterestPayments: TotalInterestPayable,
                  PrinciplePayments: TotalPricipalPayable,
                  CashflowAnnual,
                  CashflowMonthly,
                  ReturnonEquityCOC,
                });
              }}
            />
            <div className="flex items-center justify-between w-full mt-4 ">
              <span>{propertyData.EquityInterestRate}%</span>
              <InputNumbers
                className="generator-input"
                onFocus={(e: any) => (e.target.value = "")}
                minFractionDigits={1}
                style={{ width: "5rem" }}
                value={propertyData.EquityInterestRate}
                onChange={(e: any) => {
                  const {
                    TotalInterestPayable,
                    TotalPricipalPayable,
                    CashflowAnnual,
                    CashflowMonthly,
                    ReturnonEquityCOC,
                  } = CalculateHandleRates({
                    DebtInterestRate: propertyData.DebtInterestRate,
                    DebtPrincipleRate: propertyData.DebtPrincipleRate,
                    EquityInterestRate: Number(e.value),
                    EquityPrincipleRate: propertyData.EquityPrincipleRate,
                    TotalDebt: propertyData.TotalDebt,
                    TotalDepositEquity: propertyData.TotalDepositEquity,
                  });
                  setpropertyData({
                    ...propertyData,
                    EquityInterestRate: e.value,
                    InterestPayments: TotalInterestPayable,
                    PrinciplePayments: TotalPricipalPayable,
                    CashflowAnnual,
                    CashflowMonthly,
                    ReturnonEquityCOC,
                  });
                }}
              />
            </div>
            <div className="flex">
              <div className="eclipse"></div>
              <span className="fixed-percentage">Fixed percentage</span>
            </div>
          </div>
          <div className="mb-4">
            <p className="mb-4 lbl">Equity Principal Rate </p>
            <MySlider
              className="mt-4"
              step={0.025}
              min={0}
              max={16}
              value={propertyData.EquityPrincipleRate}
              onChange={(e: any) => {
                const {
                  TotalInterestPayable,
                  TotalPricipalPayable,
                  CashflowAnnual,
                  CashflowMonthly,
                  ReturnonEquityCOC,
                } = CalculateHandleRates({
                  DebtInterestRate: propertyData.DebtInterestRate,
                  DebtPrincipleRate: propertyData.DebtPrincipleRate,
                  EquityInterestRate: propertyData.EquityInterestRate,
                  EquityPrincipleRate: Number(e.value),
                  TotalDebt: propertyData.TotalDebt,
                  TotalDepositEquity: propertyData.TotalDepositEquity,
                });
                setpropertyData({
                  ...propertyData,
                  EquityPrincipleRate: e.value,
                  InterestPayments: TotalInterestPayable,
                  PrinciplePayments: TotalPricipalPayable,
                  CashflowAnnual,
                  CashflowMonthly,
                  ReturnonEquityCOC,
                });
              }}
            />
            <div className="flex items-center justify-between w-full mt-4 ">
              <span>{propertyData.EquityPrincipleRate}%</span>
              <InputNumbers
                className="generator-input"
                onFocus={(e: any) => (e.target.value = "")}
                minFractionDigits={1}
                style={{ width: "5rem" }}
                value={propertyData.EquityPrincipleRate}
                onChange={(e: any) => {
                  const {
                    TotalInterestPayable,
                    TotalPricipalPayable,
                    CashflowAnnual,
                    CashflowMonthly,
                    ReturnonEquityCOC,
                  } = CalculateHandleRates({
                    DebtInterestRate: propertyData.DebtInterestRate,
                    DebtPrincipleRate: propertyData.DebtPrincipleRate,
                    EquityInterestRate: propertyData.EquityInterestRate,
                    EquityPrincipleRate: Number(e.value),
                    TotalDebt: propertyData.TotalDebt,
                    TotalDepositEquity: propertyData.TotalDepositEquity,
                  });
                  setpropertyData({
                    ...propertyData,
                    EquityPrincipleRate: e.value,
                    InterestPayments: TotalInterestPayable,
                    PrinciplePayments: TotalPricipalPayable,
                    CashflowAnnual,
                    CashflowMonthly,
                    ReturnonEquityCOC,
                  });
                }}
              />
            </div>
            <div className="flex">
              <div className="eclipse"></div>
              <span className="fixed-percentage">Fixed percentage</span>
            </div>
          </div>
          <div className="mb-4">
            <p className="mb-4 lbl">Outgoing Expenses </p>
            <MySlider
              className="mt-4"
              step={0.025}
              min={0}
              max={100}
              value={propertyData.outgoingExpensePercent}
              onChange={(e: any) => {
                setpropertyData({
                  ...propertyData,
                  outgoingExpensePercent: e.value,
                  outgoingExpenses:
                    propertyData.PropertyRentIncomeYear1 *
                    Number(e.value / 100 || 0),
                });
              }}
            />
            <div className="flex items-center justify-between w-full mt-4 ">
              <span>{propertyData.outgoingExpensePercent}%</span>
              <InputNumbers
                className="generator-input"
                onFocus={(e: any) => (e.target.value = "")}
                minFractionDigits={1}
                style={{ width: "5rem" }}
                value={propertyData.outgoingExpensePercent}
                onChange={(e: any) => {
                  setpropertyData({
                    ...propertyData,
                    outgoingExpensePercent: e.value,
                    outgoingExpenses:
                      propertyData.PropertyRentIncomeYear1 *
                      Number(e.value / 100 || 0),
                  });
                }}
              />
            </div>
            <div className="flex">
              <div className="eclipse"></div>
              <span className="fixed-percentage">Fixed percentage</span>
            </div>
          </div>
          <div className="mb-4">
            <span className="lbl">Mortgage Insurance apply?</span>
            <div className="flex">
              <div className="field-radiobutton radiomargin">
                <RadioButton
                  inputId="mortgageInsuranceApply1"
                  name="mortgageInsuranceApply"
                  value="Y"
                  onChange={(e) => {
                    if (propertyData.LoantoValueRatioLVR >= 80) {
                      setpropertyData({
                        ...propertyData,
                        mortgageInsuranceApply: e.value,
                      });
                    } else {
                      NotificationManager.error(
                        "Your property LVR should be greater than or equal to 80",
                        "",
                        2000
                      );
                    }
                  }}
                  checked={propertyData.mortgageInsuranceApply === "Y"}
                />
                <label htmlFor="useExtraCash1">Yes</label>
              </div>
              <div className="field-radiobutton radiomargin">
                <RadioButton
                  inputId="mortgageInsuranceApply2"
                  name="mortgageInsuranceApply"
                  value="N"
                  onChange={(e) => {
                    setpropertyData({
                      ...propertyData,
                      mortgageInsuranceApply: e.value,
                      LMI: 0,
                    });
                  }}
                  checked={propertyData.mortgageInsuranceApply === "N"}
                />
                <label htmlFor="useExtraCash2">No</label>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <GradientButton
            width="124px"
            icon={undefined}
            txt={
              PropertiesData[propertyIdNumber].isLocked
                ? "Updates Locked"
                : "Lock Updates"
            }
            onClick={() => {
              setLoading(true);
              if (PropertiesData[propertyIdNumber].isLocked) {
                UnLockProperty();
              } else {
                LockProperty();
              }
              setTimeout(() => {
                setLoading(false);
              }, 2000);
            }}
          />
          {loading == true && (
            <div className="px-1">
              <Loading height="0" />
            </div>
          )}
        </div>
      </div>

      <NotificationContainer />
    </div>
  );
};

export default PropertyDetail;
