import React, { useRef, useState } from 'react'
import { CustomToast } from "global.style"
import { useNavigate } from 'react-router-dom';
import { Button, Form, InputGroup } from "react-bootstrap";
import classes from "stylesheet.module.css"
import { Link } from 'react-router-dom';
import { checkPasswordStrength, isEmailValid } from 'utils/helperFunctions';
import PasswordCheck from '../PasswordCheck';
import { TUserSignup } from 'types';
import { UserSignup } from 'store/Slices/AuthSlice';
const SignupForm = () => {
    const navigate = useNavigate();
    const toast = useRef<any>();
    const [userdata, setuserdata] = useState<TUserSignup>({
        first_name: "",
        last_name: "",
        email: "",
        mobile_no: "+61",
        telephone_no: "",
        contact_type: "Contact Paid",
        password: "",
        agent: "",
        preferred_contact_method: "",
        photo: "",
        role_id: "6193a50669fff80382457d27",
        office_id: "610a0f1dc61edfcf62d62c52",
    });
    const [ShowPassword, setShowPassword] = useState(false);
    const [ShowPassword1, setShowPassword1] = useState(false);
    const [password, setPassword] = useState("");
    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const { valid } = checkPasswordStrength(userdata.password)
        if (!(isEmailValid(userdata.email))) {
            showSuccess("Please provide an valid email.");
        } else if (userdata.password !== password) {
            showSuccess("password donot match");
        } else if (valid === false) {
            showPassMessage(userdata.password)
        } else if (userdata.first_name.length === 0) {
            showSuccess("Firstname should not be empty");
        } else if (userdata.last_name.length === 0) {
            showSuccess("Lastname should not be empty");
        } else {
           let result=await UserSignup(userdata);
           if(result.insertedId){
            showSuccess(result.message);
            navigate("/signin")
           }else{
            showSuccess(result.message);
           }
           
        }

    }
    const showSuccess = (Message: string) => {
        toast.current.show({
            severity: "warn",
            life: 2000,
            content: (
                <div
                    className="flex flex-column "
                    style={{ paddingTop: "0px", paddingBottom: "0px", width: "100%" }}
                >
                    <div className="text-center ">

                        <p className="popup-warn-text">
                            {Message}
                        </p>
                    </div>
 
                </div>
            ),
        });
    }
    const showPassMessage = (password: string) => {
        let {  message } = checkPasswordStrength(password)
        return message
    }  
    const ChangePhoneFormat = () => {
        if (userdata.mobile_no[0] !== "+") {
            if (userdata.mobile_no[0] === "0") {
                let newnumber = "+61" + userdata.mobile_no.slice(1, userdata.mobile_no.length)
                setuserdata({ ...userdata, mobile_no: newnumber })
            } else {
                let newnumber = "+61" + userdata.mobile_no
                setuserdata({ ...userdata, mobile_no: newnumber })
            }
        }

    }
    return (
        <>
            <CustomToast
                ref={toast}
                position="center"
                style={{ zIndex: "2", opacity: "1" }}
            />
            <Form
                className="mt-[20px]"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(e);
                }}
            >
                <Form.Group className="mb-3" controlId="signinForm.first_name">
                    <Form.Control
                        type="text"
                        value={userdata.first_name}
                        onChange={(e) =>
                            setuserdata({ ...userdata, first_name: e.target.value })
                        }
                        className={classes.EmailInput}
                        placeholder="First Name"
                        required
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="signinForm.last_name">
                    <Form.Control
                        type="text"
                        value={userdata.last_name}
                        onChange={(e) =>
                            setuserdata({ ...userdata, last_name: e.target.value })
                        }
                        className={classes.EmailInput}
                        placeholder="Last Name"
                        required
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="signinForm.email">
                    <Form.Control
                        type="email"
                        value={userdata.email}
                        onChange={(e) =>
                            setuserdata({ ...userdata, email: e.target.value })
                        }
                        className={classes.EmailInput}
                        placeholder="Email Address"
                        required
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="signinForm.mobile_no">
                    <Form.Control
                        type="text"
                        onBlur={ChangePhoneFormat}
                        value={userdata.mobile_no}
                        onChange={(e) =>
                            setuserdata({ ...userdata, mobile_no: e.target.value })
                        }
                        className={classes.EmailInput}
                        placeholder="Mobile No"
                        required
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="signinForm.password">
                    <InputGroup className={classes.PasswordInputGroup}>
                        <Form.Control
                            type={ShowPassword ? "text" : "password"}
                            value={userdata.password}
                            onChange={(e) =>
                                setuserdata({ ...userdata, password: e.target.value })
                            }
                            className={classes.PasswordInput}
                            placeholder="Password"
                            required
                        />
                        <InputGroup.Text className={classes.passwordShowHide}>
                            <span
                                onClick={() => setShowPassword(!ShowPassword)}
                                className={classes.showHideBtn}
                            >
                                {ShowPassword ? "Hide" : "Show"}
                            </span>
                        </InputGroup.Text>
                    </InputGroup>

                    <PasswordCheck password={userdata.password} />


                    <p className={`${showPassMessage(userdata.password)}`} style={{ fontSize: '13px', marginTop: '5px' }}>
                        {showPassMessage(userdata.password)}
                    </p>
                </Form.Group>
                <Form.Group className="mb-3" controlId="password">
                    <InputGroup className={classes.PasswordInputGroup}>
                        <Form.Control
                            type={ShowPassword1 ? "text" : "password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className={classes.PasswordInput}
                            placeholder="Confirm Password"
                            required
                        />
                        <InputGroup.Text className={classes.passwordShowHide}>
                            <span
                                onClick={() => setShowPassword1(!ShowPassword1)}
                                className={classes.showHideBtn}
                            >
                                {ShowPassword1 ? "Hide" : "Show"}
                            </span>
                        </InputGroup.Text>

                    </InputGroup>

                </Form.Group>

                <Form.Group className="mb-3" controlId="signinForm.checkbox">
                    <Form.Check className={classes.FormcheckboxContainer}>
                        <Form.Check.Input
                            type={"checkbox"}
                            //   checked={Agree} 
                            //   onChange={() => setAgree(!Agree)}
                            className={classes.CheckboxInput}
                            required
                        />
                        <Form.Check.Label className={classes.CheckboxLabel}>
                            I agree to all the statements included in the&nbsp;
                            <Link to="/Terms" className={`${classes.link} underline`}>
                                Terms of use / Privacy policy.
                            </Link>
                        </Form.Check.Label>
                    </Form.Check>
                </Form.Group>
                <Button className={classes.loginBtn} type="submit">
                    Sign Up
                </Button>
            </Form>
        </>
    )
}

export default SignupForm
