import {
  TAllContacts,
  TPPR,
  TInvestProperties,
  TCreditCard,
  TExpenseDetails,
  TStrategicSpending,
  TBorrowingCalculator,
  TBorrowIncomeDetails,
  TSpedingTableValueType,
  TSpendingSheet,
  TPropertyGeneratorItem,
} from "../types";
import { TExpandedRows } from "hooks/useGenerateSpendingData";
import { TCalculatorData } from "store/Slices/CalculatorSlice";
import { ISpendingTableData } from "hooks/useGenerateSpendingData";
import { ISpendingRowsData } from "hooks/useGenerateSpendingData";
import { TUpdateUser } from "store/Slices/UserSlice";
import { GS } from "services/global";

//@ts-ignore
import moment from "moment";
//@ts-ignore
import { ppmt } from "financial";
export const SeparateCamelCase = (text: string = "") => {
  return text?.replace(/([a-z])([A-Z])/g, "$1 $2");
};
export const getMonthName = (i: number) => {
  var month = new Array(12);
  month[0] = "Jan";
  month[1] = "Feb";
  month[2] = "Mar";
  month[3] = "Apr";
  month[4] = "May";
  month[5] = "Jun";
  month[6] = "Jul";
  month[7] = "Aug";
  month[8] = "Sep";
  month[9] = "Oct";
  month[10] = "Nov";
  month[11] = "Dec";
  return month[i];
};
function TaxLookUp(number: any, columnIndex: any) {
  var data = [
    [0, 0, 0],
    [18201, 0, 0.19],
    [45001, 5092, 0.325],
    [120001, 29467, 0.37],
    [180001, 51667, 0.45],
  ];
  var result = data.find(function (row: any, index: any, array: any) {
    if (index === 0) {
      return false; // Skip the first row since there is no previous number to compare with
    }

    var previousNumber = array[index - 1][0];
    var currentNumber = row[0];

    if (index === array.length - 1) {
      return previousNumber < number && number >= currentNumber;
    }

    var nextNumber = array[index + 1][0];

    return (
      previousNumber < number && number >= currentNumber && number < nextNumber
    );
  });

  if (result) {
    var rate = result[2];
    return rate;
  }

  return null;
}
export const ContainsLetter = (str: string) => {
  var pattern = /[a-zA-Z]/; // Regular expression pattern to match any letter

  return pattern.test(str);
};
export const ContainsNumber = (str: string) => {
  var pattern = /[0-9]/; // Regular expression pattern to match any letter

  return pattern.test(str);
};
export const parseErrors = (errObj: any) => {
  try {
    let message = "";
    switch (errObj.status) {
      case 401:
        if (!window.location.href.includes("login")) {
          sessionStorage.removeItem("Token");
          window.location.href = "/";
        }
        return {
          success: false,
          message:
            errObj.data && errObj.data.message
              ? errObj.data.message
              : "You are not authorized. Please login",
        };
      case 403:
      case 404:
      case 409:
      case 422:
        if (errObj.data && errObj.data.message) {
          message = errObj.data.message;
        } else if (errObj.message) {
          message = errObj.message;
        } else {
          message = "An error occured while processing your request.";
        }
        return {
          success: false,
          message,
        };

      default:
        return {
          success: false,
          message: "An error occured while processing your request.",
        };
    }
  } catch (error) {
    return {
      success: false,
      message: "An error occured while processing your request.",
    };
  }
};
export const CurrencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
export const ValidateName = (name: string) => {
  if (name.length === 0) {
    return false;
  } else {
    return true;
  }
};
export const ValidateEmail = (mail: string) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }
  return false;
};
export const isEmailValid = (email: string) => {
  var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.[a-zA-Z]{2,3})+$/;
  return pattern.test(email);
};
export const validateImage = (img: any) => {
  const base64regex = new RegExp("/[A-Za-z0-9+/=]/");
  if (base64regex.test(img)) {
    return true;
  } else {
    return false;
  }
};
export const commaSeparators = (num: number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(num || 0);
};
export const commaStringSeparators = (
  num: number,
  minimumFractionDigits: number = 2
) => {
  const roundedNumber = num?.toFixed(2);
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: minimumFractionDigits,
  }).format(parseFloat(roundedNumber) || 0);
};
export const commaStringSeparatorsWithDollors = (num: number) => {
  // Round the number to two decimal places
  const roundedNumber = num?.toFixed(2);
  const formattedNumber = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Math.abs(parseFloat(roundedNumber)) || 0);
  return num < 0 ? `-$${formattedNumber}` : `$${formattedNumber}`;
};
export const checkPasswordStrength = (password: string) => {
  let count = 0;

  if (ContainsLetter(password)) {
    count++;
  }
  if (ContainsNumber(password)) {
    count++;
  }
  if (password.length >= 8) {
    count++;
  }
  if (count === 3) {
    return { valid: count === 3, message: "" };
  } else {
    if (password.length === 0) {
      return { valid: count === 3, message: "" };
    } else {
      return { valid: count === 3, message: "Weak Password" };
    }
  }
};
export function getBase64ImageType(base64String: string) {
  const header = base64String.substring(0, 30); // Only examine the first 30 characters of the string
  if (header.includes("data:image/jpeg;base64,")) {
    return "jpeg";
  } else if (header.includes("data:image/png;base64,")) {
    return "png";
  } else {
    throw new Error("Unsupported image type");
  }
}
export const formatPhoneNumber = (num: string) => {
  if (!num) return num;
  const phoneNumber = num.replace(/[^\d]/g, "");
  if (phoneNumber.length < 4) return phoneNumber;
  if (phoneNumber.length < 7) {
    return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(
      4,
      phoneNumber.length
    )}`;
  }
  return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(
    4,
    7
  )} ${phoneNumber.slice(7, phoneNumber.length)}`;
};

export const SpaceRemove = (val: string) => {
  const replaced = val.replaceAll(" ", "");
  return replaced;
};
export const SplitContactType = (value: string) => {
  if (value?.includes("Contact")) {
    let newvalue = value.split(" ")[1];
    return newvalue;
  } else {
    return value;
  }
};
export const SortContact = (contacts: TAllContacts[]) => {
  const data = contacts?.sort(function (a: TAllContacts, b: TAllContacts) {
    var textA = a.first_name.toUpperCase();
    var textB = b.first_name.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
  return data;
};
const splitFields = (name: string) => {
  let namesarr = name.split("_");
  let newString = "";
  if (namesarr.length > 0) {
    namesarr.map((item, index) => {
      let newitem = item[0].toUpperCase() + item.slice(1, item.length);
      if (index === 0) {
        newString = newitem;
      } else {
        newString = newString + " " + newitem;
      }
    });
    return newString;
  }
  return "";
};
export const checkEmptyFields = (requiredFields: string[], obj: any) => {
  for (const fieldName of requiredFields) {
    if (!obj[fieldName]) {
      let updatedFieldname = splitFields(fieldName);
      return updatedFieldname; // Required field is empty
    }
  }
  return null;
};
export const checkFieldLength = (value: string, minLength: number) => {
  if (value.length < minLength) {
    return false;
  } else {
    return true;
  }
};
export const handleContactFormErrors = (userdata: TUpdateUser) => {
  const requiredFields = ["first_name", "last_name", "email", "password"];
  let minFieldLength: number = 3;
  if (checkEmptyFields(requiredFields, userdata)) {
    return {
      error: true,
      message: `${checkEmptyFields(requiredFields, userdata)} is required`,
    };
  } else {
    if (!checkFieldLength(userdata.first_name, minFieldLength)) {
      return {
        error: true,
        message: `Name contain atleast ${minFieldLength} characters`,
      };
    } else if (!checkFieldLength(userdata.last_name, minFieldLength)) {
      return {
        error: true,
        message: `Name contain atleast ${minFieldLength} characters`,
      };
    } else if (!ValidateEmail(userdata.email)) {
      return { error: true, message: `Invalid Email format` };
    } else {
      return { error: false, message: `` };
    }
  }
};
export const ChangePhoneFormat = (mobileno: string) => {
  let newnumber = mobileno;
  if (mobileno[0] !== "+") {
    if (mobileno[0] === "0") {
      newnumber = "+61" + mobileno.slice(1, mobileno.length);
    } else {
      newnumber = "+61" + mobileno;
    }
  }
  return newnumber;
};
export const TotalUseableEquity = (
  PPRData: TPPR,
  Properties: TInvestProperties[]
) => {
  let PPREquity = PPRData.AutoValuation * 0.8 - PPRData.Mortgage;
  PPREquity = PPREquity < 0 ? 0 : PPREquity;
  let propertiesEquity = Properties.reduce(
    (total: number, item: TInvestProperties) => {
      let equity = item.AutoValuation * 0.8 - item.Mortgage;
      return (total += equity < 0 ? 0 : equity);
    },
    0
  );
  return PPREquity + propertiesEquity;
};
export const CalculateRemainingLoanTerm = (
  purchaseData: string,
  LoanTerms: number
) => {
  var formats = [moment.ISO_8601, "DD/MM/YYYY"];
  let check = moment(purchaseData, formats, true).isValid();

  if (check) {
    let formatDate = purchaseData.split("/");
    let newDate = formatDate[2] + "/" + formatDate[1] + "/" + formatDate[0];
    var purchaseDate = new Date(newDate);
    var todayDate = new Date();

    var diff =
      (todayDate.getTime() - purchaseDate.getTime()) /
      (1000 * 60 * 60 * 24) /
      365;
    var daydiff = diff.toFixed(1);

    return LoanTerms - Number(daydiff);
  } else {
    return 30;
  }
};
export const CalcuateFinanceTotalDebt = (
  PPRData: TPPR,
  Properties: TInvestProperties[],
  CreditCard: TCreditCard[]
) => {
  let propertydebt = Properties.reduce(
    (total: number, item: TInvestProperties) => {
      return (total += item.Mortgage);
    },
    0
  );
  let creditcarddebt = CreditCard.reduce((total: number, item: TCreditCard) => {
    return (total += item.currentDebt);
  }, 0);
  return PPRData.Mortgage + propertydebt + creditcarddebt;
};
function rounddown(value: any, decimalPlaces: any) {
  const factor = Math.pow(10, decimalPlaces);
  return Math.floor(value * factor) / factor;
}
export const getTaxIncome = (number: any) => {
  let getTaxRate: any = TaxLookUp(number, 3);

  // var result = rounddownToNearestTenThousand(number / (1 -  getTaxRate));
  let C3 = Math.round(number / (1 - getTaxRate));
  var result = rounddown(C3, -4);

  return result;
};
export const calculateTotalFinanceRepayment = (
  PPR: TPPR,
  Properties: TInvestProperties[]
) => {
  let homeloanRepayment = PPR?.LoanRepayments?.value;
  if (PPR?.LoanRepayments?.type === "Monthly") {
    homeloanRepayment = homeloanRepayment * 12;
  } else if (PPR?.LoanRepayments?.type === "Weekly") {
    homeloanRepayment = homeloanRepayment * 52;
  }
  let propertyLoanRepayment = 0;
  if (Properties?.length > 0) {
    propertyLoanRepayment = Properties?.reduce((total, item) => {
      let repayment_value = item.LoanRepayments?.value;
      if (item.LoanRepayments?.type === "Monthly") {
        repayment_value = repayment_value * 12;
      } else if (item.LoanRepayments?.type === "Weekly") {
        repayment_value = repayment_value * 52;
      }
      return total + repayment_value;
    }, 0);
  }

  return homeloanRepayment + propertyLoanRepayment;
};
const calculateAnnualExpense = (
  UseAverageAustralian: boolean,
  UseBasiq: boolean,
  LivingExpenses: number,
  BasiqExpenses: number,
  ManualExpense: number
) => {
  if (UseAverageAustralian) {
    return LivingExpenses;
  } else if (UseBasiq) {
    return BasiqExpenses;
  } else {
    return ManualExpense || 0;
  }
};
export const calculateMaxBorrowingCapacity = (
  PPR: TPPR,
  Properties: TInvestProperties[],
  propertyCashFlowIncome: number = 0,
  propertyno: number = 0,
  IncomeDetails: TBorrowIncomeDetails,
  CreditCards: TCreditCard[],
  UseAverageAustralian: boolean,
  UseBasiq: boolean,
  LivingExpenses: number,
  BasiqExpenses: number,
  DTI: number,
  ManualExpense: number
) => {
  let propertyGrossIncome = 0;
  if (Properties?.length > 0) {
    propertyGrossIncome = Properties?.reduce((total, item) => {
      return total + Number(item.GrossRent.value);
    }, 0);
  }
  let sum = 0;
  if (IncomeDetails.UserPartner) {
    sum =
      getTaxIncome(IncomeDetails?.YourIncome?.value) +
      getTaxIncome(IncomeDetails?.PartnerIncome?.value) +
      IncomeDetails?.OtherIncome?.value +
      propertyGrossIncome;
  } else {
    sum =
      getTaxIncome(IncomeDetails?.YourIncome?.value) +
      IncomeDetails?.OtherIncome?.value +
      propertyGrossIncome;
  }

  let total_card_limit = CreditCards?.reduce(
    (partialSum, nextItem) =>
      nextItem?.type === "Credit Card"
        ? partialSum + nextItem.limit
        : partialSum,
    0
  );
  let OtherLoanRepayments =
    CreditCards?.reduce(
      (partialSum, nextItem) =>
        nextItem?.type !== "Credit Card"
          ? partialSum + nextItem.minimumPayment
          : partialSum,
      0
    ) * 12;
  let totalpropertydebt = PPR?.Mortgage || 0;
  let totalestimatedoutgoings = 0;
  if (Properties?.length > 0) {
    let propertymortgage = 0;
    propertymortgage = Properties?.reduce((total, item) => {
      return total + item.Mortgage;
    }, 0);
    totalestimatedoutgoings = Properties?.reduce((total, item) => {
      return total + item.EstimatedOutgoings;
    }, 0);

    totalpropertydebt = totalpropertydebt + propertymortgage;
  }
  let annualexpenses = calculateAnnualExpense(
    UseAverageAustralian,
    UseBasiq,
    LivingExpenses,
    BasiqExpenses,
    ManualExpense
  );

  if (propertyno === 0) {
    let totalsum = sum * DTI;
    let totalLiabilitiesAndExpenses =
      annualexpenses +
      calculateTotalFinanceRepayment(PPR, Properties) +
      total_card_limit +
      OtherLoanRepayments +
      totalpropertydebt +
      totalestimatedoutgoings;
    let maxborrow = totalsum - totalLiabilitiesAndExpenses;
    return maxborrow;
  } else {
    const totalIncome = (sum + propertyCashFlowIncome) * DTI;
    let totalLiabilitiesAndExpenses =
      annualexpenses +
      calculateTotalFinanceRepayment(PPR, Properties) +
      total_card_limit +
      OtherLoanRepayments;
    let currentmbc = totalIncome - totalLiabilitiesAndExpenses;
    return currentmbc;
  }
};
const PMT = (rate: any, nper: any, pv: any, fv: any, type: any) => {
  if (!fv) fv = 0;
  if (!type) type = 0;

  if (rate === 0) return -(pv + fv) / nper;

  var pvif = Math.pow(1 + rate, nper);
  var pmt = (rate / (pvif - 1)) * -(pv * pvif + fv);

  if (type === 1) {
    pmt /= 1 + rate;
  }

  return pmt;
};
const IPMT = (pv: any, pmt: any, rate: any, per: any) => {
  var tmp = Math.pow(1 + rate, per);
  return 0 - (pv * tmp * rate + pmt * (tmp - 1));
};
const CalculateIMPT = (
  rate: any,
  per: any,
  nper: any,
  pv: any,
  fv?: any,
  type?: any
) => {
  if (per < 1 || per >= nper + 1) return null;
  var pmt = PMT(rate, nper, pv, fv, type);
  var ipmt = IPMT(pv, pmt, rate, per - 1);
  return ipmt;
};
const PPMT = (
  rate: any,
  per: any,
  nper: any,
  pv: any,
  fv?: any,
  type?: any
) => {
  if (per < 1 || per >= nper + 1) return null;
  var pmt = PMT(rate, nper, pv, fv, type);
  var ipmt = IPMT(pv, pmt, rate, per - 1);

  return pmt - ipmt;
};
const calculatePrincipalPayment = (
  rate: any,
  per: any,
  nper: any,
  pv: any,
  fv?: any,
  type?: any
) => {
  var pmt = PPMT(rate, per, nper, pv, fv, type);
  return Math.round(pmt || 0);
};
export const CalculateLoanRepayment = (
  data: TPPR | TInvestProperties
): { value: number; principalvalue: number; interestvalue: number } => {
  let interestRate = data.InterestRate;
  let yearsRemaining: number = data.RemainingLoanTerm;
  let paymentsPerYear: number = 0;
  if (data.LoanRepayments.type === "Annually") {
    paymentsPerYear = 1;
  } else if (data.LoanRepayments.type === "Monthly") {
    paymentsPerYear = 12;
  } else if (data.LoanRepayments.type === "Weekly") {
    paymentsPerYear = 52;
  }

  let totalpayments = yearsRemaining * paymentsPerYear;
  let principalvalue = 0;
  let interestvalue = CalculateIMPT(
    interestRate / 100 / paymentsPerYear,
    1,
    totalpayments,
    data.Mortgage
  );
  let interestAndPrincipalvalue =
    ppmt(interestRate / 100 / 12, 1, 30 * 12, -data.Mortgage) * 12 +
    data.Mortgage * (interestRate / 100);
  if (data.LoanRepayments.type === "Monthly") {
    interestAndPrincipalvalue = interestAndPrincipalvalue / 12;
  } else if (data.LoanRepayments.type === "Weekly") {
    interestAndPrincipalvalue = interestAndPrincipalvalue / 52;
  }
  principalvalue = interestAndPrincipalvalue - Math.abs(interestvalue || 0);
  if (data.InterestOnly) {
    return {
      value: Math.abs(interestvalue || 0),
      principalvalue: principalvalue,
      interestvalue: Math.abs(interestvalue || 0),
    };
  } else {
    return {
      value: interestAndPrincipalvalue || 0,
      principalvalue: principalvalue,
      interestvalue: Math.abs(interestvalue || 0),
    };
  }
};
export const CalculateNetPropertyIncome = (data: TInvestProperties): number => {
  let grossRentType = data.GrossRent.type;
  let grossRenValue = data.GrossRent.value;
  let LoanValue = data.LoanRepayments.value;
  let LoanType = data.LoanRepayments.type;
  let rentvalue =
    grossRentType === "Monthly"
      ? grossRenValue * 12
      : grossRentType === "Weekly"
      ? grossRenValue * 52
      : grossRentType === "Fornightly"
      ? grossRenValue * 26
      : grossRenValue;
  let loanvalue =
    LoanType === "Monthly"
      ? LoanValue * 12
      : LoanType === "Weekly"
      ? LoanValue * 52
      : LoanType === "Fornightly"
      ? LoanValue * 26
      : LoanValue;
  return rentvalue - (loanvalue + data.EstimatedOutgoings);
};
export const GetMultiplyValueByType = (type: string, value: number) => {
  if (type === "Monthly") {
    return value * 12;
  } else if (type === "Weekly") {
    return value * 52;
  } else if (type === "Fornightly") {
    return value * 26;
  } else {
    return value;
  }
};
export const GetDivideValueByType = (type: string, value: number) => {
  if (type === "Monthly") {
    return value / 12;
  } else if (type === "Weekly") {
    return value / 52;
  } else if (type === "Fornightly") {
    return value / 26;
  } else {
    return value;
  }
};
export const GetNetRentalIncome = (properties: TInvestProperties[]) => {
  let neticome = properties.reduce((total: number, item: TInvestProperties) => {
    return (total += item.NetPropertyIncome.value);
  }, 0);
  return neticome;
};
export const CalculateBasiqExpense = (
  basiqExpense: TExpenseDetails
): number => {
  const { Dependants, ...RestProps } = basiqExpense;
  let totalvalue = 0;
  Object.entries(RestProps).map(([key, value]) => {
    totalvalue += Number(value);
    return [key];
  });
  return totalvalue;
};
export const CalculateMonthlySavings = (
  totalIncome: number,
  livingExpenses: number,
  CreditCard: TCreditCard[],
  PPR: TPPR,
  InvestmentProperties: TInvestProperties[]
) => {
  let propertypaymentval = InvestmentProperties.reduce((prevItem, nextItem) => {
    return (prevItem +=
      nextItem.LoanRepayments.type === "Monthly"
        ? nextItem.LoanRepayments.value
        : nextItem.LoanRepayments.type === "Annually"
        ? nextItem.LoanRepayments.value / 12
        : (nextItem.LoanRepayments.value * 52) / 12);
  }, 0);
  let propertynetval = InvestmentProperties.reduce((prevItem, nextItem) => {
    if (nextItem.NetPropertyIncome.value < 0) {
      prevItem += Math.abs(nextItem.NetPropertyIncome.value / 12) | 0;
    }
    return prevItem;
  }, 0);
  let PPRRepaymentval =
    PPR.LoanRepayments.type === "Monthly"
      ? PPR.LoanRepayments.value
      : PPR.LoanRepayments.type === "Annually"
      ? PPR.LoanRepayments.value / 12
      : (PPR.LoanRepayments.value * 52) / 12;
  let cardPayments = CreditCard.reduce((total, item) => {
    return (total += item.minimumPayment);
  }, 0);
  let totalexpense =
    livingExpenses +
    cardPayments +
    PPRRepaymentval +
    propertypaymentval +
    propertynetval;
  //income-(liabilities+expenses)
  let monthlysaving = totalIncome - totalexpense;
  return monthlysaving;
};
export const CalculateAdminLiabilities = (
  CreditCards: TCreditCard[],
  PPR: TPPR,
  InvestmentProperties: TInvestProperties[]
) => {
  let propertypaymentval = InvestmentProperties.reduce((prevItem, nextItem) => {
    return (prevItem +=
      nextItem.LoanRepayments.type === "Monthly"
        ? nextItem.LoanRepayments.value
        : nextItem.LoanRepayments.type === "Annually"
        ? nextItem.LoanRepayments.value / 12
        : (nextItem.LoanRepayments.value * 52) / 12);
  }, 0);
  let PPRRepaymentval =
    PPR.LoanRepayments.type === "Monthly"
      ? PPR.LoanRepayments.value
      : PPR.LoanRepayments.type === "Annually"
      ? PPR.LoanRepayments.value / 12
      : (PPR.LoanRepayments.value * 52) / 12;
  let creditvalue = CreditCards?.reduce(
    (partialSum, nextItem) =>
      nextItem?.type === "Car Loan" || nextItem?.type === "Loan"
        ? partialSum + nextItem.minimumPayment
        : partialSum,
    0
  );

  return propertypaymentval + PPRRepaymentval; //+ creditvalue
};
export const CalculateClientLiabilities = (
  CreditCards: TCreditCard[],
  PPR: TPPR,
  InvestmentProperties: TInvestProperties[]
) => {
  let propertyloanval = InvestmentProperties.reduce((prevItem, nextItem) => {
    return (prevItem += nextItem.Mortgage);
  }, 0);
  let PPRLoanValue = PPR.Mortgage;
  let creditvalue = CreditCards?.reduce(
    (partialSum, nextItem) => partialSum + nextItem.currentDebt,
    0
  );
  return propertyloanval + PPRLoanValue + creditvalue;
};
export const CalculateMonthlyCashFlow = (
  Income: number,
  expenses: number,
  CreditCards: TCreditCard[],
  PPR: TPPR,
  InvestmentProperties: TInvestProperties[]
) => {
  let liabilities = CalculateAdminLiabilities(
    CreditCards,
    PPR,
    InvestmentProperties
  );

  return Income - (expenses + liabilities);
};
export const CreditCardItems = (CreditCards: TCreditCard[]) => {
  let total_card_limit = CreditCards?.reduce(
    (partialSum, nextItem) =>
      nextItem?.type === "Credit Card"
        ? partialSum + nextItem.limit
        : partialSum,
    0
  );

  let total_card_current_debt = CreditCards?.reduce(
    (partialSum, nextItem) =>
      nextItem?.type === "Credit Card"
        ? partialSum + nextItem.currentDebt
        : partialSum,
    0
  );

  let total_card_minimum_payment = CreditCards?.reduce(
    (partialSum, nextItem) =>
      nextItem?.type === "Credit Card" && nextItem?.owner === "Applicant"
        ? partialSum + nextItem.minimumPayment
        : partialSum,
    0
  );

  let total_card_minimum_payment_partner = CreditCards?.reduce(
    (partialSum, nextItem) =>
      nextItem?.type === "Credit Card" && nextItem?.owner === "Partner"
        ? partialSum + nextItem.minimumPayment
        : partialSum,
    0
  );
  let totalcreditCardLimit = CreditCards?.reduce(
    (partialSum, nextItem) => partialSum + nextItem.limit,
    0
  );
  let totalloan = CreditCards?.reduce(
    (partialSum, nextItem) =>
      nextItem?.type === "Car Loan" || nextItem?.type === "Loan"
        ? partialSum + nextItem.minimumPayment
        : partialSum,
    0
  );
  let loan = CreditCards?.reduce(
    (partialSum, nextItem) =>
      nextItem?.type === "Loan"
        ? partialSum + nextItem.minimumPayment
        : partialSum,
    0
  );
  return {
    total_card_current_debt,
    total_card_limit,
    total_card_minimum_payment,
    total_card_minimum_payment_partner,
    totalcreditCardLimit,
    totalloan,
    loan,
  };
};

export const GetTableData = (
  expandedRows: TExpandedRows[],
  Calstate: TCalculatorData,
  SpendingTableRowsValues: ISpendingRowsData,
  sheetmonths: any
) => {
  let tabledata: ISpendingTableData[] = [];
  let accountYour = Calstate.StrategicSpending.AccountYours;
  let accountPartner = Calstate.StrategicSpending.AccountPartner;
  let PPRLoanRepayments = Calstate.Equity.PPR.LoanRepayments;
  let PPRMortgageValue =
    PPRLoanRepayments.type === "Monthly"
      ? PPRLoanRepayments.value
      : PPRLoanRepayments.type === "Annually"
      ? PPRLoanRepayments.value / 12
      : (PPRLoanRepayments.value * 52) / 12;
  tabledata = [
    ...tabledata,
    {
      product: "Balance Sheet",
      headingName: "Balance Sheet",
      className: "!bg-[#e8f3ff]",
      values: SpendingTableRowsValues.balanceSheetArrValues,
      expanded: false,
    },
    {
      product: "Cash-on hand",
      headingName: "Cash On Hand",
      className: "!bg-[#a9c8ff]",
      values: SpendingTableRowsValues.cashOnHandArrValues,
      expanded: false,
    },
  ];
  if (accountYour > 0) {
    tabledata = [
      ...tabledata,
      {
        product: "Account-Yours",
        headingName: "Account (Yours)",
        className: "!bg-[#e8f3ff]",
        values: SpendingTableRowsValues.AccountYourArrValues,
        expanded: false,
      },
    ];
  }
  if (accountPartner > 0) {
    tabledata = [
      ...tabledata,
      {
        product: "Account-Partners",
        headingName: "Account (Partner)",
        className: "!bg-[#e8f3ff]",
        values: SpendingTableRowsValues.AccountPartnerArrValues,
        expanded: false,
      },
    ];
  }
  tabledata = [
    ...tabledata,
    {
      product: "Extra-Cash",
      headingName: "Extra Cash",
      className: "!bg-[#e8f3ff]",
      values: SpendingTableRowsValues.extraCashArrValues,
      expanded: false,
    },
    {
      product: "Liquid-Assets",
      headingName: "Liquid Assets",
      className: "!bg-[#e8f3ff]",
      values: SpendingTableRowsValues.liquidassetsArrValues,
      expanded: false,
    },
    {
      product: "PPR-Capital Growth Equity",
      headingName: "PPR - Capital Growth Equity",
      className: "!bg-[#e8f3ff]",
      values: SpendingTableRowsValues.PPRArrValues,
      expanded: false,
    },
  ];

  if (Calstate?.StrategicSpending?.EquityGains?.length > 1) {
    for (let i = 1; i < Calstate?.StrategicSpending?.EquityGains?.length; i++) {
      let arrvalues: any[] = [];
      let value =
        Calstate?.StrategicSpending?.EquityGains[i]?.value /
        Calstate?.StrategicSpending?.EquityGains[i]?.noOfMonths;
      let Today = moment();
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          { date: date, value: date === Today.format("MMM-YYYY") ? 0 : value },
        ];
      });

      tabledata = [
        ...tabledata,
        {
          product: `IP${i}-Capital Growth Equity`,
          headingName: `IP${i} - Capital Growth Equity`,
          className: "!bg-[#e8f3ff]",
          values: arrvalues,
          expanded: false,
        },
      ];
    }
  }
  tabledata = [
    ...tabledata,
    {
      product: "Equity-Gain",
      headingName: "Equity Gain",
      className: "!bg-[#e8f3ff]",
      values: SpendingTableRowsValues.EquityGainArrValues,
      expanded: true,
    },
  ];
  if (expandedRows[0].open) {
    tabledata = [
      ...tabledata,
      {
        product: "PPR-Principal",
        headingName: "PPR - Principal",
        className: "!bg-[#e8f3ff]",
        values: SpendingTableRowsValues.PPRPrincipalArrValues,
        expanded: false,
      },
    ];
    if (Calstate?.Equity?.Properties?.length > 0) {
      for (let i = 0; i < Calstate?.Equity?.Properties?.length; i++) {
        let arrvalues: any[] = [];
        let LoanRepayments = Calstate?.Equity?.Properties[i]?.LoanRepayments;
        let value =
          LoanRepayments.type === "Monthly"
            ? LoanRepayments.principalvalue
            : LoanRepayments.type === "Annually"
            ? LoanRepayments.principalvalue / 12
            : (LoanRepayments.principalvalue * 52) / 12;
        sheetmonths.map((date: any) => {
          arrvalues = [...arrvalues, { date: date, value: value }];
        });
        if (value !== 0) {
          tabledata = [
            ...tabledata,
            {
              product: `IP${i + 1}-Principal`,
              headingName: `IP${i + 1} - Principal`,
              className: "!bg-[#e8f3ff]",
              values: arrvalues,
              expanded: false,
            },
          ];
        }
      }
    }
  }
  tabledata = [
    ...tabledata,
    {
      product: "Monthly-Savings",
      headingName: "Monthly Savings",
      className: "!bg-[#e8f3ff] !uppercase !font-[600]",
      values: SpendingTableRowsValues.MonthlySavingsArrValues,
      expanded: false,
    },
    {
      product: "Income",
      headingName: "Income",
      className: "!bg-[#00a779] !text-[white] ",
      values: SpendingTableRowsValues.IncomeArrValues,
      expanded: true,
    },
  ];
  if (expandedRows[1].open) {
    if (Calstate.StrategicSpending.NetSalaryYours !== 0) {
      tabledata = [
        ...tabledata,
        {
          product: "Net-Salary(Yours)",
          headingName: "Net Salary (Yours)",
          className: "!bg-[#e8f3ff] ",
          values: SpendingTableRowsValues.NetSalaryYoursArrValues,
          expanded: false,
        },
      ];
    }
    if (Calstate.StrategicSpending.NetSalaryPartner !== 0) {
      tabledata = [
        ...tabledata,
        {
          product: "Net-Salary(Partner)",
          headingName: "Net Salary (Partner)",
          className: "!bg-[#e8f3ff]  ",
          values: SpendingTableRowsValues.NetSalaryPartnerArrValues,
          expanded: false,
        },
      ];
    }
    if (Calstate.StrategicSpending.RentalIncome !== 0) {
      tabledata = [
        ...tabledata,
        {
          product: "Rental-Income",
          headingName: "Rental Income",
          className: "!bg-[#e8f3ff] ",
          values: SpendingTableRowsValues.RentalIncomeArrValues,
          expanded: false,
        },
      ];
    }
    if (Calstate.StrategicSpending.OtherIncome !== 0) {
      tabledata = [
        ...tabledata,
        {
          product: "Other-Income",
          headingName: "Other Income",
          className: "!bg-[#e8f3ff]  ",
          values: SpendingTableRowsValues.OtherIncomeArrValues,
          expanded: false,
        },
      ];
    }
  }
  tabledata = [
    ...tabledata,
    {
      product: "Liabilities",
      headingName: "Liabilities",
      className: "!bg-[#fee468]",
      values: SpendingTableRowsValues.LiabilitiesArrValues,
      expanded: true,
    },
  ];
  if (
    Calstate?.BorrowingCalculator?.PersonalLiabilities?.CreditCards?.length > 0
  ) {
    Calstate?.BorrowingCalculator?.PersonalLiabilities?.CreditCards?.map(
      (item, index) => {
        let arrvalues: any[] = [];
        let name = `${item.type} ${item?.name ? "(" + item.name + ")" : ""} ${
          item?.owner ? "(" + item.owner + ")" : ""
        }`;
        let value = item.minimumPayment;
        sheetmonths.map((date: any) => {
          arrvalues = [...arrvalues, { date: date, value: value }];
        });
        tabledata = [
          ...tabledata,
          {
            product: `${name}`,
            headingName: `${name}`,
            className: "!bg-[#e8f3ff]",
            values: arrvalues,
            expanded: false,
          },
        ];
      }
    );
  }
  if (expandedRows[2].open) {
    if (PPRMortgageValue !== 0) {
      tabledata = [
        ...tabledata,
        {
          product: "PPR-Monthly Mortgage",
          headingName: "PPR Monthly Mortgage",
          className: "!bg-[var(--secondary-green)] ",
          values: SpendingTableRowsValues.PPRMortgageArrValues,
          expanded: false,
        },
      ];
    }
    if (Calstate.Equity.Properties.length > 0) {
      for (let i = 0; i < Calstate?.Equity?.Properties?.length; i++) {
        let arrvalues: any[] = [];
        let LoanRepayments = Calstate?.Equity?.Properties[i]?.LoanRepayments;
        let value =
          LoanRepayments.type === "Monthly"
            ? LoanRepayments.value
            : LoanRepayments.type === "Annually"
            ? LoanRepayments.value / 12
            : (LoanRepayments.value * 52) / 12;
        sheetmonths.map((date: any) => {
          arrvalues = [...arrvalues, { date: date, value: value }];
        });
        tabledata = [
          ...tabledata,
          {
            product: `IP${i + 1}-Mortgage`,
            headingName: `IP${i + 1} - Mortgage`,
            className: "!bg-[var(--secondary-green)]",
            values: arrvalues,
            expanded: false,
          },
        ];
      }
    }
  }
  tabledata = [
    ...tabledata,
    {
      product: "Living-Expenses",
      headingName: "Living Expenses",
      className: "!bg-[#cd4a5e] !text-[white]",
      values: SpendingTableRowsValues.TotalExpenseArrValues,
      expanded: true,
    },
  ];
  if (expandedRows[3].open) {
    const checkBasiqSelected = Calstate?.BorrowingCalculator?.UseBasiq;
    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails?.AlcoholicBeverages > 0 &&
      checkBasiqSelected
    ) {
      let alchohalarr: any[] = [];
      sheetmonths.map((date: any) => {
        alchohalarr = [
          ...alchohalarr,
          {
            date: date,
            value:
              Calstate?.BorrowingCalculator?.ExpenseDetails?.AlcoholicBeverages,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "Alcoholic-beverages",
          headingName: "Alcoholic beverages",
          className: "!bg-[#faedef] ",
          values: alchohalarr,
          expanded: false,
        },
      ];
    }
    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails?.ClothingAndFootwear > 0 &&
      checkBasiqSelected
    ) {
      let arrvalues: any[] = [];
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          {
            date: date,
            value:
              Calstate?.BorrowingCalculator?.ExpenseDetails
                ?.ClothingAndFootwear,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "Clothing-and-footwear",
          headingName: "Clothing and footwear",
          className: "!bg-[#faedef] ",
          values: arrvalues,
          expanded: false,
        },
      ];
    }
    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails?.Communication > 0 &&
      checkBasiqSelected
    ) {
      let arrvalues: any[] = [];
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          {
            date: date,
            value: Calstate?.BorrowingCalculator?.ExpenseDetails?.Communication,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "Communication",
          headingName: "Communication",
          className: "!bg-[#faedef] ",
          values: arrvalues,
          expanded: false,
        },
      ];
    }
    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails?.CurrentHousingCosts > 0 &&
      checkBasiqSelected
    ) {
      let arrvalues: any[] = [];
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          {
            date: date,
            value:
              Calstate?.BorrowingCalculator?.ExpenseDetails
                ?.CurrentHousingCosts,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "current-housing-costs",
          headingName: "Current housing costs",
          className: "!bg-[#faedef] ",
          values: arrvalues,
          expanded: false,
        },
      ];
    }
    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails?.DomesticFuelAndPower > 0 &&
      checkBasiqSelected
    ) {
      let arrvalues: any[] = [];
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          {
            date: date,
            value:
              Calstate?.BorrowingCalculator?.ExpenseDetails
                ?.DomesticFuelAndPower,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "domestic-fuel-and-power",
          headingName: "Domestic Fuel & Power",
          className: "!bg-[#faedef] ",
          values: arrvalues,
          expanded: false,
        },
      ];
    }
    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails?.Education > 0 &&
      checkBasiqSelected
    ) {
      let arrvalues: any[] = [];
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          {
            date: date,
            value: Calstate?.BorrowingCalculator?.ExpenseDetails?.Education,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "Education",
          headingName: "Education",
          className: "!bg-[#faedef] ",
          values: arrvalues,
          expanded: false,
        },
      ];
    }
    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails?.FoodNonAlcoholicBeverages >
        0 &&
      checkBasiqSelected
    ) {
      let arrvalues: any[] = [];
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          {
            date: date,
            value:
              Calstate?.BorrowingCalculator?.ExpenseDetails
                ?.FoodNonAlcoholicBeverages,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "Food-Non-Alcoholic-Beverages",
          headingName: "Food & Non-Alcoholic Beverages",
          className: "!bg-[#faedef] ",
          values: arrvalues,
          expanded: false,
        },
      ];
    }
    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails
        ?.HouseholdFurnishingsAndEquipment > 0 &&
      checkBasiqSelected
    ) {
      let arrvalues: any[] = [];
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          {
            date: date,
            value:
              Calstate?.BorrowingCalculator?.ExpenseDetails
                ?.HouseholdFurnishingsAndEquipment,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "Household-Furnishings-And-Equipment",
          headingName: "Household Furnishings & Equipment",
          className: "!bg-[#faedef] ",
          values: arrvalues,
          expanded: false,
        },
      ];
    }
    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails
        ?.HouseholdServicesAndOperation > 0 &&
      checkBasiqSelected
    ) {
      let arrvalues: any[] = [];
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          {
            date: date,
            value:
              Calstate?.BorrowingCalculator?.ExpenseDetails
                ?.HouseholdServicesAndOperation,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "Household-Services-And-Operation",
          headingName: "Household Services & Operation",
          className: "!bg-[#faedef] ",
          values: arrvalues,
          expanded: false,
        },
      ];
    }
    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails
        ?.MedicalCareAndHealthExpenses > 0 &&
      checkBasiqSelected
    ) {
      let arrvalues: any[] = [];
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          {
            date: date,
            value:
              Calstate?.BorrowingCalculator?.ExpenseDetails
                ?.MedicalCareAndHealthExpenses,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "Medical-Care-And-Health-Expenses",
          headingName: "Medical Care & Health Expenses",
          className: "!bg-[#faedef] ",
          values: arrvalues,
          expanded: false,
        },
      ];
    }
    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails?.PersonalCare > 0 &&
      checkBasiqSelected
    ) {
      let arrvalues: any[] = [];
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          {
            date: date,
            value: Calstate?.BorrowingCalculator?.ExpenseDetails?.PersonalCare,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "Personal-Care",
          headingName: "Personal Care",
          className: "!bg-[#faedef] ",
          values: arrvalues,
          expanded: false,
        },
      ];
    }
    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails?.Recreation > 0 &&
      checkBasiqSelected
    ) {
      let arrvalues: any[] = [];
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          {
            date: date,
            value: Calstate?.BorrowingCalculator?.ExpenseDetails?.Recreation,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "Recreation",
          headingName: "Recreation",
          className: "!bg-[#faedef] ",
          values: arrvalues,
          expanded: false,
        },
      ];
    }
    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails?.Transport > 0 &&
      checkBasiqSelected
    ) {
      let arrvalues: any[] = [];
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          {
            date: date,
            value: Calstate?.BorrowingCalculator?.ExpenseDetails?.Transport,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "Transport",
          headingName: "Transport",
          className: "!bg-[#faedef] ",
          values: arrvalues,
          expanded: false,
        },
      ];
    }
    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails?.AccountantFees > 0 &&
      checkBasiqSelected
    ) {
      let arrvalues: any[] = [];
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          {
            date: date,
            value:
              Calstate?.BorrowingCalculator?.ExpenseDetails?.AccountantFees,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "Clothing-and-footwear",
          headingName: "Accountant Fees",
          className: "!bg-[#faedef] ",
          values: arrvalues,
          expanded: false,
        },
      ];
    }
    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails?.BodyCorporatePayments >
        0 &&
      checkBasiqSelected
    ) {
      let arrvalues: any[] = [];
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          {
            date: date,
            value:
              Calstate?.BorrowingCalculator?.ExpenseDetails
                ?.BodyCorporatePayments,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "Body-Corporate-Payments",
          headingName: "Body Corporate Payments",
          className: "!bg-[#faedef] ",
          values: arrvalues,
          expanded: false,
        },
      ];
    }
    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails
        ?.CashGiftDonationsToCharityNec > 0 &&
      checkBasiqSelected
    ) {
      let arrvalues: any[] = [];
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          {
            date: date,
            value:
              Calstate?.BorrowingCalculator?.ExpenseDetails
                ?.CashGiftDonationsToCharityNec,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "Cash-Gifts-Donations-To-Charity-Nec",
          headingName: "Cash Gifts, Donations To Charity Nec",
          className: "!bg-[#faedef] ",
          values: arrvalues,
          expanded: false,
        },
      ];
    }
    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails?.FeesNecGovernment > 0 &&
      checkBasiqSelected
    ) {
      let arrvalues: any[] = [];
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          {
            date: date,
            value:
              Calstate?.BorrowingCalculator?.ExpenseDetails?.FeesNecGovernment,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "Fees-Nec-Government",
          headingName: "Fees Nec - Government",
          className: "!bg-[#faedef] ",
          values: arrvalues,
          expanded: false,
        },
      ];
    }
    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails?.FeesNecPrivate > 0 &&
      checkBasiqSelected
    ) {
      let arrvalues: any[] = [];
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          {
            date: date,
            value:
              Calstate?.BorrowingCalculator?.ExpenseDetails?.FeesNecPrivate,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "Fees-Nec-Private",
          headingName: "Fees Nec - Private",
          className: "!bg-[#faedef] ",
          values: arrvalues,
          expanded: false,
        },
      ];
    }
    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails
        ?.FinancialInstitutionCharges > 0 &&
      checkBasiqSelected
    ) {
      let arrvalues: any[] = [];
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          {
            date: date,
            value:
              Calstate?.BorrowingCalculator?.ExpenseDetails
                ?.FinancialInstitutionCharges,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "Financial-Institution-Charges",
          headingName: "Financial Institution Charges",
          className: "!bg-[#faedef] ",
          values: arrvalues,
          expanded: false,
        },
      ];
    }
    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails?.HireServicesNec > 0 &&
      checkBasiqSelected
    ) {
      let arrvalues: any[] = [];
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          {
            date: date,
            value:
              Calstate?.BorrowingCalculator?.ExpenseDetails?.HireServicesNec,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "Hire-Services-Nec",
          headingName: "Hire Services Nec",
          className: "!bg-[#faedef] ",
          values: arrvalues,
          expanded: false,
        },
      ];
    }
    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails?.InsuranceNec > 0 &&
      checkBasiqSelected
    ) {
      let arrvalues: any[] = [];
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          {
            date: date,
            value: Calstate?.BorrowingCalculator?.ExpenseDetails?.InsuranceNec,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "Insurance-Nec",
          headingName: "Insurance Nec",
          className: "!bg-[#faedef] ",
          values: arrvalues,
          expanded: false,
        },
      ];
    }
    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails?.MiscellaneousGoodsNec >
        0 &&
      checkBasiqSelected
    ) {
      let arrvalues: any[] = [];
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          {
            date: date,
            value:
              Calstate?.BorrowingCalculator?.ExpenseDetails
                ?.MiscellaneousGoodsNec,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "Miscellaneous-Goods-Nec",
          headingName: "Miscellaneous Goods Nec",
          className: "!bg-[#faedef] ",
          values: arrvalues,
          expanded: false,
        },
      ];
    }
    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails?.MiscellaneousGoodsNfd >
        0 &&
      checkBasiqSelected
    ) {
      let arrvalues: any[] = [];
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          {
            date: date,
            value:
              Calstate?.BorrowingCalculator?.ExpenseDetails
                ?.MiscellaneousGoodsNfd,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "Miscellaneous-Goods-Nfd",
          headingName: "Miscellaneous Goods Nfd",
          className: "!bg-[#faedef] ",
          values: arrvalues,
          expanded: false,
        },
      ];
    }
    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails?.MiscellaneousServicesNec >
        0 &&
      checkBasiqSelected
    ) {
      let arrvalues: any[] = [];
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          {
            date: date,
            value:
              Calstate?.BorrowingCalculator?.ExpenseDetails
                ?.MiscellaneousServicesNec,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "Miscellaneous-Services-Nec",
          headingName: "Miscellaneous Services Nec",
          className: "!bg-[#faedef] ",
          values: arrvalues,
          expanded: false,
        },
      ];
    }
    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails?.NonHolidayAccommodation >
        0 &&
      checkBasiqSelected
    ) {
      let arrvalues: any[] = [];
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          {
            date: date,
            value:
              Calstate?.BorrowingCalculator?.ExpenseDetails
                ?.NonHolidayAccommodation,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "Non-Holiday-Accommodation",
          headingName: "Non-Holiday Accommodation",
          className: "!bg-[#faedef] ",
          values: arrvalues,
          expanded: false,
        },
      ];
    }
    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails?.StationeryEquipmentNfd >
        0 &&
      checkBasiqSelected
    ) {
      let arrvalues: any[] = [];
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          {
            date: date,
            value:
              Calstate?.BorrowingCalculator?.ExpenseDetails
                ?.StationeryEquipmentNfd,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "Stationery-Equipment-Nfd",
          headingName: "Stationery Equipment Nfd",
          className: "!bg-[#faedef] ",
          values: arrvalues,
          expanded: false,
        },
      ];
    }
    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails?.TravelGoodsHandbags > 0 &&
      checkBasiqSelected
    ) {
      let arrvalues: any[] = [];
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          {
            date: date,
            value:
              Calstate?.BorrowingCalculator?.ExpenseDetails
                ?.TravelGoodsHandbags,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "Travel-goods-handbags-&-related",
          headingName: "Travel goods, handbags & related",
          className: "!bg-[#faedef] ",
          values: arrvalues,
          expanded: false,
        },
      ];
    }
    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails?.UnionDues > 0 &&
      checkBasiqSelected
    ) {
      let arrvalues: any[] = [];
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          {
            date: date,
            value: Calstate?.BorrowingCalculator?.ExpenseDetails?.UnionDues,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "Union-dues-professional-association-subscriptions",
          headingName: "Union dues, professional association subscriptions",
          className: "!bg-[#faedef] ",
          values: arrvalues,
          expanded: false,
        },
      ];
    }

    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails?.CashWithdrawals > 0 &&
      checkBasiqSelected
    ) {
      let arrvalues: any[] = [];
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          {
            date: date,
            value:
              Calstate?.BorrowingCalculator?.ExpenseDetails?.CashWithdrawals,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "Cash-Withdrawls",
          headingName: "Cash Withdrawls",
          className: "!bg-[#faedef] ",
          values: arrvalues,
          expanded: false,
        },
      ];
    }
    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails?.BankFees > 0 &&
      checkBasiqSelected
    ) {
      let arrvalues: any[] = [];
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          {
            date: date,
            value: Calstate?.BorrowingCalculator?.ExpenseDetails?.BankFees,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "Bank-Fees",
          headingName: "Bank Fees",
          className: "!bg-[#faedef] ",
          values: arrvalues,
          expanded: false,
        },
      ];
    }
    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails?.LoanInterest > 0 &&
      checkBasiqSelected
    ) {
      let arrvalues: any[] = [];
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          {
            date: date,
            value: Calstate?.BorrowingCalculator?.ExpenseDetails?.LoanInterest,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "Loan-Interest",
          headingName: "Loan Interest",
          className: "!bg-[#faedef] ",
          values: arrvalues,
          expanded: false,
        },
      ];
    }
    if (
      Calstate?.BorrowingCalculator?.ExpenseDetails?.ExternalTransfers > 0 &&
      checkBasiqSelected
    ) {
      let arrvalues: any[] = [];
      sheetmonths.map((date: any) => {
        arrvalues = [
          ...arrvalues,
          {
            date: date,
            value:
              Calstate?.BorrowingCalculator?.ExpenseDetails?.ExternalTransfers,
          },
        ];
      });
      tabledata = [
        ...tabledata,
        {
          product: "External-Transfers",
          headingName: "External Transfers",
          className: "!bg-[#faedef] ",
          values: arrvalues,
          expanded: false,
        },
      ];
    }
  }
  if (Calstate?.BorrowingCalculator?.ExpenseDetails?.OtherPayment > 0) {
    let arrvalues: any[] = [];
    sheetmonths.map((date: any) => {
      arrvalues = [
        ...arrvalues,
        {
          date: date,
          value: Calstate?.BorrowingCalculator?.ExpenseDetails?.OtherPayment,
        },
      ];
    });
    tabledata = [
      ...tabledata,
      {
        product: "Other",
        headingName: "Other",
        className: "!bg-[#faedef] ",
        values: arrvalues,
        expanded: false,
      },
    ];
  }
  return tabledata;
};
export const GenerateDates = (years: any = 7) => {
  let no_of_months = years * 12;
  const months = [];
  const dateStart = moment();
  const dateEnd = moment().add(no_of_months, "month");
  while (dateEnd.diff(dateStart, "months") >= 0) {
    months.push(dateStart.format("MMM-YYYY"));
    dateStart.add(1, "month");
  }
  return [...months];
};
export const GenerateSpendingData = (
  years: any = 7,
  Calstate: TCalculatorData
) => {
  const months = GenerateDates(years);
  let Today = moment();
  let NextMonth = moment();
  let balanceSheetArrValues: TSpedingTableValueType[] = [];
  let cashOnHandArrValues: TSpedingTableValueType[] = [];
  let AccountYourArrValues: TSpedingTableValueType[] = [];
  let AccountPartnerArrValues: TSpedingTableValueType[] = [];
  let liquidassetsArrValues: TSpedingTableValueType[] = [];
  let extraCashArrValues: TSpedingTableValueType[] = [];
  let PPRArrValues: TSpedingTableValueType[] = [];
  let EquityGainArrValues: TSpedingTableValueType[] = [];
  let PPRPrincipalArrValues: TSpedingTableValueType[] = [];
  let MonthlySavingsArrValues: TSpedingTableValueType[] = [];
  let IncomeArrValues: TSpedingTableValueType[] = [];
  let NetSalaryYoursArrValues: TSpedingTableValueType[] = [];
  let NetSalaryPartnerArrValues: TSpedingTableValueType[] = [];
  let RentalIncomeArrValues: TSpedingTableValueType[] = [];
  let OtherIncomeArrValues: TSpedingTableValueType[] = [];
  let LiabilitiesArrValues: TSpedingTableValueType[] = [];
  let PPRMortgageArrValues: TSpedingTableValueType[] = [];
  let TotalExpenseArrValues: TSpedingTableValueType[] = [];
  let prevCashOnHand = 0;
  let prevCashOnHand2 = 0;
  let prevEquityGain = 0;
  let prevEquityGainValue2 = 0;
  let prevEquityGain2 = 0;
  let accountYour = Calstate.StrategicSpending.AccountYours;
  let accountPartner = Calstate.StrategicSpending.AccountPartner;
  let PPRLoanRepayments = Calstate.Equity.PPR.LoanRepayments;

  let PPRPrincipalValue =
    PPRLoanRepayments.type === "Monthly"
      ? PPRLoanRepayments.principalvalue
      : PPRLoanRepayments.type === "Annually"
      ? PPRLoanRepayments.principalvalue / 12
      : (PPRLoanRepayments.principalvalue * 52) / 12;

  let PPRMortgageValue =
    PPRLoanRepayments.type === "Monthly"
      ? PPRLoanRepayments.value
      : PPRLoanRepayments.type === "Annually"
      ? PPRLoanRepayments.value / 12
      : (PPRLoanRepayments.value * 52) / 12;

  let PropertyPrincipalValue = Calstate.Equity.Properties.reduce(
    (prevItem, nextItem) => {
      return (prevItem +=
        nextItem.LoanRepayments.type === "Monthly"
          ? nextItem.LoanRepayments.principalvalue
          : nextItem.LoanRepayments.type === "Annually"
          ? nextItem.LoanRepayments.principalvalue / 12
          : (nextItem.LoanRepayments.principalvalue * 52) / 12);
    },
    0
  );
  let equityGain = Calstate.Equity.UsableEquity;
  let lockedProperties = Calstate?.PropertyGenerators?.filter(
    (item) => item?.isLocked === true
  );

  let data: TSpendingSheet[] = months.map((date, index) => {
    let currentDatePurchase;
    let propertiesPurchased = lockedProperties.filter(
      (i) => i.nextDate === date && i.isLocked === true
    );
    if (propertiesPurchased.length > 0) {
      currentDatePurchase = propertiesPurchased.reduce(
        (acc, curr) => {
          acc["price"] = acc.price + curr.summaryData.TotalDepositRequired;
          acc["depositCash"] =
            acc.depositCash + curr.summaryData.TotalDepositCash;
          acc["depositEquity"] =
            acc.depositEquity + curr.summaryData.TotalDepositEquity;
          acc["extraCash"] =
            acc.extraCash + curr.summaryData.TotalDepositExtraCash;
          acc["useExtraCash"] = curr.summaryData.useExtraCash;
          acc["useLiquidAssets"] = curr.summaryData.useLiquidAssets;
          acc["liquidAssets"] =
            acc.liquidAssets + curr.summaryData.TotalDepositLiquidAssets;
          acc["date"] = new Date(curr.nextDate);
          return acc;
        },
        {
          price: 0,
          depositCash: 0,
          depositEquity: 0,
          extraCash: 0,
          useLiquidAssets: "N",
          useExtraCash: "N",
          liquidAssets: 0,
          date,
        }
      );
    }

    let cashOnHandValue =
      date === Today.format("MMM-YYYY")
        ? Calstate.Equity.Cash //first month
        : date === NextMonth.format("MMM-YYYY")
        ? Calstate.StrategicSpending.CashOnHand
        : prevCashOnHand + Calstate.StrategicSpending.MonthlySavings;

    let EquityGainValue =
      date === Today.format("MMM-YYYY")
        ? Calstate.Equity.UsableEquity
        : date === NextMonth.format("MMM-YYYY")
        ? Calstate.Equity.UsableEquity +
          (PPRPrincipalValue + PropertyPrincipalValue)
        : prevEquityGain + (PPRPrincipalValue + PropertyPrincipalValue);

    let cashOnHand2 =
      date === Today.format("MMM-YYYY")
        ? Calstate.Equity.Cash //first month
        : prevCashOnHand2 + Calstate.StrategicSpending.MonthlySavings;

    let EquityGainValue2 =
      date === Today.format("MMM-YYYY")
        ? Calstate.Equity.UsableEquity
        : prevEquityGain2 + (PPRPrincipalValue + PropertyPrincipalValue);

    prevCashOnHand = cashOnHandValue;
    prevEquityGain = EquityGainValue;

    // prevCashOnHand2 = cashOnHand2;
    // prevEquityGainValue2 = EquityGainValue2
    let extracashvalue = 0;
    let liquidassetsvalue = 0;
    let total = 0;
    total = EquityGainValue2 + cashOnHand2;

    if (currentDatePurchase) {
      extracashvalue = currentDatePurchase.extraCash;
      liquidassetsvalue = currentDatePurchase.liquidAssets;

      EquityGainValue2 = EquityGainValue2 - currentDatePurchase.depositEquity;
      EquityGainValue2 = EquityGainValue2 < 0 ? 0 : EquityGainValue2;
      cashOnHand2 = cashOnHand2 - currentDatePurchase.depositCash;
      cashOnHand2 = cashOnHand2 < 0 ? 0 : cashOnHand2;
      total = EquityGainValue2 + cashOnHand2;
    } else {
      extracashvalue = 0;
      liquidassetsvalue = 0;
      total = EquityGainValue2 + cashOnHand2;
    }
    if (EquityGainValue2 < 0) {
      EquityGainValue2 = 0;
    }
    if (cashOnHand2 < 0) {
      cashOnHand2 = 0;
    }
    prevCashOnHand2 = cashOnHand2;
    prevEquityGain2 = EquityGainValue2;
    if (prevCashOnHand < 0) {
      prevCashOnHand = 0;
    }
    if (prevEquityGain2 < 0) {
      prevEquityGain2 = 0;
    }

    let pprvalue =
      Calstate.StrategicSpending.EquityGains[0].value /
      Calstate.StrategicSpending.EquityGains[0].noOfMonths;

    cashOnHandArrValues = [
      ...cashOnHandArrValues,
      { date: date, value: Number(cashOnHand2.toFixed(2)) },
    ];
    AccountYourArrValues = [
      ...AccountYourArrValues,
      { date: date, value: Number(accountYour.toFixed(2)) },
    ];
    AccountPartnerArrValues = [
      ...AccountPartnerArrValues,
      { date: date, value: Number(accountPartner.toFixed(2)) },
    ];
    liquidassetsArrValues = [
      ...liquidassetsArrValues,
      { date: date, value: Number(liquidassetsvalue.toFixed(2)) },
    ];
    extraCashArrValues = [
      ...extraCashArrValues,
      { date: date, value: Number(extracashvalue.toFixed(2)) },
    ];
    PPRArrValues = [
      ...PPRArrValues,
      {
        date: date,
        value:
          date === Today.format("MMM-YYYY") ? 0 : Number(pprvalue.toFixed(2)),
      },
    ];
    EquityGainArrValues = [
      ...EquityGainArrValues,
      { date: date, value: Number(EquityGainValue2.toFixed(2)) },
    ];
    PPRPrincipalArrValues = [
      ...PPRPrincipalArrValues,
      { date: date, value: Number(PPRPrincipalValue.toFixed(2)) },
    ];
    MonthlySavingsArrValues = [
      ...MonthlySavingsArrValues,
      {
        date: date,
        value: Number(Calstate.StrategicSpending.MonthlySavings.toFixed(2)),
      },
    ];
    IncomeArrValues = [
      ...IncomeArrValues,
      {
        date: date,
        value: Number(Calstate?.StrategicSpending?.Income?.toFixed(2)),
      },
    ];
    NetSalaryYoursArrValues = [
      ...NetSalaryYoursArrValues,
      {
        date: date,
        value: Number(Calstate?.StrategicSpending?.NetSalaryYours?.toFixed(2)),
      },
    ];
    NetSalaryPartnerArrValues = [
      ...NetSalaryPartnerArrValues,
      {
        date: date,
        value: Number(
          Calstate?.StrategicSpending?.NetSalaryPartner?.toFixed(2)
        ),
      },
    ];
    RentalIncomeArrValues = [
      ...RentalIncomeArrValues,
      {
        date: date,
        value: Number(Calstate?.StrategicSpending?.RentalIncome?.toFixed(2)),
      },
    ];
    OtherIncomeArrValues = [
      ...OtherIncomeArrValues,
      {
        date: date,
        value: Number(Calstate?.StrategicSpending?.OtherIncome?.toFixed(2)),
      },
    ];
    LiabilitiesArrValues = [
      ...LiabilitiesArrValues,
      {
        date: date,
        value: Number(Calstate.StrategicSpending.TotalLiabilities.toFixed(2)),
      },
    ];
    TotalExpenseArrValues = [
      ...TotalExpenseArrValues,
      {
        date: date,
        value: Number(Calstate.BorrowingCalculator.TotalExpenses.toFixed(2)),
      },
    ];
    PPRMortgageArrValues = [
      ...PPRMortgageArrValues,
      {
        date: date,
        value: Number(PPRMortgageValue.toFixed(2)),
      },
    ];
    balanceSheetArrValues = [
      ...balanceSheetArrValues,
      { date: date, value: total },
    ];
    return {
      date,
      balanceSheet: {
        total: cashOnHandValue + EquityGainValue, //cash on hand added in balance sheet EquityGainValue +cashOnHandValue
        cashOnHand: {
          total: cashOnHandValue,
          total2: cashOnHand2,
          accountYour: accountYour,
          accountPartner: accountPartner,
          extraCash: extracashvalue,
          liquidassets: liquidassetsvalue,
        },
        EquityGain: EquityGainValue,
        EquityGain2: EquityGainValue2,
        liabilities: {
          total: Calstate.StrategicSpending.TotalLiabilities,
          creditCardPartner:
            Calstate.BorrowingCalculator.PersonalLiabilities.creditCardPartner,
          creditCardYours:
            Calstate.BorrowingCalculator.PersonalLiabilities.creditCardYours,
        },
      },
      monthlyCashflow: {
        total: Calstate.StrategicSpending.MonthlySavings,
        expenses: {
          total: 0,
          PersonalExpenses: 0,
          InvestmentExpenses: 0,
          BillsUtilities: 0,
          Education: Calstate.BorrowingCalculator.ExpenseDetails.Education,
          Entertainment: 0,
          HigherEducationandVocationalTraining: 0,
          Childcare: 0,
          PrimaryResidence: 0,
          FoodDining: 0,
          RecreationHolidays: 0,
          GeneralInsurance: 0,
          LoansCreditCards: 0,
          RentMortgageProperty: 0,
          Shopping: 0,
          Travel:
            Calstate.BorrowingCalculator.ExpenseDetails.TravelGoodsHandbags,
          HECSPayments: 0,
          CarLoan: 0,
          AlcoholicBeverages:
            Calstate.BorrowingCalculator.ExpenseDetails.AlcoholicBeverages,
          ClothingAndFootwear:
            Calstate.BorrowingCalculator.ExpenseDetails.ClothingAndFootwear,
          Communication:
            Calstate.BorrowingCalculator.ExpenseDetails.Communication,
          CurrentHousingCosts:
            Calstate.BorrowingCalculator.ExpenseDetails.CurrentHousingCosts,
          DomesticFuelAndPower:
            Calstate.BorrowingCalculator.ExpenseDetails.DomesticFuelAndPower,
          FoodAndNonAlcoholicBeverages:
            Calstate.BorrowingCalculator.ExpenseDetails
              .FoodNonAlcoholicBeverages,
          HouseholdFurnishingsAndEquipment:
            Calstate.BorrowingCalculator.ExpenseDetails
              .HouseholdFurnishingsAndEquipment,
          HouseholdServicesAndOperation:
            Calstate.BorrowingCalculator.ExpenseDetails
              .HouseholdServicesAndOperation,
          HealthFitness: 0,
          PersonalCare:
            Calstate.BorrowingCalculator.ExpenseDetails.PersonalCare,
          Recreation: Calstate.BorrowingCalculator.ExpenseDetails.Recreation,
          AccountantFees:
            Calstate.BorrowingCalculator.ExpenseDetails.AccountantFees,
          BodyCorporatePayments:
            Calstate.BorrowingCalculator.ExpenseDetails.BodyCorporatePayments,
          CashGiftsAndDonations:
            Calstate.BorrowingCalculator.ExpenseDetails
              .CashGiftDonationsToCharityNec,
          FeesNecGovernment:
            Calstate.BorrowingCalculator.ExpenseDetails.FeesNecGovernment,
          FeesNecPrivate:
            Calstate.BorrowingCalculator.ExpenseDetails.FeesNecPrivate,
          FinancialInstitutionCharges:
            Calstate.BorrowingCalculator.ExpenseDetails
              .FinancialInstitutionCharges,
          HireServicesNec:
            Calstate.BorrowingCalculator.ExpenseDetails.HireServicesNec,
          InsuranceNec:
            Calstate.BorrowingCalculator.ExpenseDetails.InsuranceNec,
          MiscellaneousGoodsNec:
            Calstate.BorrowingCalculator.ExpenseDetails.MiscellaneousGoodsNec,
          MiscellaneousGoodsNfd:
            Calstate.BorrowingCalculator.ExpenseDetails.MiscellaneousGoodsNfd,
          MiscellaneousServicesNec:
            Calstate.BorrowingCalculator.ExpenseDetails
              .MiscellaneousServicesNec,
          NonHolidayAccommodation:
            Calstate.BorrowingCalculator.ExpenseDetails.NonHolidayAccommodation,
          StationeryEquipmentnfd:
            Calstate.BorrowingCalculator.ExpenseDetails.StationeryEquipmentNfd,
          TravelGoods:
            Calstate.BorrowingCalculator.ExpenseDetails.TravelGoodsHandbags,
          UnionDues: Calstate.BorrowingCalculator.ExpenseDetails.UnionDues,
          OtherExpenses: 0,
          cashWithdrawals:
            Calstate.BorrowingCalculator.ExpenseDetails.CashWithdrawals,
          BankFees: Calstate.BorrowingCalculator.ExpenseDetails.BankFees,
          loanInterests:
            Calstate.BorrowingCalculator.ExpenseDetails.LoanInterest,
          externalTransfers:
            Calstate.BorrowingCalculator.ExpenseDetails.ExternalTransfers,
        },
        income: {
          total: 0,
          netSalaryYour: 0,
          netSalaryPartner: 0,
          rentalIncome: 0,
          OtherIncome: 0,
        },
      },
    };
  });

  let tablerowvalues = {
    balanceSheetArrValues: balanceSheetArrValues,
    cashOnHandArrValues: cashOnHandArrValues,
    AccountYourArrValues: AccountYourArrValues,
    AccountPartnerArrValues: AccountPartnerArrValues,
    liquidassetsArrValues: liquidassetsArrValues,
    extraCashArrValues: extraCashArrValues,
    PPRArrValues: PPRArrValues,
    EquityGainArrValues: EquityGainArrValues,
    PPRPrincipalArrValues: PPRPrincipalArrValues,
    MonthlySavingsArrValues: MonthlySavingsArrValues,
    IncomeArrValues: IncomeArrValues,
    NetSalaryYoursArrValues: NetSalaryYoursArrValues,
    NetSalaryPartnerArrValues: NetSalaryPartnerArrValues,
    RentalIncomeArrValues: RentalIncomeArrValues,
    OtherIncomeArrValues: OtherIncomeArrValues,
    LiabilitiesArrValues: LiabilitiesArrValues,
    PPRMortgageArrValues: PPRMortgageArrValues,
    TotalExpenseArrValues: TotalExpenseArrValues,
  };
  return { data, tablerowvalues, months };
};
export const getFieldByPath = (object: any, fieldParts: any) => {
  let value = object;
  for (const part of fieldParts) {
    if (value && value.hasOwnProperty(part)) {
      value = value[part];
    } else {
      return undefined;
    }
  }

  return value;
};
export const HandleFilter = (field: any, sortOrder: any, TableData: any) => {
  const filteredResult = TableData;
  const fieldParts = field.split(".");
  if (fieldParts.length > 1) {
    filteredResult.sort((a: any, b: any) => {
      const fieldA = getFieldByPath(a, fieldParts);
      const fieldB = getFieldByPath(b, fieldParts);

      if (sortOrder === 1) {
        return fieldA > fieldB ? 1 : -1;
      } else if (sortOrder === -1) {
        return fieldA < fieldB ? 1 : -1;
      }

      return 0; // If sortOrder is not 1 or -1, maintain the current order
    });
  } else {
    if (sortOrder === 1) {
      filteredResult.sort((a: any, b: any) => (a[field] > b[field] ? 1 : -1));
    } else if (sortOrder === -1) {
      filteredResult.sort((a: any, b: any) => (a[field] < b[field] ? 1 : -1));
    }
  }

  return filteredResult;
  // setFilterData(filteredResult);
};
export function isInArray(array: any, item: any) {
  return array.some(
    (elem: any) =>
      elem.region === item.region &&
      elem.suburb === item.Suburb_Name &&
      elem.name === item.State
  );
}
export function GetPurchasePrice(
  iniDeposit: any = 0,
  LVR: any = null,
  stateName: any
) {
  let requiredDeposit = iniDeposit;
  // let initialvalue = (iniDeposit / 20) * 100;

  let LVRratio = LVR / 100;
  let PurchasePrice = 0;
  let StampDuty = 0;
  let AcquisitionCost = 0;
  let TotalDebt = 0;
  let Deposit = 0;
  let TotalDepositRequired = 0;
  let newPurchasePrice = 0;
  do {
    newPurchasePrice += 1;
    PurchasePrice = newPurchasePrice;
    StampDuty = stampDutyRate(stateName, newPurchasePrice);

    AcquisitionCost = newPurchasePrice * 0.025;
    TotalDebt = newPurchasePrice * LVRratio;
    Deposit = newPurchasePrice - TotalDebt;
    TotalDepositRequired = Deposit + StampDuty + AcquisitionCost;
  } while (TotalDepositRequired < requiredDeposit);
  return Math.floor(PurchasePrice);
}
export function stampDutyRate(stateName: any = "NSW", pp: any = 0) {
  let stampDutyRate = 0;

  if (stateName === "NSW") {
    stampDutyRate = stampDutyNSW(pp);
    //  9 / 100;
  } else if (stateName === "VIC") {
    stampDutyRate = stampDutyVIC(pp);
    // stampDutyRate = 10 / 100;
  } else if (stateName === "QLD") {
    stampDutyRate = stampDutyQLD(pp);
    // stampDutyRate = 9 / 100;
  } else if (stateName === "SA") {
    stampDutyRate = stampDutySA(pp);
    // stampDutyRate = 11 / 100;
  } else if (stateName === "WA") {
    stampDutyRate = stampDutyWA(pp);
    // stampDutyRate = 10 / 100;
  } else if (stateName === "ACT") {
    stampDutyRate = stampDutyWA(pp);
    // stampDutyRate = 6 / 100;
  } else if (stateName === "NT") {
    stampDutyRate = stampDutyWA(pp);
    // stampDutyRate = 10 / 100;
  } else if (stateName === "TAS") {
    stampDutyRate = stampDutyWA(pp);
    // stampDutyRate = 10 / 100;
  } else {
    stampDutyRate = stampDutyWA(pp);
    // stampDutyRate = 9 / 100;
  }
  return stampDutyRate;
}
export const stampDutySA = (pp: number) => {
  let result = 0;
  if (pp > 500000) {
    result = 21330 + 0.055 * (pp - 500000);
  } else if (pp > 300000) {
    result = 11330 + 0.05 * (pp - 300000);
  } else if (pp > 250000) {
    result = 8955 + 0.0475 * (pp - 250000);
  } else if (pp > 200000) {
    result = 6830 + 0.0425 * (pp - 200000);
  } else if (pp > 100000) {
    result = 2830 + 0.04 * (pp - 100000);
  } else if (pp > 50000) {
    result = 1080 + 0.035 * (pp - 50000);
  } else if (pp >= 30000) {
    result = 760 + 0.035 * (pp - 3000);
  } else if (pp < 30000) {
    result = 480 + 0.03 * (pp - 30000);
  } else if (pp < 12000) {
    result = 120 + 0.02 * (pp - 12000);
  }
  return result + (pp * 0.95) / 100;
};

export const stampDutyWA = (pp: number) => {
  let fee = 0;
  if (pp > 725000) {
    fee = 28453 + 0.0515 * (pp - 725000);
  } else if (pp > 360000) {
    fee = 11115 + 0.0475 * (pp - 360000);
  } else if (pp > 150000) {
    fee = 3135 + 0.038 * (pp - 150000);
  } else if (pp > 120000) {
    fee = 2280 + 0.0285 * (pp - 120000);
  } else if (pp > 1) {
    fee = 0.019 * (pp - 1);
  }
  fee += (pp * 0.15) / 100;
  return fee;
};
export const stampDutyQLD = (pp: number) => {
  let result = 0;

  if (pp > 1000000) {
    result = 38025 + 0.0575 * (pp - 1000000);
  } else if (pp > 540000) {
    result = 17325 + 0.045 * (pp - 540000);
  } else if (pp > 75000) {
    result = 1050 + 0.035 * (pp - 75000);
  } else if (pp > 5000) {
    result = 0.015 * (pp - 5000);
  } else {
    result = 0;
  }
  return result + (pp * 0.3) / 100;
};
export const stampDutyVIC = (pp: number) => {
  let result = 0;
  if (pp > 2000000) {
    result = 110000 + 0.065 * (pp - 2000000);
  } else if (pp > 960000) {
    result = 0.055 * (pp - 319000);
  } else if (pp > 130000) {
    result = 2870 + 0.06 * (pp - 130000);
  } else if (pp > 25000) {
    result = 350 + 0.024 * (pp - 25000);
  } else if (pp > 1) {
    result = 0.014 * (pp - 1);
  } else {
    result = 0;
  }
  return result + (pp * 0.3) / 100;
};
export const stampDutyNSW = (pp: number) => {
  let value = 0;

  if (pp > 31940000) {
    value = 160237 + 0.07 * (pp - 31940000);
  } else if (pp > 1064000) {
    value = 43087 + 0.055 * (pp - 1064000);
  } else if (pp > 319000) {
    value = 9562 + 0.045 * (pp - 319000);
  } else if (pp > 85000) {
    value = 1372 + 0.035 * (pp - 85000);
  } else if (pp > 32000) {
    value = 445 + 0.0175 * (pp - 32000);
  } else if (pp > 14000) {
    value = 175 + 0.015 * (pp - 14000);
  }

  return value + (pp * 1) / 1000;
};
export function round_up(value: any, precision: any = 0) {
  const power = Math.pow(10, precision);
  return Math.ceil(value * power) / power;
}
export const generateDates = (years: any = 100) => {
  let no_of_months = years * 12;
  const months = [];
  const dateStart = moment();
  const dateEnd = moment().add(no_of_months, "month");
  while (dateEnd.diff(dateStart, "months") >= 0) {
    months.push(dateStart.format("MMM-YYYY"));
    dateStart.add(1, "month");
  }
  return [...months];
};
export const CalculateFormulasFromPurchasePrice = ({
  PurchasePrice,
  stateName,
  riskfactor,
  lvr = 65,
  acquistionCostValue = 0.065, // 0.025,6.5, 6.5 for property1 i don't know
  mortgageApply = "N",
  cityName = "",
}: {
  PurchasePrice: number;
  stateName: string;
  riskfactor: number;
  lvr?: number;
  acquistionCostValue?: number;
  mortgageApply?: "Y" | "N";
  cityName?: string;
}) => {
  let acquistonCost = acquistionCostValue;
  let newTotalAcquistonCost = PurchasePrice * acquistonCost || 0;
  let TotalDebt = PurchasePrice * (lvr / 100) || 0; //newTotalDebt
  let Deposit = PurchasePrice - TotalDebt;
  let PropertyIncome = PurchasePrice * (riskfactor / 100) || 0;
  const StampDuty = stampDutyRate(stateName, PurchasePrice) || 0;
  newTotalAcquistonCost = PurchasePrice * acquistonCost;

  const AcquisitionCost = newTotalAcquistonCost || 0;
  const TotalPurchaseCosts =
    Number(PurchasePrice) + Number(StampDuty) + Number(AcquisitionCost) || 0;
  let lmi = 0;
  if (mortgageApply === "Y") {
    lmi = getLMI({
      mortgageInsuranceApply: mortgageApply,
      TotalDebt,
      TotalPurchaseCosts,
      LoantoValueRatioLVR: lvr,
      cityName,
    });
  }
  const TotalDepositRequired = Deposit + StampDuty + AcquisitionCost + lmi || 0; //+1000000

  let NetYield = PurchasePrice * (riskfactor / 100);
  return {
    Deposit,
    StampDuty,
    AcquisitionCost,
    TotalDepositRequired,
    TotalPurchaseCosts,
    TotalDebt,
    PropertyIncome,
    NetYield,
    lmi,
  };
};
export const CheckDepositCovered = ({
  cash,
  equityGain,
  stateName,
  newDeposit,
  newStampDuty,
  newAcquisitionCost,
  newTotalDepositRequired,
  newTotalDebt,
  newTotalPurchaseCosts,
  newPropertyIncome,
  PurchasePrice,
  lvr,
  riskfactor,
  mortgageApply = "N",
  LMI = 0,
}: {
  cash: number;
  equityGain: number;
  newDeposit: number;
  newStampDuty: number;
  newAcquisitionCost: number;
  newTotalDepositRequired: number;
  newTotalDebt: number;
  newTotalPurchaseCosts: number;
  newPropertyIncome: number;
  PurchasePrice: number;
  stateName: string;
  lvr: number;
  riskfactor: number;
  mortgageApply: "Y" | "N";
  LMI?: number;
}) => {
  let checkdeposit = false;

  checkdeposit = cash + equityGain > newTotalDepositRequired;
  let check = checkdeposit ? "deposit covered" : "more cash required";
  let TotalDepositRequired = newTotalDepositRequired || 0;
  let priceReduce: any = false;
  let newTotalPurchasePrice = PurchasePrice;
  let StampDuty = newStampDuty;
  let Deposit = newDeposit;
  let newTotalAcquistonCost = newAcquisitionCost;
  let PropertyIncome = newPropertyIncome;
  let TotalPurchaseCost = newTotalPurchaseCosts;
  let TotalDebt = newTotalDebt;
  let Lmi = LMI;
  while (checkdeposit === false) {
    priceReduce = true;
    newTotalPurchasePrice = newTotalPurchasePrice - 1 || 0;
    const {
      Deposit: updatedDeposit,
      StampDuty: updatedStampDuty,
      AcquisitionCost: updatedAcquisitionCost,
      TotalDepositRequired: updatedTotalDepositRequired,
      TotalPurchaseCosts: updatedTotalPurchaseCosts,
      TotalDebt: updatedTotalDebt,
      PropertyIncome: updatedPropertyIncome,
      lmi,
    } = CalculateFormulasFromPurchasePrice({
      PurchasePrice: newTotalPurchasePrice,
      stateName,
      riskfactor,
      lvr,
      mortgageApply,
    });
    let totalEquity = cash + equityGain || 0;
    Deposit = updatedDeposit;
    checkdeposit =
      totalEquity > updatedDeposit + updatedAcquisitionCost + updatedStampDuty;
    check = checkdeposit ? "deposit covered" : "more cash required";
    TotalDepositRequired = updatedTotalDepositRequired;
    TotalPurchaseCost = updatedTotalPurchaseCosts;
    TotalDebt = updatedTotalDebt;
    PropertyIncome = updatedPropertyIncome;
    Lmi = lmi;
  }
  return {
    checkdepositmessage: check,
    PurchasePrice: newTotalPurchasePrice,
    PropertyIncome,
    TotalAcquistonCost: newTotalAcquistonCost,
    Deposit,
    StampDuty,
    TotalDepositRequired,
    TotalPurchaseCost,
    TotalDebt,
    priceReduce,
    Lmi,
  };
};
export const GetPropertyType = ({
  RiskFactoredYieldReturn,
}: {
  RiskFactoredYieldReturn: number;
}) => {
  let propertytype = "Residential";
  let medianPropertyType = propertytype;
  if (RiskFactoredYieldReturn <= 5) {
    propertytype = "Residential";
  } else if (RiskFactoredYieldReturn > 5 && RiskFactoredYieldReturn <= 6) {
    propertytype = "Commercial Retail";
  } else if (RiskFactoredYieldReturn > 6 && RiskFactoredYieldReturn <= 8) {
    propertytype = "Commercial Office";
  } else {
    propertytype = "Commercial";
  }
  medianPropertyType = propertytype;
  return {
    propertytype,
    medianPropertyType,
  };
};
export const GetDomainPropertyType = ({
  RiskFactoredYieldReturn,
}: {
  RiskFactoredYieldReturn: number;
}) => {
  let medianPropertyType = "residential";

  if (RiskFactoredYieldReturn <= 5) {
    medianPropertyType = "residential";
  } else if (RiskFactoredYieldReturn > 5 && RiskFactoredYieldReturn <= 6) {
    medianPropertyType = "retail";
  } else if (RiskFactoredYieldReturn > 6 && RiskFactoredYieldReturn <= 7.5) {
    medianPropertyType = "office";
  } else {
    medianPropertyType = "other";
  }
  return medianPropertyType;
};
const calculateCashAndEquityAvailable = ({
  CalData,
}: {
  CalData: TCalculatorData;
}) => {
  let sum = CalData.StrategicSpending.Data[0]?.balanceSheet?.cashOnHand.total;
  sum += isNaN(CalData.StrategicSpending.Data[0]?.balanceSheet?.EquityGain)
    ? 0
    : CalData.StrategicSpending.Data[0]?.balanceSheet?.EquityGain;
  let v = 0;
  let result = isNaN(sum + v) ? 0 : sum + v;
  return result;
};
export const CheckBlendedPropertyRiskProfile = ({
  PropertyType,
  CalData,
  propertyno,
}: {
  PropertyType: string;
  CalData: TCalculatorData;
  propertyno: number;
}) => {
  let riskprofile = CalData?.RiskProfile?.NetYieldonProperty;
  if (PropertyType === "Blended") {
    if (calculateCashAndEquityAvailable({ CalData }) < 202500) {
      riskprofile = 5;
    } else {
      let yieldProperty = CalData?.RiskProfile?.NetYieldonProperty;
      riskprofile = yieldProperty;
    }
    const rules = {
      rule1: [5, 7, 7, 6, 5],
      rule2: [7, 5, 6, 6, 5],
      rule3: [5.5, 7, 5, 6, 5],
      rule4: [6.5, 5, 7, 5, 6],
    };
    const nY = CalData.RiskProfile.NetYieldonProperty;
    const rule =
      nY <= 5.5
        ? "rule1"
        : nY <= 6
        ? "rule3"
        : nY <= 6.5
        ? "rule4"
        : Math.round(nY) <= 7
        ? "rule2"
        : 0;
    if (rule !== 0) {
      const arrayDuplicates = Math.floor(propertyno / rules[rule].length);
      if (arrayDuplicates > 0) {
        for (let zz = 0; zz < arrayDuplicates; zz++) {
          rules[rule].push(...rules[rule]);
        }
      }

      riskprofile = rules[rule][propertyno];
    }
  } else {
    let yieldProperty = CalData?.RiskProfile?.NetYieldonProperty;
    riskprofile = yieldProperty;
  }
  return riskprofile;
};
export const GetHomeAndPropertyLoanRepayment = ({
  PPR,
  Properties,
}: {
  PPR: TPPR;
  Properties: TInvestProperties[];
}) => {
  let propertyLoanRepayment = 0;
  let homeloanRepayment = 0;
  if (PPR.LoanRepayments.type === "Monthly") {
    homeloanRepayment = PPR.LoanRepayments.value * 12;
  } else if (PPR.LoanRepayments.type === "Weekly") {
    homeloanRepayment = PPR.LoanRepayments.value * 52;
  } else {
    homeloanRepayment = PPR.LoanRepayments.value;
  }
  if (Properties?.length > 0) {
    propertyLoanRepayment = Properties?.reduce((total, item) => {
      let loanRepayment = 0;
      if (item.LoanRepayments.type === "Monthly") {
        loanRepayment = item.LoanRepayments.value * 12;
      } else if (item.LoanRepayments.type === "Weekly") {
        loanRepayment = item.LoanRepayments.value * 52;
      } else {
        loanRepayment = item.LoanRepayments.value;
      }
      return total + loanRepayment;
    }, 0);
  }
  return { propertyLoanRepayment, homeloanRepayment };
};

export const GetRegionData = ({
  RiskFactorYieldReturn,
  medianPropertyType,
  propertyno,
  selectedRegions,
}: {
  RiskFactorYieldReturn: number;
  medianPropertyType: string;
  propertyno: number;
  selectedRegions: number[];
}) => {
  let selectedPropertyArea;
  let officedata: any[] = [];
  let retaildata: any[] = [];
  let residentialdata: any[] = [];
  for (let i = 0; i < GS.Calculator.DomainData.data["office"].length; i++) {
    officedata.push({
      region: GS.Calculator.DomainData.data["office"][i].region,
      suburb: GS.Calculator.DomainData.data["office"][i].Suburb_Name,
      name: GS.Calculator.DomainData.data["office"][i].State,
      sale_price: GS.Calculator.DomainData.data["office"][i].Median_Sale_Price,
      price: GS.Calculator.DomainData.data["office"][i].Median_Rental_Price,
      postcode: GS.Calculator.DomainData.data["office"][i]?.postcode,
      growth_percent:
        GS.Calculator.DomainData.data["office"][i]?.growth_percent,
    });
  }
  for (
    let i = 0;
    i < GS.Calculator.DomainData.data["residential"].length;
    i++
  ) {
    residentialdata.push({
      region: GS.Calculator.DomainData.data["residential"][i].region,
      suburb: GS.Calculator.DomainData.data["residential"][i].Suburb_Name,
      name: GS.Calculator.DomainData.data["residential"][i].State,
      sale_price:
        GS.Calculator.DomainData.data["residential"][i].Median_Sale_Price,
      price:
        GS.Calculator.DomainData.data["residential"][i].Median_Rental_Price,
      postcode: GS.Calculator.DomainData.data["residential"][i]?.postcode,
      growth_percent:
        GS.Calculator.DomainData.data["residential"][i]?.growth_percent,
    });
  }
  for (let i = 0; i < GS.Calculator.DomainData.data["retail"].length; i++) {
    retaildata.push({
      region: GS.Calculator.DomainData.data["retail"][i].region,
      suburb: GS.Calculator.DomainData.data["retail"][i].Suburb_Name,
      name: GS.Calculator.DomainData.data["retail"][i].State,
      sale_price: GS.Calculator.DomainData.data["retail"][i].Median_Sale_Price,
      price: GS.Calculator.DomainData.data["retail"][i].Median_Rental_Price,
      postcode: GS.Calculator.DomainData.data["retail"][i]?.postcode,
      growth_percent:
        GS.Calculator.DomainData.data["retail"][i]?.growth_percent,
    });
  }
  let medianPropertyTypes = medianPropertyType;
  if (RiskFactorYieldReturn <= 5) {
    medianPropertyTypes = "residential";
    selectedPropertyArea = residentialdata;
  } else if (RiskFactorYieldReturn > 5 && RiskFactorYieldReturn < 6.9) {
    medianPropertyTypes = "retail";
    selectedPropertyArea = retaildata;
  } else if (RiskFactorYieldReturn <= 7.5) {
    medianPropertyTypes = "office";
    selectedPropertyArea = officedata;
  } else {
    medianPropertyTypes = "other";
    selectedPropertyArea = [];
  }

  let regionNo;
  if (
    selectedRegions &&
    selectedRegions?.length > 0 &&
    (selectedRegions[propertyno] === 0 || selectedRegions[propertyno])
  ) {
    regionNo = selectedRegions[propertyno];
    if (!selectedPropertyArea[regionNo]) {
      regionNo = 0;
    }
  } else {
    const rengionLength = selectedPropertyArea.length;
    regionNo = Math.floor(Math.random() * rengionLength);
  }
  let ResidentialMedianPrice =
    residentialdata[regionNo]?.price * 35.36
      ? residentialdata[regionNo]?.price * 35.36
      : residentialdata[0]?.price * 35.36;
  let ComercialMedianPrice =
    officedata[regionNo]?.price * 100
      ? officedata[regionNo]?.price * 100
      : officedata[0]?.price * 100;

  let RetailMedianPrice =
    retaildata[regionNo]?.price * 100
      ? retaildata[regionNo]?.price * 100
      : retaildata[0]?.price * 100;
  let stateName = selectedPropertyArea[regionNo]?.name
    ? selectedPropertyArea[regionNo]?.name
    : selectedPropertyArea[0]?.name;
  let growthRate = selectedPropertyArea[regionNo]?.growth_percent
    ? selectedPropertyArea[regionNo]?.growth_percent || "0%"
    : selectedPropertyArea[0]?.growth_percent || "0%";
  let region = selectedPropertyArea[regionNo]?.region
    ? selectedPropertyArea[regionNo]?.region
    : selectedPropertyArea[0]?.region || "";

  if (!selectedPropertyArea[regionNo]?.region) {
    regionNo = 0;
  }
  return {
    stateName,
    ResidentialMedianPrice,
    ComercialMedianPrice,
    RetailMedianPrice,
    medianPropertyTypes,
    growthRate,
    regionNo,
    region,
    officedata,
    retaildata,
    residentialdata,
  };
};
const getRate = ({ loan, LVR }: { loan: number; LVR: number }) => {
  if (loan < 300000) {
    if (LVR >= 80 && LVR <= 81) {
      return 0.48;
    } else if (LVR > 81 && LVR <= 82) {
      return 0.49;
    } else if (LVR > 82 && LVR <= 83) {
      return 0.6;
    } else if (LVR > 83 && LVR <= 84) {
      return 0.66;
    } else if (LVR > 84 && LVR <= 85) {
      return 0.73;
    } else if (LVR > 85 && LVR <= 86) {
      return 0.88;
    } else if (LVR > 86 && LVR <= 87) {
      return 0.93;
    } else if (LVR > 87 && LVR <= 88) {
      return 1.06;
    } else if (LVR > 88 && LVR <= 89) {
      return 1.3;
    } else if (LVR > 89 && LVR <= 90) {
      return 1.46;
    } else if (LVR > 90 && LVR <= 91) {
      return 2.01;
    } else if (LVR > 91 && LVR <= 92) {
      return 2.01;
    } else if (LVR > 92 && LVR <= 93) {
      return 2.33;
    } else if (LVR > 93 && LVR <= 94) {
      return 2.38;
    } else if (LVR > 94 && LVR <= 95) {
      return 2.61;
    }
  } else if (loan === 300000) {
    if (LVR >= 80 && LVR <= 81) {
      return 0.48;
    } else if (LVR > 81 && LVR <= 82) {
      return 0.49;
    } else if (LVR > 82 && LVR <= 83) {
      return 0.6;
    } else if (LVR > 83 && LVR <= 84) {
      return 0.66;
    } else if (LVR > 84 && LVR <= 85) {
      return 0.73;
    } else if (LVR > 85 && LVR <= 86) {
      return 0.88;
    } else if (LVR > 86 && LVR <= 87) {
      return 0.93;
    } else if (LVR > 87 && LVR <= 88) {
      return 1.06;
    } else if (LVR > 88 && LVR <= 89) {
      return 1.3;
    } else if (LVR > 89 && LVR <= 90) {
      return 1.46;
    } else if (LVR > 90 && LVR <= 91) {
      return 2.01;
    } else if (LVR > 91 && LVR <= 92) {
      return 2.01;
    } else if (LVR > 92 && LVR <= 93) {
      return 2.33;
    } else if (LVR > 93 && LVR <= 94) {
      return 2.38;
    } else if (LVR > 94 && LVR <= 95) {
      return 2.61;
    }
  } else if (loan > 300000 && loan <= 500000) {
    if (LVR >= 80 && LVR <= 81) {
      return 0.57;
    } else if (LVR > 81 && LVR <= 82) {
      return 0.57;
    } else if (LVR > 82 && LVR <= 83) {
      return 0.7;
    } else if (LVR > 83 && LVR <= 84) {
      return 0.83;
    } else if (LVR > 84 && LVR <= 85) {
      return 0.97;
    } else if (LVR > 85 && LVR <= 86) {
      return 1.08;
    } else if (LVR > 86 && LVR <= 87) {
      return 1.15;
    } else if (LVR > 87 && LVR <= 88) {
      return 1.31;
    } else if (LVR > 88 && LVR <= 89) {
      return 1.62;
    } else if (LVR > 89 && LVR <= 90) {
      return 1.87;
    } else if (LVR > 90 && LVR <= 91) {
      return 2.62;
    } else if (LVR > 91 && LVR <= 92) {
      return 2.67;
    } else if (LVR > 92 && LVR <= 93) {
      return 3.03;
    } else if (LVR > 93 && LVR <= 94) {
      return 3.03;
    } else if (LVR > 94 && LVR <= 95) {
      return 3.35;
    }
  } else if (loan > 500000 && loan <= 600000) {
    if (LVR >= 80 && LVR <= 81) {
      return 0.9;
    } else if (LVR > 81 && LVR <= 82) {
      return 0.9;
    } else if (LVR > 82 && LVR <= 83) {
      return 0.93;
    } else if (LVR > 83 && LVR <= 84) {
      return 0.96;
    } else if (LVR > 84 && LVR <= 85) {
      return 1.17;
    } else if (LVR > 85 && LVR <= 86) {
      return 1.26;
    } else if (LVR > 86 && LVR <= 87) {
      return 1.41;
    } else if (LVR > 87 && LVR <= 88) {
      return 1.46;
    } else if (LVR > 88 && LVR <= 89) {
      return 1.95;
    } else if (LVR > 89 && LVR <= 90) {
      return 2.18;
    } else if (LVR > 90 && LVR <= 91) {
      return 3.51;
    } else if (LVR > 91 && LVR <= 92) {
      return 3.57;
    } else if (LVR > 92 && LVR <= 93) {
      return 3.8;
    } else if (LVR > 93 && LVR <= 94) {
      return 3.8;
    } else if (LVR > 94 && LVR <= 95) {
      return 4.0;
    }
  } else if (loan > 600000 && loan <= 750000) {
    if (LVR >= 80 && LVR <= 81) {
      return 0.9;
    } else if (LVR > 81 && LVR <= 82) {
      return 0.9;
    } else if (LVR > 82 && LVR <= 83) {
      return 1.09;
    } else if (LVR > 83 && LVR <= 84) {
      return 1.09;
    } else if (LVR > 84 && LVR <= 85) {
      return 1.33;
    } else if (LVR > 85 && LVR <= 86) {
      return 1.41;
    } else if (LVR > 86 && LVR <= 87) {
      return 1.63;
    } else if (LVR > 87 && LVR <= 88) {
      return 1.63;
    } else if (LVR > 88 && LVR <= 89) {
      return 2.22;
    } else if (LVR > 89 && LVR <= 90) {
      return 2.37;
    } else if (LVR > 90 && LVR <= 91) {
      return 3.78;
    } else if (LVR > 91 && LVR <= 92) {
      return 3.87;
    } else if (LVR > 92 && LVR <= 93) {
      return 4.08;
    } else if (LVR > 93 && LVR <= 94) {
      return 4.29;
    } else if (LVR > 94 && LVR <= 95) {
      return 4.61;
    }
  } else if (loan > 750000 && loan <= 1000000) {
    if (LVR >= 80 && LVR <= 81) {
      return 0.91;
    } else if (LVR > 81 && LVR <= 82) {
      return 0.91;
    } else if (LVR > 82 && LVR <= 83) {
      return 1.11;
    } else if (LVR > 83 && LVR <= 84) {
      return 1.15;
    } else if (LVR > 84 && LVR <= 85) {
      return 1.41;
    } else if (LVR > 85 && LVR <= 86) {
      return 1.46;
    } else if (LVR > 86 && LVR <= 87) {
      return 1.73;
    } else if (LVR > 87 && LVR <= 88) {
      return 1.75;
    } else if (LVR > 88 && LVR <= 89) {
      return 2.4;
    } else if (LVR > 89 && LVR <= 90) {
      return 2.52;
    } else if (LVR > 90 && LVR <= 91) {
      return 3.82;
    } else if (LVR > 91 && LVR <= 92) {
      return 3.93;
    } else if (LVR > 92 && LVR <= 93) {
      return 4.16;
    } else if (LVR > 93 && LVR <= 94) {
      return 4.32;
    } else if (LVR > 94 && LVR <= 95) {
      return 4.6;
    }
  } else if (loan > 1000000) {
    if (LVR >= 80 && LVR <= 81) {
      return 0.91;
    } else if (LVR > 81 && LVR <= 82) {
      return 0.91;
    } else if (LVR > 82 && LVR <= 83) {
      return 1.11;
    } else if (LVR > 83 && LVR <= 84) {
      return 1.15;
    } else if (LVR > 84 && LVR <= 85) {
      return 1.41;
    } else if (LVR > 85 && LVR <= 86) {
      return 1.46;
    } else if (LVR > 86 && LVR <= 87) {
      return 1.73;
    } else if (LVR > 87 && LVR <= 88) {
      return 1.75;
    } else if (LVR > 88 && LVR <= 89) {
      return 2.4;
    } else if (LVR > 89 && LVR <= 90) {
      return 2.52;
    } else if (LVR > 90 && LVR <= 91) {
      return 3.82;
    } else if (LVR > 91 && LVR <= 92) {
      return 3.93;
    } else if (LVR > 92 && LVR <= 93) {
      return 4.16;
    } else if (LVR > 93 && LVR <= 94) {
      return 4.32;
    } else if (LVR > 94 && LVR <= 95) {
      return 4.6;
    }
  }
};
export const getLMI = ({
  mortgageInsuranceApply,
  TotalDebt,
  TotalPurchaseCosts,
  LoantoValueRatioLVR,
  cityName,
}: {
  mortgageInsuranceApply: "Y" | "N";
  TotalDebt: number;
  TotalPurchaseCosts: number;
  LoantoValueRatioLVR: number;
  cityName: string;
}) => {
  if (mortgageInsuranceApply === "Y") {
    let totalmortgage = TotalDebt;
    let purchasePrice = TotalPurchaseCosts;
    let lvr = (totalmortgage / purchasePrice) * 100;

    let lmiRate: any = getRate({
      loan: totalmortgage,
      LVR: LoantoValueRatioLVR,
    });
    let lmiPremium: any = totalmortgage * (lmiRate / 100);
    let stampDutyRate: any = 0;
    if (cityName === "NSW") {
      stampDutyRate = 9 / 100;
    } else if (cityName === "VIC") {
      stampDutyRate = 10 / 100;
    } else if (cityName === "QLD") {
      stampDutyRate = 9 / 100;
    } else if (cityName === "SA") {
      stampDutyRate = 11 / 100;
    } else if (cityName === "WA") {
      stampDutyRate = 10 / 100;
    } else if (cityName === "ACT") {
      stampDutyRate = 6 / 100;
    } else if (cityName === "NT") {
      stampDutyRate = 10 / 100;
    } else if (cityName === "TAS") {
      stampDutyRate = 10 / 100;
    } else {
      stampDutyRate = 9 / 100;
    }

    let stampDutyVal = lmiPremium * stampDutyRate;
    let totalLmi = stampDutyVal + lmiPremium;
    lvr = LoantoValueRatioLVR * 100;

    if (lvr >= 80) {
      return totalLmi;
    } else {
      return -1;
    }
  } else {
    return 0;
    // if (LMI > 0) {
    //   return LMI;
    // }
  }
};
export const TotalInvestmentPropertiesGrossIncome = ({
  Properties,
}: {
  Properties: TInvestProperties[];
}) => {
  let propertyGrossIncome = 0;
  if (Properties?.length > 0) {
    propertyGrossIncome = Properties?.reduce((total, item) => {
      return total + Number(item.GrossRent.value);
    }, 0);
  }
  return propertyGrossIncome;
};
export const CalculatePropertiesCashFlowAnnual = ({
  propertyno,
  properties,
}: {
  propertyno: number;
  properties: TPropertyGeneratorItem[];
}) => {
  let propertyCashflowAnnual: any = 0.0;
  for (let i = 0; i < propertyno; i++) {
    if (properties[i]?.summaryData?.CashflowAnnual > 0) {
      propertyCashflowAnnual =
        propertyCashflowAnnual + properties[i]?.summaryData?.CashflowAnnual;
    }
  }
  if (propertyCashflowAnnual < 0) {
    return 0;
  } else {
    return propertyCashflowAnnual;
  }
};
export const CalculateTotalLiabilitiesAndExpenses = ({
  CalData,
}: {
  CalData: TCalculatorData;
}) => {
  let total_card_limit =
    CalData?.BorrowingCalculator?.PersonalLiabilities?.CreditCards?.reduce(
      (partialSum: any, nextItem: any) =>
        nextItem?.type === "Credit Card"
          ? partialSum + nextItem.limit
          : partialSum,
      0
    );
  const { homeloanRepayment, propertyLoanRepayment } =
    GetHomeAndPropertyLoanRepayment({
      PPR: CalData.Equity.PPR,
      Properties: CalData.Equity.Properties,
    });
  let totalFinanceRepayment = homeloanRepayment + propertyLoanRepayment || 0;
  let OtherLoanPayments =
    CalData?.BorrowingCalculator?.PersonalLiabilities?.CreditCards?.reduce(
      (partialSum: any, nextItem: any) =>
        nextItem?.type !== "Credit Card"
          ? partialSum + nextItem.minimumPayment
          : partialSum,
      0
    ) * 12 || 0;
  let totalLivingExpense = CalData?.BorrowingCalculator?.LivingExpenses || 0;
  const totalLiabilitiesAndExpenses =
    totalLivingExpense +
      OtherLoanPayments +
      totalFinanceRepayment +
      total_card_limit || 0;
  return {
    totalLiabilitiesAndExpenses,
    total_card_limit,
    totalFinanceRepayment,
    OtherLoanPayments,
    totalLivingExpense,
  };
};
export const GetFinanceMBCValue = ({
  CalData,
}: {
  CalData: TCalculatorData;
}) => {
  let mbc = CalData.Equity.MaxBorrowingCapacity;
  for (let i = 3; i >= 0; i--) {
    if (CalData?.PropertyGenerators[i]?.isLocked) {
      mbc = CalData?.PropertyGenerators[i]?.newmbc;
      break;
    }
  }
  return mbc;
};
