import React from 'react'
import { Spinner } from "react-bootstrap";
type LoadingProps={
    height?:string,
    marginTop?:string
}
export const Loading = ({height = "20vh",marginTop="0px" }:LoadingProps) => {
  return (
    <div
    className={`flex items-center justify-center h-[${height}] mt-[${marginTop}]`}
  >
    <Spinner animation="border" variant="primary" />
  </div>
  )
}
