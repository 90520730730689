import React,{memo} from 'react'
import {  
    ContactDropDown,
    CustomMultiSelect, } from 'global.style';
import { 
    statesOfAustralia,
    PerPageOptions, } 
    from 'utils/constants';
import { Searchbar } from "components/screen-components/Searchbar";
import { Button } from "components/ui/Buttons/Button";
import { TPinderFilters,TPostcodes,TPropertyOptions,TListingOptions } from "types";

type PinderTopFilterProps = {
    postcodes:TPostcodes[],
    propertyOptions:TPropertyOptions[],
    ListingOptions:TListingOptions[],
    TopFilters:TPinderFilters,
    setTopFilters:React.Dispatch<React.SetStateAction<TPinderFilters>>,
    ClearFilter:(event:React.MouseEvent) => void
}
export const PinderTopFilters = memo (({postcodes,propertyOptions,ListingOptions ,TopFilters,setTopFilters,ClearFilter}:PinderTopFilterProps) => {
    return (
    <div className="FilterContainer">
            <div>
              <Searchbar
                SearchQuery={TopFilters.Keyword}
                setSearchQuery={(value: string) => {
                  setTopFilters({ ...TopFilters, Keyword: value });
                }}
                placeholder="Search"
              />
            </div>
            <div className="w-[12rem]">
              <CustomMultiSelect
                filter
                optionLabel="name"
                display="chip"
                className="w-[100%] h-[32px]"
                value={TopFilters.ListingType}
                options={ListingOptions}
                onChange={(e) =>
                  setTopFilters({ ...TopFilters, ListingType: e.value })
                }
                placeholder="Listing Type"
                color="#ced4da"
                maxSelectedLabels={3}
              />
            </div>
            <div className="w-[12rem]">
              <CustomMultiSelect
                filter
                optionLabel="name"
                display="chip"
                className="w-[100%] h-[32px]"
                value={TopFilters.PostCode}
                options={postcodes}
                onChange={(e) =>
                  setTopFilters({ ...TopFilters, PostCode: e.value })
                }
                placeholder="Postcode"
                color="#ced4da"
                maxSelectedLabels={3}
              />
            </div>
            <div className="w-[12rem]">
              <CustomMultiSelect
                filter
                optionLabel="name"
                display="chip"
                className="w-[100%] h-[32px]"
                value={TopFilters.State}
                options={statesOfAustralia}
                onChange={(e) =>
                  setTopFilters({ ...TopFilters, State: e.value })
                }
                placeholder="State"
                color="#ced4da"
                maxSelectedLabels={3}
              />
            </div>

            <div className="w-[12rem]">
              <CustomMultiSelect
                filter
                optionLabel="name"
                display="chip"
                className="w-[100%] h-[32px]"
                value={TopFilters.PropertyType}
                options={propertyOptions}
                onChange={(e) =>
                  setTopFilters({ ...TopFilters, PropertyType: e.value })
                }
                placeholder="Property Type"
                color="#ced4da"
                maxSelectedLabels={3}
              />
            </div>

            <div className="w-[12rem]">
              <ContactDropDown
                filter
                showClear={TopFilters.PerPage > 0 ? true : false}
                className="!w-[100%] !h-[38px]"
                value={TopFilters.PerPage}
                options={PerPageOptions}
                onChange={(e) =>
                  setTopFilters({ ...TopFilters, PerPage: e.value })
                }
                placeholder="Per Page"
                color="#ced4da"
              />
            </div>
            <div>
          <Button
            classes={`ClearFilterButton !h-[38px]`}
            onClick={ClearFilter}
            txt={"Edit Contact"}
            txtPrint={false}
          />
          
        </div>
          </div>
  )
})
