import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type PathData = {
   headerPath:string,
   SidebarPath:string
}
const initialState:PathData={
   headerPath:"/Dashboard",
   SidebarPath:"Dashboard"
}

const PathSlice=createSlice({
    name:'path',
    initialState,
    reducers:{
        setPath:(state,action:PayloadAction<PathData>) => {
        state.SidebarPath=action.payload.SidebarPath
        state.headerPath=action.payload.headerPath
        },
        setSidebarPath:(state,action)=>{
            state.SidebarPath=action.payload.SidebarPath
        },
        setHeaderPath:(state,action)=>{
            state.headerPath=action.payload.headerPath
        } 
    }
})

export const {setPath,setSidebarPath,setHeaderPath} = PathSlice.actions

export default PathSlice.reducer