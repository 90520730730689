import React from "react";
import { InputTexts } from "../InputTexts";
import { checkPasswordStrength } from "../../../utils/helperFunctions";
type InputPasswordProps = {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?:((event: React.FocusEvent<HTMLInputElement>) => void);
  outerclasses?: string;
  width?: string;
  height?: string;
  type?: string;
  name: string;
  placeholder?: string;
  disabled?:boolean;
};
export const InputPassword = ({
  value,
  onChange,
  onBlur,
  outerclasses,
  width,
  height,
  type,
  name,
  placeholder,
  disabled=false
}: InputPasswordProps) => {
  const showPassMessage = (password: string) => {
    let { valid, message } = checkPasswordStrength(password);
    return message;
  };
  return (
    <>
      <InputTexts
        placeholder={placeholder}
        outerclasses={outerclasses}
        height={height}
        type={type}
        name={name}
        value={value || ""}
        onChange={onChange}
        IconRight={true}
        onBlur={(e)=>onBlur && onBlur(e)}
        disabled={disabled}
      />
      <p
        className={`${showPassMessage(
          value || ""
        )} text-[13px] mt-[5px] w-[100%] text-center`}
      >
        {showPassMessage(value || "")}
      </p>
    </>
  );
};
