import styled from "styled-components";
import { ProgressBar } from "primereact/progressbar";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { InputText, InputTextProps } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { MultiSelect } from "primereact/multiselect";
import { Timeline } from "primereact/timeline";
import { Checkbox } from "primereact/checkbox";
import { Slider } from "primereact/slider";
import Sliderimg from "assets/images/PurpleCircle.png";
import { InputMask } from "primereact/inputmask";
import { InputNumberProps } from "primereact/inputnumber";
type OuterContainerProps = {
  overflow?: string;
};
type SidebarContainerProps = {
  open: boolean;
  marginleft: string;
};
type LogoContainerProps = {
  open: boolean;
};
type TextProps = {
  fontSize?: string;
  fontWeight?: string;
  color?: string;
};
type AvatarContainerProps = {
  width?: string;
  height?: string;
  bgimg?: string;
};
type NavItemContainerProps = {
  activeColor: string | undefined;
  active: boolean;
  height?: string;
  marginTop?: string;
};
type NavItemProps = {
  gap?: string;
};
type InnerContainerProps = {
  margin?: string;
  padding?: string;
  bg?: boolean;
  width?: string;
  height?: string;
};
type ContainerProps = {
  margin?: string;
  padding?: string;
  minHeight?: string;
};
type ButtonstyleProps = {
  width?: string;
  txt?: string;
  paddingTop?: string;
  height?: string;
};
interface InputTextProp extends InputTextProps {
  txtBlur?: boolean;
}
interface InputnumberProps {
  txtBlur?: boolean;
}
type PaginationListItemProps = {
  active: boolean;
};
type DropdownProps = {
  width?: string;
  height?: string;
};

type MultiSelectProps = {
  width?: string;
  height?: string;
  color?: string;
};
type PropertyContentProps = {
  admin: boolean;
  pathname: string;
  idx: number;
};
type CustomRiskSliderProps = {
  bgColor?: string;
  color?: string;
  RiskVal: number;
  txtColor?: string;
};

type TooltipProps = {
  left?: number;
  top?: string | number;
  width?: string | number;
  textAlign?: string | number;
  borderRadius?: string | number;
  show?: boolean;
};

export const CustomInputPhone = styled(InputMask)`
  height: 40px;
  font-family: "Inter" !important;
  font-size: 12px !important;
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
`;
export const MySlider = styled(Slider)`
  .p-slider-handle {
    background: url("${Sliderimg}") !important;
    width: 20px;
    height: 20px;
    /* background:url("../../assets/images/SliderCirlce.png") !important; */
    /* https://media.geeksforgeeks.org/wp-content/uploads/rk.png */
  }
`;
export const DonotContainer = styled.div`
  width: 12%;
  min-width: 193px;
  height: 150px;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .piechart-section {
    z-index: 2;
    margin-top: -28px;
    .donut {
      width: 150px !important;
      height: 150px !important;
    }
    .semidonut {
      margin-top: -85px;
      text-align: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      position: relative;
      .goal-container {
        display: flex;
        flex-direction: column;
        font-family: "Inter";
        width: 100%;
        .goal-text-heading {
          font-family: "Inter";
          font-size: 12px;
          font-weight: 500;
          text-align: center;
          text-transform: uppercase;
          color: #ccc;
        }
        .goal-text {
          font-family: "Inter";
          font-size: 12px;
          font-weight: 500;
          text-align: center;
          color: #ccc;
        }
      }
      .indicators {
        display: flex;
        justify-content: space-between;
        width: 100%;
        position: relative;
        span {
          font-size: 11px;
          font-family: "Inter";
          color: #ccc;
        }
      }
      .year {
        margin-top: -5px !important;
        span {
          font-size: 11px;
          color: #ccc;
        }
      }
    }
  }

  &:hover {
    box-shadow: 0 1.5px 9.9px 0 rgba(11, 61, 126, 0.02),
      0 7.3px 38.9px 0 rgba(11, 61, 126, 0.02),
      0 19px 122px 0 rgba(7, 62, 132, 0.04);
    .piechart-section {
      .semidonut {
        .goal-container {
          .goal-text-heading {
            color: #1a2b56;
          }
          .goal-text {
            color: #1a2b56;
          }
        }
        .year {
          span {
            color: #1a2b56;
          }
        }
      }
    }
  }
`;
export const InputTexts = styled(InputText)<any>`
  width: ${({ width }) => (width ? width : "320px !important")};
  border: 1px solid #cccccc;
  border-radius: 4px !important;
  line-height: normal;
  letter-spacing: normal;
  font-family: "Inter" !important;
  font-size: 12px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  height: 36px;
  padding: 15px 0px 14px 16.8px;
  color: var(--black-main-color);
  overflow: hidden;
  position: relative !important;
  z-index: 5;

  &::placeholder {
    font-family: "Inter" !important;
    font-size: 12px !important;
  }
  &:focus {
    ${({ txtBlur }) =>
      txtBlur
        ? "border: 1px solid #cccccc !important;box-shadow:none !important"
        : " border: 1px solid blue !important"}
  }
  &:hover {
    ${({ txtBlur }) => (txtBlur ? "border: 1px solid #cccccc !important" : "")}
  }
  &:hover::after {
    content: "Premium Access Only";
    position: absolute;
    font-size: 13px;
    width: 100%;
    left: 0;
    top: 0px;
    border-radius: 6px;
    background-color: #272660;
    color: white !important;
    text-shadow: none !important;
    text-align: center;
    opacity: 1 !important;
    z-index: 1000 !important;
    padding: 9px;
    ${({ txtBlur }) => (txtBlur ? "display:block !important" : "display:none")}
  }
  ${({ txtBlur }) =>
    txtBlur
      ? "color: transparent !important;text-shadow: 0 0 8px #000;background-color: transparent !important;user-select: none"
      : ""}
`;
export const InputNumbers = styled(InputNumber)<any>`
  position: relative;
  &:hover::after {
    content: "Premium Access Only";
    position: absolute;
    font-size: 13px;
    width: 100%;
    left: 0;
    top: 0px;
    border-radius: 6px;
    background-color: #272660;
    padding: 9px;
    color: white !important;
    text-shadow: none !important;
    opacity: 1 !important;
    z-index: 1000 !important;
    text-align: center;
    ${({ txtBlur }) => (txtBlur ? "display:block" : "display:none")}
  }
  .p-inputtext:enabled:focus {
    border-image-source: linear-gradient(
      to right,
      var(--gredient-blue-1) 0%,
      var(--gredient-blue-2) 100%
    ) !important;
    border-style: solid !important;
    border-width: 1px !important;
    border-image-slice: 1;
    border-radius: 4px !important;
  }
  .p-inputnumber-input {
    width: ${({ width }) => (width ? width : "320px")};
    border: 1px solid #cccccc;
    border-radius: 4px !important;
    background-color: ${({ bgColor }) =>
      bgColor ? bgColor : "white"} !important;
    line-height: normal;
    letter-spacing: normal;

    font-family: "Inter" !important;
    font-size: 12px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    height: 36px;
    padding: 15px 0px 14px 16.8px;
    color: var(--black-main-color);

    overflow: hidden;
    position: relative;
    ${({ txtBlur }) =>
      txtBlur
        ? "color: transparent !important;text-shadow: 0 0 8px #000;background-color: transparent !important;user-select: none"
        : ""}
  }
`;
export const SpendingTableContainer = styled.div`
  width: 100%;

  overflow: hidden !important;
  .p-datatable {
    width: 100%;
    .p-datatable-wrapper {
      .p-datatable-table {
        .p-datatable-thead {
          tr {
            th {
              .p-column-title {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
`;
export const ToolTipContainer = styled.div<TooltipProps>`
  position: absolute;
  left: ${({ left }) => (left ? left : 0)};
  top: ${({ top }) => (top ? top : "-70px")};
  /* display: inline-block; */
  font-family: "Inter";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.34;
  letter-spacing: normal;
  color: black !important;
  box-shadow: 0 0.4px 2.7px 0 rgba(0, 0, 0, 0.01),
    0 1.5px 5.6px 0 rgba(0, 0, 0, 0.01), 0 3.8px 11.2px 0 rgba(0, 0, 0, 0.01),
    0 7.3px 22px 0 rgba(0, 0, 0, 0.02), 0 12.3px 40.4px 0 rgba(0, 0, 0, 0.02),
    0 19px 69px 0 rgba(0, 0, 0, 0.03);
  background-color: #fff;
  width: ${({ width }) => (width ? width : "125px")};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "left")};
  height: auto;
  padding: 12px 8px;
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "4px")};
  z-index: 100 !important;
  transition: all 0.3s ease;
  display: ${(props) => (props.show === true ? "inline-block" : "none")};
  /* opacity: ${(props) => (props.show === true ? 1 : 0)}; */
`;

export const CustomTimeLine = styled(Timeline)`
  margin-top: 21px;
  padding: 0 20px;
  padding-left: 45px;
`;
export const CustomCheckBox = styled(Checkbox)`
  width: 20px !important;
  height: 20px !important;
  box-shadow: none !important;
  outline: none !important;
  .p-checkbox-box {
    box-shadow: none !important;
    outline: none !important;
    &:hover {
      border-color: #ced4da !important;
    }
    .p-checkbox-icon.p-icon {
      width: 10px !important;
      height: 10px !important;
    }
  }
  .p-highlight {
    border-color: var(--main-blue) !important ;
    background-color: transparent !important;
    &:hover {
      border-color: var(--main-blue) !important ;
    }
    .p-checkbox-icon {
      color: var(--main-blue) !important;
      font-size: 12px !important;
    }
  }
`;
export const CustomToast = styled(Toast)`
  .p-toast-message-info {
    width: 100% !important;
    .p-toast-message-content {
      button {
        margin-left: 10px;
      }
    }
  }
`;
export const CustomMultiSelect = styled(MultiSelect)<MultiSelectProps>`
  border: 1px solid ${({ color }) => (color ? color : "white")} !important; //#ced4da
  outline: none !important;
  .p-multiselect-label-container {
    .p-placeholder {
      font-size: 13px !important;
      font-family: "Inter" !important;
    }
    .p-multiselect-label {
      font-size: 13px !important;
      display: flex !important;
      height: 100% !important;
      padding-left: 10px;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      align-items: center !important;
      font-family: "Inter" !important;
      .p-multiselect-token {
        border-radius: 15px !important;
      }
    }
  }
  .p-multiselect-trigger {
    .pi-chevron-down,
    .pi-chevron-up {
      color: #999999 !important;
    }
  }
  box-shadow: none !important;
  width: ${({ width }) => width && width};
  height: ${({ height }) => (height ? height : "36px")}!important;
`;
export const ContactDropDown = styled(Dropdown)<DropdownProps>`
  width: ${({ width }) => (width ? width : "100% !important")};
  height: ${({ height }) => (height ? height : "32px")};
  border: 1px solid #ced4da !important;
  font-family: "Inter" !important;
  font-size: 12px !important;
  outline: none !important;
  box-shadow: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  .p-dropdown-label {
    font-family: "Inter" !important;
    font-size: 12px !important;
  }
  &:focus {
    border: 1px solid #ced4da !important;
  }
`;
export const CustomProgressBar = styled(ProgressBar)`
  width: 30%;
  height: 7px;
  .p-progressbar-value {
    background-color: #62c68a;
  }
  .p-progressbar-label {
    opacity: 0;
  }
`;
export const TermsContainer = styled.div`
  margin: 51px 32px 59px;
  padding: 48px 0px 48px 0px;
  background-color: var(--white);
  box-shadow: 0 1.5px 9.9px 0 rgba(11, 61, 126, 0.02),
    0 7.3px 38.9px 0 rgba(11, 61, 126, 0.02),
    0 19px 122px 0 rgba(7, 62, 132, 0.04);
  border-radius: 8px;
  box-sizing: border-box;
  margin-left: 1rem;
  margin-right: 1rem;
  .Terms {
    display: flex;
    flex-direction: column;

    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 38px;
    padding-right: 28px;
    padding-left: 26px;
    h1 {
      font-family: "Inter";
      font-size: 24px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--grey-dark);
      margin-bottom: 0px;
      text-align: center;
      width: 100%;
    }
    div {
      h2 {
        font-family: "Inter";
        font-size: 20px;
        font-weight: 600;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--grey-dark) !important;
        margin-bottom: 0px;
      }
      p {
        font-family: "Inter";
        line-height: 20px;
        color: var(--grey-dark) !important;
      }
      .termtable {
        .p-datatable-table {
          .p-datatable-tbody {
            tr {
              vertical-align: initial;
            }
          }
        }
      }
    }
  }
`;

export const OuterContainer = styled.div<OuterContainerProps>`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  overflow: ${({ overflow }) => overflow && overflow} !important;
  box-sizing: border-box;
`;
export const HomeContainer = styled.div`
  width: 100%;
  background-color: var(--off-white);
  box-sizing: border-box;
  min-width: 0;
`;

export const SideBarContainer = styled.aside<SidebarContainerProps>`
  width: ${({ open }) => (open ? "320px" : "320px")};
  /* open ? "290px" : "100px"} */
  overflow-x: hidden;

  position: relative;
  height: auto;
  box-shadow: 0.3px 0px 3.1px 0 rgba(0, 0, 0, 0),
    1.4px 0px 6.5px 0 rgba(0, 0, 0, 0), 3.4px 0px 13px 0 rgba(0, 0, 0, 0),
    6.5px 0px 25.5px 0 rgba(0, 0, 0, 0.01),
    11px 0.1px 46.9px 0 rgba(0, 0, 0, 0.01),
    17px 0.1px 80px 0 rgba(0, 0, 0, 0.01);
  background-image: linear-gradient(
    165deg,
    var(--gredient-blue-1) 0%,
    var(--gredient-blue-2) 123%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-left: 12px;
  padding-right: 13px;
  padding-bottom: 486px;
  transition: all 0.2s ease;
  z-index: 34;
  margin-left: ${({ marginleft }) =>
    marginleft === "true" ? "0px" : "-350px"};

  .crossIcon {
    position: absolute;
    right: 20px;
    top: 20px;
    display: none;
  }
  @media (max-width: 1000px) {
    position: absolute;
    .crossIcon {
      display: block;
    }
  }
  .sidebar-footer {
    position: absolute;
    margin-left: -70px;
    bottom: 48px;
    p {
      margin-bottom: 0px;
      font-family: Inter;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: var(--text-color);
    }
  }
`;
export const TimelineContainer = styled.div<any>`
  margin-bottom: 20px;
  padding: 85px 35px 48px;
  height: 280px;

  overflow-x: auto;

  z-index: 0;
  position: relative;
  &::-webkit-scrollbar {
    height: 6px !important;

    margin-bottom: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f3f4f8 !important;
  }

  &::-webkit-scrollbar-thumb {
    width: 124px !important;
    max-height: 6px !important;
    border-radius: 30px !important;
    background-color: #666666 !important;
  }

  .timeline {
    width: ${(props) => props.idx > 4 && `${12 * props.idx}%`};

    @media (max-width: 1760px) {
      width: ${(props) =>
        props.idx > 4 &&
        `${props.idx > 20 ? 15 * props.idx + 5 : 15 * props.idx}%`};
    }
    @media (max-width: 1600px) {
      width: ${(props) =>
        props.idx > 4 &&
        `${props.idx > 20 ? 17.5 * props.idx + 10 : 17.5 * props.idx}%`};
    }
    @media (max-width: 1425px) {
      width: ${(props) =>
        props.idx > 4 &&
        `${props.idx > 20 ? 22 * props.idx + 10 : 22 * props.idx}%`};
    }
    height: 3px;
    margin: 25px 0 16px 0;
    flex-grow: 0;
    object-fit: cover;
    transform: rotate(-360deg);
    background-image: linear-gradient(
      to right,
      var(--gredient-blue-1) 0%,
      var(--gredient-blue-2) 100%
    );
    background-size: contain;

    position: relative;

    &::after {
      content: "";
      height: 3px;
      right: 0;
      top: -3px;
      position: absolute;
      border: solid var(--gredient-blue-2);
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;

      transform: rotate(-45deg);
    }
  }
`;

export const Circle = styled.div<any>`
  height: 20px;
  width: 20px;
  flex-grow: 0;
  object-fit: contain;
  background-color: var(--plan-circle);
  border-radius: 50%;
  position: absolute;
  bottom: -8px;
  left: ${(props) =>
    props.idx === 0
      ? "25px"
      : props.length > 4
      ? `${props.idx * 200}px`
      : `${props.idx * 350}px`};
`;

export const Card = styled.div<any>`
  z-index: 100;
  left: ${(props) =>
    props.idx === 0
      ? "25px"
      : props.length > 4
      ? `${props.idx * 200}px`
      : `${props.idx * 350}px`};
  top: ${(props) => (props.idx % 2 === 0 ? "35px" : `-120px`)};
  background-color: white;
  width: 159px;
  height: 90px;
  flex-grow: 0;
  gap: 16px;
  margin: 0 362px 25px 0;
  padding: 16px;
  border-radius: 8px;
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: 0 1.5px 9.9px 0 rgba(11, 61, 126, 0.02),
    0 7.3px 38.9px 0 rgba(11, 61, 126, 0.02),
    0 19px 122px 0 rgba(7, 62, 132, 0.04);
  position: absolute;
  a,
  div {
    filter: ${(props) =>
      props.admin === true
        ? "blur(0px)"
        : props.idx > 0
        ? "blur(5px)"
        : "blur(0px)"};
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #323232;
  }
`;
export const NavItemContainer = styled.div<NavItemContainerProps>`
  padding-left: 20px;
  padding-right: 12px;
  width: 248px;
  height: ${({ height }) => (height ? height : "48px")};
  text-decoration: none;
  border-radius: 8px;
  background-color: ${(props) =>
    props.active ? `${props.activeColor} !important` : ""};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  transition: all 0.2s ease;
  &:hover {
    background-color: ${(props) => props.activeColor};
  }
`;
export const NavItem = styled.div<NavItemProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ gap }) => gap && gap};
`;
export const NavIcon = styled.span``;
export const NavSubContainer = styled.div``;

export const LogoContainer = styled.div<LogoContainerProps>`
  width: ${({ open }) => (open ? "151px" : "0%")};
  height: 34.6px;
  margin: 6px 51px 4.6px 0;
  object-fit: contain;
  margin-top: 23px;
  margin-left: 0px;
  margin-bottom: 70px;
  cursor: pointer;
`;
export const HeaderContainer = styled.header`
  box-shadow: 0 0.2px 3.1px 0 rgba(0, 0, 0, 0),
    0 1px 6.5px 0 rgba(0, 0, 0, 0.01), 0 2.4px 13px 0 rgba(0, 0, 0, 0.01),
    0 4.6px 25.5px 0 rgba(0, 0, 0, 0.01), 0 7.8px 46.9px 0 rgba(0, 0, 0, 0.02),
    0 3px 80px 0 rgba(0, 0, 0, 0.01);
  background-color: var(--white);
  padding: 12px 16px !important;
  padding-left: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;

  .coming-DropDown {
    position: absolute;
    width: 200px;
    height: 50px;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.02);
    top: 70px;
    right: 70px;
    display: flex;
    flex-direction: row;
    justify-content: center !important;
    align-items: center !important;
    text-align: center;
    border-radius: 10px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: normal;
    z-index: 999;
    animation-duration: 1s;
    display: none;
  }
`;
export const HeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  i {
    cursor: pointer;
    display: none;
    @media (max-width: 1000px) {
      display: flex;
      align-items: center;
      margin-right: 10px;
      color: black !important;
    }
  }
`;
export const HeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  align-items: center;
  gap: 30px;
  justify-content: space-between;
  a {
    text-decoration: none;
  }
  @media only screen and (max-width: 610px) {
    gap: 15px;
  }
`;
export const Text = styled.p<TextProps>`
  font-family: "Inter", sans-serif;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "14px")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "normal")};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => (props.color ? props.color : "var(--grey-dark)")};
`;

export const AvatarContainer = styled.div<AvatarContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center !important;
  width: ${(props) => (props.width ? props.width : "56px")};
  height: ${(props) => (props.width ? props.height : "56px")};
  object-fit: contain;
  background-color: var(--grey-dark);
  border-radius: 50px;
  /* overflow: hidden; */
  background: var(--grey-dark);
  background-image: ${({ bgimg }) =>
    bgimg && bgimg.length > 0 && `url(${bgimg})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  .file {
    opacity: 0;
    cursor: pointer !important;
    width: ${(props) => (props.width ? props.width : "56px")};

    position: absolute;
    bottom: -10px;
    z-index: 4;
  }

  .file input[type="file"]::-webkit-file-upload-button {
    cursor: pointer;
  }
  .edit-icon-container {
    position: absolute;
    bottom: -10px;
    background-color: white;
    color: var(--grey-dark);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 50px;
    box-shadow: 0 0.2px 3.1px 0 rgb(0 0 0 / 0%), 0 1px 6.5px 0 rgb(0 0 0 / 1%),
      0 2.4px 13px 0 rgb(0 0 0 / 1%), 0 4.6px 25.5px 0 rgb(0 0 0 / 1%),
      0 7.8px 46.9px 0 rgb(0 0 0 / 2%), 0 3px 80px 0 rgb(0 0 0 / 1%);
    cursor: pointer;
    z-index: 1;
  }
`;
export const UserAvatar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  object-fit: contain;
  background-color: var(--grey-dark);
  border-radius: 50px;
  color: ${(props) => (props.color ? props.color : "var(--grey-dark)")};
`;

export const SearchbarContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  border: solid 1px var(--grey-smooky);
  padding-left: 10px;
  // width: 272px;
  overflow: hidden;
  height: 36px;
  .search-icon {
    color: #999;
  }
`;
export const SearchbarText = styled.input`
  width: 100%;
  border-radius: 0px;
  background-color: transparent;
  border: none !important;
  outline: none !important;
  padding: 10px 10px 10px 10px;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  color: #999;
  &:focus {
    outline: none !important;
    border: none !important;
  }
`;

export const SearchResultContainer = styled.div`
  position: absolute;
  top: 65px;
  border-radius: 8px;
  border: solid 1px var(--grey-smooky);
  padding-left: 10px;
  width: 272px;
  max-height: 300px;
  min-height: 150px;
  overflow: hidden auto;
  background: var(--white);
  z-index: 1000;
  box-shadow: 0 1.5px 9.9px 0 rgba(11, 61, 126, 0.02),
    0 7.3px 38.9px 0 rgba(11, 61, 126, 0.02),
    0 19px 122px 0 rgba(7, 62, 132, 0.04);
  a {
    color: black;
    text-decoration: none;
    font-size: 13px;
  }
  &::-webkit-scrollbar {
    height: auto !important;
    width: 7px !important;
  }

  &::-webkit-scrollbar-track {
    background: #f3f4f8 !important;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 30px !important;
    background-color: #666666 !important;
  }
`;
export const Container = styled.div<ContainerProps>`
  margin: ${({ margin }) => (margin ? margin : "0px 1rem 0px 1rem")};
  padding: ${({ padding }) => (padding ? padding : "19px 0px 19px 0px")};
  ${({ minHeight }) => minHeight && { minHeight: minHeight }}
`;
export const InnerContainer = styled.div<InnerContainerProps>`
  margin: ${({ margin }) => (margin ? margin : "0px 0rem 0px 0rem")};
  padding: ${({ padding }) => (padding ? padding : "1.5rem 32px 1rem 32px")};
  ${({ width }) => width && { width: width }}
  ${({ height }) => height && { height: height }}
  background-color: ${({ bg }) => (bg ? "transparent" : "var(--white)")};
  box-shadow: ${({ bg }) =>
    bg
      ? "none"
      : "0 1.5px 9.9px 0 rgba(11, 61, 126, 0.02), 0 7.3px 38.9px 0 rgba(11, 61, 126, 0.02), 0 19px 122px 0 rgba(7, 62, 132, 0.04)"};
  border-radius: 8px;
  box-sizing: border-box;
  z-index: 1;
  overflow-x: hidden !important;
  overflow-y: hidden !important;

  .profile-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap-reverse;
    h1 {
      font-family: "Inter";
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--blackColor);
    }
    .content-right-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      min-height: 36px;
      gap: 10px;
      // margin-left: 20px;
    }
  }
  .avatar-container {
    display: flex;
    flex-direction: row;

    .avatar-text-container {
      display: flex;
      flex-direction: column;
      margin-left: 13px;
      justify-content: center;
      p {
        font-family: "Inter";
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        margin-bottom: 0px;
      }
      .main-text {
        margin-bottom: 2px;
        font-size: 20px;
        color: var(--blackColor);
      }
      .sub-text {
        font-size: 16px;
        color: var(--grey);
      }
    }
  }

  .User-input-container {
    .User-input-group-container {
      display: flex;
      flex-direction: row;
      margin-bottom: 30px;
      .User-input-group {
        display: flex;
        flex-direction: column;
        label {
          font-family: Inter;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: var(--black-main-color);
          margin-bottom: 8px;
        }
        input {
          border-radius: 4px;
          padding: 15px 0px 14px 16.8px;
          width: 336px;
          border: 1px solid #cccccc;
          height: 36px;
          outline: none;
          font-family: Inter;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: var(--black-main-color);

          &:focus {
            outline: none;
          }
        }
        .dropDown-container {
          .Drop-box {
            width: 336px;
            border: 1px solid #cccccc;
            border-radius: 4px;
            outline: none;
            overflow: hidden;
            height: 36px;
            padding-top: 5px;
            box-sizing: border-box;
            .dropDown-field {
              width: 100%;
              border-radius: 0px;
              border: none !important;
              padding: none !important;
              font-family: "Inter" !important;
              outline: none;
              padding-left: 10px;
              padding-top: -10px;
              height: auto !important;
              &:focus {
                outline: none;
              }
            }
          }
        }
      }
      .group1 {
        margin-right: 48px;
      }
    }
  }
  table {
    thead {
      tr {
        th {
          font-family: "Inter";
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: var(--grey-dark);
        }

        .name {
          padding-left: 81px;
        }
      }
    }
    tbody {
      tr {
        td {
          font-family: "Inter";
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          box-sizing: border-box;
          color: var(--grey-dark);
          white-space: nowrap;
          /* overflow: hidden; */
          text-overflow: ellipsis;

          cursor: pointer;
          height: 60px;
          vertical-align: middle;

          a {
            text-decoration: none;
            color: var(--grey-dark);
          }
        }
        .LineTd {
          overflow: visible;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: 16px;
            left: -5px;
            width: 2.5px;
            height: 31px;
            background-color: var(--grey-smooky);
          }
        }
        .name {
          font-weight: 600;
          padding-left: 32px;
          display: flex;
          flex-direction: row;
          align-items: center;

          gap: 18px;
        }
        .avatar-column {
          display: flex;
          align-items: center;
          gap: 18px;
          padding-left: 16px;
          font-weight: 600;
        }
        .progressContainer {
          display: flex;
          justify-content: end;
          padding-right: 23px;
          .progress {
            height: 40px;
            width: 35px;
            background-color: #fff;
          }
        }
      }
    }
  }
  .pagination-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 32px;
  }
  .LaunchingCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 1rem;
    text-align: left;
    h1 {
      font-family: "Inter";
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: var(--grey-dark);
      margin-top: 44px;
      margin-bottom: 260px;
    }
    .LaunchingCard-content-container {
      display: flex;
      justify-content: center;
      .LaunchingCard-content {
        width: 582px;
        background: linear-gradient(
          247deg,
          var(--gredient-blue-1),
          var(--gredient-blue-2) 123%
        );
        display: flex;
        flex-direction: column;
        padding-left: 92px;
        padding-bottom: 48px;
        p {
          font-size: 18px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: var(--primary-yellow);
          padding-top: 48px;
          padding-bottom: 0px;
          display: flex;
          flex-direction: row;
          align-items: center;
          div {
            width: 59px;
            height: 1px;
            flex-grow: 0;
            object-fit: contain;
            background-color: var(--primary-yellow);
            margin-right: 10px;
          }
        }
        h1 {
          font-family: "Inter";
          font-size: 48px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: var(--text-color);
          margin-bottom: 0px;
          margin-top: 0px;
          margin-left: 0px;
        }
      }
    }
  }
  .PinderPageContainer {
    padding-left: 16px;
    padding-right: 24px;
    .pageTitle {
      font-family: "Inter";
      font-size: 24px;
      font-weight: 600;
      color: #323232;
    }
    .FilterContainer {
      display: flex;
      gap: 5px;
      flex-wrap: wrap;
      margin-top: 20px;
      margin-bottom: 10px;
      width: 60vw;
      .ClearFilterButton {
        display: flex !important;
        justify-content: center;
        align-items: center;
        gap: 2px !important;
        background-color: white !important;
        width: auto;
        height: auto;
        padding: 8px 10px !important;
        border-color: #cccccc !important;
        color: #262660 !important;
        font-family: "Inter" !important;
        font-size: 12px !important;
        border-radius: 3px !important;
        border: 1px solid #ced4da !important;
      }
    }
  }
`;
export const ButtonGradient = styled.button<ButtonstyleProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-family: "Inter";
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text-color);
  border: none;
  cursor: pointer;
  width: ${({ width }) => (width ? width : "124px")};
  height: 40px;
  object-fit: contain;
  border-radius: 4px;
  padding: 10px 22px;
  background-image: linear-gradient(
    to right,
    var(--gredient-blue-1) 0%,
    var(--gredient-blue-2) 100%
  );
  ${({ txt }) =>
    (txt === "Next" || txt === "Back") &&
    `
  border-radius:4px;
  color:var(--main-blue);
  background:white !important;
  border: 1px solid var(--main-blue);
  
  `}
  &:hover {
    background: var(--main-blue);
  }
`;
export const ButtonContainer = styled.div<ButtonstyleProps>`
  padding: ${({ paddingTop }) =>
    paddingTop != null ? "10px 22px" : "14px 40px"};
  border-radius: 4px;
  box-shadow: 0 1.5px 9.9px 0 rgba(11, 61, 126, 0.02),
    0 7.3px 38.9px 0 rgba(11, 61, 126, 0.02),
    0 19px 122px 0 rgba(7, 62, 132, 0.04);
  background-color: var(--main-blue);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  height: ${({ height }) => (height ? height : "40px")};

  span {
    margin: 0;
    padding: 0;
    font-family: "Inter" !important;
    font-size: ${({ paddingTop }) => (paddingTop != null ? "13px" : "15px")};
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--white);
  }
`;
export const CustomInputNumber = styled.span<InputnumberProps>`
  position: relative;
  .p-inputnumber {
    width: 100%;
    height: 40px;
    font-family: "Inter" !important;
    font-size: 12px !important;
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
    &:hover::after {
      content: "Premium Access Only";
      position: absolute;
      font-size: 13px;
      width: 100%;
      left: 0;
      top: 0px;
      border-radius: 6px;
      background-color: #272660;
      padding: 9px;
      color: white !important;
      text-shadow: none !important;
      opacity: 1 !important;
      z-index: 1000 !important;
      text-align: center;
      ${(props) => (props.txtBlur ? "display:block" : "display:none")}
    }
    .p-inputtext {
      outline: none !important;
      box-shadow: none !important;
      border: 1px solid #ced4da !important;
      ${({ txtBlur }) =>
        txtBlur
          ? "color: transparent !important;text-shadow: 0 0 8px #000;background-color: transparent !important;user-select: none"
          : ""}
    }
  }
`;

export const CustomInputTextWithIcon = styled.span<InputnumberProps>`
  border: 1px solid #ced4da !important;
  border-radius: 5px;
  .p-inputtext {
    font-family: "Inter" !important;
    font-size: 12px !important;
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
    width: 90%;
    ${({ txtBlur }) =>
      txtBlur
        ? "color: transparent !important;text-shadow: 0 0 8px #000;background-color: transparent !important;user-select: none"
        : ""}
  }
`;
export const CustomInputText = styled(InputText)<InputTextProp>`
  width: ${({ width }) => width && width};
  height: ${({ height }) => height && height};
  font-family: "Inter" !important;
  font-size: 12px !important;
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
  position: relative;
  ${({ txtBlur }) =>
    txtBlur
      ? "color: transparent !important;text-shadow: 0 0 8px #000;background-color: transparent !important;user-select: none"
      : ""}
  &:hover {
    ${({ txtBlur }) =>
      txtBlur
        ? "background-color:#272660 !important"
        : "transparent !important"}
  }
  &:hover::after {
    content: "Premium Access Only";
    position: absolute;
    font-size: 13px;
    width: 100%;
    left: 0;
    top: 0px;
    border-radius: 6px;
    background-color: #272660;
    color: white !important;
    text-shadow: none !important;
    text-align: center;
    opacity: 1 !important;
    z-index: 1000 !important;
    padding: 9px;

    ${({ txtBlur }) => (txtBlur ? "display:block !important" : "display:none")}
  }
`;

export const PaginationListItem = styled.li<PaginationListItemProps>`
  width: 32px;
  height: 32px;
  flex-grow: 0;
  object-fit: contain;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 1.5px 9.9px 0 rgba(11, 61, 126, 0.02),
    0 7.3px 38.9px 0 rgba(11, 61, 126, 0.02),
    0 19px 122px 0 rgba(7, 62, 132, 0.04);
  background-color: var(--white);
  border: 1px solid;
  border-color: ${(props) =>
    props.active === true ? "var(--main-blue)" : "#dfe3e8"};
  color: ${(props) => (props.active === true ? "var(--main-blue)" : "#000")};
  cursor: pointer;
  a {
    text-decoration: none;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;

    cursor: pointer;
  }
`;

export const RiskProgressContainer = styled.div`
  margin-top: 20px;
  padding: 24px 34px 31px 32px;
  border-radius: 8px;
  box-shadow: 0 1.5px 9.9px 0 rgba(11, 61, 126, 0.02),
    0 7.3px 38.9px 0 rgba(11, 61, 126, 0.02),
    0 19px 122px 0 rgba(7, 62, 132, 0.04);
  background-color: var(--white);

  overflow-x: hidden;

  h1 {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-main-color);
    padding-bottom: 50px;
  }
  .Ecllipse-container {
    margin-top: 15px;
    transition: all 0.3s ease-in-out;
    z-index: 5;
  }
  .progrssBar-container {
    height: 3px;
    background-color: #ebf2fa;
    flex-grow: 0;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    z-index: 0;
    margin-top: -10px;
    .progress-bar {
      width: 0%;
      height: 3px;
      border-radius: 10px;
      background-color: yellow;
      z-index: 0;
      transition: all 0.3s ease-in-out;
    }
  }
  .progessBar-actions-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 20px;
    .progress-txt-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .line1 {
        @media (max-width: 2560px) {
          margin-left: 10%;
        }
        @media (max-width: 2200px) {
          margin-left: 4%;
        }
        @media (max-width: 1870px) {
          margin-left: 0%;
        }
        @media (max-width: 1600px) {
          margin-left: -5%;
        }
        @media (max-width: 1430px) {
          margin-left: -9%;
        }
      }
      .progress-Text {
        font-family: Inter;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #cccccc;
        position: relative;
        cursor: pointer;
      }
      .text1 {
      }

      .text2 {
        position: relative;
        left: 30%;
        @media (max-width: 2560px) {
          left: 50%;
        }
        @media (max-width: 2400px) {
          left: 46%;
        }
        @media (max-width: 2300px) {
          left: 42%;
        }
        @media (max-width: 2160px) {
          left: 37%;
        }
        @media (max-width: 2000px) {
          left: 33%;
        }
        @media (max-width: 1820px) {
          left: 24%;
        }

        @media (max-width: 1650px) {
          left: 21%;
        }
        @media (max-width: 1550px) {
          left: 17%;
        }
        @media (max-width: 1400px) {
          left: 13%;
        }
      }
      .text3 {
        position: relative;
        left: 65%;
        @media (max-width: 2560px) {
          left: 92%;
        }
        @media (max-width: 2450px) {
          left: 87%;
        }
        @media (max-width: 2360px) {
          left: 83%;
        }
        @media (max-width: 2270px) {
          left: 79%;
        }
        @media (max-width: 2200px) {
          left: 75%;
        }
        @media (max-width: 2100px) {
          left: 71%;
        }
        @media (max-width: 2000px) {
          left: 67%;
        }
        @media (max-width: 1900px) {
          left: 63%;
        }
        @media (max-width: 1800px) {
          left: 57%;
        }
        @media (max-width: 1700px) {
          left: 53%;
        }
        @media (max-width: 1650px) {
          left: 50%;
        }
        @media (max-width: 1600px) {
          left: 47%;
        }
        @media (max-width: 1500px) {
          left: 44%;
        }
        @media (max-width: 1430px) {
          left: 40%;
        }
        @media (max-width: 1330px) {
          left: 36%;
        }
        @media (max-width: 1260px) {
          left: 32%;
        }
      }
      .progress {
        width: 2px;
        height: 10px;
        background-color: var(--grey-smooky);
        margin-bottom: 14px;
      }
      .line2 {
        position: relative;
        left: 30%;
        @media (max-width: 2560px) {
          left: 50%;
        }
        @media (max-width: 2400px) {
          left: 46%;
        }
        @media (max-width: 2300px) {
          left: 42%;
        }
        @media (max-width: 2160px) {
          left: 37%;
        }
        @media (max-width: 2000px) {
          left: 33%;
        }
        @media (max-width: 1820px) {
          left: 24%;
        }

        @media (max-width: 1650px) {
          left: 21%;
        }
        @media (max-width: 1550px) {
          left: 17%;
        }
        @media (max-width: 1400px) {
          left: 13%;
        }
      }
      .line3 {
        position: relative;
        left: 65%;
        @media (max-width: 2560px) {
          left: 92%;
        }
        @media (max-width: 2450px) {
          left: 87%;
        }
        @media (max-width: 2360px) {
          left: 83%;
        }
        @media (max-width: 2270px) {
          left: 79%;
        }
        @media (max-width: 2200px) {
          left: 75%;
        }
        @media (max-width: 2100px) {
          left: 71%;
        }
        @media (max-width: 2000px) {
          left: 67%;
        }
        @media (max-width: 1900px) {
          left: 63%;
        }
        @media (max-width: 1800px) {
          left: 57%;
        }
        @media (max-width: 1700px) {
          left: 53%;
        }
        @media (max-width: 1650px) {
          left: 50%;
        }
        @media (max-width: 1600px) {
          left: 47%;
        }
        @media (max-width: 1500px) {
          left: 44%;
        }
        @media (max-width: 1430px) {
          left: 40%;
        }
        @media (max-width: 1330px) {
          left: 36%;
        }
        @media (max-width: 1260px) {
          left: 32%;
        }
      }
    }
  }
`;

export const PropertyBox = styled.div`
  margin-left: 10px; //22
  margin-right: 10px;
  margin-top: -80px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  span {
    white-space: nowrap;
    font-size: 13px;
    font-family: "Inter";
    background-color: white;
    padding: 12px;
    border-radius: 8px;
    display: inline-block;
    width: 93px;
    font-weight: bold;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  @media (max-width: 1870px) {
    span {
      font-size: 13px;
    }
  }
  @media (max-width: 1599px) {
    span {
      font-size: 11px;
    }
  }
  @media (max-width: 1280px) {
    span {
      font-size: 10px;
    }
  }
`;
export const PropertyContent = styled.div<PropertyContentProps>`
  background-color: white;
  padding: 14px;
  padding-top: 20px;
  border-radius: 8px;
  border-top-left-radius: 0px;
  .Property-Content-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    h1 {
      font-family: Inter;
      font-size: 17.7px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      margin-bottom: 0px;
    }
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
  .Propery-body {
    display: flex;
    flex-direction: row;
    @media (max-width: 2560px) {
      gap: 20%;
    }
    @media (max-width: 2200px) {
      gap: 14%;
    }
    @media (max-width: 2000px) {
      gap: 11%;
    }
    @media (max-width: 1870px) {
      gap: 9%;
      .column {
        .column-content {
          h1 {
            font-size: 11px;
          }
          p {
            font-size: 10px;
          }
        }
      }
    }

    @media (max-width: 1599px) {
      gap: 3.5%;
      .column {
        .column-content {
          h1 {
            font-size: 10px;
          }
          p {
            font-size: 9px;
          }
        }
      }
    }
    @media (max-width: 1500px) {
      gap: 5%;
    }
    @media (max-width: 1430px) {
      gap: 4.5%;
    }
    @media (max-width: 1280px) {
      gap: 2.3%;

      .column {
        .column-content {
          h1 {
            font-size: 8px;
          }
          p {
            font-size: 9px;
          }
        }
      }
    }
  }
  .availableProperty {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .content {
      p {
        font-family: "Inter";
        font-size: 13px;
      }
      h1 {
        margin-top: 5px;
        margin-bottom: 0px;
        font-family: "Inter";
        font-size: 12.5px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
      }
    }
  }
  .column {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 24px;
    .column-content {
      display: flex;
      flex-direction: column;
      white-space: nowrap;
      gap: 5px;
      h1 {
        filter: ${(props) =>
          props.admin === true || props.pathname === "Profile"
            ? "blur(0px)"
            : props.idx > 1
            ? "blur(5px)"
            : "blur(0px)"};
        margin-bottom: 0px;
        font-family: "Inter";
        font-size: 12.5px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
      }
      p {
        margin-bottom: 0px;
        font-family: Inter;
        font-size: 12.8px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
      }
    }
  }
`;
export const RiskPropertyContainer = styled.div`
  display: flex;
  flex-direction: row;
  transition: all 0.2s ease;
  z-index: 0;
`;

export const CustomRiskSliderContainer = styled.div<CustomRiskSliderProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 80%;

  .p-slider {
    position: absolute;
    z-index: 2;
    width: 80%;
    height: 4px !important;
    border-radius: 5px !important;
    -webkit-appearance: none !important;
    outline: none;
    top: 7px;
    background-color: ${({ color }) => (color ? color : "")} !important;
    ::-webkit-slider-thumb {
      -webkit-appearance: none;
      margin-top: 0px;
      width: 50px;
      height: 50px;
      background-color: ${({ color }) => (color ? color : "")};

      cursor: pointer;
      outline: none;
      box-shadow: 0 0 0 0 rgba(98, 0, 238, 0.1);
      transition: 0.5s ease;
      position: relative;
      z-index: 2;
    }
    .p-slider-range {
      background-color: transparent !important;
    }
    .p-slider-handle {
      margin-top: -6px !important;
      width: 10px !important;
      height: 10px !important;
      color: green !important;
      background-color: ${({ color }) => (color ? color : color)} !important;
      border-radius: 50% !important;
      cursor: pointer !important;
      background-image: none !important;
      position: relative;
      z-index: 3;

      &::before {
        content: "${({ RiskVal }) => RiskVal}";
        position: absolute;
        top: -40px;
        left: -10px;
        width: 30px;
        height: 30px;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Inter";
        font-size: 11px;
        font-weight: "bold";
        color: white;
      }
      &::after {
        content: "";
        position: absolute;
        top: -40px;
        left: -10px;
        width: 30px;
        height: 30px;
        background-color: ${({ color }) => (color ? color : "")};
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 0;
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;

        border-bottom-right-radius: 50%;
        transform: rotate(-45deg);
      }
    }
    .p-slider-handle:focus {
      outline: none !important;
      border: none !important;
    }
  }
  .progressbar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .progress-txt-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .progress-Text {
        font-family: "Inter";
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #cccccc;
        position: relative;
        cursor: pointer;
      }
      .progress {
        width: 2px;
        height: 10px;
        background-color: var(--grey-smooky);
        margin-bottom: 14px;
      }
    }
  }
`;
export const PropertyHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    a {
      font-size: 12px;
      color: var(--main-blue);
      cursor: pointer;
      text-decoration: none;
      font-family: "Inter";
      margin-right: 3rem;
      font-weight: bold;
      margin-bottom: 0px;
    }
    i {
      width: 18px;
      height: 9px;
      cursor: pointer;
      color: var(--arrowColor);
    }
  }
  h1 {
    font-size: 24px !important;
    font-weight: 500;
    color: var(--blackColor);
    font-family: "Inter";
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;

    cursor: pointer;
  }
`;
export const PropertyAddButton = styled.button`
  padding: 23px 0 23px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  border-radius: 8px;
  background-color: var(--light-blue);

  border: none;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--solid-colors);
`;
export const Disclaimer = styled.div`
  width: 98%;
  display: flex;
  justify-content: end;
  font-size: 14px;
  margin-bottom: 20px;
`;
export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const TaskCard = styled.div`
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0.3px 0px 3.1px 0 rgba(0, 0, 0, 0),
    1.4px 0px 6.5px 0 rgba(0, 0, 0, 0), 3.4px 0px 13px 0 rgba(0, 0, 0, 0),
    6.5px 0px 25.5px 0 rgba(0, 0, 0, 0.01),
    11px 0.1px 46.9px 0 rgba(0, 0, 0, 0.01),
    17px 0.1px 80px 0 rgba(0, 0, 0, 0.01);
  background-color: #fff;
  font-family: "Inter";
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: black !important;
  span {
    color: var(--solid-colors);
  }
`;
export const CardsContainer = styled.div`
  margin-top: 20px;
  .card-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
    @media (max-width: 510px) {
      flex-wrap: wrap;
    }
  }
`;
export const SmallCard = styled.div`
  padding-left: 24px;
  padding-right: 15px;
  padding-bottom: 25px;
  padding-top: 24px;
  box-shadow: 0.3px 0px 3.1px 0 rgba(0, 0, 0, 0),
    1.4px 0px 6.5px 0 rgba(0, 0, 0, 0), 3.4px 0px 13px 0 rgba(0, 0, 0, 0),
    6.5px 0px 25.5px 0 rgba(0, 0, 0, 0.01),
    11px 0.1px 46.9px 0 rgba(0, 0, 0, 0.01),
    17px 0.1px 80px 0 rgba(0, 0, 0, 0.01);
  background-color: #fff;
  /* width: 258px; */
  width: 40%;
  height: 96px;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  align-items: center;
  @media (max-width: 510px) {
    width: 47%;
  }
  .txtContainer {
    width: 75%;
    font-family: "Inter";
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    display: flex;
    flex-direction: column;
    .span1 {
      font-size: 13px;
      margin-bottom: 7px;
      font-family: "Inter";
      color: black !important;
    }
    .span2 {
      color: var(--heading-color);
      font-size: 20px;
      font-weight: 500;
    }
  }
  .imgContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    padding-top: 10px;
    img {
      width: 100%;
    }
  }
`;
export const PropertyPortfolio = styled.div`
  border-radius: 8px;
  box-shadow: 0.3px 0px 3.1px 0 rgba(0, 0, 0, 0),
    1.4px 0px 6.5px 0 rgba(0, 0, 0, 0), 3.4px 0px 13px 0 rgba(0, 0, 0, 0),
    6.5px 0px 25.5px 0 rgba(0, 0, 0, 0.01),
    11px 0.1px 46.9px 0 rgba(0, 0, 0, 0.01),
    17px 0.1px 80px 0 rgba(0, 0, 0, 0.01);
  background-color: #fff;
  margin-top: 20px;
  padding: 32px 0px;
  font-family: Inter;
  overflow: hidden;
  .portfolio-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 32px;
    span {
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #323232;
    }
    a {
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: var(--heading-color);
    }
  }
  .propertyList {
    margin-top: 15px;

    table {
      width: 100%;
      tbody {
        width: 100%;
        tr {
          border-bottom: 1px solid #e0e0e0;
          padding-top: 24px;
          padding-bottom: 23px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          td {
            padding: 0px 32px;
            @media (max-width: 512px) {
              padding: 0px 13px;
            }
          }
          .property-list-img {
            display: flex;
            flex-direction: row;
            @media (max-width: 512px) {
              padding-right: 0px;
            }
            div {
              color: transparent !important;
              text-shadow: 0 0 8px #000;
              background-color: transparent;
              position: relative;
              &:hover::after {
                content: "Premium Access Only";
                position: absolute;
                font-size: 13px;
                width: 150px;
                left: 0;
                top: 17px;
                border-radius: 15px;
                background-color: #272660;
                padding: 7px;
                color: white !important;
                text-shadow: none !important;
                opacity: 1 !important;
                z-index: 1000 !important;
              }
              &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;

                background-color: rgba(178, 190, 181, 0.7);

                width: 100%;
                height: 100%;
              }
              img {
                color: transparent !important;
                text-shadow: 0 0 8px #000;
                background-color: transparent;
                width: 72px;
                height: 44px;
              }
            }
            span {
              font-family: Inter;
              font-size: 16px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: left;
              color: #323232;
              width: 250px;
              @media (max-width: 624px) {
                width: 150px;
              }
              /* margin-left: 16px; */
            }
          }

          .value {
            font-family: Inter;
            font-size: 13px;

            span {
              font-weight: 500;
              font-size: 16px;
              color: #323232;
            }
          }
        }
        @media (max-width: 630px) {
          width: 200px !important;
          overflow-x: scroll;
        }
      }
    }
  }
`;
export const PropertyType = styled.td<any>`
  width: 117px;
  height: 30px !important;
  padding: 10px 24px;
  border-radius: 8px;
  background-color: ${({ bgcolor }) => bgcolor};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${({ color }) => color};
`;
