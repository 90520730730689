import React, { useEffect, useState, useRef } from "react";
import {
  useGenerateSummary,
  ISummaryTableData,
} from "hooks/useGenerateSummary";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SpendingTableContainer } from "global.style";
import ToolTips from "components/ui/Tooltip/Tooltips";
import { BsEye, BsEyeSlash, BsLockFill, BsUnlockFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { GS } from "services/global";
import { useGetDomainData } from "hooks/useGetDomainData";
import {
  commaStringSeparatorsWithDollors,
  commaStringSeparators,
} from "utils/helperFunctions";
import dartImg from "assets/images/dart1.png";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Loading } from "components/ui/Loading";
import { TPropertyGeneratorItem } from "types";
import { ContactDropDown } from "global.style";
import { useDispatch } from "react-redux";

type SuburbsData = {
  label: string;
  price: number;
  suburb: string;
  state: string;
  region: string;
  growth_percent: string;
};
const SummaryTable = () => {
  const { GenerateTable, CalculateOnPurchaseMethodChanged } =
    useGenerateSummary();
  const { getSuburbStats } = useGetDomainData();
  const dispatch = useDispatch();
  const userCalData = useSelector((state: RootState) => state.calculator);
  const [tableData, settableData] = useState<ISummaryTableData[]>([]);
  const [visibleColumns, setVisibleColumns] = useState<boolean[]>([]);
  const TableRef = useRef<any>(null);
  const scrollbarRef = useRef<any>(null);
  const [loading, setloading] = useState<boolean>(false);
  const [residentialData, setResidentialData] = useState<SuburbsData[]>([]);
  const [retailData, setRetailData] = useState<SuburbsData[]>([]);
  const [officeData, setOfficeData] = useState<SuburbsData[]>([]);
  const [scrollWidth,setScrollWidth] = useState<string>('100px')
  const userAllow = useSelector((state: RootState) => state.contacts.UserAllow);
  const ProductBodyTemplate = (rowData: ISummaryTableData) => {
    return (
      <div
        className={`flex justify-between items-center px-[18px] !min-h-[60px] ${rowData.className} !z-50 `}
      >
        <p className={`flex gap-[10px] text-[13px]  ${rowData.textclassName}`}>
          {rowData.headingName}
        </p>
      </div>
    );
  };
  const LastColumnBodyTemplate = (rowData: ISummaryTableData) => {
    return (
      <div
        className={`flex justify-between items-center px-[18px] !min-h-[60px] ${rowData.className}`}
      >
        {rowData?.lastColumnHeading &&
          rowData?.lastColumnHeading?.length > 0 && (
            <p className={`font-bold mt-3 text-[15px] `}>
              {rowData.lastColumnHeading}
            </p>
          )}
        {rowData?.totalValue && rowData?.totalValue?.length > 0 && (
          <p className={`text-[13px]`}>{rowData.totalValue} </p>
        )}
        {rowData.headingName === "Cashflow (Annual)" && (
          <img src={dartImg} alt="dartImg" />
        )}
      </div>
    );
  };
  const RiskFactorBody = (value: number, index: number) => {
    return (
      <div
        className={`tablet tablet-${value.toFixed(0)} ${
          index > 0 && !userAllow && "riskbackground"
        }`}
      >{`${value.toFixed(1)}%`}</div>
    );
  };
  const toggleColumnVisibility = (index: number) => {
    setVisibleColumns((prev) => {
      const newVisibility = [...prev];
      newVisibility[index] = !newVisibility[index];
      return newVisibility;
    });
  };
  const handleCellEditComplete = ({
    heading,
    propertyno,
    value,
  }: {
    heading: string;
    propertyno: number;
    value: string | number;
  }) => {
    if(heading==="Applied Growth Rate (Annual)(%)"){
      let appliedgrowth = String(value).replace("%","")
      let updatedappliedgrowth = Number(appliedgrowth)
      console.log(updatedappliedgrowth ,"appliedgrowth")
      const updatedproperties: TPropertyGeneratorItem[] =
      userCalData?.PropertyGenerators?.map((item, index) => {
        if (propertyno === index) {
          return {
            ...item,
           summaryData:{
            ...item.summaryData,
            AppliedGrowthRateannualPERC: `${updatedappliedgrowth}%`,
            EquityGainPERCorDOLLAR:0
           }
          };
        } else {
          return item;
        }
      });
      
       let { tablerowvalues } = CalculateOnPurchaseMethodChanged({
      Properites: updatedproperties,
      startAt:propertyno
    });
    let tableData: any = GenerateTable(tablerowvalues);
    setVisibleColumns(new Array(tableData[0].values.length).fill(true));
    settableData(tableData);
    setTimeout(adjustWidth, 0);
     
    }else if (heading === "Cash Gain ($)") {
     const updatedproperties: TPropertyGeneratorItem[] =
     userCalData?.PropertyGenerators?.map((item, index) => {
       if (propertyno === index) {
         return {
           ...item,
          summaryData:{
           ...item.summaryData,
           CashGainDOLLAR: Number(value),
          }
         };
       } else {
         return item;
       }
     });
      let { tablerowvalues } = CalculateOnPurchaseMethodChanged({
     Properites: updatedproperties,
     startAt:propertyno
   });
   let tableData: any = GenerateTable(tablerowvalues);
   setVisibleColumns(new Array(tableData[0].values.length).fill(true));
   settableData(tableData);
   setTimeout(adjustWidth, 0);
     
    }else if(heading === "Equity Gain ($)"){
      const updatedproperties: TPropertyGeneratorItem[] =
      userCalData?.PropertyGenerators?.map((item, index) => {
        if (propertyno === index) {
          return {
            ...item,
           summaryData:{
            ...item.summaryData,
            EquityGainPERCorDOLLAR: Number(value),
           }
          };
        } else {
          return item;
        }
      });
      
       let { tablerowvalues } = CalculateOnPurchaseMethodChanged({
      Properites: updatedproperties,
      startAt:propertyno
    });
    let tableData: any = GenerateTable(tablerowvalues);
    setVisibleColumns(new Array(tableData[0].values.length).fill(true));
    settableData(tableData);
    setTimeout(adjustWidth, 0);
    }
    else if(heading ==="Purchase Price"){
      const updatedproperties: TPropertyGeneratorItem[] =
      userCalData?.PropertyGenerators?.map((item, index) => {
        if (propertyno === index) {
          return {
            ...item,
           summaryData:{
            ...item.summaryData,
            PurchasePrice: Number(value)
           }
          };
        } else {
          return item;
        }
      });
       let { tablerowvalues } = CalculateOnPurchaseMethodChanged({
      Properites: updatedproperties,
      startAt:propertyno
    });
    let tableData: any = GenerateTable(tablerowvalues);
    setVisibleColumns(new Array(tableData[0].values.length).fill(true));
    settableData(tableData);
    setTimeout(adjustWidth, 0);
    }
   
  };
  const handleLocked = (rowData: any) => {
    const updatedproperties: TPropertyGeneratorItem[] =
      userCalData?.PropertyGenerators?.map((item, index) => {
        if (rowData?.property_id - 1 === index) {
          return {
            ...item,
            isLocked: !item.isLocked,
          };
        } else {
          return item;
        } 
      });
    let { tablerowvalues } = CalculateOnPurchaseMethodChanged({
      Properites: updatedproperties,
    });
    let tableData: any = GenerateTable(tablerowvalues);
    setVisibleColumns(new Array(tableData[0].values.length).fill(true));
    settableData(tableData);
    setTimeout(adjustWidth, 0);
  };
  const SortRegions = () => {
    let officedata: any[] = [];
    let retaildata: any[] = [];
    let residentialdata: any[] = [];
    for (
      let i = 0;
      i < GS?.Calculator?.DomainData.data["office"]?.length;
      i++
    ) {
      officedata.push({
        label: GS.Calculator.DomainData.data["office"][i].region,
        price: GS.Calculator.DomainData.data["office"][i].Median_Rental_Price,
        suburb: GS.Calculator.DomainData.data["office"][i].Suburb_Name,
        state: GS.Calculator.DomainData.data["office"][i].State,
        region: GS.Calculator.DomainData.data["office"][i].region,
        growth_percent:
          GS.Calculator.DomainData.data["office"][i]?.growth_percent,
      });
    }
    for (
      let i = 0;
      i < GS?.Calculator?.DomainData?.data["residential"]?.length;
      i++
    ) {
      residentialdata.push({
        label: GS.Calculator.DomainData.data["residential"][i].region,
        price:
          GS.Calculator.DomainData.data["residential"][i].Median_Rental_Price,
        suburb: GS.Calculator.DomainData.data["residential"][i].Suburb_Name,
        state: GS.Calculator.DomainData.data["residential"][i].State,
        region: GS.Calculator.DomainData.data["residential"][i].region,
        growth_percent:
          GS.Calculator.DomainData.data["residential"][i]?.growth_percent,
      });
    }
    for (
      let i = 0;
      i < GS?.Calculator?.DomainData?.data["retail"]?.length;
      i++
    ) {
      retaildata.push({
        label: GS.Calculator.DomainData.data["retail"][i].region,
        price: GS.Calculator.DomainData.data["retail"][i].Median_Rental_Price,
        suburb: GS.Calculator.DomainData.data["retail"][i].Suburb_Name,
        state: GS.Calculator.DomainData.data["retail"][i].State,
        region: GS.Calculator.DomainData.data["retail"][i].region,
        growth_percent:
          GS.Calculator.DomainData.data["retail"][i]?.growth_percent,
      });
    }

    setResidentialData(residentialdata);
    setOfficeData(officedata);
    setRetailData(retaildata);
  };
  const GetOptions = ({ property_type }: { property_type: string }) => {
    if (property_type === "Residential") {
      return residentialData.map((item) => {
        let newobj = {
          ...item,
          label: item.label + `, ${item.state}`,
        };
        return newobj;
      });
    } else if (property_type === "Commercial Retail") {
      return retailData.map((item: any) => {
        let newobj = {
          ...item,
          label: item.label + `, ${item.state}`,
        };
        return newobj;
      });
    } else if (property_type === "Commercial Office") {
      return officeData.map((item: any) => {
        let newobj = {
          ...item,
          label: item.label + `, ${item.state}`,
        };
        return newobj;
      });
    } else {
      return [];
    }
  };
  const handleSelectedRegion = ({
    propertyno,
    region,
    property_type,
  }: {
    propertyno: number;
    region: SuburbsData;
    property_type: any;
  }) => {
    let selectedregions = [...userCalData.PortfolioSummary.selectedRegions];
    let selectedindex = 0;
    if (property_type === "Residential") {
      GS?.Calculator?.DomainData?.data?.residential?.map((item, index) => {
        if (item?.region === region?.region) {
          selectedindex = index;
        }
      });
      console.log(selectedindex, "regionselect");
    } else if (property_type === "Commercial Retail") {
      GS?.Calculator?.DomainData?.data?.retail?.map((item, index) => {
        if (item?.region === region?.region) {
          selectedindex = index;
        }
      });
    } else if (property_type === "Commercial Office") {
      GS?.Calculator?.DomainData?.data?.office?.map((item, index) => {
        if (item?.region === region?.region) {
          selectedindex = index;
        }
      });
    }
    selectedregions[propertyno] = selectedindex;
    let newcalobj = { ...userCalData };
    newcalobj = {
      ...newcalobj,
      PortfolioSummary: {
        ...newcalobj.PortfolioSummary,
        selectedRegions: selectedregions,
      },
    };
    let { tablerowvalues } = CalculateOnPurchaseMethodChanged({
      selected_regions: selectedregions,
    });
    let tableData: any = GenerateTable(tablerowvalues);
    setVisibleColumns(new Array(tableData[0].values.length).fill(true));
    settableData(tableData);
    setTimeout(adjustWidth, 0);
  };
  const showblurTxt = (headingName: string, propertyno: number) => {
    let classname: string = "blurtxt";
    if (
      headingName === "Cash Gain ($)" ||
      headingName === "Equity Gain ($)" ||
      headingName === "Total Deposit Required" ||
      headingName === "LMI" ||
      headingName === "Loan to Value Ratio (LVR)" ||
      headingName === "Total Debt" ||
      headingName === "Total Cost of Lending" ||
      headingName === "Cashflow (Annual)" ||
      headingName === "Cashflow (Monthly)"
    ) {
      classname = "";
    }
    if (propertyno === 0) {
      classname = "";
    }
    if (
      headingName === "Property Rent Income (Year 1)" ||
      headingName === "Total Deposit Cash" ||
      headingName === "Return on Equity (COC)" ||
      headingName === "Suburb" ||
      (headingName === "Property Type" && propertyno > 0)
    ) {
      classname = "blurtxt";
    }
    if (userAllow) {
      classname = "";
    }
    return classname;
  };
  const ColumnsBodyTemplate = (rowData: ISummaryTableData, index: number) => {
    const value = rowData.values[index];
    const show = visibleColumns[index]; // Check column visibility
    const property_type = userCalData?.PropertyGenerators[index]?.summaryData?.propertyType || "";
    const region = userCalData?.PropertyGenerators[index]?.summaryData?.region;
    const state = userCalData?.PropertyGenerators[index]?.summaryData?.stateName;

    const [inputValue, setInputValue] = useState<any>(rowData.values[index]);
    const [textinputValue, setTextInputValue] = useState<any>(
      rowData.values[index]
    );
    const [showInput, setshowInput] = useState<boolean>(false);
    const [showInputText, setshowInputText] = useState<boolean>(false);
    const [showTooltip, setshowtooltip] = useState<boolean>(false);
    const [showDropdown, setshowDropdown] = useState<boolean>(false);

    const regs: SuburbsData[] = GetOptions({ property_type });

    const [selectedDropdown, setSelectedDropdown] = useState({
      label: "",
      price: 0,
      suburb: "",
      state: "",
      region: "",
      growth_percent: "",
    });
    useEffect(() => {
      if (regs.length > 0) {
        
        let selectedvalue = regs.filter(
          (item) => item.region === region && item.state === state
        );
        if (selectedDropdown?.label?.length === 0) {
          setSelectedDropdown(selectedvalue[0]);
        }
      }
    }, [regs]);
    return (
      <>
        {showDropdown && (
          <div className="w-[9rem] flex gap-2 items-center">
            <ContactDropDown
              className="!w-[100%] !h-[38px]"
              value={selectedDropdown}
              onChange={(e) => {
                setSelectedDropdown(e.value);
              }}
              options={regs}
              color="#ced4da"
              optionLabel="label"
            />
            <span
              className="text-[12px]"
              onClick={() => {
                setshowDropdown(false);
                handleSelectedRegion({
                  propertyno: index,
                  region: selectedDropdown,
                  property_type: property_type,
                });
              }}
            >
              Done
            </span>
          </div>
        )}
        {showInputText && (
          <InputText
            className="price-editor"
            type="text"
            value={textinputValue}
            // onMouseLeave={(e) => {
            //   e.preventDefault()
            //   setshowInputText(false);
            //   handleCellEditComplete({
            //     heading: rowData.headingName,
            //     propertyno: index,
            //     value: textinputValue,
            //   });
            // }}
            onBlur={(e) => {
              e.preventDefault()
              setshowInputText(false);
              handleCellEditComplete({
                heading: rowData.headingName,
                propertyno: index,
                value: textinputValue,
              });
            }}
            onChange={(e) => setTextInputValue(e.target.value)}
          />
        )}
        {showInput && (
          <InputNumber
            className="price-editor"
            // onMouseLeave={(e) => {
            //   e.preventDefault()
            //   setshowInput(false);
            //   handleCellEditComplete({
            //     heading: rowData.headingName,
            //     propertyno: index,
            //     value: inputValue,
            //   });
            // }}
            onBlur={(e) => {
              e.preventDefault()
              setshowInput(false);
              handleCellEditComplete({
                heading: rowData.headingName,
                propertyno: index,
                value: inputValue,
              });
            }}
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.value)
              
            }}
            mode="currency"
            currency="USD"
            locale="en-US"
          />
        )}
        {show && !showInput && !showInputText && !showDropdown ? (
          <div
            className={`flex  justify-center items-center px-[5px] !min-h-[60px] ${
              rowData.className
            } z-10 ${index === 0 && !userAllow && "hueColor"}`}
          >
            {typeof value === "string" ? (
              rowData.headingName === "Applied Growth Rate (Annual)(%)" ? (
                <p
                  onClick={() => {
                    if (userCalData?.PropertyGenerators[index]?.isLocked) {
                      setshowtooltip(true);
                    } else {
                      setshowInputText(true);
                    }
                  }}
                  onMouseLeave={() => setshowtooltip(false)}
                >
                  <div className="ToolTip">
                    <ToolTips
                      borderRadius={"4px"}
                      top="-70px"
                      left="-50px"
                      textAlign="center"
                      width="150px"
                      show={showTooltip}
                      style={{ backgroundColor: "white" }}
                      txt={`Unlock to edit`}
                    />
                  </div>
                  {value} <i className="pi pi-angle-down text-[11px]" ></i>
                </p>
              ) : (
                <p className={showblurTxt(rowData.headingName, index)}>
                  {value}
                </p>
              )
            ) : typeof value === "number" ? (
              rowData.headingName === "Risk Factored Yield Return" ? (
                RiskFactorBody(value, index)
              ) : (
                <p
                  className={showblurTxt(rowData.headingName, index)}
                  onMouseLeave={() => setshowtooltip(false)}
                  onClick={() => {
                    if (
                      rowData.headingName === "Cash Gain ($)" ||
                      rowData.headingName === "Equity Gain ($)" ||
                      rowData.headingName === "Purchase Price"
                    ) {
                      if (userCalData?.PropertyGenerators[index]?.isLocked) {
                        setshowtooltip(true);
                      } else {
                        setshowInput(true);
                      } 
                    }
                  }}
                >
                  <div className="ToolTip">
                    <ToolTips
                      borderRadius={"4px"}
                      top="-70px"
                      left="-50px"
                      textAlign="center"
                      width="150px"
                      show={showTooltip}
                      style={{ backgroundColor: "white" }}
                      txt={`Unlock to edit`}
                    />
                  </div>
                  {rowData.headingName === "Return on Equity (COC)" ||
                  rowData.headingName === "Loan to Value Ratio (LVR)"
                    ? `${commaStringSeparators(value)}%`
                    : commaStringSeparatorsWithDollors(value)}{" "}
                    {( rowData.headingName === "Cash Gain ($)" ||
                      rowData.headingName === "Equity Gain ($)" ||
                      rowData.headingName === "Purchase Price") && <i className="pi pi-angle-down text-[11px]" ></i>}
                </p>
              )
            ) : rowData.headingName === "Suburb" ? (
              
                <div
                className={`flex flex-col gap-2 ${showblurTxt(
                  rowData.headingName,
                  index
                )} `}
                onClick={() => {
                  if (userAllow && !userCalData?.PropertyGenerators[index]?.isLocked) {
                    setshowDropdown(true);
                  }
                }}
              >
                <p className="text-[9px] text-center ">{value?.state}</p>

                <p className="text-center ">{value?.name}  <i className="pi pi-angle-down text-[11px]" ></i></p>
              </div>
             
              
            ) : (
              <div className="flex flex-col gap-2">
                <p className="text-[8px] text-center font-bold">
                  {value.state}
                </p>
                <p className="datepill">{value?.data}</p>
                <div className="flex justify-center">
                  <BsEye
                    onClick={() => toggleColumnVisibility(index)}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </div>
                <div className="ToolTip-container">
                  <Link
                    style={{ color: "black" }}
                    className="propertypill"
                    to={`/Clients/Generator/${index + 1}/?id=${
                      userCalData.VendorId
                    }`}
                  >
                    <div className="ToolTip">
                      <ToolTips
                        borderRadius={"4px"}
                        top="-70px"
                        left="0px"
                        textAlign="center"
                        width="120px"
                        style={{ color: "black" }}
                      />
                      {value.name}
                    </div>
                  </Link>
                  {value?.isLocked ? (
                    <BsLockFill
                      onClick={() => handleLocked(value)}
                      style={{
                        cursor: "pointer",
                        marginLeft: "1.5px",
                      }}
                    />
                  ) : (
                    <BsUnlockFill
                      onClick={() => handleLocked(value)}
                      style={{
                        cursor: "pointer",
                        marginLeft: "1.5px",
                      }}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div
            className={`flex justify-center items-center px-[5px] !min-h-[60px] ${
              rowData.className
            } ${(showInput || showInputText || showDropdown) && "!hidden"}`}
          >
            {typeof value === "string" ? (
              <p
                className={`${
                  (!showInput || !showInputText || !showDropdown) && "hidden"
                }`}
              >
                {" "}
              </p>
            ) : typeof value === "number" ? (
              rowData.headingName === "Risk Factored Yield Return" ? (
                <p
                  className={`${
                    (!showInput || !showInputText || !showDropdown) && "hidden"
                  }`}
                >
                  {" "}
                </p>
              ) : (
                <p
                  className={`${
                    (!showInput || !showInputText || !showDropdown) && "hidden"
                  }`}
                >
                  {" "}
                </p>
              )
            ) : rowData.headingName === "Suburb" ? (
              <div
                className={`flex flex-col gap-2 ${
                  (!showInput || !showInputText || !showDropdown) && "hidden"
                }`}
              >
                <p> </p>
              </div>
            ) : (
              <div className="flex flex-col gap-2">
                <div className="flex justify-center">
                  <BsEyeSlash
                    onClick={() => toggleColumnVisibility(index)}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  };
  const GenerateColumns = () => {
    if (tableData.length === 0) {
      return null;
    }
    const firstRowValues = tableData[0]?.values;

    return firstRowValues?.map((value, index) => {
      return (
        <Column
          key={index}
          field={`values[${index}].value`}
          style={{
            minWidth: `${!visibleColumns[index] ? "50px" : " 200px"}`,
            padding: "0px",
            overflow: "auto !important",
          }}
          body={(rowData) => ColumnsBodyTemplate(rowData, index)}
        />
      );
    });
  };
  const GeneratePropertyData = async () => {
    try {
      if (GS?.Calculator?.DomainData?.data?.residential?.length === 0) {
        setloading(true);
        let data = await getSuburbStats();
        if (data) {
          setloading(false);
          SortRegions();
        }
      } else {
        SortRegions();
      }
      let { tablerowvalues } = CalculateOnPurchaseMethodChanged({});
      let tableData: any = GenerateTable(tablerowvalues);
      setVisibleColumns(new Array(tableData[0].values.length).fill(true));
      settableData(tableData);
      setTimeout(adjustWidth, 0);
    } catch (err) {}
  };
  const handleTopScrollBar = (event:any) => {
    var tableContainer = document.querySelector(".p-datatable-wrapper");
    console.log(tableContainer?.scrollWidth,"check")
    if (tableContainer) {
      tableContainer.scrollLeft = event.target.scrollLeft;
      
    }
  };
  
  const handleLowerScroll = (event:any) => {
    
    var topScrollBar  = document.querySelector(".topScrollBar");
    if (topScrollBar ) {
      topScrollBar.scrollLeft = event.target.scrollLeft;
    }
  };
  const adjustScrollBarWidth = () => {
    const tableContainer = document.querySelector(".p-datatable-wrapper") as HTMLElement;
    const topScrollBarInner = document.querySelector(".topScrollBar > div") as HTMLElement;
    if (tableContainer && topScrollBarInner) {
      topScrollBarInner.style.width = `${tableContainer.scrollWidth}px`;
      setScrollWidth(`${tableContainer.scrollWidth}px`)
    }
  };
  const adjustWidth = () => {
    adjustScrollBarWidth();
  };
  useEffect(() => {
   
    setTimeout(adjustWidth, 0);
    window.addEventListener('resize', adjustWidth);
    return () => window.removeEventListener('resize', adjustWidth);
  }, []);
  useEffect(() => {
    GeneratePropertyData();
  }, []);


  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center w-full mt-20">
          <Loading />
        </div>
      ) : (
        <>
           <div className="relative mt-4">
            <div
              className="topScrollBar"
              style={{
                width: "100%",
              }}
              onScrollCapture={(e) => handleTopScrollBar(e)}
              ref={scrollbarRef}
            >
              <div
                style={{
                  width: scrollWidth,
                  height: "10px",
                  // background: "#666666",
                  borderRadius: "30px",
                  cursor:"pointer"
                }}
              />
            </div>
          </div>
          <SpendingTableContainer className="w-full my-5">
         
            <div
              className="relative custom-table-container"
              ref={TableRef}
              onScrollCapture={(e)=>{handleLowerScroll(e)}}
            >
              <DataTable
                value={tableData}
                scrollable
                className="summary-table"
                editMode="cell"
              >
                <Column
                  field="headingName"
                  style={{
                    minWidth: "300px",
                    padding: "0px",
                  }}
                  body={ProductBodyTemplate}
                  frozen={true}
                  alignFrozen="left"
                  className="!z-10"
                ></Column>
                {GenerateColumns()}
                <Column
                  field="lastColumnHeading"
                  style={{
                    padding: "0px",
                  }}
                  body={LastColumnBodyTemplate}
                ></Column>
              </DataTable>
            </div>
          </SpendingTableContainer>
        </>
      )}
    </>
  );
};

export default SummaryTable;
