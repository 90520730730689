import React from "react";
import { useSelector,useDispatch } from "react-redux";
import { RootState } from "store/store";
import { OuterContainer, InnerContainer,RiskPropertyContainer,Disclaimer } from "global.style";
import { PortfolioJourney } from "components/screen-components/PortfolioJourney";
import { VendorProfileInfo } from "components/screen-components/Calculator/VendorProfileInfo";
import { Row, Col } from "react-bootstrap";
import { ClientPrefrences } from "components/screen-components/Calculator/ClientPrefrences";
import { RiskProfile } from "components/screen-components/Calculator/RiskProfile";
import { VideoCard } from "components/screen-components/Calculator/VideoCard";
import { PropertyCard } from "components/screen-components/PropertyCard";
import { PropertyCardsData } from "utils/constants";
import { GradientButton } from "components/ui/Buttons/GradientButton";
import { useNavigate } from "react-router-dom";
import { SetCalData,SaveCalData } from "store/Slices/CalculatorSlice";
export const ClientProfile = () => {
  const userCaldata = useSelector((state: RootState) => state.calculator);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const updateContact=async(event:React.MouseEvent<HTMLButtonElement>)=>{
    event.preventDefault();
    let newcalobj={...userCaldata}
      newcalobj={
        ...newcalobj,
        Progress:{
          ...newcalobj.Progress,
          completed:1,
          current:2
        }
      }
      SaveCalData(newcalobj)
      dispatch(SetCalData(newcalobj))
      navigate(`/Clients/Finances?id=${newcalobj.VendorId}`)
 
  }
  return (
    <OuterContainer>
      <InnerContainer width="100%" bg={true} padding="1rem 15px 0rem 15px">
        <PortfolioJourney currentActive="Profile" />
        <Row className="!mt-[20px]">
          <Col md={8} lg={8}>
            <VendorProfileInfo />
          </Col>
          <Col md={4} lg={4}>
            <ClientPrefrences />
          </Col>
        </Row>
        <Row className="!mt-[20px]">
          <Col md={9} lg={9} xl={9} xxl={9}>
            <RiskProfile/>
          </Col>
          <Col md={3} lg={3} xl={3} xxl={3}>
          <VideoCard />
        </Col>
        </Row>
        <RiskPropertyContainer className="!mt-[10px]">
        <Row>
          {PropertyCardsData?.map((item,index)=>{
            return(
              <React.Fragment key={index}>
                {item?.PropertyMode===userCaldata?.RiskProfile?.PropertyMode && 
                <Col
                md={6}
                lg={6}
                xl={6}
                xxl={4}
                style={{ marginBottom: "20px" }}
              >
                <PropertyCard 
                  img={item?.img}
                  number={item?.number}
                  propertyProps={item?.propertyProps}
                />

              </Col>
                }
              </React.Fragment>
            )
          })}
          {userCaldata?.RiskProfile?.PropertyMode === "Conservative" && (
            <>
              <Col
                md={6}
                lg={6}
                xl={6}
                xxl={4}
                style={{ marginBottom: "20px" }}
              >
              </Col>
              
            </>
          )}
        
        </Row>
      </RiskPropertyContainer>
      <Disclaimer>
        <span>Example Properties</span>
      </Disclaimer>
      <div className="flex justify-end mt-[40px]">
        <GradientButton
          style={{
            position: "fixed",
            bottom: "31px",
            right: "94px",
            width:"89px !important"
          }}
          width="89px"
          onClick={updateContact}
        />
      </div>
      </InnerContainer>
    </OuterContainer>
  );
};


