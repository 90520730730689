import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "store/store";
import { TPropertyGeneratorItem } from "types";
import { commaStringSeparatorsWithDollors } from "utils/helperFunctions";
const PropertyBoxes = () => {
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const PropertiesData:TPropertyGeneratorItem[] = useSelector((state:RootState)=>state.calculator.PropertyGenerators)
  const { propertyid } = useParams();
  const propertyIdNumber = Number(propertyid)-1;

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="boxs-container">
      <div className="boxs">
        <Row
          lg={12}
          className={`${scrollPosition > 300 ? "grid fixed" : "grid"}`}
        >
          <Col lg={2} md={3} sm={3} className="col">
            <p>Accessible Equity Available</p>
            <p>{commaStringSeparatorsWithDollors(PropertiesData[propertyIdNumber].equityAvailable)}</p>
          </Col>
          <Col lg={2} md={3} sm={3} className="col">
            <p>Cash On Hand</p>
            <p>{commaStringSeparatorsWithDollors(PropertiesData[propertyIdNumber].cashAvailable)}</p>
          </Col>
          <Col lg={2} md={3} sm={3} className="col">
            <p>Available Liquid Assets</p>
            <p>{commaStringSeparatorsWithDollors(PropertiesData[propertyIdNumber].liquidAssets)}</p>
          </Col>
          <Col lg={2} md={3} sm={3} className="col">
            <p>Total Available Funds</p>
            <p>{commaStringSeparatorsWithDollors(PropertiesData[propertyIdNumber].equityAvailable+PropertiesData[propertyIdNumber].cashAvailable)}</p>
          </Col>
          <Col lg={2} md={3} sm={3} className="col">
            <p>Year Goal Achieved</p>
            <p>{PropertiesData[propertyIdNumber].nextDate}</p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PropertyBoxes;
