import React, { useEffect, useState,memo } from "react";
import { InnerContainer, PropertyHeader, CustomCheckBox } from "global.style";
import AnimateHeight from "components/screen-components/AnimateHeight";
import { Row, Col } from "react-bootstrap";
import { GradientButton } from "components/ui/Buttons/GradientButton";
import { InfoBox } from "components/screen-components/InfoBox";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/store";
import { SetCalData, SaveCalData } from "store/Slices/CalculatorSlice";
import { InputNumbers } from "components/ui/InputNumbers";
import { Slider } from "primereact/slider";
import { ContactDropDown } from "global.style";
import { PaymentTypes } from "utils/constants";

export const ExpenseInput = memo(() => {
  const usercaldata = useSelector((state: RootState) => state.calculator);
  const dispatch = useDispatch();
  const [open, setopen] = useState<boolean>(true);
  const height = open ? "auto" : 0;
  const [showLivingPopup, setShowLivingPopup] = useState<boolean>(false);
  const [showBasicPopup, setshowBasicPopup] = useState<boolean>(false);
  const [showAvgAusPopup,setshowAvgAusPopup] = useState<boolean>(false);
  const [showManualPopup,setshowManualPopup] = useState<boolean>(false);
  const settingsdata = useSelector((state:RootState) => state.settings)
  const [ExpenseData, setExpenseData] = useState({
    UseBasiq: usercaldata.BorrowingCalculator.UseBasiq,
    UseAverageAustralian: usercaldata.BorrowingCalculator.UseAverageAustralian,
    UseManual: usercaldata.BorrowingCalculator.UseManual,
    LivingExpenses: usercaldata.BorrowingCalculator.LivingExpenses,
    ManualExpense: usercaldata.BorrowingCalculator.ManualExpense,
    OtherPayments: usercaldata.BorrowingCalculator.OtherPayments,
  });
  const [initialRender, setinitialRender] = useState<boolean>(true);
  const AutoSave = async () => {
    let newcalbody = { ...usercaldata };
    let avgAusVal = usercaldata.BorrowingCalculator.LivingExpenses
    if(usercaldata.BorrowingCalculator.IncomeDetails.UserPartner){
      avgAusVal = settingsdata.Assumptions.SingleBaseExpense + settingsdata.Assumptions.DependentBaseExpensive + (usercaldata.BorrowingCalculator.ExpenseDetails.Dependants * settingsdata.Assumptions.DependentBaseExpensive)
    }else{
      avgAusVal = settingsdata.Assumptions.SingleBaseExpense + (usercaldata.BorrowingCalculator.ExpenseDetails.Dependants * settingsdata.Assumptions.DependentBaseExpensive)
    }
    let totalExpenses = ExpenseData.UseAverageAustralian
    ? (avgAusVal / 12) | 0
    : ExpenseData.UseManual
    ? (ExpenseData.ManualExpense / 12) | 0
    : (usercaldata.BorrowingCalculator.BasiqExpenses) | 0;
    
    
    newcalbody = {
      ...newcalbody,
      BorrowingCalculator: {
        ...newcalbody.BorrowingCalculator,
        TotalExpenses:totalExpenses,
        UseBasiq: ExpenseData.UseBasiq,
        UseAverageAustralian: ExpenseData.UseAverageAustralian,
        UseManual: ExpenseData.UseManual,
        LivingExpenses: avgAusVal,
        ManualExpense: ExpenseData.ManualExpense, 
        OtherPayments: {
          ...newcalbody.BorrowingCalculator.OtherPayments,
          type: ExpenseData.OtherPayments.type,
          value: ExpenseData.OtherPayments.value,
        },
        ExpenseDetails:{
          ...newcalbody.BorrowingCalculator.ExpenseDetails,
          OtherPayment:ExpenseData.OtherPayments.value
        }
      },
      StrategicSpending:{
        ...newcalbody.StrategicSpending,
        
      }
    };
    dispatch(SetCalData(newcalbody));
    SaveCalData(newcalbody);
  };
  const handleButtonClick = (item: string) => {
    if (item === "Average Australian") {
      setExpenseData({
        ...ExpenseData,
        UseAverageAustralian: true,
        UseBasiq: false,
        UseManual: false,
      });
    } else if (item === "Manual Input") {
      setExpenseData({
        ...ExpenseData,
        UseAverageAustralian: false,
        UseBasiq: false,
        UseManual: true, 
      });
    } else {
      setExpenseData({
        ...ExpenseData,
        UseAverageAustralian: false,
        UseBasiq: true,
        UseManual: false,
      });
    }
  };
  useEffect(()=>{
    if (!initialRender) {
      if(usercaldata.BorrowingCalculator.ExpenseDetails.OtherPayment!==ExpenseData.OtherPayments.value){
        setExpenseData({...ExpenseData,OtherPayments:{...ExpenseData.OtherPayments,value:usercaldata.BorrowingCalculator.ExpenseDetails.OtherPayment}})
      }
      
    }
  },[usercaldata.BorrowingCalculator.ExpenseDetails.OtherPayment])
  useEffect(() => {
    if (initialRender) {
      setinitialRender(false);
    } else { 
      AutoSave();
    }
  }, [ExpenseData]);
  return (
    <InnerContainer className="" padding="1.5rem 32px 1.8rem 32px">
      <PropertyHeader onClick={() => setopen(!open)}>
        <h1 className="card-title">Select Your Expense Input Method</h1>
        {open ? (
          <div className="mb-[8px]">
            <i className="pi pi-angle-up" onClick={() => setopen(!open)}></i>
          </div>
        ) : (
          <div className="mb-[8px]">
            <i className="pi pi-angle-down" onClick={() => setopen(!open)}></i>
          </div>
        )}
      </PropertyHeader>
      <AnimateHeight height={height}>
        <p className="text-[13px] !mt-[10px]">
          Please choose one of the following options to input your expenses
          accurately for the Property Portfolio Forecast. Your choice will
          impact the precision of your forecast.
        </p>
        <Row>
          <div className="flex flex-wrap gap-[40px] !mt-[15px]">
            <div className="flex flex-col gap-[8px]">
              <div className="flex gap-[2px] relative">
                <p className="font-[500] text-[14px] flex items-center">
                  Use Illion
                </p>
                <span>
                  <i
                    onMouseLeave={() => setshowBasicPopup(false)}
                    onMouseEnter={() => setshowBasicPopup(true)}
                    className="ml-2 pi pi-info-circle text-[0.7rem] cursor-pointer"
                  ></i>
                </span>
                <InfoBox
                  onMouseLeave={() => setshowBasicPopup(false)}
                  style={{ width: "250px", left: 20, top: 30 }}
                  show={showBasicPopup}
                >
                  <p>
                    Access and analyze your financial data securely with Illion.
                    Connect for a complete affordibility assessment
                    (Recommended).
                  </p>
                </InfoBox>
              </div>
              <GradientButton
                classes="!h-[37px] !cursor-pointer"
                onClick={() => {
                  // handleButtonClick("Basiq");
                }}
                txt="Connect Illion"
              />
            </div>
            <div className="flex flex-col gap-[8px]">
              <div className="flex gap-[2px] relative">
                <p className="font-[500] text-[14px] flex items-center">
                  Use Average Australian
                </p>
                <span>
                  <i
                  onMouseLeave={() => setshowAvgAusPopup(false)}
                  onMouseEnter={() => setshowAvgAusPopup(true)}
                  className="ml-2 pi pi-info-circle text-[0.7rem] cursor-pointer"></i>
                </span>
                <InfoBox
                  onMouseLeave={() => setshowAvgAusPopup(false)}
                  style={{ width: "250px", left: 20, top: 30 }}
                  show={showAvgAusPopup}
                >
                  <p>
                  The Borrowing Power calculator estimates your maximum loan amount based on income and expenses. It uses the Household Expenditure Measure (HEM) as a national average for basiq expenses, considering factors like application type and dependants. Actual Expense may differ from this estimate.
                  </p>
                </InfoBox>
              </div>
              <GradientButton
                classes="!h-[37px] !cursor-pointer"
                onClick={() => {
                  handleButtonClick("Average Australian");
                }}
                txt="Use Average"
              />
            </div>
            <div className="flex flex-col gap-[8px]">
              <div className="flex gap-[2px] relative">
                <p className="font-[500] text-[14px] flex items-center">
                  Manual Input
                </p>
                <span>
                  <i
                  onMouseLeave={() => setshowManualPopup(false)}
                  onMouseEnter={() => setshowManualPopup(true)}
                  className="ml-2 pi pi-info-circle text-[0.7rem] cursor-pointer"></i>
                </span>
                <InfoBox
                  onMouseLeave={() => setshowManualPopup(false)}
                  style={{ width: "250px", left: 20, top: 30 }}
                  show={showManualPopup}
                >
                  <p>
                  Take a traditional approach and share your annual expenses, excluding rent/mortgage costs. Remember, the more accurate the date, the more precise your Porfolio Plan will be.
                  </p>
                </InfoBox>
              </div>
              <GradientButton
                classes="!h-[37px] !cursor-pointer"
                onClick={() => {
                  handleButtonClick("Manual Input");
                }}
                txt="Manual Input"
              />
            </div>
          </div>
          <div className="mt-[20px]"></div>
        </Row>
        {usercaldata.BorrowingCalculator.UseAverageAustralian && (
          <>
            <Row>
              <div className="flex items-center gap-[15px] !mt-[20px]">
                <CustomCheckBox
                  inputId="UseAverageAustralianAnnual"
                  checked={usercaldata.BorrowingCalculator.UseAverageAustralian}
                  onChange={(e) => {
                    if (e.checked !== undefined) {
                      setExpenseData({
                        ...ExpenseData,
                        UseAverageAustralian: e.checked,
                        UseBasiq: e.checked === false ? true : false,
                        UseManual: false,
                      });
                    }
                  }}
                ></CustomCheckBox>
                <label
                  className="font-[500] !text-[13px]"
                  htmlFor="UseAverageAustralianAnnual"
                >
                  Use Average Australian Annual Expenses
                </label>
              </div>
            </Row>
            <Row className="!mt-[20px] gap-[50px]">
              <Col xs={4}>
                <div className="ml-[10px]">
                  <p className="mb-[5px] input-label">Living Expenses</p>
                  <div className="flex items-center gap-3">
                    <div>
                      <Slider
                        disabled
                        value={usercaldata.BorrowingCalculator.LivingExpenses}
                        onChange={(e) => {
                          setExpenseData({
                            ...ExpenseData,
                            LivingExpenses: Number(e.value),
                          });
                        }}
                        className={"slider-field"}
                        step={1}
                        min={0}
                        max={100000}
                      />
                    </div>
                    <InputNumbers
                      disabled={true}
                      onChange={(e) => {
                        setExpenseData({
                          ...ExpenseData,
                          LivingExpenses: Number(e.value),
                        });
                      }}
                      value={usercaldata.BorrowingCalculator.LivingExpenses}
                      inputClassName={"!text-center !w-[98px]"}
                    />
                  </div>
                </div>
              </Col> 
              <Col xs={4}>
                <div className="ml-[10px] relative">
                  <p className="mb-[5px] input-label flex items-center">
                    <span>Other Payments</span>
                    <i
                      onMouseLeave={() => setShowLivingPopup(false)}
                      onMouseEnter={() => setShowLivingPopup(true)}
                      className="ml-2 pi pi-info-circle text-[0.7rem] cursor-pointer"
                    ></i>
                  </p>
                  <div className="flex items-center gap-3">
                    <div>
                      <Slider
                        value={ExpenseData.OtherPayments.value}
                        onChange={(e) => {
                          setExpenseData({
                            ...ExpenseData,
                            OtherPayments: {
                              ...ExpenseData.OtherPayments,
                              value: Number(e.value),
                            },
                          });
                        }}
                        className={"slider-field"}
                        step={1}
                        min={0}
                        max={100000}
                      />
                    </div>
                    <ContactDropDown
                      className="!w-[150px] !h-[40px]"
                      value={ExpenseData.OtherPayments.type}
                      options={PaymentTypes}
                      onChange={(e) => {
                        setExpenseData({
                          ...ExpenseData,
                          OtherPayments: {
                            ...ExpenseData.OtherPayments,
                            type: e.value,
                          },
                        });
                      }}
                      placeholder="Data Type"
                      color="#ced4da"
                    />
                    <InputNumbers
                      onChange={(e) => {
                        setExpenseData({
                          ...ExpenseData,
                          OtherPayments: {
                            ...ExpenseData.OtherPayments,
                            value: Number(e.value),
                          },
                        });
                      }}
                      value={ExpenseData.OtherPayments.value}
                      inputClassName={"!text-center !w-[98px]"}
                    />
                  </div>
                  <InfoBox
                    onMouseLeave={() => setShowLivingPopup(!showLivingPopup)}
                    style={{ width: "320px", left: 50, top: 15 }}
                    show={showLivingPopup}
                  >
                    <p>
                      By clicking this button, it will override any self-inputs
                      entered in the Spending Page.
                    </p>
                  </InfoBox>
                </div>
              </Col>
            </Row>
          </>
        )}
        {usercaldata.BorrowingCalculator.UseManual && (
          <>
            <Row>
              <div className="flex items-center gap-[15px] !mt-[20px]">
                <CustomCheckBox
                  inputId="UseManual"
                  checked={usercaldata.BorrowingCalculator.UseManual}
                  onChange={(e) => {
                    if (e.checked !== undefined) {
                      setExpenseData({
                        ...ExpenseData,
                        UseAverageAustralian: false,
                        UseBasiq: e.checked === false ? true : false,
                        UseManual: e.checked,
                      });
                    }
                  }}
                ></CustomCheckBox>
                <label className="font-[500] !text-[13px]" htmlFor="UseManual">
                  Use Manual Input
                </label>
              </div>
            </Row>
            <Row className="!mt-[20px] gap-[50px]">
              <Col xs={4}>
                <div className="ml-[10px]">
                  <p className="mb-[5px] input-label">Annual Expenses</p>
                  <div className="flex items-center gap-3">
                    <div>
                      <Slider
                        value={ExpenseData.ManualExpense}
                        onChange={(e) => {
                          setExpenseData({
                            ...ExpenseData,
                            ManualExpense: Number(e.value),
                          });
                        }}
                        className={"slider-field"}
                        step={1}
                        min={0}
                        max={100000}
                      />
                    </div>
                    <InputNumbers
                      onChange={(e) => {
                        setExpenseData({
                          ...ExpenseData,
                          ManualExpense: Number(e.value),
                        });
                      }}
                      value={ExpenseData.ManualExpense}
                      inputClassName={"!text-center !w-[98px]"}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </>
        )}
      </AnimateHeight>
    </InnerContainer>
  );
});
