import React from 'react'
import { OuterContainer, InnerContainer } from "global.style";
import { PortfolioJourney } from 'components/screen-components/PortfolioJourney';
import PropertyBoxes from 'components/screen-components/Calculator/PropertyGenerator/PropertyBoxes';
import PropertyDetail from 'components/screen-components/Calculator/PropertyGenerator/PropertyDetail';
import "./style.css"
import { GradientButton } from 'components/ui/Buttons/GradientButton';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
export const Generator = () => {
  const navigate = useNavigate()
  const caldata = useSelector((state: RootState) => state.calculator);
  const updateProfile = async (event: React.MouseEvent<HTMLButtonElement>) => {
  
    navigate(`/Clients/IncomePlan?id=${caldata.VendorId}`);
  };
  return (
    <OuterContainer>
      <InnerContainer width="100%" bg={true} padding="1rem 15px 0rem 15px">
        <PortfolioJourney currentActive="Summary" />
        <div className='mt-4'>
          <PropertyBoxes/>
          <PropertyDetail/>  
        </div> 
        <div className="relative flex justify-end mt-9">
          <GradientButton
            onClick={updateProfile}
            style={{
              position: "fixed",
              bottom: "31px",
              right: "94px",
              width: "89px !important",
            }}
            width="89px"
            txt="Next"
          />

          <GradientButton
            style={{
              position: "fixed",
              bottom: "31px",
              right: "196px",
              width: "89px !important",
            }}
            onClick={() => {
              navigate(-1)
            }}
            width="89px"
            txt="Back"
          />
        </div>
        </InnerContainer>
        </OuterContainer>
  )
}
