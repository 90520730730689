import { SendMail } from "store/Slices/PinderSlice";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import EmailImg from "assets/SVG/Email.svg";
import EmailImg2 from "assets/SVG/EmailWhite.svg";
const PropertyEmail = ({ rowData }: { rowData: any }) => {
  let check = rowData?.Background || false;
  const toast = useRef<any>(null);
  const showSuccess = (Message: any) => {
    toast.current.show({
      severity: "warn",
      life: 2000,
      content: (
        <div
          className="flex flex-column "
          style={{ paddingTop: "0px", paddingBottom: "0px", width: "100%" }}
        >
          <div className="text-center ">
            <p className="popup-warn-text">{Message}</p>
          </div>
        </div>
      ),
    });
  };
  const SendEmail = async () => {
    if (
      (rowData?.SuburbProfile !== null && rowData?.SuburbProfile !== "") ||
      (rowData?.SWOT !== null && rowData?.SWOT !== "")
    ) {
      const email = "";
      const subject = "Profile";
      const bodyObj = {};
      let text: any = "";
      let mailTextElement = document.getElementById("mailtext");
      if (mailTextElement) {
        text = mailTextElement.innerHTML;
      }
      try {
        await navigator.clipboard.writeText(text);

        showSuccess("Text Copied");
      } catch (err) {
        showSuccess("Failed to copy");
      }
      SendMail(bodyObj, email, subject);
    }
  };
  return (
    <>
      <Toast ref={toast} />
      <button
        onClick={SendEmail}
        className="text-sm"
        style={{
          width: "30px",
          height: "30px",
          color: "#262660",
          fontFamily: "Inter",
          justifyContent: "center",
          alignItems: "center",
          background: "none",
          border: "none",
          outline: "none",
          display: "flex",
        }}
        type="button"
      >
        {check ? <img src={EmailImg2} /> : <img src={EmailImg} />}
      </button>
      <div id="mailtext" style={{ display: "none", textAlign: "left" }}>
        {`
Dear ${rowData?.ClientNames || ""} 
         
I am writing to you because we have identified a property opportunity located at ${
          rowData?.Address || ""
        } that we think would suit your investment profile. 
${rowData?.Description}
          
Check out a very high-level SWOT Analysis;
${rowData?.SWOT || ""}
 
Check out this insight into the area;
${rowData?.SuburbProfile || ""}
 
Thanks ${rowData?.ClientNames || ""} 
          
       `}
      </div>
    </>
  );
};
export default PropertyEmail;
