import React from 'react'
import TermsAndPolicy from 'components/screen-components/TermsAndPolicy'
export const Terms = () => {
  return (
    <>
    <TermsAndPolicy/>
    </>
  )
}

