import React from 'react'
import { TUserData,TUpdateUser } from 'store/Slices/UserSlice'
import { AvatarContainer,Text } from 'global.style'
import { Image } from 'react-bootstrap'
// @ts-ignore
import FileBase64 from "react-file-base64";
import EditIcon from "assets/images/Edit-icon.svg";
type AvatarProps = {
  width:string,
  height:string,
  text:string,
  imgSrc:string |null | undefined,
  setImgSrc?:any,
  fontSize:string,
  textColor:string,
  setuserdata?:any,
  data?:TUserData | TUpdateUser,
  userProfile?:boolean ,
  classes?:string
}
export const Avatar = ({
  text,
  width,
  height,
  imgSrc,
  fontSize,
  textColor,
  setuserdata,
  setImgSrc,
  data,
  userProfile=false,
  classes
}:AvatarProps) => {
  let textdata = [];
  let firstname = "";
  let l:number = 0;
  let c = "";
  if (containsWhitespace(text)) {
    textdata = text.split(" ");
    firstname = textdata[0];
    l = textdata.length;
    let lastname = textdata[l - 1];
    c = firstname[0] + lastname[0];
  } else {
    firstname = text;
    c = firstname;
  }
  function containsWhitespace(str:string) {
    return /\s/.test(str);
  }
  return (
    <AvatarContainer className={classes} width={width} height={height} bgimg={imgSrc && imgSrc?.length > 0?imgSrc:""}> 
     { imgSrc && imgSrc?.length > 0 ? (
          <>
          </>
        ) : userProfile ? (
          <>
          
          <Text color={textColor} fontSize={fontSize}>
            {data?.first_name && data?.last_name
              ? `${data?.first_name.trim()[0]} ${data?.last_name.trim()[0]}`
              : ""}
          </Text>
          </>
        ) : (
          <Text color={textColor} fontSize={fontSize}>
            {c ? c : "ND"}
          </Text>
        )}
      {setuserdata && (
          <div className="file">
            <FileBase64
              onDone={({ base64 }:any) => {
                setuserdata({ ...data, logos: base64,photo:base64 });
                setImgSrc(base64);
              }}
            />
          </div>
        )}
      {setuserdata && (
          <div className="edit-icon-container">
            <Image src={EditIcon} />
          </div>
        )}
      </AvatarContainer>
  )
}
