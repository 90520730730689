import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { commaStringSeparatorsWithDollors } from "utils/helperFunctions";
import ToolTips from "components/ui/Tooltip/Tooltips";
const SummaryBoxes = () => {
  const userData = useSelector((state: RootState) => state.auth.authData);
  const userAllow = useSelector((state:RootState)=>state.contacts.UserAllow)
  const userCalData = useSelector((state:RootState)=>state.calculator)
  const [Textcolor, setTextcolor] = useState(!userAllow?true:false);
  const [idx, setIdx] = useState<any>(6);
 
  return (
    <div className="mt-10 sumaryboxes">
      <div className="boxs1 myboxes">
        <div className="grid">
          <div className="col-fixed">
            <p className="!text-[13px] font-medium text-black">
              Lifestyle Goal <br />
            </p>
            <p>
              {commaStringSeparatorsWithDollors(userCalData.BorrowingCalculator.income_goal)}
            </p>
          </div>
          <div className="col-fixed">
            <p className="flex items-center justify-center gap-2">
              Goal Achieved in Year
              {Number(userCalData?.PropertyGenerators[userCalData.PropertyGenerators.length-1]?.nextDate?.split("-")[1]) === 0 && (
                <i
                  onMouseEnter={() => setIdx("hovering")}
                  onMouseLeave={() => setIdx(6)}
                  className="pi pi-info-circle "
                ></i>
              )}
              <div className="relative">
                <ToolTips
                  top="25px"
                  width="270px"
                  textAlign="left"
                  show={idx === "hovering" ? true : false}
                  txt="Some values are negative check spending or finance"
                />
              </div>
            </p>
            <div className={Textcolor === true ? "blur1" : "summary-box-text"}>
              <p className={Textcolor === true ? "summary-box-text BlurYear " : "summary-box-text !text-[var(--main-blue)]"}>
                {Number(userCalData?.PropertyGenerators[userCalData.PropertyGenerators.length-1]?.nextDate?.split("-")[1]) === 0 ? "" : userCalData?.PropertyGenerators[userCalData.PropertyGenerators.length-1]?.nextDate?.split("-")[1]}
              </p>
              <div className={Textcolor === true ? "premiumaccess" : ""}>
                {Textcolor === true ? "Premium Access Only." : ""}
              </div>
            </div>
          </div>
          <div className="col-fixed">
            <p >Total Portfolio Value</p>
            <p className={Textcolor === true ? "  mb-2" : "mb-2"}>{commaStringSeparatorsWithDollors(userCalData.PropertyGenerators?.reduce(
    (total: any, NextProperty) => (total += Number(NextProperty.summaryData.PurchasePrice)), 
    0) + userCalData.Equity.PPR.AutoValuation + userCalData.Equity.Properties.reduce((total:number,item)=>(total+=item.AutoValuation),0))}</p>
          </div>
          <div className="col-fixed">
            <p >Average Yield</p>
            <p className="mb-2"> {(
                  userCalData.PropertyGenerators?.reduce(
                    (total: any, NextProperty: any) =>
                    (total +=
                      Number(NextProperty.summaryData.RiskFactoredYieldReturn) > 0
                        ? Number(NextProperty.summaryData.RiskFactoredYieldReturn)
                        : 5), 
                    0
                  ) / userCalData.PropertyGenerators.length 
                ).toFixed(2)}
                %</p>
          </div>

          <div className="col-fixed boxShow">
            <p >
             
              Next Purchase Date
            </p>
            <p>{userCalData?.PropertyGenerators[0]?.nextDate}</p>
          </div>
          <div className="col-fixed boxShow">
            <p className="">Cash + Equity Available</p>
            <p className="mb-2">{commaStringSeparatorsWithDollors(userCalData.Equity.Cash+userCalData.Equity.UsableEquity)}</p>
          </div>
          <div className="col-fixed boxShow">
            <p className="">Year 1 Cashflow</p>
            <p className="mb-2">{commaStringSeparatorsWithDollors(userCalData?.PropertyGenerators[0]?.summaryData?.CashflowAnnual)}</p>
          </div>
          <div className="col-fixed boxShow">
            <p className="">Year 1 Cashflow (Monthly)</p>
            <p className="mb-2">{commaStringSeparatorsWithDollors(userCalData?.PropertyGenerators[0]?.summaryData?.CashflowMonthly)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryBoxes;
