import React, { useState, memo, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useCustomInputChange } from "hooks/useCustomInputChange";
import { Avatar } from "components/screen-components/Avatar";
import { InputTexts } from "components/ui/InputTexts";
import { ContactDropDown } from "global.style";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { ContactTypeOptions, AdminContactTypes } from "utils/constants";
import { InnerContainer } from "global.style";
import InputPhone from "components/ui/InputPhone";
import { GS } from "services/global";
import { useGetDomainData } from "hooks/useGetDomainData";
export const VendorProfileInfo = memo(() => {
  const LoginUserProfile = useSelector(
    (state: RootState) => state.userProfile.data
  );
  const { getSuburbStats } = useGetDomainData();
  const [searchParams] = useSearchParams();
  let id = searchParams.get("id") as string;
  const { userdata, setuserdata, handleChange, photo, setPhoto, handleEdit } =
    useCustomInputChange({
      initialUserData: {
        full_name: "",
        first_name: "",
        last_name: "",
        email: "",
        mobile_no: "",
        logos: "",
        photo: "",
        telephone_no: "",
        contact_type: "",
        contact_address: "",
      },
      id: id,
    });
  const GetSuburbsData = async () => {
    if (GS?.Calculator?.DomainData?.data?.residential?.length === 0) {
      await getSuburbStats();
    }
  };
  useEffect(() => {
    GetSuburbsData();
  }, []);
  return (
    <>
      <InnerContainer padding="1rem 30px 0.7rem 30px">
        <div className="profile-container">
          <h1>Profile</h1>
          <div className="avatar-container">
            <Avatar
              text="CH"
              width="80px"
              height="80px"
              fontSize="23px"
              textColor="#fefefe"
              imgSrc={userdata?.photo || ""}
              setImgSrc={setPhoto}
              setuserdata={setuserdata}
              data={userdata}
              userProfile={true}
            />

            <div className="avatar-text-container">
              <p className="main-text">
                {userdata?.first_name}&nbsp;{userdata?.last_name}
              </p>
              <p className="sub-text !mt-[5px]">
                {userdata?.contact_type || ""}
              </p>
            </div>
          </div>
        </div>
        <div className="-mt-[20px]">
          <div className="gap-10 columns-1 lg:columns-3">
            <div className="py-2">
              <div className="flex flex-col">
                <p className="mb-2 input-label">First name</p>
                <InputTexts
                  onBlur={handleEdit}
                  height="40px"
                  type={"text"}
                  name="first_name"
                  value={userdata?.first_name}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="py-2">
              <div className="flex flex-col">
                <p className="mb-2 input-label">Last name</p>
                <InputTexts
                  onBlur={handleEdit}
                  height="40px"
                  type={"text"}
                  name="last_name"
                  value={userdata?.last_name}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="py-2">
              <div className="flex flex-col">
                <p className="mb-2 input-label">Email</p>
                <InputTexts
                  onBlur={handleEdit}
                  type={"email"}
                  height="40px"
                  name="email"
                  value={userdata?.email}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="gap-10 columns-1 lg:columns-3">
            <div className="py-2">
              <div className="flex flex-col">
                <p className="mb-2 input-label">Mobile No</p>
                <InputPhone
                  value={userdata?.mobile_no}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value) {
                      setuserdata({ ...userdata, mobile_no: value });
                    }
                  }}
                  name="mobile_no"
                  id="mobile"
                  mask="9999 999 999"
                  placeholder=""
                  onBlur={handleEdit}
                />
              </div>
            </div>
            {/* <div className="py-2">
              <div className="flex flex-col">
                <p className="mb-2 input-label">Phone Number</p>
                <InputPhone
                  value={userdata?.telephone_no}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value) {
                      setuserdata({ ...userdata, telephone_no: value });
                    }
                  }}
                  name="telephone_no"
                  id="phone"
                  mask="9999 999 999"
                  placeholder=""
                  onBlur={handleEdit}
                />
              </div>
            </div> */}
            <div className="py-2">
              <div className="flex flex-col">
                <p className="mb-2 input-label">Membership Type</p>
                <ContactDropDown
                  height="40px"
                  value={userdata?.contact_type}
                  onChange={(e) => {
                    setuserdata({ ...userdata, contact_type: e.value });
                    handleEdit(e);
                  }}
                  options={
                    process.env.REACT_APP_ADMIN_EMAIL ===
                    LoginUserProfile?.email
                      ? AdminContactTypes
                      : ContactTypeOptions
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </InnerContainer>
    </>
  );
});
