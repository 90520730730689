import React, { useState, useRef } from "react";
import { Container, InnerContainer } from "global.style";
import { Button } from "components/ui/Buttons/Button";
import { Avatar } from "components/screen-components/Avatar";
import { Toast } from "primereact/toast";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/store";
import { ContactUser } from "components/screen-components/svgcomponents";
import {
  TCreateContact,
  CreateContacts,
  GetAllAdmins,
} from "store/Slices/ContactSlice";
import { ContactForm } from "components/screen-components/ContactForm";
import { handleContactFormErrors } from "utils/helperFunctions";
// @ts-ignore

export const CreateAdmin = () => {
  const dispatch = useDispatch();
  const toast = useRef<any>(null);
  const { Admins, Contacts } = useSelector(
    (state: RootState) => state.contacts
  );
  const [photo, setPhoto] = useState<string>("");
  const [userdata, setuserdata] = useState<TCreateContact>({
    first_name: "",
    last_name: "",
    email: "",
    mobile_no: "+61",
    photo: "",
    telephone_no: "",
    contact_type: "Master Admin",
    contact_address: "",
    password: "",
    agent: "",
    preferred_contact_method: "",
    role_id: "610a0f1dc61edfcf62d62bb1",
    office_id: "610a0f1dc61edfcf62d62c52",
  });
  const clear = () => {
    toast.current.clear();
  };
  const showError = (Message: string) => {
    toast.current.show({
      severity: "success",
      sticky: true,
      content: (
        <div className="flex p-5 flex-column" style={{ flex: "1" }}>
          <div className="py-1 text-center">
            <p className="popup-error-text">{Message}</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <span
              className="toast-anchor toast-no"
              style={{ marginTop: "15px" }}
              onClick={clear}
            >
              Ok
            </span>
          </div>
        </div>
      ),
    });
  };
  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      life: 2000,
      content: (
        <div className="flex p-5 flex-column w-[100%]">
          <div className="text-center ">
            <div className="flex justify-center w-[100%]">
              <ContactUser />
            </div>
            <p className="!text-[#323232] !text-[16px] !text-center !mt-[32px]">
              Admin is created
            </p>
          </div>
        </div>
      ),
    });
  };
  const checkEmailExistance = (email: string) => {
    let check = false;
    Admins.map((item) => {
      if (item.email === email) {
        check = true;
      }
    });
    Contacts.map((item) => {
      if (item.email === email) {
        check = true;
      }
    });
    return check;
  };

  const handleAdd = async () => {
    let { error, message } = handleContactFormErrors(userdata);
    if (!error) {
      if (checkEmailExistance(userdata.email)) {
        showError("Email Already Exist");
      } else {
        let response = await CreateContacts(userdata);
        if (response?.insertedId) {
          await dispatch(GetAllAdmins() as any);
          showSuccess();
        } else {
          showError(response.message);
        }
      }
    } else {
      showError(message);
    }
  };
  return (
    <Container>
      <Toast
        ref={toast}
        position="center"
        style={{ zIndex: "2", opacity: "1" }}
      />
      <InnerContainer padding="1.8rem 32px 1rem 32px">
        <div className="profile-container">
          <h1>Profile</h1>
          <Button
            onClick={handleAdd}
            txt="Create Admin"
            paddingTop="20px 20px"
          />
        </div>
        <div className="flex flex-col">
          <div className="avatar-container">
            <Avatar
              text="CH"
              width="80px"
              height="80px"
              fontSize="23px"
              textColor="#fefefe"
              imgSrc={userdata?.photo || ""}
              setImgSrc={setPhoto}
              setuserdata={setuserdata}
              data={userdata}
              userProfile={false}
            />

            <div className="avatar-text-container">
              <p className="main-text">
                {userdata?.first_name}&nbsp;{userdata?.last_name}
              </p>
            </div>
          </div>
          <div className="mt-[20px]">
            <ContactForm
              isAdmin={true}
              userdata={userdata}
              setuserdata={setuserdata}
            />
          </div>
        </div>
      </InnerContainer>
    </Container>
  );
};
