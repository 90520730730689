import { useState, useCallback, useEffect } from "react";
import {
  TUserData,
  TUpdateUser,
  UpdateUser,
  GetUserById,
} from "store/Slices/UserSlice";
import { formatPhoneNumber } from "utils/helperFunctions";
import { GetAllContacts } from "store/Slices/ContactSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";

type props = {
  initialUserData: TUserData;
  id: string;
};
export const useCustomInputChange = ({ initialUserData, id }: props) => {
  const [userdata, setuserdata] = useState<TUserData>(initialUserData);
  const contacts = useSelector((state: RootState) => state.contacts);
  const selectedUser = useSelector((state:RootState)=>state.userProfile.selectedUser)
  const [photo, setPhoto] = useState<string>("");
  const [initialSave,setInitialSave]=useState<boolean>(true)
  const dispatch = useDispatch();
 
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
   
    setuserdata({ ...userdata, [e.target.name]: e.target.value });
  };
  const UpdateData = async()=>{
    let body: TUpdateUser = {
      first_name: userdata?.first_name,
      last_name: userdata?.last_name,
      email: userdata?.email,
      mobile_no: userdata?.mobile_no,
      telephone_no: userdata?.telephone_no,
      contact_type: userdata?.contact_type,
      contact_address: userdata?.contact_address,
      password: "",
    };
    if (photo) {
      body.photo = userdata?.photo;
    }
    let response = await UpdateUser(id as string, body);
    if (response.status) {
      let officeId = process.env.REACT_APP_OFFICEID as string;
      dispatch(GetAllContacts(officeId) as any);
    }
  }
  const handleEdit = async (e: any) => {
    e.preventDefault();
    UpdateData();
  };
  const FetchUsers = useCallback(async () => {
    setuserdata({
      full_name: selectedUser?.full_name,
      first_name: selectedUser?.first_name,
      last_name: selectedUser?.last_name,
      email:selectedUser?.email,
      mobile_no: selectedUser?.mobile_no,
      photo:selectedUser?.photo || "",
      telephone_no: selectedUser?.telephone_no,
      contact_type: selectedUser?.contact_type,
      contact_address: selectedUser?.contact_address,
    });
    
    
  }, [id]);

  useEffect(() => {
    FetchUsers();
  }, [FetchUsers]);
  useEffect(()=>{
    if(initialSave){
      setInitialSave(false)
    }else{
      UpdateData();
    }
  },[photo])
  return {
    userdata,
    setuserdata,
    handleChange,
    photo,
    setPhoto,
    handleEdit,
  };
};
