import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { GetFilters } from "../store/Slices/PinderSlice";
import { TPostcodes, TPropertyOptions, TListingOptions } from "../types";
type TResponse = {
  _id: string;
  totals: number;
};

export const useSetPinderFiltersOptions = () => {
  const pinderstate = useSelector((state: RootState) => state.pinder);
  const [postcodes, setpostcodes] = useState<TPostcodes[]>([]);
  const [propertyOptions, setpropertyOptions] = useState<TPropertyOptions[]>(
    []
  );
  const [ListingOptions, setListingOptions] = useState<TListingOptions[]>([]);
  const GetFilterOptions = async () => {
    let response = await GetFilters(pinderstate.currentPinderState);
    let postoptions: TPostcodes[] = response?.data?.postcodes?.map(
      (item: TResponse) => {
        let newObj = {
          name: item._id,
          code: item._id,
        };
        return newObj;
      }
    );
    postoptions?.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    );
    let propertyoptions: TPropertyOptions[] = response?.data?.propType?.map(
      (item: TResponse) => {
        let listing = item._id;
        if (pinderstate.currentPinderState === "residential") {
          listing = item._id.charAt(0).toUpperCase() + item._id.slice(1);
          let capitalLetterCount = 0;
          let indexSecondCapitalLetter = -1;
          let indexThirdCapitalLetter = -1;
          for (let i = 0; i < listing.length; i++) {
            if (listing[i] === listing[i].toUpperCase()) {
              capitalLetterCount++;
              if (capitalLetterCount === 2) {
                indexSecondCapitalLetter = i;
              } else if (capitalLetterCount === 3) {
                indexThirdCapitalLetter = i;
                break;
              }
            }
          }
          if (indexThirdCapitalLetter !== -1) {
            listing =
              listing.slice(0, indexSecondCapitalLetter) +
              " " +
              listing.slice(indexSecondCapitalLetter, indexThirdCapitalLetter) +
              " " +
              listing.slice(indexThirdCapitalLetter);
          } else if (indexSecondCapitalLetter !== -1) {
            listing =
              listing.slice(0, indexSecondCapitalLetter) +
              " " +
              listing.slice(indexSecondCapitalLetter);
          }
        }
        let newObj = {
          name: listing,
          code: item._id,
        };
        return newObj;
      }
    );
    propertyoptions?.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    );
    let listingoptions: TListingOptions[] = response?.data?.listingType?.map(
      (item: TResponse) => {
        let listing = item._id.charAt(0).toUpperCase() + item._id.slice(1);
        let capitalLetterCount = 0;
        let indexSecondCapitalLetter = -1;
        for (let i = 0; i < listing.length; i++) {
          if (listing[i] === listing[i].toUpperCase()) {
            capitalLetterCount++;
            if (capitalLetterCount === 2) {
              indexSecondCapitalLetter = i;
              break;
            }
          }
        }
        if (indexSecondCapitalLetter !== -1) {
          listing =
            listing.slice(0, indexSecondCapitalLetter) +
            " " +
            listing.slice(indexSecondCapitalLetter);
        }
        let newObj = {
          name: listing,
          code: item._id,
        };
        return newObj;
      }
    );
    listingoptions?.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    );
    setListingOptions(listingoptions);
    setpropertyOptions(propertyoptions);
    setpostcodes(postoptions);
  };
  useEffect(() => {
    GetFilterOptions();
  }, [pinderstate.currentPinderState]); // eslint-disable-line react-hooks/exhaustive-deps

  return { postcodes, propertyOptions, ListingOptions };
};
