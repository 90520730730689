import axios from 'axios';
export const BaseURL=process.env.REACT_APP_API_BASEURL

const instance = axios.create({ 
    baseURL: BaseURL,
  });
  export const setAuthToken = (tokeen?: string) => {
    try { 
      const token = sessionStorage.getItem('Token') as string;
      if (tokeen) {
        instance.defaults.headers.common.Authorization= `Bearer ${tokeen}`
      }
      if(token){
        instance.defaults.headers.common.Authorization= `Bearer ${token}`
      }
    } catch (e) {}
  };
  setAuthToken();
  export const DeleteAuthToken=()=>{
    delete instance.defaults.headers.common['Authorization'];
    delete instance.defaults.headers.common.Authorization
  }

  instance.interceptors.response.use(
    response => response,
    error => {
      // Handle error responses
      if (error.response && error.response.status === 403) {
        // Handle unauthorized access
      }
      return Promise.reject(error);
    },
  );
  
  export default instance;