import { Route, Routes } from "react-router-dom";
import {
  Siginin,
  Signup,
  ResetPassword,
  Terms,
  Dashboard,
  Profile,
  ContactList,
  ClientList,
  EditContact,
  LocalData,
  LiveData,
  AgencyEdit,
  Permissions,
  Users,
  MedianPrices,
  Assumptions,
  Sliders,
  Themes,
  PropertyType,
  Vendors,
  CreateUser,
  CreateAdmin,
  Finance,
  ClientProfile,
  Spending,
  Summary,
  Generator,
  IncomePlan,
  UploadCSV
} from "./pages";

import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import { useSelector } from "react-redux";
import { RootState } from "./store/store";
import { RouteTypes } from "./types";


function App() {
  const routestate = useSelector((state: RootState) => state.auth.authData);
  const AdminRoutes: RouteTypes[] = [
    { path: "/ContactList", element: <ContactList />, PrivateRoute: true },
    { path: "/EditContact/:id", element: <EditContact />, PrivateRoute: true },
    { path: "/Clients", element: <ClientList />, PrivateRoute: true },
    {
      path: "/CreateContact",
      element: <CreateUser />,
      PrivateRoute: true,
     
    },
    {
      path: "/CreateAdmin",
      element: <CreateAdmin />,
      PrivateRoute: true,
      
    },
    { path: "/Vendors", element: <Vendors />, PrivateRoute: true },
    {
      path: "/pinder/savedProperties",
      element: <LocalData />,
      PrivateRoute: true,
    },
    {
      path: "/pinder/liveProperties",
      element: <LiveData />,
      PrivateRoute: true,
    },
    { path: "/agency/edit", element: <AgencyEdit />, PrivateRoute: true },
    { path: "/agency/users", element: <Users />, PrivateRoute: true },
    { path: "/agency/access", element: <Permissions />, PrivateRoute: true },
    {
      path: "/settings/assumptions",
      element: <Assumptions />,
      PrivateRoute: true,
    },
    { path: "/settings/slider", element: <Sliders />, PrivateRoute: true },
    { path: "/settings/themes", element: <Themes />, PrivateRoute: true },
    {
      path: "/settings/median-prices",
      element: <MedianPrices />,
      PrivateRoute: true,
    },
    {
      path: "/settings/propertyType",
      element: <PropertyType />,
      PrivateRoute: true,
    },
    {
      path: "/settings/upload-csv",
      element: <UploadCSV />,
      PrivateRoute: true,
    },
  ];
  const ClientRoutes: RouteTypes[] = [];
  // useGetDomainData();
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/signin"
        element={
          <PublicRoute>
            <Siginin />
          </PublicRoute>
        }
      />
      <Route
        path="/signup"
        element={
          <PublicRoute>
            {" "}
            <Signup />
          </PublicRoute>
        }
      />
      <Route
        path="/reset-password"
        element={
          <PublicRoute>
            {" "}
            <ResetPassword />
          </PublicRoute>
        }
      />
      <Route
        path="/Terms"
        element={
          <PublicRoute>
            <Terms />{" "}
          </PublicRoute>
        }
      />
      <Route
        path="/Profile"
        element={
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        }
      />
       <Route 
       path="/Clients/Profile"
        element={
          <PrivateRoute> 
        <ClientProfile />
        </PrivateRoute>
        } />
        <Route 
       path="/Clients/Finances"
        element={
          <PrivateRoute>
        <Finance />
        </PrivateRoute>
        } />
        <Route 
       path="/Clients/Spending"
        element={
          <PrivateRoute>
        <Spending />
        </PrivateRoute>
        } />
        <Route 
       path="/Clients/Summary"
        element={
          <PrivateRoute>
        <Summary />
        </PrivateRoute>
        } />
         <Route 
       path="/Clients/Generator/:propertyid"
        element={
          <PrivateRoute>
        <Generator/>
        </PrivateRoute>
        } />
         <Route 
       path="/Clients/IncomePlan"
        element={
          <PrivateRoute>
        <IncomePlan />
        </PrivateRoute>
        } />
      {routestate.accountType === "Master Admin"
        ? AdminRoutes.map((item, index) => {
            return (
              <Route
                key={index}
                path={item.path}
                element={
                  item.PrivateRoute ? (
                    <PrivateRoute>{item.element}</PrivateRoute>
                  ) : (
                    item.element
                  )
                }
              />
            );
          })
        : ClientRoutes.map((item, index) => {
            return (
              <Route
                key={index}
                path={item.path}
                element={
                  item.PrivateRoute ? (
                    <PrivateRoute>{item.element}</PrivateRoute>
                  ) : (
                    item.element
                  )
                }
              />
            );
          })}
    </Routes>
  );
}

export default App;
