import React from 'react'
import { Navigate } from 'react-router-dom'
import { ClientDashboard } from './ClientDashboard'
import { AdminDashboard } from './AdminDashboard'
export const Dashboard = () => {
    let accountType = sessionStorage.getItem('accountType') as string
    let token = sessionStorage.getItem("Token") as string
    return (
        <>
            {token ?
                <>
                    {accountType === "Master Admin" ? <AdminDashboard /> : <ClientDashboard />}
                </>
                :
                <>
                    <Navigate to="/signin" />
                </>}

        </>
    )


}

