import React ,{useState} from "react";
import { InnerContainer, Container } from "global.style";
import { RadioButton } from "primereact/radiobutton";
export const PropertyType = () => {
  const [propertytype, setPropertytype] = useState("Residential");
  const [RiskType, setRiskType] = useState("Aggressive");
  return (
    <Container>
      <InnerContainer>
        <div className="profile-container">
          <div>
          <h1 className="mb-3 card-title">Property Type</h1>
          <p>
            Choose the types of properties you wish to include in your portfolio
            plans. These selections will be reflected in the composition of your
            portfolio plan.
          </p>
          </div>
        </div>
        <div className="mt-[40px]" >
        <div className="field-radiobutton radiomargins">
          <RadioButton
            inputId="risktype1"
            name="risktype"
            value="Aggressive"
            onChange={(e) => {
              setRiskType(e.value);
            }}
            checked={RiskType === "Aggressive"}
          />
          <label htmlFor="risktype1" className="titleLabel">
            Aggressive
          </label>
        </div>
        <div className="flex flex-wrap">
          <div className="field-radiobutton radiomargins">
            <RadioButton
              inputId="propertytype1"
              name="propertytype"
              value="Residential"
              onChange={(e) => {
                setPropertytype(e.value);
              }}
              checked={propertytype === "Residential"}
            />
            <label htmlFor="propertytype1">Residential</label>
          </div>
          <div className="field-radiobutton radiomargins">
            <RadioButton
              inputId="propertytype2"
              name="propertytype"
              value="Commercial Retail"
              onChange={(e) => {
                setPropertytype(e.value);
              }}
              checked={propertytype === "Commercial Retail"}
            />
            <label htmlFor="propertytype2">Retail</label>
          </div>
          <div className="field-radiobutton radiomargins">
            <RadioButton
              inputId="propertytype3"
              name="propertytype"
              value="Commercial Office"
              onChange={(e) => {
                setPropertytype(e.value);
              }}
              checked={propertytype === "Commercial Office"}
            />
            <label htmlFor="propertytype3">Office</label>
          </div>
          <div className="field-radiobutton radiomargins">
            <RadioButton
              inputId="propertytype4"
              name="propertytype"
              value="Industrial"
              onChange={(e) => {
                setPropertytype(e.value);
              }}
              checked={propertytype === "Industrial"}
            />
            <label htmlFor="propertytype4">Industrial</label>
          </div>
          <div className="field-radiobutton radiomargins">
            <RadioButton
              inputId="propertytype5"
              name="propertytype"
              value="Granny Flat"
              onChange={(e) => {
                setPropertytype(e.value);
              }}
              checked={propertytype === "Granny Flat"}
            />
            <label htmlFor="propertytype5">Granny Flat</label>
          </div>
          <div className="field-radiobutton radiomargins">
            <RadioButton
              inputId="propertytype6"
              name="propertytype"
              value="NDIS"
              onChange={(e) => {
                setPropertytype(e.value);
              }}
              checked={propertytype === "NDIS"}
            />
            <label htmlFor="propertytype6">NDIS</label>
          </div>

          <div className="field-radiobutton radiomargins">
            <RadioButton
              inputId="propertytype7"
              name="propertytype"
              value="Rooming House"
              onChange={(e) => {
                setPropertytype(e.value);
              }}
              checked={propertytype === "Rooming House"}
            />
            <label htmlFor="propertytype7">Rooming House</label>
          </div>
          <div className="field-radiobutton radiomargins">
            <RadioButton
              inputId="propertytype8"
              name="propertytype"
              value="Micro Apartment"
              onChange={(e) => {
                setPropertytype(e.value);
              }}
              checked={propertytype === "Micro Apartment"}
            />
            <label htmlFor="propertytype8">Micro Apartment</label>
          </div>
        </div>
      </div>
      <div className="mt-[40px]" >
        <div className="field-radiobutton radiomargins">
          <RadioButton
            inputId="risktype2"
            name="risktype"
            value="Moderate"
            onChange={(e) => {
              setRiskType(e.value);
            }}
            checked={RiskType === "Moderate"}
          />
          <label htmlFor="risktype2" className="titleLabel">
          Moderate
          </label>
        </div>
        <div className="flex-wrap d-flex">
          <div className="field-radiobutton radiomargins">
            <RadioButton
              inputId="propertytype1"
              name="propertytype"
              value="Residential"
              onChange={(e) => {
                setPropertytype(e.value);
              }}
              checked={propertytype === "Residential"}
            />
            <label htmlFor="propertytype1">Residential</label>
          </div>
          <div className="field-radiobutton radiomargins">
            <RadioButton
              inputId="propertytype2"
              name="propertytype"
              value="Commercial Retail"
              onChange={(e) => {
                setPropertytype(e.value);
              }}
              checked={propertytype === "Commercial Retail"}
            />
            <label htmlFor="propertytype2">Retail</label>
          </div>
          <div className="field-radiobutton radiomargins">
            <RadioButton
              inputId="propertytype3"
              name="propertytype"
              value="Commercial Office"
              onChange={(e) => {
                setPropertytype(e.value);
              }}
              checked={propertytype === "Commercial Office"}
            />
            <label htmlFor="propertytype3">Office</label>
          </div>
          <div className="field-radiobutton radiomargins">
            <RadioButton
              inputId="propertytype4"
              name="propertytype"
              value="Industrial"
              onChange={(e) => {
                setPropertytype(e.value);
              }}
              checked={propertytype === "Industrial"}
            />
            <label htmlFor="propertytype4">Industrial</label>
          </div>
          <div className="field-radiobutton radiomargins">
            <RadioButton
              inputId="propertytype5"
              name="propertytype"
              value="Granny Flat"
              onChange={(e) => {
                setPropertytype(e.value);
              }}
              checked={propertytype === "Granny Flat"}
            />
            <label htmlFor="propertytype5">Granny Flat</label>
          </div>
          <div className="field-radiobutton radiomargins">
            <RadioButton
              inputId="propertytype6"
              name="propertytype"
              value="NDIS"
              onChange={(e) => {
                setPropertytype(e.value);
              }}
              checked={propertytype === "NDIS"}
            />
            <label htmlFor="propertytype6">NDIS</label>
          </div>

          <div className="field-radiobutton radiomargins">
            <RadioButton
              inputId="propertytype7"
              name="propertytype"
              value="Rooming House"
              onChange={(e) => {
                setPropertytype(e.value);
              }}
              checked={propertytype === "Rooming House"}
            />
            <label htmlFor="propertytype7">Rooming House</label>
          </div>
          <div className="field-radiobutton radiomargins">
            <RadioButton
              inputId="propertytype8"
              name="propertytype"
              value="Micro Apartment"
              onChange={(e) => {
                setPropertytype(e.value);
              }}
              checked={propertytype === "Micro Apartment"}
            />
            <label htmlFor="propertytype8">Micro Apartment</label>
          </div>
        </div>
      </div>
      <div className="mt-[40px] mb-[10px]" >
        <div className="field-radiobutton radiomargins">
          <RadioButton
            inputId="risktype3"
            name="risktype"
            value="Conservative"
            onChange={(e) => {
              setRiskType(e.value);
            }}
            checked={RiskType === "Conservative"}
          />
          <label htmlFor="risktype3" className="titleLabel">
          Conservative
          </label>
        </div>
        <div className="flex-wrap d-flex">
          <div className="field-radiobutton radiomargins">
            <RadioButton
              inputId="propertytype1"
              name="propertytype"
              value="Residential"
              onChange={(e) => {
                setPropertytype(e.value);
              }}
              checked={propertytype === "Residential"}
            />
            <label htmlFor="propertytype1">Residential</label>
          </div>
          <div className="field-radiobutton radiomargins">
            <RadioButton
              inputId="propertytype2"
              name="propertytype"
              value="Commercial Retail"
              onChange={(e) => {
                setPropertytype(e.value);
              }}
              checked={propertytype === "Commercial Retail"}
            />
            <label htmlFor="propertytype2">Retail</label>
          </div>
          <div className="field-radiobutton radiomargins">
            <RadioButton
              inputId="propertytype3"
              name="propertytype"
              value="Commercial Office"
              onChange={(e) => {
                setPropertytype(e.value);
              }}
              checked={propertytype === "Commercial Office"}
            />
            <label htmlFor="propertytype3">Office</label>
          </div>
          <div className="field-radiobutton radiomargins">
            <RadioButton
              inputId="propertytype4"
              name="propertytype"
              value="Industrial"
              onChange={(e) => {
                setPropertytype(e.value);
              }}
              checked={propertytype === "Industrial"}
            />
            <label htmlFor="propertytype4">Industrial</label>
          </div>
          <div className="field-radiobutton radiomargins">
            <RadioButton
              inputId="propertytype5"
              name="propertytype"
              value="Granny Flat"
              onChange={(e) => {
                setPropertytype(e.value);
              }}
              checked={propertytype === "Granny Flat"}
            />
            <label htmlFor="propertytype5">Granny Flat</label>
          </div>
          <div className="field-radiobutton radiomargins">
            <RadioButton
              inputId="propertytype6"
              name="propertytype"
              value="NDIS"
              onChange={(e) => {
                setPropertytype(e.value);
              }}
              checked={propertytype === "NDIS"}
            />
            <label htmlFor="propertytype6">NDIS</label>
          </div>

          <div className="field-radiobutton radiomargins">
            <RadioButton
              inputId="propertytype7"
              name="propertytype"
              value="Rooming House"
              onChange={(e) => {
                setPropertytype(e.value);
              }}
              checked={propertytype === "Rooming House"}
            />
            <label htmlFor="propertytype7">Rooming House</label>
          </div>
          <div className="field-radiobutton radiomargins">
            <RadioButton
              inputId="propertytype8"
              name="propertytype"
              value="Micro Apartment"
              onChange={(e) => {
                setPropertytype(e.value);
              }}
              checked={propertytype === "Micro Apartment"}
            />
            <label htmlFor="propertytype8">Micro Apartment</label>
          </div>
        </div>
      </div>
      </InnerContainer>
    </Container>
  );
};
