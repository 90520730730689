import React, { useState, useRef } from "react";
import { Container, InnerContainer } from "global.style";
import { Button } from "components/ui/Buttons/Button";
import { Avatar } from "components/screen-components/Avatar";
import { Toast } from "primereact/toast";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/store";
import { TCreateContact, CreateContacts } from "store/Slices/ContactSlice";
import { ContactForm } from "components/screen-components/ContactForm";
import { handleContactFormErrors } from "utils/helperFunctions";
import { ContactUser } from "components/screen-components/svgcomponents";
import { useNavigate } from "react-router-dom";
import { CreateBasiqUser, TBasicUser } from "store/Slices/UserSlice";
import { setBasiqUserId } from "store/Slices/UserSlice";
import { TCalculatorData, SaveCalData } from "store/Slices/CalculatorSlice";
import { GetAllContacts } from "store/Slices/ContactSlice";
import { SpaceRemove } from "utils/helperFunctions";
import moment from "moment";
import { setPath } from "store/Slices/PathSlice";
import { GetUserCalData } from "store/Slices/CalculatorSlice";
import { setSelectedUser } from "store/Slices/UserSlice";
export const CreateUser = () => {
  const dispatch = useDispatch();
  const toast = useRef<any>(null);
  const navigate = useNavigate();
  const { Admins, Contacts } = useSelector(
    (state: RootState) => state.contacts
  );
  const settingsData = useSelector((state: RootState) => state.settings);
  const Loginuser = useSelector((state: RootState) => state.userProfile.data);
  const [photo, setPhoto] = useState<string>("");
  const [userdata, setuserdata] = useState<TCreateContact>({
    first_name: "",
    last_name: "",
    email: "",
    mobile_no: "+61",
    photo: "",
    telephone_no: "",
    contact_type:
      process.env.REACT_APP_ADMIN_EMAIL === Loginuser.email
        ? "Contact Paid"
        : "Contact Lite",
    contact_address: "",
    password: "",
    agent: "",
    preferred_contact_method: "",
    role_id: "6193a50669fff80382457d27",
    office_id: "610a0f1dc61edfcf62d62c52",
  });
  const [InitialCalculatorData] = useState<TCalculatorData>({
    Equity: {
      Cash: 0,
      LiquidAssets: 0,
      PPR: {
        Address: "",
        Mortgage: 0,
        InterestRate: 0,
        DebtProvider: "",
        AutoValuation: 0,
        PurchaseDate: "",
        LoanTerms: 30,
        RemainingLoanTerm: 0,
        LoanRepayments: {
          type: "Monthly",
          value: 0,
          principalvalue: 0,
          interestvalue: 0,
        },
        InterestOnly: true,
        PrincipalAndInterest: false,
      },
      Properties: [],
      MaxBorrowingCapacity:
        0 - (settingsData.Assumptions.SingleBaseExpense | 0),
      UsableEquity: 0,
      TotalDebt: 0,
      UsableEquityAndCash: 0,
    },
    BorrowingCalculator: {
      income_goal: 0,
      FinancialYear: 0,
      LivingExpenses: settingsData.Assumptions.SingleBaseExpense | 0,
      ManualExpense: 0,
      BasiqExpenses: 0,
      MonthlyCashFlow: 0,
      TotalExpenses: (settingsData.Assumptions.SingleBaseExpense / 12) | 0,
      OtherPayments: {
        type: "Monthly",
        value: 0,
      },
      UseBasiq: false,
      UseAverageAustralian: true,
      UseManual: false,
      IncomeDetails: {
        UserPartner: false,
        YourIncome: {
          type: "Annually",
          value: 0,
        },
        PartnerIncome: {
          type: "Annually",
          value: 0,
        },
        NetRentalIncome: {
          type: "Annually",
          value: 0,
        },
        OtherIncome: {
          type: "Annually",
          value: 0,
          icomeType: "Net",
        },
      },
      ExpenseDetails: {
        Dependants: 0,
        AlcoholicBeverages: 0,
        ClothingAndFootwear: 0,
        Communication: 0,
        CurrentHousingCosts: 0,
        DomesticFuelAndPower: 0,
        Education: 0,
        FoodNonAlcoholicBeverages: 0,
        HouseholdFurnishingsAndEquipment: 0,
        HouseholdServicesAndOperation: 0,
        MedicalCareAndHealthExpenses: 0,
        PersonalCare: 0,
        Recreation: 0,
        Transport: 0,
        AccountantFees: 0,
        BodyCorporatePayments: 0,
        CashGiftDonationsToCharityNec: 0,
        FeesNecGovernment: 0,
        FeesNecPrivate: 0,
        FinancialInstitutionCharges: 0,
        HireServicesNec: 0,
        InsuranceNec: 0,
        MiscellaneousGoodsNec: 0,
        MiscellaneousGoodsNfd: 0,
        MiscellaneousServicesNec: 0,
        NonHolidayAccommodation: 0,
        StationeryEquipmentNfd: 0,
        TravelGoodsHandbags: 0,
        UnionDues: 0,
        OtherPayment: 0,
        CashWithdrawals: 0,
        BankFees: 0,
        LoanInterest: 0,
        ExternalTransfers: 0,
      },

      PersonalLiabilities: {
        CreditCards: [],
        creditCardPartner: 0,
        creditCardYours: 0,
        TotalCardDebt: 0,
        TotalCardLimit: 0,
        TotalCreditCardLimit: 0,
        TotalLoan: 0,
        Loan: 0,
      },
    },
    IncomePlan: [],
    PropertyGenerators: [],
    Progress: {
      steps: 5,
      completed: 0,
      current: 1,
    },
    RiskProfile: {
      NetYieldonProperty: 5,
      PropertyType: "Residential",
      PropertyMode: "Conservative",
    },
    StrategicSpending: {
      EquityGains: [
        {
          PropertyName: `PPR`,
          Percentage: 5,
          StartDate: new Date(moment().add(1, "month")),
          EndDate: new Date(
            new Date(moment().add(1, "month")).getTime() + 31536000000
          ),
          noOfMonths: 12,
          value: 0,
        },
      ],
      Balance: 0,
      CashOnHand: 0,
      AccountYours: 0,
      AccountPartner: 0,
      TotalEquityGain: 0,
      ExtraCash: [],
      CapitalGrowth: [],
      TotalLiabilities: 0,
      MonthlySavings: 0,
      Income: 0,
      NetSalaryYours: 0,
      NetSalaryPartner: 0,
      RentalIncome: 0,
      OtherIncome: 0,
      Data: [],
    },
    PortfolioSummary: {
      Properties: {
        retail: [],
        office: [],
        other: [],
        industrial: [],
        residential: [],
      },
      PropertyPurchase: [],
      selectedRegions: [],
    },
    VendorId: "",

    _id: "",
  });
  const clear = () => {
    toast.current.clear();
  };
  const showError = (Message: string) => {
    toast.current.show({
      severity: "success",
      sticky: true,
      content: (
        <div className="flex p-5 flex-column" style={{ flex: "1" }}>
          <div className="py-1 text-center">
            <p className="popup-error-text">{Message}</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <span
              className="toast-anchor toast-no"
              style={{ marginTop: "15px" }}
              onClick={clear}
            >
              Ok
            </span>
          </div>
        </div>
      ),
    });
  };

  const checkEmailExistance = (email: string) => {
    let check = false;
    Admins.map((item) => {
      if (item.email === email) {
        check = true;
      }
    });
    Contacts.map((item) => {
      if (item.email === email) {
        check = true;
      }
    });
    return check;
  };
  const handleYes = async (userId: string) => {
    dispatch(
      setPath({ SidebarPath: "Profile", headerPath: "/Clients/Profile" })
    );
    dispatch(
      setSelectedUser({
        Progress: [],
        contact_address: userdata?.contact_address,
        contact_type: userdata?.contact_type,
        email: userdata?.email,
        first_name: userdata?.first_name,
        full_name: userdata?.first_name + " " + userdata?.last_name,
        isPrivate: false,
        last_name: userdata?.last_name,
        mobile_no: userdata?.mobile_no,
        photo: userdata?.photo,
        telephone_no: userdata?.telephone_no,
        user_id: userId,
        _id: userId,
      })
    );
    await dispatch(GetUserCalData(userId) as any);
    navigate(`/Clients/Profile?id=${userId}`);
  };
  const showSuccess = (userId: string) => {
    toast.current.show({
      severity: "success",
      life: 2000,
      content: (
        <div className="flex p-5 flex-column w-[100%]">
          <div className="text-center ">
            <div className="flex justify-center w-[100%]">
              <ContactUser />
            </div>
            <p className="!text-[#323232] !text-[16px] !text-center !mt-[32px]">
              Contact is created, would you like to view the Profile?
            </p>
          </div>
          <div className="flex justify-center items-center !mt-[32px]">
            <span
              className="text-[13px] font-[500] toast-yes "
              onClick={() => {
                handleYes(userId);
              }}
            >
              Yes
            </span>
            <span className="text-[13px] font-[500] toast-no" onClick={clear}>
              No
            </span>
          </div>
        </div>
      ),
    });
  };
  const saveUser = async () => {
    let basicUserBody: TBasicUser = {
      email: userdata.email,
      mobile: SpaceRemove(userdata.mobile_no),
      firstName: userdata.first_name,
      middleName: "",
      lastName: userdata.last_name,
      url: "users/",
    };
    let BasicResponse = await CreateBasiqUser(basicUserBody);
    if (BasicResponse?.id) {
      setBasiqUserId(BasicResponse.id);
    }
    let response = await CreateContacts(userdata);
    if (response?.insertedId) {
      dispatch(
        setPath({ SidebarPath: "Profile", headerPath: "/Clients/Profile" })
      );

      UpdateData(response.insertedId);
      if (response?.insertedId) {
        await dispatch(
          GetAllContacts(process.env.REACT_APP_OFFICEID as string) as any
        ); 
        showSuccess(response.insertedId);
      }
    } else {
      showError(response.message);
    }
  };
  const UpdateData = async (id: string) => {
    let newobj: TCalculatorData = InitialCalculatorData;
    newobj.VendorId = id;
    newobj._id = id;
    SaveCalData(newobj);
  };
  const handleAdd = async () => {
    let { error, message } = handleContactFormErrors(userdata);
    if (!error) {
      if (checkEmailExistance(userdata.email)) {
        showError("Email Already Exist");
      } else {
        saveUser();
      }
    } else {
      showError(message);
    }
  };
  return (
    <Container>
      <Toast
        ref={toast}
        position="center"
        style={{ zIndex: "2", opacity: "1" }}
      />
      <InnerContainer padding="1.8rem 32px 1rem 32px">
        <div className="profile-container">
          <h1>Profile</h1>
          <Button
            onClick={handleAdd}
            txt="Create Contact"
            paddingTop="20px 20px"
          />
        </div>
        <div className="flex flex-col">
          <div className="avatar-container">
            <Avatar
              text="CH"
              width="80px"
              height="80px"
              fontSize="23px"
              textColor="#fefefe"
              imgSrc={userdata?.photo || ""}
              setImgSrc={setPhoto}
              setuserdata={setuserdata}
              data={userdata}
              userProfile={false}
            />

            <div className="avatar-text-container">
              <p className="main-text">
                {userdata?.first_name}&nbsp;{userdata?.last_name}
              </p>
            </div>
          </div>
          <div className="mt-[20px]">
            <ContactForm userdata={userdata} setuserdata={setuserdata} />
          </div>
        </div>
      </InnerContainer>
    </Container>
  );
};
