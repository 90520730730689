import { useState } from "react";
import HistoryImg from "assets/images/History.svg";
import HistoryImg2 from "assets/SVG/HistoryWhite.svg";
import PropertyHistoryDialog from "./PropertyHistoryDialog";
const PropertyHistory = ({rowData}:{rowData:any}) => {
  const [propertyHistory, setpropertyHistory] = useState<any>([]);
  const [HistoryVisible, setHistoryVisible] = useState(false);  
  let check = rowData?.Background;
    const handleHistoryClick=(e:any)=>{
      console.log("I am Click",rowData,)
      e.preventDefault();
      let HistoryArr = rowData?.propertyHistory?.map((item:any) => {
        let FieldArr = item.ChangeFields.map((item1:any) => {
          let newobj = {
            ...item1,
            AdminId: item?.AdminId || "",
            AdminName: item?.AdminName || "",
          };
          return newobj;
        });
        return FieldArr;
      });
      let arr:any = [];
      HistoryArr?.map((item:any) => {
        item?.map((item2:any) => {
          arr.push(item2);
        });
      });
     
      setpropertyHistory(arr);
      setHistoryVisible(true);
    }
    return (
      <>
       <PropertyHistoryDialog  setHistoryVisible={setHistoryVisible} HistoryVisible={HistoryVisible} propertyHistory={propertyHistory}/> 
        <button
          onClick={handleHistoryClick}
          className="text-sm "
          style={{
            width: "30px",
            height: "30px",
            color: "#262660",
            fontFamily: "Inter",
            justifyContent: "center",
            alignItems: "center",
            background: "none",
            border: "none",
            display:"flex"
          }}
          type="button"
         
        >
           {check ? <img className='w-10 h-10' src={HistoryImg2} /> : <img src={HistoryImg} className='w-10 h-10' />}
        </button>
      </>
    );
  };

export default PropertyHistory