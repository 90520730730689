import React, { useState, useEffect } from "react";
import { PortfolioOptions } from "utils/constants";
import { InnerContainer, ContactDropDown } from "global.style";
import { InfoBox } from "components/screen-components/InfoBox";
import { InputNumbers } from "components/ui/InputNumbers";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import {
  SaveCalData,
  TCalculatorData,
  SetCalData,
} from "store/Slices/CalculatorSlice";
import { useDispatch } from "react-redux";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
type PrefrencesData = {
  income_goal: number;
  PropertyType: string;
  NetYieldonProperty: number;
};
export const ClientPrefrences = () => {
  const [Hover, setHover] = useState<boolean>(false);
  const dispatch = useDispatch();
  const usercaldata = useSelector((state: RootState) => state.calculator);
  const lockedProperties = usercaldata.PropertyGenerators.filter((item)=>item.isLocked)
  const [prefrencesData, setprefrencesData] = useState<PrefrencesData>({
    income_goal: usercaldata?.BorrowingCalculator?.income_goal,
    PropertyType: usercaldata?.RiskProfile?.PropertyType,
    NetYieldonProperty: usercaldata?.RiskProfile?.NetYieldonProperty,
  });

  const Autosave = async ({income_goal,PropertyType,NetYieldonProperty}:{income_goal:number,PropertyType:string,NetYieldonProperty:number}) => {
    let newcalobj: TCalculatorData = { ...usercaldata };
    let NetYieldProperty = NetYieldonProperty;
    let propertyMode = usercaldata?.RiskProfile?.PropertyMode
    if (PropertyType === "Residential") {
      NetYieldProperty = 5;
      propertyMode = "Conservative"
    } else if (PropertyType === "Commercial - Retail") {
      NetYieldProperty = 6;
      propertyMode = "Moderate"
    } else if (PropertyType === "Commercial - Office") {
      NetYieldProperty = 7;
      propertyMode = "Aggressive"
    }
    newcalobj = {
      ...newcalobj,
      BorrowingCalculator: {
        ...newcalobj.BorrowingCalculator,
        income_goal: income_goal,
      },
      RiskProfile: {
        ...newcalobj.RiskProfile,
        PropertyType: PropertyType,
        NetYieldonProperty: NetYieldProperty,
        PropertyMode:propertyMode
      },
      PortfolioSummary:{
        ...newcalobj.PortfolioSummary,
        selectedRegions:[]
      },
      PropertyGenerators:[]
    };
    dispatch(SetCalData(newcalobj));
      SaveCalData(newcalobj);
    
  };
  
  return (
    <InnerContainer padding="1rem 30px 2.35rem 30px">
      <div className="profile-container">
        <h1>Client Lifestyle Goals</h1>
      </div>
      <div className="flex relative flex-col gap-[25px] flex-wrap">
        <div>
          <p className="flex items-center mb-2 input-label">
            <span>Lifestyle Goal</span>
            <i
              onMouseOver={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              className="ml-2 cursor-pointer pi pi-info-circle"
            ></i>
          </p>
          <InfoBox
            className={`infobox ${Hover}`}
            onMouseLeave={() => setHover(false)}
            style={{ width: "320px", left: 0, position: "absolute" }} //310
            show={Hover}
          >
            <p>
              Your lifestyle goal is the annual net income you aim to generate
              from your portfolio to sustain your lifestyle.
            </p>
          </InfoBox>
          <InputNumbers
            inputClassName="!mt-[5px] !w-[100%]"
            value={usercaldata?.BorrowingCalculator?.income_goal}
            
            onChange={(e) => {
              setprefrencesData({
                ...prefrencesData,
                income_goal: Number(e.value),
              });
              let newcalobj: TCalculatorData = { ...usercaldata };
                newcalobj = {
                ...newcalobj,
                BorrowingCalculator: {
                  ...newcalobj.BorrowingCalculator,
                  income_goal: Number(e.value),
                }
              }
              dispatch(SetCalData(newcalobj));
              SaveCalData(newcalobj);
             
            }}
          />
        </div>
        <div>
          <p className="flex items-center mb-2 input-label">
            <span>Portfolio Makeup</span>
          </p>
          <ContactDropDown
            height="40px"
            className="!mt-[5px]"
            value={usercaldata?.RiskProfile?.PropertyType}
            onChange={(e) => {
              if(lockedProperties?.length>0){
                NotificationManager.error("Some of Properties are locked, Kindly unlock to change risk profile", "", 2000);
              }else{
                setprefrencesData({ ...prefrencesData, PropertyType: e.value });
                Autosave({income_goal:prefrencesData?.income_goal,PropertyType:e.value,NetYieldonProperty:prefrencesData?.NetYieldonProperty})
              }
              
            }}
            options={PortfolioOptions}
          />
        </div>
      </div>
      <NotificationContainer />
    </InnerContainer>
  );
};
