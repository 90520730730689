import React from "react";
import { TAllContacts, TAllUsers } from "types";
import { Avatar } from "components/screen-components/Avatar";
import { Link } from "react-router-dom";
import { SplitContactType } from "utils/helperFunctions";
import { useDispatch } from "react-redux";
import { GetUserCalData } from "store/Slices/CalculatorSlice";
import { useNavigate } from "react-router-dom";
import { setPath } from "store/Slices/PathSlice";
// @ts-ignore
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { setSelectedUser } from "store/Slices/UserSlice";
type ClientBodyProps = {
  currentTableData: TAllContacts[] ;
};
export const ClientBody = ({ currentTableData }: ClientBodyProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const opensidebar = () => {};
  const GetUserData = async (item:TAllContacts) => {
    dispatch(setSelectedUser(item))
    dispatch(setPath({SidebarPath:"Profile",headerPath:'/Clients/Profile'}))
    await dispatch(GetUserCalData(item._id) as any);
    navigate(`/Clients/Profile/?id=${item._id}`)
  };
  return (
    <>
      {currentTableData.map((item, index) => {
        let currentProgress =
          item?.Progress && item?.Progress?.length > 0
            ? item.Progress[0].completed
            : 0;
        let percentage = (100 / 5) * currentProgress;
        return (
          <tr key={index}>
            <td>
              <div className="avatar-column" onClick={opensidebar} >
                <Avatar
                  width="30px"
                  height="30px"
                  imgSrc={item.photo}
                  fontSize="8.8px"
                  textColor="#fefefe"
                  text={item.full_name}
                />
                <Link to={``} onClick={()=>GetUserData(item)} >
                  {item.full_name}
                </Link>
              </div>
            </td>
            <td onClick={opensidebar}>
              <Link to={``} onClick={()=>GetUserData(item)}>{item.email}</Link>
            </td>
            <td onClick={opensidebar}>
              <Link to={``} onClick={()=>GetUserData(item)}>
                {item.mobile_no}
              </Link>
            </td>
            <td onClick={opensidebar}>
              <Link to={``} onClick={()=>GetUserData(item)}>
                {item.contact_address}
              </Link>
            </td>
            <td onClick={opensidebar}>
              <Link to={``} onClick={()=>GetUserData(item)}>
                {SplitContactType(item.contact_type)}
              </Link>
            </td>
            <td onClick={opensidebar} className="">
              <div className="progressContainer">
                <div className="progress">
                  <CircularProgressbar
                    maxValue={100}
                    minValue={0}
                    styles={buildStyles({
                      textColor: "#0465eb",
                      pathColor: "#0465eb",
                    })}
                    value={percentage.toFixed(0)}
                    text={`${percentage.toFixed(0)}%`}
                  />
                </div>
              </div>
            </td>
          </tr>
        );
      })}
    </>
  );
};
