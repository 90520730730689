

import { GetAllSuburbs,GetOfficeMedianPrices,GetResidentialMedianPrices,GetRetailMedianPrices } from "services/api"

import { GS } from "services/global";
const resiRegions = [
    { name: "Playford", state: "SA" },
    { name: "Bundaberg", state: "QLD" },
    { name: "Onkaparinga", state: "SA" },
    { name: "Tamworth", state: "NSW" },
    { name: "Ipswich", state: "QLD" },
    { name: "Geelong", state: "VIC" },
    { name: "Gladstone", state: "QLD" },
    { name: "Rockhampton", state: "QLD" },
    { name: "Toowoomba", state: "QLD" },
    { name: "Onkaparinga", state: "QLD" },
  ];
export const useGetDomainData = () => {


const getSuburbStats = async() =>{
 
    let url = `pricefinder/suburb/median-prices?type=Residential&`;
    for (let rg of resiRegions) {
      url += `regions[]=` + rg.name + `__` + rg.state + `&`;
    }
  const residentialregionData = await GetResidentialMedianPrices(url);
  
    const retailregionData = await GetRetailMedianPrices();
    const officeregionData = await GetOfficeMedianPrices();
   
    const regionsData = await GetAllSuburbs()

    const resdata = regionsData?.data?.find(
        (i: any) => i.propertyType === "Residential"
      ).regions;
      const officeData = regionsData?.data?.find(
        (i: any) => i.propertyType === "Offices"
      ).regions;
      const retailData = regionsData?.data?.find(
        (i: any) => i.propertyType === "Retail"
      ).regions;
      let residentialdata = [];
      let officedata = [];
      let retaildata = [];
      for(let i=0;i<resdata?.length;i++){
        const res = resdata[i]; 
        if (res && res.median>0) {
            
          residentialdata.push({
            ...res,  
            Suburb_Name: "", 
            region: res.name,
            State: res.state,
            Median_Sale_Price: res.median,
            Median_Rental_Price: res.medianRental, 
            postcode:res.postcode,
            growthPercent: res.growth_percent,
          });
        }
      }
      for(let i=0;i<officeData?.length;i++){
        const off = officeData[i];
        if (off && off.median>0) {
          officedata.push({
            ...off,
            Suburb_Name: "",
            region: off.name,
            State: off.state,
            Median_Sale_Price: off.median,
            Median_Rental_Price: off.medianRental,
            postcode:off.postcode,
            growthPercent: off.growth_percent,

          });
         
        } 
      }
      for(let i=0;i<retailData?.length;i++){
        const ret = retailData[i];
        if (ret && ret.median>0) {
          retaildata.push({
            ...ret,
            Suburb_Name: "",
            region: ret.name,
            State: ret.state,
            Median_Sale_Price: ret.median,
            Median_Rental_Price: ret.medianRental,
            postcode:ret.postcode,
            growthPercent: ret.growth_percent,

          });
        }
      }
      if (officedata?.length > 0) {
        officedata.sort((a: any, b: any) => a.region.localeCompare(b.region));
      }
      if (retaildata?.length > 0) {
        retaildata.sort((a: any, b: any) => a.region.localeCompare(b.region));
      }
      if (residentialdata?.length > 0) {
        residentialdata.sort((a: any, b: any) => a.region.localeCompare(b.region));
      }
      GS.Calculator.DomainData = {
        data:{
          office: officedata,
          other:[],
          retail: retaildata,
          industry: [],
          residential: residentialdata,
        },
        regionsData:{
          retail: retailregionData?.data || [],
          industry: [],
          office: officeregionData?.data || [],
          residential: residentialregionData?.data || [],
          other: [],
        }
      }
      console.log('GS',GS)
      return GS
     
     
}
return {
    getSuburbStats
}
}