import React, { useState,useRef } from "react";
import { Image } from "react-bootstrap";
import AnimateHeight from "components/screen-components/AnimateHeight";
import OpenImg from "assets/images/Expand_down1.svg";
import CloseImg from "assets/images/Expand_down2.svg";
import { PropertyBox, PropertyContent } from "global.style";
import { commaStringSeparatorsWithDollors } from "utils/helperFunctions";
import ToolTips from "components/ui/Tooltip/Tooltips";
import { Row, Col } from "react-bootstrap";
import { GradientButton } from "components/ui/Buttons/GradientButton";
import { Toast } from "primereact/toast";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import url from "config"
import { useParams } from "react-router-dom";
type PropertyCardProps = {
  img: string;
  number: number;
  propertyProps: any;
  admin?: boolean;
};
export const IncomeCard = ({
  img,
  number,
  propertyProps,
  admin = false,
}: PropertyCardProps) => {

  const selecteduser = useSelector((state:RootState)=>state.userProfile.selectedUser)
  const userAllow = useSelector((state:RootState)=>state.contacts.UserAllow)
  const {_id} = useParams()
  const toast = useRef<any>()
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [Hovering, setHovering] = useState(false);
  const [currentHoverItem, setcurrentHoverItem] = useState<any>();
  const pathname = window.location.href.includes("IncomePlan")
    ? "IncomePlan"
    : "Profile";
  const [open, setOpen] = useState(true);
  const height = open ? "auto" : 0;
  const netrent = propertyProps?.listingData
  ? isNaN(propertyProps?.listingData?.GrossRent)
    ? 0
    : propertyProps?.listingData?.GrossRent
  : 0
  const handleMouseEnter = ({idx, event, currentItem}:{idx:any, event:any, currentItem:any}) => {
    if(currentItem?.medianPropertyType=="residential"){
      if(currentItem["0"]?.listing?.media?.length>0){
        setHovering(true);
      }
    }else{
      if(currentItem?.listing?.media?.length>0){
        setHovering(true);
      }
    } 
    const rect = event.currentTarget.getBoundingClientRect();
    setTooltipPosition({
      top: 40,
      left: 27,
    });
  };
  const splitWords = (item:any) => {
    let words = item?.trim();
    
    let splitIndexarray:any = []; // Initialize with 0
  
    for (let i = 0; i < words?.length; i++) {
      let character=words.charAt(i)
      if(character === character.toUpperCase()){
     
        splitIndexarray=[...splitIndexarray,i]
      }
    }
    if (splitIndexarray?.length > 1) {
      let newword = "";
      for (let i = 0; i < splitIndexarray?.length; i++) {
        if (i === 0) {
          let newWord = words.slice(0, splitIndexarray[i+1]);
          newword = newword+ newWord + " ";
        } else {
          let lastval = (i === splitIndexarray.length - 1) ? words.length : splitIndexarray[i + 1];
          let newWord = words.slice(splitIndexarray[i], lastval);
          newword = newword + newWord + " ";
        }
      }
   
      return newword;
    }
    return words;
  };
  const splitArrayItems = (items:any) => {
    let currStr = "";
    items?.map((item:any) => {
      currStr = currStr + " " + item;
    });
    return splitWords(currStr);
  };
  const showSuccess = () => {
    toast.current.show({
      severity: "success",

      life:1000,
      content: (
        <div
          style={{ textAlign:"center",width:"1000px",height:"70px",display:"flex",justifyContent:"center",alignItems:"center" }}
        >
          Thanks for contacting us. A member of our team will get in touch with you shortly.
          
        </div>
      ),
    });
  };
  const SendEmail=async()=>{
    let data={
      portfolio: {
        address:selecteduser?.contact_address
      },
      agent_name: selecteduser.full_name,
      agent_phone: selecteduser.mobile_no,
      url: process.env.REACT_APP_DOMAIN+`Clients/IncomePlan?id=${_id}`
    }
    try{
      let r=await url.post('contact-us-plan',data)
      if(r){
        showSuccess()
      }
      
    }catch(err){
      console.log(err)
    }
  } 
  return (
    <div className="box-border relative flex flex-col w-full"
    onMouseLeave={()=>{
      setHovering(false)
    }}
    onMouseEnter={(e) => {
     
      handleMouseEnter({idx:number, event:e, currentItem:propertyProps});
      setcurrentHoverItem(propertyProps);
      
    }}>
       <Toast ref={toast} />
      <ToolTips
          top={`${tooltipPosition.top}px`} //{ `${tooltipPosition.top}px`}   //"205px"
          left={`${tooltipPosition.left}px`} //"1200px"//{`${tooltipPosition.left}px`}
          width="auto"
          show={Hovering} //Hovering
          borderRadius={"20px"}
          txt={ 
            <div
            className="propertyPopUpContainer"
              style={{
                width: "400px",
                height: "auto",
                display: "flex",
                justifyContent:"center"
               
              }}
            >
              {currentHoverItem?.medianPropertyType=="residential" ?
              <Row style={{ width: "100%" }}>
             
              <Col sm={11}>
                <div style={{ width: "100%",marginTop:"10px" }}>
                  <div
                    className="PropertyRightContainer"
                    style={{ textAlign: "left", width: "100%" }}
                  >
                    <p className="boldtxt" style={{ marginLeft: "10px",marginTop:'15px' }}>
                      Property Details
                    </p>
                    <div
                      className="PropertyDetails"
                      style={{ marginLeft: "10px", width: "100%" }}
                    >
                      <div>
                        <p>Property Type</p>
                        <p >
                          {splitArrayItems(
                            currentHoverItem["0"]?.listing?.propertyDetails
                              ?.allPropertyTypes
                          )}
                        </p>
                      </div>
                      <div>
                        <p>Display Price</p>
                        <p>
                          {
                            currentHoverItem["0"]?.listing?.priceDetails
                              ?.displayPrice
                          }
                        </p>
                      </div>
                      <div>
                        <p>Area</p>
                        <p>
                          {currentHoverItem["0"]?.listing?.propertyDetails?.area}
                        </p>
                      </div>
                      <div>
                        <p>Bathrooms</p>
                        <p>
                          {
                            currentHoverItem["0"]?.listing?.propertyDetails
                              ?.bathrooms
                          }
                        </p>
                      </div>
                      <div>
                        <p>Bedrooms</p>
                        <p>
                          {
                            currentHoverItem["0"]?.listing?.propertyDetails
                              ?.bedrooms
                          }
                        </p>
                      </div>
                      <div>
                        <p>Carspaces</p>
                        <p>
                          {
                            currentHoverItem["0"]?.listing?.propertyDetails
                              ?.carspaces
                          }
                        </p>
                      </div>
                      <div>
                        <p>Land Area</p>
                        <p>
                          {
                            currentHoverItem["0"]?.listing?.propertyDetails
                              ?.landArea
                          }
                        </p>
                      </div>
                      <div>
                        <p>Postcode</p>
                        <p>
                          {
                            currentHoverItem["0"]?.listing?.propertyDetails
                              ?.postcode
                          }
                        </p>
                      </div>
                      <div>
                        <p>Region</p>
                        <p>
                          {currentHoverItem["0"]?.listing?.propertyDetails?.region}
                        </p>
                      </div>
                    </div>
             {
             currentHoverItem["0"]?.listing?.propertyDetails?.features?.length>0 &&
             <p className="boldtxt" style={{ marginLeft: "10px",marginTop:"10px" }}>
             Property Features
           </p>
             }      
              
                    <ul
                      style={{
                        textAlign: "left",
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        marginTop: "3px",
                        gap: "10px",
                        width:"100%",
                        marginLeft:"10px"
                       
                      }}
                    >
                      <Row style={{ width: "100%", gap: "0px" }}>
                        {currentHoverItem["0"]?.listing?.propertyDetails?.features?.map(
                          (item:any) => {
                            return (
                              <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                <li>
                                  <p>{splitWords(item)}</p>
                                </li>
                              </Col>
                            );
                          }
                        )} 
                      </Row>
                    </ul>
                    <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      marginTop: "10px",
                    }}
                  >
                    <GradientButton
                      style={{
                        width: "180px !important",
                        height: "40px",
                      }}
                      width="180px"
                      txt="Contact Us Now"
                      onClick={SendEmail}
                    />
                  </div>
                  </div>
                </div>
              </Col>
            </Row>
            :
            <Row style={{ width: "100%"}}>
                {/* <Col sm={12} >
                  <div style={{width:"100%"}}>
                    <img
                      src={currentHoverItem?.listing?.media[0]?.imageUrl}
                      style={{ width: "100%", height: "200px",borderRadius:"12px" }}
                    />
                  </div>
                </Col> */}
                <Col sm={11}>
                  <div style={{ width: "100%",marginTop:"10px" }}>
                    <div
                      className="PropertyRightContainer" 
                      style={{ textAlign: "left", width: "100%" }}
                    >
                      {/* <p className="boldtxt" style={{ marginLeft: "10px" }}>
                        {currentHoverItem?.listing?.headline}
                      </p> */}
                     
                      <p className="boldtxt" style={{ marginLeft: "10px",marginTop:"15px" }}>
                        Property Highlights
                      </p>
                      <div
                        className="PropertyDetails"
                        style={{ marginLeft: "10px", width: "100%" }}
                      >
                        <div>
                          <p>Floor Area</p>
                          <p>
                            {currentHoverItem?.listing?.propertyArea}
                          </p>
                        </div>
                        <div>
                          <p>Parking</p>
                          <p>
                            {
                              currentHoverItem?.listing?.carspaceCount

                            } x Onsite parking
                          </p>
                        </div>
                        
                        <div>
                          <p>Availability</p>
                          <p>
                          {"Vacant"}
                          </p>
                        </div>
                        <div>
                          <p>Category</p>
                          <p>
                          {
                            (currentHoverItem?.listing?.propertyType)
                            }
                          </p>
                        </div>
                        {/* <div>
                       
                          <p>Gross Rent</p>
                          <p>
                          {CurrencyFormatter.format(currentHoverItem?.listingData?.GrossRent)}
                          </p>
                        </div> */}
                        {/* <div>
                          <p>TDR</p> 
                          <p>
                          {CurrencyFormatter.format(currentHoverItem?.listingData?.tdr)}
                          </p>
                        </div> */}
                      </div>
                      
                      <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      <GradientButton
                        style={{
                          width: "180px !important",
                          height: "40px",
                        }}
                        width="180px"
                        txt="Contact Us Now"
                        onClick={SendEmail}
                      />
                    </div>
                    </div>
                  </div>
                </Col>
              </Row>
              }
              
            </div>
          }
        />
      <Image
        className="w-full h-[324.9px] rounded-[8px] object-cover"
        src={img}
      />
      <PropertyBox>
        <span>Property {number}</span>
        <PropertyContent
          pathname={pathname}
          admin={admin}
          idx={number}
          style={{ marginTop: "-10px" }}
        >
          <div className="Property-Content-header">
            <Image
              src={open === true ? OpenImg : CloseImg}
              onClick={() => setOpen(!open)}
            />
          </div>
          <AnimateHeight height={height}>
            <div className="Propery-body">
              <div className="column">
                <div className="column-content">
                  <p>Property Type</p>
                  <h1 className={(!userAllow && number>1) ?"blurtext":""}>{propertyProps?.summaryData?.propertyType}</h1>
                </div>

                <div className="column-content">
                  <p>LVR</p>
                  <h1 className={(!userAllow && number>1) ?"blurtext":""}>{propertyProps?.summaryData?.LoantoValueRatioLVR}.00%</h1>
                </div>
                <div className="column-content">
                  <p >
                    {propertyProps?.summaryData?.propertyType === "Residential"
                      ? "Net Yield"
                      : "Net Yield"}
                  </p>
                  <h1 className={(!userAllow && number>1) ?"blurtext":""}>
                    {(
                      (Number(netrent) /
                        Number(propertyProps?.listingData?.purchasePrice || 0)) *
                        100 || 0
                    ).toFixed(2)}
                    %
                  </h1>
                </div>
              </div>
              <div className="column">
                <div className="column-content">
                  <p>Purchase Price</p>
                  <h1 className={(!userAllow && number>1) ?"blurtext":""}>
                    {commaStringSeparatorsWithDollors(
                      propertyProps?.listingData
                        ? isNaN(propertyProps?.listingData?.purchasePrice)
                          ? 0
                          : propertyProps?.listingData?.purchasePrice
                        : 0
                    )}
                  </h1>
                </div>
                <div className="column-content">
                  <p>Interest Rate</p>
                  <h1 className={(!userAllow && number>1) ?"blurtext":""}>{propertyProps?.summaryData?.interestRate}%</h1>
                </div>
                <div className="column-content">
                  <p > Cashflow Annual</p>
                  <h1 className={(!userAllow && number>1) ?"blurtext":""}>
                  {commaStringSeparatorsWithDollors(
                      propertyProps?.listingData
                        ? isNaN(propertyProps?.listingData?.CFA)
                          ? 0
                          : propertyProps?.listingData?.CFA
                        : 0
                    )}
                  </h1>
                </div>
              </div>
              <div className="column">
                <div className="column-content">
                  <p>Deposit Required</p>
                  <h1 className={(!userAllow && number>1) ?"blurtext":""}>
                    {commaStringSeparatorsWithDollors(
                      propertyProps?.listingData
                        ? isNaN(propertyProps?.listingData?.tdr)
                          ? 0
                          : propertyProps?.listingData?.tdr
                        : 0
                    )}
                  </h1>
                </div>

                <div className="column-content">
                  <p>Net Rental Income</p>
                  <h1 className={(!userAllow && number>1) ?"blurtext":""}>
                    {commaStringSeparatorsWithDollors(
                      propertyProps?.listingData
                        ? isNaN(propertyProps?.listingData?.GrossRent)
                          ? 0
                          : propertyProps?.listingData?.GrossRent
                        : 0
                    )}
                  </h1>
                </div>
                <div className="column-content">
                  <p>Risk Profile</p>
                  <h1 style={{ color: propertyProps.progressColor }} className={(!userAllow && number>1) ?"blurtext":""}>
                    {propertyProps?.summaryData?.RiskFactoredYieldReturn === 5
                      ? "Conservative"
                      : propertyProps?.summaryData?.RiskFactoredYieldReturn ===
                        6
                      ? "Moderate"
                      : "Aggressive"}
                  </h1>
                </div>
              </div>
            </div>
          </AnimateHeight>
        </PropertyContent>
      </PropertyBox>
    </div>
  );
};
