import React, { useState,FC } from "react";
import {
  CustomInputText,
  CustomInputTextWithIcon,

} from "../../../global.style";
type InputTextProps = {
  width?: string;
  height?: string;
  type?: string;
  name: string;
  classname?: string;
  placeholder?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  IconLeft?: boolean;
  IconRight?: boolean;
  outerclasses?:string;
  required?:boolean;
  disabled?:boolean;
  txtBlur?:boolean;
};
export const InputTexts:FC<InputTextProps> = ({
  type = "text",
  name,
  classname,
  placeholder = "",
  value,
  onChange,
  width,
  height,
  onBlur,
  IconLeft = false,
  IconRight = false,
  outerclasses,
  disabled = false,
  txtBlur = false,

}: InputTextProps) => {
  const [open, setopen] = useState<boolean>(type==="password"?false:true);
  const handlePassword = () => {
    if (type === "password") {
      setopen(!open);
    }
  };
  return (
    <>
      {IconRight ? (
        <>
          <CustomInputTextWithIcon txtBlur={txtBlur} className={`p-input-icon-right ${outerclasses}`}>
            {!open ? (
              <i
                className="cursor-pointer pi pi-eye"
                onClick={handlePassword}
              />
            ) : (
              <i
                className="cursor-pointer pi pi-eye-slash"
                onClick={handlePassword}
              />
            )}
            <CustomInputText
              autoComplete="off"
              placeholder={placeholder}
              onBlur={(event) => onBlur && onBlur(event)}
              width={width}
              height={height}
              type={open?"text":"password"}
              name={name}
              className={classname}
              value={value}
              onChange={onChange}
              disabled={disabled}
            />
          </CustomInputTextWithIcon>
        </>
      ) : IconLeft ? (
        <>
          <CustomInputTextWithIcon txtBlur={txtBlur} className="p-input-icon-left ">
            <i className="pi pi-spin pi-spinner" />
            <CustomInputText
             autoComplete="off"
              placeholder={placeholder}
              onBlur={(event) => onBlur && onBlur(event)}
              width={width}
              height={height}
              type={type}
              name={name}
              className={classname}
              value={value}
              onChange={onChange}
              disabled={disabled}
            />
          </CustomInputTextWithIcon>
        </>
      ) : (
        <>
       
          <CustomInputText
            autoComplete="off"
            placeholder={placeholder}
            onBlur={(event) => onBlur && onBlur(event)}
          
            type={type}
            name={name}
            className={classname}
            value={value}
            onChange={onChange}
            // disabled={disabled}
            txtBlur={txtBlur}
          />
           
        </>
      )}
    </>
  );
};
