/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState,useEffect } from "react";
import { InnerContainer, PropertyHeader } from "global.style";
import AnimateHeight from "components/screen-components/AnimateHeight";
import { InputNumbers } from "components/ui/InputNumbers";
import { Row, Col,Table } from "react-bootstrap";
import { InputTexts } from "components/ui/InputTexts";
import { Calendar } from "primereact/calendar";
import { TEquityGains,TExtraCash,TCapitalGrowth,TCreditCard } from "types";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { SetCalData,SaveCalData } from "store/Slices/CalculatorSlice";
import { useDispatch } from "react-redux";
import { InputPercents } from "components/ui/InputPercents";
import { TMonthlySaving } from "../MonthlySavings";
// @ts-ignore
import moment from "moment";
 
export type TSheetData = {
  EquityGains:TEquityGains[],
  Balance: number,
    CashOnHand: number,
    AccountYours: number,
    AccountPartner: number,
    ExtraCash: TExtraCash[],
    CapitalGrowth: TCapitalGrowth[],
    TotalLiabilities: number,  
}
type BalanceSheetProps={
  handleSaveData:({BalanceSheet,MonthlySavings}:{BalanceSheet:TSheetData,MonthlySavings:TMonthlySaving}) => void;
}
export const BalanceSheet = ({handleSaveData}:BalanceSheetProps) => { 
  const [open, setopen] = useState<boolean>(false);
  const userCalData = useSelector((state:RootState)=>state.calculator)
  const [initialRender, setinitialRender] = useState<boolean>(true);
  const dispatch = useDispatch();
  const[SheetData,setSheetData] = useState<TSheetData>(
    {
    EquityGains: userCalData.StrategicSpending.EquityGains,
    Balance: userCalData.StrategicSpending.Balance,
    CashOnHand: userCalData.StrategicSpending.CashOnHand,
    AccountYours: userCalData.StrategicSpending.AccountYours,
    AccountPartner: userCalData.StrategicSpending.AccountPartner,
    ExtraCash: userCalData.StrategicSpending.ExtraCash,
    CapitalGrowth: userCalData.StrategicSpending.CapitalGrowth, 
    TotalLiabilities: userCalData.StrategicSpending.TotalLiabilities,
  }
  )
  let MonthlySavings:TMonthlySaving={
    MonthlySavings: userCalData?.StrategicSpending?.MonthlySavings || 0,
    Income: userCalData?.StrategicSpending?.Income || 0,
    NetSalaryYours: userCalData?.StrategicSpending?.NetSalaryYours || 0,
    NetSalaryPartner: userCalData?.StrategicSpending?.NetSalaryPartner || 0,
    RentalIncome: userCalData?.StrategicSpending?.RentalIncome || 0,
    OtherIncome: userCalData?.StrategicSpending?.OtherIncome || 0, 
    UseBasiq: userCalData?.BorrowingCalculator.UseBasiq,
    UseAverageAustralian: userCalData?.BorrowingCalculator?.UseAverageAustralian,
    UseManual: userCalData?.BorrowingCalculator?.UseManual,
    TotalExpenses: userCalData?.BorrowingCalculator?.TotalExpenses,
  }
  const height = open ? "auto" : 0; 
  const updateCreditCard = async(updatable_id:number, key:string, value:number) => {
    const updated_list = userCalData.BorrowingCalculator.PersonalLiabilities.CreditCards.map((item, idx) => {
      if (updatable_id === idx) {
        let newitem = { ...item };
        newitem = {
          ...newitem,
          [key]: value,
        };
        return newitem;
      } 
      return item; 
    });
   let newcalbody = {...userCalData}
   newcalbody = {
    ...newcalbody,
    BorrowingCalculator:{
      ...newcalbody.BorrowingCalculator,
      PersonalLiabilities:{
        ...newcalbody.BorrowingCalculator.PersonalLiabilities,
        CreditCards:updated_list
      }
    }
   }
   
   dispatch(SetCalData(newcalbody))
   await SaveCalData(newcalbody)
  
  };
  const updateEquityGains = (updatable_id:number, key:string, value:number | string | Date) => {
    const updated_list = SheetData?.EquityGains?.map((item, idx) => {
      let newitem = {...item}
      if (updatable_id === idx) {
        newitem = {
          ...newitem,
          [key]: value,
        }
      }
      if(key === "Percentage" || key === "remove"){
        let propertyValuation = 0
        let autovalue = 0
        if(item.PropertyName==="PPR"){
            propertyValuation = userCalData.Equity.PPR.AutoValuation
            autovalue = propertyValuation * (Number(value)/100)
        }else{
          let filterproperties = userCalData.Equity.Properties.filter((property)=>property.PropertyName === item.PropertyName)
          if(filterproperties.length>0){
            propertyValuation = filterproperties[0].AutoValuation
            autovalue = propertyValuation * (Number(value)/100)
          }
        }
        newitem = {
          ...newitem,
          Percentage: Number(value),
          value:autovalue
        }
      }
      if (key === "EndDate") {
        let startDate = moment(item.StartDate);
        let noOfMonths = Math.abs(startDate.diff(value, "months"));
        newitem = {
          ...item,
          noOfMonths:noOfMonths,
        }
      } 
      if (key === "StartDate") {
        newitem.EndDate = new Date(
          new Date(value).getTime() + 31536000000
        );
        let startDate = moment(value);
        let noOfMonths = Math.abs(
          startDate.diff(
            new Date(new Date(value).getTime() + 31536000000),
            "months"
          )
        );
        newitem = {
          ...newitem,
          noOfMonths:noOfMonths,
          
        }
      }
      return newitem;
    });
    
    setSheetData({...SheetData,EquityGains:updated_list})
  }
  const removeEquityGains = (index:number,PropertyName:string)=>{
    if(PropertyName!=="PPR"){
      const updated_list = SheetData.EquityGains.filter((_, idx) => index !== idx);
      setSheetData({...SheetData,EquityGains:updated_list})
    }else{
      let updatedEquityGains = SheetData.EquityGains.map((item)=>{
        if(item.PropertyName==="PPR"){
          let newitem = {...item}
          newitem = {
            ...newitem,
            Percentage:0,
            value:0
          }
          return newitem
        }else{
          return item
        }
      })
      setSheetData({...SheetData,EquityGains:updatedEquityGains})
    }
   
  }
  const AddExtraCash = ()=>{
    setSheetData({...SheetData,ExtraCash:[...SheetData.ExtraCash,{ExtraCash:0,Date: new Date()}]}) 
  }
  const removeExtraCash=(index:number)=>{
    const updated_list = SheetData.ExtraCash.filter((_, idx) => index !== idx);
    setSheetData({...SheetData,ExtraCash:updated_list})
  }
  const updateExtraCash = (updatable_id:number, key:string, value:number | string | Date) => {
    const updated_list = SheetData.ExtraCash.map((item, idx) => {
      if (updatable_id === idx) {
        let newitem = {...item}
        newitem = {
          ...newitem,
          [key]: value,
        }
        return newitem;
      }
      return item;
    });
    setSheetData({...SheetData,ExtraCash:updated_list})
  }
  const AutoSave = async()=>{
  
   handleSaveData({BalanceSheet:SheetData,MonthlySavings:MonthlySavings})
  }
  useEffect(()=>{
    if(initialRender){
      setinitialRender(false)
    }else{
      AutoSave();
    }
  },[SheetData])
  return ( 
    <InnerContainer className="" padding="1.5rem 32px 1.5rem 32px">
      <PropertyHeader onClick={() => setopen(!open)}>
        <h1 className="card-title !text-[18px]">Balance Sheet</h1>
        {open ? (
          <div className="mb-[8px]">
            <i className="pi pi-minus" onClick={() => setopen(!open)}></i>
          </div>
        ) : (
          <div className="mb-[8px]">
            <i className="pi pi-plus" onClick={() => setopen(!open)}></i>
          </div> 
        )}
      </PropertyHeader>
      <AnimateHeight height={height}>
        <Row className="!mt-[20px]">
          <Col sm={3}>
            <InputNumbers
              onChange={(e) => { 
              }}
              value={userCalData.StrategicSpending.Balance}
              inputClassName={"!text-left !rounded-[0px] !bg-[var(--grey-light2)] !border-[0px] "}
              classes="!w-[325px]"
            />
          </Col>
        </Row>
        <Row className="!mt-[20px] gap-x-[50px]">
          <Col>
            <div className="flex flex-col gap-[9px]">
              <p className="!text-[16px] font-[500]">Cash On Hand:</p>
              <InputNumbers
                onChange={(e) => {}}
                value={userCalData.StrategicSpending.CashOnHand}
                inputClassName={"!text-left !bg-[var(--primary-blue)] !rounded-[0px] !border-[0px]"}
                classes="!w-[325px] !bg-[var(--primary-blue)]"
              />
            </div>
          </Col>
          <Col>
            <div className="flex flex-col gap-[9px]">
              <p className="!text-[16px] font-[500]">Account (Yours)</p>
              <InputNumbers
                onChange={(e) => {setSheetData({...SheetData,AccountYours:Number(e.value)})}}
                value={SheetData.AccountYours}
                inputClassName={"!text-left !rounded-[0px]"}
                classes="!w-[325px]"
              />
            </div>
          </Col>
          <Col>
            <div className="flex flex-col gap-[9px]">
              <p className="!text-[16px] font-[500]">Account (Partner)</p>
              <InputNumbers
                 onChange={(e) => {setSheetData({...SheetData,AccountPartner:Number(e.value)})}}
                value={SheetData.AccountPartner} 
                inputClassName={"!text-left !rounded-[0px]"}
                classes="!w-[325px]"
              />
            </div>
          </Col>
          <Col>
            <div className="flex flex-col gap-[9px]">
              <p className="!text-[16px] font-[500]">Equity Gain</p>
              <InputNumbers
                onChange={(e) => {}}
                value={userCalData.Equity.UsableEquity}
                inputClassName={"!text-left !rounded-[0px]"}
                classes="!w-[325px]"
              />
            </div>
          </Col> 
        </Row>
        <Row className="!mt-[30px]">
          <Col sm={3} xs={3} lg={3} md={3} >
            {SheetData.ExtraCash.length > 0 ? (
              <>
                <Table bordered className="creditCardTable creditCardTable1" >
                  <thead>
                    <tr>
                      <th>
                        <p className="input-label">Extra Cash</p>
                      </th>
                      <th>
                        <p className="input-label">Date</p>
                      </th>
                      <th></th>
                    </tr>
                  </thead> 
                  <tbody>
                    {SheetData.ExtraCash?.map((item, idx) => {
                      return (
                        <tr key={idx} >
                          <td className="py-1">
                            <InputNumbers
                              value={item.ExtraCash}
                              onChange={(e) => {
                                updateExtraCash(idx, "ExtraCash", Number(e.value));
                              }}
                              inputClassName="!border-[0px] !w-[70px]"
                            />
                          </td>
                          <td className="py-1">
                                <Calendar
                                minDate={new Date(moment())}
                                maxDate={new Date(moment().add(84, "month"))}
                                value={new Date(item.Date)}
                                onChange={(e) =>
                                  {
                                    updateExtraCash(idx, "Date", e.value||"");
                                  }
                                }
                                dateFormat="dd-M-yy"
                                inputClassName="border-0 EquityGains-name !text-[13px]"
                              />
                          </td>
                          
                          <td>
                            <span 
                              onClick={()=>removeExtraCash(idx)}
                              style={{
                                fontSize: "18px",
                                cursor: "pointer",
                                color: "#323232",
                                padding: "0px 2px",
                              }}
                            >
                              x
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                    <tr className="creditcardTablelastrow"> 
                      <td
                        className="text-center cursor-pointer text-[14px] "
                        onClick={AddExtraCash}
                        colSpan={7}
                      >
                        Add New
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </>
            ) : (
              <>
                <Table bordered hover>
                <thead>
                    <tr>
                      <th>
                        <p className="input-label">Extra Cash</p>
                      </th>
                      <th>
                        <p className="input-label">Date</p>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                    >
                      <td
                        className="py-2 !h-[40px] text-center cursor-pointer"
                        onClick={AddExtraCash}
                       
                        colSpan={6}
                      >
                       No Extra Cash
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </>
            )}
          </Col>
        </Row>
        <Row className="!mt-[10px]">
          <Col sm={10} xs={10} lg={5} md={5}>
            <div className="flex flex-col gap-[8px]">
            <p className="!text-[16px] font-[500]">Capital Growth Rate:</p>
               <Table bordered className="creditCardTable creditCardTable1">
                    <thead>
                      <tr>
                        <th>
                          <p className="input-label">Property Name</p>
                        </th>
                        <th>
                          <p className="input-label">Percentage</p>
                        </th>
                        <th>
                          <p className="input-label">Start Date</p>
                        </th>
                        <th>
                          <p className="input-label">End Date</p>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {SheetData?.EquityGains?.map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td className="py-1 !w-[130px]">
                              <div className="flex items-center justify-between">
                                <p>{item.PropertyName}</p>
                              </div>
                            </td>
                            <td className="py-1 !w-[100px]">
                              <div
                               
                                className="flex items-center justify-between"
                              >
                                <InputPercents
                                  suffix="%"
                                  value={item.Percentage}
                                  onChange={(e) => {
                                    updateEquityGains(
                                      idx,
                                      "Percentage",
                                     Number(e.value)
                                    );
                                  }}
                                 
                                  inputClassName="!border-0 EquityGains-fields !w-[100px]"
                                />
                              </div>
                            </td>
                            <td className="py-1">
                              <Calendar
                                minDate={new Date(moment())}
                                maxDate={new Date(moment().add(84, "month"))}
                                value={new Date(item.StartDate)}
                                onChange={(e) =>
                                  
                                  {
                                    
                                    updateEquityGains(idx, "StartDate", e.value||"")
                                  }
                                }
                                dateFormat="dd-M-yy"
                                inputClassName="border-0 EquityGains-name !text-[13px]"
                              />
                            </td>
                            <td className="py-1">
                              <Calendar
                                minDate={new Date(moment())}
                                maxDate={new Date(moment().add(84, "month"))}
                                value={new Date(item.EndDate)}
                                onChange={(e) =>{
                                  updateEquityGains(idx, "EndDate", e.value ||"")
                                }
                                  
                                }
                                dateFormat="dd-M-yy"
                                inputClassName="border-0 EquityGains-name !text-[13px]"
                              />
                            </td>

                            <td className="ExtraCashActionTD">
                              <span
                                onClick={(e) => {
                                  removeEquityGains(idx,item.PropertyName)
                                 
                                }}
                                style={{ 
                                  fontSize: "18px", 
                                  cursor: "pointer",
                                  color: "#323232",
                                  padding: "0px 2px",
                                }}
                              >
                                x
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                       <tr className="creditcardTablelastrow">
                     
                    </tr>
                    </tbody>
                  </Table>
            </div>
          </Col>

        </Row>
        <Row className="!mt-[10px] gap-y-[30px]">
        <Col sm={3}>
        <div className="flex flex-col gap-[9px]">
              <p className="!text-[16px] font-[500]">Liabilities:</p>
              <InputNumbers
              onChange={(e) => {}}
              value={userCalData.StrategicSpending.TotalLiabilities}
              inputClassName={"!text-left !rounded-[0px] !bg-[var(--primary-yellow)] !border-[0px] "}
              classes="!w-[325px]"
            />
          </div>
        </Col>
        {userCalData.BorrowingCalculator.PersonalLiabilities.CreditCards.map((item,index)=>{
          return(
            <React.Fragment key={index}>
            <Col sm={3}>
             <div className="flex flex-col gap-[9px]">
                {((item.type==="")&&(item.name==="")&&(item.owner==="")) ? 
                    <p className="!text-[16px] font-[500]" style={{opacity:"0"}}>Liabilities:</p>:
                    <p className="!text-[16px] font-[500]">
                       {item.type}{" "}
                       {`${
                          item?.name ? "(" + item?.name + ")" : ""
                        }`}{" "}
                        {`${
                          item?.owner ? "(" + item?.owner + ")" : ""
                        }`}
                       </p>
                }
             
              <InputNumbers
              onChange={(e) => {
                updateCreditCard(index, "minimumPayment", Number(e.value));
              }}
              value={item?.minimumPayment}
              inputClassName={"!text-left !rounded-[0px] "}
              classes="!w-[325px]"
            />
          </div>
        </Col>
            </React.Fragment>
          )
        })}
        </Row>

      </AnimateHeight>
    </InnerContainer>
  );
};
