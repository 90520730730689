import { useState } from "react";
import { TMenuItems } from "../types";
import DashboardIcon from "../assets/images/icon-dashboard.png";
import UserIcon from "../assets/images/icon-user.png";
import Contact from "../assets/images/contact.png";
import SettingIcon from "../assets/images/icon-setting.png";
import LogoutIcon from "../assets/images/icon-logout.png";
import TwoPeople from "../assets/images/two_people.svg";
import PlusIcon from "../assets/images/plus.svg";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
export const useSetMenuItems = () => {
  const generatedProp = useSelector((state: RootState) => state.calculator.PropertyGenerators);
  const currentUserCalData = useSelector(
    (state: RootState) => state.calculator
  );
  let accountType = sessionStorage.getItem("accountType") as string;
  let userId = sessionStorage.getItem("userId") as string;
  const getSummaryProperties = () => {
    if (generatedProp.length === 0 || generatedProp.length === undefined) {
      return [
        {
          link: "/VendorProfile",
          name: "Properties 1",
          header: "/Vendor Profile",
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
      ];
    } else {
      let arr = [];
      for (let k = 1; k <= generatedProp.length; k++) {
        arr.push({
          link: "/VendorProfile",
          header: "/Vendor Profile",
          name: `Properties ${k}`,
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        });
      }
      return arr;
    }
  };
  const AdminMenuItems: TMenuItems[] = [
    {
      link: "/",
      name: "Dashboard",
      header: "/Dashboard",
      icon: DashboardIcon,
      active: true,
      activeColor: "rgba(255, 255, 255, 0.15)",
    },
    {
      link: "/pinder/savedProperties",
      name: "Pinder 2.0",
      header: "/Pinder/Saved Properties",
      icon: UserIcon,
      active: false,
      activeColor: "rgba(255, 255, 255, 0.15)",
      subIcon: PlusIcon,
      subItems: [
        {
          link: "/pinder/savedProperties",
          name: "Local Data",
          header: "/Pinder/Saved Properties",
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
        {
          link: "/pinder/liveProperties",
          name: "Live Data",
          header: "/Pinder/Live Properties",
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
      ],
    },
    {
      link: "/Clients",
      name: "Clients",
      header: "/Clients",
      icon: UserIcon,
      active: false,
      activeColor: "rgba(255, 255, 255, 0.15)",
      subIcon: PlusIcon,
      subItems: [
        {
          link: `/Clients/Profile/?id=${currentUserCalData._id}`,
          name: "Profile Details",
          header: "/Clients/Profile",
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
        {
          link: `/Clients/Finances/?id=${currentUserCalData.VendorId}`,
          name: "Finances",
          header: "/Clients/Finances",
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
        {
          link: `/Clients/Spending/?id=${currentUserCalData.VendorId}`,
          name: "Strategic Spending",
          header: "/Clients/Spending",
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
        {
          link: `/Clients/Summary/?id=${currentUserCalData.VendorId}`,
          name: "Portfolio Summary",
          header: "/Clients/Summary",
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
          subIcon: PlusIcon,
          subItems: getSummaryProperties(),
        },
        {
          link: `/Clients/IncomePlan/?id=${currentUserCalData.VendorId}`,
          name: "Portfolio Plan",
          header: "/Clients/Income Plan",
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
      ],
    },
    {
      link: "/ContactList",
      name: "Contacts",
      header: "/Contacts",
      icon: Contact,
      active: false,
      activeColor: "rgba(255, 255, 255, 0.15)",
      subIcon: PlusIcon,
      subItems: [
        {
          link: "/Vendors",
          name: "Vendors",
          header: "/Vendors",
          icon: UserIcon,
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
      ],
    },
    {
      link: "/agency/edit",
      name: "Agency Profile",
      header: "/Agency/Agency Edit",
      icon: TwoPeople,
      active: false,
      activeColor: "rgba(255, 255, 255, 0.15)",
      subIcon: PlusIcon,
      subItems: [
        {
          link: "/agency/edit",
          name: "Agency edit page",
          header: "/Agency/Agency Edit",
          icon: TwoPeople,
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
        {
          link: "/agency/users",
          name: "Users",
          header: "/Agency/Users",
          icon: TwoPeople,
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
        {
          link: "/agency/access",
          name: "Permissions access",
          header: "/Agency/Access",
          icon: TwoPeople,
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
      ],
    },
    {
      link: "/settings/assumptions",
      name: "Settings",
      header: "/Settings/Assumptions",
      icon: SettingIcon,
      active: false,
      activeColor: "rgba(255, 255, 255, 0.15)",
      subIcon: PlusIcon,
      subItems: [
        {
          link: "/settings/assumptions",
          name: "Assumptions",
          header: "/Settings/Assumptions",
          icon: TwoPeople,
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
        {
          link: "/settings/slider",
          name: "Slider",
          header: "/Settings/Slider",
          icon: TwoPeople,
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
        {
          link: "/settings/themes",
          name: "Themes",
          header: "/Settings/Themes",
          icon: TwoPeople,
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
        {
          link: "/settings/median-prices",
          name: "Median Prices",
          header: "/Settings/Median Prices",
          icon: TwoPeople,
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
        {
          link: "/settings/upload-csv",
          name: "Upload CSV",
          header: "/Settings/properties",
          icon: TwoPeople,
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
        {
          link: "/settings/propertyType",
          name: "Property Type",
          header: "/Settings/Property Type",
          icon: TwoPeople,
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
        
      ],
    },

    {
      link: "/logout",
      name: "Logout",
      header: "/",
      icon: LogoutIcon,
      active: false,
      activeColor: "rgba(255, 255, 255, 0.15)",
    },
  ];
  const ClientMenuItems: TMenuItems[] = [
    {
      link: "/",
      name: "Dashboard",
      header: "/Dashboard",
      icon: DashboardIcon,
      active: true,
      activeColor: "rgba(255, 255, 255, 0.15)",
    },
    {
      link: `/Clients/Profile?id=${userId}`,
      name: "Lifestyle Goals",
      header: "/Clients/Profile",
      icon: UserIcon,
      active: true,
      activeColor: "rgba(255, 255, 255, 0.15)",
      subIcon: PlusIcon,
      subItems: [
        {
          link: `/Clients/Profile?id=${userId}`,
          name: "Profile Details",
          header: "/Clients/Profile",
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
        {
          link: `/Clients/Finances?id=${userId}`,
          name: "Finances",
          header: "/Clients/Finances",
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
        {
          link: `/Clients/Spending?id=${userId}`,
          name: "Strategic Spending",
          header: "/Clients/Spending",
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
        {
          link: `/Clients/Summary?id=${userId}`,
          name: "Portfolio Summary",
          header: "/Clients/Summary",
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
          subIcon: PlusIcon,
          subItems: getSummaryProperties(),
        },
        {
          link: `/Clients/IncomePlan?id=${userId}`,
          name: "Portfolio Plan",
          header: "/Clients/Income Plan",
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
      ],
    },
    {
      link: "/logout",
      name: "Logout",
      header: "/",
      icon: LogoutIcon,
      active: false,
      activeColor: "rgba(255, 255, 255, 0.15)",
    },
  ];
  const [MenuItems] = useState<TMenuItems[]>(
    accountType === "Master Admin" ? AdminMenuItems : ClientMenuItems
  );

  return { MenuItems };
};
