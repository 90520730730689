import React from "react";
import { InnerContainer } from "global.style";
import { Row, Col } from "react-bootstrap";
import { commaStringSeparatorsWithDollors } from "utils/helperFunctions";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

export const BottomGradient = () => {
  const userCaldata = useSelector((state: RootState) => state.calculator);
  return (
    <InnerContainer className="card-gredient !min-h-[110px]">
      <Row className="flex justify-between">
        <Col md={4} lg={4}>
          <div className="d-flex align-items-end justify-content-between">
            <div className="text-left">
              <p className="my-1 strong-text"></p>
              <p className="my-1 text-base text-[white]">
                Total Income
              </p>
              <p className="my-1 text-base  text-[white]">
                Total Liabilities
              </p>
              <p className="my-1 text-base  text-[white]">
                Total Expenses
              </p>
              <p className="my-1 text-base  text-[white]">
                Net Personal Income{" "}
              </p>
            </div>
            <div className="text-right">
              <p className="my-1 text-base  text-[white]">{commaStringSeparatorsWithDollors(userCaldata.StrategicSpending.Income) }</p>
              <p className="my-1 text-base  text-[white]">{commaStringSeparatorsWithDollors(userCaldata.StrategicSpending.TotalLiabilities) }</p>
              <p className="my-1 text-base  text-[white]">{commaStringSeparatorsWithDollors(userCaldata.BorrowingCalculator.TotalExpenses) }</p>
              <p className="my-1  text-base text-[white]">{commaStringSeparatorsWithDollors(userCaldata.StrategicSpending.MonthlySavings) }</p>
            </div>
          </div>
        </Col>
        <Col className="net-personal-icome-saving-container" md={4} lg={4}>
          <Row className="bg-white net-personal-icome-saving">
            <Col xs={8} sm={8} md={8} lg={8}>
              <div>
                <p className="strong-text ">Cash On Hand</p>
              </div>
            </Col>
            <Col xs={4} sm={4} md={4} lg={4}>
              <div className="text-right">
                <p>{commaStringSeparatorsWithDollors(userCaldata.StrategicSpending.CashOnHand) }</p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </InnerContainer>
  );
};
