import React from 'react'
import { InputMaskProps } from 'primereact/inputmask'
import { CustomInputPhone } from 'global.style'
interface PhoneProps extends InputMaskProps{

}
const InputPhone = ({...props}:PhoneProps) => {
  return (
    <CustomInputPhone {...props}>

    </CustomInputPhone>
  )
}

export default InputPhone