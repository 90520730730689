import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import url from "../../config";
import type { PayloadAction } from "@reduxjs/toolkit";
export type TVendorsData = {
    Progress:[
        {
        completed:number,
        current:number,
        steps:number
    }
],
    contact_address:string,
    contact_type:string,
    email: string,
    first_name:string,
    full_name:string,
    isPrivate:boolean,
    last_name: string,
    mobile_no: string,
    photo:string | null,
    telephone_no:string,
    user_id:string,
    _id:string
};
export const GetVendorsData=createAsyncThunk('contacts?office_id=id',async(id:string)=>{
    try {
        let response = await url.get(`contacts?office_id=${id}`);
        return response.data;
      } catch (err) {
        return err;
      }
})
export const SearchVendors=async(id:string,query:string)=>{
    try {
        let response = await url.get(`contacts?office_id=${id}${query !== "" ? `&search=${query}` : ""}`);
        return response.data;
      } catch (err) {
        return err;
      }
}


const initialState: TVendorsData[] = [

];
const VendorsSlice = createSlice({
  name: "vendors",
  initialState,
  reducers: {
    setVendorsData: (state, action: PayloadAction<TVendorsData[]>) => {
      state = action.payload;
    },
  },
  extraReducers:(builder) => {
    builder.addCase(GetVendorsData.fulfilled,(state,action)=>{
        console.log(action.payload.data)
       state=action.payload.data
    })
    
  },
});
export const { setVendorsData } = VendorsSlice.actions;
export default VendorsSlice.reducer;
