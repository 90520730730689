import React,{useState,memo,useEffect} from 'react'
import { CustomProgressBar } from 'global.style'
import { ContainsLetter,ContainsNumber } from 'utils/helperFunctions'
import classes from "stylesheet.module.css"
type PasswordCheckProps={
    password:string
}
const PasswordCheck = ({password}:PasswordCheckProps) => {
    const[count,setcount]=useState<Number>(0)
    useEffect(()=>{
      let count=0
      if(password.length>=8){
        count++
      }if(ContainsLetter(password)){ 
        count++
      } if(ContainsLetter(password)){
        count++
      }
      setcount(count)
     
    },[password])
  return (
    <>
     {((password.length>0) && (count!==3) ) &&
       <div style={{width:"100%",height:"auto",borderRadius:"5px",marginTop:'5px',backgroundColor:'rgba(234,234,234,1)'}}>
       <div style={{display:"flex",flexDirection:"row",gap:"10px",padding:"10px",paddingTop:"15px"}}>
         <CustomProgressBar value={password.length>=8?100:0}/>
         <CustomProgressBar value={ContainsLetter(password)?100: 0}/>
         <CustomProgressBar value={ContainsNumber(password)?100: 0}/>
         
       </div>
       <div style={{display:"flex",flexDirection:"column",gap:"5px",paddingLeft:"10px",paddingBottom:"10px"}}>
           <div style={{display:"flex",flexDirection:"row",gap:"10px"}}>
            {password.length>=8?
            <div className={classes.passBox} >
            <i className={`pi pi-check ${classes.icon}`} ></i>
          </div>
          :
          <div className={classes.wrongBox} >
               <i className={`pi pi-times ${classes.icon}`} ></i>
             </div>
          }
             
           <p style={{fontFamily:"Inter",fontSize:"13px",color:"black !important"}}>Min 8 Characters</p>
           </div>
           <div style={{display:"flex",flexDirection:"row",gap:"10px"}}>
            {ContainsLetter(password)===true?
            <div className={classes.passBox} >
            <i className={`pi pi-check ${classes.icon}`} ></i>
          </div>
          :
          <div className={classes.wrongBox} >
               <i className={`pi pi-times ${classes.icon}`} ></i>
             </div>
          }
           
           <p style={{fontFamily:"Inter",fontSize:"13px",color:"black !important"}}>A Letter</p>
           </div>
           <div style={{display:"flex",flexDirection:"row",gap:"10px"}}>
            {ContainsNumber(password)?
            <div className={classes.passBox} >
            <i className={`pi pi-check ${classes.icon}`} ></i>
          </div>:
          <div className={classes.wrongBox} >
          <i className={`pi pi-times ${classes.icon}`} ></i>
        </div>
          }
           
           <p style={{fontFamily:"Inter",fontSize:"13px",color:"black !important"}}>A Number</p>
           </div>
         </div>
     </div>
       }  
    </>
  )
}

export default memo(PasswordCheck)