import React from "react";
import { OuterContainer, InnerContainer } from "global.style";
import { PortfolioJourney } from "components/screen-components/PortfolioJourney";
import { Equity } from "components/screen-components/Calculator/Equity";
import { GradientButton } from "components/ui/Buttons/GradientButton";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/store";
import { useNavigate} from "react-router-dom";
import { SaveCalData, SetCalData } from "store/Slices/CalculatorSlice";


export const Finance = () => {
  const userCaldata = useSelector((state: RootState) => state.calculator);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const updateProfile = async (event: React.MouseEvent<HTMLButtonElement>) => {
    let newcalobj = { ...userCaldata };
    newcalobj = {
      ...newcalobj,
      Progress: {
        ...newcalobj.Progress,
        completed: 2,
        current: 3,
      },
    };

    SaveCalData(newcalobj);
    dispatch(SetCalData(newcalobj));
    navigate(`/Clients/Spending?id=${newcalobj.VendorId}`);
  };
  return (
    <OuterContainer>
      <InnerContainer width="100%" bg={true} padding="1rem 15px 1rem 15px">
        <PortfolioJourney currentActive="Finances" />
        <Equity />
        <div className="relative flex justify-end mt-9">
          <GradientButton
            onClick={updateProfile}
            style={{
              position: "fixed",
              bottom: "31px",
              right: "94px",
              width: "89px !important",
            }}
            width="89px"
            txt="Next"
          />

          <GradientButton
            style={{
              position: "fixed",
              bottom: "31px",
              right: "196px",
              width: "89px !important",
            }}
            onClick={() => {
              navigate(-1)
            }}
            width="89px"
            txt="Back"
          />
        </div>
      </InnerContainer>
    </OuterContainer>
  );
};
