import React from 'react'
import { RootState } from 'store/store'
import { useSelector } from "react-redux"
import { Image } from 'react-bootstrap'
import Logo1 from "assets/images/logo.png"
import SignUpBg from "assets/images/signup-bg.png";
import SignInBg from "assets/images/signin-bg.png";
import classes from "stylesheet.module.css"
import Logo from "assets/images/invest-logic.png";
import { Link } from 'react-router-dom'
import ResetPasswordForm from 'components/screen-components/ResetPasswordForm'
export const ResetPassword = () => {
  const theme = useSelector((state: RootState) => state.theme.currentTheme)
  return (
    <div className={`min-h-[100vh] bg-[var(--white)] flex`}>
      <div className={`${classes.vectorInfo} w-[50%]`}>
        {theme === "Propvesting" && <div className={`w-[90%] mt-[20px] ml-[40px]`}>
          <Image src={Logo1} className='w-[180px]' />
        </div>}
        <div className={`flex min-h-[80vh] justify-center items-center ${theme === "Propvesting" ? 'pt-[10px] ' : 'pt-[241px]'}`}>
          <Image src={theme === "Propvesting" ? SignInBg : SignUpBg} className={`w-[85%]`} />
        </div>

      </div>
      <div className='w-[50%] flex justify-center' >
        <div className={`min-h-[100vh] w-[65%] m-[0 auto] flex justify-center flex-col`}>
          {theme === "Investlogic" && <div className={`w-[90px] mt-[10px] mx-[10px]`}>
            <Image src={Logo} className={`w-[180px]`} />
          </div>}
          <div>
            <h2 className={`${classes.greeting} my-4`}>{theme === "Propvesting" ? "WELCOME TO PROPVESTING" : "WELCOME TO INVESTLOGIC"}</h2>
          </div>

          <h3 className={classes.title}>Reset Password</h3>
          <ResetPasswordForm />
          <p className={classes.link}>
            Go back to &nbsp;
            <Link to="/signin" className={`${classes.link} underline`}>
              Login
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}

