
import {TSpendingData,TDomainData ,TPropertyGeneratorItem } from "types";
interface TCalculator{
    spendingDatesData:TSpendingData[]
    summaryDatesData:TSpendingData[]
    DomainData:TDomainData,
    PropertyGenerator:TPropertyGeneratorItem[],
    PropertyGenerator2:TPropertyGeneratorItem[]
}
class GlobalService {
public Calculator:TCalculator = {
    spendingDatesData:[],
    summaryDatesData:[],
    DomainData:{
        data: {
            retail: [],
            industry: [],
            office: [],
            residential: [],
            other: [],
          },
          regionsData: {
            retail: [],
            industry: [],
            office: [],
            residential: [],
            other: [],
          },
    },
    PropertyGenerator:[],
    PropertyGenerator2:[]
}
}
let GS = new GlobalService();

export {GS}