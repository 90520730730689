/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { TimelineContainer, Circle, Card } from "global.style";
import { commaStringSeparatorsWithDollors } from "utils/helperFunctions";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
const PlanTimeLine = ({ data }: { data: any }) => {
  const selecteduser = useSelector((state:RootState)=>state.userProfile.selectedUser)
  const userAllow = useSelector((state:RootState)=>state.contacts.UserAllow)
  const getDifference = (date: any) => {
    const yearDifference =
      new Date(date).getFullYear() - new Date().getFullYear();
    // const monthDifference = new Date(date).getMonth() - new Date().getMonth();
    return yearDifference;
    //  + monthDifference;
  };
  const calculateCFM = (idx: any) => {
    let result = 0;
    for (let i = 0; i <= idx; i++) {
      result += data[i].listingData
        ? data[i].listingData.CFA / 12
        : data[i].summaryData.CashflowMonthly;
    }
    return isNaN(result) ? 0 : result;
  };

  return (
    <div className="relative">
      <p className="text-lg ml-[45px] mt-5">Property Timeline</p>
      <TimelineContainer idx={data.length}>
        <div className="relative mb-2 timeline">
          {data.map((item: any, idx: any) => {
            return (
              <>
                <Circle idx={idx} length={data.length} />
                <Card admin={true} idx={idx} length={data.length}>
                  <div>
                    <p className={(!userAllow && idx>0) ?"blurtext":""}>
                      {item.nextDate
                        ? getDifference(item.nextDate) <= 1
                          ? getDifference(item.nextDate) + " Year"
                          : getDifference(item.nextDate) + " Years"
                        : ""}
                    </p>
                    <p >
                      <a
                        style={{ whiteSpace: "nowrap", cursor: "pointer" }}
                        href={
                          !selecteduser.contact_type.includes("Contacts") ? item.listingData?.ad : "#" //
                        }
                        target={"_blank"}
                        className={(!userAllow && idx>0) ?"blurtext":""}
                      >
                        <strong className={(!userAllow && idx>0) ?"blurtext":""}>Purchase property {idx + 1}</strong>
                      </a>
                    </p>
                    <div style={{ whiteSpace: "nowrap" }} className={(!userAllow && idx>0) ?"blurtext":""}>
                      <p>{item.nextDate}</p>
                      <p>
                        {commaStringSeparatorsWithDollors(calculateCFM(idx))}
                        (CFM)
                      </p>
                    </div>
                  </div>
                </Card>
              </>
            );
          })}
        </div>
      </TimelineContainer>
    </div>
  );
};

export default PlanTimeLine;
